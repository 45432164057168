import React, { useState, useEffect } from 'react';
import Table from '../../../common/tableEx';
import { projectColumns, projectProColumns } from './columns';
import { queryListExpertProject, queryProExprience } from '../service';
import { isNotEmpty } from '../../../utils/utils';
import Title from '../../../common/title';
import { Conditions } from '../intermediaryDetail/home/serviceCompanyHistory';

export default function Project(props) {
  const [query1, setQuery1] = useState({
    expertFirm: props.detail.expertFirm,
    expert: props.detail.expertTeam,
    firmName: props.detail.firmName,
    plate: undefined,
    industry: undefined,
    firstUniqueId: props.detail.firstUniqueId
  });
  const [query2] = useState({
    licenseNum: props.detail.cpaLicense,
    expertFirm: props.detail.expertFirm,
    expert: props.detail.expertTeam,
    firmName: props.detail.firmName,
    firstUniqueId: props.detail.firstUniqueId
  });
  const [rate, setRate] = useState('');

  function parseData(res) {
    setRate(res?.data ? res?.data[0].rate : '');
    return isNotEmpty(res) ? res.data : [];
  }

  return (
    <div style={{ background: 'white', padding: '8px 16px' }}>
      <Title level={2} text="项目经历" />
      <h4 style={{ fontSize: 16, fontWidget: 'bold', margin: '4px 0' }}>
        <span style={{ fontSize: 14 }}>
          服务对象过会率：
          <strong style={{ color: '#2269A8' }}>{rate}%</strong>
        </span>
      </h4>
      <Conditions
        onSearch={values => {
          setQuery1({ ...query1, ...values, pageNum: 1 });
          // getList({ ...query2, ...values, pageNum: 1 });
        }}
      />
      <Table
        columns={projectColumns}
        query={query1}
        getData={queryListExpertProject}
        parseData={parseData}
      />
      <h4 style={{ fontSize: 16, marginTop: 20, fontWidget: 'bold' }}>
        专家添加项目经历
      </h4>
      <br />
      <Table
        columns={projectProColumns}
        query={query2}
        getData={queryProExprience}
        parseData={res => (isNotEmpty(res) ? res.data : [])}
      />
    </div>
  );
}
