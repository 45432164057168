import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';


export function getQueryListedCompanyFb(params) {
    return request(`${API.V1}/auth/red/amount`, params);
}

//风险侧重情况
export function riskFocus(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightFirst/riskFocusRose`, params);
}
