import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { riskFocus } from './service';
import { Empty } from 'antd';

global.riskFocusRose = null;


export default function RiskFocusRose({ address, style }) {
  useEffect(() => {
    riskFocus({ area: address }).then(({ success, data }) => {
      if (success == true) {
        if (global.riskFocusRose == null) {
          global.riskFocusRose = new Chart({
            container: 'RiskFocusRose',
            autoFit: true,
            height: 130,
          });
          
          global.riskFocusRose.data(data);
          global.riskFocusRose.coordinate('polar', {
            startAngle: Math.PI, // 起始角度
            endAngle: Math.PI * 2, // 结束角度
          });
          
          global.riskFocusRose.scale('score', {
            tickCount: 6,
          });
          
          global.riskFocusRose.axis('score', {
            grid: {
              line: {
                item: 'circle',
              },
              closed: false,
            },
            verticalFactor: 1,
            label: null
          });
          
          global.riskFocusRose.axis('item', {
            tickLine: {
              alignTick: false
            },
            grid: {
              alignTick: false
            }
          });
          global.riskFocusRose.legend(false);
          global.riskFocusRose.tooltip(false);
          
          global.riskFocusRose
            .interval()
            .position('item*score')
            .color('item', ['#1890ff', '#13c2c2', '#ffc53d', '#73d13d'])
            .label('score', {
              offset: -15,
              style: {
                textAlign: 'center',
                fill: '#fff',
              },
              content:(val) => {
                return val.score + "%"
              }
            })
            .style({
              lineWidth: 1,
              stroke: '#fff',
            });
          
            global.riskFocusRose.render();
        } else {
          global.riskFocusRose.clear();
          global.riskFocusRose.changeData(data);
          global.riskFocusRose.coordinate('polar', {
            startAngle: Math.PI, // 起始角度
            endAngle: Math.PI * 2, // 结束角度
          });
          
          global.riskFocusRose.scale('score', {
            tickCount: 6,
          });
          
          global.riskFocusRose.axis('score', {
            grid: {
              line: {
                item: 'circle',
              },
              closed: false,
            },
            verticalFactor: 1,
            label: null
          });
          
          global.riskFocusRose.axis('item', {
            tickLine: {
              alignTick: false
            },
            grid: {
              alignTick: false
            }
          });
          global.riskFocusRose.legend(false);
          global.riskFocusRose.tooltip(false);
          
          global.riskFocusRose
            .interval()
            .position('item*score')
            .color('item', ['#1890ff', '#13c2c2', '#ffc53d', '#73d13d'])
            .label('score', {
              offset: -15,
              style: {
                textAlign: 'center',
                fill: '#fff',
              },
              content:(val) => {
                return val.score + "%"
              }
            })
            .style({
              lineWidth: 1,
              stroke: '#fff',
            });
          
            global.riskFocusRose.render();
        }
        
      } else {
        <Empty />
      }
    })
  }, [address])
    
    return (
      <div id='RiskFocusRose' className={ styles.RiskFocusHistogram }></div>
    )
}