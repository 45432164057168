import fetch from '../../../utils/fetch';
import { API } from '../../../constants/enum';

//获取关系图谱数据
export function GetRelationData(companyId) {
    return fetch(
        `${API.V1}/risk/graph/relation/query?companyId=`+companyId,
        {},
        {method:'POST'})
}
