/**
 * @author Ray
 * @desc 历史服务公司情况
 */

import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef
} from 'react';
import { Form, Button } from 'antd';
import TableEx from '../../../../common/tableEx';
import { historyColumns } from './columns';
import { queryAgencyService } from './service';
import AutoComplete from '../../../../common/autoComplete';
import { isNotEmpty,  } from '../../../../utils/utils';
import { Selector } from '../../../merging/home/apply';
import { PLATES } from '../../../../constants/enum';
import { getSscIndustry } from '../../../imformation/basiInfo/service';
import PlateSelection from '../../../../common/plateSelection'

export function Conditions({ onSearch }) {
  const [Element, setElement] = useState(null);

  function Forms({ form }, ref) {
    useImperativeHandle(ref, () => ({ form }));
    return (
      <div id='industryConditioin' style={{position: 'relative',margin:"8px 0"}}>
        <Form layout='inline'>
          <Form.Item label='服务行业'>
            {form.getFieldDecorator('industry', {
              initialValue: ''
            })(
              <AutoComplete
                paramKey='srcIndustry'
                parser={data =>
                  data
                    ? data.map(d => ({
                        value: d.industry,
                        label: d.industry,
                        key: d.typeCode
                      }))
                    : []
                }
                getPopupContainer={() =>
                  document.getElementById('industryConditioin')
                }
                loadSource={getSscIndustry}
                placeholder='请输入行业关键字'
              />
            )}
          </Form.Item>
          <Form.Item label='服务板块'>
            {form.getFieldDecorator('plate', {
              initialValue: null
            })(
              // <Selector
              //   placeholder='请选择板块'
              //   style={{ width: 150 }}
              //   getPopupContainer={() =>
              //     document.getElementById('industryConditioin')
              //   }
              //   options={[{ text: '全部', value: null }, ...PLATES]}
              // />
              <PlateSelection width={194}/>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              onClick={() => onSearch(form.getFieldsValue())}
            >
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  useEffect(() => {
    let Component = Form.create({
      name: 'search'
    })(forwardRef(Forms));
    setElement(<Component />);
  }, []);

  return Element;
}

export default function ServiceCompanyHistory({ match }) {
  const [query, setQuery] = useState({
    agency: match.params.companyName,
    industry: '',
    plate: ''
  });
  function mockData(data) {
    return isNotEmpty(data) ? data.data : [];
  }

  return (
    <>
      <Conditions
        onSearch={values => {
          setQuery({ ...query, ...values, pageNum: 1 });
        }}
      />
      <TableEx
        query={query}
        parseData={mockData}
        columns={historyColumns}
        getData={queryAgencyService}
      />
    </>
  );
}
