import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { Table, Empty } from 'antd'
import Hook from './hook';
import { pledgesCol, pledgorsCol, pledgesColDetail } from './columns';
import moment from 'moment';

export default function(props) {

    const { gaugeOptions, loading, barOption, pledge, pledgor, pledgorDetail } = Hook(props);

    const firstArr = pledge && pledge.slice(0, 1);
    const firstDate = firstArr.map(item => moment(item.date).format('YYYY年MM月DD日'));
    
    return (
        <div className={styles.pledge}>
            <div className = {classnames("box", styles.riskNum)}>
                <div className = "fl">
                    <h4 className = "titleBorder">质押平仓风险分析</h4>
                    {
                        loading ? '' :
                        gaugeOptions ? 
                        <ReactEcharts
                            style = {{height: "380px"}}
                            option={gaugeOptions}
                            notMerge={true}
                            theme={'myTheme'}
                        /> : <Empty />
                    }   
                </div>
                <span data = "/ / /" className = "splitLine"></span>
                <div className = "fr">
                    {
                        loading ? '' : 
                            barOption ? 
                            <ReactEcharts
                                style = {{height: "400px"}}
                                option={barOption}
                                notMerge={true}
                                theme={'myTheme'}
                            /> :  <Empty />
                    }  
                </div>
            </div>
            <div className = "box">
                <h4 className = "titleBorder">股权质押统计（截止{firstDate}累计值）</h4>
                {
                    loading ? '' : 
                    <Table 
                        columns={pledgesCol} 
                        dataSource={pledge} 
                        pagination = {{
                            hideOnSinglePage: true
                        }}
                    />
                }
                
            </div>
            <div className = "box">
                <h4 className = "titleBorder">股权质押明细</h4>
                {
                    loading ? '' : 
                    <Table 
                        className={styles.shareholder}
                        columns={pledgesColDetail} 
                        dataSource={pledgor} 
                        pagination = {{
                            hideOnSinglePage: true
                        }}
                    />
                }
            </div>
            <div className = "box">
                <h4 className = "titleBorder">重要股东质押</h4>
                {
                    loading ? '' : 
                    <Table 
                        className={styles.shareholder}
                        columns={pledgorsCol} 
                        dataSource={pledgorDetail} 
                        pagination = {{
                            hideOnSinglePage: true
                        }}
                    />
                }
            </div>
        </div>
    );
}
