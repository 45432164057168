import * as Types from './types';

export function showModal(payload) {
    return {
        type: Types.CONSULTANT_SHOW_MODAL,
        payload: {
            ...payload,
            visible: true
        }
    };
}

export function closeModal() {
    return {
        type: Types.CONSULTANT_CLOSE_MODAL,
        payload: {
            title: undefined,
            content: '',
            visible: false,
            onOk: () => {},
            onCancel: () => {}
        }
    };
}
