import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';
import { listedOverviewInnovate, listedOverviewBasic } from './service';



export default function Line2List({ type }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        //请求创新层数据
        type == 1 ? listedOverviewInnovate().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        }) : 
        //请求基础层数据
        type == 2 ? listedOverviewBasic().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        }) : setData([])
    }, [type])

    
    return (
        <div className={ styles.line2List }>
            <table>
                <thead>
                    <tr>
                        <td>证券数量 (只)</td>
                        <td>总股本 (亿股)</td>
                        <td>流通股本 (亿股)</td>
                        <td>成交股数 (万股)</td>
                        <td>成交金额 (万元)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="20%" style={{ borderRadius: '50px 0 0 50px' }}>{data.sharesCount}</td>
                        <td width="25%">{data.totalEquity}</td>
                        <td width="25%">{data.flowEquity}</td>
                        <td width={ type == 1 ? "12.7%" : '10%' }>{data.dealEquity}</td>
                        <td width="20%" style={{ borderRadius: '0 50px 50px 0' }}>{data.dealMoney}</td>
                    </tr>
                </tbody>
            </table>
            {/* <div className={ styles.loadMore }>
                点击查看更多
            </div> */}
        </div>
    )
}