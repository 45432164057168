/* eslint-disable react/no-multi-comp */
/**
 * @author Ray
 */

import React from 'react';
import Table, { Column } from '../../../../common/table';
// import { ProcessStatus, TimeFormat } from '../../../../constants/enum';
import moment from 'moment';
import { Tooltip } from 'antd';
import { limitString } from '../../../../utils/utils';

export default function({ pageSize, pageNum, dataSource = [] }) {
    return (
        <Table dataSource={dataSource} pagination={false}>
            <Column
                title="流程节点"
                dataIndex="operBy"
                key="operBy"
                align="center"
                width="25%"
            />
            <Column
                title="操作人"
                dataIndex="userName"
                key="userName"
                align="center"
                width="15%"
            />
            <Column
                title="时间"
                dataIndex="gmtTime"
                key="gmtTime"
                align="center"
                render={text => moment(text, 'x').format('YYYY-MM-DD HH:mm:ss')}
                width="15%"
            />
            <Column
                title="动作" // 0：驳回1:通过 2:提交
                dataIndex="action"
                key="action"
                align="center"
                width="15%"
            />
            <Column
                title="审批意见"
                dataIndex="opinion"
                key="opinion"
                align="center"
                width="30%"
                render={text => (
                    <Tooltip title={text}>{limitString(15, text)}</Tooltip>
                )}
            />
        </Table>
    );
}
