/**
 *     "id": "a",
 "timeRange": [1565071224727, 1565071224727],
 "companyName": "Alibaba",
 "jobTitle": "Senior Engineer",
 "jobDescription": "admin admin admin admin admin admin admin admin admin"
 */

export default class Temp {
    id = (Math.random() * 10000).toFixed(5);
    timeRange = [];
    schoolName = '';
    major = '';
    description = '';
}
