import moment from 'moment';
export const ProjectColumns = [
    {
        dataIndex: 'company',
        title: '服务公司名称',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'industryName',
        title: '所属行业',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'location',
        title: '上市板块',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'csrcStatus',
        title: '过会情况',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'ipoDate',
        title: '上市时间',
        align: 'center',
        width: '20%',
        render: (value) => value ? moment(value).format('YYYY-MM-DD') : '--'
    }
];
