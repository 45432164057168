import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc 失信被执行人信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function queryGdxx({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/intermediary/appraise/queryGdxx`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc 被执行人信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function queryLegalZhixing({ pageNum, pageSize, qyxxId }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryLegalZhixing`,
        {
            pageNum,
            pageSize,
            qyxxId
        }
    );
}

/**
 * @desc 裁决信息
 * @param companyName
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function queryZgcpwsw({ companyName, qyxxId, pageNum, pageSize }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryLegalZgcpwsw`,
        {
            companyName,
            pageNum,
            pageSize,
            qyxxId
        }
    );
}

/**
 * @desc  三年过会情况占比
 * @param companyName
 */
export function queryGhCount({ companyName }) {
    return request(`${API.V1}/investbank/intermediary/appraise/queryGhCount`, {
        companyName
    });
}

/**
 * @desc  查询处罚信息
 * @param qyxxId
 */
export function queryRiskPunish({ qyxxId }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryRiskPunish`,
        { qyxxId }
    );
}

/**
 * @desc  业务能力查询
 * @param agency
 */
export function queryIntermediaryData({ agency }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryIntermediaryData`,
        { agency }
    );
}

/**
 * @desc  近三年公司情况
 * @param agency
 * @param pageNum
 * @param pageSize
 */
export function queryZjAgencyService({ agency, pageNum, pageSize }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryZjAgencyService`,
        {
            agency,
            pageNum,
            pageSize
        }
    );
}

/**
 * @desc 合法性数量
 * @param qyxxId
 * @param companyName
 */
export function queryHfxCount(qyxxId, companyName) {
    return request(`${API.V1}/investbank/intermediary/appraise/queryHfxCount`, {
        companyName,
        qyxxId
    });
}

///investbank/intermediary/appraise/queryLegalDishonesty

/**
 * @desc  失信被执行人
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 * @param companyName
 */
export function getLostTrustee({ qyxxId, pageNum, pageSize, companyName }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/queryLegalDishonesty`,
        {
            qyxxId,
            pageNum,
            pageSize,
            companyName
        }
    );
}
