import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

// 删除专家资料
export function callDeleteExpert(type, id) {
    return request(`${API.V1}/sso/user/expert/delExpertData`, {
        id,
        type
    });
}

// 展示专家资料
export function callShowExpert() {
    return request(`${API.V1}/sso/user/expert/showExpertData`);
}

// 修改专家资料
export function callModifyExpert(data) {
    return request(
        `${API.V1}/sso/user/expert/updateExpertData`,
        {
            ...data
        },
        {
            method: 'post'
        }
    );
}
