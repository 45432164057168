import React, { useState, useEffect } from 'react';
import LeftSecond from './IPODeclarationDetailsList';

/**
 * @desc  IPO申报情况
 */
export default function LeftSecondIndex({ address, style }) {
  return (
    <LeftSecond address={address} />
  );
}
