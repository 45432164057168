/**
 * @desc  科创钻石指数对比
 */
import styles from './style.scss';
import React, { useState, useEffect } from 'react';
import TrianglePillar from '../../../echarts/options/TrianglePillar';
import { Select } from 'antd';
import { getCompare, getMain } from './service';

const { Option } = Select;

export default function KCDiamondChart({ style = {} }) {
  const [selectData, setSelectData] = useState([]);
  const [main, setMain] = useState(null);
  let [seriesData, setSeriesData] = useState([]);
  let [xData, setXData] = useState([]);
  function compare() {
    getCompare({ main }).then(res => {
      if (res.success) {
        xData = [];
        seriesData = [];
        res.data.forEach(item => {
          xData.push(item.regProvince);
          seriesData.push(item.score);
        });
        setXData([...xData]);
        setSeriesData([...seriesData]);
      }
    });
  }

  useEffect(() => {
    getMain().then(res => {
      if (res.success) {
        setSelectData([...res.data]);
        setMain(res.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    main && compare();
  }, [main]);
  return (
    <div className={styles.kCDiamondChart} style={{ ...style }}>
      <div className="clearfix" style={{ height: '12%' }}>
        <Select
          style={{ width: 140, heiht: 24 }}
          className="fr"
          value={main}
          onChange={v => {
            setMain(v);
          }}
        >
          {selectData.map(item => (
            <Option key={item} value={item} title={item}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
      <div style={{ height: '88%' }}>
        <TrianglePillar seriesData={seriesData} xData={xData} />
      </div>
    </div>
  );
}
