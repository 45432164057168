/**
 * @author Ray
 * @description 提交， 保存
 */

import React from 'react';
import { Row, Button } from 'antd';
import styles from './footer.scss';
import Modal from '../../../common/modalEx';

export default function Footer({ save, submit }) {
    return (
        <Row className={styles.footer}>
            <Button onClick={save}>保存</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={submit} type={'primary'}>
                提交
            </Button>
            <Modal title={'提示'} visible={false}>
                成功
            </Modal>
        </Row>
    );
}
