import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { loadingOption } from '../theme';

/**
 * @desc 折线图
 */
export default class AreaStyleLine extends PureComponent {
    getOption() {
        const {
            xAxis = [],
            series = [],
            yAxisFormatter = v => v,
            showYname,
            grid,
            mode
        } = this.props;

        const a = [];
        xAxis.map((item, index) => {
            if (item.year && item.year.indexOf(mode) !== -1) {
                a.push(item);
            }
        })

        return {
            legend: {
                show: true,
                top: '20',
                data: series.map(ele => ele.name),
                itemGap: 30
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                padding: [10, 20]
            },
            grid: {
                top: '90',
                left: '6%',
                right: '7%',
                ...grid
            },
            xAxis: {
                type: 'category',
                data: a && a.length > 0 ? a.map(ele => ele.year) : xAxis,
            },
            yAxis: [
                {
                    type: 'value',
                    name: showYname && '高风险\n企业数量',
                    axisLabel: {
                        formatter: yAxisFormatter
                    }
                },
                {
                    type: 'value',
                    name: showYname && '高风险\n企业占比',
                    axisLabel: {
                        show: true,
                        formatter: '{value}%'
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: a && a.length > 0 ? [
                {
                    name: "高风险企业数量",
                    type: "line",
                    data: a.map(ele => ele.highNum)
                },
                {
                    name: "高风险企业占比(%)",
                    type: "line",
                    yAxisIndex: 1,
                    data: a.map(ele => ele.highPoint)
                }
            ] : series
        };
    }
    render() {
        const { style = { height: '380px' }, loading, xAxis } = this.props;
        return (
            <div style={style}>
                {loading ? (
                    ''
                ) : xAxis && xAxis.length ? (
                    <ReactEcharts
                        option={this.getOption()}
                        notMerge={true}
                        style={{ height: '100%' }}
                        loadingOption={loadingOption}
                        theme={'myTheme'}
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}  />
                )}
            </div>
        );
    }
}
