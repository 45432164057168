/**
 * @author Ray
 * @desc 侧边栏组件
 */
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Icon, Menu } from 'antd';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import useStorage from '../../hooks/useStorage';
import { isNotEmpty } from '../../utils/utils';
import { GlobalContext } from '../globalStore/createProvider';

const { Item } = Menu;

/**
 *
 * @param match
 * @param history
 * @param location
 * @param routes
 * @returns {*}
 * @constructor
 */
export default function SideBar({ history, location, Routes }) {
    const { user } = useContext(GlobalContext);
    const [selectedKeys, setSelectedKeys] = useState(function() {
        let selectedKey = getSelectedKey(location);

        return [`/${selectedKey}`];
    });

    function getSelectedKey(location) {
        let { pathname } = location,
            pathKeys = pathname.split('/');
        return pathKeys[pathKeys.length - 1];
    }

    function handleClick(path) {
        setSelectedKeys([path]);
        history.push({ pathname: path });
    }

    useEffect(function() {
        const listen = history.listen((location, action) => {
            let key = getSelectedKey(location);
            setSelectedKeys([`/${key}`]);
        });

        return () => {
            listen();
        };
    }, []);

    return (
        <Menu className={styles.menuRoot} selectedKeys={selectedKeys}>
            {Routes.map(
                ({ path, name, icon, badge, visible, permissionKey, key }) => {
                    return visible ? (
                        isNotEmpty(permissionKey) ? (
                            permissionKey.includes(user?.type) ? (
                                <Item
                                    key={key}
                                    className={styles.menuItem}
                                    onClick={() => handleClick(path)}
                                >
                                    <Icon
                                        component={icon}
                                        className={styles.menuIcon}
                                    />
                                    <span>{name}</span>
                                    {badge && <Badge count={2} />}
                                </Item>
                            ) : null
                        ) : (
                            <Item
                                key={key}
                                className={styles.menuItem}
                                onClick={() => handleClick(path)}
                            >
                                <Icon
                                    component={icon}
                                    className={styles.menuIcon}
                                />
                                <span>{name}</span>
                                {badge && <Badge count={2} />}
                            </Item>
                        )
                    ) : null;
                }
            )}
        </Menu>
    );
}

SideBar.defaultProps = {
    Routes: []
};

SideBar.propTypes = {
    Routes: PropTypes.array
};
