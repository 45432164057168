import React, { useEffect, useState } from 'react';
import StockRiskPie from './StockRiskPie';


/**
 * @desc 左边第三个区块
 * @returns {*}
 * @constructor
 */
export default function LeftThirdIndex({ address, style }) {
  return (
    <StockRiskPie address={address} />
  );
}
