import { useContext, useState, useRef } from 'react';
import { loginApi } from '../service';
import { StatusCode } from '../../../constants/enum';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { getLoginUserInfo } from '../../userCenter/services';
import { message } from "antd";

export default function (type) {
    const { user, setUser, setPasscodeExpired } = useContext(GlobalContext);
    const [visibleMsg, setVisible] = useState({ visible: false, msg: '' });
    const codeEl = useRef(null);

    const handleClose = () => {
        setVisible({ ...visibleMsg, visible: false });
    };
    const toLogin = (data, history) => {
        loginApi(data).then(resp => {
            if (resp.statusCode === StatusCode.pswExpired) {
                setPasscodeExpired(true);
                message.warn(resp.message);
                localStorage.setItem('localLoginName', data.username);
                // setLocalLoginName(data.username);
                return history.push('/userCenter/modifyPassword');
            }
            if (resp.statusCode === StatusCode.success) {
                getLoginUserInfo().then(res => {
                    setUser(res.data);
                    let url = resp.data.needChangePwd ? '/userCenter/modifyPassword' : '/policy';
                    history.push(url);
                });
            } else {
                codeEl && codeEl.current.reset();
                setVisible({ visible: true, msg: resp.message });
            }
        });
    };

    return { codeEl, visibleMsg, setVisible, handleClose, toLogin };
}
