import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { riskTrends } from './service';
import { Empty } from 'antd';
/**
 * @desc 风险变化趋势
 */

global.riskTrendLineChart = null;

// address === '全国' ? 'all' : address
export default function RiskTrendLine({ address, style }) {
    useEffect(() => {
      riskTrends({ area: address }).then(({ success, data }) => {
        if (success == true) {
          if(global.riskTrendLineChart == null){
            global.riskTrendLineChart = new Chart({
              container: 'RiskTrendLine',
              autoFit: true,
              height: 210,
              padding:[20,20,20,20]
            });

            global.riskTrendLineChart.data(data);
            global.riskTrendLineChart.line().position('item*score');
            global.riskTrendLineChart.area().position('item*score');
            global.riskTrendLineChart.point().position('item*score').label('score');

            global.riskTrendLineChart.scale('item', {
              tickCount: 8,
            });

            global.riskTrendLineChart.scale('score',{
              min : 0,
              // max : 35,
              nice: true,
            });
            global.riskTrendLineChart.tooltip(false);
            global.riskTrendLineChart.legend(false); 
            global.riskTrendLineChart.render();

          }else{
            global.riskTrendLineChart.clear();
            global.riskTrendLineChart.changeData(data);
            global.riskTrendLineChart.line().position('item*score');
            global.riskTrendLineChart.area().position('item*score');
            global.riskTrendLineChart.point().position('item*score').label('score');

            global.riskTrendLineChart.scale('item', {
              tickCount: 3,
            });
            global.riskTrendLineChart.scale('score',{
              min : 0,
              // max : 35,
              nice: true,
            });
            global.riskTrendLineChart.tooltip(false);
            global.riskTrendLineChart.legend(false); 
            global.riskTrendLineChart.render();
          }
        } else {
          <Empty />
        }
      })
    }, [address])
    
    return (
      <div id='RiskTrendLine' className={ styles.RiskTrendLine }></div>
    )
  
}