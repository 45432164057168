/**
 * @author hjie
 * 课程目录
 */

import React, { useState } from 'react';
import styles from './styles.scss';
import { Collapse, Icon, Modal, Empty } from 'antd';
import classnames from 'classnames';
import { youkuPattern } from 'utils/validator';

const { Panel } = Collapse;

export default function ({ detailInfo, isCourse, history }) {

    const [visible, setVisible] = useState(false);
    const [url, setUrl] = useState(''); // https://v.youku.com/v_show/id_XNDQwNzk2NjIzNg==.html?spm=a2h3j.8428770.3416059.1
    function showModal(content) {
        let url = youkuPattern(content);
        setVisible(true);
        if(url && url.indexOf('https') === -1) {
            url = url.replace('http','https');
        }
        setUrl(url);
    }

    function toArticleDetail(index, i) {
        !isCourse && history.push({
            pathname:`/listingTraining/articleDetail/${detailInfo.id}/${index}/${i}`,
            state:{
                from: history.location.pathname
            }
        });
    }

    const { chapters = [] } = detailInfo;
    return (
        <div className={styles.columnCatalog}>
            {
                chapters && chapters.length ?
                    <>
                        <Collapse bordered={false} expandIconPosition="right" defaultActiveKey='0'>
                            {
                                chapters && chapters.map((ele, index) => {
                                    const { name, sections = [], num } = ele;
                                    return <Panel header={`第${num}章 ${name}`} key={index}>
                                        {
                                            sections.map((item, i) => {
                                                return <p key={i} onClick={() => toArticleDetail(index, i)} className={classnames({ [styles.linkBox]: !isCourse })}>
                                                    <span>{item.name} ({item.num})</span>
                                                    {
                                                        isCourse && <Icon type="play-circle" theme="filled" className={styles.playBtn} onClick={() => showModal(item.content)} />
                                                    }
                                                </p>;
                                            })
                                        }

                                    </Panel>;
                                })
                            }
                        </Collapse>
                        <Modal
                            visible={visible}
                            footer={null}
                            width={1200}
                            onCancel={() => setVisible(false)}
                            maskClosable={false}
                            className="playModal"
                            destroyOnClose={true}
                        >
                            {
                                url ? <iframe height="600" width="1200" src={url} frameBorder={0}></iframe> : <div className = "noData">播放地址无效</div>
                            }
                        </Modal>
                    </> :
                    <Empty />
            }
        </div>
    );
}
