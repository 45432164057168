import { transferStringNull } from '../../../../../utils/utils';

export const qualificationColumns1 = [
    {
        dataIndex: 'name',
        title: '申请已通过项目',
        align: 'center',
        width: '50%',
        render: transferStringNull
    },
    {
        dataIndex: 'type',
        title: '资质类型',
        align: 'center',
        width: '50%',
        render: transferStringNull
    }
];

export const qualificationColumns2 = [
    {
        dataIndex: 'name',
        title: '申请未通过项目',
        align: 'center',
        width: '50%',
        render: transferStringNull
    },
    {
        dataIndex: 'type',
        title: '资质类型',
        align: 'center',
        width: '50%',
        render: transferStringNull
    }
];
