/**
 * 专栏文章详情
 * @author hjie
 */

import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import BackBtn from 'common/backBtn';
import { Tree } from 'antd';
import { StatusCode } from 'constants/enum';
import { getColumnInfo } from '../../service';
import moment from 'moment';
import { Link } from 'react-router-dom';
const { TreeNode } = Tree;

let fromRoute;

export default function(props) {
  const { history, match } = props;
  const { id, chapter, section } = match.params;
  const [detailInfo, getDetailInfo] = useState(null);
  const [chooseIndex, setIndex] = useState([chapter, section]);

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    fromRoute = fromRoute || history.location?.state?.from;
  }, [props]);

  function getDetail() {
    getColumnInfo({ id }).then(res => {
      if (res.statusCode === StatusCode.success) {
        getDetailInfo(res.data);
      }
    });
  }

  const treeChange = ee => {
    const treeActive = ee[0].split('-');
    setIndex(treeActive);
  };

  function renderDom(url) {
    if (!url) {
      return null;
    }
    const arr = url.split('.');
    let isPdf = arr[arr.length - 1].indexOf('pdf') !== -1;
    return isPdf ? (
      <a href={`//file.beijingipo.com.cn/${encodeURIComponent(url)}`}>
        附件下载
      </a>
    ) : (
      <Link
        to={{
          pathname: `/listingTraining/columnAttachment/${encodeURIComponent(
            '//file.beijingipo.com.cn/' + url
          )}`,
          state: {
            from: history.location.pathname
          }
        }}
      >
        附件查看
      </Link>
    );
  }

  if (detailInfo) {
    const { title, gmtModify, chapters = [] } = detailInfo;
    const { content, url } = chapters[chooseIndex[0]].sections[chooseIndex[1]];
    // let fileUrl = '';
    // if(url){
    //     fileUrl = JSON.parse(url).fileUrl;
    // }
    return (
      <div className={styles.articleDetail}>
        <BackBtn
          history={history}
          url={fromRoute||'/listingTraining/index'}
          callback={() => {
            fromRoute = undefined;
          }}
        />
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <Tree
              onSelect={treeChange}
              defaultExpandAll
              defaultSelectedKeys={[chooseIndex.join('-')]}
            >
              {chapters.map((ele, index) => {
                const { name, sections = [], num } = ele;
                return (
                  <TreeNode
                    title={`第${num}章 ${name}`}
                    key={index}
                    selectable={false}
                  >
                    {sections.map((item, i) => {
                      return (
                        <TreeNode
                          title={`${item.name}(${item.num})`}
                          key={`${index}-${i}`}
                        ></TreeNode>
                      );
                    })}
                  </TreeNode>
                );
              })}
            </Tree>
          </div>
          <div className={styles.main}>
            <div className={styles.title}>
              <h3>{title || '- -'}</h3>
              <span className={styles.date}>
                {moment(gmtModify).format('YYYY年MM月DD日')}
              </span>
            </div>
            {chapters.length && (
              <div className={styles.text}>
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{ marginBottom: '20px' }}
                ></div>
                {renderDom(url)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
