/**
 * @author ray
 * @desc 上市咨询的详情页
 */

import React, { useEffect } from 'react';
import Require from '../require';
import Material from '../material';
export default function Detail(props) {
    useEffect(function() {
        props.setBannerShow(false);
        props.setPreviewStatus(true);
    }, []);

    return (
        <div>
            <Require isPreview {...props} />
            <Material isPreview {...props} />
        </div>
    );
}
