import request from 'utils/fetch';
import { API } from 'constants/enum';

/**
 * @description 内控规范
 * @param
 */

//内控规范填写
export function dataUpdate(param) {
    return request(
        `${API.V1}/auth/company/data/update`,
        param,
        { method: 'POST' }
    );
}

//内控规范详情
export function getByCreditCode(param) {
    return request(
        `${API.V1}/auth/company/data/get`,
        param,
    );
}
