/**
 * @desc  在审申请挂牌企业基本情况一览
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import List from './list';

export default function Line10Index() {
    
    return (
        <div className={ styles.line10 }>
            <Title />
            <List />
        </div>
        
    );
}
