import style from './style.scss';
import React from 'react';
import { Typography } from 'antd';
import qrcode from './images/qrcode.png';
import phone from './images/电话.png';
import email from './images/邮箱.png';
import fax from './images/传真.png';
import location from './images/地址.png';
import classnames from 'classnames';
/**
 * @desc 底部
 */

export default function(props) {
    return (
        <div
            className={props.location.pathname === '/user/login' ? style.footers : classnames(style.footer, {
                [style.balackHeader]: props.balackHeader
            })}
        >
            <div className={style.top}>
                <div className={style.left}>
                    <div className={style.itemBox}>
                        <a href="https://www.beijingipo.com.cn/gywm">
                            关于我们
                        </a>
                        <a href="https://www.beijingipo.com.cn/lxwm">
                            联系我们
                        </a>
                        <a href="https://www.beijingipo.com.cn/jrwm">
                            加入我们
                        </a>
                        <a href="https://www.beijingipo.com.cn/flsm">
                            法律声明
                        </a>
                    </div>
                    <div className={style.bottom}>
                        版权所有：北京启元资本市场发展服务有限公司
                      
                        <a
                            href="http://www.beian.miit.gov.cn"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            备案号：京ICP备1904568号
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <i>
                            联网备案号：11010802031386
                        </i>
                        <br/>
                        <p>建议使用1920*1080分辨率和Chrome浏览器，带来更好的网站使用体验</p>
                    </div>

                </div>
                {/* <div className={style.center}>
          <Typography>
            <img src={phone} alt='phone' />
            &nbsp;&nbsp; 电话：010-83453504
          </Typography>
          <Typography>
            <img src={fax} alt='phone' />
            &nbsp;&nbsp; 传真：010-83453000
          </Typography>
          <Typography>
            <img src={email} alt='phone' />
            &nbsp;&nbsp; 邮箱：service@beijingipo.com.cn
          </Typography>
          <Typography>
            <img src={location} alt='phone' />
            &nbsp;&nbsp; 地址：北京市海淀区西三环北路甲2号院7号楼9层
          </Typography>
        </div> */}
                <div className={style.right}>
                    <div className={style.qrcode}>
                        <img src={qrcode} alt="qrcode" />
                    </div>
                    {/* <Typography>技术支持：010-83453501</Typography> */}
                </div>
            </div>
        </div>
    );
}
