import React, {useEffect, useState} from 'react';
import {
    neeqIpoCompanyCount,
    neeqIpoCompanyCountOfArea,
    neeqIpoCompanyCountOfIndustry
} from "./service";
import {Chart} from "@antv/g2";
import {Empty} from "antd";
import Radios from "./radio";
import style from './styles.scss'
import {limitString} from "../../../../utils/utils";

global.BarCharts = null;

export default function EquityIssuance() {

    const [type, setType] = useState(2);
    const radiosData = [
        { name: '企业家数', value: 2 },
        { name: '行业分布', value: 3 },
        { name: '地域统计', value: 4 },
    ];

    useEffect(() => {
        //请求股票发行企业家数量数据
        type == 2 ? neeqIpoCompanyCount().then(({ success, data }) => {
                if (success == true) {
                    if (global.BarCharts == null) {
                        global.BarCharts = new Chart({
                            container: 'BarCharts',
                            autoFit: true,
                            height: 190,
                            padding:[20,70,100,70]
                        });

                        global.BarCharts.data(data);
                        global.BarCharts.scale('amount', {
                            nice: true,
                        });

                        global.BarCharts.tooltip({
                            showMarkers: false
                        });
                        global.BarCharts.interaction('active-region');

                        global.BarCharts.interval()
                            .position('item*amount')
                            .label('amount')
                        global.BarCharts.render();
                    } else {
                        global.BarCharts.clear();
                        global.BarCharts.changeData(data);

                        global.BarCharts.scale('amount', {
                            nice: true,
                        });

                        global.BarCharts.tooltip({
                            showMarkers: false
                        });
                        global.BarCharts.interaction('active-region');

                        global.BarCharts.interval()
                            .position('item*amount')
                            .label('amount')
                        global.BarCharts.render();
                    }
                } else {
                    <Empty />
                }
            }) :
            //请求股票发行行业分布数据
            type == 3 ? neeqIpoCompanyCountOfIndustry().then(({ success, data }) => {
                    if (success == true) {
                        data=data.map((ele)=>{
                            return{
                                amount:ele['amount'],
                                item:limitString(2,ele['item'])
                            }
                        });
                        console.log('data',data);
                        global.BarCharts.clear();
                        global.BarCharts.changeData(data);

                        global.BarCharts.scale('amount', {
                            nice: true,
                        });

                        //global.BarCharts.axis('item',false);

                        global.BarCharts.tooltip({
                            showMarkers: false
                        });
                        global.BarCharts.interaction('active-region');

                        global.BarCharts.interval()
                            .position('item*amount')
                            .label('amount')
                        global.BarCharts.render();
                    } else {
                        <Empty />
                    }
                }) :
                //请求股票发行地域分布数据
                type == 4 ? neeqIpoCompanyCountOfArea().then(({ success, data }) => {
                    if (success == true) {
                        global.BarCharts.clear();
                        global.BarCharts.changeData(data);

                        global.BarCharts.scale('amount', {
                            nice: true,
                        });

                        global.BarCharts.tooltip({
                            showMarkers: false
                        });
                        global.BarCharts.interaction('active-region');

                        global.BarCharts.interval()
                            .position('item*amount')
                            .label('amount')
                        global.BarCharts.render();
                    } else {
                        <Empty />
                    }
                }) : <Empty />
    }, [type])

    return(
        <div>
            <div className={style.divTop}>
                <div className={style.divTitle}>
                    股票发行情况统计
                </div>
                <div className={style.divRadio}>
                    <Radios
                        value={type}
                        data={radiosData}
                        defaultValue={1}
                        onChange={e => setType(e.target.value)}
                    />
                </div>
            </div>
            <div id='BarCharts' className={style.divChart}></div>
        </div>
    )
}
