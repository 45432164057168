/**
 * @desc 发送邮件
 * @returns {Promise<unknown>}
 */
import request from '../../utils/fetch';
import { API } from '../../constants/enum';

export function callSendEmail(email) {
    return request(`${API.V1}/sso/email/send`, {
        email
    });
}
