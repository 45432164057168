import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';


//科创钻石指数横向对比
export function getCompare(params) {
    return request(`${API.V1}/auth/red/diamond/compare`,params);
}

//科创钻石主题列表
export function getMain(params) {
    return request(`${API.V1}/auth/red/diamond/main`,params);
}
