import fetch from 'utils/fetch';
import { API, BasePost } from 'constants/enum';

// 分页获取处置记录
export function getNotes(data) {
    return fetch(`${API.V1}/risk/note/pages`, data, BasePost);
}

// 删除处置记录
export function deleteNotes(data) {
    return fetch(`${API.V1}/risk/note/delete`, data);
}

// 新增或编辑处置记录
export function saveNotes(data) {
    return fetch(`${API.V1}/risk/note/save`, data, BasePost);
}

// 获取顶部基本信息
export function companyTop(data) {
    return fetch(`${API.V1}/risk/company/top`, data);
}

// 用户关注公司
export function focusCompany(data) {
    return fetch(`${API.V1}/risk/company/focus`, data);
}


