/**
 * @desc 退市公司和挂牌公司分布对比title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line5Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line5Title }>
                退市公司和挂牌公司分布对比
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}