/**
 * @author Ray
 */
import React from 'react';
import { logout } from './service';

export default class extends React.Component {
    componentDidMount() {}

    logout = () => {
        return logout();
    };

    render() {
        return this.props.children({
            ...this.props,
            ...this.state,
            logout: this.logout
        });
    }
}
