/**
 * @author Ray
 */
import React, { useContext } from 'react';
import { LEVELS } from '../../../constants/enum';
import styles from './styles.scss';
import LogoutSvg from '../images/exit.svg';
import { Avatar, Badge, Icon, Tag, Typography, Popover } from 'antd';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import qrcode_test from '../images/qrcode_test.png';
import qrcode_pub from '../images/qrcode_dev.png';
import { LimitNumber } from "../../../utils/utils";

export default function (props) {
    const { history, store, location } = props;
    const { user, setUser, isTest } = useContext(GlobalContext);

    function handleLogout() {
        props.logout().then(function () {
            setUser(null);
            history.push({
                pathname: '/user/login',
                state: { from: location.pathname }
            });
        });
    }

    function onBellClick() {
        !(user?.needChangePwd) && history.push({ pathname: '/userCenter' });
    }

    return (
        <div className={styles.userRoot}>
            <Popover
                content={
                    <img
                        style={{ width: 120, height: 120 }}
                        src={isTest ? qrcode_test : qrcode_pub}
                    />
                }
            >
                {/*<span className={styles.qrcodeBox}>*/}
                {/*    <Icon type={'qrcode'} className={styles.qrcode} />*/}
                {/*    <span>下载app</span>*/}
                {/*</span>*/}
            </Popover>
            <Badge
                key="message"
                className={styles.messageBell}
                dot={!store.noUnreadMessage}
            >
                <Icon
                    type="bell"
                    style={{ fontSize: 20, color: 'white' }}
                    onClick={onBellClick}
                />
            </Badge>
            <Avatar
                onClick={() =>
                    history.push({ pathname: user?.needChangePwd ? '/userCenter/modifyPassword' : '/userCenter/modifyUser' })
                }
                className={styles.avatar}
                icon={!user?.avatar && 'user'}
                src={user?.avatar ? user?.avatar : undefined}
                size={32}
            />
            <Typography.Text className={styles.userName}>
                {LimitNumber(user?.username, 5)}
            </Typography.Text>
            {user?.level >= 2 && (
                <Tag color="green">{LEVELS[user?.level - 1]}</Tag>
            )}
            <Icon
                component={LogoutSvg}
                onClick={handleLogout}
                className={styles.logout}
            />
        </div>
    );
}
