/**
 * @author Ray
 */
import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import {
    historyApply,
    applyAuth,
    deleteApply,
    submitSavedApply,
    withdrawSavedApply
} from './service';
import { message } from 'antd';
import { GlobalContext } from '../../../common/globalStore/createProvider';

export default function Model({ children, ...rest }) {
    const { user } = useContext(GlobalContext);
    const [state, setState] = useState({
        dataSource: [],
        totalPage: 0,
        totalCount: 0,
        pageNum: 0,
        pageSize: 5,
        tableConditions: {
            applyStatus: undefined,
            pageNum: 1,
            pageSize: 5,
            applyStage: undefined,
            stage: undefined,
            status: undefined
        }
    });

    useEffect(() => {
        user && user?.creditCode && getApplyHistory(state.tableConditions);
    }, []);

    /**
     * @description 获取申请历史记录
     */
    function getApplyHistory(cds) {
        let conditions = Object.assign({}, state.tableConditions, { ...cds });

        historyApply({ ...conditions, code: user?.creditCode }).then(res => {
            if (!res.success || isEmpty(res?.data)) {
                return setState({
                    dataSource: [],
                    totalPage: 0,
                    totalCount: 0,
                    pageNum: cds.pageNum,
                    pageSize: cds.pageSize,
                    tableConditions: {
                        ...state.tableConditions,
                        ...cds
                    }
                });
            }
            const { data, pageNum, pageSize, pageCount, totalCount } = res.data;
            setState({
                dataSource: data,
                totalPage: pageCount,
                totalCount: totalCount,
                pageNum,
                pageSize,
                tableConditions: {
                    ...state.tableConditions,
                    ...cds,
                    pageNum,
                    pageSize
                }
            });
        });
    }

    /**
     * @description 检查是否可以进行申请
     */
    function checkApplyAuth() {
        return applyAuth(user?.creditCode).then(res => {
            if (res?.data?.length === 1) {
                return rest.history.push({
                    pathname:
                        rest.match.url + `/fillin/apply/${res.data[0].stage}`
                });
            }

            return {
                canApply: res.success && isNotEmpty(res.data),
                userStages: res.data
            };
        });
    }

    /**
     * @description 删除申请历史
     */
    function deleteApplys({ code, id, stage, status, type }) {
        return deleteApply(code, id, stage, status, type).then(res => {
            if (res.success) {
                getApplyHistory(state.tableConditions);
            } else {
                message.error(res.message);
            }
        });
    }

    /**
     * @desc 撤销申请
     * @param companyName
     * @param id
     * @returns {*}
     */
    function withdrawApply({ companyName, id }) {
        return withdrawSavedApply(companyName, id).then(res => {
            if (res.success) {
                getApplyHistory(state.tableConditions);
            } else {
                message.error(res.message);
            }
        });
    }

    /**
     * @desc 提交保存
     * @param companyName
     * @param id
     * @returns {*}
     */
    function commitSavedApply({ companyName, id }) {
        return submitSavedApply(companyName, id).then(res => {
            if (res.success) {
                getApplyHistory(state.tableConditions);
            } else {
                message.error(res.message);
            }
        });
    }

    return children({
        ...rest,
        ...state,
        getApplyHistory: getApplyHistory,
        checkApplyAuth: checkApplyAuth,
        commitSavedApply: commitSavedApply,
        withdrawApply: withdrawApply,
        deleteApply: deleteApplys
    });
}
