import React, { useState } from 'react';
// import {Table} from 'antd';
import { RelationColumns } from './columns';
import { queryExpertAndCommittee } from '../service';
// import { isNotEmpty } from '../../../utils/utils';
import Table from '../../../common/tableEx';
import Project from './project';
import Title from '../../../common/title';
export default function Relation(props) {
  const [query] = useState({
    expertFirm: props.detail.expertFirm,
    expert: props.detail.expertTeam,
    pageNum: 1,
    pageSize: 10,
    firmName: props.detail.firmName
  });
  // const [data, setData] = useState([]);
  // useEffect(function () {
  //     queryExpertAndCommittee({  }).then(data => {
  //         if(data.statusCode == 1000){
  //             setData(data.data)
  //         }
  //     });
  // }, [])
  return (
    <div style={{background:'white',padding:'8px 16px'}}>
      <Title level={2} text="与发审委委员对应关系" />
      <Table
        query={query}
        getData={queryExpertAndCommittee}
        columns={RelationColumns}
        parseData={res => (res && res.data ? res.data : [])}
        // dataSource={data}
      />
    </div>
  );
}
