import React, { useEffect, useState } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import styles from './styles.scss';
import { strInsert } from 'utils/utils';
import { getData } from './service';
import { Empty } from 'antd';
import Radios from '../listOfEnterprises/radio';
/**
 * @desc 中介信息概览
 * @returns {*}
 * @constructor
 */
const xAxisStyle = {
  axisLabel: {
    color: '#fff'
  },
  axisLine: {
    lineStyle: {
      color: 'rgba(86,197,253,0.3)'
    }
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: 'rgba(86,197,253,0.3)'
    }
  }
};

export default function Intermediary({ address, style }) {
  const radiosData = [
    { name: '保荐机构', value: 1 },
    { name: '会计师事务所', value: 3 },
    { name: '律师事务所', value: 2 }
  ];
  const [type, setType] = useState(1);
  const [options, setOptions] = useState({ loading: true, data: null });

  useEffect(() => doQuery(type), [address]);
  function doQuery(type) {
    getData({
      type,
      area: address
    }).then(({ success, data }) => {
      if (success) {
        if (!data || !data.length) {
          setOptions({ loading: false, data: null });
        } else {
          let option = getOptions(data);
          setOptions({ loading: false, data: option });
        }
      }
    });
  }

  function getOptions(data) {
    return {
      grid: {
        bottom: 99,
        left: 60,
        right: 20,
        top: 22
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        textStyle: {
          color: '#fff'
        },
        axisPointer: {
          type: 'shadow'
        },
        formatter: v => {
          const { name, value } = v[0];
          return `<div class = "bgPageTooltip">
                                <p class = "title">${name}: <b class = "num">${value}</b></p> 
                            </div>`;
        }
      },
      xAxis: [
        {
          ...xAxisStyle,
          type: 'category',
          data: data.map(ele => strInsert(ele.zqName, '\n', 3)),
          axisLabel: {
            color: '#fff',
            rotate: 45,
            margin: 14,
            fontSize: 12
          }
        }
      ],
      yAxis: {
        ...xAxisStyle,
        type: 'value'
      },
      series: [
        {
          type: 'bar',
          data: data.map(ele => ele.count),
          barMaxWidth: 20,
          label: {
            show: true,
            position: 'top',
            color: '#fff'
          },
          itemStyle: {
            barBorderRadius: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#0E90E8'
              },
              {
                offset: 1,
                color: '#023462'
              }
            ])
          }
        }
      ]
    };
  }

  return (
    <div className={styles.mapContainer} style={{ ...style }}>
      <div className={`clearfix ${styles.listOfEnterprisesRadio}`}>
        <div className="fr">
          <Radios
            value={type}
            data={radiosData}
            defaultValue={1}
            onChange={t => {
              setType(t.target.value);
              doQuery(t.target.value);
            }}
          />
        </div>
      </div>
      {options.loading ? (
        ''
      ) : options.data ? (
        <ReactEcharts
          theme={'myTheme'}
          style={{
            height: '100%'
          }}
          option={options.data}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
}
