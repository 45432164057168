import moment from 'moment';
import { Tooltip } from 'antd';
import { TimeFormat } from '../../../../constants/enum';
import { limitString, moneyFormatter } from '../../../../utils/utils';
import React from 'react';

export const listedWelfareColumns = [
    {
        key: 'index',
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        width: '10%',
        render: (text, record, index) => index + 1
    },
    {
        key: 'activityTime',
        title: '活动时间',
        dataIndex: 'activityTime',
        align: 'center',
        width: '15%',
        render: text => moment(text, 'x').format(TimeFormat)
    },
    {
        key: 'activityContent',
        title: '活动内容',
        dataIndex: 'activityContent',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    },
    {
        key: 'joinCompany',
        title: '参与公司',
        dataIndex: 'joinCompany',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    }
];

export const charitableActivitiesColumns = [
    {
        key: 'index',
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        width: '10%',
        render: (text, record, index) => index + 1
    },
    {
        key: 'activityTime',
        title: '活动时间',
        dataIndex: 'activityTime',
        align: 'center',
        width: '15%',
        render: (text, record, index) => moment(text, 'x').format(TimeFormat)
    },
    {
        key: 'activityContent',
        title: '活动内容',
        dataIndex: 'activityContent',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    },
    {
        key: 'joinCompany',
        title: '参与群体',
        dataIndex: 'joinCompany',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    }
];

export const donationColumns = [
    {
        key: 'index',
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        width: '10%',
        render: (text, record, index) => index + 1
    },
    {
        key: 'donationTarget',
        title: '捐赠对象',
        dataIndex: 'donationTarget',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    },
    {
        key: 'donationMoney',
        title: '捐赠金额',
        dataIndex: 'donationMoney',
        align: 'center',
        width: '25%',
        render: v => (v ? moneyFormatter(v) : v) + '万'
    },
    {
        key: 'donationTime',
        title: '捐赠时间',
        dataIndex: 'donationTime',
        align: 'center',
        width: '25%',
        render: text => moment(text, 'x').format(TimeFormat)
    }
];

export const bailoutColumns = [
    {
        key: 'index',
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        width: '10%',
        render: (text, record, index) => index + 1
    },
    {
        key: 'joinReliefProject',
        title: '参与纾困项目',
        dataIndex: 'joinReliefProject',
        align: 'center',
        width: '25%',
        render: text => <Tooltip title={text}>{limitString(15, text)}</Tooltip>
    },
    {
        key: 'pushMoney',
        title: '出资金额',
        dataIndex: 'pushMoney',
        align: 'center',
        width: '25%',
        render: v => (v ? moneyFormatter(v) : v) + '万'
    },
    {
        key: 'pushMoneyRatio',
        title: '出资比例',
        dataIndex: 'pushMoneyRatio',
        align: 'center',
        width: '25%',
        render: v => (v ? v + '%' : '--')
    }
];
