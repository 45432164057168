import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @description 获取证监会所属行业
 * @param {string} srcIndustry
 */
export function getSscIndustry(srcIndustry) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/findSscIndustry`,
        {
            srcIndustry
        },
        {
            method: 'GET'
        }
    );
}

export function getFinancingIndustry(industrySubclass) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/findFinancingIndustry`,
        {
            industrySubclass
        },
        {
            method: 'GET'
        }
    );
}

/**
 * @desc 回显基础信息数据
 * @param code
 */
export function getBaseInfos(code) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/showBaseInfo`,
        {
            code
        }
    );
}
