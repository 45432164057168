import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Modal, Icon, Input, Pagination, Select } from 'antd';
import classnames from 'classnames';
import styles from './styles.scss';
import BasicInfo from '../basicInfo';
import Risk from '../risk';
import RalationChart from '../ralationChart';
import Industry from '../industry';
import Hooks from './hook';
import moment from 'moment';
import useAuthName from '../../../hooks/useAuthName';
import newRelationMap from '../newRelationMap'
import { BLOCKTYPE } from 'constants/common';

const { Option } = Select;
const { TextArea } = Input;

const FOCUS = {
  '1': '已关注',
  '2': '关注'
};

export const Routes = [
  {
    name: '公司风险详情',
    path: 'risk/:companyName/:companyId',
    key: 'risk',
    component: Risk,
    exact: false
  },
  {
    name: '同行业对比分析',
    path: 'industry/:companyName/:companyId',
    key: 'industry',
    component: Industry,
    exact: false
  },
  {
    path: 'ralationChart/:companyName/:companyId',
    key: 'ralationChart',
    //component: RalationChart,//newRelationMap
    component: newRelationMap,
    name: '公司图谱分析',
    exact: false
  },
  {
    // 首页
    name: '公司基础详情',
    path: 'basicInfo/:companyName/:companyId',
    key: 'basicInfo',
    component: BasicInfo,
    exact: false
  }
];
export default function(props) {
  const isAuth = useAuthName('11');
  const { location, match, history } = props;
  const pathnameArr = location.pathname.split('/');
  const companyName = pathnameArr[4];
  const qyxxId = pathnameArr[5];

  const {
    report,
    toFocus,
    notes,
    getNoteList,
    visible,
    setVisible,
    addNote,
    content,
    setContent,
    pageNum,
    setPagination,
    deleteItem,
    companyInfo,
    year,
    setYear
  } = Hooks(qyxxId, companyName);

  return (
    <div className={styles.detail}>
      <div className={classnames('box', styles.companyInfo)}>
        {companyInfo && (
          <Fragment>
            <div className={classnames('clearfix', styles.firstLine)}>
              <div className={classnames('fl', styles.title)}>
                <span className={styles.name}>{companyInfo.companyName}</span>
                <Select value={year} onChange={value => setYear(value)} getPopupContainer={() => document.getElementById('riskYear')} id = "riskYear">
                  {companyInfo.years.map(ele => {
                    return (
                      <Option key={ele} value={ele}>
                        {ele}
                      </Option>
                    );
                  })}
                </Select>
                <Button
                  disabled={companyInfo.isFocus === 1}
                  className={styles.focusBtn}
                  onClick={() => {
                    companyInfo.isFocus !== 1 && toFocus();
                  }}
                >
                  {FOCUS[companyInfo.isFocus]}
                </Button>
                <Button
                  className={styles.focusBtn}
                  onClick={() => {
                    setVisible(true);
                    getNoteList();
                  }}
                >
                  处置记录
                </Button>
                {isAuth && (
                  <Button
                    className={styles.focusBtn}
                    onClick={() => {
                      report();
                    }}
                  >
                    分析报告下载
                  </Button>
                )}
              </div>
              <div className={styles.btns}>
                {Routes.map(ele => {
                  const { name, path, key } = ele;
                  return pathnameArr[3] === key ? (
                    ''
                  ) : (
                    <Button
                      key={path}
                      onClick={() => {
                        history.push(
                          `/riskMonitoring/detail/${key}/${companyName}/${qyxxId}`
                        );
                      }}
                    >
                      {name}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div className={styles.table}>
              <table>
                <tbody>
                  <tr>
                    <td width = "70%">证券代码：{companyInfo?.stockCode || '- -'}</td>
                    <td>注册资本：{companyInfo?.regcap || '- -'}</td>
                  </tr>
                  <tr>
                    <td>上市板块：{BLOCKTYPE[companyInfo.block]}</td>
                    <td>成立日期：{companyInfo?.esdate || '- -'}</td>
                  </tr>
                  <tr>
                    <td>注册地址：<span className = "ellipsis" title = {companyInfo.address}>{companyInfo?.address || '- -'}</span></td>
                    <td>联系方式：{companyInfo?.phoneNumber || '- -'}</td>
                  </tr>
                  <tr>
                    <td>法定代表: {companyInfo?.frname || '- -'}</td>
                    <td>官网地址：{companyInfo?.homePage || '- -'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
      <Switch>
        {Routes.map(o => (
          <Route
            key={o.path}
            exact={o.exact}
            path={`${match.url}/${o.path}`}
            render={prop => (
              <o.component
                {...prop}
                year={year}
                stockCode={companyInfo?.stockCode || ''}
              />
            )}
          />
        ))}
        <Redirect from={match.url} to={`${match.url}/risk`} />
      </Switch>
      <Modal
        className='recordModal'
        title='处置记录'
        visible={visible}
        okText='保存'
        width='1000px'
        onOk={() => {
          addNote({
            companyName,
            content,
            qyxxId
          });
        }}
        onCancel={() => setVisible(false)}
      >
        <div style={{ marginBottom: '20px' }}>
          <ul className='list'>
            {notes.list.map(ele => {
              const { content, gmtCreate, id, delBy } = ele;
              return (
                <li className='clearfix' key={id}>
                  <div className='fl'>
                    <p className='text ellipsis' title={content}>
                      {content}
                    </p>
                    <p className='date'>
                      {moment(gmtCreate).format('YYYY-MM-DD HH:mm:ss')}{' '}
                      <span style={{ paddingLeft: '10px' }}>{delBy}</span>
                    </p>
                  </div>
                  <div className='fr'>
                    {/* <Icon type="edit" title = "编辑"/> */}
                    <Icon
                      type='delete'
                      title='删除'
                      onClick={() => deleteItem(id)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={styles.pageBox}>
            <Pagination
              onChange={page => setPagination(page)}
              current={pageNum}
              total={notes.total}
              hideOnSinglePage={true}
              size='small'
            />
          </div>
        </div>
        <TextArea
          rows={4}
          onChange={e => setContent(e.target.value)}
          value={content}
          maxLength={100}
          placeholder='请输入备注(最多100字)'
        />
      </Modal>
    </div>
  );
}
