/**
 * @author Ray
 */

import React, { useEffect } from 'react';
import Model from './model';
import View from './view';
export default function(props) {
    useEffect(() => {
        props.setBannerShow(true);
    }, []);
    return <Model {...props}>{prop => <View {...prop} />}</Model>;
}
