import moment from 'moment';
import { TimeFormat } from '../../../../../constants/enum';
import React from 'react';
import { transferStringNull } from '../../../../../utils/utils';
import { Tooltip } from 'antd';
export const executedPersonInformationColumns = [
    {
        dataIndex: 'registerString',
        title: '立案时间',
        align: 'center',
        render: transferStringNull,
        width: '20%'
        // render: text => moment(text).format(TimeFormat)
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execSubject',
        title: '执行标的',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execCourtName',
        title: '执行法院',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    }
];

export const trustExecutedPersonInformationColumns = [
    {
        dataIndex: 'pubdate',
        title: '发布日期',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'dishonestSituation',
        title: '具体情形',
        align: 'center',
        render: transferStringNull,
        width: '40%'
    },
    {
        dataIndex: 'execState',
        title: '履行情况',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execCourtName',
        title: '执行法院',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    }
];

export const businessAnomaliesColumns = [
    {
        dataIndex: 'busexcepList',
        title: '列入经营异常目录原因',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishOrg',
        title: '作出决定机关',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'rankString',
        title: '列入日期',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        dataIndex: 'removeBusexcepList',
        title: '移出经营异常名录原因',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        dataIndex: 'removeString',
        title: '移出日期',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const administrativePenaltyInformationColumns = [
    {
        dataIndex: 'name',
        title: '案件名称',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishCode',
        title: '处罚文号',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishType',
        title: '处罚类型',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishContent',
        title: '处罚结果',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishOrg',
        title: '处罚机关',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishString',
        title: '处罚日期',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const winningInformationColumns = [
    {
        dataIndex: 'name',
        title: '标题',
        align: 'center',
        render: transferStringNull
    },
    {
        dataIndex: 'money',
        title: '正文',
        align: 'center',
        render: transferStringNull
    },
    {
        dataIndex: 'round',
        title: '招标单位',
        align: 'center',
        render: transferStringNull
    },
    {
        dataIndex: 'date',
        title: '来源',
        align: 'center',
        render: transferStringNull
    },
    {
        dataIndex: 'validation',
        title: '发布时间',
        align: 'center',
        render: transferStringNull
    }
];

export const refereePaperworkColumns = [
    {
        dataIndex: 'sentenceString',
        title: '判决日期',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'title',
        title: '标题',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        dataIndex: 'litigant',
        title: '判决详情',
        align: 'center',
        width: '25%',
        render: text => text ? <a href={text} target="_blank" rel="noreferrer">点击查看</a> : '--'
    },
    // {
    //     dataIndex: 'verdictResult',
    //     title: '判决结果',
    //     align: 'center',
    //     width: '10%',
    //     render: value => {
    //         return (
    //             <Tooltip title={value}>
    //                 {value ? value.substring(0, 15) + '...' : '--'}
    //             </Tooltip>
    //         );
    //     }
    // },
    {
        dataIndex: 'trialCourt',
        title: '判决法院',
        align: 'center',
        width: '10%',
        render: transferStringNull
    }
];

export const announcementSessionColumns = [
    {
        dataIndex: 'trialString',
        title: '开庭日期',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'title',
        title: '案件标题',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'actionCause',
        title: '案由',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'litigant',
        title: '当事人',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'city',
        title: '地点',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const courtAnnouncementColumns = [
    // {
    //     dataIndex: 'noticeTime',
    //     title: '公告时间',
    //     align: 'center',
    //     render: text => moment(text).format(TimeFormat),
    //     width: '10%'
    // },
    {
        dataIndex: 'noticeContent',
        title: '公告内容',
        align: 'center',
        width: '50%'
    },
    {
        dataIndex: 'noticeType',
        title: '公告类型',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'noticePeople',
        title: '公告人',
        align: 'center',
        width: '20%'
    }
];

export const caseColumns = [
    {
        width: '50%',
        align: 'center',
        title: '公告时间',
        dataIndex: 'noticeTime',
        key: 'noticeTime',
        render: transferStringNull
    },
    {
        width: '20%',
        align: 'center',
        title: '公告标题',
        dataIndex: 'title',
        key: 'title',
        render: transferStringNull
    },
    {
        width: '20%',
        align: 'center',
        title: '公告链接',
        dataIndex: 'attachmentUrl',
        key: 'attachmentUrl',
        render: (text) => text ? <a href = {text} target = "_blank" rel="noreferrer">点击查看</a> : '--'
    }
];
