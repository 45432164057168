/**
 * @author Ray
 * @desc 标的一个整体容器。
 */
import React from 'react';
import styles from './styles.scss';
import { isEmpty, filterFactory } from 'utils/utils';
import Head from './head';
import Body from './body';
import { Empty } from 'antd';

export default class Table extends React.Component {
    static defaultProps = {
        columns: [],
        dataSource: []
    };

    render() {
        const { dataSource, columns, onInit, year, type, readOnly } = this.props;
        
        return (
            <div className={styles.flexibleTableRoot}>
                <Head data={columns} year={year} />
                {/* {isEmpty(dataSource) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} */}
                <Body
                    dataSource={dataSource}
                    order={columns.map(({ dataIndex }) => {
                        return dataIndex;
                    })}
                    onInit={onInit}
                    year={year}
                    type={type}
                    readOnly={readOnly}
                />
            </div>
        );
    }
}
