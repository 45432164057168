'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Utils = exports.registerShape = exports.registerChart = exports.Plugin = exports.Shape = exports.Chart = exports.Filter = exports.Translator = exports.getSettings = exports.settings = exports.init = undefined;

var _index = require('./config/index.js');

var _higgscharts = require('./higgscharts');

var _higgscharts2 = _interopRequireDefault(_higgscharts);

var _Chart = require('./interface/Chart');

var _Chart2 = _interopRequireDefault(_Chart);

var _Shape = require('./interface/Shape');

var _Shape2 = _interopRequireDefault(_Shape);

var _Plugin = require('./interface/Plugin');

var _Plugin2 = _interopRequireDefault(_Plugin);

var _painter = require('./core/painter');

var _painter2 = _interopRequireDefault(_painter);

var _translator = require('./helper/translator');

var _translator2 = _interopRequireDefault(_translator);

var _filter = require('./helper/filter');

var _filter2 = _interopRequireDefault(_filter);

var _util = require('./util');

var _util2 = _interopRequireDefault(_util);

var _circle = require('./graphic/chart/circle');

var _circle2 = _interopRequireDefault(_circle);

var _community = require('./graphic/chart/community');

var _community2 = _interopRequireDefault(_community);

var _event = require('./graphic/chart/event');

var _event2 = _interopRequireDefault(_event);

var _industry = require('./graphic/chart/industry');

var _industry2 = _interopRequireDefault(_industry);

var _region = require('./graphic/chart/region');

var _region2 = _interopRequireDefault(_region);

var _seek = require('./graphic/chart/seek');

var _seek2 = _interopRequireDefault(_seek);

var _stock = require('./graphic/chart/stock');

var _stock2 = _interopRequireDefault(_stock);

var _natural = require('./graphic/chart/natural');

var _natural2 = _interopRequireDefault(_natural);

var _mortgage = require('./graphic/chart/mortgage');

var _mortgage2 = _interopRequireDefault(_mortgage);

var _Node = require('./graphic/shape/Node');

var _Node2 = _interopRequireDefault(_Node);

var _Line = require('./graphic/shape/Line');

var _Line2 = _interopRequireDefault(_Line);

var _Circle = require('./graphic/shape/Circle');

var _Circle2 = _interopRequireDefault(_Circle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// 默认分布算法
var init = _higgscharts2.default.init;
// 默认图形单元绘制

var registerChart = _painter2.default.registerChart;
var registerShape = _painter2.default.registerShape;

// 注册图谱类
registerChart('circle', _circle2.default);
registerChart('community', _community2.default);
registerChart('event', _event2.default);
registerChart('industry', _industry2.default);
registerChart('region', _region2.default);
registerChart('seek', _seek2.default);
registerChart('stock', _stock2.default);
registerChart('natural', _natural2.default);
registerChart('mortgage', _mortgage2.default);
// 注册图形单元类
registerShape('node', _Node2.default);
registerShape('line', _Line2.default);
registerShape('circle', _Circle2.default);

exports.init = init;
exports.settings = _index.settings;
exports.getSettings = _index.getSettings;
exports.Translator = _translator2.default;
exports.Filter = _filter2.default;
exports.Chart = _Chart2.default;
exports.Shape = _Shape2.default;
exports.Plugin = _Plugin2.default;
exports.registerChart = registerChart;
exports.registerShape = registerShape;
exports.Utils = _util2.default;
exports.default = _higgscharts2.default;