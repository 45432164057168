/**
 * @author 刘少天
 * @desc 风险评估分数计算情况
 */

import React, { useEffect, useState } from 'react';
import { kechuangScore } from './service';
import style from './scientificInnovationTable.scss';

export default function ScientificInnovationTable({ match }) {
    const [detailData, setDetailData] = useState(null);
    if (detailData) {
        for (const key in detailData.score) {
            detailData.score[key] = detailData.score[key] !== null
                ? Math.round(detailData.score[key]) || detailData.score[key]
                : '--';
        }
    }
    function detail() {
        kechuangScore({
            companyName: match.params.companyName,
            type: 3
        }).then(res => {
            if (res.success) {
                setDetailData(res.data);
            }
        });
    }

    useEffect(() => {
        detail();
    }, []);

    const isAccord = data => {
        if (data === 0 || data === "--") {
            return '不符合';
        } else {
            return '符合';
        }
    };

    const degree = (number) => {
        if (number < -0.1) {
            return "明显下滑"
        } else if (-0.1 <= number && number < 0) {
            return "存在负增长"
        } else if (0 <= number && number < 0.2) {
            return "无明显增长"
        } else if (0.2 <= number && number < 1) {
            return "增长平稳"
        } else if (1 <= number) {
            return "收入增长迅速"
        }
    }

    const percentum = number => {
        if (typeof number === 'number') {
            return (Math.round(number * 10000) / 100).toFixed(2) + '%';
        }
        return '--';
    };

    return (
        <div className={style.tech}>
            {detailData && detailData.item && detailData.score && (
                <>
                    {/*<h3 className={style.caption}>*/}
                    {/*    {detailData.item.companyName}*/}
                    {/*</h3>*/}
                    {/*<p className={style.upTime}>*/}
                    {/*    上传时间：*/}
                    {/*    {moment(detailData.item.gmtCreate).format('YYYY-MM-DD')}*/}
                    {/*</p>*/}
                    <div className={style.verticalTable}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={style.th}>科创维度</td>
                                    <td className={style.th}>细项</td>
                                    {/* <td className={style.th}>企业情况</td> */}
                                    <td className={style.th}>得分</td>
                                </tr>
                                <tr>
                                    <td rowSpan="4" className={style.th}>
                                        1-核心技术情况
                                    </td>
                                    <td>
                                        1.1 是否掌握具有自主知识产权的核心技术
                                    </td>
                                    {/* <td>
                                        {
                                            detailData.item
                                                .independentPatentNum !== null ?
                                                <span>
                                                    有
                                                    {
                                                        detailData.item
                                                            .independentPatentNum
                                                    }
                                                    件具有自主知识产权的核心技术
                                                </span>
                                                : '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .independentPatentNumScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>1.2 核心技术是否权属清晰</td>
                                    {/* <td>
                                        {
                                            detailData.item.occupyPatentNum !== null ?
                                                <span>
                                                    有
                                            {detailData.item.occupyPatentNum}
                                                    件核心技术独自占有知识产权
                                        </span> : '--'
                                        }
                                    </td> */}
                                    <td>
                                        {detailData.score.occupyPatentNumScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1.3 是否国内或国际领先</td>
                                    {/* <td>
                                        {
                                            detailData.item.techLeadLevel !== null ?
                                                <span>
                                                    核心技术领先程度为
                                            {detailData.item.techLeadLevel}
                                                </span>
                                                : '--'
                                        }
                                    </td> */}
                                    <td>
                                        {detailData.score.techLeadLevelScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1.4 是否成熟或者存在快速迭代的风险</td>
                                    {/* <td>
                                        {
                                            detailData.item.techMatureLevel !== null ?
                                                <span>
                                                    核心技术成熟度为
                                            {detailData.item.techMatureLevel}
                                                </span>
                                                : '--'
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.techMatureLevelScore}
                                    </td>
                                </tr>

                                <tr>
                                    <td rowSpan="8" className={style.th}>
                                        2-研发能力情况
                                    </td>
                                    <td>2.1 研发管理情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item
                                                    .researchManageCondition !== null ?
                                                    detailData.item
                                                        .researchManageCondition
                                                    : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .researchManageConditionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.2 研发人员数量</td>
                                    {/* <td>
                                        {
                                            detailData.item.researchStaffNum !== null ?
                                                <span>
                                                    {detailData.item.researchStaffNum}人
                                        </span> :
                                                "--"
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.researchStaffNumScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.3 研发团队构成</td>
                                    {/* <td>
                                        {
                                            detailData.item.researchTeamRate !== null ?
                                                <React.Fragment>
                                                    研发人员占比
                                                    <span>
                                                        {percentum(
                                                            detailData.item.researchTeamRate
                                                        )}
                                                    </span>
                                                </React.Fragment>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.researchTeamRateScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.4 核心研发人员背景情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item
                                                    .coreResearchCondition !== null ? detailData.item
                                                        .coreResearchCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .coreResearchConditionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.5 研发投入</td>
                                    {/* <td>
                                        {
                                            detailData.item.researchCost !== null ?
                                                <span>
                                                    {detailData.item.researchCost}
                                                    万元
                                        </span>
                                                : '--'
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.researchCostScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.6 研发投入/收入</td>
                                    {/* <td>
                                        <span>
                                            {percentum(
                                                detailData.item
                                                    .researchCostBussiness
                                            )}
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .researchCostBussinessScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.7 研发设备情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item
                                                    .researchDeviceCondition !== null ? detailData.item
                                                        .researchDeviceCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .researchDeviceConditionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.8 技术储备情况</td>
                                    {/* <td>
                                        {
                                            detailData.item.researchStorageNum !== null ?
                                                <React.Fragment>
                                                    在研生产技术/工具数量为
                                                    <span>
                                                        {detailData.item.researchStorageNum}
                                                    </span>
                                                    项
                                                </React.Fragment>
                                                :
                                                '--'
                                        }
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .researchStorageNumScore
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td rowSpan="4" className={style.th}>
                                        3-研发成果市场认可情况
                                    </td>
                                    <td>
                                        3.1
                                        与主营业务相关的发明专利、软件著作权及新药批件情况
                                    </td>
                                    {/* <td>
                                        {
                                            detailData.item.totalPatentRatio !== null ?
                                                <React.Fragment>
                                                    占总专利数量比重为
                                                    <span>
                                                        {percentum(
                                                            detailData.item.totalPatentRatio
                                                        )}
                                                    </span>
                                                </React.Fragment>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.totalPatentRatioScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>3.2 独立或牵头承担重大科研项目情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.majorResearchCondition !== null ? detailData.item.majorResearchCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .majorResearchConditionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3.3 主持或参与制定国家标准、行业标准情况
                                    </td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.standardDraftCondition !== null ? detailData.item.standardDraftCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .standardDraftConditionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3.4
                                        获得国家科学技术奖项及行业权威奖项情况
                                    </td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.awardObtainCondition !== null ? detailData.item.awardObtainCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .awardObtainConditionScore
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td rowSpan="4" className={style.th}>
                                        4-相对竞争优势情况
                                    </td>
                                    <td>4.1 所处行业市场空间和技术壁垒情况</td>
                                    {/* <td>
                                        <span>
                                            {detailData.item.industryStage !== null ? detailData.item.industryStage : '--'}
                                        </span>
                                    </td> */}
                                    <td>
                                        {detailData.score.industryStageScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>4.2 行业地位及主要竞争对手情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.companyIndustyPosition !== null ? detailData.item.companyIndustyPosition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .companyIndustyPositionScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>4.3 技术优势及可持续性情况</td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.techAdvantageSustain !== null ? detailData.item.techAdvantageSustain : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .techAdvantageSustainScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4.4 核心经营团队和技术团队竞争力情况
                                    </td>
                                    {/* <td>
                                        <span>
                                            {
                                                detailData.item.coreTechTeamCondition !== null ? detailData.item.coreTechTeamCondition : '--'
                                            }
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .coreTechTeamConditionScore
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td rowSpan="6" className={style.th}>
                                        5-技术成果转化情况
                                    </td>
                                    <td>5.1 技术应用情况</td>
                                    {/* <td>
                                        {
                                            detailData.item.totalIncomeRatio !== null ?
                                                <React.Fragment>
                                                    专利、著作权、新药批件相关收入占总收入比重
                                                    <span>
                                                        {percentum(
                                                            detailData.item.totalIncomeRatio
                                                        )}
                                                    </span>
                                                </React.Fragment>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {detailData.score.totalIncomeRatioScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.2 市场拓展情况</td>
                                    {/* <td>
                                        产品市场占有率
                                        <span>
                                            {detailData.item.productMarketRatio !== null ? percentum(detailData.item.productMarketRatio) : '未披露'}
                                        </span>
                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .productMarketRatioScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.3 主要客户构成情况</td>
                                    {/* <td>
                                        {
                                            detailData.item
                                                .mainCustomerRatio !== null ?
                                                <React.Fragment>
                                                    最大客户营收占比
                                                <span>
                                                        {percentum(
                                                            detailData.item
                                                                .mainCustomerRatio
                                                        )}
                                                    </span>
                                                </React.Fragment>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .mainCustomerRatioScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.4 营业收入增长速率</td>
                                    {/* <td>
                                        {
                                            detailData.item
                                                .latestYearIncomeRate !== null ?
                                                <span>
                                                    报告期内营业收入{degree(detailData.item.latestYearIncomeRate)}（
                                                <span>
                                                        {percentum(
                                                            detailData.item
                                                                .latestYearIncomeRate
                                                        )}
                                                    </span>
                                                    ）
                                                    </span>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .latestYearIncomeRateScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.5 营业收入规模</td>
                                    {/* <td>
                                        {
                                            detailData.item.latestYearIncomeScale !== null ?
                                                <span>
                                                    {
                                                        detailData.item.latestYearIncomeScale
                                                    }
                                                    万元
                                        </span>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .latestYearIncomeScaleScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.6 核心产品收入增长速率</td>
                                    {/* <td>
                                        {
                                            detailData.item.latestYearCoreRate !== null ?
                                                <span>
                                                    报告期内核心产品收入{degree(detailData.item.latestYearCoreRate)}
                                                </span>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .latestYearCoreRateScore
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td rowSpan="3" className={style.th}>
                                        6-与政策导向符合情况
                                    </td>
                                    <td>6.1 是否服务于经济高质量发展</td>
                                    {/* <td>
                                        <span>
                                            {isAccord(
                                                detailData.score
                                                    .isEconomyQualityScore
                                            )}

                                        </span>
                                    </td> */}
                                    <td>
                                        {detailData.score.isEconomyQualityScore}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6.2
                                        是否服务于创新驱动发展战略、可持续发展战略、军民融合发展战略等国家战略
                                    </td>
                                    {/* <td>
                                        {
                                            detailData.score
                                                .isComplexStrategyScore !== '--' ?
                                                <span>
                                                    符合
                                            {
                                                        detailData.score
                                                            .isComplexStrategyScore
                                                    }
                                                    项
                                                </span>
                                                :
                                                '--'
                                        }

                                    </td> */}
                                    <td>
                                        {
                                            detailData.score
                                                .isComplexStrategyScore
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>6.3 是否服务于供给侧结构性改革</td>
                                    {/* <td>
                                        <span>
                                            {isAccord(
                                                detailData.score
                                                    .isSupplyReformScore
                                            )}
                                        </span>
                                    </td> */}
                                    <td>
                                        {detailData.score.isSupplyReformScore}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}
