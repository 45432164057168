import React from 'react';
import LeftFirst from './RiskTrendLine';

/**
 * @desc  左边第一个
 */
export default function LeftFirstIndex({ address, style }) {
  return (
        <LeftFirst address={address} />
  );
}
