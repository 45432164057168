'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

exports.circlePos = circlePos;
exports.countPos = countPos;

var _lodash = require('../lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 计算圈层图的坐标
 * @param {number} deviation 偏移角度
 * @param {array} center 中心点
 * @param {object} size 图形尺寸
   { 
     nodesInterval: 20, // 节点间隔
     groupsInterval: 60, // 组的间隔
     circleInterval: 20 // 圈层间隔
   }
 * @param {object} data : links, nodes 
 * @return {number} radius 返回当前半径
*/
function circlePos(data, set, deviation) {
    var center = set.center,
        size = set.size;

    // 圈层按维度来分组

    var groups = _lodash2.default.groupBy(data.nodes, 'degree');

    // 获取组数据
    deviation = deviation || Math.PI / 10; // 偏移角度

    var radius = null; // 绘制开始的半径，默认null 的时候，会自动设置成 groupsInterval
    // center = [width / 2, height / 2];

    // 设置坐标
    for (var num in groups) {
        if (groups[num]) {
            // 数组
            var arr = groups[num];
            if (num === '0') {
                // 中心点
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = (0, _getIterator3.default)(arr), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var obj = _step.value;

                        obj.position = center;
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            } else {
                // 计算点坐标
                radius = countPos(radius, deviation, arr, center, size);
            }
        }
    }

    return radius;
}

/**
 * @desc 计算圈层的点坐标
 * @param {number} radius 当前绘制的半径
 * @param {number} deg 开始角度
 * @param {array} node 节点数据
 * @param {array} center 中心坐标
 *  
*/
function countPos(radius, deviation, nodes, center, size) {
    var nodesInterval = size.nodesInterval,
        groupsInterval = size.groupsInterval,
        circleInterval = size.circleInterval;

    var len = nodes.length; // 节点长度

    // 半径默认是 组的间隔 
    radius ? null : radius = groupsInterval;

    // 设置坐标, 返回下标
    var setPos = function setPos(eachMaxNodes, index) {
        var indexNew = 0; // 记录当前下标
        var eachDeg = 2 * Math.PI / eachMaxNodes;
        for (var i = 0; i < eachMaxNodes; i++) {
            var deg = i * eachDeg,
                d = nodes[i + index];
            if (d) {
                indexNew++;
                d.position = getCoordinate(center, deg, radius, deviation);
            } else {
                break;
            }
        }
        radius += circleInterval;
        return indexNew + index;
    };

    // 设置坐标
    var eachMaxNodes = parseInt(2 * Math.PI * radius / nodesInterval, 10);

    // // 如果一圈可以排满
    if (eachMaxNodes > len) {
        eachMaxNodes = len;
        setPos(eachMaxNodes, 0);
    } else {
        // 如果一圈排不下的情况
        var index = 0;
        while (index < len) {
            eachMaxNodes = parseInt(2 * Math.PI * radius / nodesInterval, 10);
            index = setPos(eachMaxNodes, index);
        }
    }

    radius += groupsInterval;
    return radius;
}

/**
 * @desc 计算x,y的坐标
 * @param {array} center - 中心坐标[x,y]
 * @param {number} deg 角度
 * @param {number} radius 半径
 * @param {number} deviation 偏移角度
 * @return {array} 返回 x,y 坐标 [x1, y1]
 */
function getCoordinate(center, deg, radius, deviation) {
    var x = void 0,
        y = void 0;
    x = center[0] + radius * Math.cos(deg + deviation);
    y = center[1] + radius * Math.sin(deg + deviation);
    return [x, y];
}