import React, { useEffect } from 'react';
import Title from '../../../common/title';
import Project from './project';
import Relation from './relation';
import Punish from './punish';
import ExpertCard from './expertCard';
import WorkExperience from './workExperience';
import EduExperience from './eduExperience';
import styles from '../expertIndex/styles.scss';
import BackBtn from '../../../common/backBtn/index';
export default function ExpertDetail(props) {
    useEffect(() => {
        props.setBannerShow(false);
    }, []);
    const expert = props.location.state || {};
    return (
        <div className={styles.expertList}>
            <BackBtn history={props.history} />
            <br />
            <br />
            <ExpertCard detail={expert} />
            <br/>
            <Project detail={expert} />
            <br/>
            <Relation detail={expert} />
            <br/>
            <Punish detail={expert} />
            <br/>
            <WorkExperience detail={expert} />
            <br/>
            <EduExperience detail={expert} />
        </div>
    );
}
