import { Button, Input, message } from 'antd';
import classnames from 'classnames';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styles from './styles.scss';
import { sendMessage } from './service';
import { isNotEmpty } from '../../utils/utils';

/**
 *
 * @param onChange
 * @param handleClick
 * @param timeUp
 * @param value
 * @param classes
 * @param ref
 * @returns {*}
 * @constructor
 */
function PhoneInput(
    {
        onChange = () => {},
        handleClick = () => {},
        timeUp = () => {},
        value,
        classes = {}
    },
    ref
) {
    const [countdown, setCountdown] = useState(0);
    const { input } = classes;

    // 把调用计时器的方法暴露给父组件
    useImperativeHandle(ref, () => ({
        toSend: toSend
    }));

    function toSend(phone, template, pictureCode) {
        let _countdown = 60;
        let _timer = null;
        sendMessage(phone, template, pictureCode).then(res => {
            if (isNotEmpty(res) && res.success) {
                setCountdown(_countdown);
                // 设置计时器
                _timer = setInterval(function() {
                    // 更新倒计时
                    setCountdown(--_countdown);
                    if (_countdown === 0) {
                        // 清除计时器
                        timeUp();
                        clearInterval(_timer);
                    }
                }, 1000);
            } else {
                message.error(res.message);
                clearInterval(_timer);
                timeUp();
                setCountdown(0);
            }
        });
    }

    return (
        <div className={styles.root}>
            <Input
                value={value}
                onChange={onChange}
                placeholder="请输入验证码"
                style={{ width: '55%' }}
                className={classnames({
                    [input]: Boolean(input)
                })}
            />
            <span className={styles.code}>
                <Button
                    disabled={countdown}
                    onClick={handleClick}
                    type={'primary'}
                >
                    {countdown === 0 ? '获取验证码' : `${countdown}秒后获取`}
                </Button>
            </span>
        </div>
    );
}
const VerifyPhone = forwardRef(PhoneInput);
export default VerifyPhone;
