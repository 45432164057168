/**
 * @author Ray
 * @desc 中介机构详情
 */

import React, { useContext, useEffect, useState } from 'react';
import Title from '../../../common/title';
import { Button, Col, message, Row, Skeleton } from 'antd';
import styles from '../enterpriseDetail/styles.scss';
import { renderRoutes } from 'react-router-config';
import SocialEvaluation from './socialEvaluation';
import IntermediaryEvaluation from './intermediaryEvaluation';
import Detail from './home';
import { isEmpty, isNotEmpty, transferStringNull } from '../../../utils/utils';
import { getTopCompanyInfo } from './service';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import request from '../../../utils/fetch';
import { API, TimeFormat } from '../../../constants/enum';
import moment from 'moment';
import useAuthName from '../../../hooks/useAuthName';

export const Routes = [
    {
        exact: true,
        path: '/investment/intermediaryAnalysis/:companyName/:companyId',
        component: Detail
    },
    {
        exact: true,
        path: '/investment/intermediaryAnalysis/:companyName/:companyId/social',
        component: SocialEvaluation
    },
    {
        exact: true,
        path:
            '/investment/intermediaryAnalysis/:companyName/:companyId/intermediary',
        component: IntermediaryEvaluation
    }
];

export default function (props) {
    const [basicInfo, setInfos] = useState({});
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        gs.user?.organization === props.match.params.companyName
    );
    const isAuth = useAuthName('13');
    useEffect(() => {
        getTopCompanyInfo(
            props.match.params.companyId,
            props.match.params.companyName
        ).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                setInfos(res?.data || {});
            }
        });
    }, []);

    // 内容跳转
    function goto(address) {
        return props.history.push({
            pathname:
                props.match.path
                    .replace(':companyId', props.match.params.companyId)
                    .replace(':companyName', props.match.params.companyName) +
                address
        });
    }

    function handleReportDownload() {
        request(
            `${API.V1}/auth/report/agent/upload`,
            {
                companyId: props.match.params.companyId,
                companyName: props.match.params.companyName
            },
            {
                method: 'post',
                responseType: 'blob'
            },
            blob => {
                if (blob.size === 105) {
                    message.error('此用户没有下载报告的权限');
                } else {
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, props.match.params.companyName + '分析报告.doc' || params.file);
                    } else if (blob.size === 87) {
                        props.history.push('/user/login');
                    } else {
                        let _link = document.createElement('a');
                        _link.href = URL.createObjectURL(blob);
                        _link.download =
                            props.match.params.companyName + '分析报告.doc';
                        _link.click();
                        URL.revokeObjectURL(_link.href);
                    }
                }
            }
        );
    }

    return (
        <>
            <div className={styles.info}>
                <Skeleton loading={isEmpty(basicInfo)} active>
                    <Title level={1} text={basicInfo.companyName}>
                        {isAuth && (
                            <Button
                                size="small"
                                type="primary"
                                onClick={handleReportDownload}
                            >
                                分析报告下载
                            </Button>
                        )}
                    </Title>
                    <div className={styles.box}>
                        <Row>
                            <Col span={12}>
                                <Col span={5}>中介名称：</Col>
                                <Col span={19}>
                                    {basicInfo.companyName || '--'}
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Col span={5}>法人代表：</Col>
                                <Col span={19}>{basicInfo.frname || '--'}</Col>
                            </Col>
                            <Col span={12}>
                                <Col span={5}>注册资本：</Col>
                                <Col span={19}>
                                    {transferStringNull(basicInfo?.regcap) ||
                                        '--'}
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Col span={5}>成立日期：</Col>
                                <Col span={19}>
                                    {basicInfo.esdate
                                        ? moment(basicInfo.esdate, 'x').format(
                                            TimeFormat
                                        )
                                        : '--'}
                                </Col>
                            </Col>
                            <Col span={12}>
                                <Col span={5}>注册地址：</Col>
                                <Col span={19}>{basicInfo.address || '--'}</Col>
                            </Col>
                            <Col span={12}>
                                <Col span={5}>联系电话：</Col>
                                <Col span={19}>{basicInfo.phone || '--'}</Col>
                            </Col>
                        </Row>
                        <div className={styles.buttonGroup}>
                            {(isOwnedCompany ||
                                gs.isQiYuan ||
                                gs.isGOV ||
                                gs.isTradeAssociations ||
                                gs.isFundManager ||
                                (!isOwnedCompany && gs.isEnterprise)) && (
                                    <Button
                                        block
                                        type="primary"
                                        size="small"
                                        onClick={() => goto('/intermediary')}
                                    >
                                        中介机构评价
                                </Button>
                                )}
                            <Button
                                block
                                type="primary"
                                size="small"
                                onClick={() => goto('/social')}
                            >
                                社会责任评价
                            </Button>
                            <Button
                                block
                                type="primary"
                                size="small"
                                onClick={() => goto('')}
                            >
                                中介机构详情
                            </Button>
                        </div>
                    </div>
                </Skeleton>
            </div>
            {renderRoutes(Routes, {
                ...props,
                creditCode: basicInfo.creditCode,
                zjjgType: basicInfo.zjjg_type
            })}
        </>
    );
}
