import React from 'react';
import { Avatar, Card, Typography, Tag, Tooltip } from 'antd';
import WithLabel from '../../../common/withLabel';
export default function ExpertGroupCard(props) {
    const detail = props.detail || {};
    return (
        <Card bordered={false}>
            <Card.Meta
                title={
                    <Typography>
                        <Typography.Text>{detail.expertTeam}</Typography.Text>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Tag>{detail.expertFirm}</Tag>
                    </Typography>
                }
                description={
                    <>
                        <Typography>
                            <WithLabel text="任职公司:">
                                {detail.zjtdInInstitude}
                            </WithLabel>
                        </Typography>
                        <Typography>
                            <WithLabel text="主要服务领域:">
                                <Tooltip title={detail.zjtdMainServiceIndustry} overlayStyle={{ maxWidth: 700 }}>
                                    {detail.zjtdMainServiceIndustry
                                        ? detail.zjtdMainServiceIndustry.length > 30 ? detail.zjtdMainServiceIndustry.substring(0, 30) + '...' : detail.zjtdMainServiceIndustry
                                        : '--'}
                                </Tooltip>
                            </WithLabel>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <WithLabel text="主要服务板块:">{detail.zjtdMainServiceBlock}</WithLabel>
                        </Typography>
                    </>
                }
            />
        </Card>
    );
}
