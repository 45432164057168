import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Typography, Tag, Divider, Empty, message } from 'antd';
import moment from 'moment';
import WithLabel from '../../../common/withLabel';
import styles from './styles.scss';
import Pagination from '../../../common/list/pagination';
import { Link } from 'react-router-dom';
import { queryListExpertTeam } from '../service';

export default function GroupList({ indexShow, history }) {
  const [data, setData] = useState({
    teamList: []
  });
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 9,
    total: 10,
    totalPage: 0
  });

  useEffect(() => {
    async function fetchData() {
      const result = await queryListExpertTeam({ pageNum: 1, pageSize: 9 });
      if (result.statusCode == 1000) {
        const list = result.data;
        setData({ teamList: list.data || [] });
      } else {
        setData({ teamList: [] });
        message.info('专家团队' + result.message);
      }
    }
    fetchData();
  }, []);

  function load() { }

  return (
    <div>
      {
        data?.teamList?.length ? (
          <div
            style={{
              textAlign: 'right',
              fontSize: 16
            }}
          >
            <Link to={'/investment/expertGroup'}>...更多</Link>
          </div>
        ) : null
      }
      <Row gutter={16} className={styles.group}>
        {data?.teamList?.length ? (
          data.teamList.map((team, index) => (
            <Col
              className={styles.teamItem + ' clearfix'}
              span={24}
              key={index}
            >
              <Card
                hoverable
                bordered={false}
                style={{ height: 200 }}
                onClick={() => {
                  history.push({
                    pathname: `/investment/expertGroup/${team.expertTeam}`,
                    state: team
                  });
                }}
              >
                <Row>
                  <Col span={24}>
                    <WithLabel text='团队成员:'>
                      <Typography.Text>{team.expertTeam}</Typography.Text>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Tag color='#2082ED'>{team.expertFirm}</Tag>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Typography.Text>{team.zjtdInInstitude}</Typography.Text>
                    </WithLabel>
                  </Col>
                  <Col span={24}>
                    <WithLabel text='主要服务领域:'>
                      <Typography.Text>
                        {team.zjtdMainServiceIndustry}
                      </Typography.Text>
                    </WithLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    <WithLabel text='主要服务板块:'>
                      <Typography.Text>
                        {team.zjtdMainServiceBlock}
                      </Typography.Text>
                    </WithLabel>
                  </Col>
                  <Divider />
                  <Row>
                    <Col span={24}>
                      <WithLabel text='最近项目:'>{team.company}</WithLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      <WithLabel text='所属行业:'>
                        {team.industryName}
                      </WithLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      <WithLabel text='上市板块:'>{team.location}</WithLabel>
                      &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      <WithLabel text='上市时间:'>
                        {team.ipoDate
                          ? moment(team.ipoDate).format('YYYY-MM-DD')
                          : '--'}
                      </WithLabel>
                    </Col>
                  </Row>
                </Row>
              </Card>
            </Col>
          ))
        ) : (
            <Empty />
          )}
      </Row>
    </div>
  );
}
