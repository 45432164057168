/**
 * @author Ray
 */

import React from 'react';
import { Typography } from 'antd';

export default function({ title, text, time }) {
    return (
        <div>
            <Typography.Title level={3} className="textCenter">
                {title}
            </Typography.Title>
            <Typography className="textCenter">{time}</Typography>
            <p dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
}
