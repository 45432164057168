'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _index = require('../../config/index.js');

var _index2 = _interopRequireDefault(_index);

var _Shape2 = require('../../interface/Shape');

var _Shape3 = _interopRequireDefault(_Shape2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _Config$circle = _index2.default.circle,
    Layer = _Config$circle.layer,
    CircleStyle = _Config$circle.style;

var Circle = function (_Shape) {
    (0, _inherits3.default)(Circle, _Shape);

    function Circle(opts) {
        (0, _classCallCheck3.default)(this, Circle);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Circle.__proto__ || (0, _getPrototypeOf2.default)(Circle)).call(this, opts));

        _this.type = 'circle';

        _this.id = opts.id;
        _this.options = opts;
        _this._options = _this.convertOptions(opts);
        _this.element = new _this.engine.Circle();
        return _this;
    }

    (0, _createClass3.default)(Circle, [{
        key: 'convertOptions',
        value: function convertOptions(options) {
            var opts = (0, _get3.default)(Circle.prototype.__proto__ || (0, _getPrototypeOf2.default)(Circle.prototype), 'convertOptions', this).call(this, options);

            opts.zlevel = Layer.normal;
            opts.silent = true;
            opts.shape = {
                cx: opts.position[0],
                cy: opts.position[1],
                r: opts.radius
            };

            delete opts.radius;
            delete opts.position;
            return opts;
        }
    }, {
        key: 'setStyle',
        value: function setStyle(options) {
            this.setStatus(options);
            if (options.shadow) {
                this.setShadowStyle(options);
            } else {
                delete options.style.shadowBlur;
                delete options.style.shadowColor;
                delete options.style.shadowOffsetX;
                delete options.style.shadowOffsetY;
            }
        }
    }, {
        key: 'setStatus',
        value: function setStatus(options) {
            var hidden = options.hidden,
                slight = options.slight,
                hovered = options.hovered,
                selected = options.selected,
                focused = options.focused,
                style = options.style;


            var newStyle = (0, _assign2.default)({ text: options.text }, CircleStyle.item, CircleStyle.text, style);
            if (hidden) {
                this.element.hide();
            } else {
                this.element.show();

                if (slight) {
                    newStyle.opacity = CircleStyle.slight(options);
                } else {
                    if (hovered) {
                        //newStyle.fill = 'red';
                        //newStyle.stroke = 'red';
                        //newStyle.textFill = 'red';
                        //newStyle.textStroke = 'red';
                    }

                    if (selected) {
                        //newStyle.fill = 'yellow';
                        //newStyle.stroke = 'yellow';
                        //newStyle.textFill = 'yellow';
                        //newStyle.textStroke = 'yellow';
                    }

                    if (focused) {
                        //newStyle.fill = 'blue';
                        //newStyle.stroke = 'blue';
                        //newStyle.textFill = 'blue';
                        //newStyle.textStroke = 'blue';
                    }
                }
            }

            options.style = newStyle;
        }
    }, {
        key: 'setShadowStyle',
        value: function setShadowStyle(options) {
            // TODO:
        }
    }, {
        key: 'draw',
        value: function draw() {
            this.setStyle(this._options);
            this.action(this._options);
        }
    }, {
        key: 'update',
        value: function update(opts) {
            this.options = opts;
            this._options = this.convertOptions(opts);
            this.setStyle(this._options);
            this.action(this._options);
        }
    }, {
        key: 'action',
        value: function action(options) {
            this.element.attr(options);
        }
    }, {
        key: 'remove',
        value: function remove(callback) {
            this.layer.remove(this);
            this.options = null;
            this._options = null;
        }
    }, {
        key: 'animateTo',
        value: function animateTo(_ref, element) {
            var animation = _ref.animation,
                target = _ref.target,
                callback = _ref.callback;

            if (animation) {
                var _animation$time = animation.time,
                    time = _animation$time === undefined ? 800 : _animation$time,
                    _animation$delay = animation.delay,
                    delay = _animation$delay === undefined ? 0 : _animation$delay,
                    _animation$easing = animation.easing,
                    easing = _animation$easing === undefined ? 'linear' : _animation$easing,
                    _animation$forceAnima = animation.forceAnimate,
                    forceAnimate = _animation$forceAnima === undefined ? false : _animation$forceAnima;

                element.animateTo(target, time, delay, easing, function () {
                    element.attr(target);

                    if (callback) {
                        callback();
                    }
                }, forceAnimate);
            } else {
                element.attr(target);

                if (callback) {
                    callback();
                }
            }
        }
    }, {
        key: 'getElement',
        value: function getElement() {
            return this.element;
        }
    }]);
    return Circle;
}(_Shape3.default);

exports.default = Circle;
module.exports = exports['default'];