/**
 * @author Ray
 */
import React from 'react';
import { Icon } from 'antd';
import propTypes from 'prop-types';
import styles from './styles.scss';

/**
 *
 * @param {*} param0
 */
export default function ErrorTip({ text }) {
    return (
        <div className={styles.box}>
            <Icon
                type="close-circle"
                className={styles.icon}
                theme="filled"
                twoToneColor="#F5222D"
            />
            <span className={styles.text}>{text}</span>
        </div>
    );
}

ErrorTip.defaultProps = {
    text: ''
};

ErrorTip.propTypes = {
    text: propTypes.string
};
