/**
 * @author hjie
 * 锚点
 */

import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import Hook from './hook';
/**
 *
 * @returns {*}
 */
export default function({ data }) {

    const { styleFixed, index, setInex, clickIndex } = Hook(data);
    return (
        <div className = {classnames(styles.steps, {[styles.fixedBox]: styleFixed })} >
            {
                data.map( ( ele, i ) => {
                    const { domId, name } =ele;
                    return <a key = {domId}  className = { classnames(styles.step, {[styles.active]: i === index })} onClick = { () => clickIndex(i)} >
                                <span className = {styles.dot}>
                                    <i></i>
                                </span>
                                <span className = {styles.text}>{name}</span>
                            </a>;
                })
            }
        </div>
    );
}
