import React from 'react';
import RightThird from './PunishmentFocusHistogram';

/**
 * @desc 右3区块显示内容
 * @returns {*}
 * @constructor
 */
export default function RightThirdIndex({ address, style }) {
  return (
    <RightThird address={address} />
  );
}
