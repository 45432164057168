import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';
import { listedCompanyOfIndustry } from './service';


export default function Line4List() {
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);
    
    useEffect(() => {
        listedCompanyOfIndustry().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        })
    }, [num])
    

    function loadMore() {
        setNum(num+1);
    }

    return (
        <div className={ styles.line4List }>
            {
                data && data.length ? (
                    <table>
                        <thead>
                            <tr>
                                <td width="82%" style={{ paddingLeft: 40 }}>行业分类</td>
                                <td width="15.1%" style={{ paddingLeft: 40 }}>2019 年末</td>
                            </tr>
                            <tr>
                                <td width="80%"></td>
                                <td>公司家数</td>
                                <td>占比</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="80%" style={{ paddingLeft: 30, borderRadius: '50px 0 0 50px' }}>{ele.industry}</td>
                                            <td width="15%">{ele.companyCount}</td>
                                            <td width="15%" style={{ borderRadius: '0 50px 50px 0' }}>{ele.proportion == 0 ? 0 : ele.proportion * 100 + '%'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : <Empty />
            }
            <div className={ styles.loadMore } onClick={ loadMore }>
                点击查看更多
            </div>
        </div>
    )
}