/**
 * @desc  主办券商执业情况
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import Radios from './radio';
import List from './list';

export default function Line12Index() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '新挂牌数量', value: 1 },
        { name: '新发行股票数量', value: 2 },
        { name: '转板上市数量', value: 3 },
    ];

    return (
        <div className={ styles.line12 }>
            <Title />
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <List type={type} />
        </div>
        
    );
}
