/**
 * @author Ray
 * @desc 获取融资信息列表
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import {
    getFinancingList,
    addFinancing,
    deleteFinancing,
    updateFinancing
} from './service';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import { message } from 'antd';
import { GlobalContext } from '../../../common/globalStore/createProvider';

export default function Model({ children, ...rest }) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    function del(id) {
        return deleteFinancing(id).then(res => {
            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            return res.success;
        });
    }

    function update({
        code,
        date,
        financingMoney,
        financingRotation,
        id,
        investors,
        valuation, unionId
    }) {
        return updateFinancing(
            code,
            date,
            financingMoney,
            financingRotation,
            id,
            investors,
            valuation, unionId
        ).then(res => {
            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }

            return res.success;
        });
    }

    function add({
        date,
        financingMoney,
        financingRotation,
        investors,
        valuation
    }) {
        return addFinancing(
            user?.creditCode,
            date,
            financingMoney,
            financingRotation,
            investors,
            valuation
        ).then(res => {
            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            return res.success;
        });
    }

    return children({
        ...rest,
        add: add,
        update: update,
        delete: del
    });
}
