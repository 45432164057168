import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';

/**
 * @desc  柱形图-顶部是三角形
 */

export default class TrianglePillar extends PureComponent {
  getOption() {
    const { seriesData = [], xData = [] } = this.props;
    const colorLine = '#40BFFF';
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          //type: 'shadow',
          type: 'none'
        },
        formatter: function(params) {
          return `<p style="text-align:left">
                        ${params[0].name}<br/>科创钻石指数:<span style="color:#2082ED;font-size:18px;font-weight:bold;">${params[0].value}</span>
                    </p>`;
        },
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        borderWidth: 1,
        padding: 7
      },
      grid: {
        top: '5%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        //data: ['驯鹿', '火箭', '飞机', '高铁', '轮船',],
        data: xData,
        axisTick: { show: false },
        axisLabel: {
          textStyle: {
            color: '#fff'
          }
        },
        axisLine: {
          lineStyle: {
            color: colorLine
          }
        }
      },
      yAxis: {
        splitLine: {
          lineStyle: {
            color: colorLine,
            opacity: 0.3
          }
        },
        splitNumber: 3,
        axisTick: { show: false },
        axisLabel: {
          textStyle: {
            color: '#fff'
          }
        },
        axisLine: {
          lineStyle: {
            color: colorLine
          }
        }
      },
      series: [
        {
          name: 'hill',
          type: 'pictorialBar',
          //位置偏移
          //barCategoryGap: '25%',
          //图形宽度
          //barWidth: 35,
          //图形形状
          symbol: 'path://M150 50 L130 130 L170 130  Z',
          itemStyle: {
            normal: {
              opacity: 0.9,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: '#37D1FE' },
                  { offset: 1, color: '#1662ED' }
                ],
                globalCoord: false // 缺省为 false
              }
            },
            emphasis: {
              opacity: 1
            }
          },
          //data: [123, 60, 25, 18, 12,],
          data: seriesData,
          z: 10,
          label: {
            show: true,
            position: 'top',
            color: '#fff'
          }
        },
        {
          name: 'glyph',
          type: 'pictorialBar',
          barGap: '-100%',
          symbolPosition: 'end',
          symbolSize: 50,
          symbolOffset: [0, '-120%']
        }
      ]
    };
  }
  render() {
    const { style = {}, seriesData = [], xData = [] } = this.props;
    return seriesData.length === 0 || xData.length === 0 ? (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    ) : (
      <ReactEcharts
        option={this.getOption()}
        notMerge={true}
        style={{ height: '100%', ...style }}
        theme={''}
      />
    );
  }
}
