import React, { PureComponent } from 'react';
import styles from './styles.scss';
import { hightRiskCompaniesList } from './service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../utils/utils';

export default class HightRiskCompaniesList extends PureComponent {
    constructor(props) {
       super(props);
       this.state = {
          data: [],
       }
    }

    componentDidMount = () => {
        const { address } = this.props;
        //表格无缝滚动
        this.industryNews = setInterval(this.taskIndustryNews, 50);

        hightRiskCompaniesList({ area: address === '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.address != nextProps.address) {
            const { address } = nextProps;
            //表格无缝滚动
            hightRiskCompaniesList({ area: address === '全国' ? 'all' : address }).then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            })
        }
    }

    taskIndustryNews = () => {
      if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
          this.refs.newDiv.scrollTop = 0;
      }
      else if (this.refs.newDiv) {
          this.refs.newDiv.scrollTop += 1;
      }
    }

    handleIndustryNewsEnter = () => {
        clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render() {
        const { data } = this.state;
        
        return (
          <div className={ styles.ceShiTable }>
              <div className={ styles.ceShiTabletitle }>
                  <span className={ styles.ceShiTabletext2 }>企业名称</span>
                  <span className={ styles.ceShiTabletext2 }>风险评分</span>
                  <span className={ styles.ceShiTabletext2 }>行业</span>
                  <span className={ styles.ceShiTabletext2 }>地区</span>
                  <span className={ styles.ceShiTabletext2 }>版块</span>
              </div>
              <div
                  ref='newDiv'
                  className={ styles.ceShiTablebody }
                  onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                  onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
              >
                  <ul ref='newDivUI'>
                      {data && data.length > 0
                          ?
                          data.map(this.tableBody)
                          : <span className='noData'>暂无数据</span>
                      }
                  </ul>
              </div>
          </div>
        )
    }

    tableBody = (item, index) => {
      return (
          <li key={index}>
              <span className={ styles.ceShiTabletext2 }>
                  {item.companyName}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.riskScore}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {limitString(5, item.industry)}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.area}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.block}
              </span>
          </li>
      );
    }
}































