import { useState, useEffect } from 'react';
import { financeAPI } from '../service';

export default function({year, qyxxId}){

    const [radarOption, setRadarOption] = useState({data: []}); // 雷达图
    const [tableDatas, setTableDatas] = useState([]); // 雷达图
    const [loading, setLoading] = useState(true); 
    const [gaugeOptions, setGaugeOptions] = useState({option: {}, msg: ''}); // 仪表盘

    // 风险程度
    function getFinance() {
        if(!year){
            return;
        }
        financeAPI({
            year,
            qyxxId
        }).then(res => {
            if(res.success){
                const { totalRiskScore, risks, riskMessage } = res.data;
                setRadarOption({
                    data: risks.map( ele => ele.point.toFixed(2)),
                    indicator: risks.map( ele => {return {name: ele.name, max: 100};})
                });
                getGaugeOptions(totalRiskScore, riskMessage);
                setTableDatas(risks);
            }
        }).finally(()=>{
            setLoading(false);
        })
    };

    function getGaugeOptions(totalRiskScore, msg){
        const option = {
            series: [
                {
                    type: 'gauge',
                    data: [{value: totalRiskScore.toFixed(2), name: '风险总分'}]
                }
            ]
        };
        setGaugeOptions({option, msg});
    }

    useEffect(()=>{
        getFinance();
    }, [year])

    return { radarOption, loading, gaugeOptions, tableDatas }
}