import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 获取申请列表
 * @param category
 * @param eid
 * @param pageNum
 * @param pageSize
 * @param status
 */
export function getList({ category, eid, pageNum, pageSize, status }) {
    return request(
        `${API.V1}/merge/mergeApplication/mergeList`,
        {
            category,
            eid,
            pageNum,
            pageSize,
            status
        },
        {
            method: 'get'
        }
    );
}

/**
 * 获取banner
 */
export function getBannerList(type) {
    return request(`${API.V1}/auth/advertising/list`, { type });
}

/**
 *
 * @param id
 */
export function withdrawApply(id) {
    return request(`${API.V1}/merge/mergeApplication/mergeWithdraw`, { id });
}

/**
 *
 * @param id
 */
export function deleteApply(id) {
    return request(`${API.V1}/merge/mergeApplication/mergeDelete`, {
        id,
        type: 3
    });
}

/**
 *
 * @param id
 */
export function submitApply(id) {
    return request(`${API.V1}/merge/mergeApplication/mergeSubmit`, {
        id,
        needUpdate: 0,
        type: 3
    });
}
