/**
 * @author Ray
 */
import React from 'react';
import { Typography } from 'antd';
import styles from './styles.scss';

export default function({ text, children }) {
    return (
        <section className={styles.reminderRoot}>
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
            {children}
        </section>
    );
}
