/** *
 * @author Ray
 * @desc 用来做展示的Text标签，带后缀
 */
import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import { Typography, Input } from 'antd';
import { isNotEmpty } from '../../utils/utils';
import moment from 'moment';
import { TimeFormat } from '../../constants/enum';

function Text({ value, suffix, style, formatter = v => v }, ref) {
    useImperativeHandle(ref, () => ({ value }));
    return (
        <Typography style={style}>
            {value != undefined ? formatter(value) : '--'}
            {value != undefined ? suffix : ''}
        </Typography>
    );
}

/** *
 *
 * @param value 值
 * @param options 下拉框选项
 * @returns {*}
 */
export const SelectorPreview = forwardRef(function({ value, options }, ref) {
    const [text, setText] = useState('');
    useImperativeHandle(ref, () => ({ value }));
    useEffect(
        function() {
            // 获取正确的选项
            const target = options.filter(function(item) {
                return item?.value === value;
            });

            setText(target[0]?.text);
        },
        [value]
    );
    return <Typography>{value != undefined ? text : '--'}</Typography>;
});

export const DatePreview = forwardRef(function(
    { value, tooltip, fallback = '--' },
    ref
) {
    useImperativeHandle(ref, () => ({ value }));
    const [dateString, setDateString] = useState('');

    useEffect(
        function() {
            if (value) {
                let dateStr = moment(value, 'x').format(TimeFormat);
                setDateString(dateStr);
            }
        },
        [value]
    );

    return (
        <span>
            {isNotEmpty(dateString)}
            <Typography>
                {isNotEmpty(dateString) ? dateString : fallback}
            </Typography>
            {isNotEmpty(dateString) && <Typography>{tooltip}</Typography>}
        </span>
    );
});

export const TextareaPreview = forwardRef(function({ value }, ref) {
    useImperativeHandle(ref, () => ({ value }));
    return value != undefined ? (
        <Input.TextArea
            disabled
            value={value}
            autosize={{
                minRows: 4,
                maxRows: 6
            }}
            style={{
                color: '#333',
                background: '#fff'
            }}
        />
    ) : (
        '--'
    );
});

export default React.forwardRef(Text);
