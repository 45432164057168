import fetch from '../../../utils/fetch';
import { API } from '../../../constants/enum';

// 获取中介机构列表
export function getIntermediaryList(params) {
    // 获取上市奖励首页的数据
    return fetch(
        `${API.V1}/investbank/intermediary/queryAgencyListByCondition`,
        {
            ...params
        },
        {
            method: 'post'
        },
        () => {}
    );
}
