/**
 * @desc 供应商
 * @author Ray
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import InputTable, { parser } from '../../common/inputTable';
import { Columns } from './main';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import useStorage from '../../../../hooks/useStorage';
import { getOperatingData } from '../service';
import { message } from 'antd';
import deepEqual from 'fast-deep-equal';
import { SaveButton } from '../../../../common/buttons';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

const Rows = [
    { dataIndex: 'firstName', title: '第一名的供应商名称' },
    { dataIndex: 'firstValue', title: '第一名的供应商采购额(万元)' },
    { dataIndex: 'secondName', title: '第二名的供应商名称' },
    { dataIndex: 'secondValue', title: '第二名的供应商采购额(万元)' },
    { dataIndex: 'thirdName', title: '第三名的供应商名称' },
    { dataIndex: 'thirdValue', title: '第三名的供应商采购额(万元)' },
    { dataIndex: 'fourthName', title: '第四名的供应商名称' },
    { dataIndex: 'fourthValue', title: '第四名的供应商采购额(万元)' },
    { dataIndex: 'fifthName', title: '第五名的供应商名称' },
    { dataIndex: 'fifthValue', title: '第五名的供应商采购额(万元)' }
];

export default function Vendor({
    onChange = () => {},
    onInit = () => {},
    isPreview = false,
    onSave = () => {},
    trigger = () => {},
    activeKey,
    realKey,
    reloadFlag
}) {
    const [dataCopy, setDataCopy] = useState(undefined);
    const [data, setData] = useState(undefined);
    const ref = useRef(null);
    const [isNew, setIsNew] = useState(true);
    // const [user] = useStorage('UserInfo');
    const { user } = useContext(GlobalContext);
    function handleInit(instance) {
        onInit(instance);
        ref.current = instance;
    }

    useEffect(() => {
        reloadFlag &&
            user?.creditCode &&
            getOperatingData(user?.creditCode, 3).then(res => {
                if (isNotEmpty(res)) {
                    if (isNotEmpty(res.data)) {
                        setIsNew(false);
                        let parse =
                            res?.data?.map((data = {}) => {
                                const {
                                    year,
                                    firstName,
                                    firstValue,
                                    secondName,
                                    secondValue,
                                    thirdName,
                                    thirdValue,
                                    fourthName,
                                    fourthValue,
                                    fifthName,
                                    fifthValue
                                } = data;
                                return {
                                    year,
                                    firstName,
                                    firstValue,
                                    secondName,
                                    secondValue,
                                    thirdName,
                                    thirdValue,
                                    fourthName,
                                    fourthValue,
                                    fifthName,
                                    fifthValue
                                };
                            }) || [];
                        const data = parser(parse);
                        // 保存一份接口返回的数据，用于和修改的数据作比较，一旦保存过后，更新备份数据为保存的数据
                        setDataCopy(data);
                        setData(data);
                        onChange(data);
                        setTimeout(
                            () => ref.current?.setFieldsValue({ ...data }),
                            0
                        );
                    } else {
                        setIsNew(true);
                        setTimeout(() => ref.current?.resetFields(), 0);
                    }
                }
            });
    }, [reloadFlag]);

    function handleChange(data) {
        setData(data);
        onChange(data);
    }

    function result(isUpdated) {
        if (isUpdated) setDataCopy(data);
    }

    useEffect(() => {
        let parsedData = parser(data);
        let isModified = !checkEqual(dataCopy, parsedData);
        if (activeKey === realKey || (isEmpty(dataCopy) && isEmpty(data)))
            isModified = false;
        trigger(isModified, activeKey, result, isNew);
    }, [activeKey]);

    function handleSave() {
        setDataCopy(parser(data));
        onSave(isNotEmpty(dataCopy));
    }

    function checkEqual(a, b) {
        return deepEqual(a, b);
    }

    return (
        <>
            <InputTable
                rows={Rows}
                columns={Columns}
                onChange={handleChange}
                onInit={handleInit}
                isPreview={isPreview}
            />
            {!isPreview && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </>
    );
}
