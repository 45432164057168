/**
 * @author Ray
 */

import React, { useState } from 'react';
import Title from '../../../common/title';
import styles from './styles.scss';
import { Button, Divider, message } from 'antd';
import List from '../../../common/list';
import { changeStatus, getMessages } from './service';
import moment from 'moment';
import { TimeFormat } from '../../../constants/enum';
import { isNotEmpty } from '../../../utils/utils';
import { actions } from '../../frame/scripts';

export default function(props) {
    const { history, dispatch } = props;
    const [timestamp, setTimestamp] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    function handleItemClick(item) {
        history.push({ pathname: `/userCenter/message/${item.id}` });
    }

    function handleSelectedChange(si) {
        setSelectedItems(si);
    }

    function changeMessageStatus() {
        const ids = selectedItems.map(function(d) {
            return Number(d.msgId);
        });
        changeStatus(ids)
            .then(function(res) {
                if (res.success) {
                    message.success(res.message);
                    setTimestamp(new Date().getTime());
                } else {
                    message.error(res.message);
                }
            })
            .then(() => {
                return getMessages({
                    pageSize: 10,
                    pageNum: 1,
                    read: 0
                }).then(res => {
                    if (
                        isNotEmpty(res) &&
                        isNotEmpty(res.data) &&
                        isNotEmpty(res.data.data)
                    ) {
                        dispatch(actions.updateNotify(false));
                    } else {
                        dispatch(actions.updateNotify(true));
                    }
                });
            });
    }

    return (
        <section className={styles.messageContentRoot}>
            <Title classes={{ root: styles.titleFont }} text={'消息通知'}>
                <div className={styles.topButtonBox}>
                    <Button
                        type="primary"
                        onClick={changeMessageStatus}
                        disabled={selectedItems.length === 0}
                    >
                        标记为已读
                    </Button>
                </div>
            </Title>
            <Divider />
            <List
                dot
                checkbox
                query={timestamp}
                loadSource={getMessages}
                parseData={data =>
                    data.map(d => ({
                        id: d.id,
                        msgId: d.msgId,
                        dot: !d.read,
                        description: d.title,
                        time: d.gmtCreate
                            ? moment(d.gmtCreate, 'x').format(TimeFormat)
                            : ''
                    }))
                }
                onClick={handleItemClick}
                onChange={handleSelectedChange}
            />
        </section>
    );
}
