/**
 * @desc 股转动态title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line13Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line13Title }>
                股转动态
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}