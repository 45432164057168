import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.scss';
import { Typography, Row } from 'antd';
import Back from '../../../common/backBtn';
import moment from 'moment';
import { TimeFormat, API } from '../../../constants/enum';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { download } from '../../../utils/fetch';

export default function(props) {
  // const [user] = useStorage('UserInfo');

  const { user } = useContext(GlobalContext);
  const [detail, setDetail] = useState({
    content: '',
    gmtUpdate: '',
    title: '',
    author: '',
    fileUrl: ''
  });

  useEffect(function() {
    let id = props.match.params.companyId;
    props.getPolicyDetail(id, user?.level).then(function(data) {
      if (!data) return;
      setDetail(data);
    });
  }, []);

  function handleDownload() {
    let id = props.match.params.companyId;
    props.getPolicyDetail(id, user?.level).then(function(data) {
      if (!data) return;
      if (data.status === 3) {
        download(
          `${API.V1}/policypractice/file/download`,
          {
            id: JSON.parse(detail.fileUrl).id,
            name: JSON.parse(detail.fileUrl).name,
            fileId: JSON.parse(detail.fileUrl).id
          },
          {
            method: 'GET'
          }
        );
      }
    });
  }

  return (
    <div className={styles.root}>
      <Back history={props.history} url="/investment/policyReport" />
      <br />
      <br />
      <Typography.Title className={'textCenter'}>
        {detail.title}
      </Typography.Title>
      <Row className={'textCenter'}>
        <Typography.Text>
          时间：
          {detail.gmtUpdate && moment(detail.gmtUpdate, 'x').format(TimeFormat)}
        </Typography.Text>
        &nbsp; &nbsp; &nbsp;&nbsp;
        <Typography.Text>作者：{detail.author}</Typography.Text>
      </Row>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: detail.content }}
      />
      {detail.fileUrl && (
        <div
          style={{
            marginTop: 32
          }}
        >
          附件:
          <a onClick={handleDownload}>{JSON.parse(detail.fileUrl).name}</a>
        </div>
      )}
    </div>
  );
}
