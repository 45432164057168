import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  主办券商执业情况
 */
//新挂牌数量
export function newIpoCountGroupBroker() {
    return request(`${API.V1}/cockpit/neeqStock/ten/newIpoCountGroupBroker`)
}
//新发行股票数量
export function theNewIpoList() {
    return request(`${API.V1}/cockpit/neeqStock/ten/theNewIpoList`)
}
//转板上市数量
export function delistedCompanyList() {
    return request(`${API.V1}/cockpit/neeqStock/ten/delistedCompanyList`)
}
