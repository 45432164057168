import React, { useReducer } from 'react';
import Index from './home';
import Acquisition from './acquisition';
import Transfer from './transfer';
import { renderRoutes } from 'react-router-config';
import ModalEx from '../../common/modalEx';
import reducer from '../investment/script/reducer';
import { initState } from '../investment/script/const';
import { Permission } from '../../hooks/useAuth';

const Routes = [
    {
        exact: true,
        path: '/merging',
        component: Index
    },
    {
        exact: true,
        path: '/merging/acquisition',
        component: Acquisition
    },
    {
        exact: true,
        path: '/merging/acquisition/:id',
        component: Acquisition
    },
    {
        exact: true,
        path: '/merging/acquisition/preview/:id',
        component: Acquisition
    },
    {
        exact: true,
        path: '/merging/transfer',
        component: Transfer
    },
    {
        exact: true,
        path: '/merging/transfer/:id',
        component: Transfer
    },
    {
        exact: true,
        path: '/merging/transfer/preview/:id',
        component: Transfer
    }
];

export default function({ setBannerShow }) {
    const [state, dispatch] = useReducer(reducer, {
        modal: { ...initState.modal }
    });

    return (
        <>
            <Permission permission={189}>
                {renderRoutes(Routes, { dispatch, setBannerShow })}
            </Permission>
            <ModalEx
                title="提示"
                visible={state.modal.visible}
                onOk={state.modal.onOk}
                onCancel={state.modal.onCancel}
                onClose={state.modal.onClose}
            >
                {state.modal.content}
            </ModalEx>
        </>
    );
}
