/**
 * @author Ray
 */
import { Button, Form, Input, Tag, Typography, message } from 'antd';
import ErrorTip from '../../../../common/errorTip';
import { UserType, LEVELS } from '../../../../constants/enum';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    PswCheckLetter,
    PswCheckNum,
    PswCheckSymbol,
    asyncValidator,
    pwdRule
} from '../../../../utils/validator';
import styles from './styles.scss';
import moment from 'moment';
import { TimeFormat } from '../../../../constants/enum';
import useStorage from '../../../../hooks/useStorage';
import { validateOldPassword } from '../../services';
import { isNotEmpty } from '../../../../utils/utils';
import getKeyHook from '../../../../hooks/getKeyHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

/**
 *
 * @param form
 * @param onSubmit
 * @returns {*}
 * @constructor
 */
function AccountForm({ form, onSubmit = () => {} }) {
    const { user, isPasscodeExpired } = useContext(GlobalContext);
    const [ localLoginName ] = useState(()=>{
        return localStorage.getItem("localLoginName")
    });
    const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();

    const passwordValidator = useRef(
        asyncValidator(
            validateOldPassword,
            <ErrorTip text="密码错误！" />,
            undefined,
            undefined,
            undefined,
            v => {
                return getRsaPassWord(v);
            }
        )
    );
    const newPasswordValidator = useRef(
        asyncValidator(
            validateOldPassword,
            <ErrorTip text="与旧密码相同！" />,
            (res, value) => {
                if (isNotEmpty(res) && res.success) {
                    res.data = !res.data;
                }
                return res;
            },
            undefined,
            undefined,
            v => {
                return getRsaPassWord(v);
            }
        )
    );
    const { getFieldDecorator } = form;

    function handleSubmit() {
        form.validateFields((error, values) => {
            if (!error) {
                if (values.newPassword === values.oldPassword) {
                    return form.setFields({
                        newPassword: {
                            value: values.newPassword,
                            errors: [new Error('与旧密码相同！')]
                        }
                    })
                    // return message.error('');
                }
                getRsaPreparedAsync().then(function(){
                    const rsaPassWord = getRsaPassWord(values.newPassword);
                    const oldRsaPassWord = getRsaPassWord(values.oldPassword);
                    onSubmit({
                        ...values,
                        newPassword: rsaPassWord,
                        oldPassword: oldRsaPassWord,
                        confirmPassword: rsaPassWord,
                        userName: isPasscodeExpired && localLoginName
                    });
                });
            }
        });
    }

    return (
        <Form
            className={styles.formRoot}
            labelCol={{
                xs: { span: 2 },
                sm: { span: 10 }
            }}
            wrapperCol={{
                xs: { span: 2 },
                sm: { span: 14 }
            }}
            onSubmit={handleSubmit}
        >
            {!isPasscodeExpired && <Form.Item label="用户类型">
                <Typography.Text>{UserType[(user?.type)]}</Typography.Text>
                <Tag style={{ marginLeft: 16 }} color="green">
                    {LEVELS[user?.level - 1]}
                </Tag>
                {user?.level !== 1 && (
                    <Typography.Text type="warning">
                        到期时间&nbsp;&nbsp;
                        {user?.expireTime &&
                            moment(user?.expireTime, 'x').format(TimeFormat)}
                    </Typography.Text>
                )}
            </Form.Item>}
            <Form.Item label="登录用户名">
                <Typography>{isPasscodeExpired ? localLoginName : user?.username}</Typography>
            </Form.Item>
            <Form.Item required label="旧密码">
                {getFieldDecorator('oldPassword', {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请输入旧密码" />
                        },
                        !isPasscodeExpired && passwordValidator.current
                    ].filter(Boolean)
                })(
                    <Input.Password
                        placeholder={'请输入旧密码'}
                        className={styles.formInput}
                    />
                )}
            </Form.Item>
            <Form.Item required label="新密码">
                {getFieldDecorator('newPassword', {
                    validateTrigger: 'onBlur',
                    rules: [
                        ...pwdRule,
                        {
                            pattern: PswCheckSymbol.pattern,
                            message: <ErrorTip text={PswCheckSymbol.message} />
                        },
                        {
                            pattern: PswCheckLetter.pattern,
                            message: <ErrorTip text={PswCheckLetter.message} />
                        },
                        {
                            pattern: PswCheckNum.pattern,
                            message: <ErrorTip text={PswCheckNum.message} />
                        },
                        !isPasscodeExpired && newPasswordValidator.current
                    ].filter(Boolean)
                })(
                    <Input.Password
                        placeholder={'包含英文（区分大小写）、数字和特殊符号'}
                        className={styles.formInput}
                    />
                )}
            </Form.Item>
            <Form.Item required label="确认新密码">
                {getFieldDecorator('confirmPassword', {
                    // validateTrigger: 'onChange',
                    rules: [
                        {
                            validator: (rule, value, cb) => {
                                let _password = form.getFieldValue(
                                    'newPassword'
                                );
                                if (value === _password) {
                                    cb();
                                } else {
                                    cb(<ErrorTip text="输入密码不匹配" />);
                                }
                            }
                        },
                        {
                            required: true,
                            message: <ErrorTip text="请再次输入新密码" />
                        },
                        !isPasscodeExpired && newPasswordValidator.current
                    ].filter(Boolean)
                })(
                    <Input.Password
                        placeholder={'请输入新密码'}
                        className={styles.formInput}
                    />
                )}
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 22, offset: 2 }
                }}
                label=""
                htmlType="submit"
            >
                <div className={styles.center}>
                    <Button onClick={handleSubmit} type={'primary'}>
                        提交
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default function({ onSubmit }) {
    const [E, setE] = useState(null);

    useEffect(function() {
        let Element = Form.create({ name: 'accountForm' })(AccountForm);
        setE(<Element onSubmit={onSubmit} />);
    }, []);

    return E;
}
