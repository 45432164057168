import fetch from 'utils/fetch';
import request from 'utils/fetch';
import { API } from 'constants/enum';


// 根据年份查询风险程度
export function riskLevelAPI(data) {
    return fetch(`${API.V1}/risk/risk/level`, data);
}

// 获取财务报表列表
export function formListAPI(data) {
    return fetch(`${API.V1}/risk/risk/form`, data);
}

// 财务风险详情
export function financeAPI(data) {
    return fetch(`${API.V1}/risk/risk/finance`, data);
}

// 司法风险详情
export function judicialAPI(data) {
    return fetch(`${API.V1}/risk/risk/judicial`, data);
}

/**
 * @desc  裁判文书
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function judgePaperAPI({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/cpws`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  经营异常
 * @param qyxxId
 */
export function abnormalOperationAPI({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/jyyc`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc   开庭公告
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function publicNoticeAPI({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/ktgg`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  法院公告
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function courtAnnounceAPI({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/fygg`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

// 质押担保风险详情
export function guaranteeAPI(data) {
    return fetch(`${API.V1}/risk/risk/guarantee`, data);
}

//股权质押统计数据
export function pledgeAPI(data) {
    return fetch(`${API.V1}/risk/risk/guarantee/pledge?qyxxId=${data}`);
}

//重要股东质押数据
export function pledgorAPI(data) {
    return fetch(`${API.V1}/risk/risk/guarantee/pledgor?stockCode=${data}`);
}

//重要股东质押数据
export function pledgorDetailAPI(data) {
    return fetch(`${API.V1}/risk/risk/getShareholdersPledgeInfo/list?pageNum=${data.pageNum}&companyId=${data.companyId}&companyName=${data.companyName}`);
}

// 舆情列表
export function newsManageDataAPI(data) {
    return fetch(`${API.V1}/risk/sentiment/list`, data);
}

// 舆情分析
export function newsAnalyseAPI(data) {
    return fetch(`${API.V1}/risk/risk/sentiment/analyse`, data);
}

// 口碑值、口碑走势
export function reputation(data) {
    return fetch(`${API.V1}/investbank/sentiment/kb`, data);
}

// 获取相关公司人物数据
export function invokeDataAPI(data) {
    return fetch(`${API.V1}/invoke/data.do'`, data, {
        method: 'POST'
    });
}

export function detailPageAPI({ data, type }) {
    let info = {
        method: 'POST',
        data
    };

    switch (type) {

        case DETAIL_DELETE_REMARK: {
            info = { ...info, url: dstr + 'remark/deleteRemark.do' };
            break;
        }
        case DETAIL_FIND_REMARK_LIST: {
            info = { ...info, url: dstr + 'remark/findRemarkList.do' };
            break;
        }
        case DETAIL_SAVE_REMARK: {
            info = { ...info, url: dstr + 'remark/saveRemark.do' };
            break;
        }
        case DETAIL_YU_QING_COMPANYID: {
            info = { ...info, url: dstr + 'yuqing/getYuqingContentByCompanyId.do' };
            break;
        }
        case DETAIL_YU_QING_COMPANYID_RELATE: {
            info = { ...info, url: dstr + 'yuqing/getYuqingContentByCompanyIdAndRelate.do' };
            break;
        }
        case DETAIL_INVOKE_DATA: {
            info = { ...info, url: dstr + 'invoke/data.do' };
            break;
        }
    }
    return http(info);
}


