/**
 * @author Ray
 * @desc 入库企业分析-基础信息-上市能力判断-上市板块判断
 */

import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Table from '../../../../../common/table';
import { qualifyColumns } from './columns';
import { listingJudge } from './service';
import Title from '../../../../../common/title';
const { TabPane } = Tabs;

/**
 * @desc 上市资格判断
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ListingQualificationJudgement(props) {
    const [query] = useState({
        creditCode: props.match.params.companyCode,
        companyName: props.match.params.companyName
    });
    const [tableSource, setTableSource] = useState({});
    // const [rate, setRate] = useState(undefined);
    useEffect(() => {
        Promise.all([
            listingJudge({ ...query, type: 1 }),
            listingJudge({ ...query, type: 2 }),
            listingJudge({ ...query, type: 3 }),
            listingJudge({ ...query, type: 4 })
        ]).then(res =>
            setTableSource(res.map(response => response.data || []))
        );
    }, []);

    return (
        !props.isIpoCompany && (
            <div>
                <Title level={3} text="上市资格判断" />
                <Tabs>
                    <TabPane tab="主板，中小板" key="0">
                        <Table
                            dataSource={tableSource[0]}
                            pagination={false}
                            columns={[
                                ...qualifyColumns,
                                {
                                    dataIndex: 'satisfy',
                                    title: '满足情况(需要全部满足)',
                                    align: 'center',
                                    width: '25%'
                                }
                            ]}
                        />
                    </TabPane>
                    <TabPane tab="创业板" key="1">
                        <Table
                            dataSource={tableSource[1]}
                            columns={[
                                ...qualifyColumns,
                                {
                                    dataIndex: 'satisfy',
                                    title: '满足情况(需要全部满足)',
                                    align: 'center',
                                    width: '25%'
                                }
                            ]}
                            pagination={false}
                        />
                    </TabPane>
                    <TabPane tab="科创板" key="2">
                        <Table
                            dataSource={tableSource[2]}
                            columns={[
                                ...qualifyColumns,
                                {
                                    dataIndex: 'satisfy',
                                    title: '满足情况(满足一条即可)',
                                    align: 'center',
                                    width: '25%'
                                }
                            ]}
                            pagination={false}
                        />
                    </TabPane>
                    <TabPane tab="港交所" key="3">
                        <Table
                            dataSource={tableSource[3]}
                            pagination={false}
                            columns={[
                                ...qualifyColumns,
                                {
                                    dataIndex: 'satisfy',
                                    title: '满足情况(满足一条即可)',
                                    align: 'center',
                                    width: '25%'
                                }
                            ]}
                        />
                    </TabPane>
                </Tabs>
            </div>
        )
    );
}
