/**
 * @author Ray
 */

import request from 'utils/fetch';
import { API } from 'constants/enum';

/**
 *
 * @param pageNum
 * @param pageSize
 */
export function getRiskList({ pageNum, pageSize }) {
    return request(`${API.V1}/risk/company/focus/list`, { pageNum, pageSize });
}

/**
 * @desc 批量取消关注
 * @param qyxxIds
 */
export function unwatch(qyxxIds) {
    return request(`${API.V1}/risk/company/focus/cancel`, [...qyxxIds], {
        method: 'post'
    });
}

/**
 * @desc 关注置顶
 * @param qyxxId
 */
export function toTop(qyxxId) {
    return request(`${API.V1}/risk/company/focus/top`, { qyxxId });
}
