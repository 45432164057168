import { callPolicyReportDetail } from './service';
import { message } from 'antd';

export default function Model({ children, ...others }) {
    function getPolicyDetail(id, level) {
        return callPolicyReportDetail(id, level).then(function(res) {
            if (res.success) {
                return res.data;
            } else {
                message.error(res.message);
                others.history.goBack();
            }
        });
    }

    return children({
        ...others,
        getPolicyDetail
    });
}
