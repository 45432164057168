/**
 * @author Ray
 */
import React, { useState } from 'react';
import WithLabel from 'common/withLabel';
import { Row, Col, Select } from 'antd';
import styles from './styles.scss';
import { ApplyProcess, ApplyStatus, ListingStage } from 'constants/enum';

export default function SearchBar({ onChange }) {
  const [conditions, setConditions] = useState({
    applyStage: null,
    stage: null,
    status: null,
    pageNum: 1
  });

  function handleChange(key) {
    return function(v) {
      let newCds = Object.assign(conditions, {
        [key]: v
      });
      setConditions(newCds);
      onChange(newCds);
    };
  }
  return (
    <div id='historyApply' style={{ position: 'relative' }}>
      <Row className={styles.searchbarRoot}>
        {/* <Col span={9} /> */}
        <Col span={5}>
          <WithLabel text='上市阶段:'>
            <Select
              defaultValue={null}
              onChange={handleChange('stage')}
              className={styles.searchInput}
              getPopupContainer={() => document.getElementById('historyApply')}
            >
              <Select.Option value={null}>全部</Select.Option>
              {ListingStage.map(function(a, i) {
                return (
                  <Select.Option title={a} key={a} value={a}>
                    {a}
                  </Select.Option>
                );
              })}
            </Select>
          </WithLabel>
        </Col>
        <Col span={5}>
          <WithLabel text='提交状态:'>
            <Select
              defaultValue={null}
              onChange={handleChange('status')}
              className={styles.searchInput}
              getPopupContainer={() => document.getElementById('historyApply')}
            >
              <Select.Option value={null}>全部</Select.Option>
              {ApplyStatus.map(function(a, i) {
                return (
                  <Select.Option title={a} key={a} value={i + 1}>
                    {a}
                  </Select.Option>
                );
              })}
            </Select>
          </WithLabel>
        </Col>
        <Col span={5}>
          <WithLabel text='申请进度:'>
            <Select
              defaultValue={null}
              onChange={handleChange('applyStage')}
              className={styles.searchInput}
              getPopupContainer={() => document.getElementById('historyApply')}
            >
              <Select.Option value={null}>全部</Select.Option>
              {ApplyProcess.map(function(a, i) {
                return (
                  <Select.Option title={a} key={a} value={i + 1}>
                    {a}
                  </Select.Option>
                );
              })}
            </Select>
          </WithLabel>
        </Col>
      </Row>
    </div>
  );
}
