import { transferStringNull } from '../../../../utils/utils';
import React from 'react';
import moment from 'moment';

export const afterMeetingColumns = [
    // {
    //     key: 'pubdate',
    //     title: '辅导日期',
    //     dataIndex: 'pubdate',
    //     align: 'center',
    //     width: '8%',
    //     render: transferStringNull
    // },
    {
        key: 'csrcDate',
        title: '上会日期',
        dataIndex: 'csrcDate',
        align: 'center',
        width: '8%',
        render: text => (text ? moment(text, 'x').format('YYYY-MM-DD') : '--')
    },
    {
        key: 'location',
        title: '上市板块',
        dataIndex: 'location',
        align: 'center',
        width: '8%',
        render: transferStringNull
    },
    {
        key: 'company',
        title: '辅导公司名称',
        dataIndex: 'company',
        align: 'center',
        width: '13%',
        render: transferStringNull
    },
    {
        key: 'industryName',
        title: '证监会二级行业名称',
        dataIndex: 'industryName',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'industryCode',
        title: '证监会二级行业代码',
        dataIndex: 'industryCode',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'csrcStatus',
        title: '辅导结果',
        dataIndex: 'csrcStatus',
        align: 'center',
        width: '8%',
        render: transferStringNull
    }
];

export const revenueRatioColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '投研服务占经纪业务收入比例',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const investBankRankColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '投资银行业务收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const advisoryBusinessIncomeRatioRankColumns = [
    {
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        title: '财务顾问业务收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const restructuringAdvisoryBusinessIncomeRatioRankColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '并购重组财务顾问业务收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const securitiesInvestmentIncomeRankColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '证券投资收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const equityInvestmentIncomeRankColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '股权投资收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const accountingFirmTotalIncomeRankColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '业务收入(万元)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const numberOfCertifiedPublicAccountantsColumns = [
    {
        key: 'year',
        title: '年度',
        dataIndex: 'year',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'val',
        title: '注册会计师数量(人)',
        dataIndex: 'val',
        align: 'center',
        render: transferStringNull
    },
    {
        key: 'sort',
        title: '排名',
        dataIndex: 'sort',
        align: 'center',
        render: transferStringNull
    }
];

export const lostTrusteeColumns = [
    {
        key: 'registerString',
        title: '立案时间',
        dataIndex: 'registerString',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'caseCode',
        title: '案号',
        dataIndex: 'caseCode',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'execSubject',
        title: '执行标的',
        dataIndex: 'execSubject',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'execCourtName',
        title: '执行法院',
        dataIndex: 'execCourtName',
        align: 'center',
        width: '25%',
        render: transferStringNull
    }
];

export const executedPersonColumns = [
    {
        key: 'pubdate',
        title: '发布日期',
        dataIndex: 'pubdate',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'caseCode',
        title: '案号',
        dataIndex: 'caseCode',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'dishonestSituation',
        title: '具体情形',
        dataIndex: 'dishonestSituation',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'execState',
        title: '履行情况',
        dataIndex: 'execState',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'execCcourtName',
        title: '执行法院',
        dataIndex: 'execCourtName',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const refereeColumns = [
    {
        key: 'sentenceString',
        title: '判决日期',
        dataIndex: 'sentenceString',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        key: 'caseCode',
        title: '案号',
        dataIndex: 'caseCode',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'title',
        title: '标题',
        dataIndex: 'title',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        key: 'litigant',
        title: '涉及主体',
        dataIndex: 'litigant',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'caseResults',
        title: '判决结果',
        dataIndex: 'caseResults',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'trialCourt',
        title: '审理法院',
        dataIndex: 'trialCourt',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const regulatoryColumns = [
    {
        key: 'type',
        title: '处罚类型',
        dataIndex: 'type',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        key: 'person',
        title: '处罚人（自然人）',
        dataIndex: 'person',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'company',
        title: '处罚人（公司）',
        dataIndex: 'company',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        key: 'bbdUrl',
        title: '查看原文',
        dataIndex: 'bbdUrl',
        align: 'center',
        width: '20%',
        render: text => {
            let newText = transferStringNull(text);
            if (newText === '--') return newText;
            return (
                <a href={text} target="_blank">
                    查看原文
                </a>
            );
        }
    }
];
