import React from 'react';
import RightForth from './RefinancingPie';

/**
 * @desc  上市募集金额
 */
export default function RightForthIndex({ address }) {
  return (
    <RightForth address={address} />
  );
}