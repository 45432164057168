/**
 * @desc 入库企业分析-基础信息-融资进度
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { financingProcessColumns } from './columns';
import { getFinancingProgress } from './service';
import { isNotEmpty } from '../../../../../utils/utils';

/**
 *
 * @returns {*}
 */
export default function FinancingProcess({ match }) {
    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={financingProcessColumns}
            getData={getFinancingProgress}
            parseData={res => (isNotEmpty(res) ? res.data : [])}
            locale={{
                emptyText:
                    '未发现企业的融资进度记录。不排除存在未公示或时间相对滞后的情况。'
            }}
        />
    );
}
