/**
 * @desc 其他信息
 * @author Rauy
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, message } from 'antd';
import styles from './styles.scss';
import {
    PositiveFloat,
    PositiveInteger,
    Required
} from '../../../../utils/validator';
import ErrorTip from '../../../../common/errorTip';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import { getOperatingOtherData } from '../service';
import useStorage from '../../../../hooks/useStorage';
import { SaveButton } from '../../../../common/buttons';
import { parser } from '../../common/inputTable';
import deepEqual from 'fast-deep-equal';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import Text from '../../../../common/text';

const { Item } = Form;

export const OtherForm_ = ({ form, values, onInit, isPreview }, ref) => {
    const { getFieldDecorator } = form;
    React.useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
        onInit(form);
    }, []);

    return (
        <Form
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 10 }}
            className={styles.others}
        >
            <Item label="实际控制人">
                {getFieldDecorator('actualContoller', {
                    //rules: [Required]
                })(
                    isPreview ? (
                        <Text />
                    ) : (
                        <Input disabled={isPreview} maxLength={50} />
                    )
                )}
            </Item>
            <Item label="最终收益人">
                {getFieldDecorator('finalPerson', {
                    //rules: [Required]
                })(
                    isPreview ? (
                        <Text />
                    ) : (
                        <Input disabled={isPreview} maxLength={50} />
                    )
                )}
            </Item>
            {/* <Item required label="研发支出(万元)">
                {getFieldDecorator('researchPay', {
                    rules: [PositiveFloat, Required]
                })(<InputNumber disabled={isPreview} maxLength={15} />)}
            </Item> */}
            <Item label="员工人数（人）">
                {getFieldDecorator('empNum', {
                    rules: [
                        //Required,
                        PositiveInteger
                        // {
                        //     validator: (rule, value, cb) => {
                        //         if (value < 1) {
                        //             cb(<ErrorTip text="不小于1人" />);
                        //         } else {
                        //             cb();
                        //         }
                        //     }
                        //     // message:
                        // }
                    ]
                })(
                    isPreview ? (
                        <Text suffix="人" />
                    ) : (
                        <InputNumber disabled={isPreview} maxLength={9} />
                    )
                )}
            </Item>
        </Form>
    );
};

OtherForm.defaultProps = {
    onInit: () => {},
    isPreview: false,
    trigger: () => {},
    onChange: () => {},
    value: {}
};

export default function OtherForm({
    onInit,
    onSave,
    trigger,
    activeKey,
    isPreview,
    realKey,
    reloadFlag,
    onChange
}) {
    const [dataCopy, setDataCopy] = useState(undefined);
    const [data, setData] = useState(undefined);
    const ref = useRef(null);
    // const [user] = useStorage('UserInfo');
    const { user } = useContext(GlobalContext);
    const [Element, setElement] = useState(null);
    const [isNew, setIsNew] = useState(false);

    useEffect(function() {
        const Form_ = Form.create({
            name: 'financial',
            onValuesChange: (a, b, values) => setData(values)
        })(React.forwardRef(OtherForm_));

        setElement(
            <Form_
                onInit={onInit}
                wrappedComponentRef={ref}
                isPreview={isPreview}
            />
        );
    }, []);

    function result(isUpdated) {
        if (isUpdated) setDataCopy(data);
    }

    useEffect(() => {
        let isModified = !checkEqual(dataCopy, data);
        ref.current?.form?.validateFields(errors => {
            if (!errors) {
                if (
                    activeKey === realKey ||
                    (isEmpty(dataCopy) && isEmpty(data))
                )
                    isModified = false;
                trigger(isModified, activeKey, result, isNew);
            } else {
                trigger(false, activeKey, result, isNew);
            }
        });
    }, [activeKey]);

    function checkEqual(a, b) {
        return deepEqual(a, b);
    }

    useEffect(() => {
        reloadFlag &&
            user?.creditCode &&
            getOperatingOtherData(user?.creditCode).then(res => {
                if (isNotEmpty(res)) {
                    if (isNotEmpty(res.data)) {
                        setIsNew(false);
                        // 保存一份接口返回的数据，用于和修改的数据作比较，一旦保存过后，更新备份数据为保存的数据
                        const { finalPerson, empNum, actualContoller } =
                            res.data || {};
                        setDataCopy({ finalPerson, empNum, actualContoller });
                        setData({ finalPerson, empNum, actualContoller });
                        onChange({ finalPerson, empNum, actualContoller });
                        setTimeout(
                            () =>
                                ref.current?.form?.setFieldsValue({
                                    finalPerson,
                                    empNum,
                                    actualContoller
                                }),
                            0
                        );
                    } else {
                        setIsNew(true);
                        setTimeout(() => ref.current?.form?.resetFields(), 0);
                    }
                }
            });
    }, [reloadFlag]);

    function handleSave() {
        setDataCopy(data);
        onSave(isNotEmpty(dataCopy));
    }

    return (
        <>
            {Element}
            {!isPreview && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </>
    );
}
