'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _uuid = require('../util/uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _enum = require('../constant/enum');

var _common = require('../util/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Translator = function () {
    function Translator() {
        var _this = this;

        (0, _classCallCheck3.default)(this, Translator);
        this.ESTATUS = ['吊销', '停业', '拟注销', '歇业', '注销', '不明', '注吊销', '撤销', '清算'];
        this.INVESTMENT_TYPES = ['invest', 'gd'];
        this.MANAGEMENT_TYPES = ['supervisor', 'director', 'legal', 'executive', 'fr', 'js', 'gg'];
        this.STREAM_TYPE = {
            CENTER: 'center',
            UPPER: 'upper',
            LOWER: 'lower'
        };

        this.transformCircle = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data = data.data,
                nodes = _data$data.nodes,
                links = _data$data.links,
                _data$data$keyPaths = _data$data.keyPaths,
                keyPaths = _data$data$keyPaths === undefined ? {} : _data$data$keyPaths;


            data.data = {
                nodes: _this.transformNodes({
                    nodes: nodes,
                    keyNodeIds: keyPaths && keyPaths.keyRelationIds,
                    targetId: data.targetId || opts.params.targetId
                }),
                links: _this.transformLinks(links),
                keyNodes: _this.transformKeyNodes(keyPaths)
            };

            return data;
        };

        this.transformCircleExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data2 = data.data,
                nodes = _data$data2.nodes,
                links = _data$data2.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformCircleShortestPath = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data3 = data.data,
                nodes = _data$data3.nodes,
                links = _data$data3.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformCommunity = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data4 = data.data,
                nodes = _data$data4.nodes,
                links = _data$data4.links,
                _data$data4$keyPaths = _data$data4.keyPaths,
                keyPaths = _data$data4$keyPaths === undefined ? {} : _data$data4$keyPaths;


            data.data = {
                nodes: _this.transformNodes({
                    nodes: nodes,
                    keyNodeIds: keyPaths && keyPaths.keyRelationIds,
                    targetId: opts.params.targetId
                }),
                links: _this.transformLinks(links),
                keyNodes: _this.transformKeyNodes(keyPaths)
            };

            return data;
        };

        this.transformCommunityExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data5 = data.data,
                nodes = _data$data5.nodes,
                links = _data$data5.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformCommunityShortestPath = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data6 = data.data,
                nodes = _data$data6.nodes,
                links = _data$data6.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformRegion = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data7 = data.data,
                nodes = _data$data7.nodes,
                links = _data$data7.links,
                keyRelationIds = _data$data7.keyRelationIds;


            data.data = {
                nodes: _this.transformNodes({
                    nodes: nodes,
                    keyNodeIds: keyRelationIds,
                    targetId: opts.params.targetId
                }),
                links: _this.transformLinks(links),
                keyNodes: _this.transformKeyNodes(keyRelationIds)
            };

            return data;
        };

        this.transformRegionExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data8 = data.data,
                nodes = _data$data8.nodes,
                links = _data$data8.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformIndustry = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data9 = data.data,
                nodes = _data$data9.nodes,
                links = _data$data9.links,
                keyRelationIds = _data$data9.keyRelationIds;


            data.data = {
                nodes: _this.transformNodes({
                    nodes: nodes,
                    keyNodeIds: keyRelationIds,
                    targetId: opts.params.targetId
                }),
                links: _this.transformLinks(links),
                keyNodes: _this.transformKeyNodes(keyRelationIds)
            };

            return data;
        };

        this.transformIndustryExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data10 = data.data,
                nodes = _data$data10.nodes,
                links = _data$data10.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformStock = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                data.data = {};
                return data;
            }

            var _data$data11 = data.data,
                investNodes = _data$data11.investNodes,
                centerNode = _data$data11.centerNode,
                contributiveNodes = _data$data11.contributiveNodes,
                links = _data$data11.links,
                keyRelationIds = _data$data11.keyRelationIds;

            var center = _this.transformNodes({
                nodes: [centerNode],
                keyNodeIds: keyRelationIds,
                targetId: opts.params.targetId,
                processor: function processor(node) {
                    return node.streamType = _this.STREAM_TYPE.CENTER;
                }
            });
            var upper = _this.transformNodes({
                nodes: investNodes,
                keyNodeIds: keyRelationIds,
                processor: function processor(node) {
                    return node.streamType = _this.STREAM_TYPE.UPPER;
                }
            });
            var lower = _this.transformNodes({
                nodes: contributiveNodes,
                keyNodeIds: keyRelationIds,
                processor: function processor(node) {
                    return node.streamType = _this.STREAM_TYPE.LOWER;
                }
            });

            data.data = {
                nodes: [].concat((0, _toConsumableArray3.default)(center), (0, _toConsumableArray3.default)(upper), (0, _toConsumableArray3.default)(lower)),
                links: _this.transformLinks(links),
                keyNodes: _this.transformKeyNodes(keyRelationIds)
            };

            return data;
        };

        this.transformStockExtension = function (data) {
            if (!_this.hasNodes(data)) {
                data.data = {};
                return data;
            }

            var _data$data12 = data.data,
                investNodes = _data$data12.investNodes,
                contributiveNodes = _data$data12.contributiveNodes,
                links = _data$data12.links;

            var upper = _this.transformNodes({
                nodes: investNodes,
                processor: function processor(node) {
                    return node.streamType = _this.STREAM_TYPE.UPPER;
                }
            });
            var lower = _this.transformNodes({
                nodes: contributiveNodes,
                processor: function processor(node) {
                    return node.streamType = _this.STREAM_TYPE.LOWER;
                }
            });

            data.data = {
                nodes: [].concat((0, _toConsumableArray3.default)(upper), (0, _toConsumableArray3.default)(lower)),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformEvent = function (data, headers, opts) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data13 = data.data,
                nodes = _data$data13.nodes,
                links = _data$data13.links;


            data.data = {
                nodes: _this.transformNodes({
                    nodes: nodes,
                    targetId: opts.params.targetId
                }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformEventExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data14 = data.data,
                nodes = _data$data14.nodes,
                links = _data$data14.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformSeek = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data15 = data.data,
                nodes = _data$data15.nodes,
                links = _data$data15.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };

        this.transformNaturalExtension = function (data) {
            if (!_this.hasNodes(data)) {
                return data;
            }

            var _data$data16 = data.data,
                nodes = _data$data16.nodes,
                links = _data$data16.links;


            data.data = {
                nodes: _this.transformNodes({ nodes: nodes }),
                links: _this.transformLinks(links)
            };

            return data;
        };
    }

    // 企业类型

    // 投资类型

    // 管理类型

    // 股权结构图节点类型


    (0, _createClass3.default)(Translator, [{
        key: 'polyfillNode',


        // 不同项目接口兼容性处理
        value: function polyfillNode(node) {
            return (0, _assign2.default)(node, {
                companyName: node.name || node.companyName
            });
        }
        // 不同项目接口兼容性处理

    }, {
        key: 'polyfillLink',
        value: function polyfillLink(link) {
            return link;
        }

        // 设置节点类型

    }, {
        key: 'getNodeCategory',
        value: function getNodeCategory() {
            var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var category;
            var black = node.black,
                estatus = node.estatus,
                riskCount = node.riskCount,
                human = node.human;


            if (human) {
                // 自然人
                category = 'person';
            } else if (black) {
                // 黑名单企业
                category = 'black';
            } else if (this.ESTATUS.indexOf(estatus) !== -1) {
                // 吊销企业
                category = 'revoke';
            } else if (riskCount > 0) {
                // 风险项企业
                category = 'risk';
            } else {
                // 正常企业
                category = 'normal';
            }

            return category;
        }

        // 转换通用(企业, 个人, 事件, 地址)节点数据

    }, {
        key: 'transformNodes',
        value: function transformNodes(_ref) {
            var _this2 = this;

            var nodes = _ref.nodes,
                keyNodeIds = _ref.keyNodeIds,
                targetId = _ref.targetId,
                processor = _ref.processor;


            var newNodes = [];

            if (nodes && nodes.length > 0) {
                nodes.forEach(function (node) {
                    var _polyfillNode = _this2.polyfillNode(node),
                        id = _polyfillNode.id,
                        bbdQyxxId = _polyfillNode.bbdQyxxId,
                        bbdEventId = _polyfillNode.bbdEventId,
                        bbdAddressId = _polyfillNode.bbdAddressId,
                        companyName = _polyfillNode.companyName,
                        personName = _polyfillNode.personName,
                        gisLon = _polyfillNode.gisLon,
                        gisLat = _polyfillNode.gisLat,
                        isHistory = _polyfillNode.isHistory,
                        labels = _polyfillNode.labels;

                    var newNode = {
                        id: bbdQyxxId || bbdEventId || bbdAddressId || id, // 节点id
                        text: companyName || personName || '', // 节点显示的文字
                        data: node // 接口数据
                    };

                    /* 地域分布专用 */
                    if (gisLon) {
                        newNode.lon = gisLon; // 设置精度
                    }
                    if (gisLat) {
                        newNode.lat = gisLat; // 设置纬度
                    }

                    // 设置节点类型
                    newNode.category = _this2.getNodeCategory(node);
                    // 是否为历史关联方
                    newNode.isHistory = isHistory;

                    /**
                     * 设置事件节点类型
                     */
                    if ((0, _common.isNotEmpty)(labels)) {
                        for (var type in _enum.EventTypeMapping) {
                            var event = _enum.EventTypeMapping[type];
                            for (var i = 0; i < labels.length; i++) {
                                var label = labels[i].toLowerCase();
                                if (event.indexOf(label) !== -1) {
                                    newNode.isEvent = true;
                                    newNode.category = type;
                                    break;
                                }
                            }
                        }
                    }

                    /**
                     * 设置目标节点, this存在表示是初始化调用.
                     */
                    if (node.isTarget) {
                        newNode.isTarget = true;
                    } else if (targetId) {
                        if (newNode.id === targetId) {
                            newNode.isTarget = true;
                        } else {
                            newNode.isTarget = false;
                        }
                    }

                    /**
                     * 设置关键关联方
                     */
                    if (keyNodeIds && keyNodeIds.length > 0 && keyNodeIds.indexOf(newNode.id) !== -1) {
                        newNode.isKey = true;
                    }

                    // 设置节点上的标记
                    newNode.marks = [{
                        category: 'extension',
                        hidden: true
                    }, {
                        category: 'count',
                        hidden: true
                    }, {
                        category: 'attention',
                        hidden: true
                    }, {
                        category: 'remark',
                        hidden: true
                    }];

                    if ((0, _common.isFunction)(processor)) {
                        processor(newNode, node);
                    } else if ((0, _common.isArray)(processor)) {
                        processor.forEach(function (pro) {
                            return pro(newNode, node);
                        });
                    }

                    newNodes.push(newNode);
                });
            }

            return newNodes;
        }
        // 转换关键关联方节点数据

    }, {
        key: 'transformKeyNodes',
        value: function transformKeyNodes(keyPaths) {
            var keyNodes = [];
            var nodes;
            var nodesDetail;

            if ((0, _common.isObject)(keyPaths)) {
                nodes = keyPaths.keyRelationIds;
                nodesDetail = keyPaths.keyPathDetails;
            } else if ((0, _common.isArray)(keyPaths)) {
                nodes = keyPaths;
            }

            if (nodes && nodes.length > 0) {
                keyNodes = nodes.map(function (node) {
                    var keyNode = {
                        id: node
                    };

                    if (nodesDetail && nodesDetail.length > 0) {
                        var detail = nodesDetail.find(function (_detail) {
                            return _detail.keyRelationId === node;
                        });
                        if (detail) {
                            keyNode.labels = detail.labels;
                        }
                    }
                    return keyNode;
                });
            }

            return keyNodes;
        }
        // 转换线条数据

    }, {
        key: 'transformLinks',
        value: function transformLinks(links) {
            var _this3 = this;

            var newLinks = [];

            if (links && links.length > 0) {
                links.forEach(function (link) {
                    var _polyfillLink = _this3.polyfillLink(link),
                        _polyfillLink$propert = _polyfillLink.properties,
                        bbdRoleId = _polyfillLink$propert.bbdRoleId,
                        bbdAddressId = _polyfillLink$propert.bbdAddressId,
                        bbdEventId = _polyfillLink$propert.bbdEventId,
                        labels = _polyfillLink$propert.labels;

                    newLinks.push({
                        id: link.startId + '-' + link.endId + '-' + (bbdRoleId || bbdEventId || bbdAddressId || (0, _uuid2.default)()),
                        from: link.startId,
                        to: link.endId,
                        relationType: _this3.getRelationType(labels),
                        data: link.properties
                    });
                });
            }

            return newLinks;
        }
    }, {
        key: 'hasNodes',
        value: function hasNodes(data) {
            if ((0, _common.isEmpty)(data) || (0, _common.isEmpty)(data.data) || (0, _common.isEmpty)(data.data.nodes) && (0, _common.isEmpty)(data.data.centerNode)) {
                return false;
            }

            return true;
        }
    }, {
        key: 'getRelationType',
        value: function getRelationType(labels) {
            if ((0, _common.isEmpty)(labels)) {
                return;
            }

            for (var i = 0; i < labels.length; i++) {
                var label = labels[i];
                // 投资关系
                if (this.INVESTMENT_TYPES.includes(label.toLowerCase())) {
                    return _enum.RelationType.INVESTMENT;
                    // 管理关系
                } else if (this.MANAGEMENT_TYPES.includes(label.toLowerCase())) {
                    return _enum.RelationType.MANAGEMENT;
                }
            }
        }
        /**
         * 圈层分布图接口
         */
        // 转换圈层分布图 relation 接口数据

        // 转换扩展数据

        // 转换圈层分布图 shortestPath 接口数据


        /**
         * 社区分布图接口
         */
        // 转换社区分布图 relation 接口数据

        // 转换扩展数据

        // 转换社区分布图 shortestPath 接口数据


        /**
         * 地域分布图接口
         */
        // 转换地域分布图 relation 接口数据

        // 转换扩展数据


        /**
         * 行业分布图接口
         */
        // 转换行业分布图 relation 接口数据

        // 转换扩展数据


        /**
         * 股权结构图接口
         */
        // 转换股权结构图 relation 接口数据

        // 转换扩展数据


        /**
         * 事件关系图接口
         */
        // 转换事件关系图 relation 接口数据

        // 转换扩展数据


        /**
         * 关系探寻图接口
         */
        // 转换关系探寻图 relation 接口数据

        // 转换扩展数据

    }]);
    return Translator;
}();

exports.default = Translator;
module.exports = exports['default'];