import { API } from '../../../constants/enum';
import request from '../../../utils/fetch';

/**
 * @desc 获取报告详情
 * @param id
 * @param level
 */
export function callPolicyReportDetail(id, level) {
    return request(`${API.V1}/investbank/reportBefore/showReportDetail`, {
        id,
        level
    });
}
