/**
 * @desc 每月新三板交易情况title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line9Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line9Title }>
                每月新三板交易情况
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}