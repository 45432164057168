import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { limitString } from 'utils/utils';

/**
 * @desc 饼图
 */
export default class Pie extends PureComponent {
    constructor(props) {
        super(props);
        this.color = [
            '#2082ED',
            '#FFC641',
            '#59DF8F',
            '#877DE6',
            '#3EB56A',
            '#464CEE'
        ];
    }

    // componentDidMount() {
    // }

    getOption() {
        const { data } = this.props;
        return {
            color: this.color,
            tooltip: {
                show: true,
                trigger: 'item',
                borderColor: 'rgba(33,86,143,0.14)',
                padding: [10, 20],
                formatter: this.props.formatter || '{b}: {c}家'
                // formatter: '{b}'
            },
            label: {
                formatter: v => {
                    return `${limitString(8, v.name)}: ${v.value}家`;
                }
            },
            series: [
                {
                    type: 'pie',
                    data
                }
            ]
        };
    }
    render() {
        const { style = { height: '380px' }, data, loading } = this.props;
        return (
            <div className="pieChart">
                {loading ? (
                    ''
                ) : data && data.length ? (
                    <div style={style} className="chartBg">
                        <ReactEcharts
                            option={this.getOption(data)}
                            notMerge={true}
                            style={style}
                            theme={'myTheme'}
                        />
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        );
    }
}
