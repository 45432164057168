import React, {useEffect, useRef, useState} from 'react';
import ReactEcharts from 'echarts-for-react';
import {GetRelationData} from "./service";
import style from './styles.scss'


export default function RelationMap(props) {


    const [nodes, setNodes] = useState([]);
    const [links, setLinks] = useState([]);
    const {companyName = '', companyId = ''} = props.match.params;
    const companyColor = '#006acc';
    const personColor = '#ff7d18';
    const curColor='rgb(1,182,198)';

    let categories = [{
            name: '公司',
            itemStyle: {
                color: companyColor
            }
        },
        {
            name: '自然人',
            itemStyle: {
                color: personColor
            }
        },
        {
            name:'中心节点',
            itemStyle:{
                color: curColor
            }
        }
        ];

    useEffect(() => {
        GetRelationData(companyId).then(({success, data}) => {
            if (success) {
                let e2c={
                    's':'自然人',
                    'f':'公司'
                };
                let node_arr = data['data']['nodes'].map(ele => {
                    return {
                        id: ele['id'],
                        name: ele['properties'][0]['name'],
                        category: e2c[ele['properties'][0]['ntype']]
                    }
                });
                node_arr.forEach(node=>{
                    if(node.name==companyName){
                        node['category']='中心节点';
                        node.itemStyle={
                            color:curColor
                        }
                    }
                    else if(node.category=='自然人'){
                        node.itemStyle = {
                            color: personColor
                        };
                    }
                    else{
                        node.itemStyle = {
                            color: companyColor
                        };
                    }
                });

                let links_arr = data['data']['relationships'].map(ele => {
                    let properties=ele['properties'];
                    properties=JSON.parse(properties);
                    return {
                        target: ele['startNode'],
                        source: ele['endNode'],
                        name: properties['labels'][0]
                    }
                });
                function cmp(a,b){
                    return parseInt(a.id) - parseInt(b.id);
                }
                node_arr=node_arr.sort(cmp)
                console.log('nodes',node_arr);
                console.log(('links',links_arr));
                setNodes(node_arr);
                setLinks(links_arr);
            } else {
                setNodes([]);
                setLinks([])
            }
        })
    }, []);

    function GetOptions() {
        return {
            title: {
                text: '关系图谱',
            },
            legend: [{
                // data: categories.map(x => x.name),
                data:['公司','自然人'],
                left:'5%',
                top:'20%',
                orient: 'vertical' ,
            }],
            series: [{
                type: 'graph',
                layout: 'force',
                symbolSize: 58,
                draggable: false,
                roam: true,
                focusNodeAdjacency: true,
                categories: categories,
                edgeSymbol: ['', 'arrow'],
                // edgeSymbolSize: [80, 10],
                edgeLabel: {
                    normal: {
                        show: true,
                        textStyle: {
                            fontSize: 20
                        },
                        formatter(x) {
                            return x.data.name;
                        }
                    }
                },
                label: {
                    show: true
                },
                force: {
                    repulsion: 1300,
                    edgeLength: 120,
                    gravity: 0.3,
                    layoutAnimation: false ,
                },
                data: nodes,
                links: links
            }]
        }
    }

    return (
        <div className={style.divMap}>
            <ReactEcharts
                notMerge={true}
                option={GetOptions()}
                style={{height: '100%'}}
            />
        </div>
    )
}
