import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import { transactionsInfoMonthly } from './service';
import { Empty } from './service';


global.AreaChart = null;

export default function Transaction() {

    useEffect(() => {
        transactionsInfoMonthly().then(({ success, data }) => {
            if (success == true) {
                if (global.AreaChart == null) {
                    global.AreaChart = new Chart({
                        container: 'AreaChart',
                        autoFit: true,
                        height: 250,
                        padding:[15,20,20,35]
                    });

                    //数据分割
                    let countData=data.filter((ele)=>{
                        return ele.item=="股票数量"
                    });//柱状图
                    countData=countData.map((ele)=>{
                        return{
                            mouth:ele['mouth'],
                            cnt:parseInt(ele['amount']),
                        }
                    });

                    data=countData;
                    //console.log(data);

                    global.AreaChart.data(data);
                    global.AreaChart.scale('cnt', {
                        nice: true,
                    });
                    global.AreaChart.axis('mouth',{
                        label:{
                            offset:15
                        }
                    });
                    global.AreaChart.tooltip(false);
                    global.AreaChart
                        .interval()
                        .position('mouth*cnt')
                        .color("rgb(87,137,239)")
                        .label('cnt');
                    global.AreaChart.render();

                }
            } else {
                <Empty />
            }
        })
    }, [])

    return(
        <div id='AreaChart' style={{height:'23vh'}}></div>
    )
}
