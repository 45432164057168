/**
 * @author hjie
 * 风险检测首页
 */

import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import { Select, AutoComplete, Input, Icon } from 'antd';
import RiskAnalysis from './component/riskAnalysis';
import RiskSort from './component/riskSort';
import Record from './component/record';
import IndustryAnalysis from './component/industryAnalysis';
import hooks from './hook';

const { Option } = Select;

export default function(props) {

    const { onSelect, dataSource, funcRef } = hooks(props);
    const options  = dataSource && dataSource.length ? dataSource.map(ele => <Option key={ele.bbdQyxxId}>{ele.companyName}</Option> ) : [<Option disabled key = "暂无数据">暂无数据</Option>]
    return (
        <div className={styles.home}>
            <div className = {classnames("clearfix",  styles.search)}>
                <AutoComplete
                    id="autoContainer"
                    onSelect={onSelect}
                    onSearch={funcRef.current}
                    dataSource = { 
                        options
                    }
                    getPopupContainer={()=> document.getElementById('autoContainer')}
                >
                    <Input suffix={<Icon type="search"  />}  placeholder="输入上市公司代码或关键字"  size="large"/>
                </AutoComplete>
            </div>
            <RiskAnalysis/> 
            <RiskSort />
            <Record />
            <IndustryAnalysis />
        </div>
    );
}
