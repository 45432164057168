import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 获取地区分布数据
 * @param area
 * @param type
 */
export function getAreaDistribution({ area, type }) {
    return request(
        `${API.V1}/auth/diamond/distribution/map`,
        { area, type },
        undefined,
        undefined,
        true
    );
}

/**
 * @desc 获取企业状态分布数据
 * @param area
 */
export function getStatusDistribution({ area }) {
    return request(
        `${API.V1}/auth/diamond/distribution`,
        { area },
        undefined,
        undefined,
        true
    );
}
