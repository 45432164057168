// 密码加密的公共hook

import { getCookie } from 'utils/utils';
import React, { useEffect } from 'react';
import {message } from 'antd';
import {getPublic} from '../containers/user/service';

export default function () {

    useEffect(()=>{
        require('jsencrypt');
        getRsa();
    }, [])

    const getRsa = () => {
        getPublic().then(res => {
            // 
        });
    };
    function getRsaPassWord(psw){
        const publicKey = getCookie('publicKey');
        if(!publicKey){
            message.error('请重新刷新页面再尝试登陆');
            return;
        }
        let encryptor = new JSEncrypt();  // 新建JSEncrypt对象
        encryptor.setPublicKey(publicKey);  // 设置公钥
        let rsaPassWord = encryptor.encrypt(psw);  // 对密码进行加密
        return rsaPassWord;
    }
    function getRsaPreparedAsync() {
        return getPublic();
    }
    return { getRsaPassWord, getRsaPreparedAsync };
}