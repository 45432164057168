/**
 * @author Ray
 * @description 带前缀后缀的文本，某些文本需要前面带icon的可以使用。
 */
import React from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
/**
 *
 * @param prefix
 * @param children
 * @returns {*}
 */

export default class extends React.Component {
    static defaultProps = {
        prefix: null,
        suffix: null,
        formatter: v => v
    };

    static propTypes = {
        prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    };

    static getDerivedStateFromProps;

    render() {
        const { prefix, suffix, children, value, formatter } = this.props;

        return (
            <span className={styles.root}>
                {prefix}
                <span className={styles.value}>
                    {children || formatter(value)}
                </span>
                {suffix}
            </span>
        );
    }
}
