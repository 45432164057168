import React, { useState, useEffect } from 'react';
import Pie from '../listedCompanies/Pie';
import style from '../listOfEnterprises/style.scss';
import { getQueryListedCompanyFb } from './service';
/**
 * @desc  融资金额分布
 */
export default function BigScreen({ style = {} }) {
  const [datas, setDatas] = useState({ loading: true, data: [] });
  const name = '融资金额分布';

  function queryListedCompanyFb() {
    getQueryListedCompanyFb().then(({ success, data }) => {
      if (success && data && data.length) {
        let arr = data.map((item, index) => {
          return { name: item.regProvince, value: parseFloat(item.amount) };
        });
        setDatas({ loading: false, data: arr });
      } else {
        setDatas({ loading: false, data: [] });
      }
    });
  }
  useEffect(() => {
    queryListedCompanyFb();
  }, []);
  return (
    <div style={{ ...style }}>
      <Pie datas={datas} name={name} style={{ height: '100%' }} />
    </div>
  );
}
