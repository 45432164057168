/**
 * @author Ray
 * @desc 主要客户与供应商
 */

import React, { useEffect, useState } from 'react';
import { Col, Row, Empty } from 'antd';
import styles from './styles.scss';
import { BarStack, Donut } from '../../../../../echarts';
import Title from '../../../../../common/title';
import { Selector } from '../../../../merging/home/apply';
import { mainCustomerAndSupplier } from './service';
import {
    generateYearRangeOptions,
    groupObject,
    isEmpty,
    moneyFormatter,
    unique
} from '../../../../../utils/utils';
import moment from 'moment';

export default function Vendor({ match, isIpoCompany }) {
    const [data, setData] = useState({});
    // const [selectedYear, setYear] = useState(new Date().getFullYear() - 1);

    useEffect(() => {

        async function getData() {
            const barWidth = 30;
            const colors = [
                '#2082ED',
                '#59DF8F',
                '#FB7B39',
                '#004591',
                '#0800FF',
                '#91C7AE',
                '#61A0A8',
                '#C23531'
            ];

            let customerColor = {},
                supplierColor = {};
            let customers = [],
                suppllier = [];
            const dataAxis = [];
            const labelOption = {
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            };

            const resData = (await mainCustomerAndSupplier({
                creditCode: match.params.companyCode
            })).data;

            let customerData = [], supplierData = [];
            Array.apply(null, Array(5)).map(value => {
                customerData.push({
                    type: 'bar',
                    stack: 'total',
                    label: labelOption,
                    barWidth,
                    data: []
                });
                supplierData.push({
                    type: 'bar',
                    stack: 'total',
                    label: labelOption,
                    barWidth,
                    data: []
                });
            });
            if(resData.mainCustomerList==null) resData.mainCustomerList=[];
            if(resData.mainSupplierList==null) resData.mainSupplierList=[];
            resData.mainCustomerList.reverse().forEach((value, index) => {
                dataAxis.push(value.year);
                customers = customers.concat([value.firstName, value.secondName, value.thirdName, value.fourthName, value.fifthName]);
            });
            resData.mainSupplierList.reverse().forEach(value => {
                suppllier = suppllier.concat([value.firstName, value.secondName, value.thirdName, value.fourthName, value.fifthName]);
            });
            customers = unique(customers);
            customers.forEach((value, index) => {
                if (value) {
                    customerColor[value] = colors[index];
                }
            });
            suppllier = unique(suppllier);
            suppllier.forEach((value, index) => {
                if (value) {
                    supplierColor[value] = colors[index];
                }
            });
            resData.mainCustomerList.forEach((value, index) => {
                customerData[0].data.push({
                    name: value.firstName,
                    value: value.firstValue,
                    itemStyle: {
                        color:
                            customerColor[
                            value.firstName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                });
                customerData[1].data.push({
                    name: value.secondName,
                    value: value.secondValue,
                    itemStyle: {
                        color:
                            customerColor[
                            value.secondName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
                customerData[2].data.push({
                    name: value.thirdName,
                    value: value.thirdValue,
                    itemStyle: {
                        color:
                            customerColor[
                            value.thirdName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
                customerData[3].data.push({
                    name: value.fourthName,
                    value: value.fourthValue,
                    itemStyle: {
                        color:
                            customerColor[
                            value.fourthName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
                customerData[4].data.push({
                    name: value.fifthName,
                    value: value.fifthValue,
                    itemStyle: {
                        color:
                            customerColor[
                            value.fifthName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
            });

            resData.mainSupplierList.forEach(value => {
                supplierData[0].data.push({
                    name: value.firstName,
                    value: value.firstValue,
                    itemStyle: {
                        color:
                            supplierColor[
                            value.firstName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                });
                supplierData[1].data.push({
                    name: value.secondName,
                    value: value.secondValue,
                    itemStyle: {
                        color:
                            supplierColor[
                            value.secondName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                });
                supplierData[2].data.push({
                    name: value.thirdName,
                    value: value.thirdValue,
                    itemStyle: {
                        color:
                            supplierColor[
                            value.thirdName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
                supplierData[3].data.push({
                    name: value.fourthName,
                    value: value.fourthValue,
                    itemStyle: {
                        color:
                            supplierColor[
                            value.fourthName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })
                supplierData[4].data.push({
                    name: value.fifthName,
                    value: value.fifthValue,
                    itemStyle: {
                        color:
                            supplierColor[
                            value.fifthName
                            ]
                    },
                    tooltip: { show: true, formatter: '{b0}' }
                })

            });

            setData({
                customerLegend: customers,
                supplierLegend: suppllier,
                dataAxis,
                customerData,
                supplierData
            });
        }
        getData();
    }, []);

    return (
        !isIpoCompany && (
            <div>
                <Title level={3} text="主要客户及供应商"></Title>
                <Row className={styles.vendor}>
                    <Col span={24}>
                        {data.customerLegend && data.customerLegend.length ? (
                            <BarStack
                                title={{
                                    text: '前五大客户',
                                    textStyle: {
                                        fontSize: 16
                                    }
                                }}
                                dataAxis={data.dataAxis || []}
                                options={{
                                    legend: {
                                        data: data.customerLegend || []
                                    },
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            // 坐标轴指示器，坐标轴触发有效
                                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                                        },
                                        formatter: function(params) {
                                            let result =
                                                params[0].axisValue + '年';
                                            params.forEach(value => {
                                                if (value.value) {
                                                    result +=
                                                        '<br/>' +
                                                        value.name +
                                                        ': ' +
                                                        (value.value
                                                            ? moneyFormatter(
                                                                value.value
                                                            ) + '万'
                                                            : value.value);
                                                }
                                            });
                                            return result;
                                        }
                                    },
                                    series: data.customerData || []
                                }}
                            />
                        ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                    </Col>
                    <Col span={24}>
                        {data.customerLegend && data.customerLegend.length ? (
                            <BarStack
                                title={{
                                    text: '前五大供应商',
                                    textStyle: {
                                        fontSize: 16
                                    }
                                }}
                                dataAxis={data.dataAxis || []}
                                options={{
                                    legend: {
                                        data: data.supplierLegend || []
                                    },
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            // 坐标轴指示器，坐标轴触发有效
                                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                                        },
                                        formatter: function(params) {
                                            let result =
                                                params[0].axisValue + '年';
                                            params.forEach(value => {
                                                if (value.value) {
                                                    result +=
                                                        '<br/>' +
                                                        value.name +
                                                        ': ' +
                                                        (value.value
                                                            ? moneyFormatter(
                                                                value.value
                                                            ) + '万'
                                                            : value.value);
                                                }
                                            });
                                            return result;
                                        }
                                    },
                                    series: data.supplierData || []
                                }}
                            />
                        ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                    </Col>
                </Row>
            </div>
        )
    );
}
