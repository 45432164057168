/**
 * @author Ray
 * @desc 入库企业分析-详情首页
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Col, Row, Button, Tag, Tooltip, Empty, message, Skeleton } from 'antd';
import Title from '../../../common/title';
import styles from './styles.scss';
import { renderRoutes } from 'react-router-config';
import Information from './information';
import Topo from './enterpriseTopo';
import Match from './intermediaryMatch';
import { getCompanyBaseInfo } from './information/basicInformation/service';
import {
  isEmpty,
  isNotEmpty,
  transferStringNull,
  LimitNumber
} from '../../../utils/utils';
import { Selector } from '../../merging/home/apply';
import WithLabel from '../../../common/withLabel';
import * as actions from '../script/actions';
import { API, LabelsCanChanged } from '../../../constants/enum';
import { changeCompanyLabel } from './intermediaryMatch/service';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import request from '../../../utils/fetch';
import useAuthName from '../../../hooks/useAuthName';

export const Routes = [
  {
    exact: true,
    path: '/investment/enterpriseAnalysis/:companyName/:companyId/:companyCode',
    component: Information
  },
  {
    exact: true,
    path:
      '/investment/enterpriseAnalysis/:companyName/:companyId/:companyCode/topology',
    component: Topo
  },
  {
    exact: true,
    path:
      '/investment/enterpriseAnalysis/:companyName/:companyId/:companyCode/match',
    component: Match
  }
];

export function LabelSelector({ onChange, defaultValue }) {
  return (
    <WithLabel text="企业标签">
      <Selector
        defaultValue={defaultValue}
        style={{ width: '84%' }}
        onChange={onChange}
        options={LabelsCanChanged.map(label => ({
          value: label,
          text: label
        }))}
      />
    </WithLabel>
  );
}

export default function(props) {
  useEffect(() => {
    props.setBannerShow(false);
  }, []);
  const gs = useContext(GlobalContext);
  const label = useRef(null);
  const [isOwnedCompany] = useState(
    props.match.params.companyName === gs.user?.organization
  );
  const [basicInfo, setInfos] = useState({});
  const isAuth = useAuthName('14');
  const [isCompanyExist, setIsExist] = useState(true);
  function goto(address) {
    return props.history.push({
      pathname:
        props.match.path
          .replace(':companyId', props.match.params.companyId)
          .replace(':companyName', props.match.params.companyName)
          .replace(':companyCode', props.match.params.companyCode) + address
    });
  }

  // 更新label
  const updateLabel = useCallback(() => {
    changeCompanyLabel(props.match.params.companyId, label.current).then(
      res => {
        if (isNotEmpty(res)) {
          if (res.success) {
            message.success(res.message);
            props.dispatch(actions.closeModal());
            // 更新label
            setInfos({ ...basicInfo, label: label.current });
          } else {
            message.error(res.message);
          }
        }
      }
    );
  }, [label, basicInfo]);

  function showModal() {
    props.dispatch(
      actions.showModal({
        title: '修改企业标签',
        content: (
          <LabelSelector
            onChange={labelString => (label.current = labelString)}
            defaultValue={label.current}
          />
        ),
        onOk: updateLabel
      })
    );
  }

  function handleReportDownload() {
    request(
      `${API.V1}/auth/report/breed/upload`,
      {
        companyId: props.match.params.companyId,
        companyName: props.match.params.companyName,
        year: 2019
      },
      {
        method: 'post',
        responseType: 'blob'
      },
      blob => {
        if (blob.size === 105) {
          message.error('此用户没有下载报告的权限');
        } else if (blob.size === 87) {
          props.history.push('/user/login');
        } else {
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(
              blob,
              props.match.params.companyName + '分析报告.doc' || params.file
            );
          } else {
            let _link = document.createElement('a');
            _link.href = URL.createObjectURL(blob);
            _link.download = props.match.params.companyName + '分析报告.doc';
            _link.click();
            URL.revokeObjectURL(_link.href);
          }
        }
      }
    );
  }

  useEffect(() => {
    getCompanyBaseInfo(props.match.params.companyId).then(res => {
      if (isNotEmpty(res) && isNotEmpty(res.data)) {
        setInfos(res.data);
        label.current = res.data.label;
      } else {
        setIsExist(false);
      }
    });
  }, [props.match.params.companyName]);
  
  return isCompanyExist ? (
    <>
      <div className={styles.info}>
        <Skeleton loading={isEmpty(basicInfo)} active>
          <Title level={1} text={basicInfo.companyName}>
            {(gs.isGOV || gs.isQiYuan) && basicInfo.hignLabel && (
              <Tooltip title={basicInfo.industry}>
                <Tag color="gold">高精尖产业</Tag>
              </Tooltip>
            )}
            {isAuth && (
              <Button
                size="small"
                type="primary"
                onClick={handleReportDownload}
              >
                分析报告下载
              </Button>
            )}
          </Title>
          {isNotEmpty(basicInfo?.label) &&
            basicInfo.label.split(',').map(label => (
              <Tag visible={gs.isQiYuan} color="#87d068">
                {label}
              </Tag>
            ))}

          <div className={styles.box}>
            <Row>
              <Col span={12}>
                <Col span={5}>法人代表：</Col>
                <Col span={19}>{basicInfo.frname || '--'}</Col>
              </Col>
              <Col span={12}>
                <Col span={5}>注册资本：</Col>
                <Col span={19}>
                  {transferStringNull(basicInfo?.regcap) || '--'}
                </Col>
              </Col>
              <Col span={12}>
                <Col span={5}>注册地址：</Col>
                <Col span={19} title={basicInfo.address}>
                  <Tooltip title={basicInfo.address}>
                    {LimitNumber(basicInfo.address || '--', 22)}
                  </Tooltip>
                </Col>
              </Col>
              <Col span={12}>
                <Col span={5}>联系方式：</Col>
                <Col span={19}>{basicInfo.phone || '--'}</Col>
              </Col>
              <Col span={12}>
                <Col span={5}>公司网址：</Col>
                <Col span={19}>{basicInfo.webUrl || '--'}</Col>
              </Col>
              <Col span={12}>
                <Col span={5}>成立日期：</Col>
                <Col span={19}>{basicInfo.esdate || '--'}</Col>
              </Col>
              {basicInfo.label && basicInfo.label.includes('拟上市') ? (
                <Col span={12}>
                  <Col span={5}>拟上市板块：</Col>
                  <Col span={19}>{basicInfo.plate}</Col>
                </Col>
              ) : 
              basicInfo.label && basicInfo.label.includes('上市') ? (
                <Col span={12}>
                  <Col span={5}>上市板块：</Col>
                  <Col span={19}>{basicInfo.plate}</Col>
                </Col>
              ) : null}
              {basicInfo.stockCode !== null && (
                <Col span={12}>
                  <Col span={5}>证券代码：</Col>
                  <Col span={19}>{basicInfo.stockCode}</Col>
                </Col>
              )}
              <Col span={24}>
                <Col span={2}>公司简介：</Col>
                <Tooltip
                  title={basicInfo.description}
                  overlayStyle={{ maxWidth: 700 }}
                >
                  <Col span={21} className={styles.description}>
                    {basicInfo.description || '--'}
                  </Col>
                </Tooltip>
              </Col>
            </Row>
            <div className={styles.buttonGroup}>
              <Button
                block
                type="primary"
                size="small"
                onClick={() => goto('/topology')}
              >
                公司图谱
              </Button>
              {(gs.isVIPUser || gs.isAdvanceUser || gs.isNormalUser) &&
              !isOwnedCompany &&
              (gs.isEnterprise ||
                basicInfo.ipoCompany === '上市公司') ? null : (
                <Button
                  block
                  size="small"
                  type="primary"
                  onClick={() => goto('/match')}
                >
                  智能中介机构匹配
                </Button>
              )}
              <Button
                block
                type="primary"
                size="small"
                onClick={() => goto('')}
              >
                企业基本信息
              </Button>
            </div>
          </div>
        </Skeleton>
      </div>
      <div className={styles.root} key={props.match.params.companyName}>
        {renderRoutes(Routes, {
          ...props,
          operateScope: basicInfo.operateScope,
          creditCode: basicInfo.creditCode,
          isIpoCompany: basicInfo.ipoCompany === '上市公司'
        })}
      </div>
    </>
  ) : (
    <Empty description={'该企业不在启元系统的入库企业范围内'} />
  );
}
