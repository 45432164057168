'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _mergeData = require('../../../util/processor/mergeData');

var _circlePos = require('../../../util/coordinate/circlePos');

var _processor = require('../../../util/processor/processor');

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _dailog = require('./dailog');

var _dom = require('../../../util/dom');

var _eventFilter = require('./eventFilter');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 关系探寻图
 */
var EventChart = function (_Chart) {
    (0, _inherits3.default)(EventChart, _Chart);

    function EventChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, EventChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (EventChart.__proto__ || (0, _getPrototypeOf2.default)(EventChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'event';

        _this.size = (0, _extends3.default)({}, _this.painter._setting); // 尺寸

        // this.d3zoom = null; // 记录缩放，拖动的事件对象
        _this.state = null;
        _this.gData = null;
        _this.centerId = _this.options.data.bbdQyxxId;
        _this.info = {
            center: _this.getCenterPoint(),
            size: {
                nodesInterval: opts.nodesInterval || 40, // 节点间隔
                groupsInterval: opts.groupsInterval || 100, // 组的间隔
                circleInterval: opts.circleInterval || 40 // 圈层间隔
            }
        };
        _this.lock = false; // 动的时候，不要触发坐标计算
        return _this;
    }

    (0, _createClass3.default)(EventChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(EventChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(EventChart.prototype), 'beforeProcess', this).call(this);
        }
    }, {
        key: 'process',
        value: function process() {

            var state = this.getState();
            if (state.nodes === null) {
                console.warn('没有nodes 数据！');
                this.setState(state);
                return;
            }
            // 清除多余的点
            state = (0, _eventFilter.cleanDots)(state, this.centerId);

            this.countPos(state);
        }
    }, {
        key: 'afterProcess',
        value: function afterProcess() {
            var _this2 = this;

            (0, _get3.default)(EventChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(EventChart.prototype), 'handleDrag', this).call(this);
            // 显示弹窗
            this.handler.on('click', _dailog.eventDailog, this);
            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);

            // 最短路径
            this.handler.on('event.path', function (sid, eid) {
                var state = _this2.getState();
                var data = (0, _processor.getShortPathById)(sid, eid, _this2.gData, state);
                var _gData = _this2.gData,
                    nodes = _gData.nodes,
                    links = _gData.links;

                nodes.forEach(function (d) {
                    d.slight = true;
                });
                links.forEach(function (d) {
                    d.slight = true;
                });
                data.nodes.forEach(function (d) {
                    d.slight = false;
                });
                data.links.forEach(function (d) {
                    d.slight = false;
                });
                _this2.setState(state);
            });

            // 扩展点 event.extend
            this.handler.on('event.extension', function (data) {
                if (!data || data.data.bbdEventId) {
                    return;
                }
                var id = data.id;
                _this2.store.loadNewState({
                    bbdQyxxId: id,
                    degree: 1,
                    date: data.date
                }, 'extension').then(function (d) {
                    // 数据过滤
                    d = (0, _eventFilter.cleanDots)(d, id);
                    var state = _this2.getState();
                    state = (0, _mergeData.mergeOriginData)(state, d, 'add');
                    var gData = _this2.store.toGridState(state, _this2.centerId);
                    _this2.countPos(gData);
                }, function (err) {
                    _this2.handler.trigger('event.error', err);
                });
            });

            // 切换中心点 community.extend
            this.handler.on('event.reRender', function (data) {
                if (data.reset) {
                    _this2.resetTransform();
                }

                _this2.date = data.date;
                _this2.store.loadNewState({
                    degree: data.degree,
                    bbdQyxxId: data.id,
                    date: data.date
                }, 'init').then(function (d) {
                    _this2.centerId = data.id;
                    var gData = _this2.store.toGridState(d, _this2.centerId);
                    _this2.countPos(gData);
                }, function (err) {
                    _this2.handler.trigger('event.error', err);
                });
            });

            // 收起点
            this.handler.on('event.packup', function (d) {
                if (!d || d.data.bbdEventId) {
                    return;
                }
                if (d && d.shapeType !== 'node') {
                    return;
                }
                var id = d.id;
                var state = _this2.getState();
                var gData = _this2.store.toGridState(state, _this2.centerId);
                var data = (0, _processor.flodByIds)([id], gData, _this2.centerId);
                state = (0, _mergeData.mergeOriginData)(state, data, 'del');
                _this2.countPos(state);
            });

            // 删除点
            this.handler.on('event.hide', function (node) {
                if (node) {
                    var state = _this2.getState();
                    var gData = _this2.store.toGridState(state, _this2.centerId);
                    var data = (0, _processor.delByIds)([node.id], gData, _this2.centerId);
                    state = (0, _mergeData.mergeOriginData)(state, data, 'del');
                    _this2.countPos(state);
                } else {
                    var _getState = _this2.getState(),
                        nodes = _getState.nodes,
                        links = _getState.links;

                    nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    links.forEach(function (d) {
                        d.slight = false;
                    });
                    _this2.setState({ nodes: nodes, links: links });
                }
            });

            // 高亮点
            this.handler.on('event.highlight', function (node) {
                if (node && node.shapeType !== 'node') {
                    return;
                }
                var state = _this2.getState();
                var gData = _this2.store.toGridState(state, _this2.centerId);
                var nodes = gData.nodes,
                    links = gData.links;

                if (node) {
                    var data = null;
                    if (node.data.bbdQyxxId) {
                        data = (0, _eventFilter.getHightLightCompany)(node.id, gData);
                    } else {
                        data = (0, _processor.getHightLight)(node.id, gData);
                    }

                    if (!data) {
                        console.error('高亮数据有错！', node);
                        return;
                    }
                    nodes.forEach(function (d) {
                        d.slight = true;
                    });
                    links.forEach(function (d) {
                        d.slight = true;
                    });
                    data.nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    data.links.forEach(function (d) {
                        d.slight = false;
                    });
                } else {
                    nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    links.forEach(function (d) {
                        d.slight = false;
                    });
                }
                _this2.setState({ nodes: nodes, links: links });
            });
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.force && this.force.stop();
            (0, _get3.default)(EventChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(EventChart.prototype), 'dispose', this).call(this);

            this.handler.off('click', _dailog.eventDailog);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('dblclick', this.handleExtension);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);

            (0, _dom.removeDom)('event-chart-dailog');

            this.force = null;
            this.painter = null;
            this.store = null;
            this.handler = null;
            this.gData = null;
        }
    }, {
        key: 'countPos',
        value: function countPos(state) {
            var _size = this.size,
                width = _size.width,
                height = _size.height;

            // 计算圈层图

            (0, _circlePos.circlePos)(state, this.info);

            // 设置
            this.setState(state);

            if (state && state.nodes && state.nodes.length > 0) {
                // 获取数据
                this.handler.trigger('chart.getSettingStatus');
                this.handler.trigger('chart.changeList', state);
            }
        }
    }]);
    return EventChart;
}(_Chart3.default);

exports.default = EventChart;
module.exports = exports['default'];