import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';


//重要节点企业名单
export function getQueryImpIndexCompany(params) {
    return request(`${API.V1}/auth/kcDriver/index/queryImpIndexCompany`,params);
}
//风险变化趋势
export function riskTrends(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/leftFirst/riskTrendLine`, params);
}