import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { isNotEmpty } from '../../utils/utils';
/**
 * @desc  仪表盘
 */
export default class Gauge extends PureComponent {
    getOption() {
        const { dataAxis = [], data = [] } = this.props;
        return {
            series: [
                {
                    type: 'gauge',
                    detail: {
                        show: true,
                        // formatter: value => (value > 0 ? value.toFixed(2) : 0)
                    },
                    data: isNotEmpty(data)
                        ? data
                        : [{ value: 50, name: '风险总分' }]
                }
            ]
        };
    }
    render() {
        const { style = { height: '310px' }, nodata, showLoading } = this.props;
        return nodata ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <ReactEcharts
                option={this.getOption()}
                notMerge={true}
                style={style}
                theme={'myTheme'}
                showLoading={showLoading}
            />
        );
    }
}
