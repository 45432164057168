import React, { useState, useEffect } from 'react';
import { Permission } from '../../../hooks/useAuth';
import Body from "./layout/body";
import TopTitle from "./component/topTitle";
import Drawer from "./component/drawer";
import Card from "./component/card";
import styles from './styles.scss';
import zuo1Pic from './images/zuo-1.png';
import zuo2Pic from './images/zuo-2.png';
import zuo3Pic from './images/zuo-3.png';
import zuo4Pic from './images/zuo-4.png';
import you1Pic from './images/you-1.png';
import you4Pic from './images/you-4.png';
import LeftFirst from './leftFirst';
import LeftSecond from './leftSecond'
import LeftThird from './leftThird'
import RightFirst from './rightFirst'
import RightSecond from './rightSecond'
import RightThird from './rightThird'
import CenterTop from './centerTop'
import CenterButtom from './centerButtom'
import {getTheme} from "@antv/g2";
import zhongjianPic from "./images/zhongjian.png";
import Radios from "./centerTop/radio";
import zhongxiaPic from "../domesticStock/images/zhongxia.png";
import Footer from '../common/footer'

export default function All() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '全部', value: 1 },
        { name: '基础层', value: 2 },
        { name: '创新层', value: 3 },
    ];

    const defaultTheme = getTheme();

    defaultTheme.labels.style.fill = "#fff";

    defaultTheme.components.axis.left.label.style.fill = "#fff";
    defaultTheme.components.axis.right.label.style.fill = "#fff";
    defaultTheme.components.axis.bottom.label.style.fill = '#fff';
    defaultTheme.components.axis.circle.label.style.fill = '#fff';
    defaultTheme.components.axis.radius.label.style.fill = '#fff';
    defaultTheme.components.legend.bottom.itemName.style.fill = '#fff';
    defaultTheme.components.legend.right.itemName.style.fill = '#fff';

    return(
        <Permission permission={503}>
            <Body>
                <TopTitle/>
                <Drawer position="left" style={{ width: '28vw', left: window.screen.width < 1920 ? '25px' : '30px' }}>
                    <Card
                        title={
                            <div className={ styles.leftFirstTitle }>挂牌公司行业分布情况</div>
                        }
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: window.screen.width < 1920 ? '20px' : '30px',
                            background: `url(${you1Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}>
                        <LeftFirst/>
                    </Card>
                    <Card
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: window.screen.width < 1920 ? '20px' : '30px',
                            background: `url(${zuo2Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}>
                        <LeftSecond type={type}/>
                    </Card>
                    <Card
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: window.screen.width < 1920 ? '20px' : '30px',
                            background: `url(${zuo4Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}>
                        <LeftThird type={type} />
                    </Card>
                </Drawer>
                <Drawer position="right" style={{ width: '28vw', right: window.screen.width < 1920 ? '-10px' : '-15px', }}>
                    <Card
                        title={
                            <div className={ styles.leftFirstTitle }>
                                股票发行数量
                            </div>
                        }
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: '30px',
                            background: `url(${you1Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}
                    >
                        <RightFirst />
                    </Card>
                    <Card
                        title={
                            <div className={ styles.leftFirstTitle }>
                                股票市值
                            </div>
                        }
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: window.screen.width < 1920 ? '15px' : '30px',
                            background: `url(${zuo2Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}
                    >
                        <CenterButtom />
                        {/*<RightSecond />*/}
                    </Card>
                    <Card
                        title={
                            <div className={ styles.leftFirstTitle }>
                                在审申请挂牌企业基本情况一览
                            </div>
                        }
                        style={{
                            width: '25vw',
                            height: '29vh',
                            marginTop: window.screen.width < 1920 ? '15px' : '30px',
                            background: `url(${you4Pic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                        }}
                    >
                        <RightSecond />
                        {/*<RightThird/>*/}
                    </Card>
                </Drawer>
                <div
                    style={{
                        background: `url(${zhongjianPic})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        height: '93%',
                        width: '45%',
                        marginLeft: '27.2%',
                        marginTop: window.screen.width < 1920 ? '30px' : '50px',
                        position: 'relative',
                    }}
                >
                    <div className={styles.selector}>
                        <Radios
                            value={type}
                            data={radiosData}
                            defaultValue={1}
                            onChange={e => setType(e.target.value)}
                        />
                    </div>
                    <CenterTop type={type} style={{ height: '100%' }}/>

                    <div
                        style={{
                            width: '44vw',
                            height: '23vh',
                            marginLeft: 10,
                            background: `url(${zhongxiaPic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            position: 'relative'
                        }}
                    >
                        {/*<CenterButtom />*/}
                        <RightThird/>
                    </div>
                    <Footer/>
                </div>
            </Body>
        </Permission>
    )
}
