import request from '../../utils/fetch';
import { API } from '../../constants/enum';

/**
 * @desc 发送验证码
 * @param phone
 * @param template
 * @param pictureCode
 */
export function sendMessage(phone, template, pictureCode) {
    return request(
        `${API.V1}/sso/sms/captcha`,
        {
            phone,
            template,
            pictureCode
        },
        {
            method: 'post'
        }
    );
}
