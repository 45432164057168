/**
 * @author Ray
 * @desc 入库企业分析-基础信息-核心竞争力-软件著作
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { SoftwareColumns } from './columns';
import { softwareWorks } from './service';
import { isNotEmpty } from '../../../../../utils/utils';
import Title from '../../../../../common/title';

export default function SoftwareWorks({ match }) {
    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <>
            <Title level={3} text="软件著作" />
            <Table
                query={query}
                parseData={res =>
                    (isNotEmpty(res) && isNotEmpty(res.data) && res.data) || []
                }
                getData={softwareWorks}
                columns={SoftwareColumns}
                locale={{
                    emptyText:
                        '未发现企业的软件著作记录。不排除存在未公示或时间相对滞后的情况。'
                }}
            />
        </>
    );
}
