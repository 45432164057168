/**
 * @author Ray
 * @desc 转让申请
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Title from '../../../common/title';
import Back from '../../../common/backBtn';
import styles from './styles.scss';
import { Divider, message } from 'antd';
import EnterpriseInformation from '../common/enterpriceInfo';
import Financial from '../../../common/financialTabs';
import Transaction from './transaction';
import Footer from '../common/footer';
import Structure from './structure';
import { applyDetail, editDetail, saveMergeApply } from '../service';
import { closeModal, showModal } from '../../investment/script/actions';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import moment from 'moment';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { shareholderStructure } from '../service';

let reservedMergeId = undefined;
let reservedId = undefined;

export default function View({
  history,
  dispatch,
  match,
  location,
  setBannerShow
}) {
  const state = useRef({});
  const entRef = useRef(null);
  const transactionRef = useRef(null);
  const { user } = useContext(GlobalContext);
  const [structureSource, setSource] = useState([]);
  const [isPreview] = useState(function() {
    return location.pathname.includes('preview');
  });
  const [isEdit] = useState(function() {
    return isNotEmpty(match.params);
  });
  const [needUpdate, setNeedUpdate] = useState(0);
  function handleChange(values) {
    state.current = {
      ...state.current,
      ...values
    };
  }

  function action(type) {
    let isSubmit = 'submit' === type;
    return function() {
      function commit() {
        dispatch(closeModal());
        entRef.current.validateFieldsAndScroll(function(errors) {
          if (!errors) {
            state.current.needUpdate = needUpdate;
            state.current.type = 3;
            if (isSubmit) {
              state.current.merge.applicationStatus = 2;
            } else {
              state.current.merge.applicationStatus = 1;
            }
            if (isEdit) {
              state.current.merge.id = Number(match.params.id);
              state.current.merge.mergeId = reservedMergeId;
              state.current.merge.applicationCategory = 2;
              if (isNotEmpty(state.current.mergeQualification)) {
                state.current.mergeQualification.mergeId = reservedMergeId;
                state.current.mergeQualification.id = reservedId;
              }
            }
            if (isEmpty(state.current.mergeEquity)) {
              state.current.mergeEquity = [];
            }
            if (isEmpty(state.current.mergeQualification)) {
              state.current.mergeQualification = {};
            }
            // 更新的时候附上申请的mergeId
            state.current.mergeEquity = state.current.mergeEquity.map(m =>
              m.mergeId
                ? m
                : Object.assign({}, m, {
                    mergeId: reservedMergeId
                  })
            );
            state.current.merge.userId = user?.id;
            state.current.merge.enterpriseName = user?.organization;
            state.current.merge.creditCode = user?.creditCode;
            let func = isEdit ? editDetail : saveMergeApply;

            func(state.current).then(function(res) {
              if (res.success) {
                message.success(res.message);
                dispatch(closeModal());
                history.push({
                  pathname: '/merging'
                });
              } else {
                message.error(res.message);
              }
            });
          } else {
            message.warn('存在未填写的选项!');
          }
        });
      }

      isSubmit
        ? dispatch(
            showModal({
              title: '提示',
              content: '确认提交申请？',
              onOk: () => commit(),
              onCancel: () => dispatch(closeModal()),
              onClose: () => dispatch(closeModal())
            })
          )
        : commit();
    };
  }

  useEffect(() => {
    setBannerShow(false);
    !isPreview &&
      !isEdit &&
      shareholderStructure().then(res => {
        setSource(res.data.map((t, i) => ({ ...t, index: i })));
        state.current = {
          ...state.current,
          mergeEquity: res.data
        };
        // setShareholders(res.data);
      });
  }, []);

  useEffect(function() {
    const {
      params: { id }
    } = match;

    if (isNotEmpty(id)) {
      applyDetail(id).then(function(res) {
        if (isNotEmpty(res.data)) {
          let { merge, mergeEquity, mergeQualification } = res.data;
          state.current = {
            merge,
            mergeEquity
          };
          if (isNotEmpty(mergeQualification)) {
            state.current = {
              ...state.current,
              mergeQualification
            };
            transactionRef.current.setFieldsValue({
              ...mergeQualification,
              paymentTime: mergeQualification?.paymentTime
                ? moment(mergeQualification.paymentTime, 'x')
                : undefined
            });
            reservedId = mergeQualification.id;
          }
          entRef.current.setFieldsValue({ ...merge });
          setSource(mergeEquity.map((t, i) => ({ ...t, index: i })));
          reservedMergeId = merge.id;
        }
      });
    }
  }, []);

  return (
    <div className={styles.root}>
      <Back history={history} url="/merging" />
      <Title level={1} text="标的申请" />
      <Divider />
      <Title level={2} text="转让标的基本情况" />
      <EnterpriseInformation
        showConfig
        isEdit={isEdit}
        isPreview={isPreview}
        onChange={handleChange}
        onInit={ref => (entRef.current = ref)}
      />
      <Title level={2} text="财务信息" />
      <Financial
        readOnly
        type={3}
        history={history}
        applyId={match.params?.id}
        status={isPreview ? 5 : -1}
        onRefreshClick={() => setNeedUpdate(1)}
      />
      <Title level={2} text="标的企业股权结构" />
      <Structure
        isPreview={isPreview}
        dataSource={structureSource}
        onChange={handleChange}
      />
      <Title level={2} text="交易条件与受让方资格条件" />
      <Transaction
        onChange={handleChange}
        isPreview={isPreview}
        onInit={ref => (transactionRef.current = ref)}
      />
      {!isPreview && <Footer submit={action('submit')} save={action('save')} />}
    </div>
  );
}
