'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.eventDailog = eventDailog;

var _keyName = require('./keyName');

var _dom = require('../../../util/dom');

/**
 * @desc 模板
*/
function tpls(_ref) {
    var clientX = _ref.clientX,
        clientY = _ref.clientY,
        title = _ref.title,
        content = _ref.content;

    if (window.innerHeight < clientY + 240) {
        clientY = window.innerHeight - 240;
    }
    if (window.innerWidth < clientX + 600) {
        clientX = window.innerWidth - 620;
    }
    return '<div class="event-chart-dailog" style="position: absolute; \n    max-width: 600px;\n    font-size: 14px; \n    z-index: 9999; \n    color: #fff; \n    left: ' + clientX + 'px; \n    top: ' + clientY + 'px; \n    background: rgba(0,0,0,.6); \n    border-radius: 6px; padding: 10px;">\n        <h4 style="margin: 5px 0; font-size: 15px; color: #fff;">' + title + '</h4>\n        ' + content + '\n    </div>';
}

/**
 * @desc 事件弹窗
*/
function eventDailog(e) {
    if (e.target && e.target.data && e.target.data.bbdQyxxId === undefined) {

        var category = {
            debit: '借贷担保', // 借贷担保
            litigation: '诉讼记录', // 诉讼
            bidding: '招投标信息' // 招投标
        };
        var title = category[e.target.category];
        var _e$event = e.event,
            clientX = _e$event.clientX,
            clientY = _e$event.clientY;


        var keyToVal = function keyToVal(val, key) {
            if (val instanceof Array) {
                var str = [];
                val.forEach(function (d) {
                    str.push('\uFF08' + d.mode + '\uFF09' + d.sponsor);
                });
                val = str.join('</ br>');
            }

            // 如果状态是数字，不显示
            if (key === 'caseState' && /[0-9]+/.test(val)) {
                val = '';
            }
            return val;
        };

        if (!title) {
            if (e.target.data.bbdAddressId && this.eventMoveId !== e.target.id) {
                this.eventMoveId = e.target.id;
                (0, _dom.removeDom)('event-chart-dailog');
                (0, _dom.appendHTML)(document.body, tpls({
                    clientX: clientX,
                    clientY: clientY,
                    title: '地址',
                    content: e.target.data.name
                }));
            }
            return;
        }

        if (this.eventMoveId !== e.target.id) {
            var _e$target = e.target,
                id = _e$target.id,
                data = _e$target.data;

            this.eventMoveId = id;
            this.store && this.store.load('common', 'eventDetail', {
                bbdEventId: id,
                labels: data.labels
            }).catch(function (err) {
                throw err;
            }).then(function (res) {
                (0, _dom.removeDom)('event-chart-dailog');
                var content = '';
                for (var key in res) {
                    content += '<p style="margin:0; margin-bottom: 5px;">\n                                    <label style="display: inline-block; width: 100px; vertical-align: top;">' + (_keyName.keyName[e.target.category][key] || '') + ':</label>\n                                    <span style="display: inline-block; max-width: 470px;">' + keyToVal(res[key] || '', e.target.category) + '</span>    \n                                </p>';
                }
                (0, _dom.appendHTML)(document.body, tpls({
                    clientX: clientX,
                    clientY: clientY,
                    title: title,
                    content: content
                }));
            });
        }
    } else {
        (0, _dom.removeDom)('event-chart-dailog');
        this.eventMoveId = null;
    }
}