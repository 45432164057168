/**
 * @author Ray
 */
import React from 'react';
import { getProcessList } from './service';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';

export default class extends React.Component {
    state = {
        dataSource: []
    };
    componentDidMount() {
        const { match } = this.props;

        getProcessList(match.params.id).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                this.setState({
                    dataSource: res.data
                });
            } else {
                this.setState({
                    dataSource: []
                });
            }
        });
    }
    render() {
        return this.props.children({
            ...this.props,
            ...this.state
        });
    }
}
