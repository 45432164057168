import React, {useState} from 'react';
import { Table } from 'antd'
import {transferStringNull} from 'utils/utils';

const getColumns = (page) => {
  return [
        {
            title: '序号',
            dataIndex: 'num',
            key: 'num',
            render: (text, item, index) => {
                return (index + 1) +  (page - 1) * 10;
            },
            // width: '5%'
        },
        {
            title: '公司名称',
            dataIndex: 'name',
            key: 'name',
            render: transferStringNull
            // width: '14%'
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            render: transferStringNull
        }
    ];
}

export default function(props) {
    const [page, setPage] = useState(1);
    const columns = getColumns(page);
    return (
        <Table 
            columns={columns} 
            dataSource={props.data} 
            pagination = {{
                hideOnSinglePage: true,
                onChange: (page) => setPage(page)
            }}
        />
    );
}
