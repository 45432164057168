/**
 * @author Ray
 * @desc 入库企业分析-基础信息-财务风险点汇总
 */
import React, { useEffect, useState } from 'react';
import Title from '../../../../../common/title';
import classnames from 'classnames';
import styles from './styles.scss';
import { Icon } from 'antd';
import { getRiskSummary } from './service';

export default function RiskSummary({ match, callback }) {
    const [tableDatas, setDatas] = useState([]);
    const [message, setMessage] = useState('');
    const [query, setQuery] = useState({ qyxxId: match.params.companyId });

    useEffect(() => {
        getRiskSummary({ qyxxId: match.params.companyId }).then(res => {
            const { data, success } = res;

            if (success) {
                setDatas(data.risks); // data.summaryList
                setMessage(data.riskMessage);
                callback(data);
            }
        });
    }, []);

    return (
        <div className={styles.root}>
            <Title level={3} text="财务风险点汇总">
                {/*<span style={{ fontSize: 14, color: 'red' }}>{message}</span>*/}
            </Title>
            {tableDatas && tableDatas.length ? (
                tableDatas.map(ele => {
                    const { name, message, indices, point } = ele;
                    return (
                        <div className={classnames(styles.item)} key={name}>
                            <div className={styles.total}>
                                <span className={styles.line}>
                                    <b className={styles.blue}>{name}</b>
                                </span>
                                <span className={styles.line}>
                                    风险评分：
                                    <span className={styles.red}>
                                        <b>
                                            <span className={styles.score}>
                                                {point.toFixed(2)}
                                            </span>
                                            分
                                        </b>
                                    </span>
                                </span>
                                {message && (
                                    <span
                                        className={classnames(
                                            styles.red,
                                            styles.tips
                                        )}
                                    >
                                        <Icon
                                            type="exclamation-circle"
                                            theme="filled"
                                        />{' '}
                                        说明：{message}{' '}
                                    </span>
                                )}
                            </div>
                            {indices && indices.length ? (
                                indices.map(item => {
                                    const {
                                        exIndex,
                                        explain,
                                        year,
                                        point
                                    } = item;
                                    return (
                                        <div>
                                            <div
                                                className={styles.grayBg}
                                                key={exIndex}
                                            >
                                                <p>
                                                    <span>
                                                        <Icon
                                                            type="caret-right"
                                                            className={
                                                                styles.blue
                                                            }
                                                        />
                                                        异常指标：
                                                    </span>
                                                    <b
                                                        className={
                                                            styles.orange
                                                        }
                                                    >
                                                        {exIndex}
                                                    </b>
                                                </p>
                                                <p className={styles.explain}>
                                                    {explain}
                                                </p>
                                            </div>
                                            <table className="comTable">
                                                <thead>
                                                    <tr>
                                                        <th>时间</th>
                                                        <th>{exIndex}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{year}</td>
                                                        <td>{point}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={styles.grayBg}>
                                    暂无异常指标项
                                </div>
                            )}
                        </div>
                    );
                })
            ) : (
                <div className={styles.grayBg}>暂无异常指标项</div>
            )}
        </div>
    );
}
