/**
 * @desc 外商企业上市列表搜索
 * @author hexinkui
 */
import React, { useEffect, useState } from 'react';
import styles from '../investment/enterpriseAnalysis/styles.scss';
import { Form, Cascader, AutoComplete, Checkbox } from 'antd';
import { Industry2, getCascaderProvince, CompanyType } from '../../constants/enum';
import { GroupInput } from '../merging/acquisition/intention';
import { Selector } from '../merging/home/apply';
import GroupYearPicker from '../../common/groupYearPicker';
import { getLabels } from '../investment/enterpriseAnalysis/service';

const { Item } = Form;

function SearchConditions({ form }, ref) {
    const { getFieldDecorator } = form;

    const [labelSource, setLabelSource] = useState([]);
    const CheckboxGroup = Checkbox.Group;
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    //控制反选
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < CompanyType.length);
        setCheckAll(list.length === CompanyType.length);
        form.setFieldsValue({
            listStatus: list
        })
    }

    //控制全选
    const onCheckAllChange = e => {
        const allList = CompanyType.map(item => item.value);
        setCheckedList(e.target.checked ? allList : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        form.setFieldsValue({
            listStatus: allList
        })
    };
    

    React.useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
        getLabels().then(res => {
            setLabelSource(res.data || []);
        });
    }, []);

    return (
        <Form
            className={styles.conditions}
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
        >
            <Item label="所属行业">
                {getFieldDecorator('industry', {
                    initialValue: null,
                    rules: []
                })(<Selector showSelectAll options={Industry2} />)}
            </Item>
            <Item label="所属地区">
                {getFieldDecorator('companyProvince', {
                    rules: [],
                    initialValue: ['北京', null]
                    // getValueFromEvent: values => values.slice(-1)[0]
                })(
                    <Cascader
                        allowClear={false}
                        style={{ width: 400 }}
                        options={getCascaderProvince()}
                        placeholder={'请选择所在地区'}
                        getPopupContainer={node => node.parentElement}
                    />
                )}
            </Item>
            <Item label="注册资本(万元)">
                {getFieldDecorator('regcap', {
                    initialValue: []
                })(<GroupInput min={0} />)}
            </Item>
            <Item label="注册时间">
                {getFieldDecorator('year', {
                    initialValue: []
                })(<GroupYearPicker />)}
            </Item>
            <Item label="企业类型">
                {getFieldDecorator('listStatus', {
                    initialValue: ["上市,外资企业", "拟上市,外资企业", "长期培育,外资企业"],
                    rules: []
                })(
                    // <Selector options={CompanyType} />
                    <>
                        {CompanyType && CompanyType.length > 0 && <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全部</Checkbox>}
                        <CheckboxGroup options={CompanyType} value={checkedList} onChange={onChange} />
                    </>
                )}
            </Item>
        </Form>
    );
}

export default function({ elementRef }) {
    const [E, setE] = useState(null);

    useEffect(function() {
        const Form_ = Form.create({
            name: 'search'
        })(React.forwardRef(SearchConditions));

        setE(<Form_ wrappedComponentRef={elementRef} />);
    }, []);

    return E;
}
