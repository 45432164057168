/**
 * @author Ray
 * @desc 社会责任评价-上市公益活动
 */

import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState
} from 'react';
import Table from '../../../../common/tableEx';
import { listedWelfareColumns } from './columns';
import { EditButton, DeleteButton } from '../../../../common/buttons';
import Title from '../../../../common/title';
import { Button, Form, DatePicker, Input, Empty } from 'antd';
import { Required } from '../../../../utils/validator';
import { deleteAppraise, querySsgy, addEditAppraise } from './service';
import { isNotEmpty, isEmpty } from '../../../../utils/utils';
import useActions from './actionHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import moment from 'moment';

export function ActivityForm({ form }, ref) {
    useImperativeHandle(ref, () => ({
        form
    }));
    return (
        <Form labelCol={{ xs: 6 }} wrapperCol={{ xs: 15 }}>
            <Form.Item label="活动内容">
                {form.getFieldDecorator('activityContent', {
                    rules: [Required]
                })(
                    <Input.TextArea
                        autosize={{
                            maxRows: 6,
                            minRows: 4
                        }}
                        maxLength={50}
                    />
                )}
            </Form.Item>
            <Form.Item label="参与公司">
                {form.getFieldDecorator('joinCompany', {
                    rules: [Required]
                })(
                    <Input.TextArea
                        autosize={{
                            maxRows: 6,
                            minRows: 4
                        }}
                        maxLength={50}
                    />
                )}
            </Form.Item>
            <Form.Item label="活动时间">
                {form.getFieldDecorator('activityTime', {
                    rules: [Required]
                })(
                    <DatePicker
                        disabledDate={current =>
                            current && current > moment().endOf('day')
                        }
                    />
                )}
            </Form.Item>
        </Form>
    );
}

export default function ListedPublicWelfareActivities({ dispatch, match }) {
    const [showList, setShowList] = useState(true);
    const gs = useContext(GlobalContext);
    const [isOwnCompany] = useState(
        gs.user?.organization === match.params.companyName
    );
    const [addRecord, editRecord, delRecord, , _query] = useActions({
        dispatch,
        query: { companyName: match.params.companyName, type: 1 },
        update: addEditAppraise,
        remove: deleteAppraise,
        create: addEditAppraise,
        form: forwardRef(ActivityForm),
        callback: () => {
            setShowList(true);
        }
    });

    function parseData(res) {
        if (isNotEmpty(res)) {
            if (isEmpty(res.data)) {
                setShowList(false);
                return [];
            } else {
                setShowList(true);
                return res.data;
            }
        }
        return [];
    }

    return (
        <>
            <Title level={2} text="上市公益活动">
                {isOwnCompany && (
                    <>
                        <p style={{ flex: 1 }} />
                        <Button
                            type="primary"
                            onClick={addRecord('添加上市公益活动记录')}
                        >
                            新增
                        </Button>
                    </>
                )}
            </Title>
            {showList ? (
                <Table
                    query={_query}
                    columns={
                        isOwnCompany
                            ? listedWelfareColumns.concat({
                                  title: '',
                                  align: 'center',
                                  render: (text, record) => (
                                      <>
                                          <EditButton
                                              text="编辑"
                                              onClick={editRecord(record)(
                                                  '修改上市公益活动记录'
                                              )}
                                          />
                                          <DeleteButton
                                              onClick={delRecord(record)(
                                                  '删除上市公益活动记录'
                                              )}
                                          />
                                      </>
                                  )
                              })
                            : listedWelfareColumns
                    }
                    getData={querySsgy}
                    parseData={parseData}
                    showPagination={10}
                />
            ) : (
                <Empty description="该中介机构暂未维护自身社会责任评价" />
            )}
        </>
    );
}
