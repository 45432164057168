import moment from 'moment';
import { TimeFormat } from '../../../../constants/enum';
import {
    highlight,
    limitString,
    moneyFormatter,
    transferStringNull
} from '../../../../utils/utils';
import React from 'react';
import { Tooltip } from 'antd';

export const historyColumns = [
    {
        key: 'zjtdInInstitude',
        title: '中介机构名称',
        dataIndex: 'zjtdInInstitude',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'pubdate',
        title: '申报日期',
        dataIndex: 'pubdate',
        align: 'center',
        width: '10%',
        render: text => moment(text).format(TimeFormat)
    },
    {
        key: 'location',
        title: '上市板块',
        dataIndex: 'location',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        key: 'company',
        title: '辅导公司名称',
        dataIndex: 'company',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        key: 'industryName',
        title: '证监会二级行业名称',
        dataIndex: 'industryName',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'industryCode',
        title: '证监会二级行业代码',
        dataIndex: 'industryCode',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        key: 'csrcStatus',
        title: '服务结果',
        dataIndex: 'csrcStatus',
        align: 'center',
        width: '10%',
        render: transferStringNull
    }
];

export const shareholdersColumns = [
    {
        key: 'shareholderName',
        title: '姓名',
        dataIndex: 'shareholderName',
        align: 'center',
        width: '30%'
    },
    {
        key: 'investAmount',
        title: '持有股数',
        dataIndex: 'investAmount',
        align: 'center',
        width: '20%',
        render: text => (text === '--' ? '未公示' : text)
    },
    {
        key: 'investRatio',
        title: '股权比例',
        dataIndex: 'investRatio',
        align: 'center',
        width: '20%',
        render: text => (text === '--' ? '未公示' : text)
    },
    {
        key: 'investName',
        title: '出资方式',
        dataIndex: 'investName',
        align: 'center',
        width: '30%',
        render: text => (text === '--' ? '未公示' : text)
    },
    {
        dataIndex: 'subAmount',
        title: '出资额',
        align: 'center',
        width: '20%',
        render: text =>
            text === '--' || text === ''
                ? '未公示'
                : moneyFormatter((parseFloat(text) / 10000).toFixed(2)) + '万'
    }
];

export const teamMembersColumns = [
    {
        title: '成员名称',
        dataIndex: 'name',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        title: '证书编号',
        dataIndex: 'licenseNum',
        align: 'center',
        width: '40%',
        render: transferStringNull
    },
    {
        title: '工作年限',
        dataIndex: 'workYear',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        title: '登记日期',
        dataIndex: 'regdateTime',
        align: 'center',
        width: '15%',
        render: text => {
            return text
                ? moment(text, 'x').format(TimeFormat)
                : transferStringNull(text);
        }
    }
];

export const branchOfficeColumns = [
    {
        key: 'name',
        title: '名称',
        dataIndex: 'name',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        key: 'address',
        title: '地址',
        dataIndex: 'address',
        align: 'center',
        width: '45%',
        render: transferStringNull
    },
    {
        key: 'frname',
        title: '负责人',
        dataIndex: 'frname',
        align: 'center',
        width: '20%',
        render: transferStringNull
    }
];

export const businessChangeColumns = [
    {
        title: '变更事项',
        dataIndex: 'changeItems',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        title: '变更前内容',
        dataIndex: 'contentBeforeChange',
        align: 'center',
        width: '35%',
        render: (text, record) => {
            let isTooLong = record.contentBeforeChange.length > 120;
            let originText = isTooLong
                ? limitString(120, record.contentBeforeChange)
                : record.contentBeforeChange;
            let newText = highlight(originText, record.contentAfterChange);
            newText = transferStringNull(newText);
            return isTooLong ? (
                <Tooltip
                    overlayStyle={{ minWidth: 650 }}
                    title={record.contentBeforeChange}
                >
                    <p
                        style={{ wordBreak: 'break-all' }}
                        dangerouslySetInnerHTML={{ __html: newText }}
                    />
                </Tooltip>
            ) : (
                <p
                    style={{ wordBreak: 'break-all' }}
                    dangerouslySetInnerHTML={{ __html: newText }}
                />
            );
        }
    },
    {
        title: '变更后内容',
        dataIndex: 'contentAfterChange',
        align: 'center',
        width: '35%',
        render: (text, record) => {
            let isTooLong = record.contentAfterChange.length > 120;
            let originText = isTooLong
                ? limitString(120, record.contentAfterChange)
                : record.contentAfterChange;
            let newText = highlight(originText, record.contentBeforeChange);
            newText = transferStringNull(newText);
            return isTooLong ? (
                <Tooltip
                    overlayStyle={{ minWidth: 650 }}
                    title={record.contentAfterChange}
                >
                    <p
                        style={{ wordBreak: 'break-all' }}
                        dangerouslySetInnerHTML={{ __html: newText }}
                    />
                </Tooltip>
            ) : (
                <p
                    style={{ wordBreak: 'break-all' }}
                    dangerouslySetInnerHTML={{ __html: newText }}
                />
            );
        }
    },
    {
        title: '变更日期',
        dataIndex: 'changeDate',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];
