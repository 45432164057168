import React, { useState } from 'react';
import { Table } from 'antd';
import { transferStringNull } from 'utils/utils';
import { highlight } from '../../../../utils/utils';

const getColumns = page => {
    return [
        {
            title: '序号',
            dataIndex: 'num',
            key: 'num',
            render: (text, item, index) => {
                return index + 1 + (page - 1) * 10;
            },
            width: '5%'
        },
        {
            title: '变更事项',
            dataIndex: 'changeItems',
            key: 'changeItems',
            width: '25%',
            render: transferStringNull
        },
        {
            title: '变更前内容',
            dataIndex: 'contentBeforeChange',
            key: 'contentBeforeChange',
            width: '25%',
            render: (text, record) => {
                let str = transferStringNull(text);
                if (str === '--') return '--';
                let _html = highlight(text, record.contentAfterChange);
                return (
                    <p
                        style={{ wordBreak: 'break-all' }}
                        dangerouslySetInnerHTML={{ __html: _html }}
                    />
                );
            }
        },
        {
            title: '变更后内容',
            dataIndex: 'contentAfterChange',
            key: 'contentAfterChange',
            width: '25%',
            render: (text, record) => {
                let str = transferStringNull(text);
                if (str === '--') return '--';
                let _html = highlight(text, record.contentBeforeChange);
                return (
                    <p
                        style={{ wordBreak: 'break-all' }}
                        dangerouslySetInnerHTML={{ __html: _html }}
                    />
                );
            }
        },
        {
            title: '变更日期',
            dataIndex: 'changeDate',
            key: 'changeDate',
            render: transferStringNull
        }
    ];
};

export default function(props) {
    const [page, setPage] = useState(1);
    const columns = getColumns(page);
    return (
        <Table
            columns={columns}
            dataSource={props.data}
            pagination={{
                hideOnSinglePage: true,
                onChange: page => setPage(page)
            }}
        />
    );
}
