/**
 * @author Ray
 */

import React, { useState, useEffect } from 'react';
import Title from 'common/title';
import styles from './styles.scss';
import { Divider } from 'antd';
import ChangeMobileNumberForm from './form';
import useStorage from 'hooks/useStorage';

export default function(props) {
    function handleSubmit(values) {
        return props.updatePhoneNumber(values);
    }

    return (
        <section className={styles.messageContentRoot}>
            <Title classes={{ root: styles.titleFont }} text={'修改手机号'} />
            <Divider />
            <ChangeMobileNumberForm onSubmit={handleSubmit} />
        </section>
    );
}
