/**
 * @desc 中介机构分析-失信被执行人
 * @author Ray
 */

import React, { useState } from 'react';
import { executedPersonColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { isNotEmpty } from '../../../../utils/utils';
import { getLostTrustee } from './service';
import Title from '../../../../common/title';

export default function LostTrustee({ match, zjjgType }) {
    const [query] = useState({
        companyName: match.params.companyName,
        qyxxId: match.params.companyId
    });
    function mockData(res) {
        return isNotEmpty(res) ? res?.data : [];
    }

    return zjjgType !== '律师事务所' ? (
        <>
            <Title level={3} text="失信被执行人" />
            <TableEx
                query={query}
                getData={getLostTrustee}
                parseData={mockData}
                columns={executedPersonColumns}
                locale={{emptyText:`未发现企业的失信被执行人记录。不排除存在未公示或时间相对后滞的情况`}}
            />
        </>
    ) : null;
}
