/**
 * @author 
 * @desc 创始团队
 */
import React, { useState, useEffect } from 'react';
import style from '../inctrol/style.scss';
import Title from '../../../common/title';
import { Modal, Radio, Button, Input, Table, Select, InputNumber, Form, message } from 'antd';
import { getByCreditCode, dataUpdate } from '../inctrol/service';
import {LimitNumber, uuid} from 'utils/utils';
import {SaveButton} from "../../../common/buttons";

const { TextArea } = Input;
const { Option } = Select;

const  modalformLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
}

function Team({form}) {

    const { getFieldDecorator } = form;
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({
        team: [],
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        question7: '',
        question8: '',
        question9: '',
        question10: '',
        question11: '',
    });
    const [isAdd, setstatus] = useState(true); // 新增 or 编辑
    const [itemId, setItemId] = useState(''); // 编辑id

    useEffect( () => {
       getData();
    }, [])

    function getData(){
        getByCreditCode({
            type: 2
        }).then(res => {
            if(res.success){
                setData({...data, ...JSON.parse(res.data)});
            }
        });
    }
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: '35%',
            align: 'center',
            render: (text) => <span title = {text}>{LimitNumber(text, 36)}</span>
        },
        {
            title: '职务',
            dataIndex: 'duties',
            key: 'duties',
            width: '30%',
            align: 'center',
            render: (text) => <span title = {text}>{LimitNumber(text, 30)}</span>
        },
        {
            title: '投票顺序',
            dataIndex: 'vote',
            key: 'vote',
            width: '15%',
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            render: (v, item) => {
                return  <span>
                            <a href="javascript:;" onClick={() => editTeam(item)}>编辑</a>
                            <a style={{ marginLeft: '20px' }} href="javascript:;" onClick={() => deleteTeam(item)}>删除</a>
                        </span>;
            }
        }
    ];

    function addTeam(){
        form.validateFields((err, values) => {
            if (!err) {
                let {team} = data;
                if(isAdd){
                    let id = uuid();
                    team.unshift({...values, id, key: id});
                }else{
                    let index = team.findIndex(ele => ele.id === itemId);
                    team[index] = {...values, id: itemId, key: itemId};
                    
                }
                // message.success('成功');
                save();
                setVisible(false);
            }
        });
    }

    function editTeam(v){
        setVisible(true);
        setstatus(false);
        setTimeout(() => {
            const {id, key, ...others} = v;
            setItemId(id);
            form.setFieldsValue(others);
        }, 1000);
    }

    function deleteTeam(v){
        Modal.confirm({
            title: '确认删除吗?',
            cancelText: '取消',
            okText: '确认',
            onOk() {
                let {team} = data;
                let index = team.findIndex(ele => ele.id === v.id);
                team.splice(index, 1);
                setData({...data, team});
                setTimeout(()=>save(),0); // 将请求推迟到下一个更新。
            }
        });
    }

    function save(){
        dataUpdate({
            data,
            type: 2
        }).then( res => {
            if(res.success){
                message.success(res.message);
                getData();
            }else{
                message.error(res.message);
            }
        });
    }

    function changeVal(e, type){
        setData({...data, [type]: e.target.value});
    }

    const {question1, question2, question3, question4, question5, question6, question7, question8, question9, question10, question11} = data;
   
    return (
        <div className={style.standard}>
            <Title text="创始团队" />
            {/* <Divider /> */}
            <Button 
                type="primary" icon="plus-circle" 
                onClick={() => {
                    setstatus(true);
                    setVisible(true);
                }}
            >
                添加
            </Button>
            <div className={style.inner}>
                <Table
                    dataSource={data.team} columns={columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
            </div>
            <div className={style.inner}>
                <p>1、特殊关系说明：</p>
                <TextArea value = {question1} onChange = {(e) => changeVal(e, 'question1')} rows={4} placeholder="A与B为夫妻关系，（是/否）为一致行动人。&#13;&#10;C与D为父子关系，（是/否）为一致行动人。" maxLength="500" />
            </div>

            <div className={style.inner}>
                <p>
                    2、CEO行业背景与公司产品或服务方向是否相同？
                </p>
                <TextArea value = {question2} onChange = {(e) => changeVal(e, 'question2')} rows={4} placeholder="请输入" maxLength="500" />
            </div>

            <div className={style.inner}>
                <p>
                    3、CEO、CTO、COO行业背景与公司产品或服务方向是否相同？
                </p>
                <TextArea value = {question3} onChange = {(e) => changeVal(e, 'question3')} rows={4} placeholder="请输入" maxLength="500" />
            </div>

            <div className={style.inner}>
                <p>
                    4、创始团队最低学历为？
                </p>
                <Select placeholder="请选择" style={{ width: 320 }} value = {question4} onChange = {(e) => changeVal({target: {value: e}}, 'question4')} >
                    <Option value="高中">高中</Option>
                    <Option value="大学专科">大学专科</Option>
                    <Option value="大学本科" >大学本科</Option>
                    <Option value="硕士">硕士</Option>
                    <Option value="博士">博士</Option>
                    <Option value="博士后">博士后</Option>
                </Select>
            </div>

            <div className={style.inner}>
                <p>
                    5、创始团队是否来自于世界名校？(世界排名前100)
                </p>
                <Radio.Group
                    value = {question5} onChange = {(e) => changeVal(e, 'question5')}
                >
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>
            <div className={style.inner}>
                <p>
                    6、CEO、CTO、COO就读专业是否与企业产品或服务直接相关？
                </p>
                <TextArea value = {question6} onChange = {(e) => changeVal(e, 'question6')} rows={4} placeholder="请输入" maxLength="500" />
            </div>
            <div className={style.inner}>
                <p>
                    7、创始团队学历背景、行业经验是否形成互补？
                </p>
                <TextArea value = {question7} onChange = {(e) => changeVal(e, 'question7')} rows={4} placeholder="请输入" maxLength="500" />
            </div>
            <div className={style.inner}>
                <p>
                    8、CFO是否为注册会计师？
                </p>
                <Radio.Group
                    value = {question8} onChange = {(e) => changeVal(e, 'question8')}
                >
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>
            <div className={style.inner}>
                <p>
                    9、CFO从事财务工作年限？
                </p>
                <InputNumber value = {question9} onChange = {(e) => changeVal({target: {value: e}}, 'question9')} precision={0} style={{ width: 120 }} min={0} max={99} />  年
            </div>
            <div className={style.inner}>
                <p>
                    10、现有创始核心团队合作时间？（以最后一位核心团队加入公司为起点计算）
                </p>
                <TextArea value = {question10} onChange = {(e) => changeVal(e, 'question10')} rows={4} placeholder="请输入" maxLength="500" />
            </div>
            <div className={style.inner}>
                <p>
                    11、创始团队成员是否有不良记录？（未决诉讼、违法、处罚、失信人员名单等，如有请列示）
                </p>
                <TextArea value = {question11} onChange = {(e) => changeVal(e, 'question11')} rows={4} placeholder="请输入" maxLength="500" />
            </div>
            <div style={{ 'textAlign': 'center' }}>
                <SaveButton type="primary" onClick = {save}>保存</SaveButton>
            </div>
            <Modal
                title="创始团队"
                visible={visible}
                className="teamModal"
                onOk={() => addTeam()}
                onCancel={() => setVisible(false)}
                destroyOnClose = {true}
            >
                <Form {...modalformLayout}>
                    <Form.Item label="姓名：">
                        {getFieldDecorator('name', {
                            rules: [
                                {required: true, message: "请输入姓名"},
                                {whitespace: true, message: '请输入姓名'}
                            ]
                        })(
                            <Input maxLength={50} placeholder="请输入"  autoComplete="off"/>
                        )}
                    </Form.Item>
                    <Form.Item label="职务：">
                        {getFieldDecorator('duties', {
                            rules: [
                                {required: true,message: "请输入职务"},
                                {whitespace: true, message: '请输入职务'}
                            ]
                        })(
                            <Input maxLength={50}  placeholder="请输入" />
                        )}
                    </Form.Item>
                    <Form.Item label="投票顺序：">
                        {getFieldDecorator('vote', {
                            rules: [
                                {required: true, message: "请输入投票顺序"},
                                // {whitespace: true, message: '请输入投票顺序'}
                            ]
                        })(
                            <InputNumber maxLength={9} precision={0} placeholder="请输入" min={1}/>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

const FormBox = Form.create()(Team);
export default FormBox;
