import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AutoComplete as Auto } from 'antd';
import { isEmpty, debounce } from '../../utils/utils';
import { getFinancingIndustry } from '../../containers/imformation/basiInfo/service';

export function AutoComplete({ form, loadSource, parser, ...props }, ref) {
  const [dataSource, setSource] = useState([]);
  const [query, setQuery] = useState(props.value || '');
  const funcRef = useRef(() => {});
    console.log(form.getFieldValue('srcIndustry') && form.getFieldValue('srcIndustry').trim().split(" ")[0],'00')
  let val = form.getFieldValue('srcIndustry') && form.getFieldValue('srcIndustry').trim().split(" ")[0];
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    getFinancingIndustry(val).then(res => {
        if (!isEmpty(res) && !isEmpty(res.data)) {
                    let _source = res.data;
                    setSource(_source);
                  } else {
                    setSource([]);
                  }
    })
  },[val])
//   function loadSourceData(val) {
//     loadSource(val).then(function(res) {
//       if (!isEmpty(res) && !isEmpty(res.data)) {
//         let _source = parser ? parser(res.data) : res.data;
//         setSource(_source);
//       } else {
//         setSource([]);
//       }
//     });
//   }
  // 组件加载进行一次搜索
//   useEffect(() => loadSourceData(val), []);

//   useEffect(
//     function() {
//       if (!query) return;
//       loadSourceData(query);
//     },
//     [query]
//   );

//   useEffect(function() {
//     // funcRef.current = debounce(v => setQuery(v), 800, this);
//     setQuery(val)
//   }, []);

  function renderOptions(dataSource) {
    return dataSource && dataSource.map(d => {
      return (
        <Auto.Option key={JSON.stringify(d)} value={d.content}>
          {d.content}
        </Auto.Option>
      );
    });
  }

//   function onBlur(value) {
//     const val = value.trim().split(" ")[0]
//     // getFinancingIndustry(val).then(res => {
//     //   console.log(res,'res')
//     // })
//     // console.log(val, 'val')
//     // props.paramKey === 'srcIndustry' ? setQuery(val) : ''
    
//     // props.paramKey === 'srcIndustry' ? getFinancingIndustry(val).then(res => {
//     //   setSource(res.data)
//     // }) : '';
//   }

  return (
    <Auto
      {...props}
    //   onSearch={funcRef.current}
      getPopupContainer={node => node.parentElement}
    //   onBlur={onBlur}
    >
      {renderOptions(dataSource)}
    </Auto>
  );
}

export default React.forwardRef(AutoComplete);
