/**
 * @author Ray
 */
import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';

export default function Label(prop) {
  return (
    <label className={classnames(styles.labelRoot)}>
      <span className={styles.extra}>{prop.extra}</span>
      <span className={styles.label}>{prop.text}</span>
      <span className={styles.child}>{prop.children}</span>
    </label>
  );
}

Label.defaultProps = {
  text: '',
  children: null
};
