/*
核心竞争力-api
 */

import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc  专利与公司业务匹配性
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function matchData({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/store/count/patent/page`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc   专利查询
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function patents({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/company/zhuanli`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc  作品著作
 * @param qyxxId
 */
export function paperWorks({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/zpzzq`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc   商标
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function brands({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/company/shangbiao`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  软件著作
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function softwareWorks({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/company/rjzzq`, {
        pageNum,
        pageSize,
        qyxxId
    });
}
