/**
 * @desc  每日成交量前十大个股情况
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import Radios from './radio';
import List from './list';

export default function Line7Index() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '创新层', value: 1 },
        { name: '基础层', value: 2 },
        { name: '精选层', value: 3 },
    ];

    return (
        <div className={ styles.line7 }>
            <Title />
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <List type={type} />
        </div>
        
    );
}
