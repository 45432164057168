/**
 * @author Ray
 */
import React, { useReducer } from 'react';
import * as Actions from '../investment/script/actions';
import ModalEx from '../../common/modalEx';
import reducer from '../investment/script/reducer';
import { initState } from '../investment/script/const';
import { renderRoutes } from 'react-router-config';
import { Permission } from '../../hooks/useAuth';

export default function({ Routes, match, ...rest }) {
    const [store, dispatch] = useReducer(reducer, initState);
    return (
        <>
            <Permission permission={498}>
                {renderRoutes(Routes, {
                    ...rest,
                    dispatch
                })}
            </Permission>
            <ModalEx
                title={'提示'}
                onClose={() => dispatch(Actions.closeModal())}
                onCancel={() => dispatch(Actions.closeModal())}
                {...store.modal}
            >
                {store.modal.content}
            </ModalEx>
        </>
    );
}
