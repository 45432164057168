import { useState, useEffect } from 'react';
import { guaranteeAPI, pledgeAPI, pledgorAPI, pledgorDetailAPI } from '../service';

export default function({qyxxId, stockCode, year, companyName}){
    
    const [barOption, setBarOption] = useState(null); // 柱状图
    const [pledge, setPledge] = useState([]);   //股权质押统计
    const [pledgor, setPledgor] = useState([]); //重要股东质押数据
    const [pledgorDetail, setPledgorDetail] = useState([]); //股权质押明细
    const [loading, setLoading] = useState({}); 
    const [gaugeOptions, setGaugeOptions] = useState(null); // 仪表盘

    // 获取司法风险数据
     function getJudicial() {
        if(!qyxxId || !stockCode){
            return;
        }
        guaranteeAPI({
            qyxxId,
            stockCode, 
            year
        }).then(res => {
            if(res.success){
                const { zhiyadanbaoRiskScore, zhiyadanbaoSorts, ...others } = res.data;
                getGaugeOptions(zhiyadanbaoRiskScore);
                getBarOptions(zhiyadanbaoSorts);
            }
        }).finally(()=>{
            setLoading(false);
        })

        //获取股权质押统计数据
        pledgeAPI(qyxxId).then(res => {
            if (res && res.success == true) {
                const { pledges } = res.data;
                setPledge(pledges);
            }
        }).finally(() => {
            setLoading(false);
        })

        //股权质押明细
        pledgorAPI(stockCode).then(res => {
            if (res && res.success == true) {
                const { pledgors } = res.data;
                setPledgor(pledgors);
            }
        }).finally(() => {
            setLoading(false);
        })

        //重要股东质押数据
        pledgorDetailAPI({
            pageNum: 1,
            companyId: qyxxId,
            companyName: companyName,
        }).then(res => {
            if (res && res.success == true) {
                const { items } = res.data;
                setPledgorDetail(items);
            }
        }).finally(() => {
            setLoading(false);
        })
    };


    function getBarOptions(data){
        if( data && data.length ){
            const colors = ["#59DF8F", "#2082ED", "#FB7B39"];
            const options = {
                grid: {
                    top: 30,
                    bottom: 180,
                    left: 110,
                    right: 20
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#333'
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (v) {
                        const { value, name, sort } = v.data; 
                        return `<div class="tooltipBox">
                                    <p class = "title"><b>${name}</b></p>
                                    <p>质押风险得分：<b style = "color:${v.color}">${value}</b></p>
                                    <p>质押风险行业排名：<b style = "color:${v.color}">${sort}</b></p>
                                <div>`
                        }
                },
                xAxis: {
                    data: data.map( ele => ele.companyName ),
                    type: 'category',
                    axisLabel: {
                        color: '#333',
                        rotate: 45,
                        fontSize: 12
                    }
                },
                series: [
                    {
                        type: 'bar',        
                        itemStyle: {
                            barBorderRadius: 10
                        },
                        data: data.map( (ele, i) => {
                            let color ='';
                            if(i === 0 ){
                                color = colors[2];
                            }else if(i === data.length - 1 ){
                                color = colors[0];
                            }else{
                                color = colors[1];
                            }
                            return { name: ele.companyName, value: ele.zhiyadanbaoRiskScore, itemStyle: {color}, sort: ele.sort };
                        })
                    }
                ]
            }
            setBarOption(options);
        }else{
            setBarOption(null);
        }
    }

    function getGaugeOptions(score){
        const options = {
            series: [
                {
                    type: 'gauge',
                    data: [{value: score, name: '风险总分'}]
                }
            ]
        };
        setGaugeOptions(options);
    }

    useEffect(()=>{
        getJudicial();
    }, [year])

    return { gaugeOptions, loading, barOption, pledge, pledgor, pledgorDetail }
}