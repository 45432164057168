'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _map = require('babel-runtime/core-js/map');

var _map2 = _interopRequireDefault(_map);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _lang = require('lodash/lang');

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _utils = require('./utils');

var _enum = require('../../../constant/enum');

var _processor = require('../../../util/processor/processor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RegionChart = function (_Chart) {
    (0, _inherits3.default)(RegionChart, _Chart);

    // state的复制，其实是后台所有的节点

    // 高德地图的事件集合
    // 可以定位的node
    function RegionChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, RegionChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (RegionChart.__proto__ || (0, _getPrototypeOf2.default)(RegionChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'region';
        _this.displayNodesMap = new _map2.default();
        _this.noPositionLinks = [];
        _this._amapEvent = {};
        _this.degree = 2;
        _this._stateCopy = {};

        _this.process = function () {
            // 取消

            var mapLayer = _this.painter.getLayer(_enum.LayerType.MAP);
            mapLayer.onReady({
                zoom: 11,
                resizeEnable: true,
                center: [116.397428, 39.90923],
                mapStyle: 'amap://styles/whitesmoke',
                animateEnable: false
            }).then(function (_ref) {
                var map = _ref.map,
                    Map = _ref.Map;

                _this.map = map;
                _this.AMap = Map;
                _this.addEventListeners();
                var state = _this.getState();
                _this._stateCopy = (0, _lang.cloneDeep)(state);
                _this.handler.trigger('chart.changeList', _this._stateCopy);
                _this.refresh();
                (0, _get3.default)(RegionChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(RegionChart.prototype), 'filter', _this).call(_this); // 监听筛选事件
                (0, _get3.default)(RegionChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(RegionChart.prototype), 'setting', _this).call(_this); // 监听设置事件
                _this.handler.trigger('chart.getSettingStatus');
            });
        };

        _this.dispose = function () {
            (0, _get3.default)(RegionChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(RegionChart.prototype), 'dispose', _this).call(_this);
            _this.handler.off('click', _this.handleHighlight);
            _this.handler.off('dblclick', _this.handleExtension);
            _this.handler.off('mouseover', _this.handleHighlightLine);

            // off掉地图事件
            if (_this.AMap) {
                _this.AMap.event.removeListener(_this._amapEvent.mousewheel);
                _this.AMap.event.removeListener(_this._amapEvent.mousedown);
                _this.AMap.event.removeListener(_this._amapEvent.mousemove);
                _this.AMap.event.removeListener(_this._amapEvent.mouseup);
            }

            if (_this.map) {
                _this.map.off('zoomstart', _this.AmapEventChangeStart);
                _this.map.off('zoomend', _this.AmapEventChangeEnd);
                _this.map.off('movestart', _this.AmapEventChangeStart);
                _this.map.off('moveend', _this.AmapEventChangeEnd);
            }

            _this.handler = null;
            _this.painter = null;
            _this.store = null;
        };

        _this.extend = function (node) {
            _this.store.loadNewState({
                bbdQyxxId: node.id,
                degree: 1
            }, 'extension').then(function (newState) {
                if (newState) {
                    _this.degree++;
                    var oldState = _this.getState();
                    _this.store.mergeState(oldState, newState);
                    _this.store.mergeState(_this._stateCopy, newState);
                    _this.handler.trigger('chart.changeList', _this._stateCopy);
                    _this.refresh();
                    _this.handler.trigger('chart.resetFilterSetting'); // 重设筛选设置
                }
            });
        };

        _this.highlight = function (node) {
            if (node.lon && node.lat) {
                (0, _get3.default)(RegionChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(RegionChart.prototype), 'highLightAll', _this).call(_this);
                var qyId = node.data.bbdQyxxId;
                var state = _this.getState();
                var gridState = _this.store.toGridState(state, _this.centerId);

                var _getHightLight = (0, _processor.getHightLight)(qyId, gridState),
                    nodes = _getHightLight.nodes,
                    links = _getHightLight.links;

                var highLightnNodes = {},
                    highLightLinks = {};

                nodes.forEach(function (node) {
                    highLightnNodes[node.id] = true;
                });
                links.forEach(function (link) {
                    highLightLinks[link.id] = true;
                });

                state.nodes.forEach(function (node) {
                    if (!highLightnNodes[node.id]) {
                        node.slight = true;
                    }
                });
                state.links.forEach(function (link) {
                    if (!highLightLinks[link.id]) {
                        link.slight = true;
                    }
                });
                _this.setState(state);
            }
        };

        _this.switchCenter = function (node) {
            _this.centerId = node.bbdQyxxId;
            _this.store.loadNewState(node, 'init').then(function (state) {
                _this._stateCopy = (0, _lang.cloneDeep)(state);
                _this.handler.trigger('chart.changeList', _this._stateCopy);
                _this.displayNodesMap.clear();
                _utils.rePaint.call(_this, state);
            });
        };

        _this.changeDegree = function (_ref2) {
            var id = _ref2.id,
                degree = _ref2.degree,
                date = _ref2.date;

            _this.degree = degree;
            _this.store.loadNewState({
                bbdQyxxId: id,
                degree: degree,
                date: date
            }, 'init').then(function (state) {
                _this._stateCopy = (0, _lang.cloneDeep)(state);
                _this.displayNodesMap.clear();
                _this.handler.trigger('chart.changeList', _this._stateCopy);
                _utils.rePaint.call(_this, state);
            });
        };

        _this.AmapEventChangeStart = function () {
            _this.painter.getLayer(_enum.LayerType.MAIN).hide();
        };

        _this.AmapEventChangeEnd = function () {
            _this.painter.getLayer(_enum.LayerType.MAIN).show();
            _utils.rePaint.call(_this, _this.getState());
        };

        _this.addEventListeners = function () {
            var root = _this.painter._root;
            /**
             * 监听鼠标滚动事件
             * 事件绑定方式请查阅高德文档
             */
            _this._amapEvent.mousewheel = _this.AMap.event.addDomListener(root, 'mousewheel', function (e) {
                if (e.zrDelta > 0) {
                    _this.map.zoomIn();
                } else {
                    _this.map.zoomOut();
                }
            }, false);

            /**
             * 监听鼠标拖拽事件
             */
            var dragging = false;
            var x, y;
            _this._amapEvent.mousedown = _this.AMap.event.addDomListener(root, 'mousedown', function (e) {
                dragging = true;
                x = e.x;
                y = e.y;
                _this._amapEvent.mousemove = _this.AMap.event.addDomListener(root, 'mousemove', function (e) {
                    if (dragging) {
                        _this.map.panBy(e.x - x, e.y - y);
                        x = e.x;
                        y = e.y;
                    }
                });
                _this._amapEvent.mouseup = _this.AMap.event.addDomListener(document, 'mouseup', function (e) {
                    dragging = false;
                    _this.AMap.event.removeListener(_this._amapEvent.mousemove);
                    _this.AMap.event.removeListener(_this._amapEvent.mouseup);
                });
            });
        };

        _this.refresh = function () {
            var state = _this.getState();
            (0, _utils.setFitView)(state.nodes, _this.map, _this.AMap);
            _this.map.off('zoomstart', _this.AmapEventChangeStart);
            _this.map.off('zoomend', _this.AmapEventChangeEnd);
            _this.map.on('zoomstart', _this.AmapEventChangeStart);
            _this.map.on('zoomend', _this.AmapEventChangeEnd);
            _this.map.off('movestart', _this.AmapEventChangeStart);
            _this.map.off('moveend', _this.AmapEventChangeEnd);
            _this.map.on('movestart', _this.AmapEventChangeStart);
            _this.map.on('moveend', _this.AmapEventChangeEnd);
            _utils.rePaint.call(_this, state);
        };

        _this.container = { width: _this.painter.getWidth(), height: _this.painter.getHeight() };
        _this.centerId = _this.options.data.bbdQyxxId;
        return _this;
    } // 无法定位的线，线的两端节点有一点无法定位


    (0, _createClass3.default)(RegionChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {}
    }, {
        key: 'afterProcess',
        value: function afterProcess() {
            var _this2 = this;

            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mouseover', this.handleHighlightLine);

            // 高亮
            this.handler.on('region.highlight', function (node) {
                if (node && node.shapeType === 'node') {
                    _this2.highlight(node);
                } else {
                    (0, _get3.default)(RegionChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(RegionChart.prototype), 'highLightAll', _this2).call(_this2);
                    _this2.handler.trigger('chart.resetFilterSetting');
                }
            });

            // 扩展
            this.handler.on('region.extension', function (node) {
                if (node && node.shapeType === 'node') {
                    _this2.extend(node);
                }
            });

            // 切换
            this.handler.on('region.init', function (node) {
                _this2.switchCenter(node);
            });

            // 切换度数
            this.handler.on('region.reRender', function (_ref3) {
                var id = _ref3.id,
                    degree = _ref3.degree,
                    date = _ref3.date,
                    reset = _ref3.reset;

                if (reset) {
                    _this2.resetTransform();
                }

                _this2.changeDegree({ id: id, degree: degree, date: date });
            });
        }
    }]);
    return RegionChart;
}(_Chart3.default); /**
                     * 地域分布图
                     */


exports.default = RegionChart;
module.exports = exports['default'];