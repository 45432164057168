import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { BackTop } from 'antd';
import Home from './home';
import Detail from './detail';
import styles from './styles.scss';
import useAuth from '../../hooks/useAuth';

export const Routes = [
    {
        // 首页
        path: 'index',
        component: Home,
        exact: false
    },
    {
        // 详情
        path: 'detail',
        component: Detail,
        exact: false
    }
];
export default function(props) {
    const { match } = props;
    // const isAuth = useAuth(418);
    return (
        <div className={styles.riskMonitoring}>
            <Switch>
                {Routes.map(o => (
                    <Route
                        key={o.path}
                        exact={o.exact}
                        path={`${match.url}/${o.path}`}
                        render={prop => <o.component {...prop} />}
                    />
                ))}
                <Redirect from={match.url} to={`${match.url}/index`} />
            </Switch>
            {/* <BackTop /> */}
        </div>
    );
}
