/**
 * @author Ray
 */
import React, {useEffect, useState} from 'react';
import {Form, AutoComplete, Tooltip, Icon, Select} from 'antd';
import styles from './styles.scss';
import {MESSAGE1, MESSAGE2, MESSAGE3} from '../../../../constants/common';
import {IndustryLabels, PLATES2} from '../../../../constants/enum';
import Auto from '../../../../common/autoComplete';
import Auto1 from '../../../../common/autoComplete/index1';
import {getFinancingIndustry, getSscIndustry} from '../service';
import ErrorTip from '../../../../common/errorTip';
import {isEmpty} from '../../../../utils/utils';

export function BasicForm(props, ref) {
    const {form, onInit} = props;

    function filterOptions(inputValue, option) {
        if (option.key.indexOf(inputValue) !== -1) {
            return true;
        }
    }

    useEffect(function() {
        onInit(form);
    }, []);

    return (
        <Form className={styles.root} labelCol={{xs: 10}} wrapperCol={{xs: 10}}>
            <Form.Item
                colon={false}
                label={
                    <span>
                        企业所属证监会行业&nbsp;
                        <Tooltip placement='right' title={MESSAGE1}>
                            <Icon
                                theme='filled'
                                className='blueButton'
                                type='question-circle'
                            />
                        </Tooltip>
                    </span>
                }
            >
                {form.getFieldDecorator('srcIndustry', {
                    rules: [
                        {
                            required: true,
                            message: (
                                <ErrorTip text={'企业所属证监会行业不能为空'} />
                            )
                        }
                    ]
                })(
                    <Auto
                        paramKey='srcIndustry'
                        parser={data =>
                            data
                                ? data.map(d => ({
                                      value: d.content,
                                      label: d.content,
                                      key: d.typeCode
                                  }))
                                : []
                        }
                        loadSource={getSscIndustry}
                        form={form}
                    />
                )}
            </Form.Item>
            <Form.Item
                colon={false}
                label={
                    <span>
                        企业所属国民经济行业分类小类&nbsp;
                        <Tooltip title={MESSAGE2} placement='right'>
                            <Icon
                                theme='filled'
                                className='blueButton'
                                type='question-circle'
                            />
                        </Tooltip>
                    </span>
                }
            >
                {form.getFieldDecorator('industrySubclass', {
                    rules: [
                        {
                            required: true,
                            message: (
                                <ErrorTip
                                    text={
                                        '企业所属国民经济行业分类小类不能为空'
                                    }
                                />
                            )
                        }
                    ]
                })(
                    <Auto1
                        paramKey='industrySubclass'
                        parser={data =>
                            data
                                ? data.map(d => ({
                                      value: d.content,
                                      label: d.content,
                                      key: d.typeCode
                                  }))
                                : []
                        }
                        loadSource={getFinancingIndustry}
                        form={form}
                    />
                )}
            </Form.Item>
            <Form.Item
                colon={false}
                label={
                    <span>
                        企业行业标签&nbsp;
                        <Tooltip title={MESSAGE3} placement='right'>
                            <Icon
                                theme='filled'
                                className='blueButton'
                                type='question-circle'
                            />
                        </Tooltip>
                    </span>
                }
            >
                {form.getFieldDecorator('industryLabel', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text={'企业行业标签不能为空'} />
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (isEmpty(value)) return callback();
                                if (!IndustryLabels.includes(value)) {
                                    callback(
                                        <ErrorTip text='错误的行业标签' />
                                    );
                                } else {
                                    callback();
                                }
                            }
                        }
                    ]
                })(
                    <AutoComplete
                        autoFocus
                        dataSource={IndustryLabels}
                        filterOption={filterOptions}
                    />
                )}
            </Form.Item>
            <Form.Item
                colon={false}
                label={
                    <span>
                        拟上市板块&nbsp;
                    </span>
                }
            >
                {form.getFieldDecorator('plate', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text={'拟上市板块不能为空'} />
                        }
                    ]
                })(
                    <Select
                        mode='multiple'
                        getPopupContainer={node => node.parentElement}
                    >
                        {PLATES2.map(o => (
                            <Select.Option key={o}>
                                {o}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        </Form>
    );
}

export default function({values, onInit}) {
    const [Element, setElement] = useState(null);

    useEffect(function() {
        let Form_ = Form.create({
            name: 'basicForm'
        })(React.forwardRef(BasicForm));

        setElement(<Form_ values={values} onInit={onInit} />);
    }, []);

    return Element;
}
