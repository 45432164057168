import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';

export default function(props) {

    const { title, color, data, formula } = props;
    let series = data.map((ele, i) => {
        return {
            ...ele,
            itemStyle: {
                barBorderRadius: 10,
                color: i ? color : '#2B3B9D'
            }
        }
    })
    function getOption(){
        return {
            grid: {
                top: 50,
                left: 100,
                right: 65,
                bottom: 50
            },
            yAxis: {
                type: 'value'
            },
            dataZoom: [
                {
                    start: 0,
                    end: 100,
                    bottom: 20,
                    height: 14,
                    showDataShadow: false,
                    showDetail: false
                }
            ],
            tooltip: {
                trigger: 'axis',
                padding: [10, 20],
                formatter: function (v) {
                    const { value, name, color } = v[0]; 
                    return `<div class="">
                                ${name}
                                <p>${title}：<b style = "color:${color}">${value}</b></p>
                                <p>${title.replace('(%)', '')}排名：<b style = "color:${color}">${v[0].data.sort}</b></p>
                            <div>`;
                }
            },
            xAxis: {
                data: data.map( ele => ele.name),
                type: 'category',
                axisLabel: {
                    show: false
                }
            },
            series: [
                {
                    type: 'bar',        
                    data: series
                }
            ]
        }
    }

    return (
        <div className="box">
            <h4 className = "titleBorder">{title}{formula}</h4>
            {
                data && data.length ? 
                <ReactEcharts
                    style = {{height: "370px"}}
                    option={getOption()}
                    notMerge={true}
                    theme={'myTheme'}
                /> : <Empty />
            }
        </div>
    );
}
