import React from 'react';
import { Tooltip } from 'antd';
export const projectColumns = [
  {
    dataIndex: 'company',
    title: '服务公司名称',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'industryName',
    title: '公司所在行业',
    align: 'center',
    width: '15%'
    // render: value => {
    //     return (
    //         <Tooltip title={value} overlayStyle={{ width: 700 }}>
    //             {value ? value.substring(0, 20) + '...' : '--'}
    //         </Tooltip>
    //     );
    // }
  },
  {
    dataIndex: 'location',
    title: '板块',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'expertFirm',
    title: '项目职位',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'zjtdInInstitude',
    title: '所在机构',
    align: 'center',
    width: '20%'
  },
  {
    dataIndex: 'csrcStatus',
    title: '过会情况',
    align: 'center',
    width: '20%'
  }
];
export const projectProColumns = [
  {
    dataIndex: 'serviceCompany',
    title: '服务公司名称',
    align: 'center',
    width: '15%',
    render: text => text || '--'
  },
  {
    dataIndex: 'companyIndustry',
    title: '公司所在行业',
    align: 'center',
    width: '15%',
    render: text => text || '--'
  },
  {
    dataIndex: 'plate',
    title: '板块',
    align: 'center',
    width: '15%',
    render: text => text || '--'
  },
  {
    dataIndex: 'projectPosition',
    title: '项目职位',
    align: 'center',
    width: '15%',
    render: text => text || '--'
  },
  {
    dataIndex: 'organization',
    title: '所在机构',
    align: 'center',
    width: '20%',
    render: text => text || '--'
  },
  {
    dataIndex: 'csrcStatus',
    title: '过会情况',
    align: 'center',
    width: '20%',
    render: text => text || '--'
  }
];

export const RelationColumns = [
  {
    dataIndex: 'expertTeam',
    title: '专家',
    align: 'center',
    width: '10%'
  },
  {
    dataIndex: 'committee',
    title: '发审委委员',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'company',
    title: '服务公司',
    align: 'center',
    width: '30%'
  },
  {
    dataIndex: 'csrcStatus',
    title: '审核过会情况（批准过会数量/审批总数）',
    align: 'center',
    width: '45%'
  }
];

export const PunishColumns = [
  {
    dataIndex: 'type',
    title: '处罚类型',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'person',
    title: '处罚人（自然人）',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'company',
    title: '处罚人（公司）',
    align: 'center',
    width: '15%'
  },
  {
    dataIndex: 'title',
    title: '文件标题',
    align: 'center',
    width: '25%'
  },
  {
    dataIndex: 'bbdUrl',
    title: '文书正文',
    align: 'center',
    width: '30%',
    render: text => (
      <a href={text} target="_blank">
        查看正文
      </a>
    )
  }
];
