import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
/**
 * @desc  雷达图
 */
export default class Radar extends PureComponent {
    getOption(data, indicator) {
        return {
            tooltip: {
                show: true,
                trigger: 'item',
                padding: [10, 20]
            },
            radar: [
                {
                    indicator,
                    splitNumber: 4,
                    center: ['50%', '50%'],
                    name: {
                        textStyle: {
                            color: '#333'
                        }
                    },
                    splitArea: {
                        areaStyle: {
                            color: ['#E6F0F8', '#B2D7FF', '#57B6FF', '#3495FF']
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#B2D7FF'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#B2D7FF'
                        }
                    }
                }
            ],
            series: [
                {
                    name: '雷达图',
                    type: 'radar',
                    symbolSize: '8',
                    itemStyle: {
                        color: '#f7bb30'
                    },
                    data: [
                        {
                            value: data,
                            areaStyle: {
                                normal: {
                                    color: 'rgba(255, 211, 109, 0.8)'
                                }
                            }
                        }
                    ]
                }
            ]
        };
    }
    render() {
        const {
            style = { height: '360px' },
            loading,
            data,
            indicator
        } = this.props;
        return (
            <div style={style}>
                {loading ? (
                    ''
                ) : data && data.length ? (
                    <div className="chartBg">
                        <ReactEcharts
                            option={this.getOption(data, indicator)}
                            notMerge={true}
                            style={style}
                            theme={'myTheme'}
                        />
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        );
    }
}
