import React, { useState, useEffect } from 'react';
import TopTitle from './component/topTitle';
import Line1 from './line1';
import Line2 from './line2';
import Line3 from './line3';
import Line4 from './line4';
import Line5 from './line5';
import Line6 from './line6';
import Line7 from './line7';
import Line8 from './line8';
import Line9 from './line9';
import Line10 from './line10';
import Line11 from './line11';
import Line12 from './line12';
import Line13 from './line13';
import Line14 from './line14';
import { Permission } from '../../../hooks/useAuth';

export default function NewotcMarket() {
    return (
        <Permission permission={503}>
            <TopTitle />
            <Line1 />
            <Line2 />
            <Line3 />
            <Line4 />
            {/* <Line5 /> */}
            <Line6 />
            <Line7 />
            <Line8 />
            <Line9 />
            <Line10 />
            <Line11 />
            <Line12 />
            {/* <Line13 />
            <Line14 /> */}
        </Permission>
    )
}
