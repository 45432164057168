/**
 * @author Ray
 * @desc 入库企业分析-基础信息
 */

import React, { useContext, useEffect, useState } from 'react';
import { Divider, Tabs } from 'antd';
import styles from './styles.scss';
import BusinessAnalysis from './businessAnalysis';
import ListedAbilityAnalysis from './listedAbilityAnalysis';
import FinancialSituation from './financialSituation';
import Competitiveness from './competitiveness';
import JudicialProceeding from './judicialProceedings';
import PublicOpinions from './publicOptions';
import BasicInformation from './basicInformation';
import CorporateQualifications from './corporateQualifications';
import Title from '../../../../common/title';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

// 仅供政府机构与其原用户查看的信息
let GOV = [0, 1, 2, 7];

let TabNames = [
    '智能IPO',
    '经营分析',
    '财务概况',
    '知识产权',
    '司法风险分析',
    '舆情信息',
    '基础信息',
    '企业资质'
];

let TabElements = [
    ListedAbilityAnalysis,
    BusinessAnalysis,
    FinancialSituation,
    Competitiveness,
    JudicialProceeding,
    PublicOpinions,
    BasicInformation,
    CorporateQualifications
];

export default function(props) {
    const gs = useContext(GlobalContext);
    const [activeKey, setActiveKey] = useState('0');
    let [tabNames, setTabNames] = useState([]);
    let [tabs, setTabs] = useState([]);
    let [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );

    useEffect(() => {
        let tabNames = TabNames.slice(0);
        let tabs = TabElements.slice(0);
        // 不是启元用户/政府用户，过滤掉上市能力分析/财务概况/企业资质内容
        if (isOwnedCompany || gs.isQiYuan || gs.isGOV || gs.isIntermediary) {
            setTabNames(tabNames);
            setTabs(tabs);
        } else if (
            (gs.isVIPUser || gs.isAdvanceUser || gs.isNormalUser) &&
            !isOwnedCompany &&
            gs.isEnterprise
        ) {
            tabNames = tabNames.filter((name, index) => !GOV.includes(index));
            tabs = tabs.filter((element, index) => !GOV.includes(index));
            setTabNames(tabNames);
            setTabs(tabs);
        } else {
            setTabNames(tabNames);
            setTabs(tabs);
        }
    }, []);

    // useEffect(() => {
    //     if (tabNames[0] === '智能IPO') {
    //         tabNames = tabNames.filter(name => name !== '智能IPO');
    //         tabs = tabs.filter((tab, index) => index !== 0);
    //         setTabNames(tabNames);
    //         setTabs(tabs);
    //     }
    // }, [props.isIpoCompany]);

    return (
        <div className={styles.root}>
            <Title level={1} text="基础信息" />
            <Divider />
            <Tabs activeKey={activeKey} onChange={k => setActiveKey(k)}>
                {tabs.map((Element, index) => (
                    <Tabs.TabPane key={String(index)} tab={tabNames[index]}>
                        <Element {...props} _type="company" />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
}
