import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { moneyFormatter } from '../../../utils/utils';

export const columns = [
    {
        dataIndex: 'zjjgName',
        title: '中介公司',
        align: 'center',
        render: (text, record) => (
            <Link
                to={`/investment/intermediaryAnalysis/${text}/${record.bbdQyxxId}`}
            >
                {text}
            </Link>
        ),
        width: '15%'
    },
    {
        dataIndex: 'regcap',
        title: '注册资本',
        align: 'center',
        width: '10%',
        render: text => (text !== '--' ? moneyFormatter(text) + '万' : text)
    },
    {
        dataIndex: 'esdate',
        title: '注册时间',
        align: 'center',
        width: '10%'
    },
    {
        dataIndex: 'companyProvince',
        title: '所属省份',
        align: 'center',
        width: '10%'
    },
    {
        dataIndex: 'goodBlock',
        title: '服务板块',
        align: 'center',
        width: '10%',
        render: text => {
            if (text === '--') return text;
            let words = text.split(',');
            return (
                <Tooltip title={text}>
                    {words[0] + (words.length > 1 ? '...' : '')}
                </Tooltip>
            );
        }
    },
    {
        dataIndex: 'totalGhl3years',
        title: '近三年过会率',
        align: 'center',
        width: '15%',
        render: text => text && String((text * 100).toFixed(2)) + '%'
    },
    {
        dataIndex: 'totalDlsl3years',
        title: '近三年服务数量',
        align: 'center',
        width: '15%'
    },
    {
        dataIndex: 'goodIndustry',
        title: '服务领域',
        align: 'center',
        width: '15%',
        render: text => {
            if (text === '--') return text;
            let words = text.split(',');
            return (
                <Tooltip title={text}>
                    {words[0] + (words.length > 1 ? '...' : '')}
                </Tooltip>
            );
        }
    }
];

export const columns2 = [
    {
        dataIndex: 'zjjgName',
        title: '中介公司',
        align: 'center',
        render: (text, record) => (
            <Link
                to={`/investment/intermediaryAnalysis/${text}/${record.bbdQyxxId}`}
            >
                {text}
            </Link>
        ),
        width: '15%'
    },
    {
        dataIndex: 'goodBlock',
        title: '服务板块',
        align: 'center',
        width: '10%',
        render: text => {
            if (text === '--') return text;
            let words = text.split(',');
            return (
                <Tooltip title={text}>
                    {words[0] + (words.length > 1 ? '...' : '')}
                </Tooltip>
            );
        }
    },
    {
        dataIndex: 'totalGhl3years',
        title: '近三年过会率',
        align: 'center',
        width: '15%',
        render: text => text && String((text * 100).toFixed(2)) + '%'
    },
    {
        dataIndex: 'totalDlsl3years',
        title: '近三年服务数量',
        align: 'center',
        width: '15%'
    },
    {
        dataIndex: 'goodIndustry',
        title: '服务领域',
        align: 'center',
        width: '15%',
        render: text => {
            if (text === '--') return text;
            let words = text.split(',');
            return (
                <Tooltip title={text}>
                    {words[0] + (words.length > 1 ? '...' : '')}
                </Tooltip>
            );
        }
    }
];
