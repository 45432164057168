/**
 * @author 
 * @desc 内控规范
 */
import React, { useState, useEffect } from 'react';
import style from './style.scss';
import Title from '../../../common/title';
import { Divider, Radio, Button, Checkbox, Row, Col } from 'antd';
import { dataUpdate, getByCreditCode } from './service';
import { message } from 'antd';
import {SaveButton} from "../../../common/buttons";

export default function (props) {
    const [radioData, setRadioData] = useState({});
    const [checkboxData, setCheckboxData] = useState({});
    const [defaultCheckbox, setDefaultCheckbox] = useState([]);
    function onChange(key, value) {
        radioData[key] = value;
        setRadioData({ ...radioData })
    }

    function checkboxChenge(key, value) {
        if (!value) {
            delete checkboxData[key]
        } else {
            checkboxData[key] = value;
        }
        const arr = []
        for (let key in checkboxData) {
            arr.push(key)
        }
        setCheckboxData({ ...checkboxData });
        setDefaultCheckbox([...arr])
    }
    function onDataUpdate() {
        let newObj;
        newObj = { radioData: { ...radioData }, checkboxData: [...defaultCheckbox] };
        for (let i = 0; i < 6; i++) {
            if (radioData[`radio${i + 1}`] === undefined) {
                message.info(`第${i + 1}题未选择`)
                return false;
            }
        }
        dataUpdate({ data: JSON.stringify(newObj), type: "1" }).then(res=>{
            if(res.success){
                message.success('保存成功')
            }else{
                message.error(res.message);
            }
        })
    }

    //初始化
    useEffect(() => {
        getByCreditCode({ type: 1 }).then(res => {
            if (res.success && res.data) {
                const { radioData, checkboxData } = JSON.parse(res.data);
                const newObj = {};
                setRadioData({ ...radioData });
                checkboxData.forEach(item => newObj[item] = true);
                setCheckboxData({ ...newObj });
                setDefaultCheckbox([...checkboxData]);
            }
        })
    }, [])
    return (
        <div className={style.standard}>
            <Title text="内控规范" />
            <Divider />
            <div className={style.inner}>
                <p>
                    1、企业是否建立了规范、完整的财务管理制度并有效执行？
                </p>
                <Radio.Group onChange={(e) => onChange("radio1", e.target.value)} value={radioData.radio1}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>

            <div className={style.inner}>
                <p>
                    2、企业是否建立了规范、完整的公司治理结构和议事规则并有效执行？
                </p>
                <Radio.Group onChange={(e) => onChange("radio2", e.target.value)} value={radioData.radio2}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>

            <div className={style.inner}>
                <p>
                    3、企业是否结合业务特点和内部控制要求设置内部机构、明确职责权限，并将权利与责任落实到各责任单位？
                </p>
                <Radio.Group onChange={(e) => onChange("radio3", e.target.value)} value={radioData.radio3}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>

            <div className={style.inner}>
                <p>
                    4、企业是否制定了有利于可持续发展的人力资源政策并有效执行？
                </p>
                <Radio.Group onChange={(e) => onChange("radio4", e.target.value)} value={radioData.radio4}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>

            <div className={style.inner}>
                <p>
                    5、企业是否制定了对内部、外部风险识别、评估、对应措施的制度并有效执行？
                </p>
                <Radio.Group onChange={(e) => onChange("radio5", e.target.value)} value={radioData.radio5}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>

            <div className={style.inner}>
                <p>
                    6、企业是否结合风险评估结果，运用控制措施，将风险控制在可承受范围内？（控制措施包括但不限于：不相容职务分离控制、授权审批控制、会计系统控制、财产保护控制、预测控制、运营分析控制和绩效考评控制等。）
                </p>
                <Radio.Group onChange={(e) => onChange("radio6", e.target.value)} value={radioData.radio6}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            </div>
            <div className={style.inner}>
                <p>
                    7、企业建立了以下哪些制度？
                </p>
                <Checkbox.Group style={{ width: '100%' }} value={[...defaultCheckbox]}>
                    <Row>
                        <Col span={12}>
                            <Checkbox value='checkbox1' onChange={(e) => checkboxChenge("checkbox1", e.target.checked)}>公司章程</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox2" onChange={(e) => checkboxChenge("checkbox2", e.target.checked)}>股东大会议事规则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox3" onChange={(e) => checkboxChenge("checkbox3", e.target.checked)}>董事会议事规则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox4" onChange={(e) => checkboxChenge("checkbox4", e.target.checked)}>监事会议事规则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox5" onChange={(e) => checkboxChenge("checkbox5", e.target.checked)}>独立董事制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox6" onChange={(e) => checkboxChenge("checkbox6", e.target.checked)}>董事会秘书工作细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox7" onChange={(e) => checkboxChenge("checkbox7", e.target.checked)}>关联交易管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox8" onChange={(e) => checkboxChenge("checkbox8", e.target.checked)}>对外投资管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox9" onChange={(e) => checkboxChenge("checkbox9", e.target.checked)}>对外担保管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox10" onChange={(e) => checkboxChenge("checkbox10", e.target.checked)}>投资者关系管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox11" onChange={(e) => checkboxChenge("checkbox11", e.target.checked)}>募集资金管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox12" onChange={(e) => checkboxChenge("checkbox12", e.target.checked)}>累积投票制实施细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox13" onChange={(e) => checkboxChenge("checkbox13", e.target.checked)}>董事、监事、高级管理人员所持本公司股份及变动管理办法</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox14" onChange={(e) => checkboxChenge("checkbox14", e.target.checked)}>防范控股股东及其关联方资金占用管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox15" onChange={(e) => checkboxChenge("checkbox15", e.target.checked)}>内幕信息知情人登记管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox16" onChange={(e) => checkboxChenge("checkbox16", e.target.checked)}>重大信息内部报告制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox17" onChange={(e) => checkboxChenge("checkbox17", e.target.checked)}>年报信息披露重大差错责任追究制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox18" onChange={(e) => checkboxChenge("checkbox18", e.target.checked)}>信息披露事务管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox19" onChange={(e) => checkboxChenge("checkbox19", e.target.checked)}>发展战略委员会实施细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox20" onChange={(e) => checkboxChenge("checkbox20", e.target.checked)}>审计委员会实施细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox21" onChange={(e) => checkboxChenge("checkbox21", e.target.checked)}>提名委员会实施细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox22" onChange={(e) => checkboxChenge("checkbox22", e.target.checked)}>薪酬与考核委员会实施细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox23" onChange={(e) => checkboxChenge("checkbox23", e.target.checked)}>内部审计制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox24" onChange={(e) => checkboxChenge("checkbox24", e.target.checked)}>总经理（总裁）工作细则</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox25" onChange={(e) => checkboxChenge("checkbox25", e.target.checked)}>外部信息使用人管理制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox26" onChange={(e) => checkboxChenge("checkbox26", e.target.checked)}>董事会审计委员会年报工作制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox27" onChange={(e) => checkboxChenge("checkbox27", e.target.checked)}>独立董事年报工作制度</Checkbox>
                        </Col>
                        <Col span={12}>
                            <Checkbox value="checkbox28" onChange={(e) => checkboxChenge("checkbox28", e.target.checked)}>重大信息内部保密制度
                </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </div>
            <div style={{ 'textAlign': 'center' }}>
                <SaveButton type="primary" onClick={onDataUpdate}>提交</SaveButton>
            </div>
        </div>
    );
}