import React from 'react';
import { Tooltip } from 'antd';
import { limitString } from '../../../../utils/utils';

export const sponsorColumns = [
    {
        dataIndex: 'index',
        title: '序号',
        align: 'center',
        width: '10%',
        render: (text, record, index) => index + 1
    },
    {
        dataIndex: 'zjjgName',
        title: '中介机构名称',
        align: 'center',
        width: '25%'
    },
    {
        title: '近三年过会率',
        dataIndex: 'totalGhl3years',
        align: 'center',
        width: '20%',
        render: text => (text && `${(text * 100).toFixed(2)}%`) || '--'
    },
    {
        title: '近三年服务公司数量',
        dataIndex: 'totalDlsl3years',
        align: 'center',
        width: '20%',
        render: text => (text && text.toFixed(0)) || '--'
    },
    {
        dataIndex: 'matchReason',
        title: '匹配理由',
        align: 'center',
        width: '35%',
        render: text =>
            text && text.length > 20 ? (
                <Tooltip title={text}>{limitString(17, text)}</Tooltip>
            ) : (
                text
            )
    }
];
