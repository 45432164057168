import React, { Component } from 'react';
import { Chart } from '@antv/g2';
import CenterBottom from './RiskCompaniesNumHistogram';
/**
 * @desc 中间下位置显示数据
 */
export default class CenterBottomIndex extends Component {

  render() {
    const { address, style } = this.props;
    return (
      <CenterBottom address={address} />
    )
  }
  
}