/**
 * @desc 入库企业分析-基础信息-司法诉讼-裁判文书
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { refereePaperworkColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { judgePaper } from './service';

export default function RefereePaperwork({match}) {
    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={refereePaperworkColumns}
            getData={judgePaper}
            parseData={parseData}
            locale={{emptyText: '未发现企业的裁判文书记录。不排除存在未公示或时间相对滞后的情况。'}}

        />
    );
}
