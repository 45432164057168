/***
 * @author ray
 * @description antd的走马灯banner，稍微封装一下
 */

import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'antd';
import styles from './styles.scss';
import classnames from 'classnames';
import bannerBg from '../../containers/listingTraining/images/bannerBg.png';
import { GlobalContext } from '../globalStore/createProvider';

Banner.defaultProps = {
    banners: [],
    api: () => Promise.resolve([]),
    parser: v => v
};

export default function Banner({ banners, api, query, parser }) {
    const [_banners, setBanners] = useState(banners);
    const gs = useContext(GlobalContext);

    const prefix = gs.isPub
        ? 'http://file.beijingipo.com.cn/'
        : 'http://192.168.88.106/'

    useEffect(() => {
        api(query).then(res => {
            let data = parser(res.data);
            data = data.map(banner => {
                return {
                    link: banner.url,
                    href: banner.imageUrl
                };
            });
            setBanners(data);
        });
    }, []);

    return (
        <div className={classnames(styles.banner, 'clearfix')}>
            <Carousel autoplay>
                {_banners.length > 0 ? (
                    _banners.map(({ link, href, title }, i) => {
                        return (
                            <div key={i}>
                                {link ? (
                                    <a href={link} target="_blank">
                                        <img src={prefix + href} alt={title} />
                                    </a>
                                ) : (
                                        <img src={prefix + href} alt={title} />
                                    )}
                            </div>
                        );
                    })
                ) : (
                        <a>
                            <img src={bannerBg} />
                        </a>
                    )}
            </Carousel>
        </div>
    );
}
