import React, { useEffect, useState } from 'react';
import { GroupBar } from '../../../../../echarts';
import Title from '../../../../../common/title';
import { mainItem } from './service';
import {
  isEmpty,
  isNotEmpty,
  moneyFormatter
} from '../../../../../utils/utils';

export default function Indicators({ match }) {
  const [data, setData] = useState([]);
  const [xaxis, setAxias] = useState([]);

  useEffect(() => {
    mainItem({ creditCode: match.params.companyCode }).then(res => {
      if (isNotEmpty(res) && isNotEmpty(res.data)) {
        const { data } = res;
        let years1 = [],
          years2 = [],
          years3 = [],
          years4 = [];
        const { assetList, cashList, debtList, netProfitList } = data;

        const assetListData = assetList
          ? assetList.map(a => {
              years1.push(a.year + '年');
              return { name: a.year, value: a.assetTotal };
            })
          : [];
        const cashListListData = cashList
          ? cashList.map(a => {
              years2.push(a.year + '年');
              return {
                name: a.year,
                value: a.managementCashFlowNetamoun
              };
            })
          : [];
        const debtListListData = debtList
          ? debtList.map(a => {
              years3.push(a.year + '年');
              return {
                name: a.year,
                value: a.liabilitiesTotal
              };
            })
          : [];
        const netProfitListListData = netProfitList
          ? netProfitList.map(a => {
              years4.push(a.year + '年');
              return { name: a.year, value: a.netProfit };
            })
          : [];
        setAxias([years1, years2, years3, years4]);
        setData([
          assetListData,
          cashListListData,
          debtListListData,
          netProfitListListData
        ]);
      }
    });
  }, []);

  function getBar(data) {
    return [
      {
        type: 'bar',
        barGap: '200%',
        itemStyle: { color: '#2082ED' },
        data: data[0] || [],
        xAxisIndex: 0,
        yAxisIndex: 0
      },
      {
        type: 'bar',
        barGap: '200%',
        itemStyle: { color: '#59DF8F' },
        data: data[1] || [],
        xAxisIndex: 1,
        yAxisIndex: 1
      },
      {
        type: 'bar',
        barGap: '200%',
        itemStyle: { color: '#FB7B39' },
        data: data[2] || [],
        xAxisIndex: 2,
        yAxisIndex: 2
      },
      {
        type: 'bar',
        barGap: '200%',
        itemStyle: { color: '#004591' },
        data: data[3] || [],
        xAxisIndex: 3,
        yAxisIndex: 3
      }
    ];
  }

  return (
    <>
      <Title level={3} text="主要指标" />
      <GroupBar
        nodata={
          isEmpty(data[0]) &&
          isEmpty(data[1]) &&
          isEmpty(data[2]) &&
          isEmpty(data[3])
        }
        style={{ height: 380 }}
        series={getBar(data)}
        formatter={params => {
          return `<p>
                                ${params
                                  .map(param => {
                                    return (
                                      param.data.name &&
                                      `<p>
                                               ${param.marker +
                                                 ' ' +
                                                 param.data.name +
                                                 '年' +
                                                 ': ' +
                                                 (param.data.value
                                                   ? moneyFormatter(
                                                       param.data.value
                                                     ) + '万'
                                                   : '暂无数据')}
                                           </p>`
                                    );
                                  })
                                  .join('')}
                            </p>`;
        }}
        options={{
          legend: {
            show: true
          },
          xAxis: [
            { gridIndex: 0, type: 'category', data: xaxis[0] },
            { gridIndex: 1, type: 'category', data: xaxis[1] },
            { gridIndex: 2, type: 'category', data: xaxis[2] },
            { gridIndex: 3, type: 'category', data: xaxis[3] }
          ],
          yAxis: [
            {
              gridIndex: 0,
              axisLabel: {
                formatter: value => moneyFormatter(value) + '万'
              }
            },
            {
              gridIndex: 1,
              axisLabel: {
                formatter: value => moneyFormatter(value) + '万'
              }
            },
            {
              gridIndex: 2,
              axisLabel: {
                formatter: value => moneyFormatter(value) + '万'
              }
            },
            {
              gridIndex: 3,
              axisLabel: {
                formatter: value => moneyFormatter(value) + '万'
              }
            }
          ],
          grid: [
            {
              x: '7%',
              y: '7%',
              width: '38%',
              height: '38%',
              containLabel: true
            },
            {
              x2: '7%',
              y: '7%',
              width: '38%',
              height: '38%',
              containLabel: true
            },
            {
              x: '7%',
              y2: '7%',
              width: '38%',
              height: '38%',
              containLabel: true
            },
            {
              x2: '7%',
              y2: '7%',
              width: '38%',
              height: '38%',
              containLabel: true
            }
          ],
          title: [
            {
              text: '资产总计',
              x: '25%',
              y: '0',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14
              }
              // padding: 16
            },
            {
              text: '经营活动产生的现金流量净额',
              x: '68%',
              y: '0',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14
              }
            },
            {
              text: '负债合计',
              x: '25%',
              y: '48%',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14
              }
            },
            {
              text: '净利润',
              x: '73%',
              y: '48%',
              textStyle: {
                fontWeight: 'normal',
                fontSize: 14
              }
            }
          ]
        }}
      />
    </>
  );
}

//'资产总额',
//'现金流量净额',
//'负债总额',
//'净利润'
