'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _force = require('../../../util/coordinate/force');

var _processor = require('../../../util/processor/processor');

var _dailog = require('../event/dailog');

var _eventFilter = require('../event/eventFilter');

var _enum = require('../../../constant/enum');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SeekChart = function (_Chart) {
    (0, _inherits3.default)(SeekChart, _Chart);

    function SeekChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, SeekChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (SeekChart.__proto__ || (0, _getPrototypeOf2.default)(SeekChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'seek';

        _this.size = (0, _extends3.default)({}, _this.painter._setting); // 尺寸

        // this.d3zoom = null; // 记录缩放，拖动的事件对象
        _this.state = null;
        _this.gData = null;
        _this.selectType = opts.relation ? opts.relation : 'INVEST_MANAGE';
        _this.lock = false; // 动的时候，不要触发坐标计算
        _this.centerId = opts.data.nodeIds;
        return _this;
    }

    (0, _createClass3.default)(SeekChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(SeekChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(SeekChart.prototype), 'beforeProcess', this).call(this);
        }
    }, {
        key: 'process',
        value: function process() {
            var state = this.getState(); // {node: [], links: []}

            if (!state || state.nodes === null) {
                console.warn('没有nodes 数据！');
                this.force && this.force.stop();
                this.setState(null);
                return;
            }

            this.state = state;
            this.gData = this.store.toGridState(_lodash2.default.cloneDeep(state));
            if (this.gData && this.gData.dataRight) {
                this.countPos(this.gData);
            } else {
                console.error('数据错误！没法绘图！', this.gData);
                this.setState(null);
            }
        }
    }, {
        key: 'afterProcess',
        value: function afterProcess() {
            var _this2 = this;

            (0, _get3.default)(SeekChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(SeekChart.prototype), 'handleDrag', this).call(this);
            // 显示弹窗
            this.handler.on('click', _dailog.eventDailog, this);
            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);

            this.handler.on('seek.selectType', function (d) {
                _this2.selectType = d;
            });

            // 清空画布
            this.handler.on('seek.clear', function () {
                _this2.force && _this2.force.stop();
                _this2.gData = null;
                _this2.setState(null);
            });

            // 新的点
            this.handler.on('seek.init', function (obj) {
                _this2.store.load('seek', 'init', obj).then(function (d) {
                    _this2.force.stop();
                    _this2.state = d;
                    _this2.gData = _this2.store.toGridState(_lodash2.default.cloneDeep(_this2.state));
                    _this2.countPos(_this2.gData);
                }, function (err) {
                    _this2.handler.trigger('seek.error', err);
                });
            });

            // 高亮点
            this.handler.on('seek.highlight', function (node) {
                // id = id.target.data.bbdQyxxId;
                if (node && node.shapeType !== 'node') {
                    return;
                }
                var data = null;
                var state = _this2.getState();
                _this2.gData = _this2.store.toGridState(state);
                var _gData = _this2.gData,
                    nodes = _gData.nodes,
                    links = _gData.links;

                if (node) {
                    if (node.bbdQyxxId) {
                        node.data = {
                            bbdQyxxId: node.bbdQyxxId
                        };
                        node.id = node.bbdQyxxId;
                    }

                    // data = getHightLight(node.bbdQyxxId || node.id, this.gData);
                    if (node.data.bbdQyxxId && _this2.options.relation === 'EVENT') {
                        data = (0, _eventFilter.getHightLightCompany)(node.id, _this2.gData);
                    } else {
                        data = (0, _processor.getHightLight)(node.id, _this2.gData);
                    }

                    nodes.forEach(function (d) {
                        d.slight = true;
                    });
                    links.forEach(function (d) {
                        d.slight = true;
                    });
                    data.nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    data.links.forEach(function (d) {
                        d.slight = false;
                    });
                } else {
                    nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    links.forEach(function (d) {
                        d.slight = false;
                    });
                }

                _this2.setState(_this2.gData);
            });
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.force && this.force.stop();
            (0, _get3.default)(SeekChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(SeekChart.prototype), 'dispose', this).call(this);
            this.handler.off('click', _dailog.eventDailog);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('dblclick', this.handleExtension);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);

            this.force = null;
            this.painter = null;
            this.store = null;
            this.handler = null;
            this.gData = null;
        }
    }, {
        key: 'countPos',
        value: function countPos(state) {
            var _this3 = this;

            var nodes = state.nodes,
                links = state.links;


            this.status = _enum.ChartStatus.BUSY;

            links.forEach(function (elem) {
                elem.source = elem.from;
                elem.target = elem.to;
            });

            // 设置mark
            nodes.forEach(function (elem) {
                elem.r = 0;
                if (_this3.centerId.includes(elem.id)) {
                    elem.isTarget = true;
                }
            });

            if (this.force) {
                this.force.stop();
            }

            this.force = (0, _force.defaultForce)(nodes, links, {
                center: this.getCenterPoint(),
                interval: function interval(d) {
                    var min = _this3.selectType === 'INVEST_MANAGE' ? 100 : 20;
                    var len = d.childs.length;
                    var wid = (len < 20 ? 20 : len) * 5;
                    if (wid > 200) {
                        wid = 200;
                    } else if (wid < min) {
                        wid = min;
                    }
                    return wid;
                },
                distanceMax: 100
            });

            this.force.on('tick', function () {
                nodes.forEach(function (node) {
                    node.position = [node.x, node.y];
                });
                _this3.setState(state);
            });

            this.force.on('end', function () {
                // 碰撞结束后再显示线的状态信息
                // this.setLinksStatus(links);
                _this3.setState(state);
                // 修改图谱当前状态
                _this3.status = _enum.ChartStatus.IDLE;
            });

            // 获取数据
            this.handler.trigger('seek.getState', _lodash2.default.cloneDeep(this.state));
            this.handler.trigger('chart.changeList', state);
        }
    }]);
    return SeekChart;
}(_Chart3.default); /**
                     * 关系探寻图
                     */


exports.default = SeekChart;
module.exports = exports['default'];