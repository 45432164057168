import "babel-polyfill";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import App from "./App";
import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  getLoginUserInfo,
  cookieResponse,
} from "./containers/userCenter/services";
import { queryStringAll, getCookie } from "./utils/utils";
import { message } from "antd";
import { DONT_NEED_LOGIN } from "./containers/router";

const renderTo = module.hot ? render : hydrate;
//监听cookie变化
const ssoToken = getCookie("ssoToken");
setInterval(() => {
  if (ssoToken && ssoToken !== getCookie("ssoToken")) {
    window.location.href = window.location.href;
  }
}, 1000);

const urlObj = queryStringAll();
if (urlObj && JSON.stringify(urlObj) !== "{}" && urlObj.ssoToken) {
  cookieResponse({ ssoToken: urlObj.ssoToken }).then((res) => {
    if (res && res.success) {
      onLoginUserInfo();
    } else {
      message.info(res.message);
    }
  });
} else {
  onLoginUserInfo();
}

function onLoginUserInfo() {
  getLoginUserInfo().then((res) => {
    if (res && !res.data) {
      renderTo(
        <BrowserRouter>
          <App />
        </BrowserRouter>,
        document.getElementById("root")
      );
    } else {
      renderTo(
        <BrowserRouter>
          <App user={res?.data} />
        </BrowserRouter>,
        document.getElementById("root")
      );
    }
  });
}

if (module.hot) {
  module.hot.accept();
}
