/**
 * @desc  每日成交量前十大个股情况
 * @desc  每日成交量前10大个股辅导券商/做市商
 */
import React, { useState } from 'react';
import IndividualShares from './IndividualShares'
import TopTurnover from "./TopTurnover";
import style from './styles.scss'
import Radios from "./radio";

export default function LeftSecondIndex({type}) {

    const [idx, setIdx] = useState(1);
    const radiosData = [
        { name: '前十大个股', value: 1 },
        { name: '前十大券商/做市商', value: 2 },
    ];

    return(
        <div className={style.leftSecondDiv}>
            <div className={style.divTitle}>
                每日成交量情况
            </div>
            <div className={style.divRadio}>
                <Radios
                    value={idx}
                    data={radiosData}
                    defaultValue={1}
                    onChange={e => setIdx(e.target.value)}
                />
            </div>
            {
                idx==1 ? <IndividualShares type={type}/>:
                    <TopTurnover type={type} />
            }

        </div>

    )
}
