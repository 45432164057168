import React, { useEffect } from 'react';
import Model from './model';
import View from './view';

export default function(props) {
    useEffect(() => {
        props.setBannerShow(false);
    }, []);
    return (
        <Model {...props}>
            {prop => {
                return <View {...prop} />;
            }}
        </Model>
    );
}
