import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import Radios from '../leftFirst/radio';
import { riskAverage } from './service';
import { getAreas } from '../service';
import { isNotEmpty } from '../../../../utils/utils';
import Pic1 from '../images/1.png';
import Pic2 from '../images/2.png';
import Pic3 from '../images/3.png';
import Pic4 from '../images/4.png';

const radiosData = [
    { name: '全国', value: 1 },
    { name: '北京', value: 2 },
    { name: '上海', value: 3 },
    { name: '更多', value: 4 }
];

export default function MapTools({ address }) {
    const [data, setData] = useState({
        ipoAccept: 0,
        ipoRegist: 0,
        ipoAmount: 0,
        ipoTermination: 0,
    });
    const [provinces, setProvince] = useState([]);

    useEffect(() => {
        riskAverage({
            area: address
        }).then(res => {
            if (isNotEmpty(res?.data)) {
                setData(res.data);
            }
        });

        getAreas().then(res => {
            const arr = res && res.data.reduce((acc, cur) => {
                if (address.toString().indexOf(`${cur.code}`) !== -1) {
                  acc.push(cur.area)
                  return acc;
                }
                return acc;
            }, [])
            setProvince(arr)
        })
    }, [address]);

    return (
        <div className={styles.mapTool}>
            <br />
            <div style={{
                width: '180px',
                height: '46px',
                float: 'left',
                fontSize: '16px',
                color: '#8fc8ff',
                marginLeft: '8vw',
                background: `url(${Pic1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}已受理&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: '2px',
                    paddingRight: '15px',
                    fontSize: '20px',
                    color: '#fff',
                }}>
                    {data.ipoAccept}
                </div>
            </div>
            {/*<div style={{*/}
            {/*    width: '180px',*/}
            {/*    height: '46px',*/}
            {/*    float: 'left',*/}
            {/*    fontSize: '16px',*/}
            {/*    color: '#8fc8ff',*/}
            {/*    marginLeft: '10px',*/}
            {/*    background: `url(${Pic2})`,*/}
            {/*    backgroundRepeat: 'no-repeat',*/}
            {/*    backgroundSize: '100% 100%',*/}
            {/*}}>*/}
            {/*    <div style={{*/}
            {/*        float: 'left',*/}
            {/*        paddingTop: '15px',*/}
            {/*        paddingLeft: '15px',*/}
            {/*    }}>*/}
            {/*        {provinces && provinces.length ? provinces : '全国'}已注册&nbsp;&nbsp;&nbsp;&nbsp;*/}
            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*        paddingTop: '2px',*/}
            {/*        paddingRight: '15px',*/}
            {/*        fontSize: '20px',*/}
            {/*        color: '#fff',*/}
            {/*    }}>*/}
            {/*        {data.ipoRegist}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div style={{
                width: '180px',
                height: '52px',
                float: 'left',
                fontSize: '16px',
                color: '#8fc8ff',
                marginLeft: '2vw',
                background: `url(${Pic3})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}已上市&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: '2px',
                    paddingRight: '15px',
                    fontSize: '20px',
                    color: '#fff',
                }}>
                    {data.ipoAmount}
                </div>
            </div>
            <div style={{
                width: '180px',
                height: '46px',
                float: 'left',
                fontSize: '16px',
                color: '#8fc8ff',
                marginLeft: '2vw',
                background: `url(${Pic4})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}已终止&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: '2px',
                    paddingRight: '15px',
                    fontSize: '20px',
                    color: '#fff',
                }}>
                    {data.ipoTermination}
                </div>
            </div>
        </div>
    );
}
