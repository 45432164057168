/**
 * @author Ray
 * @desc 入库企业分析-基础信息-核心竞争力-专利
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { BrandsColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { brands } from './service';
import Title from '../../../../../common/title';

export default function Patents({ match }) {
    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <>
            <Title level={3} text="商标" />
            <Table
                query={query}
                getData={brands}
                parseData={res =>
                    (isNotEmpty(res) && isNotEmpty(res.data) && res.data) || []
                }
                columns={BrandsColumns}
                locale={{emptyText: '未发现企业的专利记录。不排除存在未公示或时间相对滞后的情况。'}}

            />
        </>
    );
}
