/**
 * @desc  每月新三板交易情况
 */
import React, { useState, useEffect } from 'react';
import RightFirst from './Transactions'

export default function RightFirstIndex() {
    return(
        <RightFirst />
    )
}
