import React, { useState, useEffect } from 'react';
import LeftFirst from './IndustryDistribution'
/**
 * @desc  挂牌公司行业分布情况
 */

export default function LeftFirstIndex() {
    return(
        <div>
            <LeftFirst />
        </div>
    )
}
