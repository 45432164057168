'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _common = require('../../../util/processor/common');

var _circlePos = require('../../../util/coordinate/circlePos');

var _processor = require('../../../util/processor/processor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 抵押担保分布图
 */
var MortgageChart = function (_Chart) {
    (0, _inherits3.default)(MortgageChart, _Chart);

    function MortgageChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, MortgageChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (MortgageChart.__proto__ || (0, _getPrototypeOf2.default)(MortgageChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'mortgage';

        _this.process = function () {
            _this.centerId = (0, _common.getTarget)(_this.getState()).id;
        };

        _this.highlight = function (node) {
            var state = _this.getState();
            if (state && state.nodes && state.nodes.length > 0) {
                state.nodes.map(function (n) {
                    return n.animation = false;
                });
                state.links.map(function (n) {
                    return n.animation = false;
                });
                var gridState = _this.store.toGridState(state, _this.centerId);

                if (node && node.shapeType === 'node') {
                    var highLight = (0, _processor.getRelationByNodeId)(node.data.bbdQyxxId, gridState);
                    _this.setHighLight(highLight, gridState);
                } else {
                    gridState.nodes.map(function (n) {
                        return n.slight = false;
                    });
                    gridState.links.map(function (n) {
                        return n.slight = false;
                    });
                }
                _this.render(gridState);
            }
        };

        _this.setHighLight = function (newPoint, gridState) {
            gridState.nodes.map(function (n) {
                return n.slight = true;
            });
            gridState.links.map(function (n) {
                return n.slight = true;
            });

            newPoint.nodes.map(function (n) {
                return n.slight = false;
            });
            newPoint.links.map(function (n) {
                return n.slight = false;
            });

            gridState = _this.store.mergeState(gridState, newPoint);
        };

        _this.render = function (gridState) {
            _this.setState(gridState);
            _this.handler.trigger('chart.changeList', gridState);
            _this.handler.trigger('chart.getSettingStatus');
            _this.handler.trigger('chart.resetFilterSetting');
        };

        _this.updateNode = function () {
            var state = _this.getState();

            if (state && state.nodes && state.nodes.length > 0) {
                var highLightNodes = state.nodes.filter(function (node) {
                    return node.isTarget || node.data.labels.includes('MORTGAGE_GUARANTEE');
                });
                var highLightLinks = state.links.filter(function (link) {
                    return link.data.labels.includes('MORTGAGE_GUARANTEE');
                });

                var gridState = _this.store.toGridState(state, _this.centerId, function (nodes) {
                    nodes.forEach(function (node) {
                        return node.degree = node.data.degree;
                    });
                });

                _this.setHighLight({ nodes: highLightNodes, links: highLightLinks }, gridState);

                (0, _circlePos.circlePos)(gridState, _this.info);
                _this.render(gridState);
            } else {
                _this.setState({});
            }
        };

        _this.info = {
            center: _this.getCenterPoint(),
            size: {
                nodesInterval: opts.nodesInterval || 70, // 节点间隔
                groupsInterval: opts.groupsInterval || 130, // 组的间隔
                circleInterval: opts.circleInterval || 50 // 圈层间隔
            }
        };
        _this.centerId = null;
        return _this;
    }

    (0, _createClass3.default)(MortgageChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(MortgageChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(MortgageChart.prototype), 'beforeProcess', this).call(this);
            this.mouseEvent();
        }
    }, {
        key: 'afterProcess',


        // 监听事件或行为的最佳位置
        value: function afterProcess() {
            this.handler.trigger(this.type + '.init');
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            (0, _get3.default)(MortgageChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(MortgageChart.prototype), 'dispose', this).call(this);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);
            this.handler.off(this.type + '.init', this.updateNode);
            this.handler.off(this.type + '.highlight', this.highlight);

            this.painter = null;
            this.store = null;
            this.handler = null;
        }
    }, {
        key: 'mouseEvent',
        value: function mouseEvent() {
            (0, _get3.default)(MortgageChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(MortgageChart.prototype), 'handleDrag', this).call(this);

            this.handler.on('click', this.handleHighlight);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);
            // 初始化
            this.handler.on(this.type + '.init', this.updateNode);
            // 高亮
            this.handler.on(this.type + '.highlight', this.highlight);
        }

        /**
         * 高亮 
         */


        /**
         * 设置高亮
         */


        /**
         * 渲染
         */

    }]);
    return MortgageChart;
}(_Chart3.default);

exports.default = MortgageChart;
module.exports = exports['default'];