/**
 * @description 外商企业上市
*/
import React from 'react';
import Model from './model';
import View from './view';

export default function(props) {
    return (
        <Model>
            {prop => {
                return <View {...props} {...prop} />;
            }}
        </Model>
    )
}