import React, { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Selector } from '../../merging/home/apply';
import { GroupTypes, PLATES } from '../../../constants/enum';
import { getSscIndustry } from '../../imformation/basiInfo/service';
import Auto from '../../../common/autoComplete';
import { debounce } from '../../../utils/utils';
import PlateSelection from '../../../common/plateSelection';
export function SearchForm({ form, submit }) {
  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      submit(values);
    });
  };

  return (
    <div id='expertSearch' style={{ position: 'relative' }}>
      <Form layout='inline' onSubmit={handleSubmit}>
        <Form.Item label='专家名称'>
          {getFieldDecorator('content', {
            rules: []
          })(<Input placeholder={'请输入专家名称'} style={{ width: 180 }} />)}
        </Form.Item>
        <Form.Item label='所属机构'>
          {getFieldDecorator('zjInInstitude', {
            rules: []
          })(<Input placeholder={'请输入机构名称'} style={{ width: 180 }} />)}
        </Form.Item>
        <Form.Item label='主要服务领域'>
          {getFieldDecorator('industry', {
            rules: []
          })(
            <Auto
              paramKey='srcIndustry'
              parser={data =>
                data
                  ? data.map(d => ({
                    value: d.industry,
                    label: d.industry,
                    key: d.typeCode
                  }))
                  : []
              }
              getPopupContainer={() => document.getElementById('expertSearch')}
              placeholder={'请输入领域名称'}
              loadSource={getSscIndustry}
              style={{ width: 180 }}
            />
          )}
        </Form.Item>
        <Form.Item label='主要服务板块'>
          {getFieldDecorator('plate', {
            rules: []
          })(
            // <Selector
            //   showSelectAll
            //   options={PLATES}
            //   getPopupContainer={() => document.getElementById('expertSearch')}
            // />
            <PlateSelection width={143}/>
          )}
        </Form.Item>
        <Form.Item label='专家类型'>
          {getFieldDecorator('type', {
            rules: []
          })(
            <Selector
              showSelectAll
              options={GroupTypes}
              getPopupContainer={() => document.getElementById('expertSearch')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            搜索
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default function Search({ onChange }) {
  const [E, setE] = useState(null);
  useEffect(function () {
    const Element = Form.create({
      name: 'form'
      //   onValuesChange: debounce(function(a, b, values) {
      //     onChange(values);
      //   }, 500)
    })(SearchForm);

    setE(<Element submit={onChange} />);
  }, []);

  return E;
}
