/**
 * @author hjie
 * 政企协调
 */

import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Pagination, Empty } from 'antd';
import { getExams } from './service';
import { StatusCode } from 'constants/enum';
import moment from 'moment';

export const IsSubmit = {
    '0': '待反馈',
    '1': '填写中',
    '2': '已反馈'
}
export default function(props) {

    const [list, setList] = useState({data: [], total: 0, loading: true });
    const [ pageNum, setPage ] = useState(1);
    // 问卷列表
    function getList(){
        getExams({
            pageNum,
            pageSize: 10,
            enable: 1,
            isOwn: 1,
            noOut: 1
        }).then(res =>{
            const { statusCode, data } = res;
            if( statusCode === StatusCode.success ){
                setList({ data: data.data, total: data.totalCount, loading: false });
            }else{
                setList({total: 0, loading: false});
            }
        })
    }
    useEffect(() => {
        getList();
    }, [pageNum])

    return (
        <div className={styles.questionnaire}>
            <div className = {classnames("titleLine", styles.title)} mark = "\ \ \ ">企业问卷</div>
            {
                list.loading ? '' :
                list.total > 0 ?
                <>
                    <div className = {styles.list}>
                        <ul>
                            {
                                
                                    list.data.map( ele => {
                                        const {name, id, gmtCreate, isSubmit} = ele;
                                        return  <li key = {id}>
                                                    <Link className = "ellipsis" to = {`/listingTraining/coordinationDetail/${id}`} >{name}</Link>
                                                    <span className = {styles.status} >{IsSubmit[isSubmit]}</span>
                                                    <span className = {styles.date}>{moment(gmtCreate).format('YYYY/MM/DD')}</span>
                                                </li>;
                                    })

                            }
                        
                        </ul>
                    </div>
                    <div className = {styles.pageList}>
                        <Pagination 
                            showQuickJumper 
                            current={pageNum} 
                            size = "small" 
                            total={list.total} 
                            onChange={ (page) => setPage(page) }
                            showTotal={total =>
                                `共 ${Math.ceil(
                                    total / 10
                                )} 页 / ${total} 条数据`
                            }
                         /> 
                    </div>
                </> 
                :
                <Empty />
            }
        </div>
    );
}
