import styles from './styles.scss';
import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import { lastestIPOCompanyList, turnoverRate, turnover } from './service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../utils/utils';
import { NegativeNumber } from '../../../../utils/validator';
import Radios from './radio';
/**
 * @desc 最新上市公司
 */
global.LastestIPOCompanyList = null;
export default function LastestIPOCompanyList({ address, value }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        //请求换手率接口数据
        value == 1 ? turnoverRate().then(({ success, data }) => {
            if (success == true) {
                if (global.LastestIPOCompanyList == null) {
                    global.LastestIPOCompanyList = new Chart({
                        container: 'turnoverRate',
                        autoFit: true,
                        height: 180,
                        padding:[35,10,20,25]
                    });
                    
                    global.LastestIPOCompanyList.data(data);
                    global.LastestIPOCompanyList.scale({
                        type: {
                        range: [0, 1],
                        },
                        decimal: {
                        nice: true,
                        },
                    });
                    
                    global.LastestIPOCompanyList.axis('decimal', {
                        label: {
                        formatter: (val) => {
                            return val;
                        },
                        },
                    });

                    global.LastestIPOCompanyList.axis('type', {
                        label: {
                        formatter: (val) => {
                            return val + '月';
                        },
                        },
                    });
                    
                    global.LastestIPOCompanyList
                        .line()
                        .position('type*decimal')
                        .color('item')
                        .shape('smooth');
                    
                    global.LastestIPOCompanyList
                        .point()
                        .position('type*decimal')
                        .color('item')
                        .label("decimal")
                        .shape('circle');
                    global.LastestIPOCompanyList.legend({
                        position: 'top',
                        });
                    global.LastestIPOCompanyList.tooltip(false);
                    global.LastestIPOCompanyList.render();
                } else {
                    global.LastestIPOCompanyList.clear();
                    global.LastestIPOCompanyList.changeData(data);
                    global.LastestIPOCompanyList.scale({
                        type: {
                        range: [0, 1],
                        },
                        decimal: {
                        nice: true,
                        },
                    });
                    
                    global.LastestIPOCompanyList.tooltip({
                        showCrosshairs: true,
                        shared: true,
                    });
                    
                    global.LastestIPOCompanyList.axis('decimal', {
                        label: {
                        formatter: (val) => {
                            return val;
                        },
                        },
                    });
                    
                    global.LastestIPOCompanyList
                        .line()
                        .position('type*decimal')
                        .color('item')
                        .shape('smooth');
                    
                    global.LastestIPOCompanyList
                        .point()
                        .position('type*decimal')
                        .color('item')
                        .label("decimal")
                        .shape('circle');
                    global.LastestIPOCompanyList.tooltip(false)
                    global.LastestIPOCompanyList.render();
                }
            } else {
                <Empty />
            }
        }) : 
        //请求成交额接口数据
        value == 2 ? turnover().then(({ success, data }) => {
            if (success == true) {
                global.LastestIPOCompanyList.clear();
                global.LastestIPOCompanyList.changeData(data);
                global.LastestIPOCompanyList.scale({
                    type: {
                    range: [0, 1],
                    },
                    decimal: {
                    nice: true,
                    },
                });
                
                global.LastestIPOCompanyList.tooltip({
                    showCrosshairs: true,
                    shared: true,
                });
                
                global.LastestIPOCompanyList.axis('decimal', {
                    label: {
                    formatter: (val) => {
                        return val;
                    },
                    },
                });
                
                global.LastestIPOCompanyList
                    .line()
                    .position('type*decimal')
                    .color('item')
                    .shape('smooth');
                
                global.LastestIPOCompanyList
                    .point()
                    .position('type*decimal')
                    .color('item')
                    .label("decimal")
                    .shape('circle');
                global.LastestIPOCompanyList.tooltip(false)
                global.LastestIPOCompanyList.render();
            } else {
                <Empty />
            }
        }) : <Empty />
    }, [address, value])

    return (
        <div id='turnoverRate' className={ styles.turnoverRate }></div>
    )
    
}