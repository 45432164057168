/**
 * @author Ray
 * @desc 研发能力情况
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, InputNumber, Tooltip, Icon } from 'antd';
import { SaveButton } from '../../../common/buttons';
import {
  GreaterThanZro,
  GreaterThanZroAndLessThanHundred
} from '../../../utils/validator';
import { showModal, closeModal } from '../../investment/script/actions';
const MESSAGE1 = '判断企业营业收入构成中来源于核心技术的占比情况。';
const MESSAGE2 = '仅考虑公司在国内市场中的市场占有率。';

const FormEle = React.forwardRef(({ form, data }, ref) => {
  data = data || {};
  React.useImperativeHandle(ref, () => ({ form }));
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
    >
      <Form.Item
        label={
          <span>
            专利、著作权、新药批件相关收入占总收入比重&nbsp;
            <Tooltip placement='right' title={MESSAGE1}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('totalIncomeRatio', {
          rules: [GreaterThanZro],
          initialValue: data.totalIncomeRatio
        })(
          <InputNumber
            max={100}
            min={0}
            precision={2}
            formatter={function(value) {
              return value + '%';
            }}
          />
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            公司核心产品市场占有率&nbsp;
            <Tooltip placement='right' title={MESSAGE2}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('productMarketRatio', {
          rules: [GreaterThanZroAndLessThanHundred],
          initialValue: data.productMarketRatio
        })(
          <InputNumber
            max={100}
            min={0}
            precision={2}
            formatter={function(value) {
              return value + '%';
            }}
          />
        )}
      </Form.Item>
      <Form.Item label='核心产品销售收入增长速率'>
        {form.getFieldDecorator('latestYearCoreRate', {
          rules: [],
          initialValue: data.latestYearCoreRate
        })(
          <InputNumber
            maxLength={9}
            formatter={function(value) {
              return value + '%';
            }}
          />
        )}
      </Form.Item>
    </Form>
  );
});

export const FormElement = ({ formRef, data }) => {
  let [Element, setElement] = useState(null);

  useEffect(
    function() {
      let E = Form.create({
        name: 'core',
        onValuesChange: () => {}
      })(FormEle);

      setElement(<E wrappedComponentRef={formRef} data={data} />);
    },
    [data]
  );

  return Element;
};

let currentKey = 4;
export default function TechTransfer(props) {
  const formRef = useRef(null);

  const save = () => {
    formRef.current.form.validateFields((errors, values) => {
      props.saveFun(values);
    });
  };
  useEffect(
    function() {
      if (props.activeKey != currentKey && currentKey == 4) {
        currentKey = props.activeKey;
        const currentValue = formRef.current.form.getFieldsValue();
        const hasChanged = Object.keys(currentValue).filter(function(
          value,
          index
        ) {
          return currentValue[value] !== props.data[value];
        });
        if (hasChanged.length) {
          props.dispatch(
            showModal({
              title: '技术成果转化情况',
              cancelText: '不保存！',
              okText: '保存！',
              onOk: () => {
                props.dispatch(closeModal());
                formRef.current.form.validateFields((errors, values) => {
                  props.saveFun(values, function() {
                    hasChanged.forEach(value => {
                      formRef.current.form.setFieldsValue({
                        [value]: props.data[value]
                      });
                      props.dispatch(closeModal());
                    });
                  });
                });
              },
              onClose: () => {
                // 不保存恢复原样
                hasChanged.forEach(value => {
                  formRef.current.form.setFieldsValue({
                    [value]: props.data[value]
                  });
                  props.dispatch(closeModal());
                });
              }
            })
          );
        }
      }
      if (props.activeKey == 4) {
        currentKey = 4;
      }
    },
    [props.activeKey, props.data]
  );

  return (
    <div id={'techTransfer'}>
      <FormElement formRef={formRef} data={props.data} />
      <div className='textCenter marginTop'>
        <SaveButton onClick={save} />
      </div>
    </div>
  );
}
