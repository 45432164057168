/**
 * @desc  退市公司和挂牌公司分布对比
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import BarChart from './barchart';

export default function Line5Index() {
    
    return (
        <div className={ styles.line5 }>
            <Title />
            <BarChart />
        </div>
        
    );
}
