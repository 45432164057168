/**
 * @author Ray
 * @description 利润表
 */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, message, Tabs } from 'antd';
import { FlexibleTable } from '../flexibleTable';
import { SaveButton } from '../buttons';
import styles from './styles.scss';
import { callFinance, submitProfitInfo } from './service';
import { isEmpty, isNotEmpty } from '../../utils/utils';
import { convertData } from './utils';
import useProps from './useTableProps';
import useTimeRangerProps from './useTimeRangerProps';
import { GlobalContext } from '../globalStore/createProvider';

const { TabPane } = Tabs;

let formMap = new Map();

export default function CashFlow({ readOnly, applyId, signal, type, code }) {
    const [year, setYear] = useState(2022);
    const [tableProps, setTableProps] = useProps();
    const [query, setQuery] = useTimeRangerProps(readOnly);
    const { user } = useContext(GlobalContext);

    function handleFormInit(name, form) {
        formMap.set(name, form);
    }

    // 第一次加载的时候调用一次获取数据
    useEffect(
        function() {
            if (isNotEmpty(signal)) {
                applyId = undefined;
            }

            getFinance(query, user, applyId);
        },
        [signal, applyId, year]
    );

    // 查找财务信息
    function getFinance(query, user, applyId) {
        callFinance(
            parseInt(year),
            parseInt(year),
            4,
            code || user?.creditCode,
            applyId,
            undefined,
            type
        ).then(function(res) {
            if (isEmpty(res))
                return setTableProps({
                    columns: [],
                    dataSource: []
                });

            let tableProps = convertData(4, res.data);

            setTableProps({
                ...tableProps
            });

            //  form表单重置为默认值
            formMap.forEach((form) => {
                form.resetFields();
            })
        });
    }

    // 文件上传成功
    function fileUpload(url) {
        if (isEmpty(url)) return;
        message.success('上传成功！');
        getFinance(query, user);
    }

    function handleSave() {
        formMap.forEach((form) => {
            form.validateFieldsAndScroll((errors, values) => {

                let obj = {
                    "managementAssetFlow": values['一、经营活动产生的现金流量：'] || '--',
                    "receivedAsset": values['销售商品、提供劳务收到的现金'] || '--',
                    "receivedTaxReturn": values['收到的税费返还'] || '--',
                    "receivedOtherAsset": values['收到的其他与经营活动有关的现金'] || '--',
                    "assetInflowSubtotal": values['经营活动现金流入小计'] || '--',
                    "labourPaymentAsset": values['购买商品、接受劳务支付的现金'] || '--',
                    "workersPaymentsAsset": values['支付给职工以及为职工支付的现金'] || '--',
                    "paymentsTax": values['支付的各项税费'] || '--',
                    "paymentsOtherAsset": values['支付的其他与经营活动有关的现金'] || '--',
                    "assetOutflowSubtotal": values['经营活动现金流出小计'] || '--',
                    "managementAssetFlowNetamoun": values['经营活动产生的现金流量净额'] || '--',
                    "investAssetFlow": values['二、投资活动产生的现金流量：'] || '--',
                    "backBackReceivedAsset": values['收回投资所收到的现金'] || '--',
                    "getInvestProfitReceivedAsset": values['取得投资收益所收到的现金'] || '--',
                    "handleFixedAssetNetamoun": values['处置固定资产、无形资产和其他长期资产所收回的现金净额'] || '--',
                    "handleOtherUnitNetamoun": values['处置子公司及其他营业单位收到的现金净额'] || '--',
                    "receivedOtherInvestAsset": values['收到的其他与投资活动有关的现金'] || '--',
                    "investInflowSubtotal": values['投资活动现金流入小计'] || '--',
                    "buyFixedPayAsset": values['购建固定资产、无形资产和其他长期资产所支付的现金'] || '--',
                    "investPaymentAsset": values['投资支付的现金'] || '--',
                    "getOtherUnitNetamoun": values['取得子公司及其他营业单位支付的现金净额'] || '--',
                    "paymentOtherInvestAsset": values['支付其他与投资活动有关的现金'] || '--',
                    "investOutflowSubtotal": values['投资活动现金流出小计'] || '--',
                    "investAssetNetamoun": values['投资活动产生的现金流量净额'] || '--',
                    "financingAssetFlow": values['三、筹资活动产生的现金流量：'] || '--',
                    "absorbInvestAsset": values['吸收投资收到的现金'] || '--',
                    "obtainLoanAsset": values['取得借款收到的现金'] || '--',
                    "obtainOtherfinancingAsset": values['收到其他与筹资活动有关的现金'] || '--',
                    "financingInflowSubtotal": values['筹资活动现金流入小计'] || '--',
                    "repayDebtPaymentAsset": values['偿还债务支付的现金'] || '--',
                    "profitPaymentAsset": values['分配股利、利润或偿付利息支付的现金'] || '--',
                    "paymentOtherFinancingAsset": values['支付其他与筹资活动有关的现金'] || '--',
                    "financingOutflowSubtotal": values['筹资活动现金流出小计'] || '--',
                    "financingAssetNetamoun": values['筹资活动产生的现金流量净额'] || '--',
                    "assetInfluence": values['四、汇率变动对现金及现金等价物的影响'] || '--',
                    "assetIncrement": values['五、现金及现金等价物净增加额'] || '--',
                    "startAssetBalance": values['加：期初现金及现金等价物余额'] || '--',
                    "endAssetBalance": values['六、期末现金及现金等价物余额'] || '--'
                }

                submitProfitInfo(
                    4,
                    parseInt(year),
                    {},
                    {},
                    obj
                ).then(res => {
                    if (res.success == true) {
                        message.success(res.message);
                    } else {
                        message.error(res.message);
                    }
                })
            })
        })
    }


    return (
        <div className={styles.root}>
            {readOnly ? 
                <Tabs onChange={(val) => setYear(val)}>
                    <TabPane tab='2022' key='2022'></TabPane>
                    <TabPane tab='2021' key='2021'></TabPane>
                    <TabPane tab='2020' key='2020'></TabPane>
                </Tabs>
                    : 
                (<Row className={styles.row}>
                    <Col span={17}>
                        <Tabs onChange={(val) => setYear(val)}>
                            <TabPane tab='2022' key='2022'></TabPane>
                            <TabPane tab='2021' key='2021'></TabPane>
                            <TabPane tab='2020' key='2020'></TabPane>
                        </Tabs>
                    </Col>
                </Row>
            )}

            <FlexibleTable {...tableProps} onInit={handleFormInit} year={year} type={4} readOnly={readOnly} />
            {readOnly == false && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </div>
    );
}
