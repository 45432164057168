/**
 * @desc 入库企业分析-基础信息-司法诉讼-失信被执行人
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { trustExecutedPersonInformationColumns } from './columns';
import { getLostTrustee } from './service';
import { isNotEmpty } from '../../../../../utils/utils';

/**
 *
 * @returns {*}
 */
export default function TrustExecutedPersonInformation({ match }) {
    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    const [query] = useState({
        qyxxId: match.params.companyId,
        companyName: match.params.companyName
    });

    return (
        <Table
            query={query}
            columns={trustExecutedPersonInformationColumns}
            getData={getLostTrustee}
            parseData={parseData}
            locale={{emptyText: '未发现企业的失信被执行人记录。不排除存在未公示或时间相对滞后的情况。'}}

        />
    );
}
