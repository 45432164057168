import React, {useState} from 'react';
import { Table } from 'antd'
import {transferStringNull} from 'utils/utils';

const getColumns = (page) => {
    return [
        {
            title: '序号',
            dataIndex: 'num',
            key: 'num',
            render: (text, item, index) => {
                return (index + 1) +  (page - 1) * 10;
            }  
        },
        {
            title: '姓名\/名称',
            dataIndex: 'shareholderName',
            key: 'shareholderName',
            width: '30%',
            render: transferStringNull
        },
        {
            title: '持有股数',
            dataIndex: 'investAmount',
            key: 'investAmount',
            render: transferStringNull
        },
        {
            title: '股权占比',
            dataIndex: 'investRatio',
            key: 'investRatio',
            width: '30%',
            render: transferStringNull
        }
        // {
        //     title: '认缴出资方式',
        //     dataIndex: 'subDetailSubName',
        //     key: 'subDetailSubName',
        //     render: transferStringNull
        // }
    ];
}

export default function(props) {
    const [page, setPage] = useState(1);
    const columns = getColumns(page);
    return (
        <Table 
            columns={columns} 
            dataSource={props.data} 
            pagination = {{
                hideOnSinglePage: true,
                onChange: (page) => setPage(page)
            }}
        />
    );
}
