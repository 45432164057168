/**
 * @author Ray
 */

import React from 'react';
import { Descriptions } from 'antd';
import styles from './styles.scss';

const { Item } = Descriptions;

export default function({ data = {} }) {
    const { companyName, code, plate, stage, district, companyProvince } = data;

    return (
        <Descriptions className={styles.descriptionRoot} column={2}>
            <Item label="企业名称" span={1}>
                {companyName || '--'}
            </Item>
            <Item label="统一社会信用代码" span={1}>
                {code || '--'}
            </Item>
            <Item label="上市板块" span={1}>
                {plate || '--'}
            </Item>
            <Item label="上市阶段" span={1}>
                {stage || '--'}
            </Item>
            <Item label="所属地区" span={1}>
                {!companyProvince && !district
                    ? '--'
                    : (companyProvince || '') + ' ' + (district || '')}
            </Item>
        </Descriptions>
    );
}
