/**
 * @author Ray
 * @desc 上市服务情况-服务板块和行业分布/上市过会公司数量
 */
import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import ServiceAndIndustry from '../chart/serviceAndIndustry';
import { Line } from '../../../../echarts';
import {
    queryAgencyServicePlate,
    queryGhCompanyCount,
    queryAgencyServiceIndustryFb
} from './service';
import { isNotEmpty } from '../../../../utils/utils';

export default function ListingService({ match }) {
    const [agency] = useState(match.params.companyName);
    const [plateData, setPlateData] = useState([]);
    const [plateLoading, setPlateLoading] = useState(true);
    const [countLoading, setCountLoading] = useState(true);
    const [ghCountData, setGhCountData] = useState([]);

    useEffect(() => {
        queryAgencyServiceIndustryFb(agency)
            .then(res => {
                if (isNotEmpty(res.data)) {
                    return res.data;
                }
                return [];
            })
            .then(industriesData => {
                queryAgencyServicePlate(agency).then(res => {
                    if (isNotEmpty(res.data)) {
                        setPlateData({
                            industry: industriesData,
                            plate: res.data
                        });
                    }
                    setPlateLoading(false);
                });
            });
        queryGhCompanyCount(agency).then(res => {
            if (isNotEmpty(res.data)) {
                setGhCountData(res.data);
            }
            setCountLoading(false);
        });
    }, []);

    return (
        <Row>
            <Col span={12}>
                <Typography.Title level={4} className="textCenter">
                    服务板块和行业分布
                </Typography.Title>
                <ServiceAndIndustry
                    data={plateData}
                    nodata={!plateLoading && plateData.length === 0}
                    loading={plateLoading}
                />
            </Col>
            <Col span={12}>
                <Typography.Title level={4} className="textCenter">
                    上市过会公司数量
                </Typography.Title>
                <Line
                    nodata={!countLoading && ghCountData.length === 0}
                    showLoading={countLoading}
                    xAxis={ghCountData.map(data => data.year + '年')}
                    yAxisFormatter={v => (/^\d+$/.test(v) ? v : '')}
                    loadingOpt={{}}
                    series={[
                        {
                            type: 'line',
                            data: [...ghCountData.map(data => data.count)],
                            label: {
                                formatter: '{b}: {c}'
                            }
                        }
                    ]}
                />
            </Col>
        </Row>
    );
}
