/**
 * @author Ray
 * @desc 入库企业分析-基础信息-上市能力分析-企业估值
 */
import React, { useEffect, useState } from 'react';
import {
    valuationMethods,
    fetchValuationByRatio,
    fetchRecommendMethod
} from './service';
import Title from '../../../../../common/title';
import styles from '../styles.scss';
import { Button, InputNumber } from 'antd';
import { formatNumber } from '../../../../../utils/utils';
import { PositiveFloat } from '../../../../../utils/validator';

export default function Valuation({ match, isIpoCompany }) {
    const [recommend, setRecommend] = useState('');
    const [query] = useState({ creditCode: match.params.companyCode });
    const [ratio1, setRatio1] = useState(undefined);
    const [ratio2, setRatio2] = useState(undefined);
    const [ratio3, setRatio3] = useState(undefined);
    const [valuation1, setValuation1] = useState('暂无估值');
    const [valuation2, setValuation2] = useState('暂无估值');
    const [valuation3, setValuation3] = useState('暂无估值');

    const [valuation4, setValuation4] = useState('暂无估值');
    const [valuation5, setValuation5] = useState('暂无估值');

    useEffect(() => {
        getValuations({ query });
        fetchRecommendMethod({ code: query.creditCode }).then(res => {
            if (res?.data) {
                const { industry = 'abc行业', method = 'PE' } = res.data;
                let str1 = industry
                    ? `该企业的证监会行业分类为：<i style="color:#1890ff">${industry}</i>`
                    : '';
                let str2 = method
                    ? `，专家经验建议选用<i style="color:#1890ff">${method}</i>估值法`
                    : '';
                setRecommend(str1 + str2);
            }
        });
    }, []);

    function getValuations() {
        valuationMethods({ ...query }).then(res => {
            setValuation4(
                res?.data?.filter(valuation => valuation.method === 'PE')[0]
                    .value || '暂无估值'
            );
            setValuation5(
                res?.data?.filter(valuation => valuation.method === 'PB')[0]
                    .value || '暂无估值'
            );
        });
    }
    // 根据估值方式返回估值
    function getValuationByRatio({ method, rate }, callback) {
        fetchValuationByRatio({
            code: query.creditCode,
            method,
            rate
        }).then(res => {
            callback(res.data);
        });
    }

    return (
        !isIpoCompany && (
            <>
                <Title level={3} text="企业估值">
                    <span dangerouslySetInnerHTML={{ __html: recommend }} />
                </Title>
                <div className={styles.valuationTable}>
                    <table>
                        <thead>
                            <tr>
                                <th>估值方式</th>
                                <th>估值</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>PE</td>
                                <td>
                                    {!PositiveFloat.pattern.test(valuation4)
                                        ? valuation4
                                        : 
                                        (Number(valuation4)).toFixed(2) >= 1e4 ?
                                        `${formatNumber(
                                              (valuation4 / 1e4).toFixed(2)
                                          )}万`
                                          :
                                          `${formatNumber(
                                            Number((valuation4)).toFixed(2)
                                        )}亿`}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PB</td>
                                <td>
                                    {!PositiveFloat.pattern.test(valuation5)?
                                    valuation5:
                                   (Number(valuation5)).toFixed(2) >= 1e4
                                        ? `${formatNumber(
                                            (valuation5 / 1e4).toFixed(2)
                                        )}万`
                                        : `${formatNumber(
                                            Number((valuation5)).toFixed(2)
                                          )}亿`}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PS</td>
                                <td>
                                    {!PositiveFloat.pattern.test(valuation1)?
                                    valuation1:
                                    (Number(valuation1)).toFixed(2) >= 1e4
                                        ? `${formatNumber(
                                              (valuation1 / 1e4).toFixed(2)
                                          )}万`:
                                          `${formatNumber(
                                            Number((valuation1)).toFixed(2)
                                        )}亿`}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span>请输入市销率：</span>
                                    &nbsp;
                                    <InputNumber
                                        value={ratio1}
                                        maxLength={15}
                                        onChange={v => setRatio1(v)}
                                    />
                                    &nbsp;
                                    <Button
                                        type="primary"
                                        style={{ width: 60 }}
                                        onClick={() => {
                                            getValuationByRatio(
                                                {
                                                    method: 'PS',
                                                    rate: ratio1
                                                },
                                                data =>
                                                    setValuation1(
                                                        data || '暂无估值'
                                                    )
                                            );
                                        }}
                                    >
                                        提交
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>PCF</td>
                                <td>
                                    {!PositiveFloat.pattern.test(valuation2)?
                                        valuation2:
                                        (Number(valuation2)).toFixed(2) >= 1e4
                                        ? `${formatNumber(
                                              (valuation2 / 1e4).toFixed(2)
                                          )}万`:
                                          `${formatNumber(
                                            Number((valuation2)).toFixed(2)
                                        )}亿`}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span>请输入市现率：</span>
                                    &nbsp;
                                    <InputNumber
                                        maxLength={15}
                                        value={ratio2}
                                        onChange={v => setRatio2(v)}
                                    />
                                    &nbsp;
                                    <Button
                                        type="primary"
                                        style={{ width: 60 }}
                                        onClick={() => {
                                            getValuationByRatio(
                                                {
                                                    method: 'PCF',
                                                    rate: ratio2
                                                },
                                                data =>
                                                    setValuation2(
                                                        data || '暂无估值'
                                                    )
                                            );
                                        }}
                                    >
                                        {' '}
                                        提交
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>EV/EBITDA</td>
                                <td>
                                    {!PositiveFloat.pattern.test(valuation3)?
                                         valuation3:
                                         (Number(valuation3)).toFixed(2) >= 1e4
                                         ? `${formatNumber(
                                               (valuation3 / 1e4).toFixed(2)
                                           )}万`:
                                           `${formatNumber(
                                            Number((valuation3)).toFixed(2)
                                         )}亿`}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span>请输入企业价值倍率：</span>
                                    &nbsp;
                                    <InputNumber
                                        maxLength={15}
                                        value={ratio3}
                                        onChange={v => setRatio3(v)}
                                    />
                                    &nbsp;
                                    <Button
                                        type="primary"
                                        style={{ width: 60 }}
                                        onClick={() => {
                                            getValuationByRatio(
                                                {
                                                    method: 'EV/EBITDA',
                                                    rate: ratio3
                                                },
                                                data =>
                                                    setValuation3(
                                                        data || '暂无估值'
                                                    )
                                            );
                                        }}
                                    >
                                        提交
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    );
}
