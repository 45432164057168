/**
 * @author Ray
 * @desc 投行与投顾-上市咨询
 */

import React from 'react';
import Model from './model';
import View from './view';

export const Index = props => (
    <Model {...props}>{_props => <View {..._props} />}</Model>
);

export default Index;
