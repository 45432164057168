'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

require('./httpRequest');

var _painter = require('./core/painter');

var _painter2 = _interopRequireDefault(_painter);

var _store = require('./core/store');

var _store2 = _interopRequireDefault(_store);

var _handler = require('./core/handler');

var _handler2 = _interopRequireDefault(_handler);

var _animation = require('./animation');

var _animation2 = _interopRequireDefault(_animation);

var _dom = require('./util/dom');

var _common = require('./util/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function applyPlugins() {
    var _this = this;

    var plugins = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    plugins.forEach(function (plugin) {
        // 为插件注入图谱使用的核心对象
        (0, _assign2.default)(plugin.constructor.prototype, {
            root: _this.dom,
            handler: _this.handler,
            store: _this.store,
            painter: _this.painter
        });
        // 执行插件生命周期
        plugin.beforeApply();
        // 渲染到 DOM
        appendPlugin(plugin, _this);

        plugin.afterApply();
    });
}

function appendPlugin(plugin, higgsCharts) {
    var root = higgsCharts.dom;

    if (!plugin || !root) {
        return;
    }

    var element = plugin.apply(higgsCharts);
    plugin.setDom(element);

    if (element instanceof HTMLElement) {
        var _plugin$options = plugin.options,
            top = _plugin$options.top,
            left = _plugin$options.left,
            right = _plugin$options.right,
            bottom = _plugin$options.bottom,
            width = _plugin$options.width,
            height = _plugin$options.height,
            _plugin$options$zInde = _plugin$options.zIndex,
            zIndex = _plugin$options$zInde === undefined ? 10 : _plugin$options$zInde,
            _plugin$options$displ = _plugin$options.display,
            display = _plugin$options$displ === undefined ? 'block' : _plugin$options$displ;


        var container = (0, _dom.createDiv)(plugin.id + 'Container', (0, _assign2.default)({
            position: 'absolute'
        }, {
            top: top,
            left: left,
            right: right,
            bottom: bottom,
            width: width,
            height: height,
            zIndex: zIndex,
            display: display
        }));

        container.appendChild(element);
        root.appendChild(container);
        plugin.setContainer(container);
    }
}

function removePlugins(plugins) {
    if ((0, _common.isEmpty)(plugins)) {
        return;
    }

    plugins.forEach(function (plugin) {
        var container = plugin.getContainer();

        plugin.dispose();
        if (container) {
            // TODO: 删除绑定的事件
            container.parentNode.removeChild(container);
            container = null;
        }
    });
}

var HiggsCharts = function () {

    /**
     * setting: DEV, control, width, height, plugins
     */
    function HiggsCharts(dom) {
        var setting = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        (0, _classCallCheck3.default)(this, HiggsCharts);

        if ((0, _common.isString)(dom)) {
            this.dom = document.getElementById(dom);
        } else if ((0, _common.isDivElement)(dom)) {
            this.dom = dom;
        } else {
            throw new Error('dom参数异常, 只能接受元素id或元素对象');
        }

        var width = setting.width,
            height = setting.height,
            otherSetting = (0, _objectWithoutProperties3.default)(setting, ['width', 'height']);

        if (width) {
            width = width + '';
            if (width.indexOf('px') === -1) {
                width += 'px';
            }
            this.dom.style.width = width;
        }

        if (height) {
            height = height + '';
            if (height.indexOf('px') === -1) {
                height += 'px';
            }
            this.dom.style.height = height;
        }

        var style = window.getComputedStyle(this.dom);

        this.setting = (0, _extends3.default)({
            DEV: __DEV__,
            control: true,
            width: style.width.slice(0, -2),
            height: style.height.slice(0, -2)
        }, otherSetting);

        this.animator = new _animation2.default();
        this.handler = _handler2.default.init(this.dom);
        this.store = new _store2.default(this.handler, this.setting);
        this.painter = new _painter2.default(this.dom, this.store, this.handler, this.setting);
        this.globalPlugins = this.setting.plugins;

        applyPlugins.call(this, this.globalPlugins);
    }

    (0, _createClass3.default)(HiggsCharts, [{
        key: 'setOption',
        value: function setOption() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            this.handler.trigger('higgscharts.setOption', options);

            if (this.options) {
                // 清除上一个图使用的局部插件
                removePlugins(this.options.plugins);
            }

            this.options = options;
            var _options = this.options,
                chart = _options.chart,
                _options$plugins = _options.plugins,
                plugins = _options$plugins === undefined ? [] : _options$plugins;


            if ((0, _common.isEmpty)(chart) || (0, _common.isEmpty)(chart.data)) {
                throw new Error('data 选项为空, 无法渲染.');
            }

            // 默认效果全部关闭
            chart = (0, _assign2.default)({
                shadow: false,
                draggable: false,
                animation: false
            }, chart);

            this.store.init(chart);
            this.painter.init(chart);
            this.painter.doPaint();

            applyPlugins.call(this, plugins);
        }
    }, {
        key: 'getSetting',
        value: function getSetting() {
            return this.setting;
        }
    }, {
        key: 'getOption',
        value: function getOption() {
            return this.options;
        }
    }, {
        key: 'getDom',
        value: function getDom() {
            return this.dom;
        }
    }, {
        key: 'getStore',
        value: function getStore() {
            return this.store;
        }
    }, {
        key: 'getChartType',
        value: function getChartType() {
            return this.painter.getCurrentChartType();
        }

        /**
         * @param {string} type 生成的图片类型, 默认'image/png', 
         * @param {bgcolor} bgcolor 生成的图片背景色, 默认'#fff',
         * @param {watermark} watermark 生成的水印图片
         * @param {function} callback 生成后的回调方法, 接收一个生成好的图片 url.
         */

    }, {
        key: 'toDataURL',
        value: function toDataURL() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            if (typeof options === 'function') {
                options = { callback: options };
            }
            return this.painter.toDataURL(options);
        }
    }, {
        key: 'toBlob',
        value: function toBlob() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            if (typeof options === 'function') {
                options = { callback: options };
            }
            return this.painter.toBlob(options);
        }
        // 绑定行为

    }, {
        key: 'on',
        value: function on(action, listener) {
            this.handler.on(action, listener);
        }
        // 删除行为绑定

    }, {
        key: 'off',
        value: function off(action, handler) {
            this.handler.off(action, handler);
        }
        // 触发行为

    }, {
        key: 'trigger',
        value: function trigger(action, data) {
            this.handler.trigger(action, data);
        }
    }, {
        key: 'zoomIn',
        value: function zoomIn() {
            this.painter.zoomIn();
        }
    }, {
        key: 'zoomOut',
        value: function zoomOut() {
            this.painter.zoomOut();
        }
    }, {
        key: 'clear',
        value: function clear() {
            if (this.options) {
                removePlugins(this.options.plugins);
            }
            this.store.clear();
            this.painter.clear();
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            // 销毁全局插件
            removePlugins(this.globalPlugins);
            // 销毁局部插件
            removePlugins(this.options.plugins);

            this.store.dispose();
            this.handler.dispose();
            this.painter.dispose();

            this.animator = null;
            this.store = null;
            this.painter = null;
            this.handler = null;
            this.options = null;
            this.setting = null;
            this.globalPlugins = null;
            this.pluginContainer = null;
        }
    }]);
    return HiggsCharts;
}();

function init(dom, opts) {
    return new HiggsCharts(dom, opts);
}

exports.default = {
    init: init
};
module.exports = exports['default'];