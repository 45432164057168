/**
 * @author ray
 * @description 我的历史申请列表，包括收购与转让
 */

import React, { useContext, useEffect, useState } from 'react';
import Table from '../../../common/tableEx';
import { Column } from '../../../common/table';
import { isEmpty, isNotEmpty, limitString } from '../../../utils/utils';
import { APPLY_STATUS, APPLY_TYPES, TimeFormat } from '../../../constants/enum';
import { Form, message, Select, Tooltip } from 'antd';
import styles from './styles.scss';
import {
  PreviewButton,
  EditButton,
  DeleteButton,
  RejectButton,
  SubmitButton
} from '../../../common/buttons';
import classnames from 'classnames';
import { deleteApply, getList, withdrawApply, submitApply } from './service';
import useStorage from '../../../hooks/useStorage';
import moment from 'moment';
import { showModal, closeModal } from '../../investment/script/actions';
import { GlobalContext } from '../../../common/globalStore/createProvider';

/**
 * @desc select
 * @param options
 * @param showSelectAll
 * @param onChange
 * @returns {*}
 * @constructor
 */
export const Selector = React.forwardRef(function(props, ref) {
  const { options, showSelectAll, onChange, ...rest } = props;

  return (
    <div id="selectorBox">
      <Select
        placeholder="请选择"
        getPopupContainer={node => document.getElementById('selectorBox')}
        {...rest}
        onChange={onChange}
        className={classnames(styles.selector, {
          [rest.className]: !!rest.className
        })}
      >
        {showSelectAll && <Select.Option value={null}>全部</Select.Option>}
        {options.map((option, index) => {
          let value, text;
          if (typeof option === 'string') {
            value = text = option;
          } else {
            value = option.value;
            text = option.text || option.label;
          }

          return (
            <Select.Option key={index} value={value}>
              {text}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
});

/**
 * @description 条件删选历史申请
 * @constructor
 */
export function Search({ form }) {
  return (
    <div id="applyHome" style={{ position: 'relative' }}>
      <Form layout="inline" className={styles.form}>
        <Form.Item label="申请类型">
          {form.getFieldDecorator('category', {
            initialValue: null
          })(
            <Selector
              showSelectAll
              options={APPLY_TYPES}
              getPopupContainer={() => document.getElementById('applyHome')}
            />
          )}
        </Form.Item>
        <Form.Item label="申请状态">
          {form.getFieldDecorator('status', {
            initialValue: null
          })(
            <Selector
              showSelectAll
              options={APPLY_STATUS}
              getPopupContainer={() => document.getElementById('applyHome')}
            />
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

export function SearchForm({ onChange }) {
  const [Element, setElement] = useState(null);

  useEffect(function() {
    const SearchForm = Form.create({
      name: 'search1',
      onValuesChange: (a, b, allValues) => onChange(allValues)
    })(Search);

    setElement(<SearchForm />);
  }, []);

  return Element;
}

export default function Apply({ dispatch, history }) {
  const { user } = useContext(GlobalContext);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setQuery({ eid: user?.id });
  }, []);

  function handleChange(allFields) {
    setQuery({
      ...query,
      ...allFields,
      eid: user?.id
    });
  }

  function callback(res) {
    if (isNotEmpty(res) && res.success) {
      message.success(res.message);
    } else {
      message.error(res.message);
    }
    dispatch(closeModal());
    setQuery({
      ...query,
      eid: user?.id
    });
  }

  function handleWithdraw(id) {
    return function() {
      withdrawApply(id).then(callback);
    };
  }

  function handleDelete(id) {
    return function() {
      deleteApply(id).then(callback);
    };
  }

  function handleSubmit(id) {
    return function() {
      submitApply(id).then(callback);
    };
  }

  function gotoPreview(category, id) {
    return function() {
      history.push({
        pathname:
          category === 1
            ? `/merging/acquisition/preview/${id}`
            : `/merging/transfer/preview/${id}`
      });
    };
  }

  function gotoEdit(category, id) {
    return function() {
      history.push({
        pathname:
          category === 1
            ? `/merging/acquisition/${id}`
            : `/merging/transfer/${id}`
      });
    };
  }

  return (
    <div className={styles.applyList}>
      <SearchForm onChange={handleChange} />
      <Table
        lock={!Boolean(query)}
        query={query}
        getData={getList}
        parseData={d => {
          return d ? d.data : [];
        }}
        pagination={{ pageSize: 5, pageNum: 1 }}
      >
        <Column
          title="企业名称"
          align="center"
          dataIndex={'enterpriseName'}
          render={text =>
            isEmpty(text) ? (
              '--'
            ) : (
              <Tooltip title={text}>{limitString(15, text)}</Tooltip>
            )
          }
        />
        <Column
          title="申请时间"
          align="center"
          width="16%"
          dataIndex={'applicationTime'}
          render={text =>
            isEmpty(text)
              ? '--'
              : moment(text, 'x').format(TimeFormat + ' HH:mm:ss')
          }
        />
        <Column
          title="申请类型"
          align="center"
          width="15%"
          dataIndex={'applicationCategory'}
          render={text =>
            APPLY_TYPES.filter(a => a.value === text)[0].text || '--'
          }
        />
        <Column
          title="申请状态"
          align="center"
          width="15%"
          dataIndex={'applicationStatus'}
          render={text =>
            APPLY_STATUS.filter(a => a.value === text)[0].text || '--'
          }
        />
        <Column
          title="不予受理原因"
          align="center"
          width="15%"
          dataIndex={'disapprovalReason'}
          render={text =>
            isEmpty(text) ? (
              '--'
            ) : (
              <Tooltip title={text}>{limitString(15, text)}</Tooltip>
            )
          }
        />
        <Column
          align="center"
          title="操作"
          width="15%"
          render={(text, record) => {
            return (
              <>
                {record.applicationStatus === 2 && (
                  <RejectButton
                    text="撤回"
                    onClick={() =>
                      dispatch(
                        showModal({
                          content: '确认回撤申请？',
                          onOk: handleWithdraw(record.id),
                          onCancel: () => dispatch(closeModal()),
                          onClose: () => dispatch(closeModal())
                        })
                      )
                    }
                  />
                )}
                {record.applicationStatus === 1 && (
                  <>
                    <EditButton
                      onClick={gotoEdit(record.applicationCategory, record.id)}
                    />
                    <SubmitButton
                      onClick={() =>
                        dispatch(
                          showModal({
                            content: '确认提交申请？',
                            onOk: handleSubmit(record.id),
                            onCancel: () => dispatch(closeModal()),
                            onClose: () => dispatch(closeModal())
                          })
                        )
                      }
                    />
                    <DeleteButton
                      onClick={() =>
                        dispatch(
                          showModal({
                            content: '确认删除申请？',
                            onOk: handleDelete(record.id),
                            onCancel: () => dispatch(closeModal()),
                            onClose: () => dispatch(closeModal())
                          })
                        )
                      }
                    />
                  </>
                )}
                {record.applicationStatus !== 1 && (
                  <PreviewButton
                    onClick={gotoPreview(record.applicationCategory, record.id)}
                  />
                )}
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}
