/**
 * @author hjie
 * 我的学习
 */

import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import RiskTitle from 'common/riskTitle';
import red from '../../images/red.png';
import orange from '../../images/orange.png';
import blue from '../../images/blue.png';

export default function({ data, setVisible }) {

    return (
        <div className = {styles.basicInfo}>
            <RiskTitle>市场概况</RiskTitle>
            <div className = {classnames(styles.main, "box")}>
                <div className = {classnames(styles.item, styles.blue)}>
                    <div className = {classnames(styles.icon)}>
                        <img src = {blue} alt = "icon"/>
                    </div>
                    <div className = {classnames(styles.info)}>
                        <span className = {styles.text}>上市公司数量</span>
                        <span className = {styles.num}>
                            <b>{data?.companyTotal || 0}</b> 家
                        </span>
                    </div>
                </div>
                <div className = {classnames(styles.item, styles.orange)}>
                    <div className = {classnames(styles.icon)}>
                        <img src = {orange} alt = "icon"/>
                    </div>
                    <div className = {classnames(styles.info)} onClick = {() => {setVisible(true)}} style = {{cursor: 'pointer'}}>
                        <span className = {styles.text}>风险上市公司数量</span>
                        <span className = {styles.num}>
                            <b>{data?.companyRisk || 0}</b> 家
                        </span>
                    </div>
                </div>
                <div className = {classnames(styles.item, styles.red)}>
                    <div className = {classnames(styles.icon)}>
                        <img src = {red} alt = "icon"/>
                    </div>
                    <div className = {classnames(styles.info)}>
                        <span className = {styles.text}>区域平均风险评分</span>
                        <span className = {styles.num}>
                            <b>{data?.degree || 0}</b> 
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
