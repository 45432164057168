import React, { useContext } from 'react';
import { Avatar, Card, Typography, Tag } from 'antd';
import WithLabel from '../../../common/withLabel';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { Tooltip } from 'antd';
import { IMAGE_HOST } from '../../../constants/enum';
import expertImg from '../expertIndex/image/expert.jpeg';
export default function ExpertCard(props) {
    const { isPub, isDev, isTest } = useContext(GlobalContext);
    const enviroment = [isPub, isDev, isTest];

    const expert = props.detail || {};
    return (
        <Card bordered={false}>
            <Card.Meta
                avatar={
                    <Avatar style={{width:120}} src={(expert.avatar != '--') ? expert.avatar : expertImg} />
                }
                title={
                    <Typography>
                        <Typography.Text>{expert.expertTeam}</Typography.Text>
                        &nbsp;&nbsp;&nbsp;&nbsp;
            <Tag>{expert.expertFirm}</Tag>
                    </Typography>
                }
                description={
                    <div>
                        <Typography>
                            <WithLabel text='任职公司:'>{expert.firmName}</WithLabel>
                        </Typography>
                        <Typography>
                            <WithLabel text='主要服务领域:'>
                                <Tooltip title={expert.zjMainServiceIndustry} overlayStyle={{ maxWidth: 700 }}>
                                    {expert.zjMainServiceIndustry
                                        ? expert.zjMainServiceIndustry.length > 30 ? expert.zjMainServiceIndustry.substring(0, 30) + '...' : expert.zjMainServiceIndustry
                                        : '--'}
                                </Tooltip>
                            </WithLabel>
                            &nbsp;&nbsp;&nbsp;&nbsp;
              <WithLabel text='主要服务板块:'>
                                {expert.zjMainServiceBlock}
                            </WithLabel>
                        </Typography>
                    </div>
                }
            />
        </Card>
    );
}
