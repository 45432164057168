import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { listPassRateArea, listPassByBlock } from './service';
import { Empty } from 'antd';
import Radios from './radio';
/**
 * @desc 
 */
global.ListPassRateArea = null;
export default function ListPassRateArea({ address }) {
    const [type, setType] = useState(1);
    const [data, setData] = useState([]);

    const radiosData = [
      { name: '板块', value: 1 },
      { name: '地区', value: 2 },
    ];

    useEffect(() => {
       //请求地区接口
       type == 1 ? listPassByBlock({ area: address }).then(({ success, data }) => {
          if (success == true) {
              if (global.ListPassRateArea == null) {
                global.ListPassRateArea = new Chart({
                  container: 'ListPassRateArea',
                  autoFit: true,
                  height: 160,
                });
                
                global.ListPassRateArea.data(data);

                global.ListPassRateArea.scale('percent', {
                  nice: true,
                });
                
                global.ListPassRateArea.tooltip(false);
                
                global.ListPassRateArea
                  .area()
                  // .adjust('stack')
                  .position('year*percent')
                  .color('item');
                global.ListPassRateArea
                  .line()
                  // .adjust('stack')
                  .position('year*percent')
                  .color('item');
                global.ListPassRateArea.legend(true);
                global.ListPassRateArea.render();
              } else {
                global.ListPassRateArea.clear();
                global.ListPassRateArea.changeData(data);
                global.ListPassRateArea.scale('percent', {
                  nice: true,
                });
                
                global.ListPassRateArea.tooltip(false);
                
                global.ListPassRateArea
                  .area()
                  // .adjust('stack')
                  .position('year*percent')
                  .color('item');
                global.ListPassRateArea
                  .line()
                  // .adjust('stack')
                  .position('year*percent')
                  .color('item');
                global.ListPassRateArea.legend(true);
                global.ListPassRateArea.render();
              }
          } else {
             <Empty />
          }
       }) : 
      //请求板块接口
      type == 2 ? listPassRateArea({ area: address }).then(({ success, data }) => {
          if (success == true) {
            global.ListPassRateArea.clear();
            global.ListPassRateArea.changeData(data);
            
            global.ListPassRateArea.scale('rate', {
              nice: true,
            });
            
            global.ListPassRateArea.tooltip(false);
            
            global.ListPassRateArea
              .area()
              .adjust('stack')
              .position('year*rate')
              .color('amount');
            global.ListPassRateArea
              .line()
              .adjust('stack')
              .position('year*rate')
              .color('amount');
            global.ListPassRateArea
              .point()
              .position('year*rate')
              .color('amount')
              .shape('circle')
            global.ListPassRateArea.legend(false);
            global.ListPassRateArea.render();
          } else {
            <Empty />
          }
      }) : <Empty />
    }, [address, type])

    return (
       <div style={{ position: 'relative' }}>
          <div className={ styles.leftThirdTitle }>
              过会率情况
          </div>
          <Radios
              value={type}
              data={radiosData}
              defaultValue={1}
              onChange={e => setType(e.target.value)}
          />
          <div id='ListPassRateArea' className={ styles.ListPassRateArea }></div>
       </div>
    )
}