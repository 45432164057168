/**
 * Created by ** on 2017/4/23.
 * Change by ** on 2017/8/01.
 *  关联方
 */
import styles from './styles.scss';
import React, { Component } from 'react';
import classnames from 'classnames';
import { getRelation } from './service';
import { legendList } from './config';
import { Icon, message } from 'antd';
import HiggsChartsMap from './HiggsChartsMap';

export default class RelatedParty extends Component {
  constructor(props) {
    super(props);

    const { companyName = '', companyId = '' } = props.match.params;
    this.chart = null;
    this.state = {
      companyName,
      companyId,
      degreeIndex: 1, // 关联度数
      isMax: false, // 是否全屏展示
      mapWidth: '805px',
      mapheight: '660px',
      filterName: true,
      relationData: null,
      reset: false
    };
  }

  componentDidMount() {
    this.getRationData(1);
  }

  componentWillUnmount() {
    this.chart = null;
    // 避免异步请求调用setState时组件已经被销毁的错误.
    this.setState = () => {};
  }

  getRationData(degree = this.state.degreeIndex) {
    const { companyId } = this.state;
    getRelation({
      bbdQyxxId: companyId,
      degree 
      // date: '2019/09'
    }).then(res => {
      if (res.success) {
        this.setState({
          degreeIndex: degree,
          relationData: res.data
        });
      }else{
        message.error(res.message);
      }
    });
  }

  /**
   * 关联方度数选择
   * @param  {[index]}  [下标值]
   */
  chooseDegree(index) {
    const degreeIndex = index + 1;
    if (degreeIndex === this.state.degreeIndex) {
      return;
    }
    this.getRationData(degreeIndex);
  }
  /**
   * 隐藏关联方文字
   * @param  {[type]} index    [下标值]
   */
  hideText = e => {
    e.preventDefault();
    const { filterName } = this.state;
    this.setState({
      filterName: !filterName,
      reset: false
    });
    this.chart.trigger('circle.setting.setData', {
      statusParam: {
        name: !filterName
      }
    });
  };

  /**
   * 全屏关联方图谱
   * @param  {[type]} index    [下标值]
   */
  changeMapMax = () => {
    let isMax = this.state.isMax;
    let width = '';
    let height = '';
    if (!isMax) {
      width = document.body.clientWidth + 'px';
      height = document.body.clientHeight + 'px';
    } else {
      width = '100%';
      height = '100%';
    }
    this.setState({
      isMax: !isMax,
      mapWidth: width,
      mapheight: height,
      reset: false
    });
  };

  /**
   * 还原关联方图谱
   * @param  {[type]} index    [下标值]
   */
  recoverMap = () => {
    // this.chart.trigger('circle.reRender', {
    //     id: this.state.companyId,
    //     degree: this.state.degreeIndex,
    //     reset: true
    // }); // 框架报错
    this.setState(
      {
        reset: true
      },
      () => {
        this.setState({
          reset: false
        });
      }
    );
  };

  getHandleItem() {
    let item = [
      {
        icon: this.state.isMax ? 'fullscreen-exit' : 'fullscreen',
        click: this.changeMapMax
      },
      {
        icon: 'font-size',
        click: this.hideText
      },
      {
        icon: 'redo',
        click: this.recoverMap
      }
    ];
    return item.map(({ icon, click }, index) => {
      return (
        <a key={index} className='btn' onClick={click}>
          <Icon type={icon} />
        </a>
      );
    });
  }
  // 高亮投资关系、高亮关系
  highLine(key) {
    if (!key) {
      return;
    }
    this.chart.trigger('circle.filter', key);
  }
  render() {
    const {
      degreeIndex,
      isMax,
      mapWidth,
      mapheight,
      relationData,
      companyId,
      reset
    } = this.state;
    let higgsCharts = {
      onReady: chart => {
        this.chart = chart;
      },
      relationData,
      companyId,
      isMax,
      reset
    };
    return (
      <div className={styles['relatedParty']}>
        <div className={styles['relatedMapBox']}>
          <div className={classnames(styles['relatedMap'], 'fl')}>
            {/* 左侧图例 */}
            <div className='legendBox fl'>
              <ul>
                {legendList.map(({ name, icon, img, key }) => {
                  return (
                    <li
                      key={name}
                      onClick={() => this.highLine(key)}
                      className={classnames({ [styles.cursor]: key })}
                    >
                      {img ? (
                        <Icon component={img} />
                      ) : (
                        <img src={`/static/media${icon}.svg`} />
                      )}
                      <span title={key && '点击高亮'}>{name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            {relationData && (
              <div
                className={
                  isMax
                    ? classnames(
                        styles['relatedMapMain'],
                        'fl',
                        styles['maxMap']
                      )
                    : classnames(styles['relatedMapMain'], 'fl')
                }
                id='maxMap'
                style={{ width: mapWidth, height: mapheight }}
              >
                <HiggsChartsMap
                  id='higgsCharts'
                  style={{ width: mapWidth, height: mapheight }}
                  {...higgsCharts}
                />
                <div className={classnames('handleBox', {'fixedHandle': isMax})}>
                  {this.getHandleItem()}
                </div>
              </div>
            )}
          </div>
          <div className={classnames(styles['relatedList'], 'fr')}>
            <div className='menuBox'>
              {['一度', '二度', '三度'].map((ele, index) => {
                let itemName =
                  index === degreeIndex - 1
                    ? classnames('item', 'activeItem')
                    : 'item';
                return (
                  <a
                    key={index}
                    className={itemName}
                    href='javascript:;'
                    onClick={this.chooseDegree.bind(this, index, '')}
                  >
                    {ele}
                  </a>
                );
              })}
            </div>
            <div className={styles['companyList']}>
              <table>
                <tbody>
                  {relationData &&
                    relationData.nodes.map(ele => {
                      const { companyName, bbdQyxxId, personName } = ele;
                      return (
                        <tr key={bbdQyxxId}>
                          <td>{companyName || personName}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
