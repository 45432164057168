/**
 * @author hjie
 * 专栏
 */
import React from 'react';
import ListPage from '../common/listPage/index';

export default function(props) {
    return (
        <ListPage {...props} Type = "专栏"  Text = "专栏"/>
    );
}
