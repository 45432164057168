/**
 * @desc 入库企业分析-基础信息-司法风险分析-立案调查
 * @author 何心奎
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { caseColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { courtAnnounce } from './service';

/**
 *
 * @returns {*}
 */
export default function CourtAnnouncement({ match }) {
    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={caseColumns}
            getData={courtAnnounce}
            parseData={parseData}
            locale={{emptyText: '未发现企业的法院公告记录。不排除存在未公示或时间相对滞后的情况。'}}

        />
    );
}
