/**
 * @author hjie
 * 云课堂/专栏
 */

import React from 'react';
import styles from './styles.scss';
import { Row, Col, Empty, Input, Pagination } from 'antd';
import classnames from 'classnames';

import Card from 'common/card';
import BackBtn from 'common/backBtn';
import hooks from './hook.js';
import { Classification } from 'constants/common';

const { Search } = Input;

export default function(props) {

    const { history, Type, Text } = props;
    const [ labelList, courseList, goEnroll, pageNumber, setPageNumber, classificationAcitve, setClassificationAcitve, classTagAcitve, setClassTagAcitve, setName ] = hooks(Type);
    return (
        <div className={styles.cloudClass}>
            <div className = {styles.filter}>
                <div className = "clearfix">
                    <BackBtn history = {history} url = "/listingTraining/index"/>
                    <div className = {classnames("fr", styles.searchBox)}>
                        <Search placeholder="请输入关键字"  size="large" onSearch={value => { setPageNumber(1); setName(value); }} enterButton />
                    </div>
                </div>
                <section className = {styles.tags}>
                    <div className = {classnames("clearfix", styles.tabs)}>
                        <div className = {styles.label}>{Text}分类：</div>
                        <div className = {styles.tabsNames}>
                            {
                                Object.keys(Classification).map(key => {
                                    const name = Classification[key];
                                    return <span key = {key} className = {classnames({ [styles.active]: key == classificationAcitve })} onClick = { () => { setPageNumber(1); setClassificationAcitve(key); } }>{name}</span>;
                                })
                            }
                        </div>
                    </div>
                    <div className = {classnames("clearfix", styles.tabs)}>
                        <div className = {styles.label}>{Text}标签：</div>
                        <div className = {styles.tabsNames}>
                            {
                                labelList.map(ele => {
                                    const { id, name } = ele;
                                    return <span key = {id} className = {classnames({ [styles.active]: name === classTagAcitve })}  onClick = { () => {setPageNumber(1); setClassTagAcitve(name); }}>{name}</span>;
                                })
                            }
                        </div>
                    </div>
                </section>
            </div> 
            <div className = {styles.content}>
                {
                    courseList.loading ? '' :
                    courseList.total > 0 ?
                        <>
                            <Row gutter={30}>
                                {
                                    courseList.data.map(ele => {
                                        return <Col span={8} key={ele.id}>
                                                    <Card btncallback={(id, isEnroll) => goEnroll(id, isEnroll)} data={ele} type={Type} />
                                                </Col>;
                                    })
                                }
                            </Row>
                            <Pagination showQuickJumper current={pageNumber} total={courseList.total} pageSize = {9} onChange={ (page) => setPageNumber(page) } />
                        </>
                        :
                        <Empty />
                }
            </div>
        </div>
    );
}
