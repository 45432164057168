import React, {PureComponent, useState} from 'react';
import { newIpoCountGroupBroker, theNewIpoList, delistedCompanyList } from './service';
import {Empty} from "antd";
import styles from './style.scss'
import {limitString} from "../../../../utils/utils";


export default class InfoList extends PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount=()=>{
        const { type } = this.props;
        //表格无缝滚动
        this.industryNews = setInterval(this.taskIndustryNews, 50);

        //请求新挂牌数量数据
        type == 1 ? newIpoCountGroupBroker().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) :
        //请求新发行股票数量
        type == 2 ? theNewIpoList().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) :
        //请求转板上市数量数据
        type == 3 ? delistedCompanyList().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) : <Empty />
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.type != nextProps.type) {
            const { type } = nextProps;

            //表格无缝滚动
            //this.industryNews = setInterval(this.taskIndustryNews, 50);

            //请求新挂牌数量数据
            type == 1 ? newIpoCountGroupBroker().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) :
            //请求新发行股票数量
            type == 2 ? theNewIpoList().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) :
            //请求转板上市数量数据
            type == 3 ? delistedCompanyList().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) : <Empty />
        }
    }

    taskIndustryNews = () => {
        //console.log(this.refs.newDiv.scrollTop);
        if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
            this.refs.newDiv.scrollTop = 0;
        }
        else if (this.refs.newDiv) {
            this.refs.newDiv.scrollTop += 1;
        }
    }

    handleIndustryNewsEnter = () => {
        clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render(){
        const {data} = this.state;
        return(
            <div className={styles.leftThirdDiv}>
                <div className={ styles.ceShiTable }>
                    <div className={ styles.ceShiTabletitle }>
                        <span className={ styles.ceShiTabletext2 }>序号</span>
                        <span className={ styles.ceShiTabletext2 }>主办券商</span>
                        <span className={ styles.ceShiTabletext2 }>挂牌公司家数</span>
                    </div>
                    <div
                        ref='newDiv'
                        className={ styles.ceShiTablebody }
                        onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                        onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                    >
                        <ul ref='newDivUI'>
                            {data && data.length > 0
                                ?
                                data.map(this.tableBody)
                                : <span className={styles.noData}>暂无数据</span>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    tableBody = (item, index) => {
        return (
            <li key={index}>
              <span className={ styles.ceShiTabletext2 }>
                  {index + 1}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {item.broker}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                    {item.ipoCount}
              </span>
            </li>
        );
    }
}
