/**
 * @author Ray
 * @desc 年份选择器
 */

import React, { useEffect, useState } from 'react';
import { DatePicker, message } from 'antd';
import moment from 'moment';
import { isEmpty } from '../../utils/utils';

/**
 * @desc ${value} 是否存在于${scope: Array[int, int]}中
 * @param scope
 * @param value
 */
export function inScope(scope, value) {
    return isEmpty(scope) ? true : value <= scope[1] && value >= scope[0];
}

const YearPicker = React.forwardRef(function(
    { value, scope = [], onChange, ...rest },
    ref
) {
    const [_open, setOpen] = useState(false);
    const [_value, setValue] = useState(undefined);

    function handlePanelChange(v) {
        let value = v && v.format('YYYY');
        let flag = inScope(scope, value);
        if (flag) {
            onChange(value);
            setValue(v);
            setOpen(false);
        } else {
            message.error('只能选当年（不含）之前的年份');
        }
    }

    function handleOpenChange(status) {
        setOpen(status);
    }

    useEffect(
        function() {
            if (value) {
                if (typeof value === 'string') {
                    setValue(moment(value, 'YYYY'));
                } else {
                    setValue(value);
                }
            }
        },
        [value]
    );

    return (
        <DatePicker
            {...rest}
            open={_open}
            mode="year"
            format="YYYY"
            value={_value}
            placeholder="请选择年份"
            onChange={handlePanelChange}
            onOpenChange={handleOpenChange}
            onPanelChange={handlePanelChange}
            getCalendarContainer={node => node.parentNode}
        />
    );
});

export default YearPicker;
