import {useState, useEffect} from 'react';
import { getCourse, labels, enrollCourse, getColumn, enrollColumn } from '../../service';
import { StatusCode } from 'constants/enum';
import { CATEGORY } from 'constants/common';
import { Modal } from 'antd';

export default function (type) {

    const isCourse = !!(type === CATEGORY.Course); // 是否是云课堂

    const [courseList, setCourseList] = useState({data: [], total: 0, loading: true });
    const [ labelList, setLabelList ] = useState([{id: 'all', name: '全部'}]);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ name, setName ] = useState('');
    const [ classTagAcitve, setClassTagAcitve ] = useState('全部');
    const [ classificationAcitve, setClassificationAcitve ] = useState('0');

    useEffect(() => {
        getLabels();
    }, [])

    // 获取分签 0=查询全部 1=专栏标签 2=课程标签 3=专家标签
    function getLabels(){
        labels({type: isCourse ? 1 : 2 }).then(res =>{
            const { statusCode, data = [] } = res;
            if( statusCode === StatusCode.success ){
                let list = [...labelList, ...data];
                setLabelList(list);
            }
        })
    }

    // 课程列表
    function getCourseList(){
        const apiFun = isCourse ? getCourse : getColumn;
        const param = isCourse ? 'name' : 'title';
        apiFun({
            pageNum: pageNumber,
            pageSize: 9,
            status: 1, // 1 已上架
            label: classTagAcitve === '全部' ? '' : classTagAcitve, //标签名称
            [param]: name, // 搜索关键字
            plate: +classificationAcitve === 0 ? '' : classificationAcitve, // 课程板块类型
        }).then(res =>{
            const { statusCode, data } = res;
            if( statusCode === StatusCode.success ){
                setCourseList({ data: data.data, total: data.totalCount, loading: false });
            }else{
                setCourseList({total: 0, loading: false});
            }
        })
    }

    /**
     * 报名/取消报名
     * @param {*} id 课程id
     * @param {*} isEnroll  1=报名 2=取消报名
     */
    function goEnroll(id, isEnroll){
        let func = isCourse ? enrollCourse : enrollColumn;
        func({
            id,
            status: isEnroll
        }).then( res => {
            if( res.statusCode === StatusCode.success ){
                getCourseList();
            }else{
                Modal.error({
                    title: '提示',
                    okText: '确定',
                    content: res.message
                });
            }
        })
    }

    useEffect(() => {
        getCourseList();
    }, [classTagAcitve, name, classificationAcitve, pageNumber ])
    return [ labelList, courseList, goEnroll, pageNumber, setPageNumber, classificationAcitve, setClassificationAcitve, classTagAcitve, setClassTagAcitve, setName ];
}