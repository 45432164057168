'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = HttpRequest;

var _store = require('../core/store');

var _store2 = _interopRequireDefault(_store);

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

var _http = require('@easytool/http');

var _http2 = _interopRequireDefault(_http);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_store2.default.registerHttpRequest(HttpRequest);

function HttpRequest(options, isDev) {
    return (0, _http2.default)((0, _extends3.default)({}, _config2.default.datasource.config, options, {
        proxyPath: isDev && _http.helpers.proxy.proxyBaseURL,
        isDev: isDev
    }));
}
module.exports = exports['default'];