import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { moneyFormatter } from '../../utils/utils';
/**
 * @desc 柱状图
 */
export default class Bar extends PureComponent {
    formatter(params) {
        return `<p>
                ${params
                    .map(param => {
                        return (
                            param.data.name &&
                            `<p>
                               ${param.marker +
                                   ' ' +
                                   param.data.name +
                                   ': ' +
                                   param.data.value}
                           </p>`
                        );
                    })
                    .join('')}
            </p>`;
    }

    getOption() {
        const {
            dataset,
            series,
            options,
            xAxisData,
            yAxisFormatter
        } = this.props;
        return {
            grid: {
                left: 180
            },
            dataset,
            series,
            tooltip: {
                show: true,
                axisPointer: { type: 'none' },
                padding: [8, 16],
                formatter: this.props.formatter || this.formatter
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxisData || []
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: yAxisFormatter
                            ? yAxisFormatter
                            : value => moneyFormatter(value) + '万'
                    }
                }
            ],
            ...options
        };
    }
    render() {
        const { style = { height: '310px' }, nodata } = this.props;
        return nodata ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <ReactEcharts
                option={this.getOption()}
                notMerge={true}
                style={style}
                theme={'myTheme'}
            />
        );
    }
}
