/**
 * @author Ray
 * @desc buttons in the bottle
 */

import React from 'react';
import { Button, Row } from 'antd';
import styles from '../styles.scss';

export default function Footer({ save, submit }) {
    return (
        <Row className={styles.foot}>
            <Button onClick={save}>保存</Button>
            <Button onClick={submit} type="primary">
                提交
            </Button>
        </Row>
    );
}
