import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Frame from "../frame";
import Home from "../home";
import User from "../user";
import UserCenter from "../userCenter";
import Policy from "../policy";
import ListingTraining from "../listingTraining";
import CompanyInformation from "../imformation";
import Merging from "../merging";
import Investment from "../investment";
import RiskMonitoring from "../riskMonitoring";
import DriveCabin from "../driveCabin";
import ForeignEnterprises from "../foreignEnterprises";
import { GlobalContext } from "../../common/globalStore/createProvider";
import { getBanner } from "../investment/home/service";
import {
  isEmpty,
  queryStringAll,
  makeRegex,
  getCookie,
} from "../../utils/utils";

export const Routes = [
  // {
  //     path: '/',
  //     name: '首页',
  //     visible: false,
  //     component: Home,
  //     exact: true
  // },
  // {
  //     // 首页
  //     path: '/home',
  //     name: '门户首页',
  //     visible: false,
  //     component: Home,
  //     exact: false
  // },
  {
    // 用户页
    path: "/user",
    name: "登录",
    visible: false,
    component: User,
    exact: false,
    balackHeader: true,
    noContent: true, // 不嵌套frame组件
  },
  {
    // 用户页
    path: "/policy",
    name: "上市奖励",
    permissionId: 498,
    visible: true,
    component: Policy,
    exact: false,
  },
  {
    name: "兼并重组",
    permissionId: 189,
    path: "/merging",
    visible: true,
    component: Merging,
    exact: false,
    banner: {
      api: getBanner,
      query: 2,
      parser: (res) => res?.data || [],
    },
  },
  {
    // 用户页
    path: "/listingTraining",
    name: "资本学院",
    permissionId: 43,
    visible: true,
    component: ListingTraining,
    exact: false,
    noContent: true,
    redirect: "/listingTraining/index",
    children: [
      {
        path: "/listingTraining/course",
        name: "云课堂",
        permissionId: 491,
      },
      {
        path: "/listingTraining/column",
        name: "专栏",
        permissionId: 492,
      },
      {
        path: "/listingTraining/coordination",
        name: "政企协调",
        permissionId: 493,
      },
      {
        path: "/listingTraining/learning",
        name: "我的学习",
        permissionId: 0,
      },
    ],
  },
  {
    path: "/riskMonitoring",
    name: "风险监测",
    permissionId: 418,
    visible: true,
    component: RiskMonitoring,
    exact: false,
  },
  {
    name: "精品投行",
    permissionId: 179,
    path: "/investment",
    visible: true,
    component: Investment,
    exact: false,
    banner: {
      api: getBanner,
      query: 1,
      parser: (res) => res?.data || [],
    },
    children: [
      {
        path: "/investment/enterpriseAnalysis",
        name: "入库企业分析",
        permissionId: 497,
      },
      {
        path: "/investment/policyReport",
        name: "行研政策报告",
        permissionId: 494,
      },
      {
        path: "/investment/intermediaryAnalysis",
        name: "中介机构分析",
        permissionId: 496,
      },
      {
        path: "/investment/markSuggest",
        name: "上市咨询",
        permissionId: 495,
      },
    ],
  },
  // {
  //   name: "外资企业",
  //   path: "/foreignEnterprises",
  //   visible: true,
  //   component: ForeignEnterprises,
  //   exact: false,
  // },
  {
    // 用户中心
    path: "/userCenter",
    name: "用户中心",
    visible: false,
    component: UserCenter,
    exact: false,
  },
  {
    //    企业信息填报
    path: "/companyInformation/",
    name: "企业信息填报",
    permissionId: 44,
    visible: true,
    component: CompanyInformation,
    exact: false,
  },
  {
    //    驾驶舱
    path: "/sciAndTech",
    name: "全景分析",
    permissionId: 488,
    visible: true,
    component: DriveCabin,
    exact: false,
    isolate: true,
    target: "_blank",
    children: [
      {
        //    科创钻石评分
        path: "/sciAndTech/index",
        name: "科创指数全景分析",
        permissionId: 489,
        target: "_blank",
      },
      {
        path: "/sciAndTech/diamond",
        name: "钻石指数全景分析",
        permissionId: 490,
        target: "_blank",
      },
      {
        path: "/sciAndTech/cockpit/domesticStock",
        name: "境内上市全景分析",
        permissionId: 502,
        target: "_blank",
      },
      {
        path: "/sciAndTech/cockpit/listedCompanyRisk",
        name: "境内上市公司风险全景分析",
        permissionId: 501,
        target: "_blank",
      },
      // {
      //     path: '/sciAndTech/cockpit/newotcmarket',
      //     name: '新三板全景分析（旧版）',
      //     target: '_blank'
      // },
      {
        path: "/sciAndTech/cockpit/neeqStock",
        name: "新三板全景分析",
        permissionId: 503,
        target: "_blank",
      },
    ],
  },
  {
    //    驾驶舱
    path: "/list",
    name: "上证直通车",
    permissionId: 488,
    visible: true,
    component: "",
    exact: false,
    isolate: true,
    target: "",
  },
];

export const LOGIN_GROUP = [
  "/user/login",
  "/user/register",
  "/user/forgetPsw",
  "/user/protocol",
  "/user/complaintAccount/true",
  "/user/complaintAccount/false",
];
export const QIYUAN_NOT_ALLOWED = [
  "/companyInformation/",
  "/companyInformation",
];
export const DONT_NEED_LOGIN = [
  "/listingTraining/coordinationDetail/.+",
  "/sciAndTech/index",
  "/sciAndTech/diamond",
  "/sciAndTech/cockpit/domesticStock",
  "/sciAndTech/cockpit/listedCompanyRisk",
  "/sciAndTech/cockpit/neeqStock",
  "/investment/policyReport",
];
export const LOGIN = "/user/login";
export const HOME = "/policy";
export const MODIFYPASSWORD = "/userCenter/modifyPassword";

export function PrivateRoute({ routeProps, route, routes }) {
  // console.log("hello?", routeProps.location.pathname)
  const { user } = useContext(GlobalContext);

  const tt = false;
  // 用户已登录，但是路由指向登录页，则重定向的优先级（location > routeProps.location.from > HOME）
  if (user) {
    console.log(process.env.NODE_ENV, 5);
    let env = "";
    const ssoToken = getCookie("ssoToken");
    console.log(typeof ssoToken);
    if (routeProps.location.pathname === "/list") {
      if (ssoToken) {
        if (process.env.NODE_ENV === "development") {
          window.open(
            `http://localhost:8000/product/ipo/model/ssein/business?${ssoToken}`,
            "_blank"
          );
        } else if (process.env.NODE_ENV === "production") {
          window.open(
            `http://qyfwnew.beijingipo.com.cn/product/ipo/model/ssein/business?${ssoToken}`,
            "_blank"
          );
        }
        setTimeout(() => {
          history.go(-1);
          setTimeout(() => {
            window.location.reload();
          }, 5);
        }, 1);
      }
    }

    if (
      routeProps.location.pathname === "/" ||
      routeProps.location.pathname === LOGIN
    ) {
      if (user.needChangePwd) {
        return <Redirect to={{ pathname: MODIFYPASSWORD }} />;
      }
      return <Redirect to={{ pathname: HOME }} />;
    }
    if (
      (routeProps.location.pathname.includes("riskMonitoring/detail/risk/") ||
        routeProps.location.pathname.includes(
          "investment/enterpriseAnalysis/"
        )) &&
      !isEmpty(queryStringAll())
    ) {
      return <Redirect to={{ pathname: routeProps.location.pathname }} />;
    }
  }

  return <Frame {...routeProps} route={route} routes={routes} />;
}

function filterPermission(arr, permissions) {
  return arr
    .map((item) => {
      if (isEmpty(item.permissionId)) return item;
      if (!permissions.includes(item.permissionId)) {
        return null;
      } else {
        let _item = Object.assign({}, item);
        if (_item.children) {
          _item.children = filterPermission([..._item.children], permissions);
          _item.redirect = _item.redirect || _item.children[0]?.path;
        }
        return _item;
      }
    })
    .filter(Boolean);
}

export default function() {
  const { user } = useContext(GlobalContext);
  const [_Routes, setRoutes] = useState([...Routes]);

  useEffect(() => {
    if (!user) return;
    let permissionIds = user.needChangePwd
      ? []
      : Array.from(new Set(user?.resources?.map((re) => re.id)));
    let afterFilterRoutes = filterPermission([...Routes], permissionIds);
    setRoutes(afterFilterRoutes);
  }, [user]);

  return (
    <Switch>
      {_Routes.map((o, index) => {
        return (
          <Route
            exact={o.exact}
            path={o.path}
            key={o.path || index}
            render={(prop) => (
              <PrivateRoute
                key={o}
                route={o}
                routes={_Routes}
                routeProps={prop}
              />
            )}
          />
        );
      })}
      <Redirect from="*" to={user?.needChangePwd ? MODIFYPASSWORD : HOME} />
    </Switch>
  );
}
