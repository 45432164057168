/**
 * @desc 大屏地图
 * @returns {*}
 * @constructor
 */
import React, {useEffect, useRef, useState} from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { riskAverage } from './service';
import {isNotEmpty} from '../../../../utils/utils';
import getLocalMap from '../service';

export default function BigScreenMap({style, address}) {
    const mapSize = {
        all: {
            zoom: window.screen.width < 1920 ? 2.0 : 1.0,
            top: window.screen.width < 1920 ? '80' : '20',
            bottom: window.screen.width < 1920 ? '0' : '20',
        },
        province: {
            zoom: 1.0,
            top: '20',
            bottom: '20',
        }
    };
    const echartsInstance = useRef(null);
    const [addressData, setAddressData] = useState([]);
    const [map, setMap] = useState();
    const [mapsize, setMapSize] = useState('all');
    // 地址变化获取新的地图数据
    useEffect(() => {
        // 请求申请数据，请求地图数据。
        setAddressData([]);

        getLocalMap(address).then(res => {
            echarts.registerMap(address, res);

            if (address=='all') {
                setMapSize('all')
            } else {
                setMapSize('province')
            }

            riskAverage({area: address})
                .then(res => {
                    if (isNotEmpty(res) && isNotEmpty(res.data.areaAmount)) {
                        let data = res.data.areaAmount.map(item => {
                            const {area, riskAmount} = item
                            return {
                                name: area,
                                value: riskAmount
                            };
                        });

                        setTimeout(() => {
                            setAddressData(data);
                        }, 0);
                    }
                })
                .finally(() => setMap(address));
        })
    }, [address])

    
    function getOptions(address) {
        return {
            title: {
                text: '风险平均分分布',
                left: 'left',
                top: window.screen.width < 1920 ? 10 : 80,
                left: window.screen.width < 1920 ? 20 : 10,
                textStyle: window.screen.width < 1920 ? {
                    color: '#fff',
                    fontWeight: 'bolder',
                    fontSize: '8px'
                } : {
                    color: '#fff',
                }
            },
            geo: [
                //展示地图
                {
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    z: 2,
                    zoom: mapSize[mapsize].zoom,
                    top:mapSize[mapsize].top,
                    bottom:mapSize[mapsize].bottom,
                    layoutSize: 400,
                    label: {
                        normal: {
                            show: address == 'all' ? true : false,
                            color: 'rgb(249, 249, 249)'
                        }
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: '#1AF2FF'
                        }
                    },
                },
            ],
            visualMap: {
                min: 0,
                max: 1000,
                left: window.screen.width < 1920 ? 20 : 40,
                bottom: window.screen.width < 1920 ? 20 : 40,
                showLabel: !0,
                text: ["高", "低"],
                textStyle: {
                    color: '#fff',
                },
                pieces: [{
                    gt: 60,
                    label: "> 60 分",
                    color: "#E86452"
                }, {
                    gte: 50,
                    lte: 60,
                    label: "50 - 60 分",
                    color: "#F6BD16"
                }, {
                    gte: 40,
                    lte: 50,
                    label: "40 - 50 分",
                    color: "#006DA2"
                }, {
                    gte: 30,
                    lte: 40,
                    label: "30 - 40 分",
                    color: "#1A8DF8"
                }, {
                    lt: 30,
                    label: "< 30分",
                    color: "#38c060"
                }],
                show: !0
            },
            series: [
                // 地图
                {
                    type: 'map',
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    geoIndex: 0,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    data: addressData,
                    showLegendSymbol: false
                },
            ]
        }
    }

    return (
        <div style={{height: '62vh', paddingTop: '150px'}}>
            <ReactEcharts
                notMerge
                theme={'myTheme'}
                ref={echartsInstance}
                option={getOptions(address)}
                style={{height: '100%'}}
            />
        </div>
    )
}

BigScreenMap.defaultProps = {
    style: {},
    address: 'all'
};
