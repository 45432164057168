/* eslint-disable react/no-multi-comp */
/**
 * @author Ray
 */
import React, { useEffect, useState } from 'react';
import columns from './columns';
import Table, { Column } from '../../../../common/table';
import styles from './styles.scss';
import { random, replace } from '../../../../utils/utils';
import Input from '../../../../common/input';
import { DeleteRowButton, AddRowButton } from '../../../../common/buttons';
import Text from '../../../../common/text';
import { InputNumber } from 'antd';

export function EditableRow(props) {
    return <tr {...props} />;
}

export class EditableCell extends React.PureComponent {
    handleChange = v => {
        const { record, dataIndex, onChange } = this.props;

        // let v = e.target.value;

        onChange(
            Object.assign(record, {
                [dataIndex]: v
            })
        );
    };

    render() {
        const {
            record,
            dataIndex,
            editable,
            index,
            type,
            children,
            isPreview
        } = this.props;

        return (
            <td style={{ textAlign: 'center' }}>
                {editable ? (
                    isPreview ? (
                        <Text value={record[dataIndex]} />
                    ) : type === 'number' ? (
                        <InputNumber
                            min={0}
                            max={100}
                            precision={2}
                            value={record[dataIndex]}
                            onChange={this.handleChange}
                            key={`${dataIndex}-${index}`}
                            className={styles.editableInput}
                        />
                    ) : (
                        <Input
                            type={type}
                            maxLength={50}
                            disabled={isPreview}
                            value={record[dataIndex]}
                            onChange={this.handleChange}
                            key={`${dataIndex}-${index}`}
                            className={styles.editableInput}
                        />
                    )
                ) : (
                    children
                )}
            </td>
        );
    }
}

export default function({ dataSource, onChange }) {
    const [data, setData] = useState([]);

    useEffect(
        function() {
            setData(dataSource);
        },
        [dataSource]
    );

    function addItem() {
        let _data = data ? data.slice(0) : [];

        _data.push({
            index: random(5),
            serviceCompany: '',
            companyIndustry: '',
            projectPosition: '',
            organization: '',
            csrcStatus: '',
            plate: ''
        });

        setData(_data);
        onChange('sysExpertProExperiences', _data);
    }

    function handleFieldChange(item) {
        let _data = replace(data, item, t => t.index === item.index);
        setData(_data);
        onChange('sysExpertProExperiences', _data);
    }

    function handleDelete(index) {
        return function() {
            let _data = data.filter(t => t.index !== index);
            setData(_data);
            onChange('sysExpertProExperiences', _data);
        };
    }

    return (
        <div className={styles.root}>
            <Table
                components={{ body: { row: EditableRow, cell: EditableCell } }}
                dataSource={data}
                pagination={false}
            >
                {columns.map(function(colProps) {
                    return (
                        <Column
                            {...colProps}
                            onCell={(record, index) => ({
                                index,
                                record,
                                editable: true,
                                onChange: handleFieldChange,
                                dataIndex: colProps.dataIndex
                            })}
                        />
                    );
                })}
                {data.length > 0 && (
                    <Column
                        title="操作"
                        width="20%"
                        align="center"
                        onCell={() => ({ editable: false })}
                        render={(text, record) => {
                            return (
                                <DeleteRowButton
                                    onClick={handleDelete(record.index)}
                                />
                            );
                        }}
                    />
                )}
            </Table>
            <div className="center marginTop forceMarginBottom">
                <AddRowButton onClick={addItem} />
            </div>
        </div>
    );
}
