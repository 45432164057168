/**
 * @author Ray
 * @description 注销账户
 */
import React from 'react';
import Form from './form';
import styles from '../modifyMobilenumber/styles.scss';
import Title from '../../../common/title';
import { Divider } from 'antd';

export default function(props) {
    function handleSubmit(values) {
        return props.resignUser(values);
    }

    return (
        <section className={styles.messageContentRoot}>
            <Title classes={{ root: styles.titleFont }} text={'注销账号'} />
            <Divider />
            <Form onSubmit={handleSubmit} />
        </section>
    );
}
