/**
 * @desc hooks integration with form & modal
 * @author Ray
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import { showModal, closeModal } from '../../script/actions';
import { isNotEmpty } from '../../../../utils/utils';
import moment from 'moment';

export default function actionHook({
    dispatch, // dispatch方法
    update, // 更新
    remove, // 移除
    create, // 新建
    form, // 表单组件
    query = {}, // 请求所带的参数
    getData = () => Promise.resolve([]),
    callback = () => {} // 在完成一些操作之后，提供操作的回调
}) {
    const [initialData, setInitialData] = useState({});
    const element = useRef(null);
    const ref = useRef(null);
    const [_query, setQuery] = useState(query);
    // 生成表单组件
    useEffect(() => {
        const FormElement = Form.create({
            name: 'hook-form'
        })(form);
        element.current = <FormElement wrappedComponentRef={ref} />;
    }, []);

    useEffect(() => {
        getData &&
            getData({ ...query }).then(res => {
                callback();
                if (isNotEmpty(res) && isNotEmpty(res.data)) {
                    setInitialData(res.data);
                }
            });
    }, [_query]);

    const getTime = () => new Date().getTime();

    // 验证表单的正确性
    const validate = cb => {
        ref.current.form.validateFields((error, values) => {
            if (!error) {
                cb(values);
            }
        });
    };

    // 添加操作
    const add = () => {
        return validate(values => {
            create({ ...values, ...query }).then(res => {
                if (isNotEmpty(res) && res.success) {
                    setQuery({ ...query, signal: getTime() });
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                dispatch(closeModal());
            });
        });
    };

    // 删除操作
    const del = id => {
        remove({ id }).then(res => {
            if (isNotEmpty(res) && res.success) {
                setQuery({ ...query, signal: getTime() });
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            dispatch(closeModal());
        });
    };

    // 编辑操作
    const edit = id => {
        return validate(values => {
            update({ ...values, id, ...query }).then(res => {
                if (isNotEmpty(res) && res.success) {
                    setQuery({ ...query, signal: getTime() });
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                dispatch(closeModal());
            });
        });
    };
    // 用户dom事件触发，传入模态框的title
    const addRecord = title => () =>
        dispatch(
            showModal({
                title: title,
                onOk: add,
                content: element.current
            })
        );
    // 用户dom事件触发，传入record和模态框的title
    const editRecord = record => title => () => {
        dispatch(
            showModal({
                title: title,
                onOk: () => edit(record.id),
                content: element.current
            })
        );

        let _record = Object.assign(
            {},
            {
                ...record
            }
        );

        setTimeout(() => {
            // 删除表单不需要的数据
            delete _record.gmtCreate;
            delete _record.gmtUpdate;
            delete _record.id;
            // 转换表单不能处理的数据
            if (_record.activityTime) {
                _record.activityTime = moment(_record.activityTime, 'x');
            } else if (_record.donationTime) {
                _record.donationTime = moment(_record.donationTime, 'x');
            }
            ref.current && ref.current.form.setFieldsValue(_record);
        }, 0);
    };

    // 用户dom事件触发，传入record和模态框的title
    const removeRecord = record => title => () => {
        dispatch(
            showModal({
                title: title,
                onOk: () => del(record.id),
                content: '确认删除该条记录？'
            })
        );
    };

    return [addRecord, editRecord, removeRecord, initialData, _query];
}
