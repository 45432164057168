/**
 * @author hjie
 * 我的学习
 */
import { withRouter, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import moment from 'moment';
import RiskTitle from 'common/riskTitle';
import { Table } from 'antd';
import { getNotes } from '../../detail/service';

function getColumns(pageNum, pageSize, props) {
    return [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            render: (text, item, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            },
            width: '5%'
        },
        {
            title: ' 企业名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '30%',
            render: (text,record) => <Link  to = {`./detail/risk/${record.companyName}/${record.qyxxId}`}>{text}</Link>
        },
        {
            title: '处置信息',
            dataIndex: 'content',
            key: 'content',
            width: '35%'
        },
        {
            title: '处置人',
            dataIndex: 'delBy',
            key: 'delBy',
            width: '15%'
        },
        {
            title: '处置时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '15%',
            render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
        }
    ];
}
export default withRouter(function (props) {

    const [dataSource, setDataSource] = useState({ data: [], total: 0 });
    const [pageInfo, setPageInfo] = useState({ pageNum: 1, pageSize: 10 });

    const { pageSize, pageNum } = pageInfo;
    const columns = getColumns(pageNum, pageSize, props);

    function getList() {
        getNotes({
            ...pageInfo
        }).then((res) => {
            if (res && res.success) {
                const { data, totalCount } = res.data;
                setDataSource({ data, total: totalCount });
            }
        })
    }
    function onChange(pagination) {
        setPageInfo({ pageNum: pagination.current, pageSize: pagination.pageSize })
    }

    useEffect(() => {
        getList();
    }, [pageInfo.pageNum, pageInfo.pageSize])

    return (
        <div className={styles.riskSort}>
            <RiskTitle>处置记录</RiskTitle>
            <div className="box">
                <Table
                    columns={columns}
                    dataSource={dataSource.data}
                    onChange={onChange}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total: dataSource.total,
                        showTotal: total => `共 ${Math.ceil(dataSource.total / pageSize)} 页/${total}条数据`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        size: "small"
                    }}
                />
            </div>
        </div>
    );
})
