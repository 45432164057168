import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';
import { listedOverviewDelisted } from './service';


export default function Line3List() {
    const [data, setData] = useState([]);

    useEffect(() => {
        listedOverviewDelisted().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        })
    }, [])
    

    return (
        <div className={ styles.line3List }>
            <table>
                <thead>
                    <tr>
                        <td>证券数量 (只)</td>
                        <td>总股本 (亿股)</td>
                        <td>流通股本 (亿股)</td>
                        <td>成交股数 (万股)</td>
                        <td>成交金额 (万元)</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="20%" style={{ borderRadius: '50px 0 0 50px' }}>{data.sharesCount}</td>
                        <td width="25%">{data.totalEquity}</td>
                        <td width="25%">{data.flowEquity}</td>
                        <td width="10%">{data.dealEquity}</td>
                        <td width="20%" style={{ borderRadius: '0 50px 50px 0' }}>{data.dealMoney}</td>
                    </tr>
                </tbody>
            </table>
            {/* <div className={ styles.loadMore }>
                点击查看更多
            </div> */}
        </div>
    )
}