/**
 * @author Ray
 * @desc 入库企业分析-上市能力分析-上市板块推荐
 */

import React, { useState } from 'react';
import { RecommendColumns } from './columns';
import Table from '../../../../../common/tableEx';
import { recommendPlates } from './service';
import { isNotEmpty } from '../../../../../utils/utils';
import Title from '../../../../../common/title';
import { Typography } from 'antd';
export default function Recommendation({ match }) {
    const [plate, setPlate] = useState('');
    const [query] = useState({
        creditCode: match.params.companyCode,
        qyxxId: match.params.companyId
    });
    function parseData(res) {
        if (isNotEmpty(res)) {
            setPlate(res.plate);
            return (
                res?.reasonList?.map(reason => {
                    return { reason };
                }) || []
            );
        }
        return [];
    }
    return (
        <div>
            <Title level={3} text="上市板块推荐">
                {plate && (
                    <Typography.Text code>{plate.join(', ')}</Typography.Text>
                )}
            </Title>
            <Table
                query={query}
                parseData={parseData}
                getData={recommendPlates}
                columns={RecommendColumns}
            />
        </div>
    );
}
