/**
 * @author Ray
 */
import React from 'react';
import RowEx from './row';
import Proptypes from 'prop-types';
import styles from './body.scss';
import { isEmpty, moneyFormatter } from '../../../utils/utils';

Body.defaultProps = {
    dataSource: [],
    order: []
};

Body.proptypes = {
    dataSource: Proptypes.array,
    order: Proptypes.array
};

export default function Body({ dataSource, order, onInit, year, type, readOnly }) {
    // 整理数据，按照顺序排列成数组

    let data = dataSource.map(v => {
        let orderValueArray = [];

        order.forEach((o, i) => {
            orderValueArray.push(
                i > 0
                    ? v[o] === '#' || v[o] === '--' || isEmpty(v[o])
                        ? '--'
                        : moneyFormatter(v[o]) +
                          ([
                              '（一）基本每股收益',
                              '（二）稀释每股收益'
                          ].includes(v.item)
                              ? '元'
                              : '万')
                    : v[o]
            );
        });

        return orderValueArray;
    });

    return (
        <div className={styles.flexibleBody}>
            <RowEx data={dataSource} onInit={onInit} year={year} type={type} readOnly={readOnly} />
            {/* {data.map((d, i) => {
                return <RowEx key={i} data={d} onInit={onInit} dataSource={dataSource} />;
            })} */}
        </div>
    );
}
