/**
 * @desc 投行与投顾-上市咨询-财务数据填报
 * @auth Ray
 */

import React, { useContext, useEffect, useState } from 'react';
import Require from './require';
import Material from './material';
import styles from './styles.scss';
import BackBtn from '../../../common/backBtn';
import Title from '../../../common/title';
import { Divider, message } from 'antd';
import Steps from './steps';
import { renderRoutes } from 'react-router-config';
import { callApplyDetail, submitApply, updateApply } from './service';
import Detail from './detail';
import {
    checkFinanceData,
    checkOperationData,
    isNotEmpty,
    checkOtherData
} from '../../../utils/utils';
import Company from './company';
import * as Actions from '../script/actions';
import useStorage from '../../../hooks/useStorage';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { Permission } from '../../../hooks/useAuth';
import { showModal } from '../script/actions';

const Routes = [
    {
        exact: true,
        path: '/investment/consultant/require',
        component: Require
    },
    {
        exact: true,
        path: '/investment/consultant/require/:id',
        component: Require
    },
    {
        exact: true,
        path: '/investment/consultant/material',
        component: Material
    },
    {
        exact: true,
        path: '/investment/consultant/material/:id',
        component: Material
    },
    {
        exact: true,
        path: '/investment/consultant/detail/:id',
        component: Detail
    }
];

export default function({ history, dispatch, setBannerShow }) {
    const [isPreview, setPreviewStatus] = useState(false);
    const [step, setStep] = useState(0);
    const [demand, setDemand] = useState({});
    const [attachment, setAttachment] = useState({});
    const [main, setMain] = useState({});
    const [customer, setCustomer] = useState({});
    const [vendor, setVendor] = useState({});
    const [other, setOther] = useState({});
    // const [user] = useStorage('UserInfo');
    const { user } = useContext(GlobalContext);
    const [needUpdate, setNeedUpdate] = useState(0);

    function submit(type, id) {
        let func = null;
        let _attachment = Object.assign({}, attachment);
        const isSubmit = type === 2;
        const isUpdate = isNotEmpty(id);

        if (isUpdate) {
            func = updateApply;
            if (isNotEmpty(attachment)) {
                for (let i in attachment) {
                    if (attachment.hasOwnProperty(i)) {
                        if (Array.isArray(attachment[i])) {
                            _attachment[i] = attachment[i].map(function(_i) {
                                return _i.id ? _i.id : _i;
                            });
                        } else {
                            _attachment[i] = attachment[i]?.id || attachment[i];
                        }
                    }
                }
            }
        } else {
            func = submitApply;
        }

        isSubmit
            ? Promise.all([
                  checkOperationData(1, user?.creditCode),
                  checkOperationData(2, user?.creditCode),
                  checkOperationData(3, user?.creditCode),
                  checkOtherData(user?.creditCode)
              ]).then(([o1, o2, o3, o4]) => {
                  if (
                      isNotEmpty(o1) &&
                      isNotEmpty(o2) &&
                      isNotEmpty(o3) &&
                      isNotEmpty(o4) &&
                      isNotEmpty(vendor) &&
                      isNotEmpty(customer) &&
                      isNotEmpty(other) &&
                      isNotEmpty(main)
                  ) {
                      function commit() {
                          dispatch(Actions.closeModal());
                          func(
                              {
                                  business: main,
                                  customer: customer,
                                  other: other,
                                  supplier: vendor,
                                  attachment: _attachment,
                                  demand,
                                  plate: demand.plate,
                                  status: type,
                                  needUpdate,
                                  type: 2
                              },
                              id
                          ).then(function(res) {
                              if (isNotEmpty(res) && res.success) {
                                  message.success(res.message);
                                  dispatch(Actions.closeModal());
                                  history.push({
                                      pathname: '/investment/markSuggest'
                                  });
                              } else {
                                  message.error(res.message);
                              }
                          });
                      }

                      dispatch(
                          showModal({
                              title: '提示',
                              content: '确认提交申请？',
                              onOk: () => commit(),
                              onCancel: () => dispatch(Actions.closeModal()),
                              onClose: () => dispatch(Actions.closeModal())
                          })
                      );
                  } else {
                      dispatch(
                          Actions.showModal({
                              title: '提示',
                              content: '请完善填报信息',
                              okText: '确定',
                              onOk: () => {
                                  dispatch(Actions.closeModal());
                              },
                              onCancel: () => {
                                  dispatch(Actions.closeModal());
                              },
                              onClose: () => {
                                  dispatch(Actions.closeModal());
                              }
                          })
                      );
                  }
              })
            : func(
                  {
                      business: main,
                      customer: customer,
                      other: other,
                      supplier: vendor,
                      attachment: _attachment,
                      demand,
                      plate: demand.plate,
                      status: type,
                      needUpdate,
                      type: 2
                  },
                  id
              ).then(function(res) {
                  if (isNotEmpty(res)) {
                      if (res.success) {
                          message.success(res.message);
                          history.push({
                              pathname: '/investment/markSuggest'
                          });
                          dispatch(Actions.closeModal());
                      } else if (res.statusCode === 3001) {
                          dispatch(
                              Actions.showModal({
                                  title: '提示',
                                  content: '请完善填报信息',
                                  okText: '确定',
                                  onOk: () => {
                                      dispatch(Actions.closeModal());
                                  },
                                  onCancel: () => {
                                      dispatch(Actions.closeModal());
                                  },
                                  onClose: () => {
                                      dispatch(Actions.closeModal());
                                  }
                              })
                          );
                      } else {
                          message.error(res.message);
                      }
                  }
              });
    }

    return (
        <Permission permission={495}>
            <div className={styles.root}>
                <BackBtn
                    history={history}
                    callback={() => setStep(0)}
                    url={step === 0 ? '/investment/markSuggest' : undefined}
                />
                <br />
                <br />
                {!isPreview && (
                    <>
                        <Title level={1} text="上市咨询" />
                        <Divider />
                    </>
                )}
                {isPreview ? <Company /> : <Steps step={step} />}
                <br />
                {renderRoutes(Routes, {
                    setStep,
                    demand,
                    setDemand,
                    attachment,
                    setAttachment,
                    submit,
                    setPreviewStatus,
                    setMain,
                    setCustomer,
                    setOther,
                    setVendor,
                    setNeedUpdate,
                    setBannerShow
                })}
            </div>
        </Permission>
    );
}
