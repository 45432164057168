import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc 过会率情况
*/

//请求地区数据
export function listPassRateArea(params) {
    return request(`${API.V1}/cockpit/domesticStock/leftThird/listPassRate/area`, params);
}

//请求板块数据
export function listPassByBlock(params) {
    return request(`${API.V1}/cockpit/domesticStock/leftThird/listPassRate/block`, params);
}
