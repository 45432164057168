import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { moneyFormatter } from '../../utils/utils';

export default function Donut({ nodata, style, data, title, formatter }) {
    function getOptions(data) {
        return {
            // legend: {
            //     show: true,
            //     bottom: 0,
            //     icon: 'circle',
            //     orient: 'horizontal'
            // },
            tooltip: {
                show: true,
                trigger: 'item',
                borderColor: 'rgba(33,86,143,0.14)',
                padding: [10, 20],
                formatter: formatter || '{b}: {c}({d}%)'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    data: data,
                    label: {
                        formatter: params => {
                            const { name, value, percent } = params;
                            return (
                                name +
                                ': ' +
                                (value ? moneyFormatter(value) + '万' : value) +
                                '(' +
                                percent +
                                '%)'
                            );
                        }
                    }
                }
            ],
            title
        };
    }

    return nodata ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    ) : (
        <ReactEcharts
            notMerge
            option={getOptions(data)}
            style={{
                height: 310,
                ...style
            }}
            theme={'myTheme'}
        />
    );

}