/**
 * @author Ray
 * @desc 基本申请材料
 */
import React, { useEffect, useContext } from 'react';
import { Form, InputNumber } from 'antd';
import ImageUpload from '../../../../common/uploadImage';
import styles from './styles.scss';
import { API } from '../../../../constants/enum';
import ErrorTip from '../../../../common/errorTip';
import { GlobalContext } from "../../../../common/globalStore/createProvider";
import { TemplateButton } from '../../../../common/buttons';
import { download } from '../../../../utils/fetch';

function BasicMaterialForm({ form, onInit, data }) {
    const gs = useContext(GlobalContext);
    const prefix = gs.isPub
        ? 'http://file.beijingipo.com.cn/'
        : 'http://192.168.88.106/'
    
    useEffect(function() {
        onInit('basicMaterialForm', form);
    }, []);

    useEffect(
        function() {
            form.setFieldsValue({ ...data });
        },
        [data]
    );

    function applyFormDownload() {
        download(`${API.V1}/policypractice/file/downloadFile`, {
            file: '北京市企业上市奖励资金申请表.doc'
        });
    }

    return (
        <Form
            className={styles.formRoot}
            labelCol={{ xs: { span: 8 } }}
            wrapperCol={{ xs: { span: 16 } }}
        >
            <Form.Item label="申请金额">
                {form.getFieldDecorator('applyCash', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请填写金额！" />
                        },
                        {
                            validator: (rule, v, callback) => {
                                if (v <= 0) {
                                    callback(
                                        <ErrorTip text="申请金额不得小于0！" />
                                    );
                                }
                                callback();
                            }
                        }
                    ]
                })(<InputNumber maxLength={9} precision={2} />)}
                <span style={{ paddingLeft: 8 }}>万元</span>
            </Form.Item>
            <Form.Item
                label="营业执照复印件"
                extra="支持扩展名：.png .jpg"
            >
                {form.getFieldDecorator('businessLicUrl', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请上传文件！" />
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
            </Form.Item>
            <Form.Item
                label="经营情况介绍及证明"
                extra="支持扩展名：.png .jpg"
            >
                {form.getFieldDecorator('manageProUrl', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请上传文件！" />
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
            </Form.Item>
            <Form.Item
                label="补贴资金申请表"
                extra="支持扩展名：.png .jpg"
            >
                {form.getFieldDecorator('applicationUrl', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请上传文件！" />
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}&nbsp;&nbsp;
                <TemplateButton onClick={applyFormDownload} />
            </Form.Item>
        </Form>
    );
}

export default Form.create({ name: 'basicMaterialForm' })(BasicMaterialForm);
