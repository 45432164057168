'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _class, _temp;

var _zrender = require('zrender');

var _zrender2 = _interopRequireDefault(_zrender);

var _collection = require('lodash/collection');

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _uuid = require('../../util/uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _enum = require('../../constant/enum');

var _common = require('../../util/common');

var _grid = require('../../util/structure/grid');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Store = (_temp = _class = function () {
    // 初始数据
    // 当前在哪一个状态
    function Store(handler, setting) {
        var _this = this;

        (0, _classCallCheck3.default)(this, Store);

        this.createCountParams = function (type) {
            var nodes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
            var date = arguments[2];

            // 去请求阿斯玛的数据
            // this.postRemarkAndOther(nodes);
            var bbdQyxxId = [];
            var labels = [];

            nodes.forEach(function (d) {
                // 事件和历史关联方节点忽略count查询
                if (d.id && !d.isEvent && !d.isHistory) {
                    bbdQyxxId.push(d.id);
                    labels.push(d.data.labels);
                }
            });

            var data = {
                bbdQyxxId: bbdQyxxId.join(','),
                degree: 1,
                isEvent: false,
                labels: labels
            };

            if (date) {
                data.date = date;
            }

            if (type === 'event') {
                data.relationType = 'QYXG_DEBET,KTGG,RMFYGG,ZGCPWSW,ZHIXING,DISHONESTY,SHGY_ZHAOBJG,SHGY_ZHONGJG';
                data.type = 'Company,Person,Ktgg,Qyxg_debet,Rmfygg,Zgcpwsw,Zhixing,Dishonesty,Shgy_zhaobjg,Shgy_zhongjg,Address';
                data.isEvent = true;
            } else if (type === 'stock') {
                data.relationType = 'INVEST';
                data.type = 'Company,Person';
            } else if (type === 'circle') {
                data.relationType = 'INVEST,SUPERVISOR,DIRECTOR,LEGAL,EXECUTIVE';
                data.type = 'Company,Person';
            } else if (type === 'natural') {
                data.relationType = 'INVEST,SUPERVISOR,DIRECTOR,LEGAL,EXECUTIVE';
                data.type = 'Company,Person';
            } else {
                data.relationType = 'INVEST,SUPERVISOR,DIRECTOR,LEGAL,EXECUTIVE';
                data.type = 'Company';
            }

            return data;
        };

        this.setNodesCount = function (type) {
            var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            return new _promise2.default(function (resolve, reject) {
                if (['community', 'seek'].includes(type)) {
                    resolve(state);
                } else if (state.nodes) {
                    var service = type === 'natural' ? 'natural' : 'common';
                    var data = _this.createCountParams(type, state.nodes, params.date);

                    // 请求节点 count 数
                    _this.load(service, 'count', data).then(function (res) {
                        // 为节点加上 count 属性
                        state.nodes.forEach(function (d) {
                            if (res[d.id]) {
                                // 每个 count 都算上了节点自身, 所以 -1
                                // TODO: 抽象化此方法, 根据项目业务逻辑动态设置
                                d.count = res[d.id] - 1;
                            }
                        });
                        resolve(state);
                    }).catch(function (err) {
                        reject(err);
                    });
                } else {
                    state.nodes = [];
                    state.links = [];
                    resolve(state);
                }
            });
        };

        this._handler = handler;
        this._setting = setting;
        this._historyState = [];
        this._cancelRequest = [];
        // this._handler.on('store.cancelRequest', (isError) => {
        //     this.cancelRequest(isError);
        // });
    } // 当前图型状态

    // 数据是否被修改
    // 历史状态


    (0, _createClass3.default)(Store, [{
        key: 'init',
        value: function init(opts) {
            var _this2 = this;

            this.cancelRequest();

            this._options = opts;
            this.prepare();

            setTimeout(function () {
                // 放在下一个事件循环中执行, 确保主进程中其他对象能够优先绑定到该事件.
                _this2._handler.trigger('store.ready', _this2);
            }, 0);
        }
    }, {
        key: 'clear',
        value: function clear() {
            this.cancelRequest();
            this._options = null;
            this._state = null;
            this._originData = null;
            this._currStateIndex = -1;
            this._historyState = [];
        }
    }, {
        key: 'prepare',
        value: function prepare() {
            this._mode = null;
            this._originData = null;
            var _options = this._options,
                data = _options.data,
                datasource = _options.datasource;


            if ((0, _common.isEmpty)(datasource)) {
                this._mode = Store.Mode.STATIC;
                this._originData = data;
                this._state = this.originToState(data);
                return;
            }

            if ((0, _common.isEmpty)(Store.HttpRequest)) {
                throw new Error('起用 datasource 配置, 需要引入 ajax 模块.');
            }

            // 使用 openapi 默认配置
            if ((0, _common.isString)(datasource)) {
                this._mode = Store.Mode.DB;
                var baseURL = datasource.trim();
                if (baseURL.endsWith('/')) {
                    baseURL = baseURL.slice(0, -1);
                }
                this._datasource = _config2.default.datasource;
                // this._datasource.config = this._datasource.config || {};
                this._datasource.config.baseURL = baseURL;
                // 使用自定义数据源配置
            } else if ((0, _common.isObject)(datasource)) {
                this._mode = Store.Mode.DB;
                this._datasource = (0, _merge2.default)({}, _config2.default.datasource, datasource);
            } else {
                throw new Error('datasource 格式错误, 仅支持 string 和 object 类型格式.');
            }
        }
    }, {
        key: 'diff',
        value: function diff(nextState, lastState) {
            this._dirty = false;

            // TODO: 数据差异化比较
            var newState = nextState;
            this._dirty = true;

            // TODO: 提取出有差异的state, push到一个更新队列enqueue中, 需要有个type表示增,删,改, 然后painter只更新这个队列就行了.
            return newState;
        }

        // 将 state 转换为KV数据, 便于快速检索

    }, {
        key: 'toViewDataMapping',
        value: function toViewDataMapping(state) {
            var dataMapping = {};

            if ((0, _common.isEmpty)(state)) {
                return dataMapping;
            }

            // 将nodes转为{id: node}, 数据格式
            dataMapping[_enum.TypeMapping.nodes] = (0, _collection.keyBy)(state.nodes, 'id');

            // 检测links是否设置了fromNode, toNode, 如果没有设置, 自动添加.
            if ((0, _common.isNotEmpty)(state.links)) {
                var nodes = dataMapping[_enum.TypeMapping.nodes];
                state.links.forEach(function (link) {
                    // 必须要重新设置, 要不线条对应的节点坐标还是之前的.
                    link.fromNode = nodes[link.from];
                    link.toNode = nodes[link.to];
                });
            }

            for (var dataType in state) {
                var data = state[dataType];
                var shapeType = _enum.TypeMapping[dataType];

                if (shapeType === _enum.TypeMapping.nodes) {
                    continue;
                }

                switch (shapeType) {
                    case _enum.TypeMapping.links:
                        dataMapping[shapeType] = formatLinksState(data);
                        break;
                    default:
                        dataMapping[shapeType] = (0, _collection.keyBy)(data, 'id');
                }
            }
            return dataMapping;
        }
    }, {
        key: 'setDefaultBehavior',
        value: function setDefaultBehavior(state, options) {
            var _this3 = this;

            var _getShapesTotal = getShapesTotal(state),
                nodes = _getShapesTotal.nodes,
                links = _getShapesTotal.links;

            if (this._setting.DEV) {
                if (this._t) {
                    clearTimeout(this._t);
                }
                this._t = setTimeout(function () {
                    console.log('nodes\u6E32\u67D3\u6570: ' + nodes + ', links\u6E32\u67D3\u6570: ' + links);
                    _this3._t = null;
                }, 100);
            }

            var _options$animation = options.animation,
                animation = _options$animation === undefined ? false : _options$animation,
                _options$shadow = options.shadow,
                shadow = _options$shadow === undefined ? false : _options$shadow,
                _options$draggable = options.draggable,
                draggable = _options$draggable === undefined ? false : _options$draggable;
            // 超出最大限制自动关闭所有效果.

            if (nodes > _config2.default.animation.maxNodes || links > _config2.default.animation.maxLines) {
                animation = false;
                shadow = false;
                draggable = false;
            }

            for (var type in state) {
                var data = state[type];
                if ((0, _common.isEmpty)(data)) {
                    continue;
                }

                data.forEach(function (shapeState) {
                    (0, _assign2.default)(shapeState, {
                        animation: animation,
                        shadow: shadow,
                        draggable: draggable
                    });
                });
            }
        }

        // TODO: 这里用一个队列来保存 state, 最后合并队列中的state, 一次性绘制, 避免连续调用 setState 造成性能开销.

    }, {
        key: 'setState',
        value: function setState() {
            var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var newState;
            // 过滤掉数据异常的state, 包括node, line等, 并将节点多余属性移除.
            newState = this.filterState(state);

            this._state = this.diff(newState, this._state);

            if ((0, _common.isEmpty)(this._state)) {
                this._state = {};
            }

            if (this._dirty) {
                this._handler.trigger('store.changeState', this._state);
                // TODO: 该方法应该提前调用(初始 state 的时候), 不应该每次setState的时候都设置默认行为.
                // 配置默认行为
                this.setDefaultBehavior(this._state, this._options);
                // 转换为图形数据映射
                var dataMapping = this.toViewDataMapping(this._state);
                // 开始绘制
                this._painter.paint(dataMapping);
            }
        }

        // 重置 State 恢复到初始状态

    }, {
        key: 'resetState',
        value: function resetState() {
            this._state = this.originToState(this._originData);
            return this._state;
        }
    }, {
        key: 'getOriginData',
        value: function getOriginData() {
            return this._originData;
        }
    }, {
        key: 'getState',
        value: function getState() {
            return this._state || {};
        }
    }, {
        key: 'toGridState',
        value: function toGridState(state, centerId, setDegree) {
            if ((0, _common.isEmpty)(state)) {
                return;
            }
            /**
             * 观察取消对象深拷贝会有什么问题!!!
             */
            // var _state = zrender.util.clone(state);
            // var gridState = stateToGrid(_state, centerId);
            // return Object.assign(_state, { type: 'grid', ...gridState });

            return (0, _grid.stateToGrid)(state, centerId, setDegree);
        }
    }, {
        key: 'mergeState',
        value: function mergeState(currState, newState) {
            if ((0, _common.isEmpty)(newState)) {
                return currState;
            }

            if ((0, _common.isEmpty)(currState)) {
                currState = {};
            }

            var _loop = function _loop(shapeType) {
                if (!currState[shapeType]) {
                    currState[shapeType] = [];
                }
                var newData = newState[shapeType];
                var currData = currState[shapeType];

                newData.forEach(function (newShape) {
                    // 如果newShape.id是uuid生成的则此处无法匹配相同数据
                    var currShape = currData.find(function (shape) {
                        return shape.id === newShape.id;
                    });
                    // 合并已有shape
                    if (currShape) {
                        // 保留当前状态
                        (0, _assign2.default)(currShape, newShape, {
                            shadow: currShape.shadow,
                            animation: currShape.animation,
                            category: currShape.category,
                            marks: currShape.marks,
                            startPoint: currShape.startPoint || newShape.startPoint
                        });
                        // 添加没有的
                    } else {
                        currData.push(newShape);
                    }
                });
            };

            for (var shapeType in newState) {
                _loop(shapeType);
            }

            return currState;
        }

        /**
         * 将接收到的state做数据清洗
         * @return {object} 返回state对象
         */

    }, {
        key: 'filterState',
        value: function filterState(targetState) {
            if ((0, _common.isEmpty)(targetState)) {
                return;
            }

            var state = {};

            for (var type in targetState) {
                var shapeType = _enum.TypeMapping[type];
                if (!shapeType) {
                    continue;
                }

                var data = targetState[type];
                var shapes = [];
                if ((0, _common.isNotEmpty)(data)) {
                    switch (shapeType) {
                        case _enum.TypeMapping.nodes:
                            data.forEach(function (node) {
                                var shape = cleanNode(node);
                                if (shape && shape.position) {
                                    shapes.push(shape);
                                } else {
                                    console.error('filterState > node.position 为空, 已被过滤掉: ', shape);
                                }
                            });
                            break;
                        case _enum.TypeMapping.links:
                            data.forEach(function (link) {
                                var shape = cleanLink(link);
                                if (shape && shape.from && shape.to) {
                                    shapes.push(shape);
                                } else {
                                    console.error('filterState > link.from 或 link.to 为空, 已被过滤掉: ', shape);
                                }
                            });
                            break;
                        case _enum.TypeMapping.circles:
                            data.forEach(function (circle) {
                                var shape = (0, _assign2.default)({}, circle);
                                shapes.push(shape);
                            });
                            break;
                    }
                }
                state[type] = shapes;
            }

            return state;
        }

        /**
         * 加载接口数据
         * @param {string} service 请求的服务
         * @param {object} data 请求的参数对象
         * @return {Promise} 返回一个Promise对象
         */

    }, {
        key: 'request',
        value: function request(type, service, data) {
            var _this4 = this;

            if ((0, _common.isEmpty)(Store.HttpRequest)) {
                throw new Error('起用 datasource 配置, 需要引入 ajax 模块.');
            }
            var chartAPI = this._datasource.service[type];
            var request = (0, _assign2.default)({}, this._datasource.config, chartAPI[service](data));

            request.cancel = function (_cancel) {
                // this._cancelRequest = _cancel;
                // 可能同时有多个数据请求, 上面的方法只保存了最后那个请求的cancel          
                if (!_this4._cancelRequest) {
                    _this4._cancelRequest = [];
                }

                _this4._cancelRequest.push(_cancel);
            };

            return Store.HttpRequest(request, this._setting.DEV);
        }

        // 获取阿斯玛的remark 等

    }, {
        key: 'postRemarkAndOther',
        value: function postRemarkAndOther(nodes) {
            // 获取count 
            var bbdQyxxId = [];
            nodes.forEach(function (d) {
                if (d.id) {
                    bbdQyxxId.push(d.id);
                }
            });

            // 去请求阿斯玛的数据
            this._handler.trigger('chart.getSettingList', bbdQyxxId);
        }

        // 获取count


        // 设置节点的关联数

    }, {
        key: 'loadInitState',


        // 初始化图数据
        value: function loadInitState(callback) {
            var _this5 = this;

            var _options2 = this._options,
                type = _options2.type,
                data = _options2.data,
                _options2$extension = _options2.extension,
                extension = _options2$extension === undefined ? this.setNodesCount : _options2$extension;

            data.degree = data.degree ? data.degree : _config2.default.degree;

            this.load(type, 'init', data).then(function () {
                var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

                _this5.postRemarkAndOther(state.nodes);
                // 用户自定义扩展方法
                if (typeof extension === 'function') {
                    var isCancel = false;
                    try {
                        extension(type, state, data).then(function (res) {
                            _this5._state = res || state;
                        }).catch(function (err) {
                            _this5._state = state;
                            if (err.message === 'cancel') {
                                isCancel = true;
                            }
                            console.error(err);
                        }).finally(function () {
                            !isCancel && callback && callback(_this5._state);
                            _this5._handler.trigger('store.loadedAll');
                        });
                    } catch (e) {
                        console.error('\'extension\' option must return a Promise instance.');
                    }
                } else {
                    _this5._state = state;
                    callback && callback(_this5._state);
                    _this5._handler.trigger('store.loadedAll');
                }
            }).catch(function (err) {
                if (callback && err.message !== 'cancel') {
                    _this5._state = {};
                    callback(_this5._state);
                }
                console.error(err);
            });
        }

        // 扩展，改变中心点，探寻数据 

    }, {
        key: 'loadNewState',
        value: function loadNewState(params, service) {
            var _this6 = this;

            var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this._options.type;
            var _options$extension = this._options.extension,
                extension = _options$extension === undefined ? this.setNodesCount : _options$extension;


            return new _promise2.default(function (resolve, reject) {
                _this6.load(type, service, params).then(function (state) {
                    _this6.postRemarkAndOther(state.nodes);

                    if (typeof extension === 'function') {
                        try {
                            extension(type, state, params).then(function (res) {
                                resolve(res || state);
                            }).catch(function (err) {
                                reject(err);
                            }).finally(function () {
                                _this6._handler.trigger('store.loadedAll');
                            });
                        } catch (e) {
                            console.error('\'extension\' option must return a Promise instance.');
                        }
                    } else {
                        _this6._handler.trigger('store.loadedAll');
                        resolve(state);
                    }
                }).catch(function (err) {
                    _this6._handler.trigger('store.loadedAll');
                    console.error('扩展接口报错', err);
                });
            });
        }

        /**
         * 加载接口数据
         * @param {string} service 请求的服务
         * @param {object} params 请求的参数对象
         * @return {Promise} 返回一个Promise对象
         */

    }, {
        key: 'load',
        value: function load(type, service) {
            var _this7 = this;

            var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            this._handler.trigger('store.loading', {
                type: type,
                service: service,
                params: params
            });

            var promise = this.request(type, service, params);

            // 处理扩展接口数据
            return new _promise2.default(function (resolve, reject) {
                promise.then(function (data) {
                    _this7._handler.trigger('store.load', data);

                    if (service === 'init' && type === _this7._options.type) {
                        _this7._originData = data;
                    }

                    if (service === 'init' || service === 'extension') {
                        if ((0, _common.isEmpty)(data) || (0, _common.isEmpty)(data.nodes)) {
                            _this7._handler.trigger('store.nodata', {
                                type: type,
                                service: service,
                                params: params
                            });
                        } else {
                            data = _this7.originToState(data);
                            if (service === 'extension') {
                                setKeyNodes(data.nodes, _this7._originData.keyNodes);
                            }
                        }
                    }

                    if (service === 'init' && data && data.nodes && data.nodes.length > _config2.default.limitNodes) {
                        _this7._handler.trigger('store.overLimit', {
                            state: data,
                            len: data.nodes.length,
                            callback: function callback(flag) {
                                flag ? resolve(data) : reject();
                            }
                        });
                    } else {
                        resolve(data);
                    }
                }, function (error) {
                    reject(error);
                    _this7._handler.trigger('store.error', {
                        error: error,
                        type: type,
                        service: service
                    });
                }).finally(function () {
                    _this7._handler.trigger('store.loaded', {
                        type: type,
                        service: service,
                        params: params
                    });
                });
            });
        }
    }, {
        key: 'cancelRequest',
        value: function cancelRequest() {
            if (!this._cancelRequest) {
                return;
            }

            var count = this._cancelRequest.length;
            if (count > 0) {
                this._cancelRequest.forEach(function (_cancel) {
                    return _cancel('cancel');
                });
                this._cancelRequest = [];
            }
        }

        /**
         * 将原始数据转换为State
         * @param {Object} originData 原始数据类型
         * @param {Object} options    当前图形的配置项
         */

    }, {
        key: 'originToState',
        value: function originToState(origin) {
            var _this8 = this;

            if ((0, _common.isEmpty)(origin) || (0, _common.isEmpty)(origin.nodes)) {
                return;
            }

            var state = {};
            // 深拷贝所有原始数据
            var originData = _zrender2.default.util.clone(origin);

            var _loop2 = function _loop2(dataType) {
                if (dataType === 'keyNodes') {
                    // 此属性不是绘制的图形状态
                    return 'continue';
                }
                data = originData[dataType];

                data.forEach(function (newShape) {
                    if (!newShape.id) {
                        // 自动生成的id, 合并数据时无法找到相同的元素
                        newShape.id = (0, _uuid2.default)();
                    }
                    if (dataType === 'nodes') {
                        // 设置默认起始点
                        newShape.startPoint = newShape.startPoint || _this8._painter.getCenterPoint();
                    }
                });
                state[dataType] = data;
            };

            for (var dataType in originData) {
                var data;

                var _ret2 = _loop2(dataType);

                if (_ret2 === 'continue') continue;
            }
            return state;
        }
    }, {
        key: 'setPainter',
        value: function setPainter(painter) {
            this._painter = painter;
        }
    }, {
        key: 'getPainter',
        value: function getPainter() {
            return this._painter;
        }
    }, {
        key: 'getOption',
        value: function getOption() {
            return this._options;
        }
    }, {
        key: 'getCurrStateIndex',
        value: function getCurrStateIndex() {
            return this._currStateIndex;
        }
    }, {
        key: 'getMode',
        value: function getMode() {
            return this._mode;
        }
    }, {
        key: 'getHistoryState',
        value: function getHistoryState(index) {
            if (index >= 0) {
                return this._historyState[index];
            }
            return this._historyState;
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            // this._handler.off('store.cancelRequest');
            this.cancelRequest();

            this._mode = null;
            this._options = null;
            this._datasource = null;
            this._originData = null;
            this._state = null;
            this._painter = null;
            this._dirty = null;
            this._cancelRequest = null;
        }
    }], [{
        key: 'registerHttpRequest',
        value: function registerHttpRequest(request) {
            Store.HttpRequest = request;
        }
    }]);
    return Store;
}(), _class.Mode = {
    DB: 'db',
    STATIC: 'static'
}, _temp);

// 将startId_endId, endId_startId相同的links, 以startId_endId为key, 合并为同一组数据

exports.default = Store;
function formatLinksState(links) {
    var newLinks = {};

    if ((0, _common.isEmpty)(links)) {
        return newLinks;
    }

    links.forEach(function (link) {
        var group = link.from + '_' + link.to;
        var from_to = link.from + '_' + link.to;
        var to_from = link.to + '_' + link.from;

        if (newLinks['' + from_to]) {
            group = '' + from_to;
            newLinks[group] = newLinks['' + from_to];
        } else if (newLinks['' + to_from]) {
            group = '' + to_from;
            newLinks[group] = newLinks['' + to_from];
        } else {
            group = '' + from_to;
            newLinks[group] = [];
            newLinks[group].id = group;
        }

        // from-to, to-from相同的分为一组
        if (group.indexOf(from_to) !== -1 || group.indexOf(to_from) !== -1) {
            if (group.indexOf(from_to) === 0) {
                link.endArrow = true;
            } else {
                link.startArrow = true;
            }
            newLinks[group].push(link);
        }
    });

    return newLinks;
}

function setKeyNodes(nodes, keyNodes) {
    if (!nodes || !keyNodes) {
        return;
    }

    nodes.forEach(function (node) {
        if (keyNodes.some(function (keyNode) {
            return keyNode.id === node.id;
        })) {
            node.isKey = true;
        }
    });
}

function cleanNode(node) {
    if (!node) {
        return;
    }

    var shape = (0, _assign2.default)({}, node);

    delete shape.childs;
    delete shape.vx;
    delete shape.vy;
    delete shape.x;
    delete shape.y;

    return shape;
}

function cleanLink(link) {
    if (!link) {
        return;
    }

    var shape = (0, _assign2.default)({}, link);

    shape.fromNode = cleanNode(link.fromNode);
    shape.toNode = cleanNode(link.toNode);

    delete shape.source;
    delete shape.target;

    return shape;
}

function getShapesTotal(state) {
    var nodeCount = 0;
    if (state.nodes) {
        for (var id in state.nodes) {
            nodeCount++;
        }
    }

    var lineCount = 0;
    if (state.links) {
        for (var _id in state.links) {
            lineCount++;
        }
    }

    return {
        nodes: nodeCount,
        links: lineCount
    };
}
module.exports = exports['default'];