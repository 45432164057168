import { Avatar, Card, Col, Empty, message, Row, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { Link } from 'react-router-dom';
import { queryListExpert } from '../service';
import { IMAGE_HOST } from '../../../constants/enum';
import styles from './styles.scss';
import expertImg from './image/expert.jpeg';
export default function ExpertList({ indexShow, history }) {
  const { isPub, isDev, isTest } = useContext(GlobalContext);
  const enviroment = [isPub, isDev, isTest];
  const [data, setData] = useState({
    expertList: []
  });
  useEffect(() => {
    async function fetchData() {
      const result = await queryListExpert({ pageNum: 1, pageSize: 12 });
      const list = result.data;
      if (result.statusCode == 1000) {
        const list = result.data;
        setData({ expertList: list.data });
      } else {
        setData({ expertList: [] });
        message.info('专家列表' + result.message);
      }
    }
    fetchData();
  }, []);
  function load() { }
  return (
    <div>
      {
        data?.expertList?.length ? (
          <div
            style={{
              textAlign: 'right',
              fontSize: 16
            }}
          >
            <Link to={'/investment/expert'}>...更多</Link>
          </div>
        ) : null
      }
      <Row gutter={16} className={styles.expertList}>
        {data?.expertList?.length ? (
          data.expertList.map((expert, index) => (
            <Col span={8} key={'expert' + index} style={{ marginTop: 16 }}>
              <Card
                hoverable
                bordered={false}
                style={{ height: 150 }}
                onClick={() => {
                  history.push({
                    pathname: `/investment/expert/${expert.expertTeam}`,
                    state: expert
                  });
                }}
              >
                <Card.Meta
                  avatar={<Avatar style={{width:120}} src={(expert.avatar != '--') ? expert.avatar : expertImg} />}
                  title={expert.expertTeam + '（' + expert.expertFirm + '）'}
                  description={
                    <div className={styles.textContent}>
                      <Tooltip title={expert.firmName}>
                        <Typography>
                          <div className={styles.textBoundary}>
                            {expert.firmName}
                          </div>
                        </Typography>
                      </Tooltip>
                      <Tooltip title={expert.zjMainServiceBlock}>
                        <Typography>
                          <div className={styles.textBoundary}>
                            主要服务板块：{expert.zjMainServiceBlock.split(',')[0]}
                          </div>
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title={expert.zjMainServiceIndustry}
                        overlayStyle={{ maxWidth: 700 }}
                      >
                        <Typography>
                          <div className={styles.textBoundary}>主要领域：{expert.zjMainServiceIndustry.split(',')[0]}</div>
                        </Typography>
                      </Tooltip>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))
        ) : (
            <Empty />
          )}
      </Row>
    </div>
  );
}
