'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _RoundCircle = require('./RoundCircle');

var _RoundCircle2 = _interopRequireDefault(_RoundCircle);

var _circleParams = require('./circleParams');

var _circleParams2 = _interopRequireDefault(_circleParams);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// 将 nodes 按行业分类，并计算坐标
/**
 * Created by Tirion on 2017/12/6.
 */
var IndustryData = function () {
    function IndustryData(data, canvasCenterPoints) {
        (0, _classCallCheck3.default)(this, IndustryData);
        this.industry = {// 各个行业的数据
            // data: {
            //     A: {
            //         nodes: [this.nodes[i]...],
            //         attr: {
            //             r,
            //             area,
            //             rectArea,
            //             perimeter,
            //             position
            //         }
            //     }
            // },
            // attr: {
            //   length,
            //   area,
            //   rectArea,
            //   sideLength
            // }
        };
        this.canvasCenterPoints = [0, 0];
        this.mapCenterPoints = [0, 0];

        this.nodes = data.nodes;
        this.links = data.links;
        this.canvasCenterPoints = canvasCenterPoints;
        this._industryClassify();
        this._calcIndustryPosition();
        this._calcNodePosition();
    }
    // 将 nodes 按行业进行分类


    (0, _createClass3.default)(IndustryData, [{
        key: '_industryClassify',
        value: function _industryClassify() {
            var data = {};
            this.nodes.forEach(function (item) {
                var companyIndustry = item.data.companyIndustry;
                if (!data[companyIndustry]) {
                    data[companyIndustry] = {};
                    data[companyIndustry].nodes = [];
                    data[companyIndustry].attr = {};
                }
                data[companyIndustry].nodes.push(item);
            });
            this.industry.data = data;
            this._calcIndustrySize();
        }
        // 计算每个行业的 size，及总 size

    }, {
        key: '_calcIndustrySize',
        value: function _calcIndustrySize() {
            var industryAttr = {
                length: 0,
                area: 0,
                rectArea: 0
            };

            var data = this.industry.data;
            for (var k in data) {
                // 计算每个行业圈层的面积
                var count = data[k].nodes.length;
                var roundCircle = this._calcIndustryCircle({ cx: 0, cy: 0, count: (count - 1) * 2, draw: false });
                var _roundCircle$attr = roundCircle.attr,
                    r = _roundCircle$attr.r,
                    perimeter = _roundCircle$attr.perimeter,
                    area = _roundCircle$attr.area,
                    rectArea = _roundCircle$attr.rectArea;


                data[k].attr = {
                    r: r,
                    perimeter: perimeter,
                    area: area,
                    rectArea: rectArea
                };

                // 所有行业总共占的面积
                industryAttr.length++;
                industryAttr.area += area;
                industryAttr.rectArea += rectArea;
            }
            industryAttr.sideLength = Math.sqrt(industryAttr.rectArea * 2); // 因为画各个行业的时候会产生空隙，所以这里x2弥补
            this.industry.attr = industryAttr;

            this._calcMapCenterPoints();
        }
        // 计算绘制的图的中心点位置

    }, {
        key: '_calcMapCenterPoints',
        value: function _calcMapCenterPoints() {
            var sideLength = Math.sqrt(this.industry.attr.rectArea);
            this.mapCenterPoints = [sideLength / 2, sideLength / 2];
        }
        // 计算行业圈层属性

    }, {
        key: '_calcIndustryCircle',
        value: function _calcIndustryCircle(_ref) {
            var cx = _ref.cx,
                cy = _ref.cy,
                count = _ref.count,
                draw = _ref.draw;

            return new _RoundCircle2.default((0, _extends3.default)({ cx: cx, cy: cy }, _circleParams2.default.roundCircle, { count: count, draw: draw }));
        }
        // 计算各个行业的位置

    }, {
        key: '_calcIndustryPosition',
        value: function _calcIndustryPosition() {
            var _this = this;

            var right = 0;
            var bottom = 0;
            var prelineBottom = 0;
            var currentRight = 0;
            var currentBottom = 0;

            var data = this.industry.data;
            var industrySide = this.industry.attr.sideLength;

            var gap = 50; // 行业间的间隙
            // 将各个行业放入数组中
            var industries = [];
            for (var k in data) {
                industries.push(data[k]);
            }
            // 对数组进行随机排序，再计算坐标，随机排序
            // industries.sort(() => Math.random() - 0.5);
            industries.forEach(function (item) {
                var r = item.attr.r;

                var sideLength = r * 2;
                if (right + sideLength <= industrySide) {
                    // 不换行添加行业
                    currentRight = right + sideLength + gap;
                    currentBottom = prelineBottom + sideLength + gap;
                    right = currentRight;
                    if (prelineBottom + sideLength >= bottom) {
                        bottom = currentBottom;
                    }
                } else {
                    // 换行添加行业
                    right = currentRight = sideLength + gap;
                    currentBottom = bottom + sideLength + gap;
                    prelineBottom = bottom;
                    bottom = currentBottom;
                }
                item.attr.position = [];
                item.attr.position[0] = currentRight - r + (_this.canvasCenterPoints[0] - _this.mapCenterPoints[0]);
                item.attr.position[1] = currentBottom - r + (_this.canvasCenterPoints[1] - _this.mapCenterPoints[1]);
            });
        }
        // 计算每个节点的坐标

    }, {
        key: '_calcNodePosition',
        value: function _calcNodePosition() {
            var _this2 = this;

            var data = this.industry.data;

            var _loop = function _loop(k) {
                var position = data[k].attr.position;

                var count = data[k].nodes.length;
                var circle = _this2._calcIndustryCircle({ cx: position[0], cy: position[1], count: (count - 1) * 2, draw: false });
                var coords = circle.coords;
                data[k].nodes.forEach(function (item) {
                    var randomIndex = Math.floor(Math.random() * coords.length);
                    var coord = coords.splice(randomIndex, 1)[0];
                    item.position = [];
                    item.position[0] = coord.cx;
                    item.position[1] = coord.cy;
                });
            };

            for (var k in data) {
                _loop(k);
            }
        }

        // 更新行业各节点坐标

    }, {
        key: 'updateIndustryPosition',
        value: function updateIndustryPosition(industry, moveX, moveY) {
            industry.attr.position = [industry.attr.position[0] + moveX, industry.attr.position[1] + moveY];
            industry.nodes.forEach(function (node) {
                var newPosition = [node.position[0] + moveX, node.position[1] + moveY];
                node.position = newPosition;
                node.zr && node.zr.attr('position', newPosition);
            });
        }
    }]);
    return IndustryData;
}();

exports.default = IndustryData;
module.exports = exports['default'];