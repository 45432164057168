/**
 * @author Ray
 * @description 企业基本信息
 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import Back from '../../../common/backBtn';
import Title from '../../../common/title';
import { Divider, message } from 'antd';
import styles from './styles.scss';
import EnterpriseInformation from '../common/enterpriceInfo';
import Financial from '../../../common/financialTabs';
import Intention from './intention';
import Footer from '../common/footer';
import { applyDetail, editDetail, saveAcquisitionApply } from '../service';
import {
    showModal,
    closeModal,
    default as Actions
} from '../../investment/script/actions';
import useStorage from '../../../hooks/useStorage';
import { isEmpty, isNotEmpty, checkFinanceData } from '../../../utils/utils';
import { GlobalContext } from '../../../common/globalStore/createProvider';

let reservedMergeId = undefined;
let purchId = undefined;

export default function View({ history, dispatch, match, location }) {
    // const [user] = useStorage('UserInfo');

    const { user } = useContext(GlobalContext);
    const enterRef = useRef(null);
    const intentionRef = useRef(null);
    const [state, set] = useState({});
    const [isPreview] = useState(function() {
        return location.pathname.includes('preview');
    });
    const [isEdit] = useState(function() {
        return isNotEmpty(match.params);
    });
    const [needUpdate, setNeedUpdate] = useState(0);
    // 统一处理表单的数据
    function handleChange(values) {
        set(
            Object.assign(state, {
                ...values
            })
        );
    }
    // 更新与保存操作
    function action(type) {
        let isSubmit = 'submit' === type;

        return function() {
            function callApi() {
                enterRef.current.validateFieldsAndScroll(function(errors) {
                    dispatch(closeModal());
                    if (!errors) {
                        let data = JSON.parse(JSON.stringify(state));
                        data.needUpdate = needUpdate;
                        data.type = 3;
                        if (isSubmit) {
                            data.merge.applicationStatus = 2;
                        } else {
                            data.merge.applicationStatus = 1;
                        }
                        if (isEdit) {
                            data.merge.id = Number(match.params.id);
                            data.merge.mergeId = reservedMergeId;
                            if (isNotEmpty(data.mergePurch)) {
                                data.mergePurch.id = purchId;
                                data.mergePurch.mergeId = reservedMergeId;
                            }
                            data.merge.applicationCategory = 1;
                        }
                        if (isEmpty(data.mergePurch)) {
                            data.mergePurch = {};
                        }
                        data.merge.userId = user?.id;
                        data.merge.enterpriseName = user?.organization;
                        data.merge.creditCode = user?.creditCode;
                        if (isNotEmpty(data.mergePurch)) {
                            const {
                                subjectCapital: [
                                    subjectCapitalLow,
                                    subjectCapitalHigh
                                ]
                            } = data.mergePurch;
                            data.mergePurch.subjectCapitalLow = subjectCapitalLow;
                            data.mergePurch.subjectCapitalHigh = subjectCapitalHigh;
                            delete data.mergePurch.subjectCapital;
                        }
                        let func = isEdit ? editDetail : saveAcquisitionApply;
                        func(data).then(function(res) {
                            if (res.success) {
                                message.success(res.message);
                                dispatch(closeModal());
                                history.push({
                                    pathname: '/merging'
                                });
                            } else {
                                message.error(res.message);
                            }
                        });
                    } else {
                        message.warn('存在未填写的选项!');
                    }
                });
            }

            isSubmit
                ? dispatch(
                      showModal({
                          content: '是否提交申请？',
                          onOk: () => callApi(),
                          onClose: () => dispatch(closeModal()),
                          onCancel: () => dispatch(closeModal())
                      })
                  )
                : callApi();
        };
    }

    // 请求详情
    useEffect(function() {
        const {
            params: { id }
        } = match;

        if (isNotEmpty(id)) {
            applyDetail(id).then(function(res) {
                if (isNotEmpty(res.data)) {
                    const { merge, mergePurch } = res.data;
                    const { id } = merge;
                    if (isNotEmpty(mergePurch)) {
                        const {
                            subjectCapitalLow,
                            subjectCapitalHigh,
                            id
                        } = mergePurch;
                        mergePurch.subjectCapital = [
                            subjectCapitalLow,
                            subjectCapitalHigh
                        ];
                        purchId = id;
                        delete mergePurch.id;
                        delete mergePurch.mergeId;
                        delete mergePurch.gmtCreate;
                        delete mergePurch.subjectCapitalLow;
                        delete mergePurch.subjectCapitalHigh;
                        setTimeout(
                            () =>
                                intentionRef.current.setFieldsValue({
                                    ...mergePurch
                                }),
                            0
                        );
                    }
                    reservedMergeId = id;
                    const {
                        mailbox,
                        industry,
                        economicType,
                        realityCapital,
                        enterpriseType,
                        contactInformation,
                        businessScope,
                        registeredAddress,
                        registeredCapital
                    } = merge;
                    setTimeout(
                        () =>
                            enterRef.current.setFieldsValue({
                                mailbox,
                                industry,
                                economicType,
                                realityCapital,
                                enterpriseType,
                                contactInformation,
                                businessScope,
                                registeredAddress,
                                registeredCapital
                            }),
                        0
                    );
                }
            });
        }
    }, []);

    return (
        <div className={styles.root}>
            <Back history={history} url="/merging" />
            <Title text="购买意向申请表" level={1} />
            <Divider />
            <Title level={2} text="购买方企业基本信息" />
            <EnterpriseInformation
                isEdit={isEdit}
                isPreview={isPreview}
                onChange={handleChange}
                onInit={ref => (enterRef.current = ref)}
            />
            <Title level={2} text="财务信息" />
            <Financial
                readOnly
                type={3}
                history={history}
                applyId={match.params?.id}
                status={isPreview ? 5 : -1}
                onRefreshClick={() => setNeedUpdate(1)}
            />
            <Title level={2} text="购买意向" />
            <Intention
                isPreview={isPreview}
                onInit={ref => (intentionRef.current = ref)}
                onChange={handleChange}
            />
            {!isPreview && (
                <Footer save={action('save')} submit={action('submit')} />
            )}
        </div>
    );
}
