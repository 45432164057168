/**
 * @author Ray
 */
import React, { useContext, useState } from 'react';
import { Selector } from '../../merging/home/apply';
import styles from '../../merging/home/styles.scss';
import { SUBMIT_STATUS, HANDLE_STATUS } from '../../../constants/enum';
import { Column } from '../../../common/table';
import {
  SubmitButton,
  PreviewButton,
  EditButton,
  DeleteButton,
  RejectButton
} from '../../../common/buttons';
import { columns } from './columns';
import Table from '../../../common/tableEx';
import { getApply } from './service';
import WithLabel from '../../../common/withLabel';
import { withRouter } from 'react-router-dom';
import {
  deleteApply,
  submitApplyById,
  saveApplyById,
  callApplyDetail
} from '../consultant/service';
import {
  checkOperationData,
  checkOtherData,
  isNotEmpty
} from '../../../utils/utils';
import { message } from 'antd';
import * as Actions from '../script/actions';
import { GlobalContext } from '../../../common/globalStore/createProvider';

function Apply({ history, dispatch }) {
  const [query, setQuery] = useState(null);
  const gs = useContext(GlobalContext);
  function handleChange(t) {
    return function(v) {
      setQuery({ ...query, [t]: v });
    };
  }

  function handler(res) {
    if (isNotEmpty(res) && res.success) {
      message.success(res.message);
    } else {
      message.error(res.message);
    }
    setQuery({ ...query });
    dispatch(Actions.closeModal());
  }

  function handleDelete(id) {
    dispatch(
      Actions.showModal({
        visible: true,
        content: '确认删除！',
        onOk: () => {
          deleteApply(id).then(handler);
        }
      })
    );
  }

  function handleSubmit(id) {
    dispatch(
      Actions.showModal({
        visible: true,
        content: '确认提交！',
        onOk: () => {
          Promise.all([
            checkOperationData(1, gs.user?.creditCode),
            checkOperationData(2, gs.user?.creditCode),
            checkOperationData(3, gs.user?.creditCode),
            checkOtherData(gs.user?.creditCode),
            callApplyDetail(id)
          ]).then(([o1, o2, o3, o4, applyDetail]) => {
            if (
              isNotEmpty(o1) &&
              isNotEmpty(o2) &&
              isNotEmpty(o3) &&
              isNotEmpty(o4) &&
              isNotEmpty(applyDetail.data?.business) &&
              isNotEmpty(applyDetail.data?.customer) &&
              isNotEmpty(applyDetail.data?.other) &&
              isNotEmpty(applyDetail.data?.supplier)
            ) {
              submitApplyById(id).then(handler);
            } else {
              dispatch(
                Actions.showModal({
                  title: '提示',
                  content: '请完善填报信息',
                  okText: '确定',
                  onOk: () => {
                    dispatch(Actions.closeModal());
                  },
                  onCancel: () => {
                    dispatch(Actions.closeModal());
                  },
                  onClose: undefined
                })
              );
            }
          });
        }
      })
    );
  }

  const handleWithdraw = id => () => {
    saveApplyById(id).then(handler);
  };

  return (
    <div className={styles.applyList}>
      <div style={{ marginBottom: 16 }}>
        <WithLabel text="提交状态:">
          <Selector
            showSelectAll
            defaultValue={null}
            options={SUBMIT_STATUS}
            onChange={handleChange('submitStatus')}
          />
        </WithLabel>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <WithLabel text="受理状态:">
          <Selector
            showSelectAll
            defaultValue={null}
            options={HANDLE_STATUS}
            onChange={handleChange('examineStatus')}
          />
        </WithLabel>
      </div>
      <Table
        query={query}
        getData={getApply}
        pagination={{ pageNum: 1, pageSize: 5 }}
        parseData={d => {
          return d ? d.data : [];
        }}
      >
        {columns.map(function(c, i) {
          return <Column key={i} {...c} />;
        })}
        <Column
          title="操作"
          align="center"
          render={(text, record) => {
            return (
              <>
                {record.submitStatus === 1 && (
                  <EditButton
                    onClick={() => {
                      history.push({
                        pathname: `/investment/consultant/require/${record.id}`
                      });
                    }}
                  />
                )}
                {record.submitStatus === 1 && (
                  <SubmitButton
                    onClick={() => {
                      handleSubmit(record.id);
                    }}
                  />
                )}
                {record.submitStatus === 2 && (
                  <>
                    <PreviewButton
                      onClick={() => {
                        history.push({
                          pathname: `/investment/consultant/detail/${record.id}`
                        });
                      }}
                    />
                    {record.examineStatus === 1 && (
                      <RejectButton
                        text="撤回"
                        onClick={() => {
                          dispatch(
                            Actions.showModal({
                              visible: true,
                              content: '确认撤回！',
                              onOk: handleWithdraw(record.id)
                            })
                          );
                        }}
                      />
                    )}
                  </>
                )}
                {record.submitStatus === 1 && (
                  <DeleteButton
                    onClick={() => {
                      handleDelete(record.id);
                    }}
                  />
                )}
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}

export default withRouter(Apply);
