import React from 'react';
import Model from './model';
import View from './view';
import Basic from './basiInfo';
import FinancialInfo from './financialInfo';
import FinancingInfo from './financingInfo';
import FinancingIcon from './images/financingIcon.svg';
import FinancialIcon from './images/financialIcon.svg';
import BasicIcon from './images/listIcon.svg';
import Science from './images/science.svg';
import Operation from './images/operation.svg';
import Inctrol from './images/inctrol.svg';
import Creating from './creating';
import Operating from './operating';
import Inctroling from './inctrol';
import Team from './team';

const Routes = [
  {
    path: '/companyInformation/',
    name: '基本信息',
    component: Basic,
    exact: true,
    icon: BasicIcon,
    visible: true,
    key: '/'
  },
  {
    path: '/companyInformation/financial',
    component: FinancialInfo,
    exact: false,
    name: '财务信息',
    icon: FinancialIcon,
    visible: true,
    key: '/financial'
  },
  {
    path: '/companyInformation/financing',
    component: FinancingInfo,
    exact: false,
    name: '融资情况',
    icon: FinancingIcon,
    visible: true,
    key: '/financing'
  },
  {
    path: '/companyInformation/creating',
    component: Creating,
    exact: false,
    name: '科创属性',
    icon: Science,
    visible: true,
    key: '/creating'
  },
  {
    path: '/companyInformation/operating',
    component: Operating,
    exact: false,
    name: '经营信息',
    icon: Operation,
    visible: true,
    key: '/operating'
  },
  {
    path: '/companyInformation/inctrol',
    component: Inctroling,
    exact: false,
    name: '内控规范',
    icon: Inctrol,
    visible: true,
    key: '/inctrol'
  },
  {
    path: '/companyInformation/team',
    component: Team,
    exact: false,
    name: '创始团队',
    icon: Operation,
    visible: true,
    key: '/team'
  }
];

export default function(props) {
  return (
    <Model {...props} Routes={Routes}>
      {prop => {
        return <View {...props} {...prop} />;
      }}
    </Model>
  );
}
