import fetch from 'utils/fetch';
import { API } from 'constants/enum';

const listtrain = 'listtrain/';

// 我的课程
export function getCourse(data) {
    return fetch(`${API.V1}/${listtrain}index/own/course`, data );
}

// 我的专栏
export function getColumn(data) {
    return fetch(`${API.V1}/${listtrain}index/own/column`, data );
}