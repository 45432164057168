/**
 * @author Ray
 */
import request from '../../utils/fetch';
import { API } from '../../constants/enum';

/**
 * @description 修改密码
 * @param {*} confirmPassword
 * @param {*} newPassword
 * @param {*} oldPassword
 */
export function updatePassword(confirmPassword, newPassword, oldPassword) {
  return request(
    `${API.V1}/sso/user/password/update`,
    {
      confirmPassword,
      newPassword,
      oldPassword
    },
    {
      method: 'POST'
    }
  );
}

/**
 * @description 修改过期的密码
 * @param {*} confirmPassword
 * @param {*} newPassword
 * @param {*} oldPassword
 * @param {*} userName
 */
export function updatePasswordThreeMonths({
  confirmPassword,
  newPassword,
  oldPassword,
  userName
}) {
  return request(
    `${API.V1}/sso/user/password/threemonth/update`,
    {
      confirmPassword,
      newPassword,
      oldPassword,
      userName
    },
    {
      method: 'POST'
    }
  );
}

/**
 * @description 更新用户信息
 * @param {object} data
 */
export function updateUser(data) {
  return request(
    `${API.V1}/sso/user/update`,
    {
      ...data
    },
    {
      method: 'POST'
    }
  );
}

/**
 * @description 修改手机号码
 * @param {*} imageCaptcha
 * @param {*} password
 * @param {*} phone
 * @param {*} smsCode
 */
export function updatePhone(imgCaptcha, password, phone, smsCode) {
  return request(
    `${API.V1}/sso/user/phone/update`,
    {
      imgCaptcha,
      password,
      phone,
      smsCode
    },
    {
      method: 'POST'
    }
  );
}

/**
 * @desc 注销账户
 * @param imgCaptcha 图片验证码
 * @param password 密码
 * @param phoneCaptcha 手机验证码
 */
export function resignUser(imgCaptcha, password, phoneCaptcha) {
  return request(
    `${API.V1}/sso/withdraw`,
    {
      imgCaptcha,
      password,
      phoneCaptcha
    },
    {
      method: 'POST'
    }
  );
}

/**
 * @desc 验证密码是否正确
 * @param oldPassoword
 */
export function validateOldPassword(oldPassoword) {
  return request(
    `${API.V1}/sso/user/oldpassword/validate`,
    {
      oldPassoword
    },
    {},
    () => { },
    { withLoading: false }
  );
}

// 验证执业/从业资格证书编号是否存在
export function validateCertificate(certificate) {
  return request(
    `${API.V1}/sso/user/certificate/validate`,
    {
      certificate,
      update: true
    },
    {},
    () => { },
    { withLoading: false }
  );
}

// 验证用户名是否存在
export function validateName(userName) {
  return request(
    `${API.V1}/sso/user/username/validate`,
    {
      userName
    },
    {},
    () => { },
    { withLoading: false }
  );
}

// 验证企业名称是否存在
export function validateCompanyName(companyName) {
  return request(
    `${API.V1}/sso/user/company/validate`,
    {
      companyName
    },
    {},
    () => { },
    { withLoading: false }
  );
}

// 验证手机号是否存在
export function validatePhone(mobile) {
  return request(
    `${API.V1}/sso/user/mobile/validate`,
    {
      mobile
    },
    {},
    () => { },
    { withLoading: false }
  );
}

// 验证邮箱验证码是否正确
export function validateEmailCode(emailCode) {
  return request(
    `${API.V1}/sso/user/email/validate`,
    { emailCode },
    {},
    () => { },
    { withLoading: false }
  );
}

// get user info
export function getLoginUserInfo() {
  return request(`${API.V1}/auth/sys/user/detail`, {}, {}, () => {}, {});
 
}

//設置cookie
export function cookieResponse(cookie) {
  return request(
    `${API.V1}/sso/cookie/response`,
    cookie
    // {
    //     method: 'POST'
    // }
  );
}
