'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

exports.getCenterPoint = getCenterPoint;
exports.setCircleCoords = setCircleCoords;
exports.setLinesCoordsByNodes = setLinesCoordsByNodes;
exports.calcAvgRadian = calcAvgRadian;
exports.radianToAngle = radianToAngle;
exports.angleToRadian = angleToRadian;
exports.positionOffset = positionOffset;
exports.judgeArea = judgeArea;
exports.getBezierPoint = getBezierPoint;

var _common = require('../common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// 获取中心点
function getCenterPoint() {
    return [document.documentElement.clientWidth / 2, document.documentElement.clientHeight / 2];
}
// 根据 node 节点数组创建圈层图坐标集合
function setCircleCoords(nodes, opts) {
    var _Object$assign = (0, _assign2.default)({}, opts),
        target = _Object$assign.target,
        _Object$assign$center = _Object$assign.center,
        center = _Object$assign$center === undefined ? [0, 0] : _Object$assign$center,
        _Object$assign$radius = _Object$assign.radius,
        radius = _Object$assign$radius === undefined ? 20 : _Object$assign$radius,
        _Object$assign$startA = _Object$assign.startAngle,
        startAngle = _Object$assign$startA === undefined ? 0 : _Object$assign$startA,
        _Object$assign$nodeSp = _Object$assign.nodeSpace,
        nodeSpace = _Object$assign$nodeSp === undefined ? 50 : _Object$assign$nodeSp,
        _Object$assign$layerS = _Object$assign.layerSpace,
        layerSpace = _Object$assign$layerS === undefined ? 50 : _Object$assign$layerS,
        _Object$assign$offset = _Object$assign.offset,
        offset = _Object$assign$offset === undefined ? [0, 0] : _Object$assign$offset,
        _Object$assign$counte = _Object$assign.counterClockwise,
        counterClockwise = _Object$assign$counte === undefined ? false : _Object$assign$counte;

    var targetNode;
    if (target) {
        var index = nodes.findIndex(function (node) {
            return node.id === target.id;
        });
        if (index !== -1) {
            targetNode = nodes.splice(index, 1)[0];
            targetNode.position = center;
        }
    }

    if (!counterClockwise) {
        startAngle += 90;
    }

    var startRadian = angleToRadian(startAngle); // 起始弧度
    var currRadian = startRadian; // 当前弧度
    var pastPointCount = 0; // 已计算过的点数量
    var currPointCount = calcAvgPointCount(radius, nodeSpace);

    nodes.forEach(function (node, i) {
        // 当前圈已排满, 扩大半径, 重新计算节点分布数量
        if (i - pastPointCount > currPointCount - 1) {
            // 初始起始弧度
            currRadian = startRadian;
            // TODO: 根据 degree 增加半径
            radius += layerSpace;
            pastPointCount += currPointCount;
            currPointCount = calcAvgPointCount(radius, nodeSpace);
        }
        var x = center[0] + radius * (counterClockwise ? Math.cos(currRadian) : Math.sin(currRadian));
        var y = center[1] - radius * (counterClockwise ? Math.sin(currRadian) : Math.cos(currRadian));
        node.position = [x + offset[0], y + offset[1]];
        if (i < nodes.length - 1) {
            // 计算下一个点的弧度
            currRadian += calcAvgRadian(radius, nodeSpace);
        }
    });

    if (targetNode) {
        nodes.unshift(targetNode);
    }
}

function setLinesCoordsByNodes(lines, nodes) {
    if ((0, _common.isEmpty)(lines) || (0, _common.isEmpty)(nodes)) {
        return;
    }

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        for (var j = 0; j < nodes.length; j++) {
            var node = nodes[j];
            if (line.from === node.id) {
                line.fromNode = node;
            } else if (line.to === node.id) {
                line.toNode = node;
            }

            if (line.fromNode && line.toNode) {
                break;
            }
        }
    }
}

// 根据半径和节点间的弧长, 计算当前圆周上可以均匀分布多少个节点
function calcAvgPointCount(radius, arc) {
    var cir = 2 * Math.PI * radius;
    return Math.floor(cir / arc);
}
// 根据半径和节点间的弧长, 计算节点在当前圆的平均弧度
function calcAvgRadian(radius, arc) {
    return 2 * Math.PI / calcAvgPointCount(radius, arc);
}
// 弧度转角度
function radianToAngle(radian) {
    return 180 / Math.PI * radian;
}
// 角度转弧度
function angleToRadian(angle) {
    return Math.PI / 180 * angle;
}
// 根据 offset 修正坐标位置
function positionOffset(position) {
    var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [0, 0];

    var x, y;
    if ((0, _common.isObject)(position)) {
        x = position.x;
        y = position.y;
        return {
            x: x + offset[0],
            y: y + offset[1]
        };
    }

    if ((0, _common.isArray)(position)) {
        x = position[0];
        y = position[1];
        return [x + offset[0], y + offset[1]];
    }
}

/**
 * @desc 判断相对向限，p2相对p1的向限
 * @param {array} p1 坐标1， [1, 2]
 * @param {array} p2 坐标1， [1, 100]
 * @return {string} 1,12,2,23,3,34,4,41 两位数的表示在边界上
*/
function judgeArea(p1, p2) {
    var x = '';
    if (p1[0] <= p2[0] && p1[1] >= p2[1]) {
        x += 1;
    }
    if (p1[0] >= p2[0] && p1[1] >= p2[1]) {
        x += 2;
    }
    if (p1[0] >= p2[0] && p1[1] <= p2[1]) {
        x += 3;
    }
    if (p1[0] <= p2[0] && p1[1] <= p2[1]) {
        x += 4;
    }
    return x;
}

/**
 * 根据滑动系数获取贝塞尔曲线上点的位置
 * @param {array} p0 起点， [x, y]
 * @param {array} p1 控制点， [x, y]
 * @param {array} p2 终点， [x, y]
 * @param {number} t 滑动系数 0-1 
 * @return {Object} 返回点的坐标位置
*/
function getBezierPoint(p0, p1, p2) {
    var t = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    var x = (1 - t) * (1 - t) * p0[0] + 2 * t * (1 - t) * p1[0] + t * t * p2[0];
    var y = (1 - t) * (1 - t) * p0[1] + 2 * t * (1 - t) * p1[1] + t * t * p2[1];
    return [x, y];
}