/**
 * @desc  大屏地图展示
 */
import React, {useEffect, useRef, useState} from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import {riskAverage} from './service';
import {isNotEmpty} from '../../../../utils/utils';
import getLocalMap from '../service';

export default function Map({address}) {
    const mapSize = {
        all: {
            zoom: window.screen.width < 1920 ? 2.0 : 1.0,
            top: window.screen.width < 1920 ? '80' : '20',
            bottom: window.screen.width < 1920 ? '0' : '10',
        },
        province: {
            zoom: 1.0,
            top: '20',
            bottom: '20',
        }
    };
    const echartsInstance = useRef(null);
    const [addressData, setAddressData] = useState([]);
    const [value, setValue] = useState('');
    const [map, setMap] = useState();
    const [mapsize, setMapSize] = useState('all');
    // 地址变化获取新的地图数据
    useEffect(() => {
        // 请求申请数据，请求地图数据。
        setAddressData([]);

        getLocalMap(address).then(res => {
            echarts.registerMap(address, res);

            if(address == 'all') {
                setMapSize('all')
            } else {
                setMapSize('province')
            }

            riskAverage({area: address})
                .then(res => {
                    if (isNotEmpty(res) && isNotEmpty(res.data.areaAmount)) {
                        let data = res.data.areaAmount.map(item => {
                            const {area, amount} = item;
                            return {
                                name: area,
                                value: amount
                            };
                        });

                        setTimeout(() => {
                            setAddressData(data);
                            setValue(res.data.ipoTotalValue);
                        }, 0);
                    }
                })
                .finally(() => setMap(address));
        })
    }, [address]);

    
    function getOption(address) {
        return {
            title: {
                text: `总市值:${value}万亿元`,
                top: 20,
                left: 20,
                textStyle: {
                    color: '#fff'
                }
            },
            visualMap: {
                type: 'piecewise',
                pieces: [
                    {max: 19, color: "#38c060"},//
                    {min: 20, max: 39, color: "#1A8DF8"},
                    {min: 40, max: 149, color: "#006DA2"},
                    {min: 150, max: 299, color: "#F6BD16"},
                    {min: 300, color: "#E86452"},
                ],
                bottom: 40,
                left: 40,
                textStyle: {
                    color: '#fff'
                }
            },
            toolbox: {
                show: true,
                orient: 'vertical',
                left: 'right',
                top: 'center',
            },
            roamController: {
                show: true,
                left: 'right',
                mapTypeControl: {
                    'china': true
                }
            },
            series: [
                {
                    type: 'map',
                    map: map,
                    roam: false,
                    label: {
                        show: address == 'all' ? true : false,
                        color: 'rgb(249, 249, 249)'
                    },
                    data: addressData,
                    showLegendSymbol: false,
                    zoom: mapSize[mapsize].zoom,
                    top: mapSize[mapsize].top,
                    bottom: mapSize[mapsize].bottom,
                    emphasis: {
                        itemStyle: {
                            areaColor: '#1AF2FF'
                        }
                    },
                }
            ]
        }
    }

    return (
        <div style={{ height: '62vh', paddingTop: '150px' }}>
            <ReactEcharts
                option={getOption(address)}
                notMerge={true}
                ref={echartsInstance}
                style={{height: '100%'}}
            />
        </div>
    );
}

Map.defaultProps = {
    address: 'all'
};
