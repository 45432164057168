import React, { useState } from 'react';
import { branchOfficeColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { queryFzjg } from './service';
import { isNotEmpty } from '../../../../utils/utils';

export default function BranchOffice({ match }) {
    const [query] = useState({ qyxxId: match.params.companyId });

    function mockData(res) {
        return isNotEmpty(res) ? res.data : [];
    }

    return (
        <TableEx
            query={query}
            getData={queryFzjg}
            parseData={mockData}
            columns={branchOfficeColumns}
        />
    );
}
