import moment from 'moment';
import { TimeFormat } from '../../../../../constants/enum';
import { LimitNumber, transferStringNull } from '../../../../../utils/utils';
import React from 'react';
import { Tooltip } from 'antd';

export const PatentColumns = [
    {
        key: 'main_classcode',
        dataIndex: 'main_classcode',
        title: '专利对应大类',
        align: 'center',
        width: '70%',
        render: transferStringNull
    },
    {
        key: 'count',
        dataIndex: 'count',
        title: '数量',
        align: 'center',
        width: '30%',
        render: transferStringNull
    }
];

export const PatentsColumns = [
    {
        dataIndex: 'title',
        title: '专利名称',
        align: 'center',
        width: '15%',
        render: text => <Tooltip title={text}>{LimitNumber(text, 15)}</Tooltip>
    },
    {
        dataIndex: 'applicationCode',
        title: '申请号',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'applicant',
        title: '专利申请人',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'patentType',
        title: '专利类型',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'classCode',
        title: 'IPC大类',
        align: 'center',
        width: '15%',
        render: text =>
            text === 'null' ? (
                transferStringNull(text)
            ) : (
                <Tooltip title={text}>{LimitNumber(text, 15)}</Tooltip>
            )
    },
    {
        dataIndex: 'publidate',
        title: '公告时间',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        dataIndex: 'inventor',
        title: '发明人',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

export const SoftwareColumns = [
    {
        dataIndex: 'softFullName',
        title: '名称',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        dataIndex: 'regnum',
        title: '登记号',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'classCode',
        title: '分类号',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'versionNum',
        title: '版本',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'regdate',
        title: '登记时间',
        align: 'center',
        width: '15%',
        render: text => moment(text).format(TimeFormat)
    }
];

export const BrandsColumns = [
    {
        dataIndex: 'brandImage',
        title: '商标',
        align: 'center',
        width: '30%',
        render: (text, record) => (
            <img
                className="table-image"
                // data:image/png;base64,
                src={`${text}`}
                alt={record.brandName}
            />
        )
    },
    {
        dataIndex: 'brandName',
        title: '名称',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        dataIndex: 'applicationNo',
        title: '注册号',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'classBrand',
        title: '类别',
        align: 'center',
        width: '20%',
        render: transferStringNull
    }
];

export const PaperWorksColumns = [
    {
        dataIndex: 'productionName',
        title: '名称',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        dataIndex: 'regnum',
        title: '登记号',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        dataIndex: 'creationCompletionString',
        title: '创作完成日期',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'regdate',
        title: '登记时间',
        align: 'center',
        width: '20%',
        render: transferStringNull
    }
];
