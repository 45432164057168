import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import getLocalMap from '../service';
import { getAreaDistribution } from './service';
import { isNotEmpty } from '../../../utils/utils';
import mapPic from '../images/map.png';

/**
 * @desc 大屏地图
 * @returns {*}
 * @constructor
 */

const mapSize = {
    big: { bottom: '15%', top: '15%' },
    small: { bottom: '25%', top: '25%' },
    lower: { bottom: '10%', top: '25%' },
    topper: { bottom: '25%', top: '10%' },
    bigger: {
        bottom: '5%',
        top: '5%'
    },
    smaller: {
        bottom: '25%',
        top: '35%'
    },
    lowerer: {
        top: '45%',
        bottom: '5%'
    }
};

export default function BigScreenMap({ style, address }) {
    const echartsInstance = useRef(null);
    const [addressData, setAddressData] = useState([]);
    const [map, setMap] = useState();
    const [mapsize, setMapSize] = useState('big');
    // 地址变化获取新的地图数据
    useEffect(() => {
        // 请求申请数据，请求地图数据。
        setAddressData([]);

        getLocalMap(address).then(res => {
            echarts.registerMap(address, res);
            let coordies = res.features.reduce((acc, feature) => {
                acc[feature.properties.name] = feature.properties.cp;
                return acc;
            }, {});

            if (['辽宁', '福建', '河南', '广东'].includes(address)) {
                setMapSize('topper');
            } else if (['吉林'].includes(address)) {
                setMapSize('lower');
            } else if (['天津'].includes(address)) {
                setMapSize('bigger');
            } else if (address === '甘肃') {
                setMapSize('smaller');
            } else if (address === '黑龙江') {
                setMapSize('lowerer');
            } else {
                setMapSize('big');
            }

            getAreaDistribution(address)
                .then(res => {
                    if (isNotEmpty(res) && isNotEmpty(res.data)) {
                        let data = res.data.map(area => {
                            const { areaName, num } = area;
                            return {
                                name: areaName,
                                value: [
                                    ...(coordies[areaName] || [0, 0]),
                                    num,
                                    areaName
                                ]
                            };
                        });

                        setTimeout(() => {
                            setAddressData(data);
                        }, 0);
                    }
                })
                .finally(() => setMap(address));
        });
    }, [address]);

    function getOptions() {
        return {
            geo: [
                // 展示的地图
                {
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    z: 2,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#4495BC',
                        shadowBlur: 0,
                        shadowOffsetY: 0,
                        borderWidth: 0.5
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: '#1AF2FF'
                        }
                    },
                    ...mapSize[mapsize]
                },
                // 第二个地图，用于突出边框和边界阴影效果。
                {
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    z: 1,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#1AF2FF',
                        borderWidth: 3,
                        shadowBlur: 2,
                        shadowColor: '#1E6FB5',
                        shadowOffsetY: 15
                    },
                    ...mapSize[mapsize]
                }
            ],
            tooltip: {
                trigger: 'item',
                backgroundColor: 'rgba(16,70,118,0.68)',
                borderColor: '#0293DC',
                textStyle: {
                    color: '#fff'
                },
                formatter: params => {
                    return params?.data?.name
                        ? `${params?.data?.name}<br/> ${params?.data
                              ?.value[2] || 0} 家企业`
                        : '暂无数据';
                }
            },
            series: [
                // 地图
                {
                    type: 'map',
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    geoIndex: 0,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    data: addressData,
                    ...mapSize[mapsize]
                },
                {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    dimensions: ['long', 'lat', 'value', 'address'],
                    hoverAnimation: true,
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    symbolSize: function(value) {
                        return value[2] > 20 ? 20 : value[2];
                    },
                    itemStyle: {
                        normal: {
                            shadowBlur: 5,
                            color: '#FDDE62',
                            shadowColor: '#333'
                        }
                    },
                    data: addressData
                }
            ]
        };
    }

    return (
        <div style={{ ...style }}>
            <ReactEcharts
                notMerge
                theme={'myTheme'}
                ref={echartsInstance}
                option={getOptions()}
                style={{ height: '100%' }}
            />
            {
                address === '全国' ? 
                <div style={{ 
                    width: '66px',
                    height: '95px', 
                    position: 'absolute',
                    bottom: '48%',
                    left: '65%',
                    background: `url(${mapPic})`, 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%', 
                }}>
                </div> : ''
            }
        </div>
    );
}

BigScreenMap.defaultProps = {
    style: {},
    address: '全国'
};
