/**
 * @author Ray
 * @desc 社会责任评价-公益捐赠
 */

import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState
} from 'react';
import Table from '../../../../common/tableEx';
import { donationColumns } from './columns';
import { EditButton, DeleteButton } from '../../../../common/buttons';
import Title from '../../../../common/title';
import { Button, DatePicker, Form, Input, InputNumber, Empty } from 'antd';
import { GreaterThanZro, Required } from '../../../../utils/validator';
import { addEditDonation, delDonation, showDonations } from './service';
import { isNotEmpty, isEmpty } from '../../../../utils/utils';
import useActions from './actionHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import moment from 'moment';

export function DonationForm({ form }, ref) {
    useImperativeHandle(ref, () => ({
        form
    }));
    return (
        <Form labelCol={{ xs: 6 }} wrapperCol={{ xs: 15 }}>
            <Form.Item label="捐赠对象">
                {form.getFieldDecorator('donationTarget', {
                    rules: [Required]
                })(
                    <Input.TextArea
                        autosize={{
                            maxRows: 6,
                            minRows: 4
                        }}
                        maxLength={500}
                    />
                )}
            </Form.Item>
            <Form.Item label="捐赠金额">
                {form.getFieldDecorator('donationMoney', {
                    rules: [GreaterThanZro, Required]
                })(<InputNumber placeholder="请输入" maxLength={9} />)}
            </Form.Item>
            <Form.Item label="捐赠时间">
                {form.getFieldDecorator('donationTime', {
                    rules: [GreaterThanZro, Required]
                })(<DatePicker disabledDate={current => current && current > moment().endOf('day')} />)}
            </Form.Item>
        </Form>
    );
}

export default function Donation({ dispatch, match }) {
    const [showList, setShowList] = useState(true);
    const gs = useContext(GlobalContext);
    const [isOwnCompany] = useState(
        gs.user?.organization === match.params.companyName
    );
    const [addRecord, editRecord, delRecord, , query] = useActions({
        dispatch,
        query: { companyName: match.params.companyName },
        update: addEditDonation,
        remove: delDonation,
        create: addEditDonation,
        form: forwardRef(DonationForm),
        callback: () => {
            setShowList(true);
        }
    });

    function parseData(res) {
        if (isNotEmpty(res)) {
            if (isEmpty(res.data)) {
                setShowList(false);
                return [];
            } else {
                setShowList(true);
                return res.data;
            }
        }
        return [];
    }

    return (
        <>
            <Title level={2} text="公益捐赠">
                {isOwnCompany && (
                    <>
                        <p style={{ flex: 1 }} />
                        <Button
                            type="primary"
                            onClick={addRecord('添加公益捐赠')}
                        >
                            新增
                        </Button>
                    </>
                )}
            </Title>
            {showList ? (
                <Table
                    query={query}
                    columns={
                        isOwnCompany
                            ? donationColumns.concat({
                                  title: '',
                                  align: 'center',
                                  render: (text, record) => (
                                      <>
                                          <EditButton
                                              text="编辑"
                                              onClick={editRecord(record)(
                                                  '编辑公益捐赠'
                                              )}
                                          />
                                          <DeleteButton
                                              onClick={delRecord(record)(
                                                  '删除公益捐赠'
                                              )}
                                          />
                                      </>
                                  )
                              })
                            : donationColumns
                    }
                    getData={showDonations}
                    parseData={parseData}
                    showPagination={10}
                />
            ) : (
                <Empty description="该中介机构暂未维护自身社会责任评价" />
            )}
        </>
    );
}
