import React from 'react';
import { getSscIndustry, getFinancingIndustry, getBaseInfos } from './service';
import { isEmpty } from '../../../utils/utils';

export default class extends React.Component {
    // componentDidMount() {}

    /**
     * @description 获取国民经济行业分类小类
     * @param srcIndustry
     * @returns {*}
     */
    getSscIndustry = srcIndustry => {
        return getSscIndustry(srcIndustry).then(res => {
            if (isEmpty(res)) {
                return;
            }
            return res;
        });
    };
    /**
     * @description 获取证监会行业
     * @param srcIndustry
     * @returns {*}
     */
    getFinancingIndustry = srcIndustry => {
        return getFinancingIndustry(srcIndustry).then(res => {
            if (isEmpty(res)) {
                return;
            }
            return res;
        });
    };

    /**
     * @desc 提交企业基本信息修改
     * @param code
     * @returns {*}
     */
    getBaseInfo = code => {
        return getBaseInfos(code).then(function(res) {
            return res?.data;
        });
    };

    render() {
        return this.props.children({
            ...this.props,
            ...this.state,
            getBaseInfo: this.getBaseInfo,
            getSscIndustry: this.getSscIndustry,
            getFinancingIndustry: this.getFinancingIndustry
        });
    }
}
