"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Created by Tirion on 2017/12/6.
 */

// 圆面积公式
var circleArea = exports.circleArea = function circleArea(r) {
  return Math.pow(Math.PI * r, 2);
};
// 圆周长
var circlePerimeter = exports.circlePerimeter = function circlePerimeter(r) {
  return 2 * Math.PI * r;
};
// 正方形面积
var rectArea = exports.rectArea = function rectArea(side) {
  return Math.pow(side, 2);
};
// 角度转弧长
var angleToArclength = exports.angleToArclength = function angleToArclength(angle, r) {
  return angle * Math.PI * 2 * r / 360;
};
// 计算一个节点所占的角度的一半
var halfAngle = exports.halfAngle = function halfAngle(minR, nodeR) {
  return Math.acos((Math.pow(minR, 2) + Math.pow(minR, 2) - Math.pow(nodeR, 2)) / (2 * minR * minR)) * 180 / Math.PI;
};