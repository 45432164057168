/**
 * @author hjie
 */
import style from './style.scss';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from './login';
import Register from './register';
import ForgetPsw from './forgetPsw';
import ComplaintAccount from './complaintAccount';
import Protocol from './protocol';
import Welcome from './welcome';
/**
 * @desc 用户页
 */

export const Routes = [
    {
        // 登陆
        path: 'login',
        component: Login,
        exact: false
    },
    {
        // 注册
        path: 'register',
        component: Register,
        exact: false
    },
    {
        // 忘记密码
        path: 'forgetPsw',
        component: ForgetPsw,
        exact: false
    },
    {
        // 账号申诉
        path: 'complaintAccount/:flag',
        component: ComplaintAccount,
        exact: true
    },
    {
        // 使用协议
        path: 'protocol',
        component: Protocol,
        exact: false
    },
    {
        // 欢迎页
        path: 'welcome',
        component: Welcome,
        exact: false
    }
];

export default function(props) {
    const { match } = props;
    
    return (
        <div className={props.location.pathname === '/user/login' ? style.users : style.user}>
            <Switch>
                {Routes.map(o => (
                    <Route
                        key={o.path}
                        exact={o.exact}
                        path={`${match.url}/${o.path}`}
                        render={prop => <o.component {...prop} />}
                    />
                ))}
                {/* <Redirect
                    to={{
                        pathname: `${match.url}/login`,
                        state: { from: match.url }
                    }}
                /> */}
            </Switch>
        </div>
    );
}
