'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _StockChart = require('./StockChart');

var _StockChart2 = _interopRequireDefault(_StockChart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _StockChart2.default;
module.exports = exports['default'];