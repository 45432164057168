'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _uuid = require('../util/uuid');

var _uuid2 = _interopRequireDefault(_uuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Plugin = function () {
    function Plugin() {
        var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _uuid2.default)();
        var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        (0, _classCallCheck3.default)(this, Plugin);

        this.id = id;
        this.options = opts;
    }

    (0, _createClass3.default)(Plugin, [{
        key: 'beforeApply',
        value: function beforeApply() {}
    }, {
        key: 'apply',
        value: function apply() {}
    }, {
        key: 'afterApply',
        value: function afterApply() {}
    }, {
        key: 'dispose',
        value: function dispose() {
            this.id = null;
            this.root = null;
            this.dom = null;
            this.container = null;
            this.options = null;
            this.handler = null;
            this.store = null;
            this.painter = null;
        }
    }, {
        key: 'setDom',
        value: function setDom(dom) {
            this.dom = dom;
        }
    }, {
        key: 'setContainer',
        value: function setContainer(container) {
            this.container = container;
        }
    }, {
        key: 'getDom',
        value: function getDom() {
            return this.dom;
        }
    }, {
        key: 'getContainer',
        value: function getContainer() {
            return this.container;
        }
    }, {
        key: 'getId',
        value: function getId() {
            return this.id;
        }
    }]);
    return Plugin;
}(); /**
      * 图谱插件抽象类
      */


exports.default = Plugin;
module.exports = exports['default'];