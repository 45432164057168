import React, { useEffect } from 'react';
import Project from './project';
import Title from '../../../common/title';
import ExpertGroupCard from './expertGroupCard';
import styles from '../expertIndex/styles.scss';
import BackBtn from '../../../common/backBtn/index';

export default function ExpertGroupDetail(props) {
    useEffect(() => {
        props.setBannerShow(false);
    }, []);
    return (
        <div className={styles.expertList}>
            {/*<div style={{ marginBottom: 10 }}>*/}
                <BackBtn history={props.history} />
                <br/>
                <br/>
            {/*</div>*/}
            <ExpertGroupCard detail={props.location.state} />
            <br/>
            <Project detail={props.location.state} />
        </div>
    );
}
