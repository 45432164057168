/**
 * @author Ray
 * @desc 入库企业分析-入库企业列表-搜索组件
 */

import React, { useContext, useRef, useState } from 'react';
import styles from './styles.scss';
import { Collapse, Typography, Icon } from 'antd';
import Search from '../common/Search';
import { columns } from './columns';
import SearchConditions from './searchConditions';
import TableEx from '../../../common/tableEx';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import { mergeArrayToObject } from '../intermediaryAnalysis/view';
import { getCompanyList } from './service';
import { Permission } from '../../../hooks/useAuth';
import { GlobalContext } from '../../../common/globalStore/createProvider';

const keyMap = {
  regcap: ['minRegcap', 'maxRegcap'],
  year: ['minYear', 'maxYear']
};

export const VipKeys = ['valuation', 'kechuangScore', 'fengxianScore'];

export default function() {
  const [query, setQuery] = useState(null);
  const searchRef = useRef(null);
  const [lock, setLock] = useState(true);
  const { isVIPUser, isEnterprise, isIntermediary } = useContext(GlobalContext);

  function mockData(raw) {
    return query
      ? isNotEmpty(raw) && isNotEmpty(raw.data)
        ? raw.data
        : []
      : [];
  }

  // 搜索框变化
  function handleSearch(value) {
    setLock(false);
    if (isEmpty(searchRef.current)) {
      return setQuery({ ...query, sign: 1, companyName: value });
    }
    searchRef.current.form.validateFields((errors, values) => {
      if (isEmpty(errors)) {
        let conditions = parseValues(values);

        // const { minRegcap, maxRegcap, minYear, maxYear } = conditions;

        // if (
        //     isNotEmpty(minRegcap) &&
        //     isNotEmpty(maxRegcap) &&
        //     minRegcap > maxRegcap
        // ) {
        //     message.error('起始注册资本金额不能大于结束注册资本金额！');
        // }
        //
        // if (
        //     isNotEmpty(minYear) &&
        //     isNotEmpty(maxYear) &&
        //     minYear > maxYear
        // ) {
        //     message.error('起始注册时间不能大于结束注册时间！');
        // }

        return setQuery({
          ...query,
          ...conditions,
          sign: 1,
          companyName: value
        });
      }
    });
  }
  // 将groupInput的值(array)转化成object
  function parseValues(allValues) {
    let values = {};
    for (let key in allValues) {
      if (allValues.hasOwnProperty(key)) {
        switch (key) {
          case 'regcap':
          case 'year': {
            let value = allValues[key];
            if (isNotEmpty(value)) {
              values = mergeArrayToObject(value, keyMap[key], values);
            }
            delete values[key];
            break;
          }
          case 'companyProvince': {
            values[key] = allValues[key][0]; // 永远会有第一个值
            values['areaCode'] = allValues[key][1]; // 单选就是undefined
            break;
          }
          default:
            let value = allValues[key];
            values[key] = value;
            break;
        }
      }
    }
    return values;
  }

  return (
    <Permission permission={497}>
      <div className={styles.root}>
        <Typography.Title className="textCenter">
          专注于北京双创企业上市服务
        </Typography.Title>
        <Search onSearch={handleSearch} placeholder="请输入企业名称关键字" />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel forceRender key={1} header="条件搜索">
            <SearchConditions elementRef={searchRef} />
          </Collapse.Panel>
        </Collapse>
        <div
          style={{
            marginTop: 32,
            marginBottom: 32,
            display: lock ? 'none' : 'block'
          }}
        >
          <TableEx
            lock={!Boolean(query)}
            query={query}
            parseData={mockData}
            getData={getCompanyList}
            columns={
              (isIntermediary || isEnterprise) && !isVIPUser
                ? columns.map(function(item) {
                    if (VipKeys.includes(item.dataIndex)) {
                      return {
                        ...item,
                        render: () => {
                          return '仅限VIP用户';
                        }
                      };
                    }
                    return item;
                  })
                : columns
            }
          />
        </div>
      </div>
    </Permission>
  );
}
