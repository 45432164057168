'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _common = require('../../../util/processor/common');

var _config = require('../../../config');

var _config2 = _interopRequireDefault(_config);

var _processor = require('../../../util/processor/processor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NaturalChart = function (_Chart) {
    (0, _inherits3.default)(NaturalChart, _Chart);

    function NaturalChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, NaturalChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (NaturalChart.__proto__ || (0, _getPrototypeOf2.default)(NaturalChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'natural';

        _this.process = function () {
            var state = _this.getState();
            _this.centerId = (0, _common.getTarget)(state).id;
            _this.updateNode(state);
        };

        _this.reRender = function (info) {
            _this.centerId = info.id;
            _this.date = info.date;
            _this.store.loadNewState({
                bbdQyxxId: info.id,
                degree: info.degree,
                date: info.date,
                isHistory: info.isHistory
            }, 'init').then(function (newState) {
                if (newState) {
                    _this.updateNode(newState);
                }
            });
        };

        _this.path = function (node) {
            var state = _this.getState();
            var gridState = _this.store.toGridState(state, _this.centerId);

            var newOrginData = _this.newOrginData || _this.store.getOriginData();
            var path = (0, _processor.getShortPathById)(node.id, _this.centerId, gridState, newOrginData);

            _this.setHighLight(path, gridState);
            _this.renderNode(gridState);
        };

        _this.highlight = function (node) {
            var state = _this.getState();
            if (state && state.nodes && state.nodes.length > 0) {
                state.nodes.map(function (n) {
                    return n.animation = false;
                });
                state.links.map(function (n) {
                    return n.animation = false;
                });
                var gridState = _this.store.toGridState(state, _this.centerId);
                if (node && node.shapeType === 'node') {
                    var highLight = (0, _processor.getHightLight)(node.id, gridState);
                    _this.setHighLight(highLight, gridState);
                } else {
                    gridState.nodes.map(function (n) {
                        return n.slight = false;
                    });
                    gridState.links.map(function (n) {
                        return n.slight = false;
                    });
                }
                _this.renderNode(gridState);
            }
        };

        _this.setHighLight = function (newPoint, gridState) {

            gridState.nodes.map(function (n) {
                return n.slight = true;
            });
            gridState.links.map(function (n) {
                return n.slight = true;
            });

            newPoint.nodes.map(function (n) {
                return n.slight = false;
            });
            newPoint.links.map(function (n) {
                return n.slight = false;
            });

            gridState = _this.store.mergeState(gridState, newPoint);
        };

        _this.deletePoint = function (node, del) {
            var gridState = _this.store.toGridState(_this.getState(), _this.centerId);
            var newPoints = null;
            if (del) {
                // 删除
                newPoints = (0, _processor.delByIds)([node.id], gridState, _this.centerId);
            } else {
                // 收起
                newPoints = (0, _processor.flodByIds)([node.id], gridState, _this.centerId);
            }
            _this.handlePoint(newPoints, node);
        };

        _this.handlePoint = function (point, node) {
            var state = _this.getState();
            var gridState = _this.store.toGridState(state, _this.centerId);

            gridState.nodes.map(function (n) {
                n.animation = true;
                n.slight = false;
            });
            gridState.links.map(function (n) {
                n.animation = true;
                n.slight = false;
            });

            _this.setState(gridState);
            point.nodes.filter(function (n) {
                if (gridState.nodesKV[n]) {
                    gridState.nodesKV[n].hidden = true;
                }
            });
            point.links.filter(function (n) {
                if (gridState.linksKV[n]) {
                    gridState.linksKV[n].hidden = true;
                }
            });
            var nodes = gridState.nodes.filter(function (n) {
                return !gridState.nodesKV[n.id].hidden;
            });
            var links = gridState.links.filter(function (n) {
                return !gridState.linksKV[n.id].hidden;
            });
            nodes.map(function (m) {
                if (m.id === node.id) {
                    m.expanded = false;
                }
            });
            _this.renderNode({ nodes: nodes, links: links });
        };

        _this.seek = function (info) {
            _this.store.loadNewState({
                nodeIds: info.nodeIds,
                nodeTypes: info.nodeTypes,
                seekType: 'INVEST_MANAGE'
            }, 'init', 'seek').then(function (extensionState) {
                if (extensionState && extensionState.nodes && extensionState.nodes.length > 0) {
                    var state = _this.getState();
                    state.nodes.map(function (n) {
                        return n.slight = true;
                    });
                    state.links.map(function (n) {
                        return n.slight = true;
                    });

                    extensionState.nodes.map(function (n) {
                        return n.slight = false;
                    });
                    extensionState.links.map(function (n) {
                        return n.slight = false;
                    });

                    _this.newOrginData = _this.store.mergeState(state, extensionState);
                    var gridState = _this.store.toGridState(_this.newOrginData, _this.centerId);

                    _this.setPostion(gridState, _this.info);
                    _this.renderNode(gridState);
                }
            });
        };

        _this.extend = function (node) {
            _this.store.loadNewState({
                bbdQyxxId: node.id,
                degree: 1,
                type: node.data.human ? 1 : 2,
                date: _this.date
            }, 'extension').then(function (newState) {
                // 设置起始位置
                newState.nodes.forEach(function (newNode) {
                    newNode.startPoint = node.parent.position;
                    newNode.startPoint = node.parent.position;
                });
                newState.nodes.map(function (m) {
                    if (m.id === node.id) {
                        m.expanded = true;
                    }
                });
                _this.newOrginData = _this.store.mergeState(_this.getState(), newState);
                _this.updateNode(_this.newOrginData);
            });
        };

        _this.renderNode = function (gridState) {
            _this.setState(gridState);
            _this.handler.trigger('chart.changeList', gridState);
            _this.handler.trigger('chart.getSettingStatus');
            _this.handler.trigger('chart.resetFilterSetting');
        };

        _this.updateNode = function (state) {
            if (state && state.nodes && state.nodes.length > 0) {
                state.nodes.map(function (n) {
                    return n.slight = false;
                });
                state.links.map(function (n) {
                    return n.slight = false;
                });
                var gridState = _this.store.toGridState(state, _this.centerId);
                _this.setPostion(gridState, _this.info);
                _this.renderNode(gridState);
            } else {
                _this.setState({});
            }
        };

        _this.setPostion = function (gridData, info) {
            var center = info.center,
                size = info.size;
            var nodesGroup = gridData.nodesGroup;
            var nodeSpace = size.nodeSpace,
                degreeSpace = size.degreeSpace,
                nodeSize = size.nodeSize,
                layerSpace = size.layerSpace;


            var yAxis = center[1] / 4; // 目标节点(中心点)y轴位置
            var yStartAxis = yAxis;

            // 根据画布宽度获取一排能排多少个点
            var maxCount = Math.floor(center[0] * 2 / (nodeSize + nodeSpace)) + 2;

            for (var degree in nodesGroup) {
                // 按key值取数据
                if (degree === 0) {
                    nodesGroup[degree].map(function (item) {
                        item.position = [center[0], yAxis];
                    });
                } else {
                    var currentNodes = nodesGroup[degree];
                    var len = currentNodes.length;
                    yStartAxis += degreeSpace;

                    var line = Math.ceil(len / maxCount);

                    for (var lineIndex = 0; lineIndex < line; lineIndex++) {
                        if (lineIndex >= 0) {
                            yStartAxis += layerSpace;
                        }
                        var leftxAxis = lineIndex % 2 === 0 ? center[0] : center[0] - nodeSpace / 2; // 奇偶排错开排列
                        var rightxAxis = leftxAxis;
                        var newNodes = currentNodes.splice(0, maxCount);
                        for (var newIndex = 0; newIndex < newNodes.length; newIndex++) {
                            if (newIndex % 2 === 0) {
                                // 从中心点开始一左一右排列
                                newNodes[newIndex].position = [leftxAxis, yStartAxis];
                                leftxAxis = leftxAxis - nodeSpace;
                            } else {
                                newNodes[newIndex].position = [rightxAxis + nodeSpace, yStartAxis];
                                rightxAxis = rightxAxis + nodeSpace;
                            }
                        }
                    }
                    nodesGroup[degree] = currentNodes;
                }
            }
        };

        _this.info = {
            center: _this.getCenterPoint(),
            size: {
                layerWidth: opts.layerWidth || 500,
                nodeSize: _config2.default.node.style.target.width, // 节点尺寸
                nodeSpace: opts.nodeSpace || 85 + _config2.default.node.style.target.width, // 节点间的间距
                layerSpace: opts.layerSpace || 20 + _config2.default.node.style.target.height, // 相同维度的节点排满后半径的增长值
                degreeSpace: opts.degreeSpace || 40 + _config2.default.node.style.target.height // 不同维度
            }
        };
        _this.centerId = null;
        _this.newOrginData = null;
        _this.date = '';
        return _this;
    }

    (0, _createClass3.default)(NaturalChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(NaturalChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(NaturalChart.prototype), 'beforeProcess', this).call(this);
        }
    }, {
        key: 'afterProcess',


        // 监听事件或行为的最佳位置
        value: function afterProcess() {
            // 行为绑定
            this.mouseEvent();
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            (0, _get3.default)(NaturalChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(NaturalChart.prototype), 'dispose', this).call(this);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('dblclick', this.handleExtension);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);

            this.painter = null;
            this.store = null;
            this.handler = null;
            this.newOrginData = null;
        }
    }, {
        key: 'mouseEvent',
        value: function mouseEvent() {
            var _this2 = this;

            (0, _get3.default)(NaturalChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(NaturalChart.prototype), 'handleDrag', this).call(this);

            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);
            // 扩展
            this.handler.on(this.type + '.extension', function (node) {
                if (node && node.shapeType !== 'node') {
                    return;
                }
                _this2.extend(node);
            });
            // 隐藏
            this.handler.on(this.type + '.hide', function (node) {
                _this2.deletePoint(node, true);
            });
            // 探寻
            this.handler.on(this.type + '.seek', function (info) {
                _this2.seek(info);
            });
            // 收起
            this.handler.on(this.type + '.packup', function (node) {
                if (node && node.shapeType !== 'node') {
                    return;
                }
                _this2.deletePoint(node);
            });
            // 高亮
            this.handler.on(this.type + '.highlight', function (node) {
                _this2.highlight(node);
            });
            // 路径
            this.handler.on(this.type + '.path', function (node) {
                _this2.path(node);
            });
            // 度数切换
            this.handler.on(this.type + '.reRender', function (info) {
                if (info.reset) {
                    _this2.resetTransform();
                }

                _this2.reRender(info);
            });
        }

        /**
         * 度数切换
         */


        /**
         * 路径 
         */


        /**
         * 高亮 
         */


        /**
         * 设置高亮
         */


        /**
         * 移除点
         */


        /**
         * 点处理
         */


        /**
         * 探寻
         */


        /**
         * 扩展
         */


        /**
         * 渲染
         */


        /**
         * 设置点坐标
         * @param {object} gridData 节点数据
         * @param {object} info 图形尺寸 {
            layerWidth   画布宽度(自定义)
            nodeSize     节点尺寸
            nodeSpace    节点间的间距
            layerSpace   相同维度的节点排满后半径的增长值
            degreeSpace  不同维度间距
         }
         */

    }]);
    return NaturalChart;
}(_Chart3.default); /**
                     * 核心自然人分析图
                     */


exports.default = NaturalChart;
module.exports = exports['default'];