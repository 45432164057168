/**
 * @author Ray
 */

import React from 'react';
import Title from '../../../common/title';
import styles from './styles.scss';
import { Divider } from 'antd';
import AccountForm from './accountForm';

export default function(props) {
    return (
        <section className={styles.messageContentRoot}>
            <Title classes={{ root: styles.titleFont }} text={'修改账户信息'} />
            <Divider />
            <AccountForm onSubmit={props.updateUser} />
        </section>
    );
}
