/**
 * @desc 顶部title
 * @constructor
 */
import React, { useContext } from 'react';
import { Input } from 'antd';
import { Avatar, Icon, Typography } from 'antd';
import moment from 'moment';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import { LimitNumber } from "../../../../utils/utils";
import { Link } from 'react-router-dom';
import LogoutSvg from '../images/exit.svg';
import headerPic from '../images/home-white_01.png';
import styles from './styles.scss';

const { Search } = Input;

export default function TopTitle() {
    const { user, setUser } = useContext(GlobalContext);
    
    return (
        <div className={ styles.headers }
            style={{ 
                background: `url(${headerPic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%', 
            }}
        >
            {/* <div className={ styles.searchBox }>
                <Search placeholder="搜索你需要的内容" size='middle' enterButton />
            </div> */}
            <div className={ styles.topTitle }>
                新三板市场总貌
            </div>
            <div>
                <Link
                    to={
                    user?.needChangePwd
                        ? '/userCenter/modifyPassword'
                        : '/userCenter/modifyUser'
                    }
                >
                    <Avatar 
                        className={ styles.avatar }
                        icon={!user?.avatar && 'user'}
                        src={user?.avatar ? user?.avatar : undefined}
                        size={40}
                    />
                </Link>
                <Typography.Text className={styles.userName}>
                    {LimitNumber(user?.username, 5)} <br /> 欢迎您使用!
                </Typography.Text>
                <div className={ styles.line }>|</div>
                <div className={ styles.time }>
                    {moment().format('YYYY-MM-DD')}&nbsp;&nbsp;&nbsp;&nbsp;
                    {
                        moment().format('d') == 1 ? <span>星期一</span> : 
                        moment().format('d') == 2 ? <span>星期二</span> :
                        moment().format('d') == 3 ? <span>星期三</span> :
                        moment().format('d') == 4 ? <span>星期四</span> :
                        moment().format('d') == 5 ? <span>星期五</span> :
                        moment().format('d') == 6 ? <span>星期六</span> :
                        moment().format('d') == 0 ? <span>星期日</span> : ''
                    }
                </div>
                <Link to='/user/login'>
                    <Icon
                        component={LogoutSvg}
                        className={ styles.logout }
                    /> 
                </Link>   
            </div>
        </div>
    )
}
