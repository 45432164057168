import { useState, useEffect } from 'react';
import { getNotes, deleteNotes, saveNotes, companyTop, focusCompany } from './service';
import { message, Modal } from 'antd';
import request from 'utils/fetch';
import { API } from 'constants/enum';
const { confirm } = Modal;

export default function (qyxxId, companyName) {

    const [notes, setNodes] = useState({ total: 0, list: [] }); // 处置列表
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState(''); // 处置记录输入框value
    const [pageNum, setPagination] = useState(1);
    const [companyInfo, setCompanyInfo] = useState(null); // 公司基本信息
    const [year, setYear] = useState(''); // 年份选择

    // 处置列表
    function getNoteList() {
        getNotes({
            pageNum,
            qyxxId,
            pageSize: 10
        }).then(res => {
            if (res.success && res.data) {
                const { data, totalCount } = res.data;
                setNodes({ total: totalCount, list: data });
            }
        });
    }

    // 新增处置
    function addNote(param) {
        const { content } = param;
        if (!content) {
            return;
        }
        saveNotes(param).then(res => {
            if (res.success) {
                setContent('');
                getNoteList();
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    }

    /**
     * @description 删除处置
     */
    function deleteItem(id) {
        confirm({
            title: '确认删除吗',
            content: '',
            cancelText: '取消',
            okText: '确定',
            onOk() {
                confirmDelete(id);
            }
        });
    }

    function confirmDelete(id) {
        deleteNotes({ id }).then(function (res) {
            if (res.success) {
                message.success(res.message);
                if (notes.list.length < 2) { // 当列表只有一个的时候删除之后， 要请求上一页的数据
                    if (pageNum == 1) {
                        getNoteList();
                    } else {
                        setPagination(pageNum - 1);
                    }
                } else {
                    getNoteList();
                }

            } else {
                message.error(res.message);
            }
        });
    }

    // 公司基本信息
    function getCompany(notUpdate) {
        companyTop({
            qyxxId
        }).then(function (res) {
            if (res.success) {
                setCompanyInfo(res.data);
                const { years } = res.data;
                if (years && years.length) {
                    !notUpdate && setYear(years[years.length - 1]);
                }
            } else {
                message.error(res.message);
            }
        });
    }

    // 关注公司
    function toFocus() {
        focusCompany({
            qyxxId
        }).then(function (res) {
            if (res.success) {
                getCompany(true);
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    }

    function report() {
        request(
            `${API.V1}/auth/report/listed/upload`,
            {
                companyId: qyxxId,
                companyName,
                year
            },
            {
                method: 'post',
                responseType: 'blob'
            }
        ).then(blob => {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, (companyName + '风险监测报告.doc') || params.file);
            } else {
                let _link = document.createElement('a');
                _link.href = URL.createObjectURL(blob);
                _link.download = companyName + '风险监测报告.doc';
                _link.click();
                URL.revokeObjectURL(_link.href);
            }
        });
    }

    useEffect(() => {
        getNoteList();
    }, [pageNum])

    useEffect(() => {
        document.getElementById('root').scrollTop = 0;
        getCompany();
    }, [])
    return { report, toFocus, notes, getNoteList, visible, setVisible, addNote, content, setContent, pageNum, setPagination, deleteItem, companyInfo, year, setYear };
}