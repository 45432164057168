'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _index = require('../../config/index.js');

var _index2 = _interopRequireDefault(_index);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _Shape2 = require('../../interface/Shape');

var _Shape3 = _interopRequireDefault(_Shape2);

var _enum = require('../../constant/enum');

var _common = require('../../util/common');

var _common2 = require('../../util/coordinate/common');

var _NodeMark = require('./../mark/NodeMark');

var _NodeMark2 = _interopRequireDefault(_NodeMark);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Node = function (_Shape) {
    (0, _inherits3.default)(Node, _Shape);

    function Node(opts) {
        var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        (0, _classCallCheck3.default)(this, Node);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Node.__proto__ || (0, _getPrototypeOf2.default)(Node)).call(this, opts));

        _this.type = 'node';


        _this.init(opts, settings);
        _this.id = opts.id;
        _this.category = opts.category;
        _this.isTarget = opts.isTarget;
        _this.group = new _this.engine.Group();
        _this.element = new _this.engine.Image({
            zlevel: _this.settings.layer.normal
        });
        _this.group.add(_this.element);
        _this.actionTargetEffect = _this.actionTargetEffect.bind(_this);
        return _this;
    }

    (0, _createClass3.default)(Node, [{
        key: 'init',
        value: function init(opts) {
            var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            this.settings = (0, _merge2.default)({}, _index2.default.node, settings.node);
            this.options = opts;
            this._options = this.convertOptions(opts);
        }
    }, {
        key: 'setStyle',
        value: function setStyle(options) {
            this.setStatus(options);
            if (options.shadow) {
                this.setShadowStyle(options);
            } else {
                delete options.style.shadowBlur;
                delete options.style.shadowColor;
                delete options.style.shadowOffsetX;
                delete options.style.shadowOffsetY;
            }
            this.setMarksStyle(options);
        }
    }, {
        key: 'setStatus',
        value: function setStatus(options) {
            var _settings$style = this.settings.style,
                icon = _settings$style.icon,
                item = _settings$style.item,
                target = _settings$style.target,
                key = _settings$style.key,
                textStyle = _settings$style.text;
            var text = options.text,
                category = options.category,
                isTarget = options.isTarget,
                isKey = options.isKey,
                hidden = options.hidden,
                slight = options.slight,
                hovered = options.hovered,
                focused = options.focused,
                selected = options.selected,
                isHistory = options.isHistory;

            // 通用样式

            var style = (0, _assign2.default)({
                text: textStyle.content(text, options),
                image: icon(category, isTarget, isHistory, this.options)
            }, item, textStyle);

            // 关键关联方样式
            if (isKey) {
                (0, _assign2.default)(style, key);
            }

            // 目标企业样式
            if (isTarget) {
                (0, _assign2.default)(style, target);
            }

            if (hidden) {
                this.group.hide();
            } else {
                this.group.show();

                if (slight) {
                    style.opacity = this.settings.style.slight(options);
                } else {
                    if (hovered) {
                        //style.fill = 'red';
                        //style.stroke = 'red';
                        //style.textFill = 'red';
                        //style.textStroke = 'red';
                    }

                    if (selected) {
                        // style.fill = 'yellow';
                        // style.stroke = 'yellow';
                        // style.textFill = 'yellow';
                        // style.textStroke = 'yellow';
                    }

                    if (focused) {
                        //style.fill = 'blue';
                        //style.stroke = 'blue';
                        //style.textFill = 'blue';
                        //style.textStroke = 'blue';
                    }
                }
            }

            (0, _assign2.default)(options.style, style);
        }

        // 性能问题, 暂未使用

    }, {
        key: 'setShadowStyle',
        value: function setShadowStyle(options) {
            var style = {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0
            };

            switch (options.category) {
                case _enum.NodeType.NORMAL:
                    // 普通企业
                    style.shadowColor = '#a1cef5';
                    break;
                case _enum.NodeType.RISK:
                    // 风险企业
                    style.shadowColor = '#f5afa1';
                    break;
                case _enum.NodeType.BLACK:
                    // 黑名单企业
                    style.shadowColor = '#a6b2ca';
                    break;
                case _enum.NodeType.REVOKE:
                    // 吊销企业
                    style.shadowColor = '#abebc1';
                    break;
                case _enum.NodeType.PERSON:
                    // 自然人
                    style.shadowColor = '#abebc1';
                    break;
                // TODO: 缺少事件节点
            }

            // TODO: isTarget, isKey

            (0, _assign2.default)(options.style, style);
        }
    }, {
        key: 'draw',
        value: function draw(immediately) {
            if (immediately) {
                this._options.animation = false;
            }

            this.setStyle(this._options);
            this.setTargetEffect(this._options);

            var _options = this._options,
                startPoint = _options.startPoint,
                _options$style = _options.style,
                image = _options$style.image,
                textPosition = _options$style.textPosition;
            // 元素的初始位置和样式

            this.group.attr('position', startPoint);
            this.element.attr({
                style: {
                    image: image,
                    opacity: 0,
                    textPosition: textPosition
                }
            });
            // 元素的目标位置和样式, 根据配置判断是否执行动画.
            this.action(this._options, true);
        }
    }, {
        key: 'update',
        value: function update(opts) {
            var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            this.init(opts, settings);
            this.setStyle(this._options);
            this.setTargetEffect(this._options);
            this.action(this._options, false);
        }
    }, {
        key: 'setTargetEffect',
        value: function setTargetEffect(_ref) {
            var _ref$isTarget = _ref.isTarget,
                isTarget = _ref$isTarget === undefined ? false : _ref$isTarget;

            var targetEffect = this.settings.style.targetEffect;
            // 目标节点增加一个呼吸效果
            if (isTarget && !this.effectElement && targetEffect) {
                this.effectElement = new this.engine.Circle({
                    zlevel: this.settings.layer.target
                });
                this.group.add(this.effectElement);
            } else if (!isTarget && this.effectElement) {
                this.group.remove(this.effectElement);
                delete this.effectElement;
            }
        }
    }, {
        key: 'actionTargetEffect',
        value: function actionTargetEffect() {
            // TODO: this._options.animation判断不准, 社区图和地域分布图默认关了动画, 这样就没有呼吸效果了, 需要一个框架内部属性控制动画开起,关闭(_animation).
            // if (!this.effectElement || !this._options.animation) {
            if (!this.effectElement) {
                return;
            }

            var _settings$style$targe = this.settings.style.targetEffect,
                EFFECT_OVERFLOW = _settings$style$targe.overflow,
                EFFECT_EASING = _settings$style$targe.easing,
                EFFECT_SPEED = _settings$style$targe.speed;

            // 元素位置固定后再显示

            this.effectElement.attr('invisible', false);
            // 半径变大动画
            this.effectElement.animate('shape', true).when(EFFECT_SPEED, {
                r: this.effectElement.maxRadius / 2 + EFFECT_OVERFLOW
            }).start(EFFECT_EASING);
            // 透明度变小动画
            this.effectElement.animate('style', true).when(EFFECT_SPEED, {
                opacity: 0
            }).start(EFFECT_EASING);
        }
    }, {
        key: 'action',
        value: function action(options, isNew) {
            var _this2 = this;

            var EFFECT_COLOR = this.settings.style.targetEffect.color;
            var _options$marks = options.marks,
                marks = _options$marks === undefined ? [] : _options$marks,
                others = (0, _objectWithoutProperties3.default)(options, ['marks']);
            var animation = others.animation,
                position = others.position,
                offset = others.offset,
                hidden = others.hidden,
                _others$style = others.style,
                width = _others$style.width,
                height = _others$style.height;

            var _position = (0, _common2.positionOffset)(position, offset || [-(width / 2), -(height / 2)]);

            // 目标节点呼吸效果
            if (this.effectElement) {
                this.effectElement.attr({
                    maxRadius: width,
                    silent: true,
                    invisible: true,
                    style: {
                        fill: EFFECT_COLOR,
                        opacity: 1
                    },
                    shape: {
                        r: 0,
                        cx: width / 2,
                        cy: height / 2
                    }
                });
            }

            // group控制元素的位置
            this.animateTo({
                animation: animation,
                target: {
                    position: _position
                },
                callback: this.actionTargetEffect
            }, this.group);

            // element控制元素的样式
            this.animateTo({
                animation: animation,
                target: (0, _extends3.default)({}, others, {
                    marks: [].concat((0, _toConsumableArray3.default)(marks)), // 直接mark加入动画会有问题，NAN[object]
                    position: [0, 0]
                }),
                callback: function callback() {
                    if ((0, _common.isNotEmpty)(marks) && !hidden && !_this2.settings.mark.hidden) {
                        if (isNew) {
                            _this2.createMarks();
                        } else {
                            _this2.updateMarks();
                        }
                    }
                }
            }, this.element);
        }
    }, {
        key: 'remove',
        value: function remove(immediately) {
            var _this3 = this;

            // 移除marks
            if ((0, _common.isNotEmpty)(this.marks)) {
                this.marks.forEach(function (mark) {
                    _this3.group.remove(mark.getElement());
                });
            }

            var animation = this._options.animation;


            if (immediately) {
                animation = false;
            }

            this.animateTo({
                animation: animation,
                target: {
                    position: this._options.startPoint
                }
            }, this.group);

            this.animateTo({
                animation: animation,
                target: {
                    style: {
                        opacity: 0
                    }
                },
                callback: function callback() {
                    _this3.group.remove(_this3.element);
                    _this3.layer.remove(_this3);
                    _this3.group = null;
                    _this3.marks = null;
                    _this3.element = null;
                }
            }, this.element);
        }
    }, {
        key: 'animateTo',
        value: function animateTo(_ref2, element) {
            var animation = _ref2.animation,
                target = _ref2.target,
                callback = _ref2.callback;

            if (animation) {
                var _animation$time = animation.time,
                    time = _animation$time === undefined ? 800 : _animation$time,
                    _animation$delay = animation.delay,
                    delay = _animation$delay === undefined ? 0 : _animation$delay,
                    _animation$easing = animation.easing,
                    easing = _animation$easing === undefined ? 'linear' : _animation$easing,
                    _animation$forceAnima = animation.forceAnimate,
                    forceAnimate = _animation$forceAnima === undefined ? false : _animation$forceAnima;

                element.animateTo(target, time, delay, easing, function () {
                    element.attr(target);

                    if (callback) {
                        callback();
                    }
                }, forceAnimate);
            } else {
                element.attr(target);

                if (callback) {
                    callback();
                }
            }
        }
    }, {
        key: 'setMarksStyle',
        value: function setMarksStyle(options) {
            var _this4 = this;

            var animation = options.animation,
                hidden = options.hidden,
                slight = options.slight,
                hovered = options.hovered,
                selected = options.selected,
                focused = options.focused,
                data = options.data,
                _options$marks2 = options.marks,
                marks = _options$marks2 === undefined ? [] : _options$marks2;

            marks.forEach(function (mark, i) {
                if ((0, _common.isNotEmpty)(mark)) {
                    if (animation) {
                        mark.animation = {
                            delay: i * _this4.settings.mark.delay
                        };
                    } else {
                        mark.animation = false;
                    }
                    mark = (0, _assign2.default)(mark, { slight: slight, hovered: hovered, selected: selected, focused: focused, data: data });
                    mark.hidden = hidden || mark.hidden;
                }
            });
        }
    }, {
        key: 'createMarks',
        value: function createMarks() {
            var _this5 = this;

            var _options2 = this.options,
                offset = _options2.offset,
                marks = _options2.marks;
            var _options$style2 = this._options.style,
                width = _options$style2.width,
                height = _options$style2.height;


            this.marks = [];

            // 计算 Mark 标记的坐标
            (0, _common2.setCircleCoords)(marks, {
                center: (0, _common2.positionOffset)([0, 0], offset || [width / 2, height / 2]),
                radius: width / 2 + this.settings.mark.distance,
                nodeSpace: width / 2 * 0.8,
                startAngle: -60
            });

            // 创建标记
            marks.forEach(function (options) {
                var mark = new _NodeMark2.default(options);
                _this5.group.add(mark.getElement());
                mark.draw();
                _this5.marks.push(mark);
            });
        }
    }, {
        key: 'updateMarks',
        value: function updateMarks() {
            var _this6 = this;

            var _options3 = this.options,
                offset = _options3.offset,
                marks = _options3.marks;
            var _options$style3 = this._options.style,
                width = _options$style3.width,
                height = _options$style3.height;


            if (!this.marks) {
                this.createMarks();
            } else {
                // 计算 Mark 标记的坐标
                (0, _common2.setCircleCoords)(marks, {
                    center: (0, _common2.positionOffset)([0, 0], offset || [width / 2, height / 2]),
                    radius: width / 2 + this.settings.mark.distance,
                    nodeSpace: width / 2 * 0.8,
                    startAngle: -60
                });

                marks.forEach(function (options) {
                    var mark = _this6.marks.find(function (_mark) {
                        return _mark.category === options.category;
                    });
                    mark.update(options);
                });
            }
        }
    }, {
        key: 'getElement',
        value: function getElement() {
            return this.element;
        }
    }]);
    return Node;
}(_Shape3.default);

exports.default = Node;
module.exports = exports['default'];