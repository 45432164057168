import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  每日成交量前十大个股情况
*/
//创新层
export function rankingOfVolumeInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeInnovate`)
}
//基础层
export function rankingOfVolumeBasic() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeBasic`)
}
//精选层
export function rankingOfVolumeSelected() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeSelected`)
}
//全部层
export function rankingOfVolumeAll() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeAll`)
}

/**
 * @desc  每日成交量前50大个股辅导券商/做市商
 */
//创新层
export function rankingOfBrokerVolumeInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/six/rankingOfBrokerVolumeInnovate`)
}
//基础层
export function rankingOfVolumeBroker() {
    return request(`${API.V1}/cockpit/neeqStock/six/rankingOfVolumeBroker`)
}
//精选层
export function rankingOfBrokerVolumeSelected() {
    return request(`${API.V1}/cockpit/neeqStock/six/rankingOfBrokerVolumeSelected`)
}
//全部层
export function rankingOfAllBrokerVolume() {
    return request(`${API.V1}/cockpit/neeqStock/six/rankingOfVolumeAllBroker`)
}
