import React, { useRef, useState } from 'react';
import styles from './styles.scss';
import {
  Button,
  Collapse,
  Icon,
  Tabs,
  Typography,
  Table,
  Empty,
  message
} from 'antd';
import Search from '../common/Search';
import { columns, columns2 } from './columns';
import SearchConditions from './searchConditions';
import { getIntermediaryList } from './service';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import { Permission } from '../../../hooks/useAuth';

const keyMap = {
  regcap: ['startRegcap', 'endRegcap'],
  rate: ['startRate', 'endRate'],
  subsist: ['startSubsist', 'endSubsist']
};

const types = ['会计事务所', '证券公司', '律师事务所'];
/**
 *
 * @param arr
 * @param keys
 * @param object
 */
export function mergeArrayToObject(arr, keys, object) {
  let obj = {};
  arr.forEach((value, index) => (obj[keys[index]] = value));
  return Object.assign({}, object, obj);
}

export default function({ history }) {
  const searchRef = useRef(null);
  const [query, setQuery] = useState(null);
  const [lock, setLock] = useState(true);
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState(false);

  function parseValues(allValues) {
    let values = {};
    for (let key in allValues) {
      if (allValues.hasOwnProperty(key)) {
        switch (key) {
          case 'regcap':
          case 'rate':
          case 'subsist': {
            let value = allValues[key];
            if (isNotEmpty(value)) {
              values = mergeArrayToObject(value, keyMap[key], values);
            }
            delete values[key];
            break;
          }
          default:
            let value = allValues[key];
            values[key] = value;
            break;
        }
      }
    }

    return values;
  }

  function doQuery(params) {
    setNoData(false);
    Promise.all([
      getIntermediaryList({ ...params, zjjgType: 1 }),
      getIntermediaryList({ ...params, zjjgType: 2 }),
      getIntermediaryList({ ...params, zjjgType: 3 })
    ])
      .then(function([
        data1, // 会计事务所
        data2, // 证券公司
        data3 // 律师事务所
      ]) {
        // if (!data1.data && !data2.data && !data3.data) {
        // setNoData(true);
        // return message.error(data1.message);
        // }

        setData([data1.data || [], data2.data || [], data3.data || []]);
        let noDatas = [
          data1.data?.data,
          data2.data?.data,
          data3.data?.data
        ].filter(item => isEmpty(item));
        if (noDatas.length === 3) {
          setNoData(true);
        }
      })
      .finally(() => {});
  }

  // 搜索框变化
  function handleSearch(value) {
    setLock(false);
    if (isEmpty(searchRef.current)) {
      setQuery({ ...query, zjjgName: value });
      return doQuery({ ...query, zjjgName: value });
    }
    searchRef.current.form.validateFields((errors, values) => {
      if (isEmpty(errors)) {
        let conditions = parseValues(values);
        setQuery({
          ...query,
          ...conditions,
          zjjgName: value,
          pageNum: 0
        });
        doQuery({
          ...query,
          ...conditions,
          zjjgName: value,
          pageNum: 0
        });
      }
    });
  }

  // 前往专家团队
  function goToExpert() {
    history.push({ pathname: '/investment/expert/index' });
  }

  return (
    <Permission permission={496}>
      <div className={styles.root}>
        <Typography.Title className="textCenter">
          中介机构分析
          <Button
            type="primary"
            style={{ float: 'right' }}
            onClick={goToExpert}
          >
            专家团队
          </Button>
        </Typography.Title>
        <Search
          placeholder="请输入中介机构名称关键字"
          onSearch={handleSearch}
        />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel forceRender key={1} header="条件搜索">
            <SearchConditions elementRef={searchRef} />
          </Collapse.Panel>
        </Collapse>

        <div
          style={{
            marginTop: 32,
            marginBottom: 32,
            display: lock ? 'none' : 'block'
          }}
        >
          {!noData ? (
            <Tabs onChange={() => doQuery({ ...query, pageNum: 0 })}>
              {data.map((itemData, index) => {
                if (itemData.totalCount > 0) {
                  return (
                    <Tabs.TabPane key={index} tab={types[index]}>
                      <Table
                        // loading={loading}
                        pagination={{
                          size: 'small',
                          pageSizeOptions: ['5', '10', '20', '30', '40'],
                          showSizeChanger: true,
                          showQuickJumper: true,
                          current: itemData?.pageNum,
                          pageSize: itemData?.pageSize,
                          total: itemData?.totalCount,
                          showTotal: () =>
                            `共 ${Math.ceil(
                              itemData?.totalCount / itemData?.pageSize
                            )} 页 / ${itemData?.totalCount} 条数据`,
                          onShowSizeChange: (current, pageSize) => {
                            setQuery({
                              ...query,
                              pageSize,
                              pageNum: current
                            });
                            doQuery({
                              ...query,
                              pageSize,
                              pageNum: current
                            });
                          },
                          onChange: current => {
                            setQuery({
                              ...query,
                              pageNum: current
                            });
                            doQuery({
                              ...query,
                              pageNum: current
                            });
                          }
                        }}
                        columns={index === 2 ? columns2 : columns}
                        dataSource={itemData.data || []}
                      />
                    </Tabs.TabPane>
                  );
                } else {
                  return null;
                }
              })}
            </Tabs>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Permission>
  );
}
