/**
 * @author Ray
 * @desc 财务相关信息
 */

import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import styles from './styles.scss';
import UploadFile from '../../../../common/uploadFile';
import { MIME1, API } from '../../../../constants/enum';
import { isNotEmpty } from '../../../../utils/utils';

const { Item } = Form;

export const FinancialForm_ = React.forwardRef(function(
    { form, values, onInit, isPreview },
    ref
) {
    const { getFieldDecorator } = form;

    // 给出实例
    useEffect(function() {
        onInit(form);
        if (isNotEmpty(values)) {
            form.setFieldsValue({
                ...values
            });
        }
    }, []);

    return (
        <Form
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 10 }}
            className={styles.others}
        >
            <Item
                label="年度审计报告"
                extra={
                    !isPreview && '支持拓展名：zip、doc、docx、xlsx、jpg、pdf'
                }
            >
                {getFieldDecorator('yearReport', {
                    rules: []
                })(
                    <UploadFile
                        // multi
                        // maxFileCount={3}
                        preview={isPreview}
                        showTemplateDownload={false}
                        accept=".zip,.doc,.docx,.xlsx,.jpg,.pdf"
                        action={`${API.V1}/investbank/apply/upload`}
                        templateAction={`${API.V1}/investbank/apply/download/template`}
                        templateName={`年度审计报告.pdf`}
                        templateQuery={{ type: 1 }}
                        downloadAction={`${API.V1}/investment/apply/download`}
                    />
                )}
            </Item>
            {/*<Item*/}
            {/*    label="数据真实性保证书"*/}
            {/*    extra={*/}
            {/*        !isPreview && '支持拓展名：zip、doc、docx、xlsx、jpg、pdf'*/}
            {/*    }*/}
            {/*>*/}
            {/*    {getFieldDecorator('realData', {*/}
            {/*        rules: []*/}
            {/*    })(*/}
            {/*        <UploadFile*/}
            {/*            preview={isPreview}*/}
            {/*            showTemplateDownload*/}
            {/*            accept=".zip,.doc,.docx,.xlsx,.jpg,.pdf"*/}
            {/*            action={`${API.V1}/investbank/apply/upload`}*/}
            {/*            templateAction={`${API.V1}/investbank/apply/download/template`}*/}
            {/*            templateName={`数据真实性保证书.pdf`}*/}
            {/*            templateQuery={{ type: 2 }}*/}
            {/*            downloadAction={`${API.V1}/investment/apply/download`}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*</Item>*/}
            <Item
                label="其他文件"
                extra={!isPreview && '支持拓展名：rar、zip、pdf、jpg'}
            >
                {getFieldDecorator('others', {
                    rules: []
                })(
                    <UploadFile
                        multi
                        maxFileCount={5}
                        preview={isPreview}
                        action={`${API.V1}/investbank/apply/upload`}
                        accept=".rar,.zip,.pdf,.jpg"
                        downloadAction={`${API.V1}/investment/apply/download`}
                    />
                )}
            </Item>
            <Item label="附件备注">
                {getFieldDecorator('desc', {
                    rules: []
                })(
                    <Input.TextArea
                        readOnly={isPreview}
                        maxLength={500}
                        autosize={{
                            maxRows: 6,
                            minRows: 4
                        }}
                    />
                )}
            </Item>
        </Form>
    );
});

export default function FinancialForm({ onChange, values, onInit, isPreview }) {
    const [Element, setElement] = useState(null);

    useEffect(function() {
        const Form_ = Form.create({
            name: 'financial',
            onValuesChange: (a, b, allValues) => onChange(allValues)
        })(FinancialForm_);

        setElement(
            <Form_ values={values} onInit={onInit} isPreview={isPreview} />
        );
    }, []);

    return Element;
}
