/**
 * @author Ray
 */

import React, { useContext } from 'react';
import Title from '../../../common/title';
import styles from './styles.scss';
import { Divider } from 'antd';
import ChangePasswordForm from './form';
import { GlobalContext } from "../../../common/globalStore/createProvider";

export default function(props) {
    const {
        isPasscodeExpired
    } = useContext(GlobalContext);
    function handleSubmit(values) {
        if(isPasscodeExpired) {
            props.updatePasswordThreeMonths(values);
        }else{
            props.updatePassword(values);
        }
    }

    return (
        <section className={styles.messageContentRoot}>
            <Title
                classes={{
                    root: styles.titleFont
                }}
                text={'修改密码'}
            />
            <Divider />
            <ChangePasswordForm onSubmit={handleSubmit} />
        </section>
    );
}
