/**
 * @author Ray
 * @desc  中介评价-三年过会情况占比
 */

import React, { useState } from 'react';
import { afterMeetingColumns } from './columns';
import Table from '../../../../common/tableEx';
import { queryZjAgencyService } from './service';
import { isNotEmpty } from '../../../../utils/utils';

export default function AfterMeeting({ match }) {
    const [query] = useState({ agency: match.params.companyName });

    function mockData(res) {
        return isNotEmpty(res) ? res?.data : [];
    }

    return (
        <Table
            query={query}
            parseData={mockData}
            getData={queryZjAgencyService}
            columns={afterMeetingColumns}
            locale={{
                emptyText: `未发现企业的过会情况记录。不排除存在未公示或时间相对后滞的情况`
            }}
        />
    );
}
