'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _force = require('../../../util/coordinate/force');

var _mergeData = require('../../../util/processor/mergeData');

var _processor = require('../../../util/processor/processor');

var _enum = require('../../../constant/enum');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 关系探寻图
 */
var CommunityChart = function (_Chart) {
    (0, _inherits3.default)(CommunityChart, _Chart);

    function CommunityChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, CommunityChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CommunityChart.__proto__ || (0, _getPrototypeOf2.default)(CommunityChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'community';

        _this.size = (0, _extends3.default)({}, _this.painter._setting); // 尺寸
        _this.centerId = _this.options.data.bbdQyxxId;
        return _this;
    }

    (0, _createClass3.default)(CommunityChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(CommunityChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CommunityChart.prototype), 'beforeProcess', this).call(this);
        }
    }, {
        key: 'process',
        value: function process() {
            var state = this.getState();

            if (state.nodes === null) {
                console.warn('没有nodes 数据！');
                this.setState(state);
                return;
            }
            var gData = this.store.toGridState(state, this.centerId);
            this.countPos(gData);
        }
    }, {
        key: 'afterProcess',
        value: function afterProcess() {
            var _this2 = this;

            (0, _get3.default)(CommunityChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CommunityChart.prototype), 'handleDrag', this).call(this);
            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);

            this.handler.on('community.stop', function () {
                _this2.force.stop();
            });

            // 最短路径
            this.handler.on('community.path', function (sid, eid) {
                var state = _this2.getState();
                var gData = _this2.store.toGridState(state, _this2.centerId);
                var data = (0, _processor.getShortPathById)(sid, eid, gData, state);
                var nodes = gData.nodes,
                    links = gData.links;

                nodes.forEach(function (d) {
                    d.slight = true;
                });
                links.forEach(function (d) {
                    d.slight = true;
                });
                data.nodes.forEach(function (d) {
                    d.slight = false;
                });
                data.links.forEach(function (d) {
                    d.slight = false;
                });
                _this2.setState(state);
            });

            // 切换中心点 community.extend
            this.handler.on('community.reRender', function (data) {
                if (data.reset) {
                    _this2.resetTransform();
                }

                _this2.date = data.date;
                _this2.store.loadNewState({
                    degree: data.degree,
                    bbdQyxxId: data.id,
                    date: data.date
                }, 'init', 'community').then(function (d) {
                    _this2.force.stop();
                    _this2.centerId = data.id;
                    var state = d;
                    var gData = _this2.store.toGridState(state, _this2.centerId);
                    _this2.countPos(gData);
                }, function (err) {
                    _this2.handler.trigger('community.error', err);
                });
            });

            // 新的点
            this.handler.on('community.init', function (obj) {
                _this2.store.loadNewState(obj, 'init', 'community').then(function (d) {
                    _this2.centerId = obj.bbdQyxxId;
                    _this2.force.stop();
                    // this.addNodes(d);
                    var state = d;
                    var gData = _this2.store.toGridState(state, _this2.centerId);
                    _this2.countPos(gData);
                }, function (err) {
                    _this2.handler.trigger('community.error', err);
                });
            });

            // 收起点
            this.handler.on('community.packup', function (d, cid) {
                if (d && d.shapeType !== 'node') {
                    return;
                }
                var id = d.id;
                var state = _this2.getState();
                var gData = _this2.store.toGridState(state, _this2.centerId);
                var data = (0, _processor.flodByIds)([id], gData, cid || gData.centerId);
                state = (0, _mergeData.mergeOriginData)(state, data, 'del');
                gData = _this2.store.toGridState(state, _this2.centerId);
                gData.nodesKV[id].expanded = false;
                _this2.countPos(gData);
            });

            // 删除点
            this.handler.on('community.hide', function (id, cid) {

                if (id) {
                    // id = id.target.data.bbdQyxxId;
                    var state = _this2.getState();
                    var gData = _this2.store.toGridState(state, _this2.centerId);
                    var data = (0, _processor.delByIds)([id], gData, cid || _this2.gData.nodesGroup[0][0]);
                    state = (0, _mergeData.mergeOriginData)(state, data, 'del');
                    _this2.countPos(state);
                } else {
                    var _gData = _this2.gData,
                        nodes = _gData.nodes,
                        links = _gData.links;

                    nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    links.forEach(function (d) {
                        d.slight = false;
                    });
                    _this2.setState(_this2.gData);
                }
            });

            // 高亮点
            this.handler.on('community.highlight', function (node) {
                // id = id.target.data.bbdQyxxId;
                if (node && node.shapeType !== 'node') {
                    return;
                }
                var data = null;
                var state = _this2.getState();
                var gData = _this2.store.toGridState(state, _this2.centerId);
                var nodes = gData.nodes,
                    links = gData.links;

                if (node) {
                    data = (0, _processor.getHightLight)(node.id, gData);
                    nodes.forEach(function (d) {
                        d.slight = true;
                    });
                    links.forEach(function (d) {
                        d.slight = true;
                    });
                    data.nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    data.links.forEach(function (d) {
                        d.slight = false;
                    });
                } else {
                    nodes.forEach(function (d) {
                        d.slight = false;
                    });
                    links.forEach(function (d) {
                        d.slight = false;
                    });
                }
                _this2.setState(gData);
            });
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.force && this.force.stop();
            (0, _get3.default)(CommunityChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CommunityChart.prototype), 'dispose', this).call(this);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('dblclick', this.handleExtension);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);

            this.force = null;
            this.painter = null;
            this.store = null;
            this.handler = null;
        }
    }, {
        key: 'countPos',
        value: function countPos(state) {
            var _this3 = this;

            var nodes = state.nodes,
                links = state.links;
            var _size = this.size,
                width = _size.width,
                height = _size.height;


            this.status = _enum.ChartStatus.BUSY;

            links.forEach(function (elem) {
                elem.source = elem.from;
                elem.target = elem.to;
            });
            nodes.forEach(function (elem) {
                elem.r = 0;
            });

            this.force = (0, _force.defaultForce)(nodes, links, {
                center: [width / 2, height / 2],
                interval: function interval(d) {
                    var wid = d.childs.length * 2;
                    if (wid > 140) {
                        wid = 140;
                    } else if (wid < 20) {
                        wid = 80;
                    }
                    return wid;
                },
                distanceMax: 200
            });

            this.force.on('tick', function () {
                nodes.forEach(function (elem) {
                    elem.position = [elem.x, elem.y];
                });
                _this3.setState(state);
            });

            this.force.on('end', function () {
                _this3.status = _enum.ChartStatus.IDLE;

                (0, _get3.default)(CommunityChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CommunityChart.prototype), 'bindSetData', _this3).call(_this3);
                _this3.handler.trigger('chart.force.stop');
                _this3.handler.trigger('chart.getSettingStatus');
            });

            // 设置mark
            // this.handler.trigger('chart.getSettingStatus');

            // 获取数据
            this.handler.trigger('chart.changeList', state);
        }
    }]);
    return CommunityChart;
}(_Chart3.default);

exports.default = CommunityChart;
module.exports = exports['default'];