/**
 * @author hjie
 *  云课堂/专栏 详情
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Tabs, Modal } from 'antd';
import main from '../../images/main.png';
import ClassDetail from './classDetail';
import ClasslCatalog from './classlCatalog';
import { getCourseInfo, enrollCourse, getColumnInfo, enrollColumn } from '../../service';
import { Classification, ISENROLL, CATEGORY } from 'constants/common';
import { StatusCode } from 'constants/enum';
import classnames from 'classnames';

const { TabPane } = Tabs;

export default function(props) {

    const { Name, Type, stepsData, history } = props;
    const isCourse = !!(Type === CATEGORY.Course); // 是否是云课堂

    const [ detailInfo, getDetailInfo ] = useState(null);

    useEffect(() => {
        getDetail();
    }, [])

    function getDetail(){
        const { id } = props.match.params;
        const apiFun = isCourse ? getCourseInfo : getColumnInfo;
        apiFun({ id }).then(res => {
            if( res.statusCode === StatusCode.success ){
                getDetailInfo(res.data);
            }
        })
    }

    /**
     * 报名/取消报名
     * @param {*} id 课程id
     * @param {*} isEnroll  1=报名 2=取消报名
     */
    function goEnroll(id, isEnroll){
        let func = isCourse ? enrollCourse : enrollColumn;
        func({
            id,
            status: isEnroll
        }).then( res => {
            if( res.statusCode === StatusCode.success ){
                getDetail();
            }else{
                Modal.error({
                    title: '提示',
                    okText: '确定',
                    content: res.message
                });
            }
        })
    }

    if( detailInfo ){
        const { isEnroll, lecturers, frontUrl, name, title, label, plate, introduction, enrollCount, id, author } = detailInfo;
        const flag = !!(isEnroll === ISENROLL.yes);
        return  <div className={styles.cloudClassDetail}>
                    <div className = {styles.main}>
                        <div className = {styles.img}>
                            <img src = { frontUrl || main} title = {`${Name}封面图`}/>
                        </div>
                        <div className = {styles.textBox}>
                            <h3 className = "ellipsis">{ name || title || '- -'}</h3>
                            <p className = {styles.labelBox}>
                                <span style = {{ marginRight: '20px' }}>{Name}类型：{Classification[plate]}</span> 
                                {
                                    label && label.split(',').map( ele => <span className = {styles.tag} key = {ele}>{ele}</span> )
                                }
                            </p>

                            {
                                isCourse ?
                                <p className = "ellipsis">讲师：
                                    { lecturers ? lecturers.map( (ele, i) => `${ele.name}${ i === lecturers.length - 1 ? '' : '、' } ` ) : '- -'}
                                </p> :
                                <p className = "ellipsis">作者：
                                    { author || '- -'}
                                </p> 
                            }

                            <p className = {styles.descriptionBox}>
                                {Name}简介：{ introduction || '- -'}
                            </p>
                            <div className = {styles.signUpBox}>
                                {
                                    flag ? 
                                    <a href = "javascript:;" className = {classnames(styles.signUpBtn, styles.signDownBtn ) } onClick = {() => goEnroll( id, ISENROLL.not )}><b>取消报名</b></a>
                                    :
                                    <a href = "javascript:;" className = {styles.signUpBtn } onClick = {() => goEnroll( id, ISENROLL.yes )} ><b>点击报名</b></a>
                                
                                }
                                <span className = {styles.num}>
                                    报名人数：
                                    <b>{ enrollCount || 0}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className = { classnames( styles.details, {[styles.hasCatalog]: flag}) }>
                        <Tabs defaultActiveKey="1" animated = {false}>
                            <TabPane tab= {`${Name}详情`} key="1">
                                <ClassDetail detailInfo = {detailInfo} history = {history} isCourse = {isCourse} stepsData = {stepsData}/>
                            </TabPane>
                            {
                                flag &&
                                <TabPane tab= {`${Name}目录`} key="2">
                                    <ClasslCatalog  detailInfo = {detailInfo} isCourse = {isCourse} history = {history}/>
                                </TabPane>
                            }
                        </Tabs>
                    </div>
                </div> 
    }
    return <div></div>;
}
