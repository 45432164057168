import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 *
 * @param contentType 	1、报告名称 2、作者 3、标签
 * @param inputContent  输入内容
 * @param type  报告类型 1 行业研究报告 2 政策研究报告
 * @param startTime  开始时间
 * @param endTime  结束时间
 * @param pageNum  当前页数
 * @param pageSize  每页条数
 */
export function callPolicyReportList({
    contentType,
    inputContent,
    type,
    startTime,
    endTime,
    pageNum,
    pageSize
}) {
    return request(
        `${API.V1}/investbank/reportBefore/showReport`,
        {
            contentType,
            inputContent,
            type,
            startTime,
            endTime,
            pageNum,
            pageSize
        },
        {
            method: 'post'
        }
    );
}
