/**
 * @author Ray
 * @desc 科创属性
 */

import { Divider, message, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Title from '../../../common/title';
import useStorage from '../../../hooks/useStorage';
import { innovateDetail, innovateUpdate } from '../service';
import Competition from './competition';
import CoreTech from './coreTech';
import Marketing from './marketing';
import Research from './research';
import styles from './styles.scss';
import TechTransfer from './techTransfer';
import { isNotEmpty } from '../../../utils/utils';
import { GlobalContext } from '../../../common/globalStore/createProvider';

let tabs = [CoreTech, Research, Marketing, Competition, TechTransfer];
let tabNames = [
  '核心技术',
  '研发能力',
  '研发成果市场认可',
  '相对竞争优势',
  '技术成果转化'
];

export default function Creating(props) {
  const [activeKey, setActiveKey] = useState('0');
  const [data, setData] = useState({
    info: {}
  });
  // const [user] = useStorage('UserInfo');
  const { user } = useContext(GlobalContext);
  const getDetail = () => {
    async function fetchData() {
      let result = await innovateDetail({
        companyName: user?.organization,
        type: 1
      });
  
      if (result.statusCode == 1000) {
        result = result.data || {};
        const item = result.item || {};
        item.totalPatentRatio = isNotEmpty(item.totalPatentRatio)
          ? parseFloat((item.totalPatentRatio * 100).toFixed())
          : null;
        item.totalIncomeRatio = isNotEmpty(item.totalIncomeRatio)
          ? parseFloat((item.totalIncomeRatio * 100).toFixed())
          : null;
        item.productMarketRatio = isNotEmpty(item.productMarketRatio)
          ? parseFloat((item.productMarketRatio * 100).toFixed())
          : null;
        item.latestYearCoreRate = isNotEmpty(item.latestYearCoreRate)
          ? parseFloat((item.latestYearCoreRate * 100).toFixed())
          : null;
        setData({ info: item });
      } else {
        message.info(data.message);
        return;
      }
    }
    fetchData();
  };
  const save = (values, errBackFn) => {
   
    innovateUpdate({
      ...data.info, ...values, companyName: user.organization,
      creditCode: user.creditCode,
    }).then(data => {
      if (data.statusCode != 1000) {
        message.info(data.message);
        errBackFn && errBackFn();
      } else {
        message.info('保存成功');
        getDetail();
      }
    });
  };

  useEffect(function() {
    getDetail();
  }, []);

  return (
    <div className={styles.root}>
      <Title level={1} text='科创属性' />
      <Divider />
      <Tabs onTabClick={key => setActiveKey(key)} activeKey={activeKey}>
        {tabs.map((T, index) => (
          <Tabs.TabPane key={String(index)} tab={tabNames[index]}>
            <T
              {...props}
              activeKey={activeKey}
              data={data.info}
              saveFun={save}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}
