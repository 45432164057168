/**
 * @author Ray
 * @description 注销账户的数据交互
 */
import React, { useState } from 'react';

export default function(props) {
    const [state, setState] = useState({});

    return props.children({
        ...props,
        ...state
    });
}
