import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { industryRiskEnterprises } from './service';
import { Empty } from 'antd'
/**
 * @desc 堆叠条形图
 */

global.industryRiskBar = null;

export default function IndustryRiskBar({ address, style }) {
    useEffect(() => {
        industryRiskEnterprises({ area: address }).then(({ success, data }) => {
            if (success == true) {
                if (global.industryRiskBar == null) {
                    global.industryRiskBar = new Chart({
                        container: 'IndustryRiskBar',
                        autoFit: true,
                        height: 160,
                        padding:[0,18,33,200]
                    });

                    global.industryRiskBar.data(data);

                    global.industryRiskBar.axis('item', {
                        tickLine: null,
                        line: null,
                    });
                    global.industryRiskBar.legend({
                        position: 'bottom',
                    });

                    global.industryRiskBar.coordinate('rect').transpose();

                    global.industryRiskBar.tooltip(false);

                    global.industryRiskBar.interaction('active-region');
                    global.industryRiskBar
                    .interval()
                    .adjust('stack')
                    .position('item*amount')
                    .color('type*item', (type, item) => {
                        if (type === '无风险企业') {
                            return '#1890ff';
                        }
                        if (type === '风险企业') {
                            return '#f0f2f3';
                        }
                    })
                    .size(18)
                    .label('amount*type',(amount,type) => {
                        const color = type === '无风险企业' || (type === '风险企业' && amount < 150) ? 'white' : '#47494b';
                        const offset = type === '风险企业' && amount < 150 ? 15 : 0;
                        return {
                            position: 'middle',
                            offset,
                            style: {
                                fontSize: 12,
                                fill: color,
                                lineWidth: 0,
                                stroke: null,
                                shadowBlur: 2,
                                shadowColor: 'rgba(0, 0, 0, .45)',
                            }
                        }
                    });
                    global.industryRiskBar.render();
                } else {
                    global.industryRiskBar.clear();
                    global.industryRiskBar.changeData(data);
                    global.industryRiskBar.axis('item', {
                        tickLine: null,
                        line: null,
                    });
                    global.industryRiskBar.legend({
                        position: 'bottom',
                    });

                    global.industryRiskBar.coordinate('rect').transpose();

                    global.industryRiskBar.tooltip(false);

                    global.industryRiskBar.interaction('active-region');
                    global.industryRiskBar
                    .interval()
                    .adjust('stack')
                    .position('item*amount')
                    .color('type*item', (type, item) => {
                        if (type === '无风险企业') {
                            return '#1890ff';
                        }
                        if (type === '风险企业') {
                            return '#f0f2f3';
                        }
                    })
                    .size(18)
                    .label('amount*type',(amount,type) => {
                        const color = type === '无风险企业' || (type === '风险企业' && amount < 150) ? 'white' : '#47494b';
                        const offset = type === '风险企业' && amount < 150 ? 15 : 0;
                        return {
                            position: 'middle',
                            offset,
                            style: {
                                fontSize: 12,
                                fill: color,
                                lineWidth: 0,
                                stroke: null,
                                shadowBlur: 2,
                                shadowColor: 'rgba(0, 0, 0, .45)',
                            }
                        }
                    });
                    global.industryRiskBar.render();
                }
            } else {
                <Empty />
            }
        })
    }, [address])
    
    return (
      <div id='IndustryRiskBar' className={ styles.IndustryRisk }></div>
    )
  
}