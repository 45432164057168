'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _Shape2 = require('../../interface/Shape');

var _Shape3 = _interopRequireDefault(_Shape2);

var _Arrow = require('./Arrow');

var _Arrow2 = _interopRequireDefault(_Arrow);

var _common = require('../../util/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Line = function (_Shape) {
    (0, _inherits3.default)(Line, _Shape);

    function Line(opts) {
        var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        (0, _classCallCheck3.default)(this, Line);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Line.__proto__ || (0, _getPrototypeOf2.default)(Line)).call(this, opts));

        _this.type = 'line';


        _this.init(opts, settings);
        _this.id = opts.id;
        _this.elements = [];
        _this.group = new _this.engine.Group();

        var count = _this.options.length;
        _this.options.forEach(function (opts) {
            var line,
                text,
                arrow = {};
            // 大于1画贝塞尔曲线
            if (count > 1) {
                line = new _this.engine.BezierCurve({
                    shapeType: 'line'
                });
                // 等于1画直线
            } else {
                line = new _this.engine.Line({
                    shapeType: 'line'
                });
            }
            text = new _this.engine.Text({
                shapeType: 'text',
                silent: true
            });

            _this.group.add(line);
            _this.group.add(text);

            if (opts.startArrow) {
                arrow.start = new _Arrow2.default({
                    shapeType: 'arrow',
                    silent: true
                });
                _this.group.add(arrow.start);
            }

            if (opts.endArrow) {
                arrow.end = new _Arrow2.default({
                    shapeType: 'arrow',
                    silent: true
                });
                _this.group.add(arrow.end);
            }

            _this.elements.push({
                line: line,
                text: text,
                arrow: arrow
            });
        });
        return _this;
    }

    (0, _createClass3.default)(Line, [{
        key: 'init',
        value: function init(opts) {
            var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            this.context = settings;
            this.settings = (0, _merge2.default)({}, _config2.default.line, settings.line);
            // 需要参考 node 大小
            this.nodeSettings = (0, _merge2.default)({}, _config2.default.node, settings.node);
            this.options = (0, _common.isObject)(opts) ? [opts] : opts;
            this._options = this.convertOptions(this.options);
        }
    }, {
        key: 'convertOptions',
        value: function convertOptions(options) {
            var _this2 = this;

            var _options = [];
            options.forEach(function (opts) {
                var _opts = (0, _get3.default)(Line.prototype.__proto__ || (0, _getPrototypeOf2.default)(Line.prototype), 'convertOptions', _this2).call(_this2, opts);
                // _opts.zlevel = LayerConfig.normal;   // TODO: 移入setStyle()中, 观察是否会有问题.
                // _opts.silent = true;
                _options.push(_opts);
            });
            return _options;
        }
    }, {
        key: 'setStyle',
        value: function setStyle(options) {
            var _this3 = this;

            if (!options) {
                return;
            }

            options.forEach(function (opts) {
                return _this3.setStatus(opts);
            });

            // if (options.shadow) {
            //     this.setShadowStyle(options);
            // } else {
            //     delete options.style.shadowBlur;
            //     delete options.style.shadowColor;
            //     delete options.style.shadowOffsetX;
            //     delete options.style.shadowOffsetY;
            // }
        }

        // 设置线条的显示状态

    }, {
        key: 'setStatus',
        value: function setStatus(options) {
            var _settings = this.settings,
                LayerConfig = _settings.layer,
                StyleConfig = _settings.style;
            var low = LayerConfig.low,
                normal = LayerConfig.normal,
                highlight = LayerConfig.highlight;


            var text = '';
            var hidden = options.hidden,
                slight = options.slight,
                hovered = options.hovered,
                selected = options.selected,
                focused = options.focused;


            var style = (0, _assign2.default)({}, StyleConfig.item);

            (0, _entries2.default)(style).forEach(function (_ref) {
                var _ref2 = (0, _slicedToArray3.default)(_ref, 2),
                    key = _ref2[0],
                    value = _ref2[1];

                if (typeof value === 'function') {
                    style[key] = value(options);
                }
            });

            if (hidden) {
                this.group.hide();
            } else {
                this.group.show();

                // 淡化效果, 优先级 1
                if (slight) {
                    options.zlevel = low;
                    style.opacity = StyleConfig.slight(options);
                    // 高亮效果(关键路径颜色), 优先级 2
                } else if (selected) {
                    options.zlevel = highlight;
                    style.stroke = StyleConfig.selected(options);
                    // 鼠标悬浮效果, 优先级 3
                } else if (hovered) {
                    options.zlevel = highlight;
                    style.stroke = StyleConfig.hovered(options) || style.stroke;
                } else {
                    options.zlevel = normal;
                }

                text = StyleConfig.text.content.call(this, options);
            }

            (0, _assign2.default)(options, { text: text, style: style });
        }
    }, {
        key: 'setShadowStyle',
        value: function setShadowStyle(options) {
            // TODO:
        }
    }, {
        key: 'buildPath',
        value: function buildPath(fromNode, toNode, opts, index) {
            var _offsetPoints = offsetPoints(fromNode, toNode, this.nodeSettings),
                _offsetPoints2 = (0, _slicedToArray3.default)(_offsetPoints, 4),
                x1 = _offsetPoints2[0],
                y1 = _offsetPoints2[1],
                x2 = _offsetPoints2[2],
                y2 = _offsetPoints2[3];

            if (index >= 0) {
                // 画抛物线
                var _getBezierProps = getBezierProps(x1, y1, x2, y2, index),
                    startk = _getBezierProps.startk,
                    endk = _getBezierProps.endk,
                    _getBezierProps$contr = (0, _slicedToArray3.default)(_getBezierProps.control, 2),
                    cpx1 = _getBezierProps$contr[0],
                    cpy1 = _getBezierProps$contr[1];

                opts.startk = startk;
                opts.endk = endk;
                opts.shape = { x1: x1, y1: y1, x2: x2, y2: y2, cpx1: cpx1, cpy1: cpy1 };
                var bezierPoint = getBezierPoint([x1, y1], [cpx1, cpy1], [x2, y2], 0.5);
                opts.textPosition = bezierPoint;
            } else {
                // 画直线
                opts.shape = { x1: x1, y1: y1, x2: x2, y2: y2 };
                var textX = (x1 + x2) / 2;
                var textY = (y1 + y2) / 2;
                opts.textPosition = [textX, textY];
            }
        }
    }, {
        key: 'draw',
        value: function draw() {
            this.setStyle(this._options);
            this.action(this._options);
        }
    }, {
        key: 'update',
        value: function update(opts) {
            var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            this.init(opts, settings);
            this.setStyle(this._options);
            this.action(this._options);
        }
    }, {
        key: 'action',
        value: function action(options) {
            var _this4 = this;

            if ((0, _common.isEmpty)(options)) {
                return;
            }

            var TextConfig = this.settings.style.text;
            var count = options.length;
            // 设置参考点, 将一组线的起点和终点设为相同, 否则如果一条线起点是A, 另一条线起点是B绘出的线条会重叠.
            var _options$ = options[0],
                _options$$id = _options$.id,
                id = _options$$id === undefined ? '' : _options$$id,
                fromNode = _options$.fromNode,
                toNode = _options$.toNode;

            var _id$split = id.split('-'),
                _id$split2 = (0, _slicedToArray3.default)(_id$split, 2),
                startId = _id$split2[0],
                endId = _id$split2[1];

            /* 校验 link 的 startId 和 endId 属性是否为 null */


            if ((0, _common.isEmpty)(startId) || startId === 'null') {
                console.error('link \u6570\u636E\u5F02\u5E38: startId \u5C5E\u6027\u4E3A\u7A7A. link.id: ' + id);
                return;
            }

            if ((0, _common.isEmpty)(endId) || endId === 'null') {
                console.error('link \u6570\u636E\u5F02\u5E38: endId \u5C5E\u6027\u4E3A\u7A7A. link.id: ' + id);
                return;
            }

            /* 校验 link 关联的 fromNode 和 toNode 对象是否为 null */
            if (!fromNode) {
                console.error('link \u6570\u636E\u5F02\u5E38: \u672A\u627E\u5230\u5173\u8054\u7684 fromNode \u8282\u70B9. \u8282\u70B9 id \u4E3A: ' + startId);
                return;
            }

            if (!toNode) {
                console.error('link \u6570\u636E\u5F02\u5E38: \u672A\u627E\u5230\u5173\u8054\u7684 toNode \u8282\u70B9. \u8282\u70B9 id \u4E3A: ' + endId);
                return;
            }

            // link 关联的起始或结束节点坐标还未计算出来, 因此忽略此 link 绘制
            if (!fromNode.position || !toNode.position) {
                return;
            }

            var fontSize = TextConfig.fontSize,
                textFill = TextConfig.textFill,
                otherStyle = (0, _objectWithoutProperties3.default)(TextConfig, ['fontSize', 'textFill']);

            options.forEach(function (opts, index) {
                // count大于1画曲线, 等于1画直线.
                _this4.buildPath(fromNode, toNode, opts, count > 1 ? index : -1);

                var _elements$index = _this4.elements[index],
                    line = _elements$index.line,
                    text = _elements$index.text,
                    arrow = _elements$index.arrow;
                var selected = opts.selected,
                    hovered = opts.hovered,
                    zlevel = opts.zlevel,
                    z = opts.z,
                    silent = opts.silent,
                    textPosition = opts.textPosition,
                    startArrow = opts.startArrow,
                    endArrow = opts.endArrow,
                    startk = opts.startk,
                    endk = opts.endk,
                    _opts$style = opts.style,
                    opacity = _opts$style.opacity,
                    fill = _opts$style.fill,
                    stroke = _opts$style.stroke,
                    _opts$shape = opts.shape,
                    x1 = _opts$shape.x1,
                    y1 = _opts$shape.y1,
                    x2 = _opts$shape.x2,
                    y2 = _opts$shape.y2;

                var r = Math.atan2(x2 - x1, y2 - y1);
                var fixing = r > 0 ? 270 : 90; // 文字保持不颠倒.

                // 绘制线
                line.attr(opts);

                // 绘制文字
                text.attr({
                    zlevel: zlevel,
                    z: z,
                    rotation: r + fixing * Math.PI / 180,
                    position: textPosition,
                    invisible: !opts.text,
                    style: (0, _extends3.default)({}, otherStyle, {
                        fontSize: typeof fontSize === 'function' ? fontSize.call(_this4, opts) : fontSize,
                        opacity: opacity,
                        text: opts.text,
                        transformText: true,
                        textFill: selected || hovered ? stroke : textFill
                    })
                });

                // 画起点箭头
                if (startArrow) {
                    arrow.start.attr({
                        zlevel: zlevel,
                        z: z,
                        style: {
                            opacity: opacity,
                            fill: stroke,
                            stroke: stroke
                        },
                        shape: {
                            x1: x2,
                            y1: y2,
                            x2: x1,
                            y2: y1,
                            k: startk
                        }
                    });
                    // 画终点箭头
                }
                if (endArrow) {
                    arrow.end.attr({
                        zlevel: zlevel,
                        z: z,
                        style: {
                            opacity: opacity,
                            fill: stroke,
                            stroke: stroke
                        },
                        shape: {
                            x1: x1,
                            y1: y1,
                            x2: x2,
                            y2: y2,
                            k: endk
                        }
                    });
                }
            });
        }
    }, {
        key: 'remove',
        value: function remove() {
            this.layer.remove(this);
            this.id = null;
            this.options = null;
            this._options = null;
            this.elements = null;
            this.group = null;

            // var { animation } = this._options;
            // this.animateTo({
            //     animation: animation,
            //     target: {
            //         position: this._options.startPoint
            //     }
            // }, this.group);

            // this.animateTo({
            //     animation: animation,
            //     target: {
            //         style: {
            //             opacity: 0
            //         }
            //     },
            //     callback: () => {
            //         this.group.remove(this.element);

            //         var self = this;
            //         if (callback) {
            //             callback(self);
            //         }
            //         this.group = null;
            //         this.marks = null;
            //         this.element = null;
            //     }
            // }, this.element);
        }
    }, {
        key: 'animateTo',
        value: function animateTo(_ref3, element) {
            var animation = _ref3.animation,
                target = _ref3.target,
                callback = _ref3.callback;

            // element.stopAnimation(true);

            if (animation) {
                var _animation$time = animation.time,
                    time = _animation$time === undefined ? 800 : _animation$time,
                    _animation$delay = animation.delay,
                    delay = _animation$delay === undefined ? 0 : _animation$delay,
                    _animation$easing = animation.easing,
                    easing = _animation$easing === undefined ? 'linear' : _animation$easing,
                    _animation$forceAnima = animation.forceAnimate,
                    forceAnimate = _animation$forceAnima === undefined ? false : _animation$forceAnima;

                element.animateTo(target, time, delay, easing, function () {
                    element.attr(target);

                    if (callback) {
                        callback();
                    }
                }, forceAnimate);
            } else {
                element.attr(target);

                if (callback) {
                    callback();
                }
            }
        }
    }, {
        key: 'getElement',
        value: function getElement() {
            return this.group;
        }
    }]);
    return Line;
}(_Shape3.default);

/**
 * 根据滑动系数获取贝塞尔曲线上点的位置
 * @param {array} p0 起点， [x, y]
 * @param {array} p1 控制点， [x, y]
 * @param {array} p2 终点， [x, y]
 * @param {number} t 滑动系数 0-1
 * @return {Object} 返回点的坐标位置
 */


exports.default = Line;
function getBezierPoint(p0, p1, p2) {
    var t = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

    var x = (1 - t) * (1 - t) * p0[0] + 2 * t * (1 - t) * p1[0] + t * t * p2[0];
    var y = (1 - t) * (1 - t) * p0[1] + 2 * t * (1 - t) * p1[1] + t * t * p2[1];
    return [x, y];
}

/**
 * 设置贝塞尔曲线坐标
 * @param {number} x1 起点x1坐标
 * @param {number} y1 起点y1坐标
 * @param {number} x2 终点x2坐标
 * @param {number} y2 终点y2坐标
 * @param {number} index 当前线条在group中的索引
 * @param {number} space 贝塞尔曲线的间距
 * @return {Object} 返回控制点的坐标, 起点到控制点的斜率, 终点到控制点的斜率
 */
function getBezierProps(x1, y1, x2, y2) {
    var index = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var space = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 10;

    // 控制点
    var controlX;
    var controlY;
    // 中心点
    var centerX = (x1 + x2) / 2;
    var centerY = (y1 + y2) / 2;
    var angle = Math.atan2(y2 - y1, x2 - x1);

    // 计算控制点的位置
    if (index % 2 === 0) {
        space = space * (index + 1);
        controlX = centerX + space * Math.sin(angle);
        controlY = centerY - space * Math.cos(angle);
    } else {
        space = space * index;
        controlX = centerX - space * Math.sin(angle);
        controlY = centerY + space * Math.cos(angle);
    }

    return {
        control: [controlX, controlY],
        startk: Math.atan2(y1 - controlY, x1 - controlX),
        endk: Math.atan2(y2 - controlY, x2 - controlX)
    };
}

// 根据起始点的节点大小, 计算绘制的线条起始点偏移量
function offsetPoints(fromNode, toNode, nodeSettings) {
    var _fromNode$position = (0, _slicedToArray3.default)(fromNode.position, 2),
        x1 = _fromNode$position[0],
        y1 = _fromNode$position[1];

    var _toNode$position = (0, _slicedToArray3.default)(toNode.position, 2),
        x2 = _toNode$position[0],
        y2 = _toNode$position[1];

    var _nodeSettings$style = nodeSettings.style,
        item = _nodeSettings$style.item,
        target = _nodeSettings$style.target,
        key = _nodeSettings$style.key;

    var from_r, to_r;
    // 起始节点的半径
    if (fromNode.isKey) {
        from_r = key.width / 2;
    } else if (fromNode.isTarget) {
        from_r = target.width / 2;
    } else {
        from_r = item.width / 2;
    }

    // 终止节点的半径
    if (toNode.isKey) {
        to_r = key.width / 2;
    } else if (toNode.isTarget) {
        to_r = target.width / 2;
    } else {
        to_r = item.width / 2;
    }

    var k = Math.atan2(y2 - y1, x2 - x1);
    var _x1, _y1, _x2, _y2;
    _x1 = x1 + from_r * Math.cos(k);
    _y1 = y1 + from_r * Math.sin(k);
    _x2 = x2 - to_r * Math.cos(k);
    _y2 = y2 - to_r * Math.sin(k);
    return [_x1, _y1, _x2, _y2];
}
module.exports = exports['default'];