import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { listedCompanies } from './service';
import { Empty } from 'antd';

global.punishmentList = null;


export default function PunishmentListHistogram({ address, style }) {
    useEffect(() => {
          listedCompanies({ area: address }).then(({ success, data }) => {
            if (success == true) {
              if (global.punishmentList == null) {
                global.punishmentList = new Chart({
                  container: 'PunishmentListHistogram',
                  autoFit: true,
                  height: 120,
                });
                global.punishmentList.data(data);
                global.punishmentList.scale('amount', {
                  nice: true,
                });
                
                global.punishmentList.tooltip(false);
                global.punishmentList.legend(false);
                
                global.punishmentList
                  .interval()
                  .position('area*amount')
                  .label('amount',{
                    position:'middle'
                  })
                  .color('amount', '#2194ff');
                
                global.punishmentList.render();
              } else {
                  global.punishmentList.clear();
                  global.punishmentList.changeData(data);
                  global.punishmentList.scale('amount', {
                    nice: true,
                  });
                  
                  global.punishmentList.tooltip(false);
                  global.punishmentList.legend(false);
                  
                  global.punishmentList
                    .interval()
                    .position('area*amount')
                    .label('amount',{
                      position:'middle'
                    })
                    .color('amount', '#2194ff');
                  
                  global.punishmentList.render();
              }
              
            } else {
              <Empty />
            }
          })
    }, [address])
        
    
    return (
          <div id='PunishmentListHistogram' className={ styles.PunishmentListHistogram }></div>
    )
}
