import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { queryWorkExperience } from '../service';
import styles from './style.scss';
// import Punish from './punish';
import Title from '../../../common/title';

export default function WorkExperience(props) {
  const [data, setData] = useState({
    info: []
  });
  useEffect(() => {
    async function fetchData() {
      const result =
        (await queryWorkExperience({
          licenseNum: props.detail.cpaLicense,
          firstUniqueId: props.detail.firstUniqueId
        })) || {};
      if (result.statusCode === 1000) {
        setData({ info: result.data });
      } else {
        setData({ info: [] });
        message.info('工作经历' + result.message);
      }
    }
    fetchData();
  }, []);
  return (
    <div className={styles.experience}>
      <Title level={2} text="工作经历" />
      {data.info.length
        ? data.info.map((item, index) => {
            return (
              <div className={styles.date} key={'work' + index}>
                <div>
                  <i className={styles.bigCircle}></i>
                  {moment(item.startTime).format('YYYY-MM-DD')}至
                  {moment(item.endTime).format('YYYY-MM-DD')}
                </div>
                <div className={styles.others}>
                  <i className={styles.smallCircle}></i>
                  {item.company} | {item.position}
                </div>
                <div className={styles.others}>
                  <i className={styles.smallCircle}></i>
                  工作说明: {item.describe}
                </div>
              </div>
            );
          })
        : '暂无数据'}
    </div>
  );
}
