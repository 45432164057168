import isomorphicFetch from "isomorphic-fetch";
import { paramFilter, getRequestQuery, queryStringAll } from "./utils";

function option() {
  return {
    credentials: "include",
    method: "GET",
    cache: "no-cache",
    // body: '',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      // 'Content-Type': 'application/x-www-form-urlencoded'
    },
  };
}

let callback = function(res) {
  /** */
};

function compHeader(headers) {
  //const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // const urlObj = queryStringAll();
  // console.log(urlObj);
  // TO-DO remove stored ssoToken
  // if (urlObj && JSON.stringify(urlObj) !== '{}') {
  //removeCookie('ssoToken');
  //     headers.ssoToken = urlObj.ssoToken;
  // }
  // return (urlObj && urlObj.ssoToken) || '';
  return "";
}

// 一般请求
export default function request(url, params = {}, opt = {}, cb, withLoading) {
  // console.log(1111111);
  if (cb) {
    callback = cb;
  }

  // 全局loading
  if (request.preFetch && !withLoading) {
    request.preFetch();
  }
  const options = Object.assign({}, option(), opt);
  options.method = options.method.toUpperCase();
  if (
    options.method === "GET" ||
    options.method === "HEAD" ||
    options.method === "DELETE"
  ) {
    delete options.headers["Content-Type"];
    const query = getRequestQuery(paramFilter(params));
    if (query) {
      url = `${url}?${query}`;
    }
  }
  if (options.method === "POST" || options.method === "PUT") {
    // options.body = JSON.stringify(paramFilter(params));
    const query = getRequestQuery(params);
    options.body = query;
    if (options.headers["Content-Type"].match("json")) {
      options.body = JSON.stringify(params);
    }
  }

  // 为请求头部添加token
  compHeader(options.headers);
  return isomorphicFetch(url, options)
    .then((response) => {
      return filterResponse(response, cb, opt.responseType);
    })
    .catch(function(err) {
      console.log("Fetch Error : %S", err);
    })
    .finally(() => {
      if (request.afterFetch) {
        request.afterFetch();
      }
    });
}

// 响应拦截
function filterResponse(response, cb, responseType) {
  try {
    let result = null;
    if (responseType === "blob") {
      result = response.blob();
    } else {
      result = response.json();
    }
    return result.then((res) => {
      // 6003未登录时调用frame传过来的回调，去设置登录状态，以控制路由跳转至登录页

      // if (res.statusCode === StatusCode.loginErr) {
      //     localStorage.removeItem('UserInfo');
      //     if (window.location.pathname !== '/user/login') {
      //         window.location.href = '/user/login';
      //     }
      //     return res;
      // }
      // if (res.statusCode === StatusCode.notPower) {
      //     message.destroy();
      //     message.info('暂无权限');
      //     return res;
      // }
      if (cb) {
        cb(res);
      }
      // if(res.statusCode !== 1000 ){
      //     message.destroy();
      //     message.error(res.message);
      // }
      if (request.fetched) {
        request.fetched(res, response);
      }
      return res;
    });
  } catch (e) {
    return response;
  }
}

// 文件与文本字段混合提交
export function postFormData(
  url,
  params,
  uploadKey = "file",
  extraParams = {}
) {
  const res = [];
  if (request.preFetch) {
    request.preFetch();
  }
  Object.entries(params).forEach((o) => {
    if (o[0] === "file") {
      return;
    }
    res.push(`${o[0]}=${encodeURIComponent(o[1])}`);
  });
  const query = res.join("&");
  if (query) {
    url = `${url}?${query}`;
  }
  const fd = new FormData();
  // Object.entries(params).map(item => {
  //     fd.append(item[0], item[1]);
  // });
  fd.append(uploadKey, params.file);
  Object.keys(extraParams).map((key) => {
    fd.append(key, extraParams[key]);
  });
  const options = {
    method: "POST",
    credentials: "include",
    body: fd,
    headers: {},
  };
  compHeader(options.headers);
  return isomorphicFetch(url, options)
    .then((response) => {
      return filterResponse(response);
    })
    .catch(function(err) {
      console.log("Fetch Error : %S", err);
    })
    .finally(() => {
      if (request.afterFetch) {
        request.afterFetch();
      }
    });
}

/**
 * 单纯文件上传，带上传进度
 * @param {*} url
 * @param {*} params
 * @param {上传进度回调，以获取请求进度} onProgress
 */
export function uploadFile(url, params, onProgress, onError, onSuccess) {
  if (request.preFetch) {
    request.preFetch();
  }
  const fd = new FormData();
  for (let key in params) {
    fd.append(key, params[key]);
  }
  // return isomorphicFetch(url, {
  //     method: 'POST',
  //     credentials: 'include',
  //     body: fd
  // }).then(response => {
  //     return filterResponse(response);
  // })
  //     .catch(function(err) {
  //         console.log('Fetch Error : %S', err);
  //     });

  // fetch暂时不支持onprogress，这里为了获取上传进度，只能用XMLHttpRequest 2。
  // 不过FetchObserver对象已在草案阶段，规范实施后就能获取请求的进度
  return new Promise(function(resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("ssoToken", compHeader({}));
    xhr.onload = function() {
      if (request.afterFetch) {
        request.afterFetch();
      }
      resolve(filterResponse(new Response(xhr.response)));
    };
    xhr.onerror = function(e) {
      if (request.afterFetch) {
        request.afterFetch();
      }
      reject(e);
    };
    xhr.upload.onprogress = function(e) {
      onProgress && onProgress(Math.floor((e.loaded / e.total) * 100));
    };
    xhr.upload.onerror = function(e) {
      onError && onError(e);
    };
    xhr.onloadend = function() {
      onSuccess && filterResponse(new Response(xhr.response), onSuccess);
    };
    xhr.send(fd);
  });
}

export const download = (url, params, inits) => {
  let _params = getRequestQuery(paramFilter(params));

  return isomorphicFetch(url + "?" + _params, {
    ...inits,
  })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      if (params.responseType === "blob") {
        return blob;
      } else {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, params.name || params.file);
        } else {
          let _link = document.createElement("a");
          _link.href = URL.createObjectURL(blob);
          _link.download = params.name || params.file;
          _link.click();
          URL.revokeObjectURL(_link.href);
        }
      }
    })
    .catch((err) => err);
};
