import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
/**
 * @desc 柱状图
 */
export default class Bar extends PureComponent {
    getOption() {
        const {
            dataAxis = [],
            data = [],
            left = '40%',
            formatter,
            options
        } = this.props;
        return {
            ...options,
            grid: {
                left,
                right: '8%',
                bottom: '10'
            },
            series: [
                {
                    type: 'bar',
                    data,
                    itemStyle: {
                        barBorderRadius: [0, 10, 10, 0]
                    },
                    label: {
                        show: true,
                        position: 'right',
                        fontSize: '12',
                        color: '#999999',
                        formatter: formatter || '{c}%'
                    }
                }
            ],
            xAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                }
            ],
            yAxis: {
                data: dataAxis,
                type: 'category',
                axisLabel: {
                    fontSize: '12'
                },
                splitLine: {
                    show: false
                }
            },
            
        };
    }
    render() {
        const { style = { height: '250px' }, nodata } = this.props;
        return nodata ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <ReactEcharts
                option={this.getOption()}
                notMerge={true}
                style={style}
                theme={'myTheme'}
            />
        );
    }
}
