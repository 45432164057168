/**
 * @author hjie
 * 我的学习
 */

import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import { Pagination, Tabs, Row, Col, Empty, Modal } from 'antd';
import { getCourse, getColumn } from './service';
import { enrollCourse, enrollColumn } from '../service';
import Card from 'common/card';
import { StatusCode } from 'constants/enum';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { getPermissionIds } from '../../../utils/utils';
import useAuth, { Permission } from '../../../hooks/useAuth';

const { TabPane } = Tabs;

export default function(props) {
  // const { user } = useContext(GlobalContext);
  // const permissionIds = getPermissionIds(user);
  const isAuth491 = useAuth(491);
  const isAuth492 = useAuth(492);
  const [courseList, setCourseList] = useState({
    data: [],
    total: 0,
    loading: true
  });
  const [coursePageNum, setCoursePage] = useState(1);
  // 课程列表
  function getCourseList() {
    getCourse({
      pageNum: coursePageNum,
      pageSize: 9
    }).then(res => {
      const { statusCode, data } = res;
      if (statusCode === StatusCode.success) {
        setCourseList({
          data: data.data,
          total: data.totalCount,
          loading: false
        });
      } else {
        setCourseList({ total: 0, loading: false });
      }
    });
  }
  useEffect(() => {
    getCourseList();
  }, [coursePageNum]);

  const [columnList, setColumnList] = useState({
    data: [],
    total: 0,
    loading: true
  });
  const [columnPageNum, setColumnPage] = useState(1);
  // 专栏列表
  function getColumnList() {
    getColumn({
      pageNum: columnPageNum,
      pageSize: 9
    }).then(res => {
      const { statusCode, data } = res;
      if (statusCode === StatusCode.success) {
        setColumnList({
          data: data.data,
          total: data.totalCount,
          loading: false
        });
      } else {
        setColumnList({ total: 0, loading: false });
      }
    });
  }
  useEffect(() => {
    getColumnList();
  }, [columnPageNum]);

  /**
   * 报名/取消报名
   * @param {*} id 课程id
   * @param {*} isEnroll  1=报名 2=取消报名
   */
  function goEnroll(id, isEnroll, isCourse) {
    let func = isCourse ? enrollCourse : enrollColumn;
    func({
      id,
      status: isEnroll
    }).then(res => {
      if (res.statusCode === StatusCode.success) {
        isCourse ? getCourseList() : getColumnList();
      } else {
        Modal.error({
          title: '提示',
          okText: '确定',
          content: res.message
        });
      }
    });
  }
  return (
    <div className={styles.myStudy}>
      <div
        className={classnames('titleLine', styles.title)}
        mark='\ \ \ '
      ></div>
      <Tabs defaultActiveKey='1'>

     {isAuth491 && <TabPane tab='云课堂' key='1'>
            {courseList.loading ? (
                ''
            ) : courseList.total > 0 ? (
                <>
                  <Row gutter={30}>
                    {courseList.data.map(ele => {
                      return (
                          <Col span={8} key={ele.id}>
                            <Card
                                btncallback={(id, isEnroll) =>
                                    goEnroll(id, isEnroll, true)
                                }
                                data={ele}
                                type='云课堂'
                            />
                          </Col>
                      );
                    })}
                  </Row>
                  <Pagination
                      showQuickJumper
                      current={coursePageNum}
                      pageSize={9}
                      total={courseList.total}
                      onChange={page => setCoursePage(page)}
                  />
                </>
            ) : (
                <Empty />
            )}
          </TabPane>
      }

         { isAuth492 && <TabPane tab='专栏' key='2'>
            {columnList.loading ? (
                ''
            ) : columnList.total > 0 ? (
                <>
                  <Row gutter={30}>
                    {columnList.data.map(ele => {
                      return (
                          <Col span={8} key={ele.id}>
                            <Card
                                btncallback={(id, isEnroll) =>
                                    goEnroll(id, isEnroll, false)
                                }
                                data={ele}
                                type='专栏'
                            />
                          </Col>
                      );
                    })}
                  </Row>
                  <Pagination
                      showQuickJumper
                      current={columnPageNum}
                      pageSize={9}
                      total={columnList.total}
                      onChange={page => setColumnPage(page)}
                  />
                </>
            ) : (
                <Empty />
            )}
          </TabPane>}

      </Tabs>
    </div>
  );
}
