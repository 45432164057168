/**
 * @author Ray
 * @desc 中介机构评价-业务能力分析
 */
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import Title from '../../../../common/title';
// import Table from '../../../../common/table';
import { queryIntermediaryData } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import {
    accountingFirmTotalIncomeRankColumns,
    advisoryBusinessIncomeRatioRankColumns,
    equityInvestmentIncomeRankColumns,
    investBankRankColumns,
    numberOfCertifiedPublicAccountantsColumns,
    restructuringAdvisoryBusinessIncomeRatioRankColumns,
    revenueRatioColumns,
    securitiesInvestmentIncomeRankColumns
} from './columns';

export default function BusinessAbility({ match, zjjgType }) {
    const [query] = useState({ agency: match.params.companyName });
    const [data, setData] = useState({
        bgczPm: [],
        cwgwPm: [],
        gqtzPm: [],
        kjswsPm: [],
        tyZbPm: [],
        tzyhPm: [],
        zckjSlPm: [],
        zjtzPm: []
    });
    useEffect(() => {
        queryIntermediaryData(query).then(res => {
            if (isNotEmpty(res)) {
                setData(res.data);
            }
        });
    }, []);
    return (
        <Row type="flex" justify="space-between" gutter={32}>
            {zjjgType === '保荐公司' && (
                <>
                    <Col span={12}>
                        <Title
                            level={3}
                            text="机构客户投研服务收入占经纪业务收入比例"
                        />
                        <Table
                            dataSource={data.tyZbPm || []}
                            columns={revenueRatioColumns}
                            pagination={
                                data.tyZbPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="投资银行业务收入排名" />
                        <Table
                            dataSource={data.tzyhPm || []}
                            columns={investBankRankColumns}
                            pagination={
                                data.tzyhPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="财务顾问业务收入" />
                        <Table
                            dataSource={data.cwgwPm || []}
                            columns={advisoryBusinessIncomeRatioRankColumns}
                            pagination={
                                data.cwgwPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="并购重组财务顾问业务收入" />
                        <Table
                            dataSource={data.bgczPm || []}
                            columns={
                                restructuringAdvisoryBusinessIncomeRatioRankColumns
                            }
                            pagination={
                                data.bgczPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="证券投资收入排名" />
                        <Table
                            dataSource={data.zjtzPm || []}
                            columns={securitiesInvestmentIncomeRankColumns}
                            pagination={
                                data.zjtzPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="股权投资收入排名" />
                        <Table
                            dataSource={data.gqtzPm || []}
                            columns={equityInvestmentIncomeRankColumns}
                            pagination={
                                data.gqtzPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                </>
            )}
            {zjjgType === '会计师事务所' && (
                <>
                    <Col span={12}>
                        <Title level={3} text="会计事务所总收入排名" />
                        <Table
                            dataSource={data.kjswsPm || []}
                            columns={accountingFirmTotalIncomeRankColumns}
                            pagination={
                                data.kjswsPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <Title level={3} text="注册会计师数量" />
                        <Table
                            dataSource={data.zckjSlPm || []}
                            columns={numberOfCertifiedPublicAccountantsColumns}
                            pagination={
                                data.zckjSlPm.length > 5 && {
                                    pageSize: 5,
                                    size: 'small',
                                    showSizeChanger: true,
                                    pageSizeOptions: ['5', '10']
                                }
                            }
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}
