import request from '../../utils/fetch';
import { API } from '../../constants/enum';

// 获取申请记录
export function getApplyIndexInfo() {
    // 获取上市奖励首页的数据
    return request(
        `${API.V1}/policypractice/user/apply/index`,
        {},
        {
            method: 'GET'
        }
    );
}

// 保存收购申请
export function saveAcquisitionApply(params) {
    return request(
        `${API.V1}/merge/mergeApplication/mergeAcquisition`,
        {
            ...params
        },
        {
            method: 'POST'
        }
    );
}

// 保存转让申请
export function saveMergeApply(params) {
    return request(
        `${API.V1}/merge/mergeApplication/mergeSell`,
        {
            ...params
        },
        {
            method: 'POST'
        }
    );
}

// 提交申请
export function submitApply(id) {
    return request(
        `${API.V1}/merge/mergeApplication/mergeSubmit`,
        {
            id
        },
        {
            method: 'POST'
        }
    );
}

// 获取申请详情
export function applyDetail(id) {
    return request(`${API.V1}/merge/mergeApplication/mergeDetails`, {
        id
    });
}

// 编辑申请
export function editDetail(params) {
    return request(
        `${API.V1}/merge/mergeApplication/mergeEdit`,
        {
            ...params
        },
        {
            method: 'post'
        }
    );
}

// 获取企业股权结构数据回显
export function shareholderStructure(params) {
    return request(`${API.V1}/merge/mergeApplication/equityStructure`, {
        ...params
    });
}
