import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

// 首发上市募集资金情况
export function fundRaisingBarIPO(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightThird/fundRaisingBar/ipo`, params);
}

// 各上市板IPO募集资金情况
export function fundRaisingBarStocks(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightThird/fundRaisingBar/stocks`, params);
}

//获取行业接口
export function industryOptions(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightThird/fundRaisingBar/industryOptions`, params)
}