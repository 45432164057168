/**
 * @author 只能输入正数的数字输入框
 */

import React, { useState } from 'react';
import { PositiveFloat, NegativeNumber } from '../../utils/validator';
import { Input as AntdInput } from 'antd';
const PositiveRegex = PositiveFloat.pattern;
const NegativeRegex = NegativeNumber.pattern;
import classnames from 'classnames';
import styles from './styles.scss';

Input.defaultProps = {
    type: 'text'
};

/**
 *
 * @param {string} type ("positiveNumber"|"text"|"negativeNumber")
 * @param {any} value
 * @param {function} onChange
 * @returns {*}
 * @constructor
 */
export default function Input({ type, onChange, className, ...rest }) {
    const [status, setStatus] = useState(1);

    function _handleChange(e) {
        const _value = e.target.value;
        switch (type) {
            case 'positiveNumber': {
                setStatus(Number(PositiveRegex.test(_value)));
                break;
            }
            case 'negativeNumber': {
                setStatus(Number(NegativeRegex.test(_value)));
                break;
            }
        }

        onChange(_value);
    }

    return (
        <AntdInput
            {...rest}
            className={classnames(className, {
                [styles.error]: 0 === status
            })}
            onChange={_handleChange}
        />
    );
}
