/**
 * @author Ray
 * @desc 入库企业分析-基础信息-财务概况
 */

import React, { useContext, useState } from 'react';
import styles from './styles.scss';
import RiskAssessment from './riskAssessment';
import RiskSummary from './riskSummary';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

export default function FinancialSituation({ match }) {
    const gs = useContext(GlobalContext);
    const [scoresAndIndex, setScoresData] = useState({});
    const [isOwnedCompany] = useState(
        match.params.companyName === gs.user?.organization
    );

    function callback(data) {
        setScoresData(data);
    }

    return (
        <div className={styles.root}>
            {((gs.isAdvanceUser || gs.isVIPUser) && isOwnedCompany) ||
            (gs.isIntermediary && gs.isVIPUser) ||
            gs.isGOV ||
            gs.isQiYuan ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                <>
                    <RiskAssessment data={scoresAndIndex} />
                    <RiskSummary match={match} callback={callback} />
                </>
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
