/**
 * @author Ray
 */
import React, {useContext} from 'react';
import Model from './model';
import View from './view';
import ModifyPassword from './modifyPassword';
import ModifyNumber from './modifyMobilenumber';
import ModifyUser from './modifyUser';
import Message from './message';
import MessageDetail from './messageDetail';
import ModifyMonitor from './modifyMonitor';
import ModifyExpert from './modifyExpert';
import Cancellation from './cancellation';
import ExpertSvg from './images/expertIcon.svg';
import MonitoringSvg from './images/monitoringIcon.svg';
import MobileSvg from './images/mobileIcon.svg';
import SecuritySvg from './images/securityIcon.svg';
import MessageSvg from './images/messageIcon.svg';
import AccountSvg from './images/accountIcon.svg';
import Delete from './images/delete.svg';
import { GlobalContext } from 'common/globalStore/createProvider';


function getRoutes(needChangePwd, isPasscodeExpired){
    return (needChangePwd || isPasscodeExpired) ? [
        {
            exact: false,
            name: '修改密码',
            path: '/userCenter/modifyPassword',
            icon: SecuritySvg,
            visible: true,
            component: ModifyPassword,
            key: '/modifyPassword'
        }
    ] : 
    [
        {
            exact: true,
            name: '消息通知',
            path: '/userCenter/',
            icon: MessageSvg,
            visible: true,
            component: Message,
            key: '/'
        },
        {
            exact: true,
            name: '消息通知',
            path: '/userCenter/message/:msgId',
            visible: false,
            component: MessageDetail
        },
        {
            exact: true, // false
            name: '修改账户信息',
            path: '/userCenter/modifyUser', // modifyUser
            icon: AccountSvg,
            visible: true,
            component: ModifyUser,
            key: '/modifyUser'
        },
        {
            exact: false,
            name: '修改密码',
            path: '/userCenter/modifyPassword',
            icon: SecuritySvg,
            visible: true,
            component: ModifyPassword,
            key: '/modifyPassword'
        },
        {
            exact: false,
            name: '修改手机号',
            path: '/userCenter/modifyMobileNumber',
            icon: MobileSvg,
            visible: true,
            component: ModifyNumber,
            key: '/modifyMobileNumber'
        },
        {
            exact: false,
            name: '专家资料修改',
            path: '/userCenter/modifyExpert',
            icon: ExpertSvg,
            visible: true,
            component: ModifyExpert,
            permissionKey: [3],
            key: '/modifyExpert'
        },
        {
            exact: false,
            name: '风险监测关注列表',
            path: '/userCenter/monitoringList',
            icon: MonitoringSvg,
            visible: true,
            component: ModifyMonitor,
            key: '/monitoringList'
        },
        {
            exact: false,
            name: '注销账户',
            path: '/userCenter/cancellation',
            icon: Delete,
            visible: true,
            component: Cancellation,
            permissionKey: [2, 3],
            key: '/cancellation'
        }
    ];
}

export default function(props) {
    const { user, isPasscodeExpired } = useContext(GlobalContext);
    let Routes = getRoutes(user?.needChangePwd, isPasscodeExpired);
    return (
        <Model {...props} Routes={Routes}>
            {prop => {
                return <View {...prop} />;
            }}
        </Model>
    );
}
