/**
 * @desc  烛形图
 */
import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import Radios from './radio';
import moment from 'moment';
import DataSet from '@antv/data-set';
import { marketMakingIndex, componentIndex, innovateIndex } from './service'

export default function Candlestick() {
    const [type, setType] = useState(1);
    const [data, setData] = useState([]);

    const radiosData = [
        { name: '三板做市', value: 1 },
        { name: '三板成指', value: 2 },
        { name: '创新城指', value: 3 },
    ];
    
    useEffect(() => {
        //请求三板做市数据
        type == 1 ? marketMakingIndex().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        }) :
        //请求三板成指数据
        type == 2 ? componentIndex().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        }) : 
        //请求创新成指数据
        type == 3 ? innovateIndex().then(({ success, data }) => {
            if (success == true) {
                setData(data)
            } else {
                setData([])
            }
        }) : setData([])
    }, [type])
    
    return (
        <div>
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <div>
                <div className={ styles.contents }>
                    <span>时间</span><br />
                    <span>{ data.time }</span>
                </div>
                <div className={ styles.line6Lines }>|</div>
                <div className={ styles.line6Index }>
                    <span>指数</span><br />
                    <span style={{ color: '#6ac5d7' }}>{ data.indexNum }</span>
                </div>
                <div className={ styles.line6Lines1 }>|</div>
                <div className={ styles.price }>
                    <span>涨跌幅</span><br />
                    <span style={{ color: '#ff8c87' }}>{ data.upAndDownRange }</span>
                </div>
                <div className={ styles.line6Lines2 }>|</div>
                <div className={ styles.money }>
                    <span>交易金额</span><br />
                    <span style={{ color: '#ff8c87' }}>{ data.transactionAmount + '万元' }</span>
                </div>
            </div>
            
            {/* <div id='Candlestick' className={ styles.candlestick }></div> */}
        </div>
    )
}
