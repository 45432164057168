import React, { PureComponent } from 'react';
import styles from './styles.scss';
import { intermediary, intermediaryClub, intermediaryLawFirm } from './service';
import Radios from './radio';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../utils/utils';

export default class IntermediaryPunishmentList extends PureComponent {
    constructor(props) {
       super(props);
       this.state = {
         type: 1,
          data: [],
          data1: [],
          data2: []
       }
    }

    componentDidMount = () => {
        const { address } = this.props;
        //表格无缝滚动
        this.industryNews = setInterval(this.taskIndustryNews, 50);

        intermediary({ area: address === '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        })

        intermediaryClub({ area: address == '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
               this.setState({
                  data1: data
               })
            } else {
               <Empty />
            }
        })

        intermediaryLawFirm({ area: address == '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
               this.setState({
                  data2: data
               })
            } else {
               <Empty />
            }
        })
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.address != nextProps.address) {
          const { address } = nextProps;
          //表格无缝滚动
          intermediary({ area: address === '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        })

        intermediaryClub({ area: address == '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
               this.setState({
                  data1: data
               })
            } else {
               <Empty />
            }
        })

        intermediaryLawFirm({ area: address == '全国' ? 'all' : address }).then(({ success, data }) => {
            if (success == true) {
               this.setState({
                  data2: data
               })
            } else {
               <Empty />
            }
        })
      }
    }


    taskIndustryNews = () => {
      if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
          this.refs.newDiv.scrollTop = 0;
      }
      else if (this.refs.newDiv) {
        this.refs.newDiv.scrollTop += 1;
      }
    }

    handleIndustryNewsEnter = () => {
        clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render() {
        const radiosData = [
          { name: '券商', value: 1 },
          { name: '会所', value: 2 },
          { name: '律所', value: 3 }
        ];
        const { type, data, data1, data2 } = this.state;
        return (
          <div className={ styles.IntermediaryPunishmentList }>
            <div className={ styles.rightForthTitle }>
              中介机构行政处罚情况
            </div>
            <div className={ styles.IntermediaryPunishmentListRadio }>
              <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={t => {
                  this.setState({
                     type: t.target.value
                  });
                }}
              />
            </div>
            <div className={ styles.ceShiTable }>
                <div className={ styles.ceShiTabletitle }>
                    <span className={ styles.ceShiTabletext2 }>中介结构名称</span>
                    <span className={ styles.ceShiTabletext2 }>处罚次数</span>
                </div>
                <div
                    ref='newDiv'
                    className={ styles.ceShiTablebody }
                    onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                    onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                >
                    <ul ref='newDivUI'>
                        {
                          data && data.length > 0 && type == 1 ? data.map(this.tableBody) : 
                          data1 && data1.length > 0 && type == 2 ? data1.map(this.tableBody) : 
                          data2 && data2.length > 0 && type == 3 ? data2.map(this.tableBody) : 
                          <span className='noData'>暂无数据</span>
                        }
                    </ul>
                </div>
            </div>
          </div>
        )
    }

    tableBody = (item, index) => {
      return (
          <li key={index}>
              <span className={ styles.ceShiTabletext2 }>
                  {item.fullName}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.amount}
              </span>
          </li>
      );
    }
}