/**
 * @author hjie
 */

import { Carousel, Col, Empty, Row } from 'antd';
import classnames from 'classnames';
import Card from 'common/card';
import { CATEGORY } from 'constants/common';
import React from 'react';
import { Link } from 'react-router-dom';
import bannerBg from '../images/bannerBg.png';
import icon1 from '../images/icon1.png';
import icon2 from '../images/icon2.png';
import icon3 from '../images/icon3.png';
import hooks from './hook.js';
import styles from './styles.scss';

export default function(props) {

    const [bannerData, courseList, columnList, goEnroll] = hooks();

    return (
        <div className={styles.home}>
            <div className={styles.wrapper}>
                <div className={styles.banner}>
                    {
                        bannerData ?
                            bannerData.length ?
                                <Carousel autoplay>
                                    {
                                        bannerData.map(ele => {
                                            const { bannerUrl, title, type, id } = ele;
                                            return <div key={title}>
                                                <Link to={`/listingTraining/${type === 2 ? 'courseDetail' : 'columnDetail'}/${id}`} className={styles.box}>
                                                    <img src={bannerUrl || bannerBg} className={styles.bannerImg} alt="封面图" />
                                                </Link>
                                            </div>;
                                        })
                                    }
                                </Carousel> :
                                <Empty /> : ''
                    }
                </div>
                <div className={styles.menu}>
                    <ul>
                        <li className="fl">
                            <Link to="/listingTraining/course">
                                <img src={icon1} />
                                <p>云课堂</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/listingTraining/column">
                                <img src={icon2} />
                                <p>专栏</p>
                            </Link>
                        </li>
                        <li className="fr">
                            <Link to="/listingTraining/learning">
                                <img src={icon3} />
                                <p>我的学习</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.box}>
                    <h3 className={styles.title}>云课堂</h3>
                    {
                        courseList ?
                            courseList.length ?
                                <>
                                    <Row gutter={30}>
                                        {
                                            courseList.map(ele => {
                                                return <Col span={8} key={ele.id}>
                                                    <Card btncallback={(id, isEnroll) => goEnroll(id, isEnroll, CATEGORY.Course)} data={ele} type={CATEGORY.Course} />
                                                </Col>;
                                            })
                                        }
                                    </Row>
                                    <Link to="/listingTraining/course" className={classnames("fr", styles.more)}>>>更多</Link>
                                </>
                                :
                                <Empty /> : ''
                    }

                </div>
                <div className={styles.box}>
                    <h3 className={styles.title}>专栏</h3>
                    {
                        columnList ?
                            columnList.length ?
                                <>
                                    <Row gutter={30}>
                                        {
                                            columnList.map(ele => {
                                                return <Col span={8} key={ele.id}>
                                                    <Card btncallback={(id, isEnroll) => goEnroll(id, isEnroll, CATEGORY.Column)} data={ele} type={CATEGORY.Column} />
                                                </Col>;
                                            })
                                        }
                                    </Row>
                                    <Link to="/listingTraining/column" className={classnames("fr", styles.more)}>>>更多</Link>
                                </>
                                :
                                <Empty /> : ''
                    }
                </div>
            </div>
        </div>
    );
}
