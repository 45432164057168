import React, {PureComponent, useState} from 'react';
import styles from './styles.scss'
import {limitString} from "../../../../utils/utils";
import {Empty} from "antd";
import {reviewNeeqIpoList} from "./service";

export default class ListedEnterprises extends PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount=()=>{

        //表格无缝滚动
        this.industryNews = setInterval(this.taskIndustryNews, 50);

        reviewNeeqIpoList().then(({ success, data })=>{
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        })

    }

    taskIndustryNews = () => {
        //console.log(this.refs.newDiv.scrollTop);
        if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
            this.refs.newDiv.scrollTop = 0;
        }
        else if (this.refs.newDiv) {
            this.refs.newDiv.scrollTop += 1;
        }
    }

    handleIndustryNewsEnter = () => {
        clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render(){
        const {data} = this.state;
        return(
            <div className={styles.rightSecondDiv}>
                <div className={ styles.ceShiTable }>
                    <div className={ styles.ceShiTabletitle }>
                        <span className={ styles.ceShiTabletext2 }>公司名称</span>
                        <span className={ styles.ceShiTabletext2 }>所属省份</span>
                        <span className={ styles.ceShiTabletext2 }>所属行业</span>
                        <span className={ styles.ceShiTabletext2 }>主办券商</span>
                    </div>
                    <div
                        ref='newDiv'
                        className={ styles.ceShiTablebody }
                        onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                        onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                    >
                        <ul ref='newDivUI'>
                            {data && data.length > 0
                                ?
                                data.map(this.tableBody)
                                : <span className={styles.noData}>暂无数据</span>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    tableBody = (item, index) => {
        return (
            <li key={index}>
              <span className={ styles.ceShiTabletext2 }>
                  {limitString(6,item.companyName)}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {item.ownRegion}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                    {limitString(7,item.ownIndustry)}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {limitString(7,item.ownBroker)}
              </span>
            </li>
        );
    }

}
