/**
 * @author Ray
 * @desc 司法风险分析
 */

import React, { useEffect, useState } from 'react';
import { Gauge, Bar } from '../../../../../echarts';
import { Col, message, Row } from 'antd';
import { judicial } from './service';
import { isNotEmpty } from '../../../../../utils/utils';

let tooltipConfig = {
    tooltip: {
        show: true,
        axisPointer: {
            type: 'none'
        },
        formatter: function([v]) {
            const { value, name, color } = v;
            return `<div class="tooltipBox">
                        ${name}:
                        <span style = "color:${color}"><b>${value}</b></span>
                    <div>`;
        }
    }
};

export default function JudicialInsuranceAnalysis({ match }) {
    let [chartData, setChartData] = useState({ score: 0, itemList: [] });

    useEffect(() => {
        judicial(match.params.companyId).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                setChartData(res.data);
            } else {
                message.error(res.message);
            }
        });
    }, []);

    return (
        <Row>
            <Col span={12}>
                <Gauge data={[{ value: chartData.score || 0, name: '' }]} />
            </Col>
            <Col span={12}>
                <Bar
                    nodata={chartData.itemList.length === 0}
                    dataAxis={chartData.itemList.map(item => item.name)}
                    data={chartData.itemList.map(item => item.count)}
                    left={'15%'}
                    formatter="{c}"
                    options={tooltipConfig}
                />
            </Col>
        </Row>
    );
}
