/**
 * Created by ** on 2017/4/19.
 * Remark 舆情
 */
import styles from  './news.scss';
import React, { Component } from 'react';
import { Pagination, Empty } from 'antd';
import { fieldAnomaly } from 'utils/utils';
import { newsManageDataAPI } from '../../service';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom' //引入withRouter

function timestampToTime(timestamp) {
    timestamp=parseInt(timestamp);
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    return Y + M + D + h + m + s;
}

class News extends Component {
    constructor(props) {
        super(props);
        const { companyName, companyId } = props.match.params;
        this.news = null;
        this.state = {
            companyName,
            companyId,
            listLoading: true, // 列表加载
            total: 0, // 总量
            pageNum: 1,
            pageSize: 10,
            listData: []
        };
    }

    componentDidMount() {
        this.newsList();
    }
    /**
     * 舆情列表
     * @return {[type]} [description]
     */
    newsList() {
        let { companyId, pageNum, pageSize } = this.state;
        newsManageDataAPI({
            companyId: companyId,
            pageNum: pageNum, // 接口分页从0开始
            pageSize
        }).then((res) => {
            if(res.success){
                this.setState({
                    listData: res.data.data.items,
                    total: res.data.data.total
                });
            }
        }).finally( () => {
            this.setState({
                listLoading: false
            });
        });
    }

    /**
     * 分页回调
     * @param  {[type]} pageNumber [当前页码]
     * @return {[type]}            [description]
     */
    pageCallback = (page) => {
        this.setState({
            pageNum: page
        }, function () {
            this.newsList();
        });
    };

    // timestampToTime=(timestamp)=>{
    //     let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //     let Y = date.getFullYear() + '-';
    //     let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //     let D = date.getDate() + ' ';
    //     let h = date.getHours() + ':';
    //     let m = date.getMinutes() + ':';
    //     let s = date.getSeconds();
    //     return Y + M + D + h + m + s;
    // };

    render() {
        const { listData, total, pageNum, pageSize, listLoading } = this.state;

        return (
            <div className={styles['news']}>
                {
                    listLoading ? '' :
                    total > 0 ?
                        <div>
                            {
                                listData.map(function (item, index) {
                                    const { website, docid, title, rtm, uri } = item;
                                    //let _newsRoundup = newsRoundup.replace(/<\/?[^>]*>/g,'');
                                    return (
                                        <div className={styles['listItems']} key={docid}>
                                            <div className = {styles.titleBox}>
                                                <a className={classnames(styles['name'], 'ellipsis')} title = {title}  href= {uri || "javascript:;"} target = "_blank">{title}</a>
                                            </div>
                                            <div className={styles['soure']}>{timestampToTime(rtm)} {website}</div>
                                            {/*<p title={_newsRoundup} dangerouslySetInnerHTML={{__html:fieldAnomaly(newsRoundup)}} />*/}
                                        </div>
                                    );
                                })
                            }
                            <div className= {styles.pageBox}>
                                <Pagination
                                    onChange = { this.pageCallback }
                                    current =  { pageNum }
                                    total = { total }
                                    //showTotal = { () => `共 ${Math.ceil(total / pageSize)} 页 / ${total} 条数据`}
                                    // showSizeChanger
                                    showQuickJumper
                                    size = "small"
                                />
                            </div>
                        </div>
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </div>
        );
    }
}
export default withRouter(News);
