/**
 * @desc 交易情况概览title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line6Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line6Title }>
                交易情况概览
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}