import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';
import { rankingOfBrokerVolumeInnovate, rankingOfVolumeBroker, rankingOfBrokerVolumeSelected } from './service';


export default function Line8List({ type }) {
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);

    useEffect(() => {
        //请求创新层数据
        type == 1 ? rankingOfBrokerVolumeInnovate().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : 
        //请求基础层数据
        type == 2 ? rankingOfVolumeBroker().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : 
        //请求精选层数据
        type == 3 ? rankingOfBrokerVolumeSelected().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : setData([])
    }, [type, num])
    
    function loadMore() {
        setNum(num+1);
    }

    return (
        <div className={ styles.line8List }>
            {
                data && data.length ? (
                    <table>
                        <thead>
                            <tr>
                                <td>公司名称</td>
                                <td>券商数量</td>
                                <td>成交量(万股)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="25%" style={{ borderRadius: '50px 0 0 50px' }}>{ele.brokerName}</td>
                                            <td width="66.9%">{ele.stockNum}</td>
                                            <td width="20%" style={{ borderRadius: '0 50px 50px 0' }}>{ele.totalVolume}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : <Empty />
            }
            <div className={ styles.loadMore } onClick={ loadMore }>
                点击查看更多
            </div>
        </div>
    )
}