/**
 * @author Ray
 * @desc 重点培育公司分析columns
 */
import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import {
    transferStringNull,
    moneyFormatter,
    limitString
} from '../../utils/utils';
// 估值、科创评分、风险评分只对VIP客户显示
export const columns = [
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: '公司名称',
        align: 'center',
        render: (text, record) => (
            <Link
                to={`/investment/enterpriseAnalysis/${text}/${record.bbdQyxxId}/${record.creditCode}`}
            >
                {text}
            </Link>
        ),
        width: '20%'
    },
    {
        key: 'listStatus',
        dataIndex: 'listStatus',
        title: '企业类型',
        align: 'center',
        width: '10%',
        render: text =>
            text ? (
                text.length > 10 ? (
                    <Tooltip title={text}>{limitString(8, text)}</Tooltip>
                ) : (
                    text
                )
            ) : (
                '--'
            )
    },
    {
        key: 'regcapAmount',
        dataIndex: 'regcapAmount',
        title: '注册资本',
        align: 'center',
        width: '10%',
        render: text => (text ? moneyFormatter(text) + '万' : '--')
    },
    {
        dataIndex: 'esDate',
        title: '注册时间',
        align: 'center',
        width: '10%'
    },
    {
        key: 'companyIndustry',
        dataIndex: 'companyIndustry',
        title: '行业',
        align: 'center',
        width: '10%',
        render: text =>
            text ? (
                text.length > 13 ? (
                    <Tooltip title={text}>{limitString(8, text)}</Tooltip>
                ) : (
                    text
                )
            ) : (
                '--'
            )
    },
    {
        key: 'companyCountry',
        dataIndex: 'companyCountry',
        title: '所属地区',
        align: 'center',
        width: '10%',
        render: transferStringNull
    }
];
