/**
 * @author Ray
 */
import { Tabs } from 'antd';
import Card from '../../../../common/cardEx';
import React from 'react';
import { isEmpty } from '../../../../utils/utils';
import BusinessTabs from '../../../../common/financialTabs';
import Description from '../../materialFillin/description';
import Reminder from '../../materialFillin/reminder';
import BasicApplyForm from '../basicApplyMaterial';
import DocumentaryProofForm from '../documentaryProofMaterial';
import FlowChart from '../flowChart';
import PrecessNode from '../processNode';
import styles from './styles.scss';
import Title from '../../../../common/title';

export default class extends React.Component {
    state = {
        columns: [],
        dataSource: []
    };

    static defaultProps = {
        data: {
            basicInfo: {},
            reminder: {},
            basicApplyMaterial: {}
        }
    };

    handleTabChange = args => {
        this.props.onChange(args).then(res => {
            const { source, columns } = res;
            return this.setState({
                columns,
                dataSource: source
            });
        });
    };

    handleCachedSourceLoad = key => {
        const data = this.props.loadSource(key);

        if (isEmpty(data)) {
            return this.setState({
                dataSource: [],
                columns: []
            });
        }

        this.setState({
            dataSource: data.source,
            columns: data.columns
        });
    };

    render() {
        const { data, id, status, ...others } = this.props;
        const { basicInfo, basicApplyMaterial, applyMaterials } = data;
        return (
            <Tabs defaultActiveKey="1" className={styles.tabRoot}>
                <Tabs.TabPane tab="流程详情" key={'1'}>
                    <FlowChart {...others} />
                    <PrecessNode {...others} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="申请信息" key={'2'}>
                    <Title level={2} text="基本信息" />
                    <Description data={basicInfo} />
                    <Title level={2} text="温馨提示" />
                    <Reminder text={basicInfo.tipContent} />
                    <Title level={2} text="基本申请材料" />
                    <BasicApplyForm data={basicApplyMaterial} />
                    <Title level={2} text="上市证明材料" />
                    <DocumentaryProofForm data={applyMaterials} />
                    <Title level={2} text="财务信息" />
                    <BusinessTabs
                        readOnly
                        applyId={id}
                        type={1}
                        status={status}
                        startYear={2017}
                        endYear={2019}
                    />
                </Tabs.TabPane>
            </Tabs>
        );
    }
}
