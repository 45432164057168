import { transferStringNull } from '../../../../utils/utils';
import React from 'react';
// 诉讼相关表头
export const litigationCol = [
    {
        dataIndex: 'sentenceString',
        title: '判决日期',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        width: '20%',
        render: transferStringNull,
    },
    {
        dataIndex: 'title',
        title: '标题',
        align: 'center',
        width: '25%',
        render: transferStringNull
    },
    {
        dataIndex: 'litigant',
        title: '判决详情',
        align: 'center',
        width: '25%',
        render: text => text ? <a href={text} target="_blank" rel="noreferrer">点击查看</a> : '--'
    },
    {
        dataIndex: 'trialCourt',
        title: '判决法院',
        align: 'center',
        width: '10%',
        render: transferStringNull
    }
];

// 处罚相关表头
export const punishCol = [
    // {
    //     width: '8%',
    //     title: '序号',
    //     dataIndex: 'num',
    //     key: 'num',
    //     render: (text, item, index) => {
    //         return index + 1;
    //     },
    //     // width: '5%'
    // },
    {
        width: '22%',
        title: '案件名称',
        dataIndex: 'caseName',
        key: 'caseName',
        render: transferStringNull
        // width: '14%'
    },
    {
        width: '15%',
        title: '文书号',
        dataIndex: 'punishCode',
        key: 'punishCode',
        render: transferStringNull
    },
    {
        width: '15%',
        title: '处罚对象(自然人)',
        dataIndex: 'person',
        key: 'person',
        render: transferStringNull
    },
    {
        width: '15%',
        title: '处罚对象(公司)',
        dataIndex: 'company',
        key: 'company',
        render: transferStringNull
    },
    {
        width: '15%',
        title: '处罚机关',
        dataIndex: 'punishOrg',
        key: 'punishOrg',
        render: transferStringNull
    },
    {
        width: '10%',
        title: '处罚日期',
        dataIndex: 'punishDate',
        key: 'punishDate',
        render: transferStringNull
    }
];

// 被执行人表头
export const executorCol = [
    {
        dataIndex: 'registerDate',
        title: '立案时间',
        align: 'center',
        render: transferStringNull,
        width: '20%'
        // render: text => moment(text).format(TimeFormat)
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execSubject',
        title: '执行标的',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execCourtName',
        title: '执行法院',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    }
];

// 失信被执行人表头
export const noExecutorCol = [
    {
        dataIndex: 'pubdate',
        title: '发布日期',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        render: transferStringNull,
        width: '10%'
    },
    {
        dataIndex: 'dishonestSituation',
        title: '具体情形',
        align: 'center',
        render: transferStringNull,
        width: '40%'
    },
    {
        dataIndex: 'execState',
        title: '履行情况',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    },
    {
        dataIndex: 'execCourtName',
        title: '执行法院',
        align: 'center',
        render: transferStringNull,
        width: '20%'
    }
];

// 立案调查表头
export const caseCol = [
    // {
    //     width: '8%',
    //     title: '序号',
    //     dataIndex: 'num',
    //     key: 'num',
    //     render: (text, item, index) => {
    //         return index + 1;
    //     },
    // },
    {
        width: '30%',
        title: '公告时间',
        dataIndex: 'noticeTime',
        key: 'noticeTime',
        render: transferStringNull
    },
    {
        width: '40%',
        title: '公告标题',
        dataIndex: 'title',
        key: 'title',
        render: transferStringNull
    },
    {
        width: '30%',
        title: '公告链接',
        dataIndex: 'attachmentUrl',
        key: 'attachmentUrl',
        render: (text) => text ? <a href = {text} target = "_blank" rel="noreferrer">点击查看</a> : '--'
    }
];

//  经营异常信息表头
export const businessAnomaliesColumns = [
    {
        dataIndex: 'busexcepList',
        title: '列入经营异常目录原因',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        dataIndex: 'punishOrg',
        title: '作出决定机关',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'rankString',
        title: '列入日期',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        dataIndex: 'removeBusexcepList',
        title: '移出经营异常名录原因',
        align: 'center',
        width: '30%',
        render: transferStringNull
    },
    {
        dataIndex: 'removeString',
        title: '移出日期',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

//  开庭公告表头
export const announcementSessionColumns = [
    {
        dataIndex: 'trialString',
        title: '开庭日期',
        align: 'center',
        width: '10%',
        render: transferStringNull
    },
    {
        dataIndex: 'caseCode',
        title: '案号',
        align: 'center',
        width: '15%',
        render: transferStringNull
    },
    {
        dataIndex: 'title',
        title: '案件标题',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'actionCause',
        title: '案由',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'litigant',
        title: '当事人',
        align: 'center',
        width: '20%',
        render: transferStringNull
    },
    {
        dataIndex: 'city',
        title: '地点',
        align: 'center',
        width: '15%',
        render: transferStringNull
    }
];

//  法院公告表头
export const courtAnnouncementColumns = [
    {
        dataIndex: 'noticeContent',
        title: '公告内容',
        align: 'center',
        width: '50%'
    },
    {
        dataIndex: 'noticeType',
        title: '公告类型',
        align: 'center',
        width: '20%'
    },
    {
        dataIndex: 'noticePeople',
        title: '公告人',
        align: 'center',
        width: '20%'
    }
];