import request from '../../utils/fetch';
import { API } from 'constants/enum';

export function queryListExpert(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryListExpert`,
        data,
        { method: 'get' }
    );
}

export function queryListExpertByCondition(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryListExpertByCondition`,
        data,
        { method: 'get' }
    );
}

export function queryListExpertTeam(data) {
    return request(`${API.V1}/investbank/intermediary/expertTeam/queryListExpertTeam`,
        data,
        { method: 'get' }
    );
}

export function queryListExpertTeamByCondition(data) {
    return request(`${API.V1}/investbank/intermediary/expertTeam/queryListExpertTeamByCondition`,
        data,
        { method: 'get' }
    );
}

export function queryListExpertTeamProject(data) {
    return request(`${API.V1}/investbank/intermediary/expertTeam/queryListExpertTeamProject`,
        data,
        { method: 'get' }
    );
}

export function queryListExpertProject(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryListExpertProject`,
        data,
        { method: 'get' }
    );
}
// 查询专家的项目经历
export function queryProExprience(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryProExperience`,
        data,
        { method: 'get' }
    );
}

// 专家与发审委关系
export function queryExpertAndCommittee(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryExpertAndCommitteePage`,
        data,
        { method: 'get' }
    );
}

// 查询专家的工作经历
export function queryWorkExperience(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryWorkExperience`,
        data,
        { method: 'get' }
    );
}

// 查询专家的教育经历
export function queryEduExperience(data) {
    return request(`${API.V1}/investbank/intermediary/expert/queryEduExperience`,
        data,
        { method: 'get' }
    );
}

// 查询专家的处罚信息
export function queryExpertRiskPunish(data) {
    return request(`${API.V1}/investbank/intermediary/appraise/queryExpertRiskPunish`,
        data,
        { method: 'get' }
    );
}