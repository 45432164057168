import fetch from '../../utils/fetch';
import { API } from 'constants/enum';
/**
 * @desc 获取地图数据
 * @param mapName
 */
export default function getLocalMap(mapName) {
    return fetch(
        `/data/map/${encodeURIComponent(mapName)}.json`,
        undefined,
        undefined,
        undefined,
        true
    );
}

export function authRedOverview(area) {
    return fetch(`${API.V1}/auth/red/overview`, { area });
}
