/**
 * @author Ray
 */

import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import {
    submit as submits,
    preserve as preserves,
    applyBaseDetail,
    historyApplyDetail
} from './service';
import { isNotEmpty } from '../../../utils/utils';
import { GlobalContext } from '../../../common/globalStore/createProvider';

export default function Model({ children, ...rest }) {
    const { user } = useContext(GlobalContext);
    const [state, setState] = useState({
        basicInfo: {},
        basicApplyMaterial: {},
        applyMaterials: {}
    });

    useEffect(() => {
        const { match } = rest;
        const { params } = match;
        if (params.stage) {
            apply(undefined, match?.params?.stage);
        } else {
            getEditApplyInfo(params.id);
        }
    }, []);

    function apply(code, stage) {
        return applyBaseDetail(user?.creditCode, stage).then(res => {
            if (isNotEmpty(res) && res.success) {
                const { data } = res;
                setState({ ...state, basicInfo: data, applyMaterials: data });
            } else {
                message.error(res.message);
            }
        });
    }

    function getEditApplyInfo(id) {
        historyApplyDetail(id).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                const { data } = res;
                const {
                    companyName,
                    code,
                    plate,
                    stage,
                    district,
                    tipContent,
                    id,
                    status,
                    companyProvince
                } = data; // 基本信息
                const { applyCash, manageProUrl, businessLicUrl, yearReportUrl } = data; //  基本申请材料
                const {
                    coachSummaryUrl,
                    approvedCsrcUrl,
                    issueCsrcUrl,
                    acceptanceCsrcUrl,
                    acceptanceExchangeUrl,
                    deliberationIpoCouncilUrl,
                    decription,
                    agreeCsrcUrl,
                    shareTransferUrl,
                    administrativeLicenseCsrcUrl,
                    selfRegulationUrl,
                    considerationResultUrl,
                    agreeCoachBjsrUrl,
                } = data;

                setState({
                    ...state,
                    id,
                    status,
                    basicInfo: {
                        companyName,
                        code,
                        plate,
                        stage,
                        district,
                        companyProvince,
                        policyTipsPO: {
                            content: tipContent
                        }
                    },
                    basicApplyMaterial: {
                        applyCash,
                        manageProUrl,
                        businessLicUrl,
                        yearReportUrl
                    },
                    applyMaterials: {
                        coachSummaryUrl,
                        approvedCsrcUrl,
                        issueCsrcUrl,
                        acceptanceCsrcUrl,
                        acceptanceExchangeUrl,
                        deliberationIpoCouncilUrl,
                        decription,
                        agreeCsrcUrl,
                        shareTransferUrl,
                        administrativeLicenseCsrcUrl,
                        selfRegulationUrl,
                        considerationResultUrl,
                        agreeCoachBjsrUrl,
                        plate
                    }
                });
            } else {
                message.error(res.message);
                rest.history.goBack();
            }
        });
    }

    function submit(data) {
        delete data.policyApplyTipsPO;
        let id = rest.match?.params?.id;
        return submits({
            id,
            ...data
        }).then(res => {
            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
                return {};
            }
            return {
                id: res.data.id,
                success: res.success
            };
        });
    }

    function preserve(data) {
        delete data.policyApplyTipsPO;
        let id = rest.match?.params?.id;
        return preserves({
            id,
            ...data
        }).then(res => {
            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
                return {};
            }
            return {
                success: res.success
            };
        });
    }

    return children({
        ...rest,
        ...state,
        apply: apply,
        submit: submit,
        preserve: preserve
    });
}
