/**
 * @desc 入库企业分析-基础信息-股东列表
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { shareholdersColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { shareHolders } from './service';

/**
 *
 * @returns {*}
 */
export default function ShareHolder({ match, company }) {
    const [query] = useState({ qyxxId: match.params.companyId, pageNum: 1 });

    return (
        <Table
            query={query}
            columns={shareholdersColumns.filter(column => {
                let isIPO = company.ipoCompany === '上市公司';
                return (
                    (isIPO &&
                        column.dataIndex !== 'investName' &&
                        column.dataIndex !== 'subAmount') ||
                    (!isIPO &&
                        column.dataIndex !== 'investRatio' &&
                        column.dataIndex !== 'investAmount')
                );
            })}
            getData={shareHolders}
            parseData={res => (isNotEmpty(res) ? res.data : [])}
            locale={{
                emptyText:
                    '未发现企业的股东记录。不排除存在未公示或时间相对滞后的情况。'
            }}
        />
    );
}
