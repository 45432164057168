'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _class, _temp; /**
                    * Canvas 绘制器, 底层封装了 zrender 引擎
                    */


var _common = require('../../util/common');

var _MainLayer = require('../../layer/MainLayer');

var _MainLayer2 = _interopRequireDefault(_MainLayer);

var _MapLayer = require('../../layer/MapLayer');

var _MapLayer2 = _interopRequireDefault(_MapLayer);

var _dom = require('../../util/dom');

var _common2 = require('../../util/processor/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Painter = (_temp = _class = function () {
    // 绘制器的图层

    function Painter(root, store, handler, setting) {
        (0, _classCallCheck3.default)(this, Painter);

        this._root = root;
        this._store = store;
        this._handler = handler;
        this._setting = setting;
        this.layers = {};
        // 创建主要图层
        this.layers.main = new _MainLayer2.default(root, this._handler, (0, _extends3.default)({}, this._setting, {
            zIndex: 2
        }));
        this._store.setPainter(this);
        // 将鼠标监听器绑定到handler
        this._handler.setMouseDispatcher(this.layers.main.getProxy());
    } // 缓存最后一次绘制的state


    (0, _createClass3.default)(Painter, [{
        key: 'init',
        value: function init(opts) {
            this._options = opts;

            if (this._chart) {
                this._chart.dispose();
            }
            // 重新调整画布大小
            this.resize();

            if (this.layers.main) {
                this.layers.main.reset(opts);
            }

            if (this.layers.map && opts.type !== 'region') {
                this.layers.map.dispose();
                delete this.layers.map;
            }

            this.initMapLayer();
            this.initChart();
            // 触发 painter 准备完毕事件
            this._handler.trigger('painter.ready', this);
        }
    }, {
        key: 'initMapLayer',
        value: function initMapLayer() {
            var type = this._options.type;

            // 初始化高德地图图层

            if (!this.layers.map && type === 'region') {
                this.layers.map = new _MapLayer2.default(this._root, this._handler, (0, _extends3.default)({}, this._setting, {
                    zIndex: 0
                }));
            }
        }

        // 初始化图谱

    }, {
        key: 'initChart',
        value: function initChart() {
            var type = this._options.type;

            var Chart = Painter.chartList[type];
            if (Chart) {
                this._chart = new Chart(this, this._store, this._handler, this._options, this._setting);
            } else {
                throw new Error('\u672A\u52A0\u8F7D ' + type + ' \u5BF9\u5E94\u7684\u56FE\u8C31\u6E32\u67D3\u6A21\u5757');
            }
        }
    }, {
        key: 'clear',
        value: function clear() {
            this._options = null;

            if (this._chart) {
                this._chart.dispose();
                this._chart = null;
            }

            if (this.layers.main) {
                this.layers.main.clear();
            }

            if (this.layers.map) {
                // 地图层直接销毁
                this.layers.map.dispose();
                this.layers.map = null;
            }
        }
    }, {
        key: 'doPaint',
        value: function doPaint() {
            var _this = this;

            var originData = this._store.getOriginData();
            if (originData) {
                this.process();
            } else {
                this._store.loadInitState(function () {
                    _this.process();
                });
            }
        }

        // 交给布局算法类库处理

    }, {
        key: 'process',
        value: function process() {
            var chart = this._chart;
            chart.beforeProcess();
            chart.process();
            chart.afterProcess();
        }
        // 数据状态发生变化, 更新视图数据, 进行 state -> viewData 的diff比较

    }, {
        key: 'paint',
        value: function paint(state) {
            var _this2 = this;

            this._allShapesState = state;

            if ((0, _common.isEmpty)(state)) {
                this.clearLayers();
                return;
            }

            var layer = this.layers.main;
            // 线条每次都重绘
            layer.removeShapes('line');

            // 遍历不同的shape类型
            for (var type in state) {
                var shapesState = state[type];
                var shapes = layer.getShapes();

                /**
                 * layer中删除没有了的图形单元
                 */
                for (var id in shapes) {
                    if (!this.hasState(state, id)) {
                        // 是否立即执行删除
                        var immediately = !this._options.animation;
                        // immediately决定移除有没有动画
                        try {
                            shapes[id].remove(immediately);
                        } catch (e) {
                            console.error('移除 shape 时出现异常, 异常节点:', shapes[_id], e);
                        }
                    }
                }

                for (var _id2 in shapesState) {
                    var shapeState = shapesState[_id2];

                    if (shapes[_id2]) {
                        /**
                         * 更新已有的图形单元
                         */
                        try {
                            shapes[_id2].update(shapeState, this._chart.getOptions()); // shapes[_id].draw(shapeState)
                        } catch (e) {
                            console.error('更新 shape 时出现异常, 异常节点:', shapes[_id2], e);
                        }
                    } else {
                        /**
                         * 添加新增的图形单元
                         */
                        var Shape = this.getRegisterShape(type);
                        var shape = new Shape(shapeState, this._chart.getOptions()); // shapeState包含初始属性和目标属性.
                        layer.add(shape); // 添加时只渲染初始属性
                        try {
                            shape.draw(); // 根据是否开启动画, 设置shape.attr()还是animateTo()到目标属性. draw(state)有状态传入, 说明是更新就用新的状态, 没有就用当前自身的状态.
                        } catch (e) {
                            console.error('创建 shape 时出现异常, 异常节点:', shape, e);
                        }
                    }
                }
            }

            // 防止连续调用 paint() 时不停的触发 painter.rendered 事件
            if (!this._debounce) {
                this._debounce = (0, _common.debounce)(function () {
                    _this2._handler.trigger('painter.rendered', state);
                    _this2._debounce = null;
                }, 100);
            }

            this._debounce();
        }
    }, {
        key: 'hasState',
        value: function hasState(state, id) {
            for (var type in state) {
                var shapesState = state[type];
                if (shapesState[id]) {
                    return true;
                }
            }
            return false;
        }
    }, {
        key: 'getRegisterShape',
        value: function getRegisterShape(type) {
            var Shape = Painter.shapeList[type];
            if (Shape) {
                return Shape;
            }
            throw new Error('\u672A\u52A0\u8F7D ' + type + ' \u5BF9\u5E94\u56FE\u5F62\u7C7B\u578B\u7684\u6E32\u67D3\u6A21\u5757');
        }
    }, {
        key: 'getType',
        value: function getType() {
            return Painter.type;
        }
    }, {
        key: 'getCurrentChartType',
        value: function getCurrentChartType() {
            if (this._chart) {
                return this._chart.getType();
            }
        }
    }, {
        key: 'getRoot',
        value: function getRoot() {
            return this._root;
        }
    }, {
        key: 'getLayers',
        value: function getLayers() {
            return this.layers;
        }
    }, {
        key: 'getLayer',
        value: function getLayer(type) {
            if (type) {
                return this.layers[type];
            }
            return this.layers;
        }
    }, {
        key: 'clearAnimation',
        value: function clearAnimation(forwardToLast) {
            if (!this.layers) {
                return;
            }

            for (var type in this.layers) {
                this.layers[type].clearAnimation(forwardToLast);
            }
        }
    }, {
        key: 'zoomIn',
        value: function zoomIn() {
            var layer = this.layers.map || this.layers.main;
            layer.zoomIn();
        }
    }, {
        key: 'zoomOut',
        value: function zoomOut() {
            var layer = this.layers.map || this.layers.main;
            layer.zoomOut();
        }
    }, {
        key: 'drag',
        value: function drag(detail) {
            if (this.layers && this.layers.main) {
                this.layers.main.drag(detail);
            }
        }
    }, {
        key: 'clearLayers',
        value: function clearLayers() {
            if (!this.layers) {
                return;
            }

            for (var type in this.layers) {
                var layer = this.layers[type];
                layer.removeAll();
            }
        }
    }, {
        key: 'getCenterPoint',
        value: function getCenterPoint() {
            return [this._setting.width / 2, this._setting.height / 2];
        }
    }, {
        key: 'getWidth',
        value: function getWidth() {
            return this._setting.width;
        }
    }, {
        key: 'getHeight',
        value: function getHeight() {
            return this._setting.height;
        }
    }, {
        key: 'resize',
        value: function resize() {
            var sizeChanged = false;
            var style = window.getComputedStyle(this._root);
            var width = style.width.slice(0, -2);
            var height = style.height.slice(0, -2);

            if (this._setting.width !== width) {
                this._setting.width = width;
                sizeChanged = true;
            }
            if (this._setting.height !== height) {
                this._setting.height = height;
                sizeChanged = true;
            }

            if (sizeChanged) {
                for (var type in this.layers) {
                    var layer = this.layers[type];
                    layer.resize({
                        width: width,
                        height: height
                    });
                }
            }
        }
    }, {
        key: 'createScreenshot',
        value: function createScreenshot() {
            var _this3 = this;

            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _callback = arguments[1];
            var _options$bgcolor = options.bgcolor,
                bgcolor = _options$bgcolor === undefined ? '#fff' : _options$bgcolor,
                watermark = options.watermark;

            var state = this._allShapesState || {};
            var target = (0, _common2.getTarget)(state);
            var chartType = this._chart.getType();
            if (bgcolor === 'transparent') {
                bgcolor = null;
            }

            // 有地图图层
            if (this.layers.map) {
                var ctx = (0, _dom.createCanvas)(this._setting.width, this._setting.height, bgcolor);
                // 绘制地图层
                this.layers.map.drawLayer(ctx);
                // 绘制节点层
                this.layers.main.drawLayer(ctx);
                // 绘制图例
                this.layers.map.drawAttachment(ctx, options, chartType, target);
                // 绘制水印
                this.layers.map.drawWatermark(watermark, ctx, _callback);
                // 没有地图层
            } else {
                this.layers.main.drawFullLayer({
                    state: state,
                    getRegisterShape: this.getRegisterShape,
                    chartType: chartType,
                    backgroundColor: bgcolor,
                    callback: function callback(ctx) {
                        _this3.layers.main.drawAttachment(ctx, options, chartType, target);
                        _this3.layers.main.drawWatermark(watermark, ctx, _callback);
                    }
                });
            }
        }
    }, {
        key: 'toDataURL',
        value: function toDataURL() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _options$type = options.type,
                type = _options$type === undefined ? 'image/png' : _options$type,
                callback = options.callback;

            this.createScreenshot(options, function (ctx) {
                var dataURL = ctx.canvas.toDataURL(type);
                callback && callback(dataURL);
            });
        }
    }, {
        key: 'toBlob',
        value: function toBlob() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _options$type2 = options.type,
                type = _options$type2 === undefined ? 'image/png' : _options$type2,
                callback = options.callback;

            this.createScreenshot(options, function (ctx) {
                // IE10-Edge
                if ('msToBlob' in ctx.canvas) {
                    // IE 不支持参数, 直接返回png格式
                    var blob = ctx.canvas.msToBlob();
                    callback(blob);
                } else {
                    ctx.canvas.toBlob(callback, type);
                }
            });
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this._chart && this._chart.dispose && this._chart.dispose();
            for (var type in this.layers) {
                var layer = this.layers[type];
                layer.dispose();
            }
            this._root = null;
            this._store = null;
            this._chart = null;
            this.layers = null;
            this._handler = null;
            this._setting = null;
            this._options = null;
        }
    }], [{
        key: 'registerChart',
        value: function registerChart(type, chart) {
            Painter.chartList[type] = chart;
        }
    }, {
        key: 'registerShape',
        value: function registerShape(type, shape) {
            Painter.shapeList[type] = shape;
        }
    }]);
    return Painter;
}(), _class.chartList = {}, _class.shapeList = {}, _class.type = 'canvas', _temp);
exports.default = Painter;
module.exports = exports['default'];