import React from 'react';
import { Radio } from 'antd';
import style from './radio.scss';

/**
 * @desc 单选按钮
 */

export default function Radios(props) {
    const { data = [] } = props;
    return (
        data.length && <Radio.Group {...props} buttonStyle="solid" className={style.radios}>
            {data.map(item => <Radio.Button key={item.value} value={item.value}>{item.name}</Radio.Button>)}
        </Radio.Group>
    )
}