/**
 * @author Ray
 */
import { message } from 'antd';
import classnames from 'classnames';
import Back from '../../../common/backBtn';
import React, { useState } from 'react';
import FinancialTabs from '../../../common/financialTabs';
import BasicMaterial from './basicApplyMaterial';
import BasicDescription from './description';
import DocumentaryProofMateial from './documentaryProofMaterial';
import Reminder from './reminder';
import styles from './styles.scss';
import Title from '../../../common/title';
// import ModalEx from '../../../common/modalEx';
import { CommitButton, SaveButton } from '../../../common/buttons';
import { isNotEmpty } from '../../../utils/utils';
// import { GlobalContext } from '../../../common/globalStore/createProvider';
import * as actions from '../../investment/script/actions';

let formMap = new Map();

export default function(props) {
    // const gs = useContext(GlobalContext);
    const {
        history,
        basicInfo,
        basicApplyMaterial,
        applyMaterials,
        id,
        status,
        dispatch
    } = props;
    const [needUpdate, setNeedUpdate] = useState(0);
    // 申请提交的弹窗提示
    // const [visible, setVisible] = useState();
    // const [modalInfo, setModalInfo] = useState({
    //     id: null,
    //     key: 'commit',
    //     title: '提交成功',
    //     message: '请等待审批结果，前往流程查询界面可以查看当前流程进度。'
    // });

    function handleFormInit(name, form) {
        formMap.set(name, form);
    }

    function handleSubmit() {
        let flag = true;
        let materials = {};
        dispatch(actions.closeModal());
        formMap.forEach(function(form) {
            form.validateFieldsAndScroll(function(errors, values) {
                if (errors) {
                    flag = false;
                    return;
                }
                Object.assign(materials, values);
            });
        });

        if (flag) {
            // Promise.all([
            //     checkFinanceData(2, gs.user?.creditCode, 1),
            //     checkFinanceData(3, gs.user?.creditCode, 1),
            //     checkFinanceData(4, gs.user?.creditCode, 1)
            // ]).then(([f1, f2, f3]) => {
            //     if (f1 && f2 && f3) {
            props
                .submit({
                    ...materials,
                    ...basicInfo,
                    needUpdate,
                    tipContent: basicInfo?.policyTipsPO?.content, // 温馨提示的内容
                    type: 1
                })
                .then(function(res) {
                    const { success, id } = res;
                    if (success) {
                        dispatch(
                            actions.showModal({
                                content:
                                    '请等待审批结果，前往流程查询界面可以查看当前流程进度。',
                                title: '提交成功',
                                onOk: () => {
                                    dispatch(actions.closeModal());
                                    history.push({
                                        pathname: `/policy/process/${id}`
                                    });
                                },
                                onClose: () => {
                                    dispatch(actions.closeModal());
                                    history.push({ pathname: '/policy' });
                                },
                                onCancel: () => {
                                    dispatch(actions.closeModal());
                                    history.push({ pathname: '/policy' });
                                }
                            })
                        );
                    } else {
                        message.error(res.message);
                    }
                });
            // } else {
            //     dispatch(
            //         actions.showModal({
            //             content: '请完善填报信息！',
            //             title: '提示',
            //             onOk: () => dispatch(actions.closeModal()),
            //             onCancel: () => dispatch(actions.closeModal()),
            //             onClose: undefined
            //         })
            //     );
            // }
            // });
        }
    }

    function handleSave() {
        let flag = true;
        let materials = {};
        dispatch(actions.closeModal());
        formMap.forEach(function(form) {
            form.validateFieldsAndScroll(function(errors, values) {
                if (isNotEmpty(errors)) {
                    flag = false;
                    return;
                }

                Object.assign(materials, values);
            });
        });

        if (flag) {
            props
                .preserve({
                    ...materials,
                    ...basicInfo,
                    needUpdate,
                    tipContent: basicInfo?.policyTipsPO?.content, // 温馨提示的内容
                    type: 1
                })
                .then(function(res) {
                    const { success } = res;
                    if (success) {
                        history.push({ pathname: '/policy' });
                        dispatch(actions.closeModal());
                    }
                });
        }
    }

    return (
        <section className={styles.root}>
            <Back history={history} url="/policy" />
            <Title level={2} text="基本信息" />
            <BasicDescription data={basicInfo} />
            <Title level={2} text="温馨提示" />
            <Reminder text={basicInfo?.policyTipsPO?.content} />
            <Title level={2} text="基本申请材料" />
            <BasicMaterial data={basicApplyMaterial} onInit={handleFormInit} />
            {/* <Title level={2} text="企业综合经营情况证明" /> */}
            <Title level={2} text="上市证明材料" />
            <DocumentaryProofMateial
                data={applyMaterials}
                onInit={handleFormInit}
            />
            <Title level={2} text="财务信息" />
            <FinancialTabs
                readOnly
                applyId={id}
                status={status}
                history={history}
                type={1}
                onRefreshClick={() => setNeedUpdate(1)}
            />
            <div
                className={classnames('center', {
                    [styles.footButton]: true
                })}
            >
                <SaveButton type="default" text="保存" onClick={handleSave} />
                <CommitButton
                    text="提交"
                    onClick={() => {
                        dispatch(
                            actions.showModal({
                                content: '确认提交申请？',
                                title: '提示',
                                onOk: () => handleSubmit(),
                                onClose: () => dispatch(actions.closeModal()),
                                onCancel: () => dispatch(actions.closeModal())
                            })
                        );
                    }}
                />
            </div>
        </section>
    );
}
