/**
 * @author Ray
 * @desc 中介机构评价-合法性
 */

import React, { useEffect, useState } from 'react';
import { GroupBar } from '../../../../echarts';
import { queryHfxCount } from '../intermediaryEvaluation/service';
import { isNotEmpty, isEmpty } from '../../../../utils/utils';
import Title from '../../../../common/title';
import { Divider } from 'antd';

export default function LegalAnalysis({ match, zjjgType }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    queryHfxCount(match.params.companyId, match.params.companyName).then(
      res => {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
          const {
            disCount,
            legalPersonCount,
            documentCount
            // riskNum
          } = res.data;
          setData([
            { name: '失信被执行人', value: disCount || 0 },
            { name: '被执行人', value: legalPersonCount || 0 },
            { name: '裁判文书', value: documentCount || 0 }
            // { name: '开庭公告', value: riskNum || 0 }
          ]);
        }
      }
    );
  }, []);

  return zjjgType !== '律师事务所' ? (
    <>
      <Title level={2} text="合法性" />
      <Divider />
      <GroupBar
        nodata={isEmpty(data)}
        yAxisFormatter={value => value}
        series={[{ type: 'bar', data }]}
        options={{
          yAxis: {
            axisLabel: {
              formatter: value => {
                if (/^[1-9]\d*$/.test(value)) {
                  return value;
                }
              }
            }
          },
          xAxis: {
            data: [
              '失信被执行人',
              '被执行人',
              '裁判文书'
              // '开庭公告'
            ]
          }
        }}
      />
    </>
  ) : null;
}
