/**
 * @author Ray
 * @desc 购买意向
 */
import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { InputNumber, Input, Form } from 'antd';
import { Selector } from '../home/apply';
import { PositiveFloat, increment } from '../../../utils/validator';
import { QIYUAN_CATEGORY } from '../../../constants/enum';
import Text, { SelectorPreview, TextareaPreview } from '../../../common/text';
import PropTypes from 'prop-types';
import { moneyFormatter } from '../../../utils/utils';

const { Item } = Form;

export const GroupInput = React.forwardRef(function(
    { value = [], onChange, isPreview, ...others },
    ref
) {
    // 输入框的变化
    function handleChange(v, index) {
        if (v === '') v = undefined;
        let _value = value;
        _value[index] = v;
        onChange(_value);
    }

    return (
        <Input.Group compact>
            {isPreview ? (
                <Text
                    style={{ lineHeight: '32px' }}
                    value={value?.[0] || ''}
                    formatter={value?.[0] === null ? () => '--' : moneyFormatter}
                    suffix={value?.[0] === null ? '' : '万元'}
                />
            ) : (
                <InputNumber
                    onChange={v => handleChange(v, 0)}
                    value={value?.[0]}
                    style={{ width: 181 }}
                    maxLength={15}
                    placeholder={'请输入'}
                    {...others}
                />
            )}
            <p style={{ lineHeight: '32px', padding: '0 13px' }}>至</p>
            {isPreview ? (
                <Text
                    style={{ lineHeight: '32px' }}
                    value={value?.[1] || ''}
                    formatter={value?.[1] === null ? () => '--' : moneyFormatter}
                    suffix={value?.[1] === null ? '' : '万元'}
                />
            ) : (
                <InputNumber
                    style={{ width: 181 }}
                    onChange={v => handleChange(v, 1)}
                    value={value?.[1]}
                    maxLength={15}
                    placeholder={'请输入'}
                    {...others}
                />
            )}
        </Input.Group>
    );
});

function Intention_({ form, onInit, isPreview }, ref) {
    const { getFieldDecorator } = form;

    React.useImperativeHandle(ref, () => ({ form }));

    useEffect(function() {
        onInit(form);
    }, []);

    return (
        <Form
            className={styles.intention}
            wrapperCol={{ xs: 16 }}
            labelCol={{ xs: 8 }}
        >
            <Item label={'标的注册资本(万元)'}>
                {getFieldDecorator('subjectCapital', {
                    initialValue: [],
                    rules: [
                        increment([
                            '请输入标的资本最小值',
                            '请输入标的资本最大值'
                        ])
                    ]
                })(<GroupInput isPreview={isPreview} disabled={isPreview} />)}
            </Item>
            <Item label={'期望交易结构'}>
                {getFieldDecorator('expectedStructure', {
                    rules: []
                })(
                    isPreview ? (
                        <TextareaPreview />
                    ) : (
                        <Input.TextArea
                            disabled={isPreview}
                            autosize={{ maxRows: 6, minRows: 4 }}
                            maxLength={500}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'期望交易价格'}>
                {getFieldDecorator('expectedPrice', {
                    rules: []
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            maxLength={15}
                            disabled={isPreview}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的所属行业'}>
                {getFieldDecorator('targetIndustry', {
                    rules: []
                })(
                    isPreview ? (
                        <Text />
                    ) : (
                        <Input
                            maxLength={50}
                            disabled={isPreview}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的主营产品'}>
                {getFieldDecorator('mainProduct', {
                    rules: []
                })(
                    isPreview ? (
                        <TextareaPreview />
                    ) : (
                        <Input.TextArea
                            disabled={isPreview}
                            autosize={{ maxRows: 6, minRows: 4 }}
                            maxLength={500}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的近一年营业收入大于(万元)'}>
                {getFieldDecorator('oneYearIncome', {
                    rules: [PositiveFloat]
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            disabled={isPreview}
                            maxLength={15}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的近一年净利润大于(万元)'}>
                {getFieldDecorator('oneYearProfit', {
                    rules: [PositiveFloat]
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            disabled={isPreview}
                            maxLength={15}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的近一年资产总计大于(万元)'}>
                {getFieldDecorator('oneYearAssets', {
                    rules: [PositiveFloat]
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            disabled={isPreview}
                            maxLength={15}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'标的发展阶段'}>
                {getFieldDecorator('progressionStage', {
                    rules: []
                })(
                    isPreview ? (
                        <SelectorPreview options={QIYUAN_CATEGORY} />
                    ) : (
                        <Selector
                            disabled={isPreview}
                            options={QIYUAN_CATEGORY}
                        />
                    )
                )}
            </Item>
            <Item label={'标的注册地位置'}>
                {getFieldDecorator('targetRegistrationLocation', {
                    rules: []
                })(
                    isPreview ? (
                        <Text />
                    ) : (
                        <Input
                            disabled={isPreview}
                            maxLength={50}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
            <Item label={'其他购买意向'}>
                {getFieldDecorator('otherIntentions', {
                    rules: []
                })(
                    isPreview ? (
                        <TextareaPreview />
                    ) : (
                        <Input.TextArea
                            disabled={isPreview}
                            autosize={{ maxRows: 6, minRows: 4 }}
                            maxLength={500}
                            placeholder="请输入"
                        />
                    )
                )}
            </Item>
        </Form>
    );
}

Intention.defaultProps = {
    onChange: () => {}
};

Intention.propTypes = {
    onChange: PropTypes.func
};

// 购买意向表
export default function Intention({ onChange, onInit, isPreview }) {
    let [Element, setElement] = useState(null);

    useEffect(function() {
        let Form_ = Form.create({
            name: 'intention',
            onValuesChange: (props, changedValues, allValues) =>
                onChange({ mergePurch: { ...allValues } })
        })(React.forwardRef(Intention_));

        setElement(<Form_ onInit={onInit} isPreview={isPreview} />);
    }, []);

    return Element;
}
