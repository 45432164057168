/**
 * @author Ray
 * @desc 基本申请材料
 */
import React, { useEffect, useContext } from 'react';
import { Form } from 'antd';
import ImageUpload from '../../../../common/uploadImage';
import Typo from '../../../../common/addonTypo';
import styles from './styles.scss';
import { moneyFormatter } from '../../../../utils/utils';
import { GlobalContext } from "../../../../common/globalStore/createProvider";

function BasicMaterialForm(props) {
    const { form, data = {} } = props;
    const gs = useContext(GlobalContext);
    const prefix = gs.isPub
        ? 'http://file.beijingipo.com.cn/'
        : 'http://192.168.88.106/'


    useEffect(
        function() {
            form.setFieldsValue({ ...data });
        },
        [data]
    );

    return (
        <Form
            className={styles.formRoot}
            labelCol={{ xs: { span: 8 } }}
            wrapperCol={{ xs: { span: 16 } }}
        >
            <Form.Item label="申请金额">
                {form.getFieldDecorator('applyCash', {
                    rules: [
                        {
                            required: true,
                            message: '请填写金额！'
                        }
                    ]
                })(<Typo suffix="万元" formatter={moneyFormatter} />)}
            </Form.Item>
            <Form.Item label="营业执照副本复印件">
                {form.getFieldDecorator('businessLicUrl', {
                    rules: [
                        {
                            required: true,
                            message: '请上传文件！'
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
            </Form.Item>
            <Form.Item label="经营情况介绍及证明">
                {form.getFieldDecorator('manageProUrl', {
                    rules: [
                        {
                            required: true,
                            message: '请上传文件！'
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
            </Form.Item>
            <Form.Item label="补贴资金申请表">
                {form.getFieldDecorator('yearReportUrl', {
                    rules: [
                        {
                            required: true,
                            message: '请上传文件！'
                        }
                    ]
                })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
            </Form.Item>
        </Form>
    );
}

export default Form.create({ name: 'basicMaterialForm' })(BasicMaterialForm);
