/**
 * @author hjie
 */
import style from './style.scss';
import React, { useState, useRef, useEffect } from 'react';
import { Input, Form, Button, Icon } from 'antd';
import getKeyHook from '../../../hooks/getKeyHook';
import VerifyCode from 'common/verifyCode';
import VerifyPhone from 'common/verifyPhone';
import useStorage from '../../../hooks/useStorage';

const TEMPLATE = 2;
function PswLogin({ form, history, toLogin, codeEl, smsVisible }) {
    const { getRsaPassWord, getRsaPreparedAsync }  = getKeyHook();
    // let [smsVisible, setSmsVisible] = useState(false);
    const phoneEl = useRef(null);

    // 图片验证码
    const [validateImg, setValidateImg] = useState(false);
    const imgCallBack = tip => {
        setValidateImg(tip);
    };

    const { getFieldDecorator } = form;
    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                getRsaPreparedAsync().then(res => {
                    if (res && res.success) {
                        let rsaPassWord = getRsaPassWord(values.password);  // 对密码进行加密
                        toLogin({ ...values, systemState: 1, password: rsaPassWord }, history); // 1：前台 2：后台 默认传2
                    }
                })
                // toLogin({...values, systemState: 1}, history); // 1：前台 2：后台 默认传2
            }
        });
    }
    // 手机验证码 发送手机验证码之前需验证手机号码
    function getCode() {
        form.validateFields(['username', 'imgCaptcha'], (err, values) => {
            if (!err && !validateImg && values.imgCaptcha) {
                phoneEl.current.toSend(values.username, TEMPLATE, values.imgCaptcha); // 倒计时并发送验证码  
            }
            if (!values.imgCaptcha) {
                setValidateImg(true);
            }
        });
    }

    return (
        <Form onSubmit={handleSubmit} >
            <Form.Item
            // label="用户名"
            >
                {getFieldDecorator('username', {
                    validateTrigger: 'onBlur',
                    rules: [
                        { required: true, message: '请输入用户名' },
                        { whitespace: true, message: '请输入用户名' }
                    ]
                })(
                    <Input prefix={<Icon type="user" />} placeholder={'请输入用户名'} />
                )}
            </Form.Item>

            <Form.Item
            // label="密码"
            >
                {getFieldDecorator('password', {
                    validateTrigger: 'onBlur',
                    rules: [
                        { required: true, message: '请输入密码' },
                        { whitespace: true, message: '请输入密码' }
                    ]
                })(
                    <Input type="password" prefix={<Icon type="lock" />} placeholder="请输入密码" />
                )}
            </Form.Item>
            {
                smsVisible &&
                <Form.Item required className="formCaptcha">
                    {getFieldDecorator('imgCaptcha', {
                        initialValue: ''
                    })(
                        <VerifyCode
                            callBack={imgCallBack}
                            showErrTip={validateImg}
                            template={TEMPLATE}
                            ref={codeEl}
                        />
                    )}
                </Form.Item>
            }
            {
                smsVisible &&
                <Form.Item>
                    {getFieldDecorator('smsCode', {
                        rules: [
                            { required: true, message: '请输入验证码' }
                        ]
                    })(
                        <VerifyPhone handleClick={getCode} ref={phoneEl} />
                    )}
                </Form.Item>
            }
            <Form.Item
            >
                <Button type="primary" htmlType="submit" className={style['loginButton']} style={{ width: '100%' }}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    );
}

const PswLoginForm = Form.create()(PswLogin);
export default PswLoginForm;