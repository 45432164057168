import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 获取申请历史
 * @param examineStatus
 * @param pageNum
 * @param pageSize
 * @param submitStatus
 */
export function getApply({ examineStatus, pageNum, pageSize, submitStatus }) {
    return request(`${API.V1}/investbank/self/apply/page`, {
        examineStatus,
        pageNum,
        pageSize,
        submitStatus
    });
}

/**
 * @desc 获取banner图
 * @param type
 */
export function getBanner(type) {
    return request(`${API.V1}/auth/advertising/list`, {
        type,
        pageNum: 1,
        pageSize: 10
    });
}
