/**
 * @author Ray
 * @desc
 */

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';

export const Bar = function({ showLoading, data, yAxisData }) {
    function getOptions() {
        return {
            grid: {
                top: 0,
                bottom: 45
            },
            tooltip: {
                show: true,
                axisPointer: { type: 'none' },
                padding: [8, 16],
                formatter: '{b} {c}'
            },
            series: [
                {
                    type: 'bar',
                    label: {
                        formatter: '{c}'
                    },
                    data: data
                }
            ],
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: yAxisData
            }
        };
    }

    return (
        <ReactEcharts
            theme="myTheme"
            option={getOptions()}
            notMerge={true}
            style={{ height: 380 }}
            showLoading={showLoading}
        />
    );
};

export default function EvaluationIndex({ data }) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isNotEmpty(data)) {
            setLoading(false);
        }
    }, [data]);

    return (
        <Bar
            nodata={isEmpty(data)}
            data={data}
            yAxisData={['合规性', '合法性', '业务能力']}
            formatter={'{c}'}
            style={{ height: 380 }}
            showLoading={loading}
        />
    );
}
