/**
 * @author 板块
 */
import React, { useState, useEffect } from 'react';
import { getShowPlate } from './service';
import { Select } from 'antd';
import { isNotEmpty } from '../../utils/utils';
export default function PlateSelection(props) {
  const { Option } = Select;
  const { isAll, filterKey } = props; //默认显示全部
  const [data, setData] = useState([]);
  function onShowPlate() {
    getShowPlate().then(res => {
      if (res && res.success) {
        setData(res.data);
      }
    });
  }
  useEffect(() => {
    onShowPlate();
  }, []);

  return (
    <Select
      placeholder="请选择"
      {...props}
      onChange={props.onChange ? props.onChange : () => {}}
      style={{ width: props.width }}
      getPopupContainer={element => element.parentElement}
    >
      {!isAll ? <Option value={null}>全部</Option> : ''}
      {data
        .filter(item => {
          if (isNotEmpty(filterKey)) {
            return item && item.includes(filterKey);
          }
          return Boolean(item);
        })
        .map(item => (
          <Option key={item}>{item}</Option>
        ))}
    </Select>
  );
}
