/**
 * @desc 在审申请挂牌企业基本情况一览title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line10Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line10Title }>
                在审申请挂牌企业基本情况一览
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}