/**
 * @desc buttons
 */

import React from 'react';
import styles from './styles.scss';
import { Button, Icon } from 'antd';
import Edit from './svg/edit.svg';
import Reject from './svg/reject.svg';
import Delete from './svg/delete.svg';
import Preview from './svg/preview.svg';
import Hammer from './svg/financialHammer.svg';
import Financial from './svg/financial.svg';
import Records from './svg/records.svg';
import Invest from './svg/invest.svg';
import Submit from './svg/submit.svg';
import Template from './svg/template.svg';
import Upload from './svg/uploadToCloud.svg';

/**
 * @desc 编辑按钮
 * @param props
 * @returns {*}
 * @constructor
 */
export function EditButton(props) {
    return (
        <Button type="link" {...props} className={styles.edit} size="small">
            <Icon component={Edit} />
            {props.text || '编辑详情'}
        </Button>
    );
}

/**
 * @desc 查看按钮
 * @param props
 * @returns {*}
 * @constructor
 */
export function PreviewButton(props) {
    return (
        <Button type="link" {...props} className={styles.preview} size="small">
            <Icon component={Preview} />
            查看详情
        </Button>
    );
}

/**
 * @desc 删除按钮
 * @param props
 * @returns {*}
 * @constructor
 */
export function DeleteButton(props) {
    return (
        <Button type="link" {...props} className={styles.delete} size="small">
            <Icon component={Delete} />
            删除
        </Button>
    );
}

/**
 * @desc 驳回按钮
 * @param props
 * @returns {*}
 * @constructor
 */
export function RejectButton(props) {
    return (
        <Button type="link" {...props} className={styles.reject} size="small">
            <Icon component={Reject} />
            {props.text || '驳回'}
        </Button>
    );
}

/**
 * @desc 提交按钮
 * @param props
 * @returns {*}
 * @constructor
 */
export function SubmitButton(props) {
    return (
        <Button type="link" {...props} className={styles.submit} size="small">
            <Icon component={Submit} />
            {props.text || '提交'}
        </Button>
    );
}

/**
 * @desc 很大的转让按钮
 * @param props
 */
export function FinancialButton(props) {
    return (
        <Button {...props} className={styles.financial}>
            <Icon component={Financial} />
            我要转让
        </Button>
    );
}

/**
 * @desc 很大的收购按钮
 * @param props
 */
export function FinancialHammerButton(props) {
    return (
        <Button {...props} className={styles.hammer}>
            <Icon component={Hammer} />
            我要收购
        </Button>
    );
}

/**
 * @desc 申请上市奖励
 * @param props
 * @returns {*}
 * @constructor
 */
export function ApplyButton(props) {
    return (
        <Button {...props} className={styles.applyButton}>
            <Icon component={Records} />
            申请上市奖励
        </Button>
    );
}

/**
 * @desc 上市咨询服务
 * @param props
 * @returns {*}
 * @constructor
 */
export function ConsultantButton(props) {
    return (
        <Button {...props} className={styles.applyButton}>
            <Icon component={Invest} />
            上市咨询服务
        </Button>
    );
}

/**
 * @desc 下载模板
 */
export function TemplateButton(props) {
    return (
        <Button {...props} type="outlined" className={styles.template}>
            <Icon component={Template} />
            {props.text || '申请表下载'}
        </Button>
    );
}

/**
 * @desc 上传文件
 */
export function UploadButton(props) {
    return (
        <Button {...props} type="outlined" className={styles.template}>
            <Icon component={Upload} />
            {props.text || '上传文件'}
        </Button>
    );
}

/**
 * @desc 移除行
 */
export function DeleteRowButton(props) {
    return (
        <Button
            {...props}
            icon="minus-circle"
            type="outlined"
            className={styles.deleteRowButton}
        >
            删除行
        </Button>
    );
}

/**
 * @desc 移除行
 */
export function AddRowButton(props) {
    return (
        <Button
            {...props}
            icon="plus-circle"
            type="outlined"
            className={styles.addRowButton}
        >
            {props.text || '添加项目'}
        </Button>
    );
}

/**
 * @desc 提交
 * @param props
 * @returns {*}
 * @constructor
 */
export function SaveButton(props) {
    return (
        <Button type="primary" {...props} className={styles.base}>
            {props.text || '保存'}
        </Button>
    );
}

/**
 * @desc 保存
 * @param props
 * @returns {*}
 * @constructor
 */
export function CommitButton(props) {
    return (
        <Button type="primary" {...props} className={styles.base}>
            {props.text || '提交'}
        </Button>
    );
}

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function LinkButton(props) {
    return (
        <Button
            type="link"
            style={{ color: props.color }}
            {...props}
            // className={styles.base}
        >
            {props.text || '提交'}
        </Button>
    );
}
