import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import  getLocalMap  from '../../containers/bigscreen/service';
/**
 * @desc 地图
 */

const mapName = '北京';

export default function({data, mapCallBack, name}) {

    const [option, setOption] = useState(null);
    const [chartReady, setChartReady] = useState(false);

    useEffect( () =>{
        getLocalMap(mapName).then(res => {
            echarts.registerMap(mapName, res);
            const options =  getOption();
            setOption(options);
            setChartReady(true);
        });
    }, [])
    
    useEffect( () =>{
        chartReady && setOption(getOption(name));
    }, [name])

    function getOption(name) {
        let max = 0;
        const series = data.map( ele => {
            const { district, totalRiskScore, code } = ele;
            if( totalRiskScore > max ){
                max = totalRiskScore;
            }
            return { name:district, value: totalRiskScore, code, selected: name === district};
        });
        return   {
            // geo: {
            //     show: true,
            //     map: mapName,
            //     roam: true
            // },
            tooltip: {
                trigger: 'item',
                show: true,
                padding: [10, 20],
                formatter: function(v){
                    // const { name, ipoAmount, preIpoAmount } = v.data;
                    return `<div class="">
                                    <h3 class = "title">${v.name}</h3>
                                    <p>风险总分： <span class = "colorBlue">${v.value}</span></p>
                                </div>`;
                }
            },
            visualMap: {
                min: 0,
                max,
                text: ['高', '低'],
                calculable: true,
                show:false,
                inRange: {
                    color: [
                        "#67a8ef",
                        "#2082ed"
                    ]
                }
            },
            series: [
                {
                    type: 'map',
                    mapType: mapName,
                    geoIndex: 0,
                    data: series,
                    roam: false,
                    selectedMode: 'single'
                }
            ]
        };
    }

    return  option ? <ReactEcharts
                        option={option}
                        notMerge={true}
                        onEvents = {{ click: mapCallBack }}
                        style = {{height: '100%'}}
                        theme={'myTheme'}
                    /> : '';
}
