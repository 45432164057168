'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CircleChart = require('./CircleChart');

var _CircleChart2 = _interopRequireDefault(_CircleChart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _CircleChart2.default;
module.exports = exports['default'];