/**
 * @author Ray
 * @desc 股东列表的columns数据
 **/
import { transferStringNull } from '../../../utils/utils';

export const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: '10%',
        editable: false,
        render: (text, record, index) => index + 1
    },
    {
        title: '股东名称',
        dataIndex: 'shareholderName',
        key: 'shareholderName',
        align: 'center',
        width: '60%',
        editable: true,
        render: transferStringNull
    },
    {
        title: '持有比例(%)',
        dataIndex: 'ownRatio',
        key: 'ownRatio',
        align: 'center',
        width: '30%',
        editable: true,
        type: 'number',
        render: transferStringNull
    }
];

export const source = [
    {
        index: 1,
        name: 'admin',
        ratio: 10
    },
    {
        index: 2,
        name: 'admin',
        ratio: 10
    },
    {
        index: 3,
        name: 'admin',
        ratio: 10
    },
    {
        index: 4,
        name: 'admin',
        ratio: 10
    },
    {
        index: 5,
        name: 'admin',
        ratio: 10
    },
    {
        index: 6,
        name: 'admin',
        ratio: 10
    },
    {
        index: 7,
        name: 'admin',
        ratio: 10
    },
    {
        index: 8,
        name: 'admin',
        ratio: 10
    },
    {
        index: 9,
        name: 'admin',
        ratio: 10
    },
    {
        index: 10,
        name: 'admin',
        ratio: 10
    }
];

export default columns;
