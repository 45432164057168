import React, { useContext, useState } from 'react';
import PublicOpinion from '../../../riskMonitoring/risk/publicOpinion/PublicOpinion';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

export default function PublicOptions(props) {
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );
    return isOwnedCompany ||
        (!isOwnedCompany && gs.isEnterprise && gs.isVIPUser) ||
        gs.isIntermediary ||
        gs.isQiYuan ||
        gs.isGOV ||
        gs.isTradeAssociations ||
        gs.isFundManager ? (
        <PublicOpinion {...props} />
    ) : (
        <Empty description={'请升级会员查看'} />
    );
}
