/**
 * @desc 入库企业分析-基础信息-司法诉讼-开庭公告
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { announcementSessionColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { publicNotice } from './service';

export default function AnnouncementSession({ match }) {
    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={announcementSessionColumns}
            getData={publicNotice}
            parseData={parseData}
            locale={{emptyText: '未发现企业的开庭公告记录。不排除存在未公示或时间相对滞后的情况。'}}

        />
    );
}
