/**
 * @desc 中介机构详情-工商变更
 * @author Ray
 */
import React, { useState } from 'react';
import { businessChangeColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { queryBgxx } from './service';
import { isNotEmpty } from '../../../../utils/utils';

export default function BusinessChange({ match }) {
    const [query] = useState({ qyxxId: match.params.companyId });

    function mockData(res) {
        return isNotEmpty(res) ? res.data : [];
    }

    return (
        <TableEx
            query={query}
            getData={queryBgxx}
            parseData={mockData}
            columns={businessChangeColumns}
        />
    );
}
