/**
 * @author Ray
 * @desc 入库企业分析-基础信息-经营分析-相似企业分析
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import Table from '../../../../../common/tableEx';
import { getSimilarCompany } from './service';
import {
  isNotEmpty,
  moneyFormatter,
  transferStringNull
} from '../../../../../utils/utils';
import Title from '../../../../../common/title';

const Columns = [
  {
    key: 'companyName',
    dataIndex: 'companyName',
    title: '公司名称',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  {
    key: 'industry',
    dataIndex: 'industry',
    title: '行业',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  {
    key: 'companyProvince',
    dataIndex: 'companyProvince',
    title: '地区',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  {
    key: 'financing',
    dataIndex: 'financing',
    title: '当前轮次',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  {
    key: 'valuation',
    dataIndex: 'valuation',
    title: '估值',
    align: 'center',
    width: '20%',
    render: text => (text === null ? '--' : moneyFormatter(text) + '亿')
  }
];

export default function MostLike({ match, isIpoCompany }) {
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setQuery({ creditCode: match.params.companyCode });
  }, []);

  return (
    !isIpoCompany && (
      <>
        <Title level={3} text="相似公司" />
        <div className={styles.mostLike}>
          <Table
            query={query}
            lock={!Boolean(query)}
            getData={getSimilarCompany}
            parseData={res => (isNotEmpty(res) ? res.data : [])}
            columns={Columns}
          />
        </div>
      </>
    )
  );
}
