/**
 * @author Ray
 */

import React from 'react';
import Model from './model';
import View from './view';

export default function(props) {
    return (
        <Model {...props}>
            {prop => {
                return <View {...prop} />;
            }}
        </Model>
    );
}
