/**
 * @author Ray
 */
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.scss";
import { Button, Form, Input, message, Tag, Typography, Avatar } from "antd";
import ErrorTip from "../../../../common/errorTip";
import { EmailLabel, Timer } from "../../../../common/verifyEmail";
import { UserType, LEVELS, TimeFormat } from "../../../../constants/enum";
import { getCompany, getIntermediaryCompany, logout } from './service';
import Auto from "../../../../common/autoComplete";
import moment from "moment";
import { asyncValidator } from "../../../../utils/validator";
import {
  validateCertificate,
  validateName,
  validateCompanyName,
  validateEmailCode
} from "../../services";
import { isNotEmpty } from "../../../../utils/utils";
import { callSendEmail } from "../../../../common/verifyEmail/service";
import { GlobalContext } from "../../../../common/globalStore/createProvider";
import UploadFile from "../../../../common/uploadFile";

/**
 *
 * @param form
 * @param onSubmit
 * @returns {*}
 * @constructor
 */

function AccountForm({ form, onSubmit = () => {} }) {
  let emailSendFlag = useRef(null);
  let [newEmail, setNewEmail] = useState(null);
  const gs = useContext(GlobalContext);
  const [isEmailSent, setSentStatus] = useState(false);
  // const [isBlank, setIsBlank] = useState(false);
  const [uploadAvatar, setUploadAvatar] = useState(undefined);
  const [creditCode, setCode] = useState(gs.user?.creditCode);
  const [bbdQyxxId, setId] = useState(gs.user?.bbdQyxxId);
  const { getFieldDecorator } = form;
  const certificateValidator = asyncValidator(
    validateCertificate,
    <ErrorTip text="输入编号已被注册！" />,
    (res, value) => {
      if (value === gs.user?.code) return { success: true, data: true };
      if (isNotEmpty(res) && res.success) {
        res.data = !res.data;
      }
      return res;
    }
  );
  const nameValidator = asyncValidator(
    validateName,
    <ErrorTip text="姓名已被注册！" />,
    (res, value) => {
      if (value === gs.user?.realName) return { success: true, data: true };
      if (isNotEmpty(res) && res.success) {
        res.data = !res.data;
      }
      return res;
    }
  );

  const companyValidator = asyncValidator(
    validateCompanyName,
    <ErrorTip text="公司名称已被注册！" />,
    (res, value) => {
      if (value === gs.user?.organization) return { success: true, data: true };
      if (isNotEmpty(res) && res.success) {
        res.data = !res.data;
      }
      return res;
    }
  );

  const emailCodeValidator = asyncValidator(
    validateEmailCode,
    <ErrorTip text="验证码错误！" />
  );

  function onLogout() {
    logout().then(res => {
      if (res.success) {
        window.location.href = "/user/login";
      }
    });
  }
  function handleSubmit() {
    form.validateFields((errors, values) => {
      if (!errors) {
        if (!creditCode && gs.isEnterprise) {
          message.error("该企业不存在！");
        } else {
          onSubmit({ creditCode, bbdQyxxId, ...values }).then(flag => {
            if (flag) {
              // setIsBlank(true);
              setSentStatus(false);
              emailSendFlag.current = false;
              setNewEmail(null);
              // message.success('保存成功，请重新登录账号');
              // setTimeout(onLogout, 3000);
            }
          });
        }
      }
    });
  }

  function onSelect(value, option) {
    // 选中保存企业的code
    setCode(JSON.parse(option.key).key);
    setId(JSON.parse(option.key).bbdQyxxId);
  }
  // 输入清除保存的code
  function handleOrgChange() {
    setCode(null);
    setId(null);
  }

  // 发送邮箱验证码
  function sendEmail() {
    form.validateFields(["email"], (errors, values) => {
      if (!errors) {
        emailSendFlag.current = true;
        callSendEmail(values.email).then(function(res) {
          if (res && res.success) {
            setSentStatus(true);
          } else {
            message.error(res.message);
          }
        });
      }
    });
  }

  function normFile(fileInfo) {
    if (!fileInfo) {
      return "";
    }
    const { url } = JSON.parse(fileInfo);
    return url;
  }

  const prefix = gs.isPub
    ? "http://file.beijingipo.com.cn/"
    : "http://192.168.88.106/";

  return (
    <Form
      className={styles.formRoot}
      labelCol={{ sm: { span: 10 } }}
      wrapperCol={{ sm: { span: 14 } }}
      onSubmit={handleSubmit}
    >
      <Form.Item hasFeedback label="用户类型">
        <Typography.Text>{UserType[gs.user?.type]}</Typography.Text>
        <Tag style={{ marginLeft: 16 }} color="green">
          {LEVELS[gs.user?.level - 1]}
        </Tag>
        {gs.user?.level !== 1 && (
          <Typography.Text type="warning">
            到期时间&nbsp;&nbsp;
            {gs.user?.expireTime &&
              moment(gs.user?.expireTime, "x").format(TimeFormat)}
          </Typography.Text>
        )}
      </Form.Item>
      <Form.Item label="登录用户名">
        <Typography>{gs.user?.username}</Typography>
      </Form.Item>
      <Form.Item label="头像" extra="支持格式：.jpg .png">
        <Avatar
          size={64}
          icon={!gs.user?.avatar && "user"}
          style={{ marginLeft: 20 }}
          src={
            uploadAvatar
              ? prefix + uploadAvatar
              : gs.user?.avatar
              ? gs.user?.avatar
              : undefined
          }
        />
        <br />
        {getFieldDecorator("avatar", {
          initialValue: gs.user?.avatar,
          getValueFromEvent: e => normFile(e)
        })(
          <UploadFile
            noFileList
            accept=".jpg,.png"
            text={"上传头像"}
            onChange={e => {
              let url = JSON.parse(e).url;
              setUploadAvatar(url);
            }}
          />
        )}
      </Form.Item>
      {gs.isGOV === 4 && (
        <Form.Item label="政府单位">
          <Typography>{gs.user?.organization}</Typography>
        </Form.Item>
      )}
      {[1, 6, 5].indexOf(gs.user?.type) !== -1 && (
        <Form.Item required label="组织机构名称">
          {getFieldDecorator("organization", {
            initialValue: gs.user?.organization,
            rules: [
              {
                max: 50,
                message: <ErrorTip text="组织机构名称不能超过50个字符！" />
              },
              {
                whitespace: true,
                message: <ErrorTip text="组织机构名称不能为空格！" />
              },
              {
                required: true,
                message: <ErrorTip text="请输入组织机构名称！" />
              }
            ]
          })(<Input maxLength={50} className={styles.formInput} />)}
        </Form.Item>
      )}
      {[2, 3].indexOf(gs.user?.type) !== -1 && (
        <React.Fragment>
          <Form.Item label="企业名称" required>
            {getFieldDecorator("organization", {
              validateTrigger: "onBlur",
              initialValue: gs.user?.organization,
              rules: [
                {
                  max: 50,
                  message: <ErrorTip text="企业名称不能超过50个字符！" />
                },
                {
                  whitespace: true,
                  message: <ErrorTip text="企业名称不能为空格！" />
                },
                {
                  required: true,
                  message: <ErrorTip text="请输入企业名称！" />
                },
                gs.isIntermediary ? {} : companyValidator
              ]
            })(
              <Auto
                maxLength={50}
                loadSource={gs.isIntermediary ? getIntermediaryCompany : getCompany}
                parser={d =>
                  d.data
                    ? d.data.map(t => ({
                        value: t.companyName,
                        label: t.companyName,
                        key: t.creditCode,
                        bbdQyxxId: t.bbdQyxxId
                      }))
                    : []
                }
                className={styles.formInput}
                onSelect={onSelect}
                onChange={handleOrgChange}
                children={<Input maxLength={50} />}
              />
            )}
          </Form.Item>
          {/*<Form.Item required label="统一社会信用代码">*/}
          {/*    {getFieldDecorator('creditCode', {*/}
          {/*        initialValue: gs.user?.creditCode,*/}
          {/*        rules: [*/}
          {/*            {*/}
          {/*                pattern: /^([^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}|[^_IOZSVa-z\W]{9})$/g,*/}
          {/*                message: <ErrorTip text="代码格式错误！" />*/}
          {/*            }*/}
          {/*        ]*/}
          {/*    })(*/}
          {/*        <Input*/}
          {/*            disabled*/}
          {/*            maxLength={50}*/}
          {/*            className={styles.formInput}*/}
          {/*        />*/}
          {/*    )}*/}
          {/*</Form.Item>*/}
        </React.Fragment>
      )}
      {3 === gs.user?.type && (
        <Form.Item label="执业/从业资格书编号" required>
          {getFieldDecorator("code", {
            validateTrigger: "onBlur",
            initialValue: gs.user?.code,
            rules: [
              {
                required: true,
                message: <ErrorTip text="请输入执业/从业资格书编号！" />
              },
              certificateValidator
            ]
          })(<Input maxLength={50} className={styles.formInput} />)}
        </Form.Item>
      )}
      <Form.Item label="姓名">
        {getFieldDecorator("realName", {
          validateTrigger: "onBlur",
          initialValue: gs.user?.realName,
          rules: [
            {
              max: 50,
              message: <ErrorTip text="企业名称不能超过50个字符！" />
            },
            {
              whitespace: true,
              message: <ErrorTip text="企业名称不能为空格！" />
            },
            {
              required: true,
              message: <ErrorTip text="请输入真实姓名！" />
            },
            nameValidator
          ]
        })(<Input className={styles.formInput} />)}
      </Form.Item>
      <Form.Item
        required
        label={
          newEmail !== "" ? (
            <EmailLabel
              verified={
                (gs.user?.emailIsValidate && !newEmail) ||
                (gs.user?.emailIsValidate && gs.user?.email === newEmail)
              }
            />
          ) : (
            <EmailLabel verified={false} />
          )
        }
        extra={isEmailSent && "验证邮件已发送，请输入邮件中附带的验证码"}
      >
        {getFieldDecorator("email", {
          initialValue: gs.user?.email,
          rules: [
            {
              required: true,
              message: <ErrorTip text="请输入邮箱！" />
            },
            {
              pattern: /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
              message: <ErrorTip text="邮箱格式错误！" />
            }
          ]
        })(
          <Input
            maxLength={50}
            className={styles.formInput}
            onChange={e => setNewEmail(e.target.value)}
          />
        )}
        {isEmailSent ? (
          <Timer onFinished={() => setSentStatus(false)} />
        ) : (
          <Button type="primary" onClick={sendEmail} style={{ marginLeft: 16 }}>
            发送验证邮件
          </Button>
        )}
      </Form.Item>
      {((!gs.user?.emailIsValidate && isEmailSent) ||
        emailSendFlag.current) && (
        <Form.Item required label="邮箱验证码">
          {form.getFieldDecorator("emailCode", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: <ErrorTip text="请输入邮箱验证码！" />
              },
              emailCodeValidator
            ]
          })(
            <Input
              maxLength={6}
              placeholder="请输入邮箱验证码"
              className={styles.formInput}
            />
          )}
        </Form.Item>
      )}
      <Form.Item
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 22, offset: 2 }
        }}
        label=""
        htmlType="submit"
      >
        <div className={styles.center}>
          <Button type={"primary"} onClick={handleSubmit}>
            提交
          </Button>
        </div>
      </Form.Item>
      {/*{isBlank && <div className={styles.isBlank}></div>}*/}
    </Form>
  );
}

export default function({ onSubmit }) {
  const [E, setE] = useState(null);

  useEffect(function() {
    let Element = Form.create({
      name: "accountForm"
    })(AccountForm);

    setE(<Element onSubmit={onSubmit} />);
  }, []);

  return E;
}
