'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

exports.isArray = isArray;
exports.isString = isString;
exports.isDate = isDate;
exports.isObject = isObject;
exports.isFunction = isFunction;
exports.isNumber = isNumber;
exports.isDivElement = isDivElement;
exports.isCanvasElement = isCanvasElement;
exports.isIE = isIE;
exports.isEdge = isEdge;
exports.isWK = isWK;
exports.isEmpty = isEmpty;
exports.isNotEmpty = isNotEmpty;
exports.isBlank = isBlank;
exports.isNotBlank = isNotBlank;
exports.getValueByProps = getValueByProps;
exports.debounce = debounce;
exports.range = range;
exports.deepClone = deepClone;
exports.uniqueByKey = uniqueByKey;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 封装了一些项目常用方法.
 */

// 内部函数, 用于判断对象类型
function _getClass(object) {
    return Object.prototype.toString.call(object).match(/^\[object\s(.*)\]$/)[1];
}

function isArray(obj) {
    return _getClass(obj).toLowerCase() === 'array';
}

function isString(obj) {
    return _getClass(obj).toLowerCase() === 'string';
}

function isDate(obj) {
    return _getClass(obj).toLowerCase() === 'date';
}

function isObject(obj) {
    return _getClass(obj).toLowerCase() === 'object';
}

function isFunction(obj) {
    return _getClass(obj).toLowerCase() === 'function';
}

function isNumber(obj) {
    return _getClass(obj).toLowerCase() === 'number';
}

function isDivElement(obj) {
    return _getClass(obj).toLowerCase() === 'htmldivelement';
}
function isCanvasElement(obj) {
    return _getClass(obj).toLowerCase() === 'htmlcanvaselement';
}

function isIE(version) {
    var userAgent = window.navigator.userAgent.toLowerCase();
    var match = userAgent.match(/msie ([\d+\.]+)/) || userAgent.match(/rv:([\d+\.]+)\) like gecko/);
    if (match) {
        var IE_version = parseFloat(match[1]); // 当前IE版本号
        version = parseFloat(version);
        return !version || version === IE_version;
    }

    return isEdge() || isWK();
}
// edge浏览器
function isEdge() {
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586
    var userAgent = window.navigator.userAgent.toLowerCase();
    var match = userAgent.match(/(edge)\/([\w.]+)/);
    return !!match;
}
// 用于 wkhtmltopdf
function isWK() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    var match = userAgent.match(/wkhtmlto(pdf|image)/);

    return !!match;
}
/**
 * @desc 判断参数是否为空, 包括null, undefined, [], '', {}
 * @param {object} obj 需判断的对象
 */
function isEmpty(obj) {
    var empty = false;

    if (obj === null || obj === undefined) {
        // null and undefined
        empty = true;
    } else if ((isArray(obj) || isString(obj)) && obj.length === 0) {
        empty = true;
    } else if (isObject(obj)) {
        var hasProp = false;
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                hasProp = true;
                break;
            }
        }
        if (!hasProp) {
            empty = true;
        }
    } else if (isNumber(obj) && isNaN(obj)) {
        empty = true;
    }
    return empty;
}

/**
 * @desc 判断参数是否不为空
 */
function isNotEmpty(obj) {
    return !isEmpty(obj);
}

/**
 * @desc 判断参数是否为空字符串, 比isEmpty()多判断字符串中全是空格的情况, 如: '   '.
 * @param {string} str 需判断的字符串
 */
function isBlank(str) {
    if (isEmpty(str)) {
        return true;
    } else if (isString(str) && str.trim().length === 0) {
        return true;
    }
    return false;
}

/**
 * @desc 判断参数是否不为空字符串
 */
function isNotBlank(obj) {
    return !isBlank(obj);
}

/**
 * @desc 根据传递的对象, 以及嵌套对象的属性名, 来获取属性值
 * @param {object} obj 需要遍历的对象,
 * @param {string} props 需要遍历的对象属性名, 可传递一个到多个.
 * @param {string/number} defaultValue 默认属性值为空时返回的值.
 */
function getValueByProps(obj) {
    if (arguments.length < 2) {
        return;
    }

    var currentObj = obj;
    var props = Array.prototype.slice.call(arguments, 1);
    var defaultVal = props.pop();
    for (var i = 0; i < props.length; i++) {
        var prop = props[i];
        currentObj = currentObj[prop];
        if (isEmpty(currentObj)) {
            return defaultVal;
        }

        if (i === props.length - 1) {
            if (isObject(currentObj) && isNotEmpty(currentObj[defaultVal])) {
                return currentObj[defaultVal];
            }
            return currentObj;
        }
    }
}
/**
 * @desc 函数防抖，让某个函数在上一次执行后，满足等待某个时间内不再触发此函数后再执行，而在这个等待时间内再次触发此函数，等待时间会重新计算。
 * 解决频繁发生的事件，比如
 * 1.window 的 resize、scroll
 * 2.mousedown、mousemove
 * 3.keyup、keydown
 * 试用场景：输入框搜索，滚动懒加载图片
 * @param {fun} 需要进行函数防抖的函数
 * @param {wait} 参数wait则是需要等待的时间，单位为毫秒
 * @param {immediate} immediate参数如果为true，则debounce函数会在调用时立刻执行一次function，而不需要等到wait这个时间后，
 */
function debounce(func, wait, immediate) {
    var timeout, result;
    var debounced = function debounced() {
        var context = this;
        var args = arguments;

        if (timeout) {
            clearTimeout(timeout);
        }

        if (immediate) {
            // 如果已经执行过，不再执行
            var callNow = !timeout;

            if (callNow) {
                result = func.apply(context, args);
            }

            timeout = setTimeout(function () {
                // timeout 为 null 的时候 callNow 才为 true.
                timeout = null;
            }, wait);
        } else {
            timeout = setTimeout(function () {
                func.apply(context, args);
            }, wait);
        }
        return result;
    };

    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
}

/**
 * @desc 获取随机数组
*/
function range(num) {
    var arr = [];
    for (var i = 0; i < num; i++) {
        arr.push(i);
    }
    arr.sort(function () {
        return 0.5 - Math.random();
    });
    return arr;
}

/**
 * @desc 深拷贝，支持常见类型
 * @param {Any} values
 */
function deepClone(values) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null === values || 'object' !== (typeof values === 'undefined' ? 'undefined' : (0, _typeof3.default)(values))) {
        return values;
    }

    // Handle Date
    if (values instanceof Date) {
        copy = new Date();
        copy.setTime(values.getTime());
        return copy;
    }

    // Handle Array
    if (values instanceof Array) {
        copy = [];
        for (var i = 0, len = values.length; i < len; i++) {
            copy[i] = deepClone(values[i]);
        }
        return copy;
    }

    // Handle Object
    if (values instanceof Object) {
        copy = {};
        for (var attr in values) {
            if (values.hasOwnProperty(attr)) {
                copy[attr] = deepClone(values[attr]);
            }
        }
        return copy;
    }

    throw new Error('Unable to copy values! Its type isn\'t supported.');
}

function uniqueByKey(arr) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';

    if (!arr) {
        return;
    }

    var flag = {};
    var newArr = [];

    arr.forEach(function (obj) {
        var value = obj[key];
        if (!flag[value]) {
            flag[value] = obj;
            newArr.push(obj);
        }
    });
    return newArr;
}