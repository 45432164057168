import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';
import { rankingOfVolumeInnovate, rankingOfVolumeBasic, rankingOfVolumeSelected } from './service';


export default function Line7List({ type }) {
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);

    useEffect(() => {
        //请求创新层
        type == 1 ? rankingOfVolumeInnovate().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : 
        //请求基础层
        type == 2 ? rankingOfVolumeBasic().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : 
        //请求精选层
        type == 3 ? rankingOfVolumeSelected().then(({ success, data }) => {
            if (success == true) {
                setData(data.slice(0, 5*num))
            } else {
                setData([])
            }
        }) : setData([])
    }, [type, num])
    
    function loadMore() {
        setNum(num+1);
    }


    return (
        <div className={ styles.line7List }>
            {
                data && data.length ? (
                    <table>
                        <thead>
                            <tr>
                                <td>代码</td>
                                <td>简称</td>
                                <td>最近</td>
                                <td>涨跌幅</td>
                                <td>成交量 (万股)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="20%" style={{ borderRadius: '50px 0 0 50px' }}>{ele.stockCode}</td>
                                            <td width="25%">{ele.companyShortName}</td>
                                            <td width="25%">{ele.stockPrice}</td>
                                            <td width="21.4%">{ele.upAndDown}</td>
                                            <td width="20%" style={{ borderRadius: '0 50px 50px 0' }}>{ele.volume}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : <Empty />
            }
            <div className={ styles.loadMore } onClick={ loadMore }>
                点击查看更多
            </div>
        </div>
    )
}