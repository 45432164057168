import fetch from '../../../utils/fetch';
import { API } from 'constants/enum';
/**
 * @desc 获取地图数据
 * @param mapName
 */
export default function getLocalMap(mapName) {
    // let url="";
    // if(mapName==="all") url='https://geo.datav.aliyun.com/areas_v2/bound/100000_full.json';
    // else url='https://geo.datav.aliyun.com/areas_v2/bound/'+encodeURIComponent(mapName)+'_full.json';
    return fetch(
        `/data/map/${encodeURIComponent(mapName)}.json`,
        //url,
        undefined,
        undefined,
        undefined,
        true
    );
}

export function authRedOverview(area) {
    return fetch(`${API.V1}/auth/red/overview`, { area });
}

// 各行业IPO申报
export function authDiamondNewFive(area) {
    return fetch(`${API.V1}/auth/diamond/new/five`, { area });
}

// 获取全国地区接口
export function getAreas() {
    return fetch(`${API.V1}/cockpit/listedCompanyRisk/getArea`);
}
