/**
 * @author Ray
 * @desc 中介机构-中介机构详情-团队成员
 */

import React, { useEffect, useState } from 'react';
import { teamMembersColumns } from './columns';
import { queryTeamMembers } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import { Table } from 'antd';
import styles from '../../../../common/table/styles.scss';

export default function TeamMembers({ match }) {
    const [dataSource, setDataSource] = useState([]);
    const [query] = useState({ agency: match.params.companyName });

    useEffect(() => {
        queryTeamMembers(query).then(res => {
            isNotEmpty(res) && isNotEmpty(res.data)
                ? setDataSource(res.data)
                : setDataSource([]);
        });
    }, []);

    return (
        <Table
            className={styles.tableRoot}
            dataSource={dataSource}
            pagination={{ pageSize: 10, size: 'small' }}
            columns={teamMembersColumns}
        />
    );
}
