/**
 * @author hjie
 * 有点好看的返回按钮
 */

import React from 'react';
import styles from './styles.scss';
import { Icon } from 'antd';

/**
 *
 * @returns {*}
 */
export default function({ history, url, text = '返回', callback, onClick }) {
    return (
        <div
            className={styles.backBtn}
            onClick={() => {
                if(onClick){
                    onClick();
                    return;
                }
                url ? history.push(url) : history.goBack();
                callback && callback();
            }}
        >
            <Icon type="left-circle" theme="filled" />
            <span className={styles.btnText}>{text}</span>
        </div>
    );
}
