import React  from 'react';
import RightForth from './IntermediaryPunishmentList';

/**
 * @desc  中介机构行政处罚情况
 */
let currentType = 1;

export default function RightForthIndex({ address, style}) {

  return (
    <RightForth address={address} />
  );
}
