/**
 * @author Ray
 */
import React, { Fragment } from 'react';
import styles from './styles.scss';
import {
  List,
  Button,
  Input,
  DatePicker,
  Col,
  Row,
  Divider,
  Empty
} from 'antd';
import Label from '../../../../../common/withLabel';
import PropTypes from 'prop-types';
import moment from 'moment';
import { replace, timeRange } from '../../../../../utils/utils';

export default class extends React.Component {
  state = { source: [] };

  static propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func
  };

  static defaultProps = {
    data: [],
    onChange: () => {}
  };

  handleChange = (field, item) => v => {
    const { onChange, data } = this.props;
    let newItem = {};
    switch (field) {
      case 'timeRange':
        v = v.map(t => moment(t).format('x'));
        newItem = {
          ...item,
          startTime: Number(v[0]),
          endTime: Number(v[1])
        };
        break;
      case 'school':
      case 'major':
        // case 'describe':
        v = v.target.value;
        newItem = { ...item, [field]: v };
        break;
      default:
        break;
    }
    let _data = replace(data, newItem, o => {
      return item.index === o.index;
    });
    this.setState({ source: _data }, () => {
      onChange(data, this.state.source, [item.index], [newItem]);
    });
  };

  deleteItem = item => () => {
    // 删除记录
    const { index } = item;
    const { data } = this.props;
    let _data = data.filter(d => d.index !== index);
    this.setState({ source: _data }, () => {
      this.props.onChange(data, this.state.source, [index], [item]);
    });
  };

  componentWillUnmount() {
    this.setState({ source: [] });
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.template}>
        <List split itemLayout="horizontal">
          {data.map((item, index) => {
            return (
              <Fragment key={item.index}>
                <List.Item
                  actions={[
                    <Button
                      className={styles.actionButton}
                      icon="minus-circle"
                      onClick={this.deleteItem(item)}
                    >
                      删除该行
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    description={
                      <Label
                        extra={<i style={{ color: 'red' }}>*</i>}
                        text="学习时间"
                      >
                        <DatePicker.RangePicker
                          onChange={this.handleChange('timeRange', item)}
                          defaultValue={timeRange(item.startTime, item.endTime)}
                        />
                      </Label>
                    }
                  />
                </List.Item>
                <Row>
                  <Col span={14}>
                    <Label
                      extra={<i style={{ color: 'red' }}>*</i>}
                      text="学校名称"
                    >
                      <Input
                        style={{ width: 374 }}
                        onChange={this.handleChange('school', item)}
                        maxLength={50}
                        defaultValue={item.school}
                      />
                    </Label>
                  </Col>
                  <Col span={10} className="right">
                    <Label
                      extra={<i style={{ color: 'red' }}>*</i>}
                      text="学习专业"
                    >
                      <Input
                        style={{ width: 229 }}
                        onChange={this.handleChange('major', item)}
                        maxLength={50}
                        defaultValue={item.major}
                      />
                    </Label>
                  </Col>
                </Row>
                {index !== data.length - 1 && <Divider />}
              </Fragment>
            );
          })}
          {!data.length && <Empty />}
        </List>
      </div>
    );
  }
}
