/**
 * @author hjie
 * 我的学习
 */

import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import {sortWin, bjDistrict} from '../service';
import { Link } from 'react-router-dom';
import { isNumber, transferStringNull } from 'utils/utils';
import {Table} from 'antd';
import { BLOCKTYPE } from 'constants/common';

function getColumns(pageNum, pageSize) {
    return [
        {
            title: '序号',
            dataIndex: 'qyxxId',
            key: 'qyxxId',
            render: (text, item, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            }
        },
        {
            title: '上市公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text,record) => <Link to = {`./detail/risk/${record.companyName}/${record.qyxxId}`}>{transferStringNull(text)}</Link>
        },
        {
            title: '法定代表人',
            dataIndex: 'frname',
            key: 'frname',
            render: transferStringNull
        },
        {
            title: '所属区',
            dataIndex: 'district',
            key: 'district',
            render: transferStringNull
        },
        {
            title: '上市板块',
            dataIndex: 'block',
            key: 'block',
            render: (text) => {
                return BLOCKTYPE[text] || '--';
            },
        },
        {
            title: '总体风险',
            dataIndex: 'totalRiskScore',
            key: 'totalRiskScore',
            render: (text) => isNumber(text) ? text.toFixed(2) : '--'
        },
        {
            title: '关注状态',
            dataIndex: 'isFocus',
            key: 'isFocus',
            render: (text) => text == 1 ? '已关注' : '未关注'
        }
    ];
}
export default function({param}) {
    const [dataSource, setDataSource] = useState({ data: [], total: 0 });
    const [pageNum, setPageInfo] = useState(1);
    const columns = getColumns(pageNum, 10);

    // 分页
    function onChange(pagination) {
        // setPageInfo( pagination.current);
        getMap(pagination.current)
    }

   // 风险排名弹窗
   function getMap(page){
        sortWin({
            ...param,
            pageNum: page,
            pageSize: 10
        }).then(res => {
            if( res.success){
                setDataSource({ data: res.data.data, total: res.data.totalCount});
                setPageInfo(page);
                return;
            }
            setDataSource({ data: [], total: 0});
        })
    }
    useEffect( () =>{
        param && getMap(1);
        // getAA();
    }, [param])
    
    return (
        <div className = {styles.modalTable}>
           <Table
                columns={columns}
                dataSource={dataSource.data}
                onChange={onChange}
                pagination={{
                    current: pageNum,
                    pageSize: 10,
                    total: dataSource.total,
                    showTotal: total => `共 ${Math.ceil(dataSource.total / 10)} 页/${total}条数据`,
                    showQuickJumper: true,
                    size: "small"
                }}
            />
        </div>
    );
}
