import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import { transactionsInfoMonthly } from './service';
import { Empty } from './service';


global.AreaChart2 = null;

export default function Transaction() {

    useEffect(() => {
        transactionsInfoMonthly().then(({ success, data }) => {
            if (success == true) {
                if (global.AreaChart2 == null) {
                    global.AreaChart2 = new Chart({
                        container: 'AreaChart2',
                        autoFit: true,
                        height: 250,
                        padding:[10,20,30,30]
                    });

                    //数据分割
                    let valData=data.filter((ele)=>{
                        return ele.item=="股票市值";
                    });//折线图
                    valData=valData.map((ele)=>{
                        return{
                            mouth:ele['mouth'],
                            val:parseFloat(ele['amount']),
                        }
                    });

                    data=valData;

                    global.AreaChart2.data(data);

                    global.AreaChart2.axis('mouth',{
                        label:{
                            offset:15
                        }
                    });
                    global.AreaChart2.scale('val', {
                        nice: true,
                        min:0,
                    });
                    global.AreaChart2.tooltip(false);
                    global.AreaChart2
                        .line()
                        .position('mouth*val')
                        .color("rgb(86,207,162)")
                        .label('val');
                    global.AreaChart2.render();

                }
            } else {
                <Empty />
            }
        })
    }, [])

    return(
        <div id='AreaChart2' style={{height:'23vh'}}></div>
    )
}
