import styles from './style.scss';
import React, { useState, useEffect } from 'react';
import TrianglePillar from './Line';
import { authDiamondTrend } from '../service';
import { Empty, message } from 'antd';

/**
 * @desc 申报企业数量趋势
 */
export default function BigScreen({ area, style }) {
  const [xData, setXData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [legendData, setLegendData] = useState([]);

  function queryImpIndexCompany(data) {
    setXData(data.date);
    setSeriesData(data.data);
    setLegendData(data.area);
  }
  useEffect(() => {
    authDiamondTrend(area).then(res => {
      if (res.statusCode == 1000) {
        if (res.data.length) {
          let arr = [];
          let newArr = [];
          let legendData = [];
          res.data.forEach((item, indexes) => {
            item.impIndexCompanyCountVos.forEach((element, index) => {
              legendData.push(element.area);
              if (index === 0) {
                newArr.unshift(element.meetingDate);
              }
              if (indexes === 0) {
                arr = [
                  ...arr,
                  {
                    name: element.area,
                    type: 'line',
                    data: [element.count],
                    areaStyle: {}
                  }
                ];
              } else {
                arr[index].data.unshift(element.count);
              }
            });
          });
          // setXData([...newArr])
          // setSeriesData([...arr])
          queryImpIndexCompany({
            date: newArr,
            area: legendData,
            data: arr
          });
          //
          // let seriesData = [];
          // let legendData = [];
          // res.data[0].impIndexCompanyCountVos.forEach(value => {
          //   legendData.push(value.area);
          //   seriesData.push({ name: value.area, type: 'line', data: [] });
          // });
          // res.data.forEach((value, index) => {
          //   value.impIndexCompanyCountVos.forEach((v, i) => {
          //     seriesData[i].data.push(v.count);
          //   });
          // });
          // seriesData.forEach(value => {
          //   value.data.reverse();
          // });
          // queryImpIndexCompany({
          //   date: res.data
          //     .map(value => {
          //       return value.impIndexCompanyCountVos[0].meetingDate;
          //     })
          //     .reverse(),
          //   area: legendData,
          //   data: seriesData
          // });
        } else {
          queryImpIndexCompany({
            date: [],
            area: [],
            data: []
          });
        }
      } else {
        message.error(res.message);
      }
    });
  }, [area]);

  return (
    <div className={styles.listOfEnterprises} style={{ ...style }}>
      {/* <div className={`clearfix ${style.listOfEnterprisesRadio}`}>
                <div className="fr">
                    <Radios data={radiosData} defaultValue={1} onChange={(v) => queryImpIndexCompany(v.target.value)} />
                </div>
            </div> */}
      {legendData.length ? (
        <TrianglePillar
          style={{ height: '27vh', ...style }}
          // legendData={legendData}
          xData={xData}
          seriesData={seriesData}
        />
      ) : (
        <Empty></Empty>
      )}
    </div>
  );
}
