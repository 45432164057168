import React, { useState, useEffect } from 'react';
import Pie from './Pie';
import Radios from '../listOfEnterprises/radio';
import styles from '../listOfEnterprises/style.scss';
import { getQueryListedCompanyFb } from './service';
/**
 * @desc  拟上市企业分布
 */
export default function BigScreen(props) {
  const { address, style } = props;
  const [areaOrIndustry, setAreaOrIndustry] = useState(1);
  const [datas, setDatas] = useState({ loading: true, data: [] });
  const name = '拟上市企业分布';
  const radiosData = [
    { name: '地区', value: 1 },
    { name: '行业', value: 2 }
  ];

  function queryListedCompanyFb(areaOrIndustry) {
    getQueryListedCompanyFb({ area: address, areaOrIndustry }).then(res => {
      if (res.success) {
        let arr = [];
        res.data.map(item => {
          arr = [...arr, { name: item.areaOrIndustry, value: item.count }];
        });
        setDatas({ loading: false, data: arr });
      } else {
        setDatas({ loading: false, data: [] });
      }
    });
  }

  useEffect(() => {
    setAreaOrIndustry(1);
    queryListedCompanyFb(1);
  }, [address]);
  return (
    <div className={styles.listOfEnterprises} style={{ ...style }}>
      <div className={`clearfix ${styles.listOfEnterprisesRadio}`}>
        <div className="fr">
          <Radios
            data={radiosData}
            value={areaOrIndustry}
            onChange={v => {
              setAreaOrIndustry(v.target.value);
              queryListedCompanyFb(v.target.value);
            }}
          />
        </div>
      </div>
      <div style={{ height: '88%' }}>
        <Pie datas={datas} name={name} style={{ height: '100%' }} />
      </div>
    </div>
  );
}
