import React, { useEffect, useState } from 'react';
import {
    rankingOfBrokerVolumeInnovate,
    rankingOfBrokerVolumeSelected,
    rankingOfVolumeBroker,
    rankingOfAllBrokerVolume
} from "./service";
import { Chart } from '@antv/g2';
import { Empty } from "antd";
import { limitString } from "../../../../utils/utils";


global.rankList = null;

export default function TopTurnover({ type }) {
    const [data, setData] = useState([]);
    function cmp(a, b) {
        return b['totalVolume'] - a['totalVolume'];
    }

    useEffect(() => {
        //请求全部层数据
        type == 1 ? rankingOfAllBrokerVolume().then(({ success, data }) => {
            if (success == true) {
                data = data.sort(cmp).slice(0, 10);//取最大的前10个
                setData(data)
            } else {
                setData([])
            }
            create_chart(data);
        }) :
            //请求创新层数据
            type == 2 ? rankingOfBrokerVolumeInnovate().then(({ success, data }) => {
                if (success == true) {
                    data = data.sort(cmp).slice(0, 10);//取最大的前10个
                    setData(data)
                } else {
                    setData([])
                }
                create_chart(data);
            }) :
                //请求基础层数据
                type == 3 ? rankingOfVolumeBroker().then(({ success, data }) => {
                    if (success == true) {
                        data = data.sort(cmp).slice(0, 10);//取最大的前10个
                        setData(data)
                    } else {
                        setData([])
                    }
                    create_chart(data);
                }) :
                    //请求精选层数据
                    type == 4 ? rankingOfBrokerVolumeSelected().then(({ success, data }) => {
                        if (success == true) {
                            data = data.sort(cmp).slice(0, 10);//取最大的前10个
                            setData(data)
                        } else {
                            setData([])
                        }
                        create_chart(data);
                    }) : create_chart([]);

        //console.log(data);
    }, [type]);

    function create_chart(data) {
        //创建表格
        if (data && data.length) {
            global.rankList = null;
            if (global.rankList == null) {
                global.rankList = new Chart({
                    container: 'rankList',
                    autoFit: true,
                    height: 200,
                    padding: [20, 30, 35, 50]
                });
                data = data.map((ele) => {
                    return {
                        totalVolume: ele['totalVolume'],
                        brokerName: limitString(2, ele['brokerName'])
                    }
                });
                global.rankList.data(data);
                global.rankList.scale('totalVolume', {
                    alias: '成交量(万股)',
                });
                global.rankList.axis('totalVolume', {
                    title: {
                        style: {
                            fill: '#fff',
                        },
                    }
                });
                global.rankList.tooltip(false);
                global.rankList.legend(false);
                //global.rankList.axis('brokerName', false);

                global.rankList
                    .interval()
                    .position('brokerName*totalVolume')
                    .label('totalVolume', {
                        position: 'middle'
                    })
                    .color('totalVolume', '#2194ff');
                global.rankList.render();
            }
            else {
                global.rankList.clear();
                data = data.map((ele) => {
                    return {
                        totalVolume: ele['totalVolume'],
                        brokerName: limitString(4, ele['brokerName'])
                    }
                });
                global.rankList.changeData(data);
                global.rankList.scale('totalVolume', {
                    alias: '成交量(万股)',
                });
                global.rankList.axis('totalVolume', {
                    title: {
                        style: {
                            fill: '#fff',
                        },
                    }
                });
                global.rankList.tooltip(false);
                global.rankList.legend(false);
                //global.rankList.axis('brokerName', false);

                global.rankList
                    .interval()
                    .position('brokerName*totalVolume')
                    .label('totalVolume', {
                        position: 'middle'
                    })
                    .color('totalVolume', '#2194ff');
                global.rankList.render();
            }
        }
        else {
            <Empty />
        }
    }

    return (
        <div id='rankList'></div>
    )
}
