/**
 * @author Ray
 * @desc 中介机构分析-中介评价
 */

import React, { forwardRef, useContext, useState } from 'react';
import { Button, Form, InputNumber, Empty } from 'antd';
import styles from './styles.scss';
import Title from '../../../../common/title';
import {
    GreaterThanZro,
    PositiveInteger,
    Required
} from '../../../../utils/validator';
import { showEmploymentDetail, addEditEmployment } from './service';
import useActions from './actionHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import { isNotEmpty } from '../../../../utils/utils';

export function EmployForm({ form, data = {} }, ref) {
    React.useImperativeHandle(ref, () => ({
        form
    }));

    return (
        <Form labelCol={{ xs: 7 }} wrapperCol={{ xs: 17 }}>
            <Form.Item label="集体合同覆盖率">
                {form.getFieldDecorator('contractRatio', {
                    rules: [Required, GreaterThanZro]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="社会保险覆盖率">
                {form.getFieldDecorator('insuranceRatio', {
                    rules: [Required, GreaterThanZro]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="企业年金覆盖率">
                {form.getFieldDecorator('annuityRatio', {
                    rules: [Required, GreaterThanZro]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="员工人数">
                {form.getFieldDecorator('staffNumber', {
                    rules: [Required, GreaterThanZro, PositiveInteger]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="女性员工比例">
                {form.getFieldDecorator('womanNumberRatio', {
                    rules: [Required, GreaterThanZro]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="应届毕业生人数">
                {form.getFieldDecorator('graduatesNumber', {
                    rules: [Required, GreaterThanZro, PositiveInteger]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
        </Form>
    );
}

export default function Employment({ dispatch, match, creditCode }) {
    const gs = useContext(GlobalContext);
    const [isOwnCompany] = useState(
        gs.user?.organization === match.params.companyName
    );
    const [, editRecord, , data] = useActions({
        dispatch,
        query: { companyName: match.params.companyName },
        formProps: {},
        update: addEditEmployment,
        form: forwardRef(EmployForm),
        getData: showEmploymentDetail
    });

    return (
        <div className={styles.table}>
            <Title level={2} text="就业情况">
                {isOwnCompany && (
                    <>
                        <p style={{ flex: 1 }} />
                        <Button
                            type="primary"
                            onClick={editRecord(data)('编辑就业情况')}
                        >
                            编辑
                        </Button>
                    </>
                )}
            </Title>
            {isNotEmpty(data) ? (
                <table>
                    <tbody>
                        <tr>
                            <td>集体合同覆盖率</td>
                            <td>{data.contractRatio || '-'} %</td>
                        </tr>
                        <tr>
                            <td>社会保险覆盖率</td>
                            <td>{data.insuranceRatio || '-'} %</td>
                        </tr>
                        <tr>
                            <td>企业年金覆盖率</td>
                            <td>{data.annuityRatio || '-'} %</td>
                        </tr>
                        <tr>
                            <td>员工人数</td>
                            <td>{data.staffNumber || '-'} 人</td>
                        </tr>
                        <tr>
                            <td>女性员工比例</td>
                            <td>{data.womanNumberRatio || '-'} %</td>
                        </tr>
                        <tr>
                            <td>应届毕业生人数</td>
                            <td>{data.graduatesNumber || '-'} 人</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <Empty description="该中介机构暂未维护自身社会责任评价" />
            )}
        </div>
    );
}
