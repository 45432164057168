/**
 * @author Ray
 */
import React from 'react';
import { Button } from 'antd';
import styles from './styles.scss';
import Template from './workTemplate';
import classname from 'classnames';
import Temp from './temp';
import PropTypes from 'prop-types';
import { AddRowButton } from '../../../../common/buttons';
import { random } from '../../../../utils/utils';

export default class View extends React.Component {
  state = { data: [] };
  addItem = () => {
    const { data } = this.state;
    let _data = [...data];
    let item = {
      index: random(5),
      company: '',
      position: '',
      describe: '',
      startTime: undefined,
      endTime: undefined
    };
    _data.unshift(item);
    this.setState({ data: _data });
    this.props.onChange('sysExpertWorkExes', _data);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: nextProps.dataSource
    });
  }

  handleTemplateChange = (oldSource, newSource) => {
    this.setState({ data: newSource }, () => {
      this.props.onChange('sysExpertWorkExes', newSource);
    });
  };

  render() {
    const { data } = this.state;

    return (
      <section className={styles.workContent}>
        <AddRowButton block text="新增经历" onClick={this.addItem} />
        <Template onChange={this.handleTemplateChange} data={data} />
      </section>
    );
  }
}

View.defaultProps = {
  dataSource: []
};

View.propTypes = {
  dataSource: PropTypes.array
};
