import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { Empty } from 'antd';
import { brokerIPOPassHistogramLine, listOfBroker } from './service';
import { Selector } from '../../../merging/home/apply';
/**
 * @desc 
 */
global.BrokerIPOPassHistogramLine = null;
export default function BrokerIPOPassHistogramLine({ address, style }) {
    const [value, setValue] = useState([]);
    const [type, setType] = useState('');

    useEffect(() => {
        //请求券商信息接口
        listOfBroker().then(({ success, data }) => {
            if (success == true) {
                setValue(data)
            } else {
                setValue([])
            }
        })

        brokerIPOPassHistogramLine({ area: address, broker: type }).then(({ success, data }) => {
            if (success == true) {
                if (global.BrokerIPOPassHistogramLine == null) {
                  global.BrokerIPOPassHistogramLine = new Chart({
                    container: 'BrokerIPOPassHistogramLine',
                    autoFit: true,
                    height: 150,
                    padding:[20,20,19,28]
                  });

                  global.BrokerIPOPassHistogramLine.scale('rate', {
                     nice:true   
                  });

                  global.BrokerIPOPassHistogramLine.data(data);

                  global.BrokerIPOPassHistogramLine.tooltip(false)
                  
                  global.BrokerIPOPassHistogramLine.interval()
                  .position('year*rate')
                  .style({
                      fillOpacity: 1,
                  }).label('amount');
                  global.BrokerIPOPassHistogramLine.line().shape('smooth').position('year*rate');
                  global.BrokerIPOPassHistogramLine.point().position('year*rate');
              
                  global.BrokerIPOPassHistogramLine.render();
                } else {
                  global.BrokerIPOPassHistogramLine.clear();
                  global.BrokerIPOPassHistogramLine.changeData(data);
            
                  global.BrokerIPOPassHistogramLine.tooltip(false)
                  
                  global.BrokerIPOPassHistogramLine.interval()
                  .position('year*rate')
                  .style({
                      fillOpacity: 1,
                  }).label('amount');
                  global.BrokerIPOPassHistogramLine.line().shape('smooth').position('year*rate');
                  global.BrokerIPOPassHistogramLine.point().position('year*rate');
              
                  global.BrokerIPOPassHistogramLine.render();
                }
            } else {
                <Empty />
            }
        })
    }, [address, type])
    
    return (
        <div>
            <div className={ styles.leftForthTitle }>
                券商过会率
            </div>
            <Selector
              defaultValue={'券商'}
              className={ styles.selectorValue }
              placeholder="请选择券商"
              options={value}
              onChange={type => {
                  setType(type)
              }}
            />
            <div id='BrokerIPOPassHistogramLine' className={ styles.BrokerIPOPassHistogramLine }></div>
        </div>
        
    )
}
