/**
 * @desc  挂牌公司总览
 */
import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import Title from './title';
import Radios from './radio';
import List from './list';
import { Empty } from 'antd';
import { listedOverview } from './service';

export default function Line2Index() {
    const [type, setType] = useState(1);
    const [data, setData] = useState([]);

    const radiosData = [
        { name: '创新层', value: 1 },
        { name: '基础层', value: 2 },
    ];

    useEffect(() => {
        listedOverview().then(({ success, data }) => {
            if (success == true) {
                setData(data);
            } else {
                setData([]);
            }
        })
    }, [])

    
    return (
        <div className={ styles.line2 }>
            <Title />
            <div className={ styles.line2Table }>
                <table>
                    <thead>
                        <tr>
                            <td>证券数量 (只)</td>
                            <td>总股本 (亿股)</td>
                            <td>流通股本 (亿股)</td>
                            <td>成交股数 (万股)</td>
                            <td>成交金额 (万元)</td>
                        </tr>
                    </thead>
                    <tbody>
                         <tr>
                            <td width="20%" style={{ borderRadius: '50px 0 0 50px' }}>{data.sharesCount}</td>
                            <td width="25%">{data.totalEquity}</td>
                            <td width="25%">{data.flowEquity}</td>
                            <td width="10%">{data.dealEquity}</td>
                            <td width="20%" style={{ borderRadius: '0 50px 50px 0' }}>{data.dealMoney}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <List type={type} />
        </div>
        
    );
}
