import { download } from '../../utils/fetch';
import { API } from '../../constants/enum';
import React, { useState, useEffect } from 'react';

export default function hooks(value, template) {
    const [captha, setCapthe] = useState(null);
    const [isChange, setIsChange] = useState(false);
    const [iptVal, setIptVal] = useState(value || '');

    useEffect(
        function() {
            requestCaptcha(template);
        },
        [template]
    );

    function requestCaptcha(temp) {
        return download(`${API.V1}/sso/kaptcha`, {
            template: temp,
            responseType: 'blob'
        }).then(function(blob) {
            URL.revokeObjectURL(blob);
            let url = URL.createObjectURL(blob);
            setCapthe(url);
        });
    }

    return [captha, requestCaptcha, isChange, setIsChange, iptVal, setIptVal];
}

