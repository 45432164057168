/**
 * @author Ray
 * @description 验证邮箱
 */
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Button, Input, Form, Typography, Tag, message } from 'antd';
import styles from './styles.scss';
import ErrorTip from '../errorTip';
import { isEmpty } from '../../utils/utils';
import { callSendEmail } from './service';
import useStorage from '../../hooks/useStorage';
import { GlobalContext } from '../globalStore/createProvider';

const { Item } = Form;

export const Timer = ({ onFinished }) => {
    let [count, setCount] = useState(60);

    useEffect(function() {
        let timer = setInterval(function() {
            count -= 1;
            if (count === 0) {
                onFinished();
                clearInterval(timer);
            } else {
                setCount(count);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Typography
            className={styles.text}
        >{`${count}秒后重新发送`}</Typography>
    );
};

export const EmailLabel = ({ verified }) => {
    return (
        <>
            <Tag color={verified ? 'green' : 'yellow'}>
                {verified ? '已验证' : '未验证'}
            </Tag>
            邮箱
        </>
    );
};

export default function({ form, initialValue }) {
    const { user } = useContext(GlobalContext);
    // const [user] = useStorage('UserInfo');
    // 是否验证
    const [isLock, setLock] = useState(false);
    // const [isVerified, setVerifyStatus] = useState(false);
    const [isEmailSent, setEmailSentStatus] = useState(false);
    const [isCorrectEmail, setEmailStatus] = useState(true);

    // 发送邮箱验证码
    function sendEmail() {
        let email = form.getFieldValue('email');
        callSendEmail(email).then(function(res) {
            if (res && res.success) {
                setLock(true);
                setEmailSentStatus(true);
            } else {
                message.error(res.message);
            }
        });
    }

    return (
        <Fragment>
            <Item>
                {form.getFieldDecorator('email', {
                    initialValue,
                    rules: [
                        {
                            validator: (rule, value, callback) => {
                                if (isEmpty(value)) {
                                    setEmailStatus(false);
                                    return callback(
                                        <ErrorTip text="请输入邮箱！" />
                                    );
                                }
                                if (
                                    !/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm.test(
                                        value
                                    )
                                ) {
                                    setEmailStatus(false);
                                    return callback(
                                        <ErrorTip text="邮箱格式错误！" />
                                    );
                                }
                                setEmailStatus(true);
                            }
                        }
                    ]
                })(
                    <Input
                        disabled={isEmailSent && isLock}
                        className={styles.input}
                    />
                )}
                {!isLock && isCorrectEmail && (
                    <Button
                        type="primary"
                        onClick={sendEmail}
                        style={{ marginLeft: 16 }}
                    >
                        发送验证邮件
                    </Button>
                )}
                {isLock && isCorrectEmail && (
                    <Timer onFinished={() => setLock(false)} />
                )}
            </Item>
            {isEmailSent && (
                <Item required hasFeedback label="邮箱验证码">
                    {form.getFieldDecorator('emailCode', {
                        rules: [
                            {
                                required: true,
                                message: <ErrorTip text="请输入邮箱验证码！" />
                            }
                        ]
                    })(
                        <Input
                            placeholder="请输入邮箱验证码"
                            className={styles.input}
                        />
                    )}
                </Item>
            )}
        </Fragment>
    );
}
