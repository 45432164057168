/**
 * @author Ray
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import { Button, List, Icon } from 'antd';
import Typo from '../../../common/addonTypo';
import Supplementary from './supplementaryExplanation';
import TableList from './applyHistoryTable';
import ApplyModal from './applyModal';
import SearchBar from './searchBar';
import { isEmpty } from '../../../utils/utils';
import { API } from '../../../constants/enum';
import Title from '../../../common/title';
import { ApplyButton, TemplateButton } from '../../../common/buttons';
import { download } from '../../../utils/fetch';

export default function(props) {
    // 模态框是否显示
    const [isModalShow, setModalStatus] = useState(false);
    // 申请是否成功
    const [canApply, setApplyStatus] = useState(false);
    // 用户可选的申请阶段
    const [userStages, setUserStages] = useState([]);

    const {
        companyName,
        phone,
        email,
        plateDescription,
        dataSource,
        totalPage,
        totalCount,
        ...rest // 包含声明在model类中的方法
    } = props;

    function handleSearchChange(cds) {
        props.getApplyHistory(cds);
    }

    function handleOkClick(stage) {
        props.apply(stage);
    }

    function applyAuthCheck() {
        props.checkApplyAuth().then(function(status) {
            if (isEmpty(status)) {
                return;
            }

            const { canApply, userStages } = status;

            if (canApply) {
                setUserStages(userStages);
            }
            setApplyStatus(canApply);
            setModalStatus(true);
        });
    }
    function applyFormDownload() {
        download(`${API.V1}/policypractice/file/downloadFile`, {
            file: '北京市企业上市奖励资金申请表.doc'
        });
    }
    return (
        <section className={styles.root}>
            <Title text={companyName} />
            <List>
                <List.Item>
                    <List.Item.Meta
                        description={
                            <React.Fragment>
                                <Typo
                                    prefix={
                                        <Icon
                                            type="phone"
                                            theme="twoTone"
                                            twoToneColor="#30CFB7"
                                        />
                                    }
                                >
                                    联系人电话：{phone}
                                </Typo>
                                <Typo
                                    prefix={
                                        <Icon
                                            type="mail"
                                            theme="twoTone"
                                            twoToneColor="#FDAD4D"
                                        />
                                    }
                                >
                                    企业邮箱：{email}
                                </Typo>
                            </React.Fragment>
                        }
                    />
                    <TemplateButton onClick={applyFormDownload} />
                </List.Item>
            </List>
            <Supplementary text={plateDescription} />
            <div
                className={classnames('center padding', {
                    [styles.actionButton]: true
                })}
            >
                <ApplyButton onClick={applyAuthCheck} />
            </div>
            <Title level={2} text="历史申请" />
            <SearchBar onChange={handleSearchChange} />
            <TableList
                dataSource={dataSource}
                totalPage={totalPage}
                totalCount={totalCount}
                {...rest}
            />
            <ApplyModal
                title="提示"
                visible={isModalShow}
                canApply={canApply}
                onClose={() => setModalStatus(false)}
                onCancel={() => {
                    setModalStatus(false);
                }}
                onOk={
                    canApply
                        ? handleOkClick
                        : () => {
                              setModalStatus(false);
                          }
                }
                stages={userStages}
            />
        </section>
    );
}
