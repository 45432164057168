/**
 * @desc 入库企业分析-基础信息-司法诉讼-行政处罚
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { administrativePenaltyInformationColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { adminPenalties } from './service';

export default function AdministrativePenaltyInformation({ match }) {
    function parseData(res) {
        if (isNotEmpty(res)) {
            return res?.data || [];
        }
        return [];
    }

    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={administrativePenaltyInformationColumns}
            getData={adminPenalties}
            parseData={parseData}
            locale={{
                emptyText:
                    '未发现企业的行政处罚记录。不排除存在未公示或时间相对滞后的情况。'
            }}
        />
    );
}
