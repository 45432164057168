import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import DataSet from '@antv/data-set';
import styles from './styles.scss';
import { financialRiskFocus } from './service';
import { Empty } from 'antd';
/**
 * @desc 堆叠条形图
 */

global.financialRisk = null;

export default function FinancialRiskRadar({ address, style }) {
  useEffect(() => {
    financialRiskFocus({ area: address }).then(({ success, data }) => {
      if (success == true) {
        if (global.financialRisk == null) {
          global.financialRisk = new Chart({
            container: 'FinancialRiskRadar',
            autoFit: true,
            height: 200,
          });
          
          global.financialRisk.data(data);
          global.financialRisk.tooltip(false);
          global.financialRisk.coordinate('polar', {
            radius: 0.7,
          });

          global.financialRisk.scale('score', {
            min: 0,
            max: 100,
          });
          global.financialRisk.axis('item', {
            line: null,
            tickLine: null,
            label:{
              offset:20
            },
            grid: {
              line: {
                style: {
                  lineDash: null,
                },
              },
            },
          });
          global.financialRisk.axis('score', {
            line: null,
            tickLine: null,
            label:null,
            grid: {
              line: {
                type: 'circle',
                style: {
                  lineDash: null,
                },
              },
              alternateColor: 'rgba(0, 0, 0, 0.04)',
            },
          });
          global.financialRisk.legend(false)
          global.financialRisk
            .point()
            .position('item*score')
            .color('score')
            .shape('circle')
            .size(4)
            .style({
              stroke: '#fff',
              lineWidth: 1,
              fillOpacity: 1,
            });
            global.financialRisk
            .line()
            .label('score')
            .position('item*score')
            .size(2);
            
            global.financialRisk.render();
        } else {
            global.financialRisk.clear();
            global.financialRisk.changeData(data);
            global.financialRisk.tooltip(false);
          global.financialRisk.coordinate('polar', {
            radius: 0.7,
          });

          global.financialRisk.scale('score', {
            min: 0,
            max: 100,
          });
          global.financialRisk.axis('item', {
            line: null,
            tickLine: null,
            label:{
              offset:20
            },
            grid: {
              line: {
                style: {
                  lineDash: null,
                },
              },
            },
          });
          global.financialRisk.axis('score', {
            line: null,
            tickLine: null,
            label:null,
            grid: {
              line: {
                type: 'circle',
                style: {
                  lineDash: null,
                },
              },
              alternateColor: 'rgba(0, 0, 0, 0.04)',
            },
          });
          global.financialRisk.legend(false)
          global.financialRisk
            .point()
            .position('item*score')
            .color('score')
            .shape('circle')
            .size(4)
            .style({
              stroke: '#fff',
              lineWidth: 1,
              fillOpacity: 1,
            });
            global.financialRisk
            .line()
            .label('score')
            .position('item*score')
            .size(2);
            
            global.financialRisk.render();
        }
        
      } else {
        <Empty />
      }
    })
  }, [address])

    return (
        <div id='FinancialRiskRadar' className={ styles.FinancialRiskRadar }></div>
    );
    
}
