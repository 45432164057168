import React from 'react';
import styles from './styles.scss';
import backpic from '../images/new_mapBottomU.png';

export default function Title({ title }) {
    return (
        <div className={styles.titleRoot}>
            <p
                style={{
                    background: `url(${backpic})`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'none'
                }}
            >
                {title}
            </p>
        </div>
    );
}
