import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { loadingOption } from '../../../echarts/theme';
/**
 * @desc 折线图
 */
export default class Gauge extends PureComponent {
    getOption() {
        return {
            tooltip: {
                formatter: '{a} <br/>{b} : {c}%'
            },
            toolbox: {
                show: false
            },
            series: [
                {
                    name: '业务指标',
                    type: 'gauge',
                    max: 3000,
                    min: 300,
                    itemStyle: {
                        borderWidth: 8
                    },
                    radius: '95%',
                    axisLine: {
                        lineStyle: {
                            width: 16
                        }
                    },
                    axisLabel: {
                        show: false,
                        distance: 4
                    },
                    detail: { formatter: '{value}', fontSize: 16 },
                    data: [{ value: this.props.data || 0, name: '' }]
                }
            ]
        };
    }
    render() {
        const { style = {} } = this.props;
        return (
            <div style={{ height: style.height }}>
                <ReactEcharts
                    notMerge={true}
                    theme={'myTheme'}
                    option={this.getOption()}
                    loadingOption={loadingOption}
                    style={{ height: '24vh', ...style }}
                />
            </div>
        );
    }
}
