/**
 * @desc  面积图
 */
import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import DataSet from '@antv/data-set';
import styles from './styles.scss';
import { transactionsInfoMonthly } from './service';
import { Empty } from './service';

global.AreaChart = null;

export default function AreaChart() {
    const [type, setType] = useState(1);

    
    useEffect(() => {
        transactionsInfoMonthly().then(({ success, data }) => {
            if (success == true) {
                if (global.AreaChart == null) {
                    global.AreaChart = new Chart({
                        container: 'Line9AreaChart',
                        autoFit: true,
                        height: 500,
                    });
                      
                    global.AreaChart.data(data);
                    global.AreaChart.scale({
                    mouth: {
                        range: [0, 1],
                    },
                    amount: {
                        nice: true,
                    },
                    });

                    global.AreaChart.tooltip({
                        showCrosshairs: true,
                        shared: true,
                    });

                    global.AreaChart.axis('amount', {
                    label: {
                        formatter: (val) => {
                        return val;
                        },
                    },
                    });

                    global.AreaChart
                    .line()
                    .position('mouth*amount')
                    .color('item')
                    .shape('smooth');

                    global.AreaChart
                    .point()
                    .position('mouth*amount')
                    .color('item')
                    .shape('circle');

                    global.AreaChart.render();

                }
            } else {
                <Empty />
            }
        })
    }, [])




    
    return (
        <div className={ styles.areaChart }>
            <div id='Line9AreaChart' className={ styles.line9AreaChart }></div>
        </div>
    )
}
