/**
 * @author Ray
 */
import React from 'react';
import { ApplyProcess } from 'constants/enum';
import styles from './styles.scss';
import classnames from 'classnames';

export default function({ applyStage = 0 }) {
    return (
        <div className={styles.root}>
            {ApplyProcess.map(function(v, i) {
                return (
                    <React.Fragment key={v}>
                        <span
                            className={classnames(styles.block, {
                                [styles.activeBlock]: i + 1 == applyStage
                            })}
                        >
                            <span
                                className={classnames({
                                    [styles.text]: i + 1 <= applyStage,
                                    [styles.unReachedBlock]: i + 1 > applyStage
                                })}
                            >
                                {v}
                            </span>
                        </span>
                        {i < 4 && (
                            <i
                                className={classnames({
                                    [styles.arrow]: i + 1 < applyStage,
                                    [styles.unReachedArrow]: i + 1 >= applyStage
                                })}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}
