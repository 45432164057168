'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _extension = require('../../image/svg/extension.svg');

var _extension2 = _interopRequireDefault(_extension);

var _attention = require('../../image/svg/attention.svg');

var _attention2 = _interopRequireDefault(_attention);

var _remark = require('../../image/svg/remark.svg');

var _remark2 = _interopRequireDefault(_remark);

var _extension3 = require('../../image/png/extension.png');

var _extension4 = _interopRequireDefault(_extension3);

var _attention3 = require('../../image/png/attention.png');

var _attention4 = _interopRequireDefault(_attention3);

var _remark3 = require('../../image/png/remark.png');

var _remark4 = _interopRequireDefault(_remark3);

var _zrender = require('zrender');

var _zrender2 = _interopRequireDefault(_zrender);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _Mark2 = require('../../interface/Mark');

var _Mark3 = _interopRequireDefault(_Mark2);

var _common = require('../../util/common');

var _common2 = require('../../util/coordinate/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var usePng = (0, _common.isIE)() || (0, _common.isEdge)(); // IE,edge浏览器使用PNG
var _Config$node$mark = _config2.default.node.mark,
    Layer = _Config$node$mark.layer,
    StyleConfig = _Config$node$mark.style;


var Type = {
    EXTENSION: 'extension', // 可扩展
    COUNT: 'count', // 扩展数
    ATTENTION: 'attention', // 关注
    REMARK: 'remark' // 备注
};

var NodeMark = function (_Mark) {
    (0, _inherits3.default)(NodeMark, _Mark);

    function NodeMark(opts) {
        (0, _classCallCheck3.default)(this, NodeMark);

        var _this = (0, _possibleConstructorReturn3.default)(this, (NodeMark.__proto__ || (0, _getPrototypeOf2.default)(NodeMark)).call(this));

        _this.type = 'mark';

        _this.options = opts;
        _this.category = opts.category;
        _this._options = _this.convertOptions(opts);
        if (_this.category === Type.COUNT) {
            _this.element = new _zrender2.default.Rect({
                zlevel: Layer.normal,
                silent: true
            });
        } else {
            _this.element = new _zrender2.default.Image({
                zlevel: Layer.normal,
                silent: true
            });
        }
        return _this;
    }

    (0, _createClass3.default)(NodeMark, [{
        key: 'setStyle',
        value: function setStyle(options) {
            var category = options.category,
                count = options.count,
                text = options.text,
                position = options.position,
                offset = options.offset,
                hidden = options.hidden,
                slight = options.slight,
                hovered = options.hovered,
                selected = options.selected,
                focused = options.focused;

            var style = (0, _assign2.default)({}, StyleConfig.item);

            switch (category) {
                case Type.EXTENSION:
                    // 扩展
                    style.image = usePng ? _extension4.default : _extension2.default;
                    break;
                case Type.COUNT:
                    // 数量
                    var _text = text || count || 0;
                    _text += '';
                    (0, _assign2.default)(style, {
                        text: _text
                    }, StyleConfig.count);
                    options.shape = {
                        r: [100],
                        width: 7 * (_text.length < 2 ? 2 : _text.length),
                        height: style.height
                    };
                    break;
                case Type.ATTENTION:
                    // 关注
                    style.image = usePng ? _attention4.default : _attention2.default;
                    break;
                case Type.REMARK:
                    // 备注
                    style.image = usePng ? _remark4.default : _remark2.default;
                    break;
            }

            if (hidden) {
                options.invisible = true;
            } else {
                options.invisible = false;

                if (slight) {
                    style.opacity = StyleConfig.slight(options);
                } else {
                    if (hovered) {
                        // TODO
                        /* style.fill = 'red';
                        style.stroke = 'red';
                        style.textFill = 'red';
                        style.textStroke = 'red';*/
                    }

                    if (selected) {
                        // TODO
                        /* style.fill = 'yellow';
                        style.stroke = 'yellow';
                        style.textFill = 'yellow';
                        style.textStroke = 'yellow';*/
                    }

                    if (focused) {
                        // TODO
                        /* style.fill = 'blue';
                        style.stroke = 'blue';
                        style.textFill = 'blue';
                        style.textStroke = 'blue';*/
                    }
                }
            }

            var offsetWidth, offsetHeight;
            if (options.shape) {
                offsetWidth = options.shape.width;
                offsetHeight = options.shape.height;
            } else {
                offsetWidth = style.width;
                offsetHeight = style.height;
            }

            options.position = (0, _common2.positionOffset)(position, offset || [-(offsetWidth / 2), -(offsetHeight / 2)]);

            (0, _assign2.default)(options.style, style);
        }
    }, {
        key: 'draw',
        value: function draw() {
            this.setStyle(this._options);
            this.element.attr(this._options);

            // var { animation, style } = this._options;
            // style.opacity = 0;
            //
            // this.element.attr(this._options);

            // style.opacity = 1;
            // this.animateTo({
            //    animation: animation,
            //    target: {
            //        ...this._options
            //    }
            // });
        }
    }, {
        key: 'update',
        value: function update(opts) {
            this.options = opts;
            this._options = this.convertOptions(opts);
            this.setStyle(this._options);

            this.element.attr(this._options);

            // this.animateTo({
            //    animation: false,       // 更新不需要动画
            //    target: {
            //        ...this._options
            //    }
            // });
        }
    }, {
        key: 'remove',
        value: function remove(_callback) {
            var _this2 = this;

            var animation = this._options.animation;


            this.animateTo({
                animation: animation,
                target: {
                    opacity: 0
                },
                callback: function callback() {
                    if (_callback) {
                        _callback(_this2);
                    }
                }
            });
        }
    }, {
        key: 'animateTo',
        value: function animateTo(_ref) {
            var _this3 = this;

            var animation = _ref.animation,
                target = _ref.target,
                callback = _ref.callback;

            if (animation) {
                var _animation$time = animation.time,
                    time = _animation$time === undefined ? 500 : _animation$time,
                    _animation$delay = animation.delay,
                    delay = _animation$delay === undefined ? 0 : _animation$delay,
                    _animation$easing = animation.easing,
                    easing = _animation$easing === undefined ? 'linear' : _animation$easing,
                    _animation$forceAnima = animation.forceAnimate,
                    forceAnimate = _animation$forceAnima === undefined ? false : _animation$forceAnima;

                this.element.animateTo(target, time, delay, easing, function () {
                    _this3.element.attr(target);
                    if (callback) {
                        callback();
                    }
                }, forceAnimate);
            } else {
                this.element.attr(target);
                if (callback) {
                    callback();
                }
            }
        }
    }, {
        key: 'show',
        value: function show() {
            this.element.show();
        }
    }, {
        key: 'hide',
        value: function hide() {
            this.element.hide();
        }
    }, {
        key: 'getType',
        value: function getType() {
            return this.type;
        }
    }, {
        key: 'getCategory',
        value: function getCategory() {
            return this.category;
        }
    }, {
        key: 'getElement',
        value: function getElement() {
            return this.element;
        }
    }]);
    return NodeMark;
}(_Mark3.default);

exports.default = NodeMark;
module.exports = exports['default'];