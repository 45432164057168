import React from 'react';
import styles from './styles.scss';
import { Icon, Empty } from 'antd';
import classnames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { Radar } from 'bbdCharts';
import Hook from './hook';

export default function(props) {

    const { radarOption, loading, gaugeOptions, tableDatas } = Hook(props);

    return (
        <div className={styles.financeRisk}>
            <div className = {classnames("box", styles.riskNum)}>
                <div className = "fl">
                    <h4 className = "titleBorder">财务风险评估值</h4>
                    {
                        !loading && 
                        <ReactEcharts
                            style = {{height: "360px"}}
                            option={gaugeOptions.option}
                            notMerge={true}
                            theme={'myTheme'}
                        />
                    }
                    {
                        gaugeOptions.msg && 
                        <p className = "tips"><Icon type="exclamation-circle" theme="filled" />说明：{gaugeOptions.msg}</p>
                    }
                </div>
                <span data = "/ / /" className = "splitLine"></span>
                <div className = "fr">
                    <Radar {...radarOption} loading = {loading}/>
                </div>
            </div>
            <div className = {classnames("box", styles.profitRisk)}>
                <h4 className = "titleBorder">财务风险异常指标分析</h4>
                {
                    tableDatas && tableDatas.length ? tableDatas.map(ele => {
                        const { name, message, indices, point } = ele;
                        return <div className = {classnames(styles.item)} key = {name}>
                                    <div className = {styles.total}>
                                        <span className = {styles.line}><b className = {styles.blue}>{name}</b></span>
                                        <span className = {styles.line}>
                                            风险评分：
                                            <span className = {styles.red}>
                                                <b><span className = {styles.score}>{point.toFixed(2)}</span>分</b>          
                                            </span>
                                        </span>
                                        {
                                            message && 
                                            <span className = {classnames(styles.red, styles.tips) }><Icon type="exclamation-circle" theme="filled" /> 说明：{message} </span>
                                        }
                                    </div>
                                    {
                                        indices && indices.length ? indices.map( item => {
                                            const { exIndex, explain, year, point } = item;
                                            return <div  key = {exIndex}>
                                                        <div className={styles.grayBg}>
                                                            <p>
                                                                <span><Icon type="caret-right" className = {styles.blue}/>异常指标：</span>
                                                                <b className={styles.orange}>{exIndex}</b>
                                                            </p>
                                                            <p className = {styles.explain}>{explain}</p>
                                                        </div>
                                                        <table className="comTable">
                                                            <thead>
                                                                <tr>
                                                                    <th>时间</th>
                                                                    <th>{exIndex}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{year}</td>
                                                                    <td>{point}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                        }) : <div className={styles.grayBg}>暂无异常指标项</div>
                                    }
                                </div>;
                    }) : '暂无异常指标项'
                }
            </div>
        </div>
            
    );
}
