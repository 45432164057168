import { useState, useRef, useEffect } from 'react';
import { companysearch} from './service';
import { debounce } from 'utils/utils';

export default function(props){

    const [dataSource, setdataSource] = useState([]); // 企业名称联想下拉框数据
    const funcRef = useRef(() => {});
    const [companyName, setCompanyName] = useState('');

    function onSelect(value, option) {
        props.history.push(`/riskMonitoring/detail/risk/${option.props.children}/${value}`)
    }

    useEffect(function() {
        funcRef.current = debounce(v => setCompanyName(v.trim()), 500, this);
    }, []);

    useEffect( () => {
        companysearch({
            key: companyName,
        }).then(res => {
            if(res.success){
                const  data  = res.data || [];
                setdataSource(data);
            }
        })
    }, [companyName])
    
    return { onSelect, dataSource, funcRef }
}