/**
 * Created by ** on 2017/7/7.
 * PublicOpinion 舆情信息
 */
import styles from  './publicOpinion.scss';
import React, { Component } from 'react';
// import Analysis from './analysis/Analysis'; // 舆情分析
import News from './news/News'; // 舆情列表
import { Tabs } from 'antd';
import classnames from 'classnames';

const TabItem = Tabs.TabPane;

export default class PublicOpinion extends Component {
    render() {
        return (
            <div className={classnames(styles['publicOpinion'], 'clearfix')} >
                {/*<Tabs className="smallTab menu"  defaultActiveKey="0">*/}
                {/*    <TabItem key="0" tab="舆情列表">*/}
                {/*        <News {...this.props} />*/}
                {/*    </TabItem>*/}
                {/*    <TabItem key="2" tab="舆情分析">*/}
                {/*        <Analysis {...this.props}/>*/}
                {/*    </TabItem>*/}
                {/*</Tabs>    */}
                <News {...this.props} />
                {/* <Analysis {...this.props}/> */}
            </div>
        );
    }
}
