/**
 * @author Ray
 */
import request from 'utils/fetch';
import { API } from 'constants/enum';

/**
 * @description 提交申请
 * @param {object} data
 */
export function submit(data) {
    return request(
        `${API.V1}/policypractice/apply/submit`,
        {
            ...data
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 保存申请
 * @param {object} data
 */
export function preserve(data) {
    return request(
        `${API.V1}/policypractice/apply/preserve`,
        {
            ...data
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 基础信息展示
 * @param {string} code
 * @param {string} stage
 */
export function applyBaseDetail(code, stage) {
    return request(
        `${API.V1}/policypractice/apply/showBaseApplyInformation`,
        {
            code,
            stage
        },
        {
            method: 'GET'
        }
    );
}
/**
 * @description 历史记录详情
 * @param {number} id
 */
export function historyApplyDetail(id) {
    return request(`${API.V1}/policypractice/apply/showHistoryApplyDetail`, {
        id
    });
}
