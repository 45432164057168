/**
 * @author hjie
 */
import style from '../register/style.scss';
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Input, Form, Select, Button, Checkbox, Modal, Alert, AutoComplete } from 'antd';
import { UserName, EmailCheck, CellphoneCheck, PswCheckSymbol, PswCheckLetter, PswCheckNum, SpaceCheck, asyncValidator } from 'utils/validator';
import { appeal } from '../service';
import { validateName } from '../../userCenter/services';
import { RegisterUserType, StatusCode } from 'constants/enum';
import { btnLayoutCol, modalformLayout } from 'constants/common';
import VerifyCode from 'common/verifyCode';
import VerifyPhone from 'common/verifyPhone';
import ErrorTip from 'common/errorTip';
import { Link } from 'react-router-dom';
import BackBtn from 'common/backBtn';
import getKeyHook from '../../../hooks/getKeyHook';
import Hook from '../register/hook';
import UploadFile from 'common/uploadFile';

const { Option } = Select;
const TEMPLATE = 5; // 模板ID

function ComplaintAccount({ form, history, match }) {

    const { getFieldDecorator } = form;
    const flag = match.params.flag === 'true' ? true : false; // 只有从注册页面过来的才回显
    const { phoneEl, codeEl, validateImg, setValidateImg, getCode, resetCodeItem, dataSource, setdataSource, organization, setOrganization, onSelect, funcRef, userType, setUserType } = Hook(form, TEMPLATE);
    const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();


    // 上传证明资料file
    const normFile = fileInfo => {
        if (!fileInfo) {
            return '';
        }
        const { url } = JSON.parse(fileInfo);
        return url;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!values.imgCaptcha) {
                setValidateImg(true);
                return;
            }
            if (!err && !validateImg) {
                goAppeal({ ...values, ...organization });
            }
        });
    }

    const goAppeal = (param) => {
        const { password, confirmPassword, agreement, ...others } = param;
        getRsaPreparedAsync().then(res => {
            if (res && res.success) {
                const rsaPassWord = getRsaPassWord(password);
                const json = { ...others, password: rsaPassWord };
                appeal(json).then(res => {
                    if (res.statusCode === StatusCode.success) {
                        Modal.success({
                            title: '提示',
                            okText: '确定',
                            content: '申诉已提交，请等待审核',
                            onOk() {
                                history.push('/user/login');
                            }
                        });
                    } else {
                        Modal.error({
                            title: '提示',
                            okText: '确定',
                            content: res.message
                        });
                        resetCodeItem();
                    }
                })
            }
        })
        
    }

    //  获取注册页面已填写的信息回显
    const [complaintInfo, setComplaintInfo] = useState({});
    useEffect(
        function() {
            const info = JSON.parse(sessionStorage.getItem('complaintInfo'));
            setComplaintInfo(info);
            if (flag) {
                setUserType(info['type']);
                setOrganization({ creditCode: info.creditCode, organization: info.organization, bbdQyxxId: info.bbdQyxxId });
            }
        },
        []
    );

    const nameValidator = useRef(
        asyncValidator(
            validateName,
            <ErrorTip text="用户名已被使用！" />,
            (res, value) => {
                if (res && res.success) {
                    res.data = !res.data;
                }
                return res;
            }
        )
    );
    return (
        <div className={classnames(style.register)}>
            <BackBtn history={history} url="/user/login" text="返回登录" />
            <div className={classnames(style.formWrap, "formWrap")}>
                <div className="userTitle">账号申诉</div>
                <Form onSubmit={handleSubmit} {...modalformLayout}>
                    <Form.Item label="用户类型">
                        {getFieldDecorator('type', {
                            initialValue: flag ? complaintInfo['type'] : undefined,
                            rules: [{ required: true, message: <ErrorTip text="请选择用户类型" /> }],
                        })(
                            <Select
                                placeholder="选择用户类型"
                                onChange={(val) => {
                                    setUserType(val);
                                    setdataSource([]);
                                    form.setFieldsValue({organization:undefined});
                                }}
                                getPopupContainer={() => document.getElementById('type')}
                            >
                                {Object.keys(RegisterUserType).map(key => {
                                    const item = RegisterUserType[key];
                                    return <Option value={key} key={key}>{item}</Option>;
                                }
                                )}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="登录用户名"
                    >
                        {getFieldDecorator('username', {
                            initialValue: flag ? complaintInfo.username : '',
                            validateTrigger: 'onBlur',
                            rules: [
                                { required: true, message: <ErrorTip text="请输入用户名" /> },
                                { pattern: UserName.pattern, message: <ErrorTip text={UserName.message} /> },
                                nameValidator.current
                            ]
                        })(
                            <Input
                                type="text"
                                placeholder="4-10位中文、英文（区分大小写）或数字"
                                autoComplete="off"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label='企业名称' >
                        {getFieldDecorator('organization', {
                            validateTrigger: 'onBlur',
                            initialValue: flag ? complaintInfo.organization : '',
                            validateFirst: true,
                            rules: [
                                { required: true, message: <ErrorTip text="请输入关键字选择" /> },
                                {
                                    validator: (rule, value, callback) => {
                                        if (userType === '2') {
                                            organization ? callback() : callback(<ErrorTip text="请从列表中选择企业" />);
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(
                            <AutoComplete
                                onSearch={funcRef.current}
                                onSelect={onSelect}
                                placeholder="请输入关键字选择"
                            >
                                {
                                    dataSource.map(ele => <Option key={ele.bbdQyxxId} creditcode={ele.creditCode} bbdqyxxid={ele.bbdQyxxId}>{ele.companyName}</Option>)
                                }
                            </AutoComplete>
                        )}
                    </Form.Item>
                    {
                        userType === '3' &&
                        <Form.Item label='执业/从业资格证书编号' >
                            {getFieldDecorator('code', {
                                initialValue: flag ? complaintInfo.code : '',
                                rules: [
                                    { required: true, message: <ErrorTip text="输入执业/从业资格证" /> }
                                ]
                            })(
                                <Input type="text" placeholder="输入执业/从业资格证" />
                            )}
                        </Form.Item>
                    }
                    <Form.Item label="姓名">
                        {getFieldDecorator('realName', {
                            initialValue: flag ? complaintInfo.realName : '',
                            rules: [
                                { required: true, message: <ErrorTip text="请输入真实姓名" /> },
                                { max: 50, message: <ErrorTip text="姓名不能超出50位" /> },
                                { whitespace: true, message: <ErrorTip text="请输入真实姓名" /> },
                            ]
                        })(
                            <Input type="text" placeholder="请输入真实姓名" />
                        )}
                    </Form.Item>
                    <Form.Item label="密码">
                        {getFieldDecorator('password', {
                            validateFirst: true,
                            rules: [
                                { required: true, message: <ErrorTip text="请输入密码" /> },
                                { pattern: SpaceCheck.pattern, message: <ErrorTip text={SpaceCheck.message} /> },
                                { pattern: PswCheckSymbol.pattern, message: <ErrorTip text={PswCheckSymbol.message} /> },
                                { pattern: PswCheckLetter.pattern, message: <ErrorTip text={PswCheckLetter.message} /> },
                                { pattern: PswCheckNum.pattern, message: <ErrorTip text={PswCheckNum.message} /> },
                                { min: 6, message: <ErrorTip text="长度至少6位" /> },
                            ]
                        })(
                            <Input type="password" placeholder="包含英文（区分大小写）、数字和特殊符号" autoComplete="new-password" />
                        )}
                    </Form.Item>
                    <Form.Item label="确认密码">
                        {getFieldDecorator('confirmPassword', {
                            validateFirst: true,
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请再次输入密码" />
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        let _password = form.getFieldValue('password');
                                        if (value === _password) {
                                            callback();
                                        } else {
                                            callback(<ErrorTip text="输入密码不匹配" />);
                                        }
                                    }
                                }
                            ]
                        })(
                            <Input
                                type="password"
                                placeholder={'请再次输入密码'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="手机号码">
                        {getFieldDecorator('phone', {
                            initialValue: flag ? complaintInfo.phone : '',
                            rules: [
                                { required: true, message: <ErrorTip text="请输入手机号码" /> },
                                { pattern: CellphoneCheck.pattern, message: <ErrorTip text={CellphoneCheck.message} /> }
                            ]
                        })(
                            <Input type="text" placeholder="请输入手机号码" />
                        )}
                    </Form.Item>
                    <Form.Item label="邮箱地址">
                        {getFieldDecorator('email', {
                            initialValue: flag ? complaintInfo.email : '',
                            rules: [
                                { required: true, message: <ErrorTip text="请输入邮箱地址" /> },
                                { pattern: EmailCheck.pattern, message: EmailCheck.message }
                            ]
                        })(
                            <Input type="text" placeholder="请输入邮箱地址" />
                        )}
                    </Form.Item>
                    <Form.Item label="图片验证码" className="formCaptcha">
                        {
                            getFieldDecorator('imgCaptcha', {
                                initialValue: ''
                            })(
                                <VerifyCode callBack={(tip) => setValidateImg(tip)} showErrTip={validateImg} ref={codeEl} template={TEMPLATE} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="短信验证码">
                        {getFieldDecorator('smsCode', {
                            rules: [
                                { required: true, message: <ErrorTip text="请输入验证码" /> }
                            ]
                        })(
                            <VerifyPhone handleClick={getCode} ref={phoneEl} />
                        )}
                    </Form.Item>
                    <Form.Item label="上传证明资料" style={{ marginBottom: '6px' }}>
                        {getFieldDecorator('url', {
                            valuePropName: 'file',
                            rules: [
                                { required: true, message: '请上传' }
                            ],
                            getValueFromEvent: (e) => normFile(e),
                            multiple: false
                        })(
                            <UploadFile accept=" .doc,.docx,.pdf,.jpg,,.rar,.zip" action="/api/v1.0/sso/appeal/upload" />
                        )}
                    </Form.Item>
                    <Alert className={style.fileWarning} message="请上传能证明身份的材料，企业用户如企业公章、资质、证照扫描件等，中介用户如执业/从业资格证、其他证书扫描件等。" type="warning" showIcon />
                    <Form.Item className="checkItem" wrapperCol={btnLayoutCol}>
                        {getFieldDecorator('agreement', {
                            valuePropName: 'checked',
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (value) {
                                            callback();
                                        } else {
                                            callback(<ErrorTip text="请勾选使用协议" />);
                                        }
                                    }
                                }
                            ]
                        })(
                            <Checkbox>我已认真阅读并同意北京企业上市综合服务平台</Checkbox>
                        )}
                        <Link to="/user/protocol" className={style.protocol} target="_blank">《使用协议》</Link>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={btnLayoutCol}
                    >
                        <Button type="primary" htmlType="submit" className={style.button}>
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

const ComplaintAccountForm = Form.create()(ComplaintAccount);
export default ComplaintAccountForm;
