/**
 * @author hjie
 * 课程详情
 */

import React, {useState} from 'react';
import styles from './styles.scss';
import Steps from 'common/steps/index';
import Lecturers from './lecturers';

export default function({ detailInfo, stepsData = [] }) {

    return (
        <div className={styles.classDetail}>
            <div className = {styles.cards}>
                {
                    stepsData.map( ele => {
                        const { name, domId, param } = ele;
                        const content = detailInfo[param];
                        return  <div className = {styles.cardItem} id = {domId} key = {domId}>
                                    <h4 className = "titleLine" mark = "\ \ \ ">{name}</h4>
                                    {
                                        content ? 
                                            param === 'lecturers' ?
                                                <Lecturers data = {content}/> :
                                                <div className = {styles.text} dangerouslySetInnerHTML = {{__html: content }}></div> :
                                                '- -'
                                    }
                                </div>
                    })
                }
            </div>
            <Steps data = {stepsData}/>
        </div>
    );
}
