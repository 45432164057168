import style from './style.scss';
import React from 'react';
import { Table, Pagination } from 'antd';
import hooks from './hooks';

/**
 * @desc 带分页的表格
 */

// 初始化分页参数
const defaultPagination = {
    pagination: {
        pageNum: 1,
        pageSize: 10
    }
};
// 初始化表格参数
const defaultTableOption = {
    loading: true,
    data: null,
    items: null
};

export default function(props) {
    // 将参数传给hooks
    const [pagination, totalCount, setPagination, loading, data, items] = hooks(
        Object.assign({}, defaultPagination, defaultTableOption, props)
    );
    const { showPagination } = props;
    
    return (
        <div className={style.commonTable}>
            <div>
                <Table
                    rowKey="id"
                    {...props}
                    dataSource={props.parseData(data || items, pagination)}
                    pagination={false}
                    loading={loading}
                />
                {totalCount > (showPagination || 5) ? (
                    <div className={style.paginationBox}>
                        <Pagination
                            {...pagination}
                            current={pagination.pageNum}
                            pageSize={pagination.pageSize}
                            total={totalCount}
                            onChange={current => {
                                props.getPage &&
                                    props.getPage(current, pagination.pageSize);
                                setPagination({
                                    ...pagination,
                                    pageNum: current
                                });
                            }}
                            onShowSizeChange={(current, pageSize) => {
                                props.getPage &&
                                    props.getPage(current, pageSize);
                                setPagination({
                                    ...pagination,
                                    pageNum: current,
                                    pageSize
                                });
                            }}
                            showTotal={total =>
                                `共 ${Math.ceil(
                                    total / pagination.pageSize
                                )} 页 / ${total} 条数据`
                            }
                            showSizeChanger
                            showQuickJumper
                            size="small"
                            pageSizeOptions={['5', '10', '20', '30', '40']}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
