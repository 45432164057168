import { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import { validateMobile, searchCompany, searchIntermediaryCompany } from '../service';
import { debounce } from 'utils/utils';

export default function(form, TEMPLATE){

    const phoneEl = useRef(null);
    const codeEl = useRef(null);
    const funcRef = useRef(() => {});

    // 用户类型
    const [userType, setUserType] = useState('');

    // 图片验证码
    const [validateImg, setValidateImg] = useState(false);

    // 手机验证码 发送手机验证码之前需验证手机号码和图片验证码是否正确
    function getCode(){
        form.validateFields(['phone', 'imgCaptcha'], (err, values) => {
            if (!err && !validateImg && values.imgCaptcha) {
                toValidateMobile(values.phone, values.imgCaptcha);
                return;
            }
            if(!values.imgCaptcha){
                setValidateImg(true);
            }
        });
    }

    // 验证手机号码是否注册
    function toValidateMobile(mobile, imgCaptcha){
        validateMobile({mobile}).then(res =>{
            if(res.success){
                if(!res.data){
                    phoneEl.current.toSend(mobile, TEMPLATE, imgCaptcha); // 倒计时并发送验证码
                }else{
                    Modal.error({
                        title: "提示",
                        okText: '确定',
                        content: "手机号码已存在，请检查"
                    });
                }
            }
        })
    }
    // 注册失败需要重置图片验证码
    const resetCodeItem = () => {
        codeEl.current.reset();
    }

    // 企业名称联想下拉框数据
    const [dataSource, setdataSource] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [companyError, setCompanyError] = useState('');

    function onSelect(value, option) {
        const { children, creditcode, bbdqyxxid } = option.props;
        setOrganization({ creditCode: creditcode, organization: children, bbdQyxxId: bbdqyxxid });
    }

    useEffect(function() {
        funcRef.current = debounce(v => setCompanyName(v), 500, this);
    }, []);

    useEffect( () => {
        let func;
        setOrganization(''); // 重新搜索的时候 需要重置为空
        if(userType === "3") {
            func = searchIntermediaryCompany;
        }else {
            func = searchCompany;
        }
        if(companyName==='') return;
        func({
            companyName,
            pageNum: 1,
            pageSize: 10
        }).then(res => {
            if(res.success){
                const  data  = res.data.data || [];
                setdataSource(data);
                setCompanyError('');
            }
            else{
                setCompanyError(res.message);
            }
        });
    }, [companyName]);

    return { phoneEl, codeEl, validateImg, setValidateImg, getCode, resetCodeItem, dataSource, setdataSource, organization, setOrganization,  onSelect, funcRef, userType, setUserType ,companyError}
}
