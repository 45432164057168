import fetch from 'utils/fetch';
import { API, BasePost } from 'constants/enum';

const listtrain = 'listtrain/';
// 云课堂批量查询
export function getCourse(data) {
    return fetch(`${API.V1}/${listtrain}index/find/course`, data, BasePost );
}

// 专栏批量查询
export function getColumn(data) {
    return fetch(`${API.V1}/${listtrain}index/find/column`, data, BasePost );
}

// 课程报名
export function enrollCourse(data) {
    return fetch(`${API.V1}/${listtrain}index/enroll/course`, data );
}
 
// 专栏报名
export function enrollColumn(data) {
    return fetch(`${API.V1}/${listtrain}index/enroll/column`, data);
}

// 获取标签
export function labels(data) {
    return fetch(`${API.V1}/${listtrain}index/find/labels`, data);
}

// 云课程详情
export function getCourseInfo(data) {
    return fetch(`${API.V1}/${listtrain}index/find/course/info`, data );
}

// 专栏详情
export function getColumnInfo(data) {
    return fetch(`${API.V1}/${listtrain}index/find/column/info`, data );
}