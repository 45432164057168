import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  IPO申报情况
*/
//请求地区数据
export function area() {
    return request(`${API.V1}/cockpit/domesticStock/leftFirst/ipoDeclarationLine/area`)
}
//请求行业数据
export function industry() {
    return request(`${API.V1}/cockpit/domesticStock/leftFirst/ipoDeclarationLine/industry`)
}
//请求券商数据
export function broker() {
    return request(`${API.V1}/cockpit/domesticStock/leftFirst/ipoDeclarationLine/broker`)
}