/**
 * @description 两边有小三角形的title
 */

import React from 'react';
import classnames from 'classnames';
import styles from './styles.scss';
import { Icon } from 'antd';

/**
 * @param classes
 * @param children
 * @returns {*}
 */
export default function({ classes = {}, children }) {
    const { root } = classes;

    return (
        <div
            className={classnames(styles.riskTitle, {
                [root]: Boolean(root)
            })}
        >
            <Icon type="caret-right" theme="filled" />
            <span className = {styles.text}>{children}</span>
            <Icon type="caret-left" theme="filled" />
        </div>
    );
}
