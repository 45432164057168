import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import RiskIndex from '../chart/riskIndex';
import EvaluationIndex from '../chart/evaluationIndex';
import { isNotEmpty } from '../../../../utils/utils';

export default function RiskEvaluation({ data }) {
    const [evaluation, setEvaluation] = useState([]);

    useEffect(
        function() {
            if (isNotEmpty(data)) {
                const { zjjgHfxScore, zjjgHgxScore, zjjgYwnlScore } = data;
                setEvaluation([zjjgHgxScore, zjjgHfxScore, zjjgYwnlScore]);
            }
        },
        [data]
    );

    return (
        <Row>
            <Col span={12}>
                <RiskIndex data={data.zjjgTotalScore?.toFixed(2) || 0} />
            </Col>
            <Col span={12}>
                <EvaluationIndex data={evaluation} />
            </Col>
        </Row>
    );
}
