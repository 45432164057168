import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import RiskTitle from 'common/riskTitle';
import ReactEcharts from 'echarts-for-react';
import { Tabs, Empty } from 'antd';

import FinanceRisk from './financeRisk';
import Pledge from './pledge';
import Judicial from './judicial';
import PublicOpinion from './publicOpinion/PublicOpinion';

import Hook from './hook';

const { TabPane } = Tabs;

export default function(props) {

    const { year, match, stockCode } = props;
    const qyxxId = match.params.companyId;
    const companyName = match.params.companyName;
    const { barOption, levelLoading, gaugeOptions, formList } = Hook(qyxxId, stockCode, year, companyName);

    return (
        <div className={styles.risk}>
            <RiskTitle>风险评分</RiskTitle>
            <div className = {classnames("box", styles.main)}>
                {
                    levelLoading ? '' : 
                    gaugeOptions ?
                    <ReactEcharts
                        style = {{width: "48%", height: "100%"}}
                        option={gaugeOptions}
                        notMerge={true}
                        theme={'myTheme'}
                    /> : 
                    <Empty />
                }
                <span data = "/ / /" className = "splitLine"></span>
                {
                    levelLoading ? '' : 
                    <ReactEcharts
                        style = {{width: "48%", height: "70%", marginLeft: "2%"}}
                        option={barOption}
                        notMerge={true}
                        theme={'myTheme'}
                    />
                }
            </div>
            <RiskTitle>财务报表</RiskTitle>
            <div className = { classnames( "box")}>
                <table className = "comTable">
                    <thead>
                        <tr>
                            <th width = "30%">公司名称</th>
                            <th width = "20%">证券代码</th>
                            <th width = "20%">公告日期</th>
                            <th>查看</th>
                        </tr>
                    </thead>
                </table>
               
                <div className = {styles.comTableBody}>
                {
                    formList && formList.length ? 
                    <table className = "comTable" >
                        <tbody>
                            {
                                formList.map( (ele, index) => {
                                    const { attachmentUrl, companyName, stockCode, year, title } = ele;
                                    return  <tr key = {index}>
                                                <td width = "30%">{companyName}</td>
                                                <td width = "20%">{stockCode}</td>
                                                <td width = "20%">{year}</td>
                                                <td><a href = {attachmentUrl} target = "_blank">{title}</a></td>
                                            </tr>
                                })
                            }                       
                        </tbody>
                    </table> : <Empty />
                } 
                </div>
            </div>
            <RiskTitle>风险详情</RiskTitle>
            <div>
                <div className = "titleLine" mark = "/ / /"></div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="财务风险" key="1">
                        <FinanceRisk year = {year} qyxxId = {qyxxId}/>
                    </TabPane>
                    <TabPane tab="质押担保风险" key="2">
                        <Pledge qyxxId = {qyxxId}  year = {year} stockCode= {stockCode} companyName={companyName} />
                    </TabPane>
                    <TabPane tab="司法风险" key="3" >
                        <Judicial  qyxxId = {qyxxId}  year = {year} stockCode= {stockCode}/>
                    </TabPane>
                    <TabPane tab="舆情口碑" key="4">
                        <PublicOpinion qyxxId = {qyxxId}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}
