/**
 * @author Ray
 */
import React, { useState, useEffect } from 'react';
import Modal from 'common/modalEx';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import WithLabel from 'common/withLabel';
import { Input, Select } from 'antd';
// import { ListingStage } from 'constants/enum';

// 上市阶段选择器
function StageSelector({ onChange, stages }) {
    //
    if (stages?.[0]?.stage) {
        onChange(stages?.[0]?.stage);
    }

    return (
        <WithLabel text="上市阶段">
            <Select
                defaultValue={stages?.[0]?.stage}
                placeholder="选择上市阶段"
                className={styles.stageSelect}
                onChange={onChange}
            >
                {stages.map(function({ stage }, i) {
                    return (
                        <Select.Option title={stage} key={stage} value={stage}>
                            {stage}
                        </Select.Option>
                    );
                })}
            </Select>
        </WithLabel>
    );
}

StageSelector.defaultProps = {
    onChange: () => {
        //
    }
};

StageSelector.propTypes = {
    onChange: PropTypes.func
};

/**
 *
 * @desc 申请模态框，无法申请与可以申请两种情况
 * @param visible
 * @param canApply
 * @param onCancel
 * @param onOk
 * @returns {*}
 * @constructor
 */
// eslint-disable-next-line react/no-multi-comp
export default function ApplyModal({
    visible,
    canApply,
    onCancel,
    onOk,
    stages
}) {
    // stage
    const [stage, setStage] = useState('');
    // setup modal info
    const [modalInfo, setModalInfo] = useState(function() {
        if (canApply) {
            return {
                id: 'success',
                title: '申请阶段',
                content: (
                    <StageSelector
                        onChange={v => {
                            setStage(v);
                        }}
                        stages={stages}
                    />
                )
            };
        }

        return {
            id: 'error',
            title: '提示',
            content: '申请失败，暂无奖励资格'
        };
    });

    function handleOk() {
        //    TODO 处理申请
        onOk(stage);
    }

    function handleCancel() {
        onCancel();
    }

    useEffect(
        function() {
            if (canApply) {
                return setModalInfo({
                    id: 'success',
                    title: '申请阶段',
                    content: (
                        <StageSelector
                            onChange={v => setStage(v)}
                            stages={stages}
                        />
                    )
                });
            }

            setModalInfo({
                id: 'error',
                title: '提示',
                content: '申请失败，暂无奖励资格'
            });
        },
        [canApply]
    );

    return (
        <Modal
            closable
            className={styles.applyModalRoot}
            title={modalInfo.title}
            visible={visible}
            onOk={handleOk}
            okText="确定"
            onCancel={handleCancel}
            onClose={handleCancel}
        >
            {modalInfo.content}
        </Modal>
    );
}

ApplyModal.propTypes = {
    visible: PropTypes.bool,
    canApply: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func
};

ApplyModal.defaultProps = {
    visible: false,
    canApply: false,
    onCancel: () => {
        //
    },
    onOk: () => {
        //
    }
};
