import React from 'react';
import styles from '../../../investment/enterpriseDetail/information/basicInformation/styles.scss';
import {transferStringNull} from 'utils/utils';

export default function({ data = {} }) {

    return (
        <div className={styles.root}  style = {{marginTop: '15px'}}>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>统一社会信用码</td>
                        <td>{transferStringNull(data.creditCode)}</td>
                        <td>登记状态：</td>
                        <td>{ transferStringNull(data.enterpriseStatus)}</td>
                    </tr>
                    <tr>
                        <td>法定代表人</td>
                        <td>{ transferStringNull(data.frname)}</td>
                        <td>成立日期</td>
                        <td>{transferStringNull(data.esdate)}</td>
                    </tr>
                    <tr>
                        <td>企业类型 </td>
                        <td>{transferStringNull(data.companyType)}</td>
                        <td>核准日期</td>
                        <td>{transferStringNull(data.approvalDate)}</td>
                    </tr>
                    <tr>
                        <td>注册资本</td>
                        <td>{transferStringNull(data.regcap)}</td>
                        <td>营业期限自</td>
                        <td>{transferStringNull(data.openfrom) }</td>
                    </tr>
                    <tr>
                        <td>登记机关</td>
                        <td>{transferStringNull(data.regorg) }</td>
                        <td>营业期限至</td>
                        <td>{transferStringNull(data.opento) }</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>企业地址</td>
                        <td colSpan={3}>
                        {transferStringNull(data.address) }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>经营范围</td>
                        <td colSpan={3}>
                            {transferStringNull(data.operateScope) }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
