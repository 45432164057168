/**
 * @author Ray
 * @desc 入库企业分析-基础信息-企业资质
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { qualificationColumns1, qualificationColumns2 } from './columns';
import { getQualification } from './service';
import { isNotEmpty } from '../../../../../utils/utils';

/**
 *
 * @returns {*}
 */
export default function Qualifications({ match }) {
    // 已通过
    const [query1] = useState({
        companyName: match.params.companyName,
        type: 1
    });
    // 未通过
    const [query2] = useState({
        companyName: match.params.companyName,
        type: 0
    });
    return (
        <>
            <Table
                query={query1}
                columns={qualificationColumns1}
                getData={getQualification}
                parseData={res =>
                    (isNotEmpty(res) && isNotEmpty(res.data) && res.data) || []
                }
            />
            <br />
            <Table
                query={query2}
                columns={qualificationColumns2}
                getData={getQualification}
                parseData={res =>
                    (isNotEmpty(res) && isNotEmpty(res.data) && res.data) || []
                }
            />
        </>
    );
}
