/**
 * @author Ray
 */
import React, { useReducer } from 'react';
import SideBar from '../../common/sidebar';
import styles from './styles.scss';
import { renderRoutes } from 'react-router-config';
import ModalEx from '../../common/modalEx';
import * as Actions from '../investment/script/actions';
import reducer from '../investment/script/reducer';
import { initState } from '../investment/script/const';
import { Permission } from '../../hooks/useAuth';

export default function(props) {
    const [store, dispatch] = useReducer(reducer, initState);
    const { Routes, addBaseInfo } = props;
    return (
        <Permission permission={44}>
            <section className={styles.userCenterRoot}>
                <div className={styles.userSidebar}>
                    <SideBar {...props} />
                </div>
                <div className={styles.userContent}>
                    {renderRoutes(Routes, { addBaseInfo, store, dispatch })}
                </div>
                <ModalEx
                    title={'提示'}
                    onCancel={() => dispatch(Actions.closeModal())}
                    {...store.modal}
                >
                    {store.modal.content}
                </ModalEx>
            </section>
        </Permission>
    );
}
