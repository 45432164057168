/**
 * @author Ray
 * @desc 入库企业分析-基础信息-核心竞争力-专利与公司业务匹配性
 */

import React, { useEffect, useState } from 'react';
import { Pie } from '../../../../../echarts';
import { Row, Col } from 'antd';
import { isNotEmpty } from '../../../../../utils/utils';
import { matchData } from './service';
import Title from '../../../../../common/title';

export default function PatentBusinessMatching({ match }) {
    const [query] = useState({
        qyxxId: match.params.companyId
    });

    const [pieData, setPieData] = useState({});
    const [pieNuber, setPieNuber] = useState(null);
    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    function actionPar() {
        const newObj = {};
        const arrDta = [];
        for (let x in pieData) {
            newObj.name = `${x}:${Math.round(pieData[x] * 100 * 100 / pieNuber) / 100}%`;
            newObj.value = `${pieData[x]}`;
            arrDta.push({...newObj})
        }
        return arrDta
    }

    useEffect(() => {
        matchData({ pageNum: 1, pageSize: 10, qyxxId: match.params.companyId }).then(res => {
            if (res.success) {
                let total = 0;
                res.data.data.map(item => {
                    pieData[String(item.main_classcode)] = item.count;
                    total += item.count;
                })
                setPieData(pieData);
                setPieNuber(total)
            }
        })
    }, [])

    return (
        <>
            <Title level={3} text="专利与公司业务匹配性">
                {/*<Typography.Text type="danger">*/}
                {/*    公司行业分类：农业（证监会代码：A01）*/}
                {/*</Typography.Text>*/}
            </Title>
            {/* <Table
                query={query}
                getData={matchData}
                parseData={parseData}
                columns={PatentColumns}
            /> */}
            <Row>
                <Col>
                    <Pie
                        style={{height: 300}}
                        data={actionPar()}
                        formatter={"{b}: {c}"}
                    />
                </Col>
            </Row>
        </>
    );
}

{
    /*<Row gutter={16}>*/
}
{
    /*    <Col span={10}>*/
}
{
    /*        <Pie*/
}
{
    /*            style={{ height: 260 }}*/
}
{
    /*            showLoading={loading}*/
}
{
    /*            data={barData}*/
}
{
    /*        />*/
}
{
    /*    </Col>*/
}
{
    /*    <Col span={14}>*/
}
{
    /*        <Table*/
}
{
    /*            getData={matchData}*/
}
{
    /*            parseData={parseData}*/
}
{
    /*            columns={PatentColumns}*/
}
{
    /*        />*/
}
{
    /*    </Col>*/
}
{
    /*</Row>*/
}
