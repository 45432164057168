/**
 * @author Ray
 */
import React from 'react';
import { Button, Divider, message } from 'antd';
import styles from './styles.scss';
import classnames from 'classnames';
import Title from '../../../common/title';
import Table from '../../../common/tableEx';
import { Columns } from './columns';
import { getRiskList, toTop, unwatch } from './service';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import { LinkButton } from '../../../common/buttons';

/**
 * @description Risks Monitoring List
 * @class MonitorList
 * @extends React.Component
 */

function getColumns(unWatch, toTop, history) {
    return [
        {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            align: 'center',
            width: '15%',
            render: (text, record) => (
                <a
                    rel="noreferrer nofollow noopener"
                    onClick={e => {
                        e.preventDefault();
                        history.push({
                            pathname: `/riskMonitoring/detail/risk/${record.companyName}/${record.qyxxId}`
                        });
                    }}
                >
                    {text}
                </a>
            )
        },
        ...Columns,
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, record) => (
                <>
                    <LinkButton
                        color="#fb7b39"
                        text="取消关注"
                        onClick={() => unWatch(record.qyxxId)}
                    />
                    <LinkButton
                        text="置顶"
                        onClick={() => toTop(record.qyxxId)}
                    />
                </>
            )
        }
    ];
}

export default class MonitorList extends React.Component {
    state = { selectedRowKeys: [], selectedItems: [], timeStamp: undefined };

    // 选中行监听
    handleSelectionChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedItems: selectedRows
        });
    };

    // 取消关注
    unWatch = id => {
        let ids = [];
        if (isNotEmpty(id)) {
            ids.push(id);
        } else {
            ids = this.state.selectedRowKeys;
        }
        unwatch(ids).then(res => {
            if (isNotEmpty(res) && res.success) {
                message.success(res.message);
                this.setState({
                    timeStamp: new Date().getTime(),
                    selectedRowKeys: [],
                    selectedItems: []
                });
            } else {
                message.error(res.message);
            }
        });
    };

    // 置顶
    toTop = id => {
        toTop(id).then(res => {
            if (isNotEmpty(res) && res.success) {
                message.success(res.message);
                this.setState({
                    timeStamp: new Date().getTime()
                });
            } else {
                message.error(res.message);
            }
        });
    };

    render() {
        return (
            <div title="" className={styles.root}>
                <Title text="风险监测关注列表" />
                <Divider />
                <div className={classnames('left', styles.topButtonBox)}>
                    <Button
                        className={styles.unsubscribeSelectedButton}
                        onClick={() => this.unWatch(undefined)}
                        disabled={isEmpty(this.state.selectedRowKeys)}
                    >
                        取消关注选中的公司
                    </Button>
                </div>
                <Table
                    columns={getColumns(
                        this.unWatch,
                        this.toTop,
                        this.props.history
                    )}
                    // loading={false}
                    parseData={res =>
                        isNotEmpty(res) && isNotEmpty(res.data)
                            ? res.data.map(item => ({
                                  ...item,
                                  id: item.qyxxId
                              }))
                            : []
                    }
                    query={this.state.timeStamp}
                    getData={getRiskList}
                    rowSelection={{
                        columnTitle: '',
                        selectedRowKeys: this.state.selectedRowKeys,
                        onChange: this.handleSelectionChange
                    }}
                />
            </div>
        );
    }
}
