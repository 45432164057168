import React from 'react';
import styles from './styles.scss';
import WechatIMG from '../../../header/images/WechatIMG.png';
import moment from 'moment';
/**
 * @desc 顶部中间的title
 * @constructor
 */
export default function TopTitle() {
    return (
        <div>
            {/* <div style={{
                position: 'absolute',
                left: '50px',
                top: '15px',
                fontSize: '28px'
            }}>{moment().format('YYYY-MM-DD')}</div> */}
            <div
                className={styles.topTitle}
                style={{}}
            >
                {/* <img src={WechatIMG} style={{ width: 38 }} /> */}
                &nbsp;&nbsp; 境内上市全景分析
            </div>
            <div className={ styles.rightTime } style={{float: 'right', marginTop: '25px', marginRight: '40px' }}>{moment().format('YYYY-MM-DD HH:mm:ss')}</div>
        </div>
    );
}
