'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _zrender = require('zrender');

var _zrender2 = _interopRequireDefault(_zrender);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Arrow = _zrender2.default.Path.extend({
    type: 'arrow',
    shape: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        k: null,
        angle: 45,
        length: 10,
        lineWidth: 1
    },
    init: function init(opts) {},
    buildPath: function buildPath(ctx, shape) {
        var x1 = shape.x1,
            y1 = shape.y1,
            x2 = shape.x2,
            y2 = shape.y2,
            k = shape.k,
            angle = shape.angle,
            length = shape.length,
            lineWidth = shape.lineWidth;


        var leftX = void 0;
        var leftY = void 0;
        var rightX = void 0;
        var rightY = void 0;
        var offsetX = void 0;
        var offsetY = void 0;
        var _angle = void 0;
        var PI = Math.PI,
            atan2 = Math.atan2,
            cos = Math.cos,
            sin = Math.sin;

        var arrowLength = length;
        // 转换为弧度
        var arrowAngle = angle * PI / 180;

        // Calculate angle
        if (k) {
            _angle = k;
        } else {
            _angle = atan2(y2 - y1, x2 - x1);
        }
        // Adjust angle correctly
        _angle -= PI;
        // Calculate offset to place arrow at edge of path
        offsetX = lineWidth * cos(_angle);
        offsetY = lineWidth * sin(_angle);

        // Calculate coordinates for left half of arrow
        leftX = x2 + arrowLength * cos(_angle + arrowAngle / 2);
        leftY = y2 + arrowLength * sin(_angle + arrowAngle / 2);
        // Calculate coordinates for right half of arrow
        rightX = x2 + arrowLength * cos(_angle - arrowAngle / 2);
        rightY = y2 + arrowLength * sin(_angle - arrowAngle / 2);

        //ctx.beginPath();
        // Draw left half of arrow
        ctx.moveTo(leftX - offsetX, leftY - offsetY);
        ctx.lineTo(x2 - offsetX, y2 - offsetY);
        // Draw right half of arrow
        ctx.lineTo(rightX - offsetX, rightY - offsetY);

        // TODO: 画实线或者虚线
        ctx.lineTo(leftX - offsetX, leftY - offsetY);

        // Visually connect arrow to path
        ctx.moveTo(x2 - offsetX, y2 - offsetY);
        ctx.lineTo(x2 + offsetX, y2 + offsetY);
        // Move back to end of path
        ctx.moveTo(x2, y2);
        ctx.closePath();
        //ctx.fill();
        //ctx.stroke();
    }
});

exports.default = Arrow;
module.exports = exports['default'];