import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import getLocalMap from '../service';
import { getAreaDistribution } from './service';
import { isNotEmpty } from '../../../utils/utils';
import styles from './styles.scss';
import MapTools from './mapTools';
import mapPic from '../images/map.png';
/**
 * @desc 大屏地图
 * @returns {*}
 * @constructor
 */

let coordies = [];

const mapSize = {
    big: { bottom: '5%', top: '25%' },
    small: { bottom: '20%', top: '30%' },
    lower: { bottom: '10%', top: '25%' },
    topper: { bottom: '25%', top: '25%' },
    bigger: {
        bottom: '5%',
        top: '5%'
    },
    lowerer: {
        top: '45%',
        bottom: '5%'
    }
};

export default function BigScreenMap({ style, address }) {
    const [type, setType] = useState(1);
    const [map, setMap] = useState(address);
    const [addressData, setAddressData] = useState([]);
    const [mapsize, setMapSize] = useState('big');
    function renderMap(type) {
        setAddressData([]);
        getLocalMap(address).then(res => {
            echarts.registerMap(address, res);
            coordies = res.features.reduce((acc, feature) => {
                acc[feature.properties.name] = feature.properties.cp;
                return acc;
            }, {});

            getAreaDistribution({
                area: address === '全国' ? '所有地区' : address,
                type: type
            }).then(res => {
                if (isNotEmpty(res?.data)) {
                    let scatterData = res?.data.map(area => {
                        const { areaName, num } = area;
                        return {
                            name: areaName,
                            value: [
                                ...(coordies[areaName] || [0, 0]),
                                num,
                                areaName
                            ]
                        };
                    });

                    setTimeout(() => setAddressData(scatterData), 0);
                }
            });

            if (['辽宁', '福建', '广东', '河南', '四川'].includes(address)) {
                setMapSize('topper');
            } else if (['吉林'].includes(address)) {
                setMapSize('lower');
            } else if (['贵州', '甘肃', '宁夏', '香港'].includes(address)) {
                setMapSize('small');
            } else if (address === '黑龙江') {
                setMapSize('lowerer');
            } else {
                setMapSize('big');
            }

            setMap(address);
        });
    }
    // 地址变化获取新的地图数据
    useEffect(() => {
        renderMap(type);
    }, [address]);

    function getOptions() {
        return {
            geo: [
                // 展示的地图
                {
                    map: map,
                    roam: false,
                    // aspectScale: 0.9,
                    z: 2,
                    zoom: 1,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#4495BC',
                        shadowBlur: 0,
                        shadowOffsetY: 0,
                        borderWidth: 0.5
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: '#1AF2FF'
                        }
                    },
                    ...mapSize[mapsize]
                },
                // 第二个地图，用于突出边框和边界阴影效果。
                {
                    map: map,
                    roam: false,
                    // aspectScale: 0.9,
                    top: 160,
                    z: 1,
                    zoom: 1,
                    bottom: '20%',
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#1AF2FF',
                        borderWidth: 3,
                        shadowBlur: 2,
                        shadowColor: '#1E6FB5',
                        shadowOffsetY: 15
                    },
                    ...mapSize[mapsize]
                }
            ],
            tooltip: {
                trigger: 'item',
                backgroundColor: 'rgba(16,70,118,0.68)',
                borderColor: '#0293DC',
                textStyle: {
                    color: '#fff'
                },
                formatter: params => {
                    return params?.data?.name
                        ? `${params?.data?.name}<br/> ${params?.data
                              ?.value[2] || 0} 家企业`
                        : '暂无数据';
                }
            },
            series: [
                // 地图
                {
                    type: 'map',
                    map: map,
                    roam: false,
                    // aspectScale: 0.9,
                    zoom: 1,
                    geoIndex: 0,
                    data: addressData,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    ...mapSize[mapsize]
                },
                {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    dimensions: ['long', 'lat', 'value', 'address'],
                    hoverAnimation: true,
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    symbolSize: function(value) {
                        return value[2] > 20 ? 20 : value[2];
                    },
                    itemStyle: {
                        normal: {
                            shadowBlur: 5,
                            color: '#FDDE62',
                            shadowColor: '#333'
                        }
                    },
                    data: addressData
                }
            ]
        };
    }

    return (
        <div className={styles.mapContainer}>
            <MapTools
                value={type}
                address={address}
                onChange={type => {
                    setType(type);
                    renderMap(type);
                }}
            />
            <ReactEcharts
                notMerge
                theme={'myTheme'}
                style={{ height: '500px', ...style }}
                option={getOptions()}
            />
            {
                address === '全国' ? 
                <div style={{ 
                    width: '66px',
                    height: '95px', 
                    position: 'absolute',
                    bottom: '36%',
                    left: '65%',
                    background: `url(${mapPic})`, 
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%', 
                }}>
                </div> : ''
            }
        </div>
    );
}

BigScreenMap.defaultProps = {
    style: {},
    address: '全国'
};
