/**
 * @author Ray
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.scss';
import { Button, Form, Input, Tag, Typography } from 'antd';
import VerifyCode from '../../../../common/verifyCode';
import VerifyPhone from '../../../../common/verifyPhone';
import ErrorTip from '../../../../common/errorTip';
import { UserType, API, LEVELS } from '../../../../constants/enum';
import { isNotEmpty, random } from '../../../../utils/utils';
import moment from 'moment';
import { TimeFormat } from '../../../../constants/enum';
import {
  asyncValidator,
  PhonePattern,
  Required
} from '../../../../utils/validator';
import { validateOldPassword, validatePhone } from '../../services';
import getKeyHook from '../../../../hooks/getKeyHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
/**
 *
 * @param form
 * @param userType
 * @param loginName
 * @param onSubmit
 * @returns {*}
 * @constructor
 */
function AccountForm({ form, onSubmit = () => {} }) {
  const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();
  const passwordValidator = useRef(
    asyncValidator(
      validateOldPassword,
      <ErrorTip text="密码错误！" />,
      undefined,
      undefined,
      undefined,
      v => {
        return getRsaPassWord(v);
      }
    )
  );
  const phoneValidator = useRef(
    asyncValidator(
      validatePhone,
      <ErrorTip text="手机号码已被注册" />,
      (res, value) => {
        // 如果输入是用户自己的电话，直接返回正确
        if (value === user?.phone) return { success: true, data: true };
        if (isNotEmpty(res) && res.success) {
          // 翻转结果，传入组件，用来判断输入的手机哈是否可用
          res.data = !res.data;
        }
        return res;
      }
    )
  );
  const phoneRef = useRef(null);
  const codeRef = useRef(null);
  const [codeHash, setHash] = useState(random(5));
  //

  const { user } = useContext(GlobalContext);
  const [verifyCodeSuccess, setVerifyCodeStatus] = useState(false);
  const { getFieldDecorator } = form;

  function handleSubmit() {
    form.validateFields((error, values) => {
      if (!error) {
        getRsaPreparedAsync().then(function(){
          const rsaPassWord = getRsaPassWord(values.password);
          onSubmit({ ...values, password: rsaPassWord })
            .then(success => {
              if (success) {
                form.resetFields();
              }
            })
            .finally(() => {
              codeRef.current.reset();
              setVerifyCodeStatus(false);
            });
        })
      }
    });
  }

  function handleCodeClick() {
    setHash(random(5));
  }

  // 获取手机验证码
  function getCode() {
    form.validateFields(['phone', 'password', 'imgCaptcha'], function(
      errors,
      values
    ) {
      if (!errors) {
        phoneRef.current.toSend(
          values.phone,
          3,
          values.imgCaptcha,
          values.imgCaptcha
        ); // 倒计时并发送验证码
      }
    });
  }

  // 验证码验证之后的回调
  function validateCode(success) {
    setVerifyCodeStatus(success);
  }

  return (
    <Form
      className={styles.formRoot}
      labelCol={{ sm: { span: 10 } }}
      wrapperCol={{ sm: { span: 14 } }}
      onSubmit={handleSubmit}
    >
      <Form.Item label="用户类型">
        <Typography.Text>{UserType[(user?.type)]}</Typography.Text>
        <Tag style={{ marginLeft: 16 }} color="green">
          {LEVELS[user?.level - 1]}
        </Tag>
        {user?.level !== 1 && (
          <Typography.Text type="warning">
            到期时间&nbsp;&nbsp;
            {user?.expireTime &&
              moment(user?.expireTime, 'x').format(TimeFormat)}
          </Typography.Text>
        )}
      </Form.Item>
      <Form.Item label="登录用户名">
        <Typography>{user?.username}</Typography>
      </Form.Item>
      <Form.Item required label="密码">
        {form.getFieldDecorator('password', {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: <ErrorTip text="请输入密码" />
            },
            passwordValidator.current
          ]
        })(
          <Input.Password
            placeholder="请输入密码"
            className={styles.formInput}
          />
        )}
      </Form.Item>
      <Form.Item required label="图片验证码">
        {form.getFieldDecorator('imgCaptcha', {
          rules: [
            {
              required: true,
              message: <ErrorTip text="请输入图片验证码" />
            }
          ]
        })(
          <VerifyCode
            ref={codeRef}
            showErrTip={verifyCodeSuccess}
            callBack={validateCode}
            onCodeClick={handleCodeClick}
            template={3}
            codeSrc={`${API.V1}/sso/kaptcha?${codeHash}`}
          />
        )}
      </Form.Item>
      <Form.Item required label="手机号码">
        {getFieldDecorator('phone', {
          validateTrigger: 'onBlur',
          rules: [Required, PhonePattern, phoneValidator.current]
        })(
          <Input
            maxLength={11}
            placeholder={'请输入手机号码'}
            className={styles.formInput}
          />
        )}
      </Form.Item>
      <Form.Item required label="短信验证码">
        {form.getFieldDecorator('smsCode', {
          rules: [
            {
              required: true,
              message: <ErrorTip text="请输入短信验证码" />
            }
          ]
        })(
          <VerifyPhone
            ref={phoneRef}
            handleClick={getCode}
            timeUp={() => {
              codeRef.current.reset();
              form.setFieldsValue({ imgCaptcha: undefined });
            }}
          />
        )}
      </Form.Item>
      <Form.Item
        wrapperCol={{ sm: { span: 22, offset: 2 } }}
        label=""
        htmlType="submit"
      >
        <div className={styles.center}>
          <Button onClick={handleSubmit} type={'primary'}>
            提交
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default function({ onSubmit }) {
  const [E, setE] = useState(null);

  useEffect(function() {
    let Element = Form.create({ name: 'accountForm' })(AccountForm);

    setE(<Element onSubmit={onSubmit} />);
  }, []);

  return E;
}
