/**
 * @author Ray
 */
import React, {useContext} from 'react';
import {addbaseInfo} from './service';
import {isEmpty} from '../../utils/utils';
import {message} from 'antd';
import {GlobalContext} from '../../common/globalStore/createProvider';

export default React.memo(function Model({children, ...props}) {
    const {user} = useContext(GlobalContext);
    function addBaseInfo({
        code,
        industryLabel,
        industrySubclass,
        srcIndustry,
        plate
    }) {
        code = user?.creditCode;
        return addbaseInfo(
            code,
            industryLabel,
            industrySubclass,
            srcIndustry,
            plate
        ).then(res => {
            if (isEmpty(res)) {
                return;
            }

            if (res.success) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    }

    return children({
        ...props,
        addBaseInfo
    });
});
