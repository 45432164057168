import styles from './styles.scss';
import React, { PureComponent } from 'react';
import { ipoDeclarationDetailsList, lastestIPOCompanyList } from './service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { limitString } from '../../../../utils/utils';
import Radios from './radio';

/**
 * @desc IPO申报明细
 * @returns {*}
 * @constructor
 */

export default class IPODeclarationDetailsList extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        type: 1,
        data: [],
        data1: []
      }
    }

    componentDidMount = () => {
      const { address } = this.props;
      //表格无缝滚动
      this.industryNews = setInterval(this.taskIndustryNews, 50);

      //请求IPO申报明细接口数据
      ipoDeclarationDetailsList({ area: address }).then(({ success, data }) => {
          if (success == true) {
              this.setState({
                  data
              })
          } else {
              <Empty />
          }
      })

      //请求最新上市公司接口数据
      lastestIPOCompanyList({ area: address }).then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data1: data
                })
            } else {
                <Empty />
            }
      })

    }

    componentWillReceiveProps(nextProps) {
      if (this.props.address != nextProps.address) {
          const { address } = nextProps;
          //表格无缝滚动
          ipoDeclarationDetailsList({ area: address }).then(({ success, data }) => {
              if (success == true) {
                  this.setState({
                      data
                  })
              } else {
                  <Empty />
              }
          })

          lastestIPOCompanyList({ area: address }).then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data1: data
                    })
                } else {
                    <Empty />
                }
          })
      }
    }

    taskIndustryNews = () => {
      if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
          this.refs.newDiv.scrollTop = 0;
      }
      else if (this.refs.newDiv) {
          this.refs.newDiv.scrollTop += 1;
      }
    }

    handleIndustryNewsEnter = () => {
      clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render() {
        const radiosData = [
            { name: '最新IPO申报明细', value: 1 },
            { name: '最新上市公司', value: 2 },
        ];
        const { type, data, data1 } = this.state;
        
        return (
        <div className={ styles.IPODeclarationDetailsList }>
            <div className={ styles.leftSecondTitle }>
                IPO申报明细
            </div>
            <div className={ styles.IPODeclarationDetailsListRadio }>
                <Radios
                    value={type}
                    data={radiosData}
                    defaultValue={1}
                    onChange={t => {
                    this.setState({
                        type: t.target.value
                    });
                    }}
                />
            </div>
            {
                type == 1 ? <div className={ styles.ceShiTable }>
                <div className={ styles.ceShiTabletitle }>
                    <span className={ styles.ceShiTabletext2 }>公司名称</span>
                    <span className={ styles.ceShiTabletext2 }>上市板</span>
                    <span className={ styles.ceShiTabletext2 }>证监会行业</span>
                    <span className={ styles.ceShiTabletext2 }>受理日期</span>
                </div>
                <div
                    ref='newDiv'
                    className={ styles.ceShiTablebody }
                    onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                    onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                >
                    <ul ref='newDivUI'>
                        {data && data.length > 0
                            ?
                            data.map(this.tableBody)
                            : <span className='noData'>暂无数据</span>
                        }
                    </ul>
                </div>
            </div> : 
                type == 2 ? <div className={ styles.ceShiTable }>
                <div className={ styles.ceShiTabletitle }>
                    <span className={ styles.ceShiTabletext2 }>地区</span>
                    <span className={ styles.ceShiTabletext2 }>企业名</span>
                    <span className={ styles.ceShiTabletext2 }>行业</span>
                    <span className={ styles.ceShiTabletext2 }>上市日期</span>
                </div>
                <div
                    ref='newDiv'
                    className={ styles.ceShiTablebody }
                    onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                    onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                >
                    <ul ref='newDivUI'>
                        {data1 && data1.length > 0
                            ?
                            data1.map(this.tableBody1)
                            : <span className='noData'>暂无数据</span>
                        }
                    </ul>
                </div>
            </div> : ''
            }
            
        </div>
          
        )
    }

    tableBody = (item, index) => {
      return (
    //     <Link
    //     target="_blank"
    //     to={
    //       ele.isMarket
    //         ? `/riskMonitoring/detail/risk/${fullName}/${ele.qyxxId}`
    //         : `/investment/enterpriseAnalysis/${fullName}/${ele.qyxxId}/${ele.creditCode}`
    //     }
    //   >
    //     {fullName}
    //   </Link>
          <li key={index}>
              
                <span className={ styles.ceShiTabletext2 }>
                    <Link
                        target="_blank"
                        to={`/investment/enterpriseAnalysis/${item.companyName}/${item.companyId}/${item.creditCode}`}
                    >
                        {limitString(8,item.companyName)}
                    </Link>
                </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.ipoLocal}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {limitString(5, item.industry)}
              </span>
              <span className={ styles.ceShiTabletext2 }>
                  {item.ipoDate}
              </span>
         </li>
      );
    }

    tableBody1 = (item, index) => {
        return (
            <li key={index}>
                <span className={ styles.ceShiTabletext2 }>
                    {item.area}
                </span>
                <span className={ styles.ceShiTabletext2 }>
                    {limitString(8, item.companyName)}
                </span>
                <span className={ styles.ceShiTabletext2 }>
                    {limitString(5, item.industry)}
                </span>
                <span className={ styles.ceShiTabletext2 }>
                    {item.ipoDate}
                </span>
           </li>
        );
      }
}