import React, { useEffect, useState } from 'react';
import { Gauge } from '../../../../echarts';

export default function RiskIndex({ data }) {
    return (
        <Gauge
            data={[{ name: '服务能力评分', value: data }]}
            style={{ height: 380 }}
        />
    );
}
