import styles from './styles.scss';
import React, { useEffect, useState } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { Select, Empty, message } from 'antd';
const { Option } = Select;

import { getIndustry, getData } from './service';

/**
 * @desc 行业内优质企业分布情况
 * @returns {*}
 * @constructor
 */
const color = ['#0CD64A', '#00DBFF', '#61A5E8', '#F87E12', '#D4614B'];
export default function BarEchart({ address, style }) {
  const [industry, setIndustry] = useState([]);
  const [industryVal, setIndustryVal] = useState('所有行业');
  const [options, setOptions] = useState({ loading: true, data: null });

  useEffect(() => {
    getIndustry().then(res => {
      if (res.success) {
        setIndustry(res.data);
      }
    });
  }, []);

  useEffect(() => {
    getBarData();
  }, [industryVal, address]);

  function getBarData() {
    getData({
      area: address,
      industry: industryVal
    }).then(res => {
      if (res.success) {
        let option = getOptions(res.data || []);
        setOptions({ loading: false, data: option });
      } else {
        message.error(res.message);
      }
    });
  }

  function getOptions(data) {
    const yAxis = ['AAA', 'AA', 'A', 'BBB', 'BB'].reverse();
    const defaultValue = {
      name: null,
      value: 0,
      itemStyle: {
        color: color[4]
      }
    };
    let result = {
      AAA: { ...defaultValue, name: 'AAA' },
      AA: { ...defaultValue, name: 'AA' },
      A: { ...defaultValue, name: 'A' },
      BBB: { ...defaultValue, name: 'BBB' },
      BB: { ...defaultValue, name: 'BB' }
    };
    data.forEach((ele, i) => {
      result[ele.level] = {
        name: ele.level,
        value: ele.num,
        itemStyle: {
          color: color[i]
        }
      };
    });
    const series = [
      result['AAA'],
      result['AA'],
      result['A'],
      result['BBB'],
      result['BB']
    ].reverse();
    return {
      tooltip: {
        show: true,
        trigger: 'axis',
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        axisPointer: {
          type: 'shadow'
        },
        formatter: v => {
          const { name, value } = v[0];
          return `<div class = "bgPageTooltip">
                                <p class = "title">${name}</p> 
                                <p><b class = "num">${value}</b> 家企业</p>     
                            </div>`;
        }
      },
      grid: {
        left: 60,
        right: 30
      },
      xAxis: [
        {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(86,197,253,0.3)'
            }
          }
        }
      ],
      yAxis: {
        data: yAxis,
        type: 'category',
        axisLabel: {
          color: '#fff'
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: [
        {
          type: 'bar',
          data: series,
          barMaxWidth: 16,
          itemStyle: {
            barBorderRadius: 0
          },
          label: {
            show: true,
            position: 'right',
            color: '#fff'
          }
        }
      ]
    };
  }
  return (
    <div className={styles.box} style={{ ...style }}>
      <div className={styles.selectBox}>
        <Select
          defaultValue={industryVal}
          style={{ width: 150 }}
          onChange={value => setIndustryVal(value)}
        >
          <Option value="所有行业">所有行业</Option>
          {industry.map(ele => {
            return (
              <Option value={ele} key={ele}>
                {ele}
              </Option>
            );
          })}
        </Select>
      </div>
      {options.loading ? (
        ''
      ) : (
        <ReactEcharts
          theme={'myTheme'}
          style={{
            height: '100%'
          }}
          option={options.data}
        />
      )}
    </div>
  );
}
