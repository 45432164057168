import React, { useEffect, useRef, useState } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import getLocalMap from '../service';
import { getAreaDistribution } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import styles from './styles.scss';
import MapTools from './mapTools';
import Map from './map';

/**
 * @desc 大屏地图
 * @returns {*}
 * @constructor
 */

const mapSize = {
    big: { bottom: '15%', top: '15%' },
    small: { bottom: '25%', top: '25%' },
    lower: { bottom: '10%', top: '25%' },
    topper: { bottom: '25%', top: '10%' },
    bigger: {
        bottom: '5%',
        top: '5%'
    },
    smaller: {
        bottom: '25%',
        top: '35%'
    },
    lowerer: {
        top: '45%',
        bottom: '5%'
    }
};

export default function BigScreenMap({ style, address }) {
    const [type, setType] = useState(1);
    const echartsInstance = useRef(null);
    const [addressData, setAddressData] = useState([]);
    const [map, setMap] = useState();
    const [mapsize, setMapSize] = useState('big');
    // 地址变化获取新的地图数据
    useEffect(() => {
        // 请求申请数据，请求地图数据。
        setAddressData([]);
        getLocalMap(address).then(res => {

        });
    }, [address]);
    
    function getOptions() {
        return {
            geo: [
                // 展示的地图
                {
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    z: 2,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#4495BC',
                        shadowBlur: 0,
                        shadowOffsetY: 0,
                        borderWidth: 0.5
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: '#1AF2FF'
                        }
                    },
                    ...mapSize[mapsize]
                },
                // 第二个地图，用于突出边框和边界阴影效果。
                {
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    z: 1,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        areaColor: '#006DA2',
                        borderColor: '#1AF2FF',
                        borderWidth: 3,
                        shadowBlur: 2,
                        shadowColor: '#1E6FB5',
                        shadowOffsetY: 15
                    },
                    ...mapSize[mapsize]
                }
            ],
            tooltip: {
                trigger: 'item',
                backgroundColor: 'rgba(16,70,118,0.68)',
                borderColor: '#0293DC',
                textStyle: {
                    color: '#fff'
                },
                formatter: params => {
                    return params?.data?.name
                        ? `${params?.data?.name}<br/> ${params?.data
                              ?.value[2] || 0} 家企业`
                        : '暂无数据';
                }
            },
            series: [
                // 地图
                {
                    type: 'map',
                    map: map,
                    roam: false,
                    aspectScale: 0.8,
                    scale: 0.8,
                    geoIndex: 0,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    data: addressData,
                    ...mapSize[mapsize]
                },
                {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    dimensions: ['long', 'lat', 'value', 'address'],
                    hoverAnimation: true,
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    symbolSize: function(value) {
                        return value[2] > 20 ? 20 : value[2];
                    },
                    itemStyle: {
                        normal: {
                            shadowBlur: 5,
                            color: '#FDDE62',
                            shadowColor: '#333'
                        }
                    },
                    data: addressData
                }
            ]
        };
    }

    return (
        <div className={styles.mapContainer}>
            <MapTools
                value={type}
                address={address}
                onChange={type => {
                    setType(type);
                }}
            />
            <Map address={address} style={{ height: '100%' }}/>
        </div>
    );
}

BigScreenMap.defaultProps = {
    style: {},
    address: '全国'
};
