/**
 * @author Ray
 */
import request from 'utils/fetch';
import { API } from 'constants/enum';

/**
 * @description 根据id获取申请信息
 * @param id
 */
export function getApplyInfo(id) {
    return request(`${API.V1}/policypractice/apply/showHistoryApplyDetail`, {
        id
    });
}
