import React from 'react';
import RightFirst from './LastestIPOCompanyList';
/**
 * @desc  行业地域分布
 */
export default function RightFirstIndex({ address, value, style }) {
  return (
    <RightFirst address={address} value={value} />
  );
}
