import React from 'react';
import styles from './styles.scss';

export default function CardHeader({ title, prefix, suffix }) {
    return (
        <div className={styles.cardHeader}>
            {prefix}
            {title}
            {suffix}
        </div>
    );
}
