/**
 * @author hjie
 * 云课堂卡片
 */

import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.scss';
import { Button } from 'antd';
import  basicBg from '../../containers/listingTraining/images/basicBg.png';
import { ISENROLL, CATEGORY } from 'constants/common';

/**
 *
 * @returns {*}
 */
export default function({ btncallback, data, type }) {
    const {frontUrl, name, title, introduction, enrollCount, id, isEnroll, lecturers, author } = data;
    const flag = !!(isEnroll === ISENROLL.yes);
    const isCourse = !!(type === CATEGORY.Course);
    const authors = isCourse ? 
    lecturers ? lecturers.map( (ele, i) => `${ele.name}${ i === lecturers.length - 1 ? '' : '、' } ` ) : '' 
    : author;

    return (
        <div className={styles.card}>
            <Link className = {styles.img} to = { isCourse ? `/listingTraining/courseDetail/${id}` : `/listingTraining/columnDetail/${id}` } >
                <img src = {frontUrl || basicBg} title = "封面图"/>
            </Link>
            <div className = {styles.cardBody}>
                <h4 className = "ellipsis" title = {name || title}>{name || title || '- -'}</h4>
                <p className = "ellipsis" title = {authors}>{ authors || '- -'}</p>
                <p className = "ellipsis" title = {introduction}>{introduction || '- -'}</p>
                <div>
                    <span>报名人数：<b className = {styles.num}>{enrollCount || 0}</b></span>
                    <Button className = {classnames(styles.cardBtn, {[styles.grayBtn]: flag },"fr")} onClick = { () => {btncallback && btncallback(id, flag ? ISENROLL.not : ISENROLL.yes );}}>{flag ? '取消报名' : '报名'}</Button>
                </div>
            </div>
        </div>
    );
}
