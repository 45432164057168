import { useState, useEffect } from 'react';

/**
 * @desc 分页的逻辑
 */
export default function(props) {
  // const [lock, setLock] = useState(props.lock);
  const [pagination, setPagination] = useState(props.pagination);
  const [totalCount, setCount] = useState(0);
  const [loading, setLoading] = useState(props.loading);
  const [data, setData] = useState(props.data);
  function doFetch() {
    // setLoading(true);
    props
      .getData({
        ...props.query,
        pageSize: pagination.pageSize,
        pageNum: pagination.pageNum
      })
      .then(res => {
        setLoading(false);
        if (res.success) {
          setCount(res.data ? res.data.totalCount : totalCount);
          setData(res.data);
        }
      });
  }

  useEffect(
    () => {
      !props.lock && doFetch();
    },
    // 当这其中任一项变化时请求数据。
    // 推荐作法应该是一个useState管理一个状态，由于这里状态比较多，就简化成了一个对象，所以这里有警告
    // [props.pagination.pageSize, props.pagination.pageNum, props.query]
    [pagination]
  );

  // 如果传递的query参数不为{}，就会将pageNum重置为1；
  // 否则，就使用当前参数列表 发送请求，以刷新数据
  useEffect(() => {
    if (!props.query) {
      return;
    }
    const params = Object.keys(props.query);
    if (params.length) {
      setPagination({
        pageNum: 1,
        pageSize: pagination.pageSize
      });
    } else {
      !props.lock && doFetch();
    }
  }, [props.query]);
  
  return [pagination, totalCount, setPagination, loading, data];
}
