import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 申请上市咨询
 * @param params
 * @param id
 */
export function submitApply(params, id) {
    return request(
        `${API.V1}/investbank/apply/add`,
        { ...params, id },
        { method: 'post' }
    );
}

/**
 *
 * @param id
 */
export function submitApplyById(id) {
    return request(`${API.V1}/investbank/apply/subimit`, {
        id,
        submitStatus: 2
    });
}

/**
 *
 * @param id
 */
export function saveApplyById(id) {
    return request(`${API.V1}/investbank/apply/subimit`, {
        id,
        submitStatus: 1
    });
}

/**
 * @desc 获取详情
 * @param id
 */
export function callApplyDetail(id) {
    return request(`${API.V1}/investbank/apply/get`, {
        id
    });
}

/**
 *
 * @param params
 * @param id
 */
export function updateApply(params, id) {
    return request(
        `${API.V1}/investbank/apply/update`,
        {
            ...params,
            id
        },
        {
            method: 'post'
        }
    );
}

/**
 * @desc 删除申请
 * @param id
 */
export function deleteApply(id) {
    return request(`${API.V1}/investbank/apply/delete`, {
        id,
        type: 2
    });
}

/**
 * @desc  经营信息查询
 * @param creditCode
 */
export function getOperatingData(creditCode, type) {
    return request(`${API.V1}/investbank/company/jyxx/list`, {
        creditCode,
        type
    });
}

/**
 * @desc 经营信息其他信息
 * @param creditCode
 */
export function getOperatingOtherData(creditCode) {
    return request(`${API.V1}/investbank/company/jyxx/other/get`, {
        creditCode
    });
}
