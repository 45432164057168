/**
 * @author Ray
 * @desc 科创属性评分
 */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Icon, Tooltip } from 'antd';
import { Gauge, Radar } from '../../../../../echarts';
import Title from '../../../../../common/title';
import { kechuangScore } from './service';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import styles from './styles.scss';
import katex from 'katex';

export default function ScientificInnovation({ match }) {
    const gs = useContext(GlobalContext);
    const [radarOptions, setRadarOptions] = useState({
        indicator: [
            { text: '核心技术情况', max: 5 },
            { text: '研发能力情况', max: 5 },
            { text: '研发成果市场认可情况', max: 5 },
            { text: '相对竞争优势情况', max: 5 },
            { text: '技术成果转化情况', max: 5 },
            { text: '与政策导向符合情况', max: 5 }
        ],
        data: []
    });
    const [score, setScore] = useState(0);
    useEffect(() => {
        kechuangScore({
            companyName: match.params.companyName,
            type: 3
        }).then(res => {
            if (res.success) {
                console.log(res,8888)
                setScore(res.data?.score?.techInnovateScore || 0);
                setRadarOptions({
                    ...radarOptions,
                    data: [
                        res.data?.score?.coreTechScore
                            ? res.data?.score?.coreTechScore.toFixed(2)
                            : 0,
                        res.data?.score?.researchAlilityScore
                            ? res.data?.score?.researchAlilityScore.toFixed(2)
                            : 0,
                        res.data?.score?.researchResultAcceptScore
                            ? res.data?.score?.researchResultAcceptScore.toFixed(
                                  2
                              )
                            : 0,
                        res.data?.score?.relativeCompleteScore
                            ? res.data?.score?.relativeCompleteScore.toFixed(2)
                            : 0,
                        res.data?.score?.techResultTransformScore
                            ? res.data?.score?.techResultTransformScore.toFixed(
                                  2
                              )
                            : 0,
                        res.data?.score?.policyOrientMatchScore
                            ? res.data?.score?.policyOrientMatchScore.toFixed(2)
                            : 0
                    ]
                });
            }
        });
    }, []);

    const tipHtml = katex.renderToString(
        'TotalCount = \\frac{\\sum_{i=1}^6 \\times score_i}{5} \\times 100 = \\frac{\\sum_{i=1}^6 \\sum_{j} \\alpha_i \\omega_{i,j} \\beta_{i,j}}{5}  \\times 100',
        {
            throwOnError: false
        }
    );
    const tip1 = katex.renderToString('\\alpha_i');
    const tip2 = katex.renderToString('\\omega_{i,j}');
    const tip3 = katex.renderToString('\\beta_{i,j}');
    const tip4 = katex.renderToString('score_i');
    return (
        (gs.isAdvanceUser || gs.isVIPUser || gs.isQiYuan || gs.isGOV) && (
            <>
                <Title level={3} text="科创属性判断" />
                <Row gutter={16}>
                    <Col span={12}>
                        <Gauge
                            style={{ height: 360 }}
                            data={[{ name: '', value: score }]} //科创钻石评分
                        />
                        <span className={styles.title}>
                            <Tooltip
                                placement="bottom"
                                overlayStyle={{ maxWidth: 500 }}
                                title={
                                    <p>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: tipHtml
                                            }}
                                        />
                                        <br />
                                        <br />
                                        <p>TotalScore为科创评分，满分为100分</p>
                                        <br />
                                        <p>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: tip4
                                                }}
                                            />
                                            表示第i项大类评分
                                        </p>
                                        <br />
                                        <p>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: tip1
                                                }}
                                            />
                                            表示第i项大类对应的权重
                                        </p>
                                        <br />
                                        <p>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: tip2
                                                }}
                                            />
                                            为第i项大类中第j细项的权重
                                        </p>
                                        <br />
                                        <p>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: tip3
                                                }}
                                            />
                                            表示第i项大类中第j细项的分值
                                        </p>
                                    </p>
                                }
                            >
                                <span>
                                    <Icon type="exclamation-circle" />
                                    &nbsp; 科创钻石评分
                                </span>
                            </Tooltip>
                        </span>
                    </Col>
                    <Col span={12}>
                        <Radar {...radarOptions} />
                    </Col>
                </Row>
            </>
        )
    );
}
