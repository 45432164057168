import React, { useState, useEffect } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import styles from './styles.scss';
import { isNotEmpty, limitString, strInsert } from '../../../utils/utils';
import { Col, Row } from 'antd';
import Title from './title';
import { getData } from './service';
/**
 * @desc 中介信息概览
 * @returns {*}
 * @constructor
 */
const xAxisStyle = {
  axisLabel: {
    color: '#fff'
  },
  axisLine: {
    lineStyle: {
      color: 'rgba(86,197,253,0.3)'
    }
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: 'rgba(86,197,253,0.3)'
    }
  }
};

const color = ['#0CD64A', '#00DBFF', '#61A5E8', '#F87E12', '#D4614B'];

export default function Statistics({ style = {} }) {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    areaList: [],
    industryList: [],
    mainList: [],
    sponsorList: []
  });

  function getOptions(data = [], parser = v => v) {
    data = parser(data);

    return {
      grid: {
        bottom: 42,
        left: 40,
        right: 16,
        top: 8
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        textStyle: { color: '#fff' },
        axisPointer: { type: 'shadow' },
        confine: true,
        formatter: params => {
          const { name, value } = params[0];
          const { name: name1, value: value1 } = params[1];
          return `<div class = "bgPageTooltip">
                                <p class = "title">${name}: <b class = "num">${value}</b></p>
                                <p class = "title">${name1}: <b class = "num">${value1}</b></p>
                            </div>`;
        }
      },
      xAxis: [
        {
          ...xAxisStyle,
          type: 'category',
          data: data.map(ele => limitString(2, ele.name)),
          axisLabel: {
            color: '#fff',
            rotate: 45,
            margin: 14,
            fontSize: 12
          }
        }
      ],
      yAxis: {
        ...xAxisStyle,
        type: 'value'
      },
      series: [
        {
          type: 'bar',
          data: data.map(() => ({
            name: '总数',
            value: total
          })),
          barMaxWidth: 20,
          itemStyle: {
            barBorderRadius: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#0E90E8'
              },
              {
                offset: 1,
                color: '#023462'
              }
            ])
          },
          label: {
            show: true,
            position: 'top',
            color: '#fff'
          }
        },
        {
          type: 'bar',
          data: data.map((ele, i) => ({
            ...ele,
            itemStyle: {
              color: color[i]
            }
          })),
          barMaxWidth: 20,
          itemStyle: {
            barBorderRadius: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#F87E12'
              },
              {
                offset: 1,
                color: '#023462'
              }
            ])
          },
          label: {
            show: true,
            position: 'top',
            color: '#fff'
          }
        }
      ]
    };
  }

  function getCount() {
    getData().then(res => {
      if (isNotEmpty(res?.data)) {
        setData(res.data);
        setTotal(res.data.total);
      }
    });
  }

  useEffect(() => {
    getCount();
  }, []);

  return (
    <div className={styles.mapContainer} style={{ ...style }}>
      <Row style={{ height: '100%' }}>
        <Col span={12} style={{ height: '49%' }}>
          <div style={{ height: '20%' }}>
            <Title title="地区" />
          </div>
          <div style={{ height: '80%' }}>
            <ReactEcharts
              theme={'myTheme'}
              style={{
                height: '100%'
              }}
              option={getOptions(data.areaList, data =>
                data.map(d => ({
                  name: d.name,
                  value: d.num
                }))
              )}
            />
          </div>
        </Col>
        <Col span={12} style={{ height: '49%' }}>
          <div style={{ height: '20%' }}>
            <Title title="行业" />
          </div>
          <div style={{ height: '80%' }}>
            <ReactEcharts
              theme={'myTheme'}
              style={{
                height: '100%'
              }}
              option={getOptions(data.industryList, data =>
                data.map(d => ({
                  name: d.name,
                  value: d.num
                }))
              )}
            />
          </div>
        </Col>
        <Col span={12} style={{ height: '49%' }}>
          <div style={{ height: '20%' }}>
            <Title title="科创主题" />
          </div>
          <div style={{ height: '80%' }}>
            <ReactEcharts
              theme={'myTheme'}
              style={{
                height: '100%'
              }}
              option={getOptions(data.mainList, data =>
                data.map(d => ({
                  name: d.name,
                  value: d.num
                }))
              )}
            />
          </div>
        </Col>
        <Col span={12} style={{ height: '49%' }}>
          <div style={{ height: '20%' }}>
            <Title title="保荐机构" />
          </div>
          <div style={{ height: '80%' }}>
            <ReactEcharts
              theme={'myTheme'}
              style={{
                height: '100%'
              }}
              option={getOptions(data.sponsorList, data =>
                data.map(d => ({
                  name: d.name,
                  value: d.num
                }))
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
