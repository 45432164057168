/**
 * @author Ray
 */
import React from 'react';
import { Typography } from 'antd';
import styles from './styles.scss';

export default function({ text }) {
    return (
        <section className={styles.supplementRoot}>
            <span dangerouslySetInnerHTML={{__html: text}}></span>
        </section>
    );
}
