/**
 * @author Ray
 * @desc entry file of the material fill page
 */

import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Icon, message } from 'antd';
import Title from '../../../../common/title';
import Financial from '../../../../common/financialTabs';
import styles from './styles.scss';
import OtherForm from './others';
import FinancialForm from './financial';
import MainIndustry from './main';
import Relationships from './relationships';
import Footer from './footer';
import Vendor from './vendor';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import { callApplyDetail } from '../service';
import Operation from './images/operation.svg';
import Refresh from '../../../../common/financialTabs/refreshBtn';
import { parser } from '../../common/inputTable';

export default function Material({
    demand,
    attachment,
    setAttachment,
    setStep,
    submit,
    match,
    isPreview,
    history,
    main,
    setMain,
    customer,
    setCustomer,
    other,
    setOther,
    vendor,
    setVendor,
    setNeedUpdate,
    setBannerShow
}) {
    const id = match.params.id;
    const attachmentRef = useRef(null);
    const mainRef = useRef(null);
    const customerRef = useRef(null);
    const vendorRef = useRef(null);
    const otherRef = useRef(null);
    const [timestamp1, setTimestamp1] = useState(isNotEmpty(id) ? 0 : 1);
    const [timestamp2, setTimestamp2] = useState(isNotEmpty(id) ? 0 : 1);
    const [timestamp3, setTimestamp3] = useState(isNotEmpty(id) ? 0 : 1);

    useEffect(() => {
        setBannerShow(false);
    }, []);

    useEffect(function () {
        setStep(1);

        if (isEmpty(demand) && !isPreview) {
            // 如果从父组件下来的需求数据为空，则返回需求填报界面
            setStep(0);
            history.push({
                pathname: `/investment/consultant/require/${
                    isNotEmpty(id) ? id : ''
                    }`
            });
        }

        if (isNotEmpty(id)) {
            callApplyDetail(id).then(function (res) {
                if (isNotEmpty(res) && isNotEmpty(res.data)) {
                    const {
                        attachment,
                        business,
                        customer,
                        supplier,
                        other
                    } = res.data;
                    attachmentRef.current?.setFieldsValue({ ...attachment });
                    otherRef.current?.setFieldsValue(other);
                    mainRef.current?.setFieldsValue(parser(business));
                    vendorRef.current?.setFieldsValue(parser(supplier));
                    customerRef.current?.setFieldsValue(parser(customer));
                    setAttachment({ ...attachment });
                    setVendor(supplier);
                    setOther(other);
                    setCustomer(customer);
                    setMain(business);
                } else {
                    message.error(res.message);
                }
            });
        }
    }, []);

    function handleSave() {
        submit(1, id);
    }

    function handleSubmit() {
        submit(2, id);
    }

    function goTo(e) {
        e.preventDefault();
        e.stopPropagation();
        // 打开新的窗口
        setTimeout(
            () =>
                window.open(
                    location.protocol +
                    '//' +
                    location.host +
                    '/companyInformation/operating',
                    '_blank'
                ),
            0
        );
    }

    return (
        <div className={styles.root}>
            <Title
                level={2}
                text={
                    <span>
                        <i style={{ color: 'red' }}>*</i>&nbsp;主营信息
                    </span>
                }
            >
                {!isPreview && (
                    <div class={styles.innerTitle}>
                        <Refresh
                            type="primary"
                            onClick={() => setTimestamp1(timestamp1 + 1)}
                        >
                            刷新
                        </Refresh>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={goTo}>
                            <Icon component={Operation} />
                            主营信息填报
                        </Button>
                    </div>
                )}
            </Title>
            <MainIndustry
                value={main}
                onChange={data => setMain(data)}
                reloadFlag={timestamp1}
                isPreview
                onInit={ref => (mainRef.current = ref)}
            />
            <Title
                level={2}
                text={
                    <span>
                        <i style={{ color: 'red' }}>*</i>&nbsp;上下游关系
                    </span>
                }
            >
                {!isPreview && (
                    <div class={styles.innerTitle}>
                        <Refresh
                            type="primary"
                            onClick={() => setTimestamp2(timestamp2 + 1)}
                        >
                            刷新
                        </Refresh>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={goTo}>
                            <Icon component={Operation} />
                            上下游关系信息填报
                        </Button>
                    </div>
                )}
            </Title>
            <Relationships
                value={customer}
                onChange={data => setCustomer(data)}
                reloadFlag={timestamp2}
                isPreview
                onInit={ref => (customerRef.current = ref)}
            />
            <Divider />
            <Vendor
                value={vendor}
                onChange={data => setVendor(data)}
                reloadFlag={timestamp2}
                isPreview
                onInit={ref => (vendorRef.current = ref)}
            />
            <Title
                level={2}
                text={
                    <span>
                        <i style={{ color: 'red' }}>*</i>&nbsp;其他信息
                    </span>
                }
            >
                {!isPreview && (
                    <div class={styles.innerTitle}>
                        <Refresh
                            type="primary"
                            onClick={() => setTimestamp3(timestamp3 + 1)}
                        >
                            刷新
                        </Refresh>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={goTo}>
                            <Icon component={Operation} />
                            其他信息填报
                        </Button>
                    </div>
                )}
            </Title>
            <OtherForm
                value={other}
                onChange={data => setOther(data)}
                reloadFlag={timestamp3}
                isPreview
                onInit={ref => (otherRef.current = ref)}
            />
            <Title level={2} text="财务相关信息" />
            <FinancialForm
                isPreview={isPreview}
                values={attachment}
                onChange={v => setAttachment(v)}
                onInit={f => (attachmentRef.current = f)}
            />
            <Title level={2} text="财务信息" />
            <Financial
                readOnly={isPreview}
                status={isPreview ? 5 : -1}
                history={history}
                onRefreshClick={() => setNeedUpdate(1)}
                applyId={id}
                type={2}
            />
            <br />
            {!isPreview && <Footer save={handleSave} submit={handleSubmit} />}
        </div>
    );
}
