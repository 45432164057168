import React from 'react';
import { isEmpty } from 'utils/utils';
export default class extends React.Component {
    render() {
        return this.props.children({
            ...this.props,
            ...this.state
        });
    }
}
