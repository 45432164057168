/**
 * @author Ray
 * @desc 基本申请材料
 */
import React, { useEffect, useContext } from 'react';
import { Form, Input } from 'antd';
import ImageUpload from '../../../../common/uploadImage';
import { GlobalContext } from "../../../../common/globalStore/createProvider";
import styles from './styles.scss';

function BasicMaterialForm(props) {
    const { form, data = {} } = props;
    const gs = useContext(GlobalContext);
    const prefix = gs.isPub
        ? 'http://file.beijingipo.com.cn/'
        : 'http://192.168.88.106/'


    useEffect(
        function() {
            delete data.id;

            form.setFieldsValue({ ...data });
        },
        [data]
    );

    return (
        data && data.plate === '新三板' || data.plate === '新三板精选层' ? 
            <Form
                className={styles.formRoot}
                labelCol={{ xs: { span: 8 } }}
                wrapperCol={{ xs: { span: 16 } }}
            >
                <Form.Item 
                    label="证监会股票发行批复"
                >
                    {form.getFieldDecorator('sharesIssueCsrcUrl', {
                        rules: [
                            {
                                required: true,
                                message: '请上传文件！'
                            }
                        ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                <Form.Item label="附件备注">
                    {form.getFieldDecorator('decription', {})(
                        <Input.TextArea
                            readOnly
                            maxLength={500}
                            style={{ width: 400 }}
                            autosize={{ minRows: 4, maxRows: 6 }}
                        />
                    )}
                </Form.Item>
            </Form> 
                :
            <Form
                className={styles.formRoot}
                labelCol={{ xs: { span: 8 } }}
                wrapperCol={{ xs: { span: 16 } }}
            >
                <Form.Item label="证监局关于辅导项目的意见函">
                    {form.getFieldDecorator('coachSummaryUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                <Form.Item label="证监会股票发行注册批复">
                    {form.getFieldDecorator('issueCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                <Form.Item label="证监会核准批复">
                    {form.getFieldDecorator('approvedCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                <Form.Item label="证监会受理函">
                    {form.getFieldDecorator('acceptanceCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                <Form.Item label="交易所关于股票上市交易的通知/关于股票上市的函">
                    {form.getFieldDecorator('ipoUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                 <Form.Item label="五年不外迁承诺（境外注册企业境内上市）">
                    {form.getFieldDecorator('guaranteeUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item>
                {/*<Form.Item label="北京证监会无异议函">
                    {form.getFieldDecorator('agreeCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: '请上传文件！'
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} disabled />)}
                </Form.Item> */}
                <Form.Item label="附件备注">
                    {form.getFieldDecorator('decription', {})(
                        <Input.TextArea
                            readOnly
                            maxLength={500}
                            style={{ width: 400 }}
                            autosize={{ minRows: 4, maxRows: 6 }}
                        />
                    )}
                </Form.Item>
            </Form>

    );
}

export default Form.create({ name: 'documentaryProofForm' })(BasicMaterialForm);
