/**
 * @author lei wen peng
 * @description a hooks for localStorage
 */

import { useState, useEffect } from 'react';

export default function useStorage(key, initialValue = null, raw) {
    const [state, setState] = useState(function() {
        try {
            const sessionValue = localStorage.getItem(key);
            if (typeof localStorage === 'undefined') {
                throw new Error('localStorage is not support by this browser');
            }

            if (typeof sessionValue !== 'string') {
                localStorage.setItem(
                    key,
                    raw ? String(initialValue) : JSON.stringify(initialValue)
                );
                return initialValue;
            } else {
                return raw ? sessionValue : JSON.parse(sessionValue);
            }
        } catch (error) {
            return initialValue;
        }
    });

    useEffect(
        function() {
            try {
                const serializedValue = raw
                    ? String(state)
                    : JSON.stringify(state);
                localStorage.setItem(key, serializedValue);
            } catch (error) {}
        },
        [state]
    );

    return [state, setState];
}
