/**
 * @author Ray
 * @desc 重点培育公司分析columns
 */
import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import {
    transferStringNull,
    moneyFormatter,
    limitString
} from '../../../utils/utils';
// 估值、科创评分、风险评分只对VIP客户显示
export const columns = [
    {
        key: 'companyName',
        dataIndex: 'companyName',
        title: '公司名称',
        align: 'center',
        render: (text, record) => (
            <Link
                to={`/investment/enterpriseAnalysis/${text}/${record.bbdQyxxId}/${record.creditCode}`}
            >
                {text}
            </Link>
        ),
        width: '20%'
    },
    {
        key: 'listStatus',
        dataIndex: 'listStatus',
        title: '企业类型',
        align: 'center',
        width: '10%',
        render: text =>
            text ? (
                text.length > 13 ? (
                    <Tooltip title={text}>{limitString(12, text)}</Tooltip>
                ) : (
                    text
                )
            ) : (
                '--'
            )
    },
    {
        key: 'stockCode',
        dataIndex: 'stockCode',
        title: '上市板块',
        align: 'center',
        width: '10%',
    },
    {
        key: 'regcapAmount',
        dataIndex: 'regcapAmount',
        title: '注册资本',
        align: 'center',
        width: '10%',
        render: text => (text ? moneyFormatter(text) + '万' : '--')
    },
    {
        dataIndex: 'esDate',
        title: '注册时间',
        align: 'center',
        width: '10%'
        // render: text =>
        //     text ? moment(text).format(TimeFormat) : transferStringNull(text)
    },
    // {
    //     key: 'valuation',
    //     dataIndex: 'valuation',
    //     title: '估值',
    //     align: 'center',
    //     width: '10%',
    //     render: text => {
    //         if (text === null) return '--';
    //         // if (
    //         //     String(text).length > 5 &&
    //         //     String(text).split('.')[0].length > 4
    //         // ) {
    //         return `${moneyFormatter((text / 10000).toFixed(2))}`;
    //         // } else {
    //         //     return `${moneyFormatter(text)}万`;
    //         // }
    //     }
    // },
    {
        key: 'kechuangScore',
        dataIndex: 'kechuangScore',
        title: '科创评分',
        align: 'center',
        width: '10%',
        render: text => {
            let newText = text && text.toFixed(2);
            newText = transferStringNull(newText);
            return newText;
        }
    },
    {
        key: 'fengxianScore',
        dataIndex: 'fengxianScore',
        title: '风险评分',
        align: 'center',
        width: '10%',
        render: text => {
            let newText = text && text.toFixed(2);
            newText = transferStringNull(newText);
            return newText;
        }
    },
    {
        key: 'companyIndustry',
        dataIndex: 'companyIndustry',
        title: '行业',
        align: 'center',
        width: '10%',
        render: text =>
            text ? (
                text.length > 13 ? (
                    <Tooltip title={text}>{limitString(10, text)}</Tooltip>
                ) : (
                    text
                )
            ) : (
                '--'
            )
    },
    {
        key: 'companyCountry',
        dataIndex: 'companyCountry',
        title: '所属地区',
        align: 'center',
        width: '10%',
        render: transferStringNull
    }
];
