/**
 * @author Ray
 * @desc steps组件
 */

import React from 'react';
import { Steps as AntSteps } from 'antd';
import styles from './styles.scss';
const { Step } = AntSteps;

Steps.defaultProps = {
    step: 0
};

export default function Steps({ step }) {
    return (
        <div className={styles.steps}>
            <br />
            <AntSteps current={step}>
                <Step title="第一步" description="上市需求填写" />
                <Step title="第二步" description="经营数据填报" />
            </AntSteps>
        </div>
    );
}
