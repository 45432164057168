import React, { useState } from 'react';
import styles from '../pledge/styles.scss';
import classnames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { Table, Empty } from 'antd'
import Hook from './hook';
import { judgePaperAPI, courtAnnounceAPI, publicNoticeAPI, abnormalOperationAPI } from '../service';
import { litigationCol, courtAnnouncementColumns, announcementSessionColumns, businessAnomaliesColumns } from './columns';
import { isNotEmpty } from '../../../../utils/utils';
import TableEx from '../../../../common/tableEx';

export default function(props) {

    const {  gaugeOptions, loading, tableData, barOption } = Hook(props);

    function parseData(res) {
        if (isNotEmpty(res) && isNotEmpty(res.data)) {
            return res.data;
        }
        return [];
    }

    const [query] = useState({ qyxxId: props.qyxxId });
    
    return (
        <div className={styles.pledge}>
            <div className = {classnames("box", styles.riskNum)}>
                <div className = "fl">
                    <h4 className = "titleBorder">司法风险分析</h4>
                    {
                        loading ? '' :
                        gaugeOptions ?
                        <ReactEcharts
                            style = {{height: "380px"}}
                            option={gaugeOptions}
                            notMerge={true}
                            theme={'myTheme'}
                        /> : <Empty />
                    }
                </div>
                <span data = "/ / /" className = "splitLine"></span>
                <div className = "fr">
                    {
                        loading ? '' :
                        <ReactEcharts
                            style = {{height: "380px"}}
                            option={barOption}
                            notMerge={true}
                            theme={'myTheme'}
                        />
                    }
                </div>
            </div>
            {
                tableData.map( (ele, index) => {
                    const { name, columns, data } = ele;
                    if (index == 2) {
                        return  <div className = "box">
                                    <h4 className = "titleBorder">裁判文书</h4>
                                    <TableEx
                                        query={query}
                                        columns={litigationCol}
                                        getData={judgePaperAPI}
                                        parseData={parseData}
                                        locale={{emptyText: '未发现企业的裁判文书记录。不排除存在未公示或时间相对滞后的情况。'}}
                                    />
                                </div>;
                    } 
                    
                    else if (index == 3) {
                        return  <div className = "box">
                                    <h4 className = "titleBorder">经营异常</h4>
                                    <TableEx
                                        query={query}
                                        columns={businessAnomaliesColumns}
                                        getData={abnormalOperationAPI}
                                        parseData={parseData}
                                        locale={{emptyText: '未发现企业的经营异常记录。不排除存在未公示或时间相对滞后的情况。'}}
                                    />
                                </div>;
                    }

                    else if (index == 4) {
                        return  <div className = "box">
                                    <h4 className = "titleBorder">开庭公告</h4>
                                    <TableEx
                                        query={query}
                                        columns={announcementSessionColumns}
                                        getData={publicNoticeAPI}
                                        parseData={parseData}
                                        locale={{emptyText: '未发现企业的开庭公告记录。不排除存在未公示或时间相对滞后的情况。'}}
                                    />
                                </div>; 
                    }

                    else if (index === 5) {
                        return  <div className = "box">
                                    <h4 className = "titleBorder">法院公告</h4>
                                    <TableEx
                                        query={query}
                                        columns={courtAnnouncementColumns}
                                        getData={courtAnnounceAPI}
                                        parseData={parseData}
                                        locale={{emptyText: '未发现企业的法院公告记录。不排除存在未公示或时间相对滞后的情况。'}}
                                    />
                                </div>;
                    }

                    else {
                        return  <div className = "box" key = {name}>
                                    <h4 className = "titleBorder">{name}</h4>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        scroll={{ y: 300 }}
                                        pagination = {{
                                            hideOnSinglePage: true
                                        }}
                                        locale={{emptyText:`未发现企业的${name}记录。不排除存在未公示或时间相对后滞的情况`}}
                                    />
                                </div>;
                    }
                })
            }
        </div>
    );
}
