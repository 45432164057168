'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.cleanDots = cleanDots;
exports.getHightLightCompany = getHightLightCompany;

var _grid = require('../../../util/structure/grid');

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 数据过滤，因为扩展的是2度的点，扩展出来之后，去掉children 没有 childs 的点
 * @param {string} cid 中心点ID
*/
function cleanDots(data, cid) {

    var gData = (0, _grid.stateToGrid)(_lodash2.default.cloneDeep(data), cid);

    var nodes = gData.nodes,
        links = gData.links;


    return { nodes: nodes, links: links };

    // let cNode = gData.nodesKV[cid];
    // let delNodeIds = []; // 要删除的点
    // let { nodes, links } = gData;
    // cNode.childs.forEach(d => {
    //     if (d.childs.length === 1) {
    //         delNodeIds.push(d.id);
    //     }
    // });
    // let nNodes = []; // 不删除的点
    // let gNodes = [];
    // nodes.forEach(d => {
    //     if (delNodeIds.indexOf(d.id) === -1) {
    //         // count - 删除的childs 个数
    //         let num = 0;
    //         d.childs.forEach( cd => {
    //             if(delNodeIds.indexOf(cd.id) !== -1) {
    //                 num++;
    //             }
    //         });
    //         num--;
    //         d.count -= num;
    //         nNodes.push(d);
    //     }
    // });
    // let linkIds = getLinksByNodes2(nNodes, gData); // 不删除的线
    // let nLinks = [];
    // links.forEach(d => {
    //     if (linkIds.indexOf(d.id) !== -1) {
    //         nLinks.push(d);
    //     }
    // });
    // return { nodes: nNodes, links: nLinks };
}

/**
 * @desc 获取高亮点
*/
function getHightLightCompany(id, gData) {
    var nodesKV = gData.nodesKV,
        linksGroup = gData.linksGroup;

    var nodes = [];
    var links = [];

    nodesKV[id].childs.forEach(function (d) {
        var line1 = linksGroup[id + '-' + d.id];
        var line2 = linksGroup[d.id + '-' + id];
        nodes.push(d);
        if (line1) {
            line1.forEach(function (d) {
                links.push(d);
            });
        }
        if (line2) {
            line2.forEach(function (d) {
                links.push(d);
            });
        }
    });

    nodes.forEach(function (node) {
        node.childs.forEach(function (d) {
            var line1 = linksGroup[node.id + '-' + d.id];
            var line2 = linksGroup[d.id + '-' + node.id];
            nodes.push(d);
            if (line1) {
                line1.forEach(function (d) {
                    links.push(d);
                });
            }
            if (line2) {
                line2.forEach(function (d) {
                    links.push(d);
                });
            }
        });
    });

    nodes = _lodash2.default.uniqBy(nodes, 'id');
    links = _lodash2.default.uniqBy(links, 'id');

    return { nodes: nodes, links: links };
}

/**
 * @desc 通过点找线条
*/
function getLinksByNodes2(nodes, gridData) {
    var linksGroup = gridData.linksGroup;

    var links = [];
    nodes.forEach(function (d) {
        nodes.forEach(function (cd) {
            var line1 = linksGroup[d.id + '-' + cd.id];
            var line2 = linksGroup[cd.id + '-' + d.id];
            if (line1) {
                line1.forEach(function (i) {
                    links.push(i.id);
                });
            }
            if (line2) {
                line2.forEach(function (i) {
                    links.push(i.id);
                });
            }
        });
    });
    return _lodash2.default.uniq(links);
}