/**
 * @author Ray
 */
import React from 'react';
import SideBar from '../../common/sidebar';
import styles from './styles.scss';
import { renderRoutes } from 'react-router-config';

export default function({
    updateUser,
    updatePassword,
    updatePhoneNumber,
    resignUser,
    updatePasswordThreeMonths,
    dispatch,
    ...rest
}) {
    return (
        <section className={styles.userCenterRoot}>
            <div className={styles.userSidebar}>
                <SideBar {...rest} />
            </div>
            <div className={styles.userContent}>
                {renderRoutes(rest.Routes, {
                    updateUser,
                    updatePassword,
                    updatePhoneNumber,
                    resignUser,
                    updatePasswordThreeMonths,
                    dispatch
                })}
            </div>
        </section>
    );
}
