import React from 'react';
import styles from './styles.scss';
import { Icon, Row, Col, Empty } from 'antd';
import BarChart from './barChart'
import Hook from './hook';

export default function(props) {

    const { year, match } = props;
    const qyxxId = match.params.companyId;
    const { data, loading, barData } = Hook(year, qyxxId);

    if(loading){
        return <div></div>;
    }
    if(!data){
        return <Empty />;
    }
    const { classification } = data;

    return (
        <div className={styles.industry}>
           {/* <div className = {styles.dateBox}>
               <label>报告期</label>
               <Select defaultValue="lucy" style={{ width: 120 }} onChange={handleChange}>
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" > Disabled</Option>
                    <Option value="Yiminghe">yiminghe</Option>
                </Select>
                <span className = "tips"><Icon type="exclamation-circle" theme="filled" />注： 在相同报告期下，可比公司为具有相同证监会二级行业分类的上市公司</span>
           </div> */}
           <p className = {styles.types}>二级行业分类：{classification}</p>
           <div className = {styles.barCharts}>
                <Row gutter={20}>
                    {
                        barData.map( ele => {
                            return <Col span={12} key = {ele.title}>
                                        <BarChart {...ele}/>
                                    </Col>
                        })
                    }
                </Row>
           </div>
        </div>
    );
}
