'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Handler = require('./Handler');

var _Handler2 = _interopRequireDefault(_Handler);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// 单例模式
var handler = new _Handler2.default(0);
exports.default = handler;
module.exports = exports['default'];