"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 动画处理对象
 */

var Animator = function () {
    function Animator() {
        (0, _classCallCheck3.default)(this, Animator);
    }

    (0, _createClass3.default)(Animator, [{
        key: "dispose",
        value: function dispose() {}
    }]);
    return Animator;
}();

exports.default = Animator;
module.exports = exports["default"];