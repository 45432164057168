/**
 * @author Ray
 * @desc 研发能力情况
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, InputNumber, Tooltip, Icon } from 'antd';
import { Selector } from '../../merging/home/apply';
import {
    ImportantResearch,
    StandardSetting,
    Awards
} from '../../../constants/enum';
import { SaveButton } from '../../../common/buttons';
import { GreaterThanZro, GreaterThanZroAndLessThanHundred } from '../../../utils/validator';
import { showModal, closeModal } from '../../investment/script/actions';

const FormEle = React.forwardRef(({ form, data }, ref) => {
    React.useImperativeHandle(ref, () => ({ form }));
    data = data || {};
    return (
        <Form
            labelCol={{ xs: { span: 24 }, sm: { span: 10 } }}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
        >
            {/*与主营业务相关的发明专利、软件著作权及新药批件数量占总数比*/}
            <Form.Item
                label="主营相关核心技术占比"
                extra="与主营业务相关的发明专利、软件著作权及新药批件数量占总数比"
            >
                {form.getFieldDecorator('totalPatentRatio', {
                    rules: [GreaterThanZroAndLessThanHundred],
                    initialValue: data.totalPatentRatio
                })(<InputNumber max={100} min={0} maxLength={6} formatter={function (value) {
                    return value + '%';
                }} />)}
            </Form.Item>
            <Form.Item label="重大科研项目参与情况">
                {form.getFieldDecorator('majorResearchCondition', {
                    rules: [],
                    initialValue: data.majorResearchCondition
                })(<Selector options={ImportantResearch} getPopupContainer={()=> document.getElementById('marketing')}  />)}
            </Form.Item>
            <Form.Item label="标准制定参与情况">
                {form.getFieldDecorator('standardDraftCondition', {
                    rules: [],
                    initialValue: data.standardDraftCondition
                })(<Selector options={StandardSetting} getPopupContainer={()=> document.getElementById('marketing')}  />)}
            </Form.Item>
            <Form.Item label="奖项获得情况">
                {form.getFieldDecorator('awardObtainCondition', {
                    rules: [],
                    initialValue: data.awardObtainCondition
                })(<Selector options={Awards} getPopupContainer={()=> document.getElementById('marketing')} />)}
            </Form.Item>
        </Form>
    );
});
export const FormElement = ({ formRef, data }) => {
    let [Element, setElement] = useState(null);

    useEffect(function () {
        let E = Form.create({
            name: 'core',
            onValuesChange: () => { }
        })(FormEle);
        setElement(<E wrappedComponentRef={formRef} data={data} />);
    }, [data]);

    return Element;
};
let currentKey = 2;
export default function Marketing(props) {
    const formRef = useRef(null);

    const save = () => {
        formRef.current.form.validateFields((errors, values) => {
            if (!errors) {
                props.saveFun(values);
            }
        });
    };
    useEffect(function () {
        if (props.activeKey != currentKey && currentKey == 2) {
            currentKey = props.activeKey;
            // 检验值是否修改过
            const currentValue = formRef.current.form.getFieldsValue();
            const hasChanged = Object.keys(currentValue).filter(function (value, index) {
                return currentValue[value] !== props.data[value];
            });
            if (hasChanged.length) {
                props.dispatch(
                    showModal({
                        title: '研发成果市场认可情况',
                        cancelText: '不保存！',
                        okText: '保存！',
                        onOk: () => {
                            props.dispatch(closeModal());
                            formRef.current.form.validateFields((errors, values) => {
                                props.saveFun(values, function () {
                                    hasChanged.forEach(value => {
                                        formRef.current.form.setFieldsValue({ [value]: props.data[value] });
                                        props.dispatch(closeModal());
                                    })
                                });
                            });
                        },
                        onClose: () => {
                            // 不保存恢复原样
                            hasChanged.forEach(value => {
                                formRef.current.form.setFieldsValue({ [value]: props.data[value] });
                                props.dispatch(closeModal());
                            })
                        }
                    })
                );
            }

        }
        if (props.activeKey == 2) {
            currentKey = 2;
        }
    }, [props.activeKey, props.data]);
    return (
        <div id="marketing">
            <FormElement formRef={formRef} data={props.data} />
            <div className="textCenter marginTop">
                <SaveButton onClick={save} />
            </div>
        </div>
    );
}
