import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 *
 * @param pageNum
 * @param pageSize
 */
export function getMessages({ pageNum, pageSize, read }) {
    return request(
        `${API.V1}/auth/sys/message/user/list`,
        {
            pageNum,
            pageSize,
            read
        },
        {},
        () => {},
        true
    );
}

/**
 *
 * @param msgIds
 */
export function changeStatus(msgIds) {
    return request(
        `${API.V1}/auth/sys/message/status/change`,
        [...msgIds],
        {
            method: 'post'
        },
        () => {},
        true
    );
}
