import React, { useContext } from 'react';
import style from './style.scss';
import Menus from './menus';
import User from './user';
import { isEmpty } from '../../utils/utils';
import { HOME } from '../router';
import { GlobalContext } from '../../common/globalStore/createProvider';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import LogoutSvg from './images/exit.svg';
import { logout } from './user/service';
/**
 * @desc 头部
 */

export default function(props) {
  
  const { user, isPasscodeExpired } = useContext(GlobalContext);
  function handleLogout() {
    logout().then(function() {
      props.history.push({
        pathname: '/user/login',
        state: { from: location.pathname }
      });
    });
  }
  return (
      props.location.pathname === '/user/login' ? <div></div> : 
        <div
        className={classnames(style.header, {
          [style.balackHeader]: props.balackHeader
        })}
      >
        <Link
          to={
            user?.needChangePwd
              ? '/userCenter/modifyPassword'
              : `${props.routes[1]?.redirect || props.routes[1]?.path || HOME}`
          }
        >
          <div className={style.titleContent}>
            {/*<img src={headerLogo} style={{}} alt="logo" />*/}
          </div>
        </Link>
        {!isEmpty(user) && <Menus {...props} />}
        {!isEmpty(user) && <User {...props} />}
        {isPasscodeExpired && (
          <>
            <div className={style.flex} />
            <Icon
              component={LogoutSvg}
              onClick={handleLogout}
              className={style.logout}
            />
          </>
        )}
      </div>
  );
}
