import React, { useState, useEffect } from 'react';
import tree1 from './tree1.png';
import tree2 from './tree2.png';
import tree3 from './tree3.png';
import numBg from './nums.png';
import { authRedOverview } from '../service';
// import styles from './styles.scss';
export default function Tree({ area, style }) {
  let [data, setData] = useState({});
  const textColor = '#fff';
  // [x, y, r]
  const r = 22,
    R = 33;
  const circleBg = {
    Gradient1: tree1,
    Gradient2: tree2,
    Gradient3: tree3
  };
  const circlePosition = [
    [45, 175, 'acceptanced', 'Gradient1'], //0
    [170, 175, 'enquiry', 'Gradient1'], //1
    [370, 175, 'committee', 'Gradient1'], // 2
    [530, 175, 'submit', 'Gradient1'], //3
    [680, 175, 'effect', 'Gradient1'], //4

    [240, 60, 'interrupt', 'Gradient2'], //5
    [400, 60, 'reprieve', 'Gradient2'], //6
    [530, 60, 'supplement', 'Gradient2'], //7

    [250, 275, 'examination', 'Gradient3'], //8
    [450, 275, 'uncommittee', 'Gradient3'], //9
    [600, 275, 'unregister', 'Gradient3'], //10
    [680, 275, 'registerEnd', 'Gradient3'] //11
  ];
  const text = {
    acceptanced: '已受理',
    committee: '上市委会议通过',
    effect: '注册生效',
    enquiry: '已问询',
    examination: '终止审查',
    interrupt: '中止审查',
    registerEnd: '终止注册',
    reprieve: '暂缓审议',
    supplement: '补充审核',
    total: '申报企业数',
    uncommittee: '上市委会议不通过',
    unregister: '不予注册',
    submit: '提交注册'
  };
  const lines = [
    [
      circlePosition[0][0] + R,
      circlePosition[0][1],
      circlePosition[1][0] - R,
      circlePosition[1][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[5][0] - R,
      circlePosition[5][1] + R
    ],

    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[2][0] - R,
      circlePosition[2][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[8][0] - (R * 2) / 3,
      circlePosition[8][1] - R
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[6][0] - R,
      circlePosition[6][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[9][0] - R,
      circlePosition[9][1]
    ],

    [
      circlePosition[2][0] + R,
      circlePosition[2][1],
      circlePosition[7][0] - R,
      circlePosition[7][1]
    ],
    [
      circlePosition[2][0] + R,
      circlePosition[2][1],
      circlePosition[3][0] - R,
      circlePosition[3][1]
    ],

    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[4][0] - R,
      circlePosition[4][1]
    ],
    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[10][0] - (R * 2) / 3,
      circlePosition[10][1] - R
    ],
    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[11][0] - R,
      circlePosition[11][1] - R
    ]
  ];
  useEffect(() => {
    authRedOverview(area).then(res => {
      if (res.statusCode == 1000) {
        const result = res.data;
        setData(result);
      }
    });

    document.addEventListener('resize', () => {
      debugger;
    });
  }, [area]);
  return (
    <div
      style={{
        width: 770,
        margin: 'auto',
        position: 'relative',
        textAlign: 'center',
        ...style
      }}
    >
      <div style={{ position: 'absolute', top: 0, width: 200, height: 100 }}>
        <img
          src={numBg}
          style={{
            width: 200,
            height: 95,
            position: 'absolute',
            zIndex: 0,
            left: 0,
            top: -19
          }}
        />
        <div style={{ position: 'relative', top: -4 }}>申报企业数 </div>
        <div style={{ fontSize: 36, color: '#00DBFF' }}>{data.total}</div>
      </div>
      <svg height={style.height} width={style.width}>
        <defs>
          <linearGradient id="Gradient1">
            <stop stopColor="#37D1FE" offset="0%" />
            <stop stopColor="#0B8ED9" offset="100%" />
          </linearGradient>
          <linearGradient id="Gradient2">
            <stop stopColor="#FAC596" offset="0%" />
            <stop stopColor="#F87E12" offset="100%" />
          </linearGradient>
          <linearGradient id="Gradient3">
            <stop stopColor="#FFA4A4" offset="0%" />
            <stop stopColor="#FF3D3D" offset="100%" />
          </linearGradient>
        </defs>
        {circlePosition.map((value, index) => {
          return (
            <g key={'g' + index}>
              <circle
                cx={value[0]}
                cy={value[1]}
                r={r}
                fill={'url(#' + value[3] + ')'}
              />
              <image
                xlinkHref={circleBg[value[3]]}
                x={value[0] - 37.5}
                y={value[1] - 37.5}
              />
              <text
                x={value[0]}
                y={value[1]}
                fill={textColor}
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {data[value[2]]}
              </text>
              <text
                x={value[0]}
                y={value[1] + 45}
                fill={textColor}
                fontWeight="bold"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {text[value[2]]}
              </text>
            </g>
          );
        })}
        {lines.map((value, index) => {
          return (
            <line
              key={'line' + index}
              x1={value[0]}
              y1={value[1]}
              x2={value[2]}
              y2={value[3]}
              style={{
                stroke: '#0B8ED9',
                strokeWidth: 2,
                strokeDasharray: '5,5'
              }}
            />
          );
        })}
        y
      </svg>
    </div>
  );
}
