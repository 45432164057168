import React from 'react';
import { Row } from 'antd';
import styles from './styles.scss';
import {
    FinancialButton,
    FinancialHammerButton
} from '../../../common/buttons';

export default function Buttons({ history }) {
    return (
        <Row className={styles.buttons}>
            <FinancialHammerButton
                onClick={() => {
                    history.push({
                        pathname: '/merging/acquisition'
                    });
                }}
            />
            <FinancialButton
                onClick={() => {
                    history.push({
                        pathname: '/merging/transfer'
                    });
                }}
            />
        </Row>
    );
}
