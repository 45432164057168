import React from 'react';
import LeftForth from './BrokerIPOPassHistogramLine';

/**
 * @desc 全A驾驶舱左边第四个
 * @returns {*}
 * @constructor
 */

export default function DiamondNum({ address, style }) {
  return (
    <LeftForth address={address} style={style} />
  );
}
