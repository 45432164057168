import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
/**
 * @desc 柱状图
 */
export default class BarStack extends PureComponent {
    getOption() {
        const {
            dataAxis = [],
            title,
            options
        } = this.props;
        return {
            title,
            // tooltip: {
            //     show: true,
            //     trigger: 'axis',
            //     axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            //         type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow',
            //     },
            // },
            grid: {
                right: '6%',
                bottom: '',
                top:15
            },
            series: [],
            xAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    splitLabel: false,
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                }
            ],
            yAxis: {
                data: dataAxis,
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    fontSize: '12',
                    formatter: '{value}年'
                }
            },
            ...options,
        };
    }
    render() {
        const { style = { height: '200px' }, nodata } = this.props;
        return nodata ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
            <ReactEcharts
                option={this.getOption()}
                notMerge={true}
                style={style}
                theme={''}
            />
        );
    }
}
