/**
 * @desc column configuration
 */
import moment from 'moment';
import { TimeFormat } from '../../../constants/enum';
import { limitString, moneyFormatter } from '../../../utils/utils';
import { Tooltip } from 'antd';
import React from 'react';

export default [
    {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        render: text => {
            return text ? moment(text, 'x').format(TimeFormat) : '--';
        },
        width: '15%'
    },
    {
        title: '融资轮次',
        dataIndex: 'financingRotation',
        key: 'financingRotation',
        align: 'center',
        render: text => {
            return text ? `${text}` : '--';
        },
        width: '15%'
    },
    {
        title: '估值',
        dataIndex: 'valuation',
        key: 'valuation',
        align: 'center',
        render: text => {
            return text ? `${moneyFormatter(text)} 亿元` : '--';
        },
        width: '15%'
    },
    {
        title: '融资金额',
        dataIndex: 'financingMoney',
        key: 'financingMoney',
        align: 'center',
        render: text => {
            return text ? `${moneyFormatter(text)} 万元` : '--';
        },
        width: '15%'
    },
    {
        title: '投资方',
        dataIndex: 'investors',
        key: 'investors',
        align: 'center',
        width: '15%',
        render: text => {
            return <Tooltip title={text}>{limitString(15, text)}</Tooltip>;
        }
    }
];
