import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import { Empty } from 'antd'
import {listedCompanyOfIndustry} from './service'
import styles from './styles.scss';

global.industryRiskBar = null;

export default function IndustryDistribution() {
    useEffect(()=>{
        listedCompanyOfIndustry().then(({ success, data })=>{
            if (success === true && global.industryRiskBar === null){
                function cmp(a,b){
                    return b['proportion']-a['proportion'];
                }
                data=data.sort(cmp).slice(0,10);//取最大的前10个
                let sum=0;
                for(let d of data) sum+=d.companyCount;
                let ndata=data.map(e=>{
                    const {companyCount,industry}=e;
                    return{
                        item:industry,
                        percent:parseFloat((companyCount/sum*100).toFixed(2))
                    }
                });
                //console.log(ndata);

                global.industryRiskBar=new Chart({
                    container: 'IndustryDistribution',
                    height: 300,
                    autoFit:true,
                    width:450,
                });
                global.industryRiskBar.data(ndata);
                global.industryRiskBar.coordinate('theta', {
                    radius: 0.75,
                });

                global.industryRiskBar.scale('percent', {
                    formatter: (val) => {
                        val = val + '%';
                        return val;
                    },
                });
                global.industryRiskBar.legend({position:'right',offsetX: -50,flipPage:false});
                global.industryRiskBar.tooltip({
                    showTitle: false,
                    showMarkers: false,
                });

                global.industryRiskBar
                    .interval()
                    .adjust('stack')
                    .position('percent')
                    .color('item')
                    .label('percent', (percent) => {
                        const offset = percent > 4 ? -10 : 13;
                        return {
                            offset,
                            content: (data) => {
                                return `${percent}%`;
                            },
                        };
                    });
                global.industryRiskBar.render();

            }
            else {
                <Empty />
            }
        })
    },[]);
    return(
        <div id='IndustryDistribution' className={ styles.IndustryRisk }></div>
    )
}
