import moment from 'moment';
import { TimeFormat } from '../../../../../constants/enum';
import React from 'react';
import {
  highlight,
  limitString,
  moneyFormatter,
  transferStringNull
} from '../../../../../utils/utils';
import { Tooltip } from 'antd';

export const financingProcessColumns = [
  {
    dataIndex: 'investmentName',
    title: '投资方名称',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  {
    dataIndex: 'financingAmount',
    title: '融资金额',
    align: 'center',
    width: '10%',
    render: transferStringNull
  },
  {
    dataIndex: 'financingTimes',
    title: '融资轮次',
    align: 'center',
    width: '10%',
    render: transferStringNull
  },
  {
    dataIndex: 'updateDay',
    title: '日期',
    align: 'center',
    width: '10%'
  }
  //   {
  //     dataIndex: 'financingValuation',
  //     title: '估值',
  //     align: 'center',
  //     width: '15%',
  //     render: transferStringNull
  //   },
  //   {
  //     dataIndex: 'investmentEvents',
  //     title: '投资事件',
  //     align: 'center',
  //     width: '25%',
  //     render: transferStringNull
  //   }
];

export const equityHoldingsColumns = [
  {
    dataIndex: 'entname',
    title: '投资企业名称',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'entRegno',
    title: '注册号',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'regcap',
    title: '注册资本(万元)',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'entstatus',
    title: '企业状态',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'subconam',
    title: '认缴出资额(万元)',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'conform',
    title: '出资方式',
    align: 'center',
    width: '15%',
    render: transferStringNull
  },
  {
    dataIndex: 'fundedratio',
    title: '出资比例',
    align: 'center',
    width: '10%',
    render: transferStringNull
  }
];

export const shareholdersColumns = [
  {
    dataIndex: 'shareholderName',
    title: '股东名称',
    align: 'center',
    width: '25%',
    render: transferStringNull
  },
  {
    dataIndex: 'investRatio',
    title: '股权比例',
    align: 'center',
    width: '20%',
    render: text => (text === 'null' || text === '' ? '未公示' : text)
  },
  {
    dataIndex: 'subAmount',
    title: '出资额',
    align: 'center',
    width: '20%',
    // render: text =>
    //   text === 'null' || text === ''
    //     ? '未公示'
    //     : moneyFormatter((parseFloat(text) / 10000).toFixed(2)) + '万'
    render: (text, record) => {
      console.log(JSON.parse(record.subDetail), '34')
      const subAmountValue = record.subDetail && record.subDetail.length > 0 ? JSON.parse(record.subDetail).map(item => item.amomon && item.amomon.split(',')) : null;
      return (
        subAmountValue
      )
    }
  },
  {
    dataIndex: 'investAmount',
    title: '持有股数',
    align: 'center',
    width: '20%',
    render: text => (text === 'null' || text === '' ? '未公示' : text)
  },
  {
    dataIndex: 'investName',
    title: '出资方式',
    align: 'center',
    width: '25%',
    // render: text => (text === 'null' || text === '' ? '未公示' : text)
    render: (text, record) => {
      const investNameValue = record.subDetail && record.subDetail.length > 0 ? JSON.parse(record.subDetail).map(item => item.paymet && item.paymet.split(',')) : null;
      return (
        investNameValue
      )
    }
  }
];

export const businessChangeColumns = [
  {
    title: '变更事项',
    align: 'center',
    width: '15%',
    dataIndex: 'changeItems',
    render: transferStringNull
  },
  {
    title: '变更前内容',
    align: 'center',
    width: '35%',
    dataIndex: 'contentBeforeChange',
    render: (text, record) => {
      let isTooLong = record.contentBeforeChange.length > 120;
      let originText = isTooLong
        ? limitString(120, record.contentBeforeChange)
        : record.contentBeforeChange;
      let newText = highlight(originText, record.contentAfterChange);
      newText = transferStringNull(newText);
      return isTooLong ? (
        <Tooltip
          overlayStyle={{ minWidth: 650 }}
          title={record.contentBeforeChange}
        >
          <p
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{ __html: newText }}
          />
        </Tooltip>
      ) : (
        <p
          style={{ wordBreak: 'break-all' }}
          dangerouslySetInnerHTML={{ __html: newText }}
        />
      );
    }
  },
  {
    title: '变更后内容',
    align: 'center',
    width: '35%',
    dataIndex: 'contentAfterChange',
    render: (text, record) => {
      let isTooLong = record.contentAfterChange.length > 120;
      let originText = isTooLong
        ? limitString(120, record.contentAfterChange)
        : record.contentAfterChange;
      let newText = highlight(originText, record.contentBeforeChange);
      newText = transferStringNull(newText);
      return isTooLong ? (
        <Tooltip
          overlayStyle={{ minWidth: 650 }}
          title={record.contentAfterChange}
        >
          <p
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{ __html: newText }}
          />
        </Tooltip>
      ) : (
        <p
          style={{ wordBreak: 'break-all' }}
          dangerouslySetInnerHTML={{ __html: newText }}
        />
      );
    }
  },
  {
    title: '变更日期',
    align: 'center',
    width: '15%',
    dataIndex: 'changeDate',
    render: transferStringNull
  }
];

export const winningInformationColumns = [
  {
    dataIndex: 'title',
    title: '标题',
    align: 'center',
    width: '25%',
    render: transferStringNull
  },
  {
    dataIndex: 'bbdUrl',
    title: '正文',
    align: 'center',
    width: '10%',
    render: text => (
      <a href={text} target="_blank">
        查看原文
      </a>
    )
  },
  {
    dataIndex: 'companyNameInvite',
    title: '招标单位',
    align: 'center',
    width: '20%',
    render: transferStringNull
  },
  // {
  //   dataIndex: 'dataSource',
  //   title: '来源',
  //   align: 'center',
  //   width: '20%',
  //   render: transferStringNull
  // },
  {
    dataIndex: 'pubdate',
    title: '发布时间',
    align: 'center',
    width: '15%',
    render: text => {
      let _text = transferStringNull(text);
      if (_text === '--') return _text;
      return moment(text).format(TimeFormat);
    }
  }
];
