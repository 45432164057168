/**
 * @author Ray
 * @description 融资情情况表单
 */
import { DatePicker, Form, Input, InputNumber } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import ErrorTip from '../../../../common/errorTip';

export default Form.create({
  name: 'financinfForm'
})(
  React.forwardRef(function FinicingForm({ data, form, onSubmit }, ref) {
    const { getFieldDecorator } = form;

    React.useImperativeHandle(ref, () => ({ form }));

    return (
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 8 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      >
        <Form.Item label="日期">
          {getFieldDecorator('date', {
            rules: [
              {
                required: true,
                message: <ErrorTip text="请选择日期" />
              }
            ]
          })(
            <DatePicker
              disabledDate={current =>
                current && current > moment().endOf('day')
              }
            />
          )}
        </Form.Item>
        <Form.Item label="融资轮次">
          {getFieldDecorator('financingRotation', {
            rules: [
              {
                required: true,
                message: <ErrorTip text="请填写轮次！" />
              }
            ]
          })(<Input maxLength={10} />)}
        </Form.Item>
        <Form.Item label="估值">
          {getFieldDecorator('valuation', {
            rules: []
          })(
            <InputNumber
              precision={2}
              maxLength={10}
              formatter={function(value) {
                return value && value + '亿元';
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="融资金额">
          {getFieldDecorator('financingMoney', {
            rules: [
              {
                required: true,
                message: <ErrorTip text="请填写融资金额！" />
              }
            ]
          })(
            <InputNumber
              precision={2}
              maxLength={10}
              formatter={function(value) {
                return value && value + '万元';
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="投资方">
          {getFieldDecorator('investors', {
            rules: [
              {
                required: true,
                message: <ErrorTip text="请填写投资方！" />
              }
            ]
          })(<Input maxLength={500} />)}
        </Form.Item>
      </Form>
    );
  })
);
