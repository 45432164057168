/**
 * @author Ray
 */
import request from 'utils/fetch';
import { API } from 'constants/enum';

/**
 * @description 获取上市奖励首页的数据
 */
export function getApplyIndexInfo() {
    return request(
        `${API.V1}/policypractice/user/apply/index`,
        {},
        {
            method: 'GET'
        }
    );
}

// 提交申请
// 撤回申请
// 删除申请

/**
 * @description 保存申请
 * @param {object} data
 */
export function preserveApply(data) {
    return request(
        `${API.V1}/policypractive/apply/preserve`,
        {
            ...data
        },
        {
            method: 'POST',
            'Content-Type': 'application/json'
        }
    );
}

/**
 * @description 提交申请
 * @param {object} data
 */
export function applySubmit(data) {
    return request(
        `${API.V1}/policypractive/apply/submit`,
        {
            ...data
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 流程节点展示
 * @param {number} id
 */
export function applyNode(id) {
    return request(
        `${API.V1}/policypractice/apply/nodeShow`,
        {
            id
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 编辑申请填报信息
 * @param {object} data
 */
export function applyEdit(data) {
    return request(
        `${API.V1}/policypractice/apply/edit`,
        {
            ...data
        },
        { method: 'POST' }
    );
}

/**
 * @description 首页信息展示
 * @param {string} code 社会信用代码
 */
export function applyIndex(code) {
    return request(
        `${API.V1}/policypractice/apply/indexShow`,
        { code },
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        }
    );
}

/**
 * @description 根据时间获取不同时间段的表数据
 * @param {string} code
 * @param {string} startYear
 * @param {string} endYear
 * @param {number} reportType 文件类型 2、利润表 3、资产债务表 4、现金流
 */
export function findFinance({ code, reportType }) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/findFinance`,
        {
            code,
            reportType
        },
        {
            method: 'POST'
        }
    );
}
