import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc 获取挂牌企业地域分布图数据
 */
//全部
export function listedEnterprises() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaAll`)
}
//基础层
export function listedEnterprisesBasic() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaBasic`)
}
//创新层
export function listedEnterprisesInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaInnovate`)
}
//精选层
export function listedEnterprisesSelected() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaSelected`)
}

//整合请求数据
export function getDataByType(type) {
    if(type==1) return listedEnterprises();
    if(type==2) return listedEnterprisesBasic();
    if(type==3) return listedEnterprisesInnovate();
    if(type==4) return listedEnterprisesSelected();
}

/**
 * @desc 获取地图数据
 * @param mapName
 */
export default function getLocalMap(mapName) {
    return request(
        `/data/map/${encodeURIComponent(mapName)}.json`, true
    );
}

/**
 * @desc  交易情况概览
 */
//三板做市
export function marketMakingIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/marketMakingIndex`)
}
//三板成指
export function componentIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/componentIndex`)
}
//创新指数
export function innovateIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/innovateIndex`)
}
