/**
 * @desc 智能中介匹配
 * @author Ray
 */

import React from 'react';
import { sponsorColumns } from './columns';
import { isNotEmpty } from '../../../../utils/utils';
import { Empty, Table } from 'antd';

export default function LawFirm({ dataSource }) {
    return isNotEmpty(dataSource.layerList) ? (
        <Table
            columns={sponsorColumns}
            dataSource={dataSource.layerList}
            pagination={false}
        />
    ) : (
        <Empty description="该企业未填报自身信息，暂无推荐信息" />
    );
}
