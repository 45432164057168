import styles from './styles.scss';
import React, { useEffect, useState } from 'react';
import { authDiamondNewFive } from '../service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
/**
 * @desc 当月上市企业
 * @returns {*}
 * @constructor
 */

export default function DiamondNumThead({ area, style }) {
  const [tableData, setData] = useState({ loading: true, data: [] });
  const swipeOptions = {
    auto: 0,
    speed: 2000,
    minSpeed: 20,
    height: 200,
    transitionEnd() {
      console.log('ended transition');
    }
  };
  useEffect(() => {
    authDiamondNewFive(area).then(res => {
      if (res.statusCode == 1000) {
        setData({ loading: false, data: res.data });
      } else {
        message.error(res.message);
      }
    });
  }, [area]);

  const { loading, data } = tableData;
  return (
    <div className={styles.diamondNum} style={{ ...style }}>
      <div className={styles.table}>
        {loading ? (
          ''
        ) : data && data.length ? (
          <table>
            <thead>
              <tr>
                <td>企业简称</td>
                <td>地区</td>
                <td>行业</td>
                <td>融资金额(亿)</td>
              </tr>
            </thead>
            <tbody>
              {data.map((ele, index) => {
                return (
                  <tr key={ele.shortName + index}>
                    <td width="25%">
                      {/* <Link to={`/riskMonitoring/detail/risk/${name}/${name}`}> */}
                      {ele.shortName}
                      {/* </Link> */}
                    </td>
                    <td width="15%">{ele.area}</td>
                    <td width="40%">{ele.industry}</td>
                    <td width="20%">{parseFloat(ele.amount).toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
}
