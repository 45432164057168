import React from 'react';
import { Tooltip } from 'antd';
import { HANDLE_STATUS, SUBMIT_STATUS } from '../../../constants/enum';
import { isEmpty, isNotEmpty, LimitNumber } from '../../../utils/utils';

export const columns = [
    {
        title: '企业名称',
        dataIndex: 'name',
        align: 'center',
        render: text => (isEmpty(text) ? '--' : text)
    },
    {
        title: '申请时间',
        dataIndex: 'gmtModify',
        align: 'center',
        render: text => (isEmpty(text) ? '--' : text)
    },
    {
        title: '申请上市板块',
        dataIndex: 'plate',
        align: 'center',
        render: text => (isEmpty(text) ? '--' : text)
    },
    {
        title: '提交状态',
        dataIndex: 'submitStatus',
        align: 'center',
        render: text => {
            let item = SUBMIT_STATUS.filter(d => d.value === text)[0];
            if (isNotEmpty(item)) {
                return item.text || '--';
            }
            return '--';
        }
    },
    {
        title: '受理状态',
        dataIndex: 'examineStatus',
        align: 'center',
        render: text => {
            let item = HANDLE_STATUS.filter(d => d.value === text)[0];
            if (isNotEmpty(item)) {
                return item.text || '--';
            } else {
                return '--';
            }
        }
    },
    {
        title: '不予受理原因',
        dataIndex: 'content',
        align: 'center',
        render: text =>
            isEmpty(text) ? (
                '--'
            ) : (
                <Tooltip title={text}>{LimitNumber(text, 20)}</Tooltip>
            )
    }
];

export default columns;
