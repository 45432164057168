/**
 * @desc 中介机构列表搜索
 * @author Ray
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import RadioGroup from '../../../common/radioGroup';
import { Form } from 'antd';
import { PLATES, IntermediaryAnalysis } from '../../../constants/enum';
import { GroupInput } from '../../merging/acquisition/intention';
import { getSscIndustry } from '../../imformation/basiInfo/service';
import Auto from '../../../common/autoComplete';
import GroupYearPicker from '../../../common/groupYearPicker';
import PlateSelection from '../../../common/plateSelection';

const { Item } = Form;

function SearchConditions({ form }, ref) {
    const { getFieldDecorator } = form;

    React.useImperativeHandle(ref, () => ({ form }));

    return (
        <Form
            className={styles.conditions}
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
        >
            {/*<Item label="中介类型">*/}
            {/*    {getFieldDecorator('zjjgType', {*/}
            {/*        rules: []*/}
            {/*    })(<RadioGroup showAll options={IntermediaryAnalysis} />)}*/}
            {/*</Item>*/}
            <Item label="注册资本(万元)">
                {getFieldDecorator('regcap', {
                    initialValue: []
                })(<GroupInput min={0} />)}
            </Item>
            <Item label="注册时间">
                {getFieldDecorator('subsist', {
                    initialValue: []
                })(<GroupYearPicker />)}
            </Item>
            <Item label="主要服务板块">
                {getFieldDecorator('goodBlock', {
                    rules: []
                })(
                    // <RadioGroup showAll options={PLATES} />
                    <PlateSelection width={400}/>
                )}
            </Item>
            <Item label="最近三年过会率（%）">
                {getFieldDecorator('rate', {
                    initialValue: []
                })(<GroupInput min={0} max={100} />)}
            </Item>
            <Item label="服务领域">
                {getFieldDecorator('goodIndustry', {
                    rules: []
                })(
                    <Auto
                        placeholder="请输入行业关键字"
                        paramKey="srcIndustry"
                        parser={data =>
                            data
                                ? data.map(d => ({
                                    value: d.industry,
                                    label: d.industry,
                                    key: d.typeCode
                                }))
                                : []
                        }
                        loadSource={getSscIndustry}
                    />
                )}
            </Item>
        </Form>
    );
}

export default function ({ elementRef }) {
    const [E, setE] = useState(null);

    useEffect(function () {
        const Form_ = Form.create({
            name: 'search'
        })(React.forwardRef(SearchConditions));

        setE(<Form_ wrappedComponentRef={elementRef} />);
    }, []);

    return E;
}
