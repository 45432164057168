/**
 * @desc 入库企业分析-基础信息-基础信息-基础信息表
 * @author Ray
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { getCompanyBaseInfo } from './service';
import { isNotEmpty, transferStringNull } from '../../../../../utils/utils';

export default function({ company }) {
    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    <td>企业名称</td>
                    <td>{company.companyName || '--'}</td>
                    <td>公司类型</td>
                    <td>{company.companyType || '--'}</td>
                </tr>
                <tr>
                    <td>统一社会信用码</td>
                    <td>{company.creditCode || '--'}</td>
                    <td>成立日期</td>
                    <td>{company.esdate || '--'}</td>
                </tr>
                <tr>
                    <td>所属地区</td>
                    <td>{company.companyProvince || '--'}</td>
                    <td>注册资本</td>
                    <td>{transferStringNull(company.regcap) || '--'}</td>
                </tr>
                <tr>
                    <td>实收资本</td>
                    <td>{transferStringNull(company.realcap) || '--'}</td>
                    <td>营业期限自</td>
                    <td>{company.openfrom || '--'}</td>
                </tr>
                <tr>
                    <td>公司网址</td>
                    <td>{company.webUrl || '--'}</td>
                    <td>营业期限至</td>
                    <td>{transferStringNull(company.opento) || '--'}</td>
                </tr>
                <tr>
                    <td>公司地址</td>
                    <td>{company.address || '--'}</td>
                    <td>法人</td>
                    <td>{company.frname || '--'}</td>
                </tr>
                <tr>
                    <td colSpan={1}>经营范围</td>
                    <td colSpan={3}>{company.operateScope || '--'}</td>
                </tr>
            </tbody>
        </table>
    );
}
