'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.wordLinefeed = wordLinefeed;
exports.getValidChild = getValidChild;
exports.getValidCount = getValidCount;

var _common = require('./common');

var _enum = require('../constant/enum');

/**
 * 将字符串根据最大值转换为换行数据
 * @param {string} str
 * @param {number} max 每行最大值
 * @return {string} 返回转换数据
 */
function wordLinefeed(str, max) {
    if ((0, _common.isBlank)(str) || (0, _common.isBlank)(max)) {
        return '';
    }
    var reg = new RegExp('(.{' + max + '})', 'g');

    return ('' + str).replace(/\n/g, '').replace(reg, '$1\n');
}

/**
 * @desc 获取节点有效的子节点
 * @param {object} gridData 网格数据
 * @param {object} node 节点
 * @return {array} 有效的childs数组
 */
function getValidChild(gridData, node) {
    var linksGroup = gridData.linksGroup;
    var id = node.id,
        _node$streamType = node.streamType,
        streamType = _node$streamType === undefined ? _enum.StreamType.CENTER : _node$streamType,
        _node$childs = node.childs,
        childs = _node$childs === undefined ? [] : _node$childs;


    return streamType === _enum.StreamType.CENTER ? childs : childs.filter(function (child) {
        return linksGroup[streamType === _enum.StreamType.UPPER ? child.id + '-' + id : id + '-' + child.id];
    });
}

// 获取有效count数
function getValidCount(node) {
    var nodeData = node.data,
        _node$streamType2 = node.streamType,
        streamType = _node$streamType2 === undefined ? _enum.StreamType.CENTER : _node$streamType2,
        _node$count = node.count,
        count = _node$count === undefined ? 0 : _node$count;
    var _nodeData$dwtzxx = nodeData.dwtzxx,
        dwtzxx = _nodeData$dwtzxx === undefined ? 0 : _nodeData$dwtzxx;

    var validCount = streamType === _enum.StreamType.CENTER ? count : streamType === _enum.StreamType.UPPER ? count - dwtzxx : dwtzxx;
    return validCount < 0 ? 0 : validCount;
}