import React, { useEffect, useState } from 'react';
import numBg from './nums.png';
import { fetchSurvey } from './service';
import { isNotEmpty } from '../../../utils/utils';

/**
 * @desc 行业内优质企业分布情况
 * @returns {*}
 * @constructor
 */

export default function Summary({ address, style }) {
  const [data, setData] = useState({});

  function getSurvey(area) {
    fetchSurvey({ area: area === '全国' ? '所有地区' : area }).then(res => {
      if (isNotEmpty(res?.data)) {
        setData(res.data);
      }
    });
  }

  useEffect(() => {
    getSurvey(address);
  }, [address]);

  return (
    <div>
      <div
        style={{
          float: 'left',
          height: '100%',
          width: '50%',
          textAlign: 'center',
          ...style
        }}
      >
        <div
          style={{
            position: 'relative',
            top: 0,
            width: 200,
            height: 100,
            marginTop: 40
          }}
        >
          <img
            src={numBg}
            style={{
              width: 200,
              height: 95,
              position: 'absolute',
              zIndex: 0,
              left: 0,
              top: -19
            }}
          />
          <div style={{ position: 'relative', top: -4 }}>
            申报科创板企业概况
          </div>
          <div style={{ fontSize: 36, color: '#00DBFF' }}>
            {data.companyNum || 0}
          </div>
        </div>
      </div>
      <div
        style={{
          float: 'right',
          textAlign: 'left',
          color: '#fff',
          width: '50%',
          height: '100%',
          lineHeight: '50px',
          paddingLeft: 20
        }}
      >
        <div>申报地区：{data.areaNum || 0}</div>
        <div>申报行业： {data.industryNum || 0}</div>
        <div>申报金额（亿）： {data.amountNum || 0}</div>
      </div>
    </div>
  );
}
