/**
 * @desc  挂牌企业地域分布图
 */
import React, { useState } from 'react';
import Title from './title';
import Radios from './radio';
import Map from './map';

export default function Line1Index() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '全部', value: 1 },
        { name: '基础层', value: 2 },
        { name: '创新层', value: 3 },
        { name: '精选层', value: 4 },
    ];

    return (
        <div>
            <Title />
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <Map type={type} style={{ height: '100%' }} />
        </div>
        
    );
}

