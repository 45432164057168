import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc 主要客户与供应商
 * @param creditCode
 * @param year
 */
export function mainCustomerAndSupplier({ creditCode, year }) {
    return request(`${API.V1}/investbank/company/main/cas`, {
        creditCode,
        year
    });
}

/**
 * @desc 主要指标
 * @param creditCode
 */
export function mainItem({ creditCode }) {
    return request(`${API.V1}/investbank/finance/item`, {
        creditCode
    });
}

/**
 * @desc 相似企业
 * @param creditCode
 */
export function getSimilarCompany({ creditCode, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/similar/enterprise`, {
        creditCode,
        pageNum,
        pageSize
    });
}

/**
 * @desc  营业收入构成
 * @param creditCode
 */
export function operationStructure({ creditCode }) {
    return request(`${API.V1}/investbank/company/main/psc`, {
        creditCode
    });
}

/**
 * @desc  资产负债构成
 * @param creditCode
 */
export function debtStructure({ code, year }) {
    return request(`${API.V1}/investbank/store/get/debt`, {
        code,
        year
    });
}

/**
 * @desc  资产负债有数据的年份
 * @param creditCode
 */
export function getDebtYears({ creditCode }) {
    return request(`${API.V1}/investbank/company/list/year`, {
        creditCode
    });
}
