'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

exports.setDegree = setDegree;
exports.clearDegree = clearDegree;

var _lodash = require('../lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 设置圈层级别
 * @param {array} nodes 点
 * @param {string} tid 目标ID
*/
function setDegree(nodes, tid) {
    var tNodes = null;
    // 重置 degree 属性
    nodes.forEach(function (d) {
        if (d.id === tid) {
            tNodes = [d];
            d.degree = 0;
        } else {
            d.degree = null;
        }
    });

    if (!tNodes) {
        console.error('目标ID不存在');
        return;
    }

    // 最大级别, 最大100圈
    var maxLevel = 100;

    var _loop = function _loop(i) {
        var arr = [];
        tNodes.forEach(function (tNode) {
            if (tNode.childs) {
                tNode.childs.forEach(function (node, j) {
                    if (node.degree === null) {
                        node.degree = i + 1;
                        arr.push(node);
                    }
                });
            } else {
                console.error('setDegree 数据异常！');
            }
        });
        // 一层一层往下遍历, 根据 childs 设置维度.
        tNodes = _lodash2.default.uniqBy(arr, 'id');

        if (tNodes.length === 0) {
            return 'break';
        }
    };

    for (var i = 0; i < maxLevel; i++) {
        var _ret = _loop(i);

        if (_ret === 'break') break;
    }
}

/**
 * @desc 清除 degree
*/
function clearDegree(data) {
    var nodes = data.nodes;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = (0, _getIterator3.default)(nodes), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var d = _step.value;

            d.degree = null;
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
}