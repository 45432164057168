import 'antd/dist/antd.css';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import './styles/common.css';
import './styles/reset.scss';
import React from 'react';
import Router from './containers/router';
import { ConfigProvider } from 'antd';
import Provider from './common/globalStore/createProvider';

const App = ({ user }) => (
    <ConfigProvider locale={zhCN}>
        <Provider store={{ user }}>
            <Router /> 
        </Provider>
    </ConfigProvider>
);

export default App;
