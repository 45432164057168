'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

exports.createDiv = createDiv;
exports.appendHTML = appendHTML;
exports.removeDom = removeDom;
exports.isIE = isIE;
exports.createCanvas = createCanvas;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 创建DIV 
*/
function createDiv(id, style) {
    var div = document.createElement('div');
    div.id = id;
    if (style) {
        (0, _keys2.default)(style).forEach(function (key) {
            return div.style[key] = style[key];
        });
    }
    return div;
}

/**
 * @desc 插入HTML
*/
function appendHTML(dom, html) {
    var divTemp = document.createElement('div'),
        nodes = null,
        fragment = document.createDocumentFragment();
    divTemp.innerHTML = html;
    nodes = divTemp.childNodes;
    for (var i = 0, length = nodes.length; i < length; i += 1) {
        fragment.appendChild(nodes[i].cloneNode(true));
    }
    dom.appendChild(fragment);
    nodes = null;
    fragment = null;
}

/**
 * @desc 移除dom
*/
function removeDom(cName) {
    // 移除dom
    var dailogs = document.getElementsByClassName(cName);
    if (dailogs.length > 0) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = (0, _getIterator3.default)(dailogs), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var elem = _step.value;

                if (isIE() === 'IE') {
                    elem.removeNode(true);
                } else {
                    elem.remove();
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
}

function isIE() {
    // 如果是IE 就是true
    var MT_MS = 'other';
    if (navigator.appVersion.indexOf('.NET') !== -1) {
        MT_MS = 'IE';
    }
    // console.warn('这浏览器类型是：', MT_MS);
    return MT_MS;
}
/**
 * 创建一个canvas
 */
function createCanvas() {
    var width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 300;
    var height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 150;
    var backgroundColor = arguments[2];

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    // 设置背景图
    if (backgroundColor) {
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, width, height);
    }
    return context;
}