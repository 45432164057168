import React from 'react';

/**
 * @author Ray
 */
export default [
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>服务公司名称
      </span>
    ),
    dataIndex: 'serviceCompany',
    key: 'serviceCompany',
    align: 'center',
    width: '20%'
  },
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>公司所在行业
      </span>
    ),
    dataIndex: 'companyIndustry',
    key: 'companyIndustry',
    align: 'center',
    width: '20%'
  },
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>板块
      </span>
    ),
    dataIndex: 'plate',
    key: 'plate',
    align: 'center',
    width: '15%'
  },
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>项目职位
      </span>
    ),
    dataIndex: 'projectPosition',
    key: 'projectPosition',
    align: 'center',
    width: '15%'
  },
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>所在机构
      </span>
    ),
    dataIndex: 'organization',
    key: 'organization',
    align: 'center',
    width: '15%'
  },
  {
    title: (
      <span>
        <i style={{ color: 'red' }}>*</i>过会情况
      </span>
    ),
    dataIndex: 'csrcStatus',
    key: 'csrcStatus',
    align: 'center',
    width: '15%'
  }
];
