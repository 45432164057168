'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _findIndex = require('lodash/findIndex');

var _findIndex2 = _interopRequireDefault(_findIndex);

var _groupBy = require('lodash/groupBy');

var _groupBy2 = _interopRequireDefault(_groupBy);

var _uniqBy = require('lodash/uniqBy');

var _uniqBy2 = _interopRequireDefault(_uniqBy);

var _concat = require('lodash/concat');

var _concat2 = _interopRequireDefault(_concat);

var _remove = require('lodash/remove');

var _remove2 = _interopRequireDefault(_remove);

var _keyBy = require('lodash/keyBy');

var _keyBy2 = _interopRequireDefault(_keyBy);

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

var _differenceBy = require('lodash/differenceBy');

var _differenceBy2 = _interopRequireDefault(_differenceBy);

var _uniq = require('lodash/uniq');

var _uniq2 = _interopRequireDefault(_uniq);

var _keys = require('lodash/keys');

var _keys2 = _interopRequireDefault(_keys);

var _xor = require('lodash/xor');

var _xor2 = _interopRequireDefault(_xor);

var _difference = require('lodash/difference');

var _difference2 = _interopRequireDefault(_difference);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    cloneDeep: _cloneDeep2.default, // 深拷贝
    findIndex: _findIndex2.default, // 查询index
    groupBy: _groupBy2.default, // 分组
    uniqBy: _uniqBy2.default, // 去重
    keys: _keys2.default, // 获取key 的数组
    keyBy: _keyBy2.default, // 转化keyBy
    uniq: _uniq2.default, // 去重
    xor: _xor2.default, // 差异
    concat: _concat2.default, // 合并数组
    remove: _remove2.default, // 删除数组
    isFunction: _isFunction2.default, // 判断函数
    difference: _difference2.default, // 判断函数
    differenceBy: _differenceBy2.default // 反向查询
};
module.exports = exports['default'];