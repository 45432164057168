import React from 'react';
import ErrorTip from '../common/errorTip';
import { isEmpty, isNotEmpty, throttle } from './utils';
import { message } from 'antd';

// 支持字母、符号、数字两类以上的组合
export const baseCheck = (text, min = 6, max = 16) => {
  return {
    message: `${text}由${min}-${max}字母、符号、数字两类以上的组合`,
    pattern: new RegExp(
      `((?=.*\\d)(?=.*\\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\\S{${min},${max}}$`
    )
  };
};

// 其他角色名，员工名等名字的。。。验证
export const OtherNameCheck = (text, min = 1, max = 15) => {
  return {
    message: `${text}由${min}-${max}位英文、数字、中文或下划线组成`,
    pattern: new RegExp(`^[\\u4e00-\\u9fa5_a-zA-Z0-9-]{${min},${max}}$`)
  };
};

export const allCheck = (min = 1, max = 15) => {
  return {
    message: `请输入${min}-${max}位字符`,
    pattern: new RegExp(`^[\\s\\S]{${min},${max}}$`)
  };
};
// 登录密码
export const pwdRule = [
  {
    required: true,
    message: <ErrorTip text="密码不能为空" />
  },
  {
    min: 6,
    message: <ErrorTip text={tipsLessN(6)} />
  },
  {
    validator: (rule, value, callback) => {
      const space = / /.test(value);
      if (space) {
        callback('不能包含空格');
      }
      const chinese = /[\u4E00-\uFA29]|[\uE7C7-\uE7F3]/.test(value);
      if (chinese) {
        callback('不能包含中文字符');
      }
      callback();
    }
  }
];
// 长度不能小于n
export function tipsLessN(n) {
  return `长度不能小于${n}位`;
}
// 长度不能大于n
export function tipsMoreN(n) {
  return `长度不能大于${n}位`;
}

// 必填项验证
export const RequiredCheck = {
  message: function(value = '') {
    return `${value}不能为空`;
  },
  pattern: /^\s*$/g
};
// 邮箱格式验证
export const EmailCheck = {
  message: <ErrorTip text="邮箱格式不正确" />,
  pattern: /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
// 电话格式验证, 如座机
export const PhoneCheck = {
  message: <ErrorTip text="电话格式不正确" />,
  pattern: /(^(\d{3,4}[-]?)?\d{7,8})$|^((1[0-9][0-9]\d{8}$))/
};
// 手机格式验证
export const CellphoneCheck = {
  message: '手机格式不正确',
  pattern: /^(1[3-8][0-9])\d{8}$/
};
// 日期格式验证
export const DateCheck = {
  message: '日期格式不正确',
  pattern: /^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$/
};
// 身份证格式验证
export const IDcardCheck = {
  message: '身份证格式不正确',
  pattern: /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
};
// 金额格式验证
export const PriceCheck = {
  message: '金额格式不正确',
  pattern: /^\d+(\.\d{1,2})?$/
};
// 纯数字验证
export const NumericCheck = {
  message: '请输入数字',
  pattern: /^[-+]?[0-9]+$/
};
// 正数验证
export const NegativeNumber = {
  message: <ErrorTip text="请输入正确的数字" />,
  pattern: /^[\-]{0,1}(\d+)$|^[-]{0,1}(\d+\.\d+)$/
};
// 纯字母验证
export const LetterCheck = {
  message: '请输入英文字符',
  pattern: /^[A-Za-z]+$/
};
// 字母加数字验证
export const LetterNumCheck = {
  message: '请输入英文字符或数字',
  pattern: /^[A-Za-z0-9]+$/
};
// 字母加数字加中文验证
export const LetterNumCNCheck = {
  message: '请输入英文字, 或数字或中文',
  pattern: /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[\w])*$/
};
// 只能输入正整数
export const PositiveInteger = {
  message: <ErrorTip text={'输入格式错误'} />,
  pattern: /^\d+$/
};
// 只能输入正数
export const PositiveFloat = {
  message: <ErrorTip text={'只能输入正数'} />,
  pattern: /^\d+(\.\d+)?$/
};
// 3-20位字母数字下划线组合
export const VerificationUserName = {
  message: '只能输入3-20位字母开头,以字母数字下划线组合',
  pattern: /^[a-zA-Z][a-zA-Z0-9_]{2,19}$/
};
export const UserPassword = {
  message: '密码由8位字母开头,以字母数字下划线组合',
  pattern: /^[a-zA-Z][a-zA-Z0-9_]{8}$/
};
// 6-20位字母数字下划线组合
export const Password = {
  message: '只能输入6-20位非空格字符',
  pattern: /^\S{6,20}$/
};

// 大于500的数字
export const greaterThan500 = {
  message: '只能输入大于等于500的数字',
  pattern: /([5-9]\d{2}|\d{4,})/
};

// 用户名 4位以上中文、英文（区分大小写）或数字
export const UserName = {
  message: '用户名不符合注册要求',
  pattern: /^([\u4E00-\uFA29]|[a-zA-Z0-9]){4,10}$/
};

// 统一社会信用代码 18位英文和数字
export const CreditCode = {
  message: '请输入正确的统一社会信用代码',
  pattern: /^[A-Za-z0-9]{18,18}$/
};

// 密码 字母+数字+特殊符号且不得少于6位
// export const PswCheck= {
//     message: '密码必须包含英文（区分大小写）数字和特殊字符',
//     pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[\W]).{6,}$/
// };

// 密码 字母+数字+特殊符号且不得少于6位
export const PswCheckSymbol = {
  message: '缺少符号',
  pattern: /^(?![0-9a-zA-Z]+$)/
};
export const PswCheckLetter = {
  message: '缺少字母',
  pattern: /^(?![0-9\W]+$)/
};
export const PswCheckNum = {
  message: '缺少数字',
  pattern: /^(?![a-zA-Z\W]+$)/
};

// 密码不能输入空格
export const SpaceCheck = {
  message: '密码不能包含空格',
  pattern: /^\S+$/
};

export const Required = {
  required: true,
  message: <ErrorTip text="该选项不可为空，请输入" />
};
// 输入大于0
export const GreaterThanZro = {
  validator: (rule, value, callback) => {
    if (isNotEmpty(value) && value < 0) {
      return callback(<ErrorTip text="输入小于0" />);
    }
    callback();
  }
};
// 输入大于0
export const GreaterThanZroAndLessThanHundred = {
  validator: (rule, value, callback) => {
    if (isNotEmpty(value) && (value < 0 || value > 100)) {
      return callback(<ErrorTip text="请输入大于0小于100" />);
    }
    callback();
  }
};

// 匹配优酷视屏iframme里面的src地址
export const youkuPattern = url => {
  if (url.indexOf('youku') === -1) {
    return false;
  }
  let pattern = /(http|https){1}:\/\/[^"'\s]*/;
  return url.match(pattern)[0];
};

// 手机号码格式验证
export const PhonePattern = {
  pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
  message: <ErrorTip text="手机号码格式错误" />
};
/**
 *
 * @param api
 * @param errorMessage
 * @param interrupter
 * @param success
 * @param fail
 * @returns {{validator: validator}}
 */
// 调用接口验证输入的数据
export const asyncValidator = (
  api = () => Promise.resolve({ success: true }),
  errorMessage = '',
  interrupter = res => res,
  success = () => {},
  fail = () => {},
  requestInterrupter = req => req
) => {
  let validator = throttle(
    (rule, value, callback) => {
      if (isEmpty(value)) {
        return callback();
      }
      value = requestInterrupter(value);
      api(value).then(res => {
        res = interrupter(res, value);
        if (isNotEmpty(res) && res.success) {
          if (res.data) {
            setTimeout(() => callback(), 0);
            success();
          } else {
            setTimeout(() => callback(errorMessage), 0);
            fail();
          }
        } else {
          callback();
          message.error(res.message);
        }
      });
    },
    1500,
    null
  );

  return {
    validator: (rule, value, callback) => {
      validator(rule, value, callback);
    }
  };
};

// 针对value是数字数组的组件，判断第一个数字比第二个数字小。
export function increment(messages) {
  return {
    validator: (rule, value, callback) => {
      if (isEmpty(value) || (isEmpty(value[0]) && isEmpty(value[1])))
        return callback();
      if (isEmpty(value[0]) || isEmpty(value[1])) {
        return callback(
          <ErrorTip
            text={
              (isEmpty(value[0]) && (messages[0] || '请选择第一个参数')) ||
              (isEmpty(value[1]) && (messages[1] || '请选择第二个参数'))
            }
          />
        );
      }
      if (isNotEmpty(value)) {
        if (
          (value[0] && value[1] && value[0] > value[1]) ||
          (value[0] && value[1] && value[1] < value[0])
        ) {
          return callback(<ErrorTip text={'输入格式错误'} />);
        }
      }
      return callback();
    }
  };
}
