import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { refinancingPieIndustry, refinancingPieTools } from './service';
import { Empty } from 'antd';
import Radios from './radio';
import { Selector } from '../../../merging/home/apply';

/**
 * @desc 再融资情况
 */
global.RefinancingPie = null;
export default function RefinancingPie({ address, style }) {
    const [type, setType] = useState(1);
    const [year, setYear] = useState('2022');
    const [data, setData] = useState([]);

    const selectValue = ['2022', '2023']

    const radiosData = [
      { name: '行业', value: 1 },
      { name: '工具', value: 2 },
    ];

    useEffect(() => {
      //请求行业接口
      type == 1 ? refinancingPieIndustry({ area: address, year: year }).then(({ success, data }) => {
          if (success == true) {
             if (global.RefinancingPie == null) {
                global.RefinancingPie = new Chart({
                  container: 'RefinancingPie',
                  autoFit: true,
                  height: 180,
                });
                global.RefinancingPie.data(data);
                global.RefinancingPie.tooltip(false);
                global.RefinancingPie.scale('percent', {
                  formatter: (val) => {
                    val = val + '%';
                    return val;
                  },
                });
      
                global.RefinancingPie.coordinate('theta', {
                  radius: 0.75,
                });
      
                global.RefinancingPie.interval()
                .adjust('stack')
                .position('percent')
                .color('item')
                .label('percent', (percent) => {
                  const offset = percent > 4 ? -10 : 13;
                  return {
                    offset,
                    content: (data) => {
                      return `${percent}%`;
                    },
                  };
                });
      
                global.RefinancingPie.legend({position:'right',offsetX: -100});
                global.RefinancingPie.render();
             } else {
                global.RefinancingPie.clear();
                global.RefinancingPie.changeData(data);
                global.RefinancingPie.tooltip(false);
                global.RefinancingPie.scale('percent', {
                  formatter: (val) => {
                    val = val + '%';
                    return val;
                  },
                });
      
                global.RefinancingPie.coordinate('theta', {
                  radius: 0.75,
                });
      
                global.RefinancingPie.interval()
                .adjust('stack')
                .position('percent')
                .color('item')
                .label('percent', (percent) => {
                  const offset = percent > 4 ? -10 : 13;
                  return {
                    offset,
                    content: (data) => {
                      return `${percent}%`;
                    },
                  };
                });
      
                global.RefinancingPie.legend({position:'right',offsetX: -100});
                global.RefinancingPie.render();
             }
          } else {
             <Empty />
          }
      }) : 
      //请求工具接口
      type == 2 ? refinancingPieTools({ area: address, year: year }).then(({ success, data }) => {
          if (success == true) {
                global.RefinancingPie.clear();
                global.RefinancingPie.changeData(data);
                global.RefinancingPie.tooltip(false);
                global.RefinancingPie.scale('percent', {
                  formatter: (val) => {
                    val = val + '%';
                    return val;
                  },
                });
      
                global.RefinancingPie.coordinate('theta', {
                  radius: 0.75,
                });
      
                global.RefinancingPie.interval()
                .adjust('stack')
                .position('percent')
                .color('item')
                .label('percent', (percent) => {
                  const offset = percent > 4 ? -10 : 13;
                  return {
                    offset,
                    content: (data) => {
                      return `${percent}%`;
                    },
                  };
                });
      
                global.RefinancingPie.legend({position:'right',offsetX: -100});
                global.RefinancingPie.render();
          } else {
             <Empty />
          }
      }) : <Empty />
    }, [address, type, year])

    return (
      <div style={{ position: 'relative' }}>
          <div className={ styles.rightForthTitle }>
              再融资情况
          </div>
          <Radios
              value={type}
              data={radiosData}
              defaultValue={1}
              onChange={e => setType(e.target.value)}
          />
          <Selector
              defaultValue={'2023'}
              className={ styles.selectorValue }
              placeholder="请选择区域"
              options={selectValue}
              onChange={value => {
                  setYear(value)
              }}
          />
          <div id='RefinancingPie' className={ styles.RefinancingPie }></div>
      </div>
    )
}