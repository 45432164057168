import React, { Component } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';    
import RelatedParty from '../../../common/ralationMap/index';


export default function(props) { 
    return (
        <div className={classnames(styles.ralationChart, "box") } >
           <RelatedParty {...props}/>
        </div>
    )
}
