import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  交易情况概览
*/
//三板做市
export function marketMakingIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/marketMakingIndex`)
}
//三板成指
export function componentIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/componentIndex`)
}
//创新指数
export function innovateIndex() {
    return request(`${API.V1}/cockpit/neeqStock/four/innovateIndex`)
}