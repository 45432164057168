import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { riskEnterprises } from './service';
import { Empty } from 'antd';

global.stockRiskPie = null;

export default function StockRiskPie({ address, style }) {
    useEffect(() => {
        riskEnterprises({ area: address }).then(({ success, data }) => {
            if (success == true) {
                if (global.stockRiskPie == null) {
                    global.stockRiskPie = new Chart({
                        container: 'StockRiskPie',
                        autoFit: true,
                        height: 230,
                    });

                    global.stockRiskPie.data(data);

                    global.stockRiskPie.coordinate('theta', {
                        radius: 0.75,
                        innerRadius: 0.4
                    });

                    global.stockRiskPie.tooltip(false);

                    global.stockRiskPie.legend({
                        position: 'right',
                        offsetX:-20,
                    });
                    global.stockRiskPie.interval()
                    .adjust('stack')
                    .position('score')
                    .color('item', ['#1890ff', '#13c2c2', '#ffc53d', '#73d13d'])
                    .style({ opacity: 0.4 })
                    .label('score', {
                        content: (obj) => {
                            return obj.score + '%';
                        },
                    });

                    global.stockRiskPie.interaction('active-region');

                    global.stockRiskPie.render();
                } else {
                    global.stockRiskPie.clear();
                    global.stockRiskPie.changeData(data);
                    global.stockRiskPie.coordinate('theta', {
                        radius: 0.75,
                        innerRadius: 0.4
                    });

                    global.stockRiskPie.tooltip(false);

                    global.stockRiskPie.legend({
                        position: 'right',
                    });

                    global.stockRiskPie.interval()
                    .adjust('stack')
                    .position('score')
                    .color('item', ['#1890ff', '#13c2c2', '#ffc53d', '#73d13d'])
                    .style({ opacity: 0.4 })
                    .label('score', {
                        content: (obj) => {
                            return obj.score + '%';
                        },
                    });

                    global.stockRiskPie.interaction('active-region');

                    global.stockRiskPie.render();
                }
            } else {
                <Empty />
            }
        })
    }, [address])

    return (
        <div id='StockRiskPie' className={ styles.StockRiskPie }></div>
    )
      
}
