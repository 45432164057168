import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  挂牌公司总览
*/
//总览
export function listedOverview() {
    return request(`${API.V1}/cockpit/neeqStock/two/listedOverview`)
}
//挂牌公司总览创新层
export function listedOverviewInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/two/listedOverviewInnovate`)
}
//挂牌公司总览基础层
export function listedOverviewBasic() {
    return request(`${API.V1}/cockpit/neeqStock/two/listedOverviewBasic`)
}