/**
 * @desc 入库企业列表搜索
 * @author Ray
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Form, Cascader, AutoComplete, Input, Radio } from 'antd';
import { Industry2, getCascaderProvince, Stock } from '../../../constants/enum';
import { GroupInput } from '../../merging/acquisition/intention';
import { Selector } from '../../merging/home/apply';
import GroupYearPicker from '../../../common/groupYearPicker';
import { getLabels } from './service';

const { Item } = Form;

function SearchConditions({ form }, ref) {
    const { getFieldDecorator } = form;

    const [labelSource, setLabelSource] = useState([]);
    
    const onChange = e => {
        form.setFieldsValue({
            status: e.target.value
        })
    }

    React.useImperativeHandle(ref, () => ({ form }));

    useEffect(() => {
        getLabels().then(res => {
            setLabelSource(res.data || []);
        });
    }, []);

    return (
        <Form
            className={styles.conditions}
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
        >
            <Item label="所属行业">
                {getFieldDecorator('industry', {
                    initialValue: null,
                    rules: []
                })(<Selector showSelectAll options={Industry2} />)}
            </Item>
            <Item label="注册资本(万元)">
                {getFieldDecorator('regcap', {
                    // rules: [
                    //     increment([
                    //         '请输入注册资本最小值',
                    //         '请输入注册资本最大值'
                    //     ])
                    // ],
                    initialValue: []
                })(<GroupInput min={0} />)}
            </Item>
            <Item label="所属地区">
                {getFieldDecorator('companyProvince', {
                    rules: [],
                    initialValue: ['北京', null]
                    // getValueFromEvent: values => values.slice(-1)[0]
                })(
                    <Cascader
                        allowClear={false}
                        style={{ width: 400 }}
                        options={getCascaderProvince()}
                        placeholder={'请选择所在地区'}
                        getPopupContainer={node => node.parentElement}
                    />
                )}
            </Item>
            <Item label="注册时间">
                {getFieldDecorator('year', {
                    // rules: [
                    //     increment(['请选择注册开始时间', '请选择注册结束时间'])
                    // ],
                    initialValue: []
                })(<GroupYearPicker />)}
            </Item>
            <Item label="企业类型">
                {getFieldDecorator('status', {
                    initialValue: '',
                    rules: []
                })(<Radio.Group onChange={onChange} options={labelSource}></Radio.Group>)}
            </Item>
            <Item label='上市板块'>
                {getFieldDecorator('stockCode', {
                    initialValue: null,
                    rules: []
                })(<Selector showSelectAll options={Stock} />)}        
            </Item>
        </Form>
    );
}

// <Input
//     style={{ width: 400 }}
//     placeholder="请输入企业状态名"
//     maxLength={50}
// />

export default function({ elementRef }) {
    const [E, setE] = useState(null);

    useEffect(function() {
        const Form_ = Form.create({
            name: 'search'
        })(React.forwardRef(SearchConditions));

        setE(<Form_ wrappedComponentRef={elementRef} />);
    }, []);

    return E;
}
