/**
 * @author Ray
 * @desc 研发能力情况
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, InputNumber, Tooltip, Icon, message } from 'antd';
import { Selector } from '../../merging/home/apply';
import {
  ResearchManage,
  ResearchBackground,
  ResearchEquipment
} from '../../../constants/enum';
import { SaveButton } from '../../../common/buttons';
import {
  GreaterThanZro,
  PositiveFloat,
  Required
} from '../../../utils/validator';
import { showModal, closeModal } from '../../investment/script/actions';
import { isNotEmpty } from '../../../utils/utils';
import { maxCreat } from '../../../constants/common';

const MESSAGE1 = '仅针对研发人员中的核心骨干背景情况。';
const MESSAGE2 = '企业正在研发中的产品、技术、生产工艺等。';

const FormEle = React.forwardRef(({ form, data }, ref) => {
  React.useImperativeHandle(ref, () => ({ form }));
  data = data || {};
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
    >
      <Form.Item label='研发管理情况'>
        {form.getFieldDecorator('researchManageCondition', {
          rules: [],
          initialValue: data.researchManageCondition
        })(<Selector options={ResearchManage} getPopupContainer={()=> document.getElementById('research')} />)}
      </Form.Item>
      <Form.Item label='员工人数'>
        {form.getFieldDecorator('staffNum', {
          rules: [GreaterThanZro],
          initialValue: data.staffNum
        })(
          <InputNumber
            min={0}
            maxLength={9}
            parser={value => value.replace(/['.']/g, '')}
            suffix='人'
          />
        )}
      </Form.Item>
      <Form.Item label='研发人员数量'>
        {form.getFieldDecorator('researchStaffNum', {
          rules: [GreaterThanZro],
          initialValue: data.researchStaffNum
        })(
          <InputNumber
            min={0}
            maxLength={9}
            parser={value => value.replace(/['.']/g, '')}
            suffix='人'
          />
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            核心研发人员背景&nbsp;
            <Tooltip placement='right' title={MESSAGE1}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('coreResearchCondition', {
          rules: [],
          initialValue: data.coreResearchCondition
        })(<Selector options={ResearchBackground}  getPopupContainer={()=> document.getElementById('research')} />)}
      </Form.Item>
      <Form.Item label='研发投入(万元)'>
        {form.getFieldDecorator('researchCost', {
          rules: [
            PositiveFloat
            //Required
          ],
          initialValue: data.researchCost
        })(<InputNumber min={0} maxLength={9} precision={2} />)}
      </Form.Item>
      <Form.Item label='研发设备情况'>
        {form.getFieldDecorator('researchDeviceCondition', {
          rules: [],
          initialValue: data.researchDeviceCondition
        })(<Selector options={ResearchEquipment} getPopupContainer={()=> document.getElementById('research')}  />)}
      </Form.Item>
      <Form.Item
        label={
          <span>
            研发储备情况&nbsp;
            <Tooltip placement='right' title={MESSAGE2}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('researchStorageNum', {
          rules: [GreaterThanZro],
          initialValue: data.researchStorageNum
        })(
          <InputNumber
            min={0}
            maxLength={9}
            parser={value => value.replace(/['.']/g, '')}
          />
        )}
      </Form.Item>
    </Form>
  );
});

export const FormElement = ({ formRef, data }) => {
  let [Element, setElement] = useState(null);

  useEffect(
    function() {
      let E = Form.create({
        name: 'core',
        onValuesChange: (ownedProps, changedValues, values) => {}
      })(FormEle);

      setElement(<E wrappedComponentRef={formRef} data={data} />);
    },
    [data]
  );

  return Element;
};
let currentKey = 1;
export default function Research(props) {
  const formRef = useRef(null);

  const save = () => {
    formRef.current.form.validateFields((errors, values) => {
      if (
        values.researchStaffNum >
        Number(
          values.staffNum === undefined
            ? Boolean(values.staffNum)
            : values.staffNum
        )
      )
        return message.error('研发人员数量不能大于员工总人数！');
      if (!errors) {
        props.saveFun(values);
      }
    });
  };

  useEffect(
    function() {
      if (props.activeKey != currentKey && currentKey == 1) {
        currentKey = props.activeKey;
        // 检验值是否修改过
        const currentValue = formRef.current.form.getFieldsValue();
        const hasChanged = Object.keys(currentValue).filter(function(
          value,
          index
        ) {
          return currentValue[value] !== props.data[value];
        });
        if (hasChanged.length) {
          props.dispatch(
            showModal({
              title: '研发能力情况',
              cancelText: '不保存！',
              okText: '保存！',
              onOk: () => {
                props.dispatch(closeModal());
                formRef.current.form.validateFields((errors, values) => {
                  if (!errors) {
                    if (
                      values.researchStaffNum >
                      Number(
                        values.staffNum === undefined
                          ? Boolean(values.staffNum)
                          : values.staffNum
                      )
                    )
                      return message.error('研发人员数量不能大于员工总人数！');
                    props.saveFun(values, function() {
                      hasChanged.forEach(value => {
                        formRef.current.form.setFieldsValue({
                          [value]: props.data[value]
                        });
                        props.dispatch(closeModal());
                      });
                    });
                  }
                });
              },
              onClose: () => {
                // 不保存恢复原样
                hasChanged.forEach(value => {
                  formRef.current.form.setFieldsValue({
                    [value]: props.data[value]
                  });
                  props.dispatch(closeModal());
                });
              }
            })
          );
        }
      }
      if (props.activeKey == 1) {
        currentKey = 1;
      }
    },
    [props.activeKey, props.data]
  );

  return (
    <div id="research">
      <FormElement formRef={formRef} data={props.data} />
      <div className='textCenter marginTop'>
        <SaveButton onClick={save} />
      </div>
    </div>
  );
}
