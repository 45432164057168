import fetch from 'utils/fetch';
import { API, BasePost } from 'constants/enum';

const listtrain = 'listtrain/';
// 分页查询问卷
export function getExams(data) {
    return fetch(`${API.V1}/${listtrain}integrate/find/exams`, data, BasePost );
}

// 问卷详情
export function getExamsInfo(data) {
    return fetch(`${API.V1}/${listtrain}integrate/find/exam/info`, data );
}

// 保存填写的问卷
export function saveAnswer(data) {
    return fetch(`${API.V1}/${listtrain}integrate/answer/save`, data, BasePost );
}