'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

exports.settings = settings;
exports.getSettings = getSettings;

var _company = require('../image/svg/company.svg');

var _company2 = _interopRequireDefault(_company);

var _riskCompany = require('../image/svg/riskCompany.svg');

var _riskCompany2 = _interopRequireDefault(_riskCompany);

var _blackCompany = require('../image/svg/blackCompany.svg');

var _blackCompany2 = _interopRequireDefault(_blackCompany);

var _revokeCompany = require('../image/svg/revokeCompany.svg');

var _revokeCompany2 = _interopRequireDefault(_revokeCompany);

var _targetCompany = require('../image/svg/targetCompany.svg');

var _targetCompany2 = _interopRequireDefault(_targetCompany);

var _targetRiskCompany = require('../image/svg/targetRiskCompany.svg');

var _targetRiskCompany2 = _interopRequireDefault(_targetRiskCompany);

var _targetBlackCompany = require('../image/svg/targetBlackCompany.svg');

var _targetBlackCompany2 = _interopRequireDefault(_targetBlackCompany);

var _targetRevokeCompany = require('../image/svg/targetRevokeCompany.svg');

var _targetRevokeCompany2 = _interopRequireDefault(_targetRevokeCompany);

var _person = require('../image/svg/person.svg');

var _person2 = _interopRequireDefault(_person);

var _targetPerson = require('../image/svg/targetPerson.svg');

var _targetPerson2 = _interopRequireDefault(_targetPerson);

var _address = require('../image/svg/address.svg');

var _address2 = _interopRequireDefault(_address);

var _bidding = require('../image/svg/bidding.svg');

var _bidding2 = _interopRequireDefault(_bidding);

var _debit = require('../image/svg/debit.svg');

var _debit2 = _interopRequireDefault(_debit);

var _litigation = require('../image/svg/litigation.svg');

var _litigation2 = _interopRequireDefault(_litigation);

var _historyCompany = require('../image/svg/historyCompany.svg');

var _historyCompany2 = _interopRequireDefault(_historyCompany);

var _historyPerson = require('../image/svg/historyPerson.svg');

var _historyPerson2 = _interopRequireDefault(_historyPerson);

var _company3 = require('../image/png/company.png');

var _company4 = _interopRequireDefault(_company3);

var _riskCompany3 = require('../image/png/riskCompany.png');

var _riskCompany4 = _interopRequireDefault(_riskCompany3);

var _blackCompany3 = require('../image/png/blackCompany.png');

var _blackCompany4 = _interopRequireDefault(_blackCompany3);

var _revokeCompany3 = require('../image/png/revokeCompany.png');

var _revokeCompany4 = _interopRequireDefault(_revokeCompany3);

var _targetCompany3 = require('../image/png/targetCompany.png');

var _targetCompany4 = _interopRequireDefault(_targetCompany3);

var _targetRiskCompany3 = require('../image/png/targetRiskCompany.png');

var _targetRiskCompany4 = _interopRequireDefault(_targetRiskCompany3);

var _targetBlackCompany3 = require('../image/png/targetBlackCompany.png');

var _targetBlackCompany4 = _interopRequireDefault(_targetBlackCompany3);

var _targetRevokeCompany3 = require('../image/png/targetRevokeCompany.png');

var _targetRevokeCompany4 = _interopRequireDefault(_targetRevokeCompany3);

var _person3 = require('../image/png/person.png');

var _person4 = _interopRequireDefault(_person3);

var _targetPerson3 = require('../image/png/targetPerson.png');

var _targetPerson4 = _interopRequireDefault(_targetPerson3);

var _address3 = require('../image/png/address.png');

var _address4 = _interopRequireDefault(_address3);

var _bidding3 = require('../image/png/bidding.png');

var _bidding4 = _interopRequireDefault(_bidding3);

var _debit3 = require('../image/png/debit.png');

var _debit4 = _interopRequireDefault(_debit3);

var _litigation3 = require('../image/png/litigation.png');

var _litigation4 = _interopRequireDefault(_litigation3);

var _historyCompany3 = require('../image/png/historyCompany.png');

var _historyCompany4 = _interopRequireDefault(_historyCompany3);

var _historyPerson3 = require('../image/png/historyPerson.png');

var _historyPerson4 = _interopRequireDefault(_historyPerson3);

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _cloneDeep = require('lodash/cloneDeep');

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _enum = require('../constant/enum');

var _common = require('../util/common');

var _jsCookie = require('js-cookie');

var _jsCookie2 = _interopRequireDefault(_jsCookie);

var _httpRequest = require('../httpRequest');

var _httpRequest2 = _interopRequireDefault(_httpRequest);

var _formatter = require('../util/formatter');

var _filter = require('../helper/filter');

var _filter2 = _interopRequireDefault(_filter);

var _translator = require('../helper/translator');

var _translator2 = _interopRequireDefault(_translator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _ref = new _filter2.default(),
    getShareholder = _ref.getShareholder,
    eachInvest = _ref.eachInvest,
    circleInvest3 = _ref.circleInvest3,
    spreadLink = _ref.spreadLink,
    filterRevelance = _ref.filterRevelance,
    filterRevelanceKey = _ref.filterRevelanceKey,
    filterRevelanceCurrentMonth = _ref.filterRevelanceCurrentMonth,
    filterEventRelation = _ref.filterEventRelation;
// PNG for IE


var _ref2 = new _translator2.default(),
    transformCircle = _ref2.transformCircle,
    transformCircleExtension = _ref2.transformCircleExtension,
    transformCircleShortestPath = _ref2.transformCircleShortestPath,
    transformCommunity = _ref2.transformCommunity,
    transformCommunityExtension = _ref2.transformCommunityExtension,
    transformCommunityShortestPath = _ref2.transformCommunityShortestPath,
    transformRegion = _ref2.transformRegion,
    transformRegionExtension = _ref2.transformRegionExtension,
    transformIndustry = _ref2.transformIndustry,
    transformIndustryExtension = _ref2.transformIndustryExtension,
    transformStock = _ref2.transformStock,
    transformStockExtension = _ref2.transformStockExtension,
    transformEvent = _ref2.transformEvent,
    transformEventExtension = _ref2.transformEventExtension,
    transformSeek = _ref2.transformSeek;

var _config = {
    degree: 2, // 全局默认搜索维度
    limitNodes: 2000, // 最大节点数
    animation: {
        maxNodes: 1000,
        maxLines: 3000
    },
    map: {
        version: '1.4.3',
        key: '2cb2e9cee39b91620e38572418bc378f'
    },
    line: {
        // line 使用的图层为100-199
        layer: {
            low: 100,
            normal: 130,
            highlight: 199
        },
        style: {
            item: {
                opacity: 1,
                stroke: '#dae4f2',
                lineWidth: 1
            },
            // 线条默认样式
            text: {
                fontSize: 12,
                textFill: '#7a8a99',
                textStroke: '#ffffff',
                textAlign: 'center',
                textVerticalAlign: 'middle',
                textStrokeWidth: 1,
                // 线上显示的文字内容
                content: function content(options) {
                    var text = '';
                    var _options$relation = options.relation,
                        relation = _options$relation === undefined ? true : _options$relation,
                        _options$ratio = options.ratio,
                        ratio = _options$ratio === undefined ? true : _options$ratio,
                        hovered = options.hovered,
                        selected = options.selected,
                        data = options.data;
                    var labels = data.labels,
                        _ratio = data.ratio,
                        isHistory = data.isHistory;

                    var showRelation = relation || hovered || selected;
                    var showRatio = ratio || hovered || selected;
                    // 关联关系
                    if (labels && showRelation) {
                        // 从labels中提取关联关系
                        var name = labels.find(function (n) {
                            return _enum.RelationRole[n.toLowerCase()];
                        });

                        if ((0, _common.isNotBlank)(name)) {
                            text += _enum.RelationRole[name.toLowerCase()] || '';
                        }

                        if (isHistory) {
                            text += '(历史)';
                        }
                    }

                    // 投资比例
                    var num = parseFloat(_ratio || 0);
                    if (num && showRatio) {
                        if (text.length > 0) {
                            text += '\n';
                        }
                        text += num.toFixed(2) + '%';
                    }

                    return text;
                }
            },
            // 淡出
            slight: function slight(options) {
                return 0.1;
            },

            // 悬停
            hovered: function hovered(options) {
                var color;
                switch (options.relationType) {
                    case _enum.RelationType.INVESTMENT:
                        // 投资关系
                        color = '#ff6b61';
                        break;
                    case _enum.RelationType.MANAGEMENT:
                        // 管理关系
                        color = '#008aff';
                        break;
                }

                return color;
            },
            selected: function selected(options) {
                // 选中
                return 'red';
            }
        }
    },
    node: {
        layer: { // node 使用的图层为200-299
            low: 200,
            normal: 230,
            target: 299
        },
        style: {
            // 节点类型图标
            icon: function icon(category, isTarget, isHistory, options) {
                var image = void 0,
                    companyIcon = (0, _common.isIE)() ? _company4.default : _company2.default,
                    targetCompanyIcon = (0, _common.isIE)() ? _targetCompany4.default : _targetCompany2.default,
                    personIcon = (0, _common.isIE)() ? _person4.default : _person2.default,
                    targetPersonIcon = (0, _common.isIE)() ? _targetPerson4.default : _targetPerson2.default,
                    riskCompanyIcon = (0, _common.isIE)() ? _riskCompany4.default : _riskCompany2.default,
                    targetRiskCompanyIcon = (0, _common.isIE)() ? _targetRiskCompany4.default : _targetRiskCompany2.default,
                    blackCompanyIcon = (0, _common.isIE)() ? _blackCompany4.default : _blackCompany2.default,
                    targetBlackCompanyIcon = (0, _common.isIE)() ? _targetBlackCompany4.default : _targetBlackCompany2.default,
                    revokeCompanyIcon = (0, _common.isIE)() ? _revokeCompany4.default : _revokeCompany2.default,
                    targetRevokeCompanyIcon = (0, _common.isIE)() ? _targetRevokeCompany4.default : _targetRevokeCompany2.default,
                    addressIcon = (0, _common.isIE)() ? _address4.default : _address2.default,
                    biddingIcon = (0, _common.isIE)() ? _bidding4.default : _bidding2.default,
                    debitIcon = (0, _common.isIE)() ? _debit4.default : _debit2.default,
                    litigationIcon = (0, _common.isIE)() ? _litigation4.default : _litigation2.default,
                    historyPersonIcon = (0, _common.isIE)() ? _historyPerson4.default : _historyPerson2.default,
                    historyCompanyIcon = (0, _common.isIE)() ? _historyCompany4.default : _historyCompany2.default;

                if (isHistory) {
                    // 历史关联方
                    switch (category) {
                        case _enum.NodeType.PERSON:
                            // 自然人
                            image = historyPersonIcon;
                            break;
                        default:
                            // 企业
                            image = historyCompanyIcon;
                    }
                } else {
                    switch (category) {
                        case _enum.NodeType.NORMAL:
                            // 普通企业
                            image = isTarget ? targetCompanyIcon : companyIcon;
                            break;
                        case _enum.NodeType.RISK:
                            // 风险企业
                            image = isTarget ? targetRiskCompanyIcon : riskCompanyIcon;
                            break;
                        case _enum.NodeType.BLACK:
                            // 黑名单企业
                            image = isTarget ? targetBlackCompanyIcon : blackCompanyIcon;
                            break;
                        case _enum.NodeType.REVOKE:
                            // 吊销企业
                            image = isTarget ? targetRevokeCompanyIcon : revokeCompanyIcon;
                            break;
                        case _enum.NodeType.PERSON:
                            // 自然人
                            image = isTarget ? targetPersonIcon : personIcon;
                            break;
                        case _enum.NodeType.ADDRESS:
                            // 地址
                            image = addressIcon;
                            break;
                        case _enum.NodeType.BIDDING:
                            // 借贷担保
                            image = biddingIcon;
                            break;
                        case _enum.NodeType.DEBIT:
                            // 诉讼
                            image = debitIcon;
                            break;
                        case _enum.NodeType.LITIGATION:
                            // 招投标
                            image = litigationIcon;
                            break;
                    }
                }

                return image;
            },

            item: { // 节点默认样式
                width: 26,
                height: 26,
                opacity: 1
            }, // 目标节点样式
            target: {
                width: 48,
                height: 48
            }, // 关键节点样式
            key: {
                width: 48,
                height: 48
            },
            text: {
                // 节点文字样式
                content: function content(text, options) {
                    var category = options.category,
                        textMaxLength = options.textMaxLength;


                    if (text && category !== 'person') {
                        // 文字清理
                        text = text.replace('有限公司', '');
                        text = text.replace('有限责任公司', '');
                        text = text.replace('分公司', '');
                        text = text.replace('公司', '');
                    }

                    // 文字换行，只能在替换关键字之后替换，不然会出现bug---切换文字有空格，公司关键字替换不完全
                    if (text && textMaxLength) {
                        text = (0, _formatter.wordLinefeed)(text, textMaxLength);
                    }

                    return text;
                },

                textFill: '#767676',
                textPosition: 'bottom',
                transformText: true
            },
            targetEffect: { // 目标节点的辐射效果
                color: '#1bc4d4', // 蓝色
                overflow: 15, // 辐射溢出目标节点范围
                easing: 'quinticOut', // 辐射动画效果
                speed: 1500 // 辐射速度
            },
            slight: function slight(node) {
                // 淡出效果
                return 0.1;
            }
        },
        mark: {
            hidden: false,
            distance: 7,
            delay: 300,
            layer: {
                normal: 231
            },
            style: {
                item: {
                    width: 12,
                    height: 12,
                    opacity: 1
                },
                count: { // 关联方数量mark样式
                    fill: 'red',
                    stroke: 'red',
                    lineWidth: 1,
                    fontSize: 9,
                    textFill: 'white',
                    textStroke: 'white',
                    transformText: true
                },
                slight: function slight(mark) {
                    // 淡出效果
                    return 0.1;
                }
            }
        }
    },
    circle: {
        layer: {
            normal: -2
        },
        style: {
            item: {
                opacity: 0.8,
                fill: '#f6f9fb',
                stroke: '#c3ced9'
            },
            text: {
                textFill: '#148fcc',
                textStroke: '#148fcc',
                textAlign: 'center',
                textPosition: 'bottom',
                fontSize: 20,
                fontWeight: 'bold',
                transformText: true
            },
            slight: function slight(circle) {
                // 淡出效果
                return 0.2;
            }
        }
    },
    chart: {
        event: {
            nodeOption: {},
            layout: {
                radius: 200,
                interval: 100,
                splitArea: true
            }
        }
    },
    export: {
        title: {
            text: function text(chartType) {
                var targets = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

                if ((typeof targets === 'undefined' ? 'undefined' : (0, _typeof3.default)(targets)) === 'object' && !Array.isArray(targets)) {
                    targets = [targets];
                }
                var companies = targets.map(function (target) {
                    return target.data.companyName || target.data.personName;
                }).join(', ');

                return '\u3010 ' + companies + ' \u4F01\u4E1A\u753B\u50CF-' + _enum.typeToName[chartType] + ' \u3011';
            },

            textAlign: 'center',
            font: '26px sans-serif',
            textFill: '#183158'
        },
        footer: {
            text: '该图系BBD基于万象平台算法利用大数据、动态本体理论独家发布的可视化关系图谱',
            textAlign: 'center',
            font: '14px sans-serif',
            textFill: '#456',
            lineStroke: 'rgba(24, 49, 89, 0.17)'
        },
        legend: {
            textFill: '#456',
            items: [{
                text: {
                    text: '企业',
                    font: '14px sans-serif',
                    textAlign: 'right'
                },
                circle: {
                    fill: '#AEC3F1',
                    radius: 7
                }
            }, {
                text: {
                    text: '（有风险事件）企业',
                    font: '14px sans-serif',
                    textAlign: 'right'
                },
                circle: {
                    fill: '#FF6B61',
                    radius: 7
                }
            }, {
                text: {
                    text: '（黑名单）企业',
                    font: '14px sans-serif',
                    textAlign: 'right'
                },
                circle: {
                    fill: '#445566',
                    radius: 7
                }
            }, {
                text: {
                    text: '（吊销/注销）企业',
                    font: '14px sans-serif',
                    textAlign: 'right'
                },
                circle: {
                    fill: '#A9ADB7',
                    radius: 7
                }
            }, {
                text: {
                    text: '自然人',
                    font: '14px sans-serif',
                    textAlign: 'right'
                },
                circle: {
                    fill: '#42C9E8',
                    radius: 7
                }
            }]
        }
    },
    filter: {
        type: {
            shareholder: spreadLink, // 信息主体股东关联
            invest: spreadLink, // 信息主体对外投资关联
            together: eachInvest, // 相互投资
            circle: circleInvest3, // 投资关联圈
            main: getShareholder, // 董监高及主要人员关联
            warning: filterRevelance, // 有风险项关联方
            unknown: filterRevelance, // 不明企业和自然人
            black: filterRevelance, // 黑名单关联方
            revoke: filterRevelance, // 吊销企业
            currentMonth: filterRevelanceCurrentMonth, // 当月有工商变更企业
            key: filterRevelanceKey, // 关键关联方
            lawsuit: filterEventRelation, // 诉讼关系
            borrow: filterEventRelation, // 借贷/担保关系
            bid: filterEventRelation, // 招投标关系
            addr: filterEventRelation // 同地址关系
        },
        spreadLink: {
            shareholder: {
                type: 'in',
                label: 'Invest'
            },
            invest: {
                type: 'out',
                label: 'Invest'
            },
            together: {
                type: '',
                label: ''
            },
            circle: {
                type: '',
                label: ''
            },
            main: {
                type: 'in',
                label: ''
            },
            warning: {
                type: 'risk',
                label: ''
            },
            black: {
                type: 'black',
                label: ''
            },
            unknown: {
                type: 'person',
                label: ''
            },
            revoke: {
                type: 'revoke',
                label: ''
            },
            currentMonth: {
                type: 'currentMonth',
                label: ''
            },
            key: {
                type: 'key',
                label: ''
            },
            lawsuit: {
                type: 'out',
                label: ['Ktgg', 'Rmfygg', 'Zgcpwsw', 'Zhixing', 'Dishonesty']
            },
            borrow: {
                type: 'out',
                label: ['Qyxg_debet']
            },
            bid: {
                type: 'out',
                label: ['Shgy_zhongjg', 'Shgy_zhaobjg']
            },
            addr: {
                type: 'out',
                label: ['Address']
            }
        }
    },
    datasource: {
        // 默认是浩格云信与企明灯的接口配置
        config: {
            cache: false,
            timeout: 120000,
            requestInterceptor: function requestInterceptor(config) {
                var TOKEN = _jsCookie2.default.get('TOKEN');
                if (TOKEN) {
                    config.headers[TOKEN] = TOKEN;
                }
                return config;
            },

            // 接口返回拦截器
            afterResponse: function afterResponse(resolve, reject, response, options) {
                var _response$data = response.data,
                    success = _response$data.success,
                    data = _response$data.data,
                    statusCode = _response$data.statusCode,
                    message = _response$data.message;
                var isDev = options.isDev;


                if (success) {
                    resolve(data);
                } else {
                    var TICKET = 'TICKET';
                    // 考虑 'xxx.higgscredit.com' 和 'xxx.bbdqimingdeng.com'
                    // 替换为单点登录域名
                    // let ssoDomain = options.baseURL.replace(/(\w+\.)/, SSO).replace('/mapSpread', '');
                    var SSO_DOMAIN = options.baseURL.match(/^(https?:)?\/{2}[\w\.]+(:\d+)?/)[0].replace(/\w+\./, 'sso.');
                    switch (statusCode) {
                        case 5201:
                            // 校验token
                            (0, _httpRequest2.default)({
                                baseURL: SSO_DOMAIN,
                                url: '/api/v1.0/auth/token',
                                method: 'post',
                                withCredentials: true,
                                data: {
                                    token: _jsCookie2.default.get('SSO-TOKEN')
                                }
                            }, isDev).done(function (ticket) {
                                if (ticket) {
                                    // token保存到sessionStorage
                                    sessionStorage.setItem(TICKET, ticket);
                                    options.params = options.params || {};
                                    options.params.ticket = ticket;
                                    (0, _httpRequest2.default)(options, isDev).done(function (data) {
                                        // 校验成功后重新执行上一个接口请求
                                        resolve(data);
                                    }, function (error) {
                                        reject(error);
                                    });
                                } else {
                                    reject('ticket 为空: ' + ticket);
                                }
                            }, function (error) {
                                reject(error);
                            });
                            break;
                        // 拦截5205， 从sessionStorage拿token重发请求
                        case 5205:
                            options.ticket = sessionStorage.getItem(TICKET);
                            (0, _httpRequest2.default)(options, isDev).done(function (data) {
                                // 校验成功后重新执行上一个接口请求
                                resolve(data);
                            }, function (error) {
                                reject(error);
                            });
                            break;
                        default:
                            console.error('\u9519\u8BEF\u4FE1\u606F: ' + message + ', \u72B6\u6001\u7801: ' + statusCode);
                            reject(response.data);
                    }
                }
            }
        },
        service: {
            // 圈层分布接口
            circle: {
                // 初始化数据
                init: function init(_ref3) {
                    var bbdQyxxId = _ref3.bbdQyxxId,
                        degree = _ref3.degree,
                        _ref3$date = _ref3.date,
                        date = _ref3$date === undefined ? '' : _ref3$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/circle/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformCircle
                    };
                },
                // 扩展数据
                extension: function extension(_ref4) {
                    var bbdQyxxId = _ref4.bbdQyxxId,
                        degree = _ref4.degree,
                        _ref4$date = _ref4.date,
                        date = _ref4$date === undefined ? '' : _ref4$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/circle/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformCircleExtension
                    };
                },
                // 2个节点之间最短路劲数据
                shortestPath: function shortestPath(_ref5) {
                    var startNodeId = _ref5.startNodeId,
                        startNodeType = _ref5.startNodeType,
                        endNodeId = _ref5.endNodeId,
                        endNodeType = _ref5.endNodeType,
                        degree = _ref5.degree;

                    return {
                        url: '/wellspring/api/v1.0/relation/circle/shortestPath/' + startNodeId + '/' + startNodeType + '/' + endNodeId + '/' + endNodeType + '/' + degree,
                        transformResponse: transformCircleShortestPath
                    };
                }
            },
            // 社区分布接口
            community: {
                // 初始化数据
                init: function init(_ref6) {
                    var bbdQyxxId = _ref6.bbdQyxxId,
                        _ref6$date = _ref6.date,
                        date = _ref6$date === undefined ? '' : _ref6$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/community/' + bbdQyxxId + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformCommunity
                    };
                },
                // 扩展数据
                extension: function extension(_ref7) {
                    var bbdQyxxId = _ref7.bbdQyxxId,
                        degree = _ref7.degree,
                        _ref7$date = _ref7.date,
                        date = _ref7$date === undefined ? '' : _ref7$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/community/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformCommunityExtension
                    };
                },
                // 2个节点之间最短路劲数据
                shortestPath: function shortestPath(_ref8) {
                    var startNodeId = _ref8.startNodeId,
                        startNodeType = _ref8.startNodeType,
                        endNodeId = _ref8.endNodeId,
                        endNodeType = _ref8.endNodeType,
                        degree = _ref8.degree;

                    return {
                        url: '/wellspring/api/v1.0/relation/community/shortestPath/' + startNodeId + '/' + startNodeType + '/' + endNodeId + '/' + endNodeType + '/' + degree,
                        transformResponse: transformCommunityShortestPath
                    };
                }
            },
            // 地域分布接口
            region: {
                // 初始化数据
                init: function init(_ref9) {
                    var bbdQyxxId = _ref9.bbdQyxxId,
                        degree = _ref9.degree,
                        _ref9$date = _ref9.date,
                        date = _ref9$date === undefined ? '' : _ref9$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/region/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformRegion
                    };
                },
                // 扩展数据
                extension: function extension(_ref10) {
                    var bbdQyxxId = _ref10.bbdQyxxId,
                        degree = _ref10.degree,
                        _ref10$date = _ref10.date,
                        date = _ref10$date === undefined ? '' : _ref10$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/region/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformRegionExtension
                    };
                }
            },
            // 行业分布接口
            industry: {
                // 初始化数据
                init: function init(_ref11) {
                    var bbdQyxxId = _ref11.bbdQyxxId,
                        degree = _ref11.degree,
                        _ref11$date = _ref11.date,
                        date = _ref11$date === undefined ? '' : _ref11$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/industry/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformIndustry
                    };
                },
                // 扩展数据
                extension: function extension(_ref12) {
                    var bbdQyxxId = _ref12.bbdQyxxId,
                        degree = _ref12.degree,
                        _ref12$date = _ref12.date,
                        date = _ref12$date === undefined ? '' : _ref12$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/industry/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformIndustryExtension
                    };
                }
            },
            // 股权分布接口
            stock: {
                // 初始化数据
                init: function init(_ref13) {
                    var bbdQyxxId = _ref13.bbdQyxxId,
                        degree = _ref13.degree,
                        _ref13$date = _ref13.date,
                        date = _ref13$date === undefined ? '' : _ref13$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/stock/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformStock
                    };
                },
                // 扩展数据
                extension: function extension(_ref14) {
                    var bbdQyxxId = _ref14.bbdQyxxId,
                        degree = _ref14.degree,
                        _ref14$date = _ref14.date,
                        date = _ref14$date === undefined ? '' : _ref14$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/stock/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformStockExtension
                    };
                }
            },
            // 事件关系接口
            event: {
                // 初始化数据
                init: function init(_ref15) {
                    var bbdQyxxId = _ref15.bbdQyxxId,
                        degree = _ref15.degree,
                        _ref15$date = _ref15.date,
                        date = _ref15$date === undefined ? '' : _ref15$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/event/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformEvent
                    };
                },
                // 扩展数据
                extension: function extension(_ref16) {
                    var bbdQyxxId = _ref16.bbdQyxxId,
                        degree = _ref16.degree,
                        _ref16$date = _ref16.date,
                        date = _ref16$date === undefined ? '' : _ref16$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/event/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformEventExtension
                    };
                }
            },
            // 关系探寻接口
            seek: {
                // 初始化数据
                init: function init(_ref17) {
                    var nodeIds = _ref17.nodeIds,
                        nodeTypes = _ref17.nodeTypes,
                        seekType = _ref17.seekType;

                    return {
                        url: '/wellspring/api/v1.0/relation/seek',
                        method: 'post',
                        data: {
                            nodeIds: nodeIds,
                            nodeTypes: nodeTypes,
                            seekType: seekType
                        },
                        transformResponse: transformSeek
                    };
                }
            },
            // 核心自然人接口(红警)
            natural: {
                // 初始化数据
                init: function init(_ref18) {
                    var bbdQyxxId = _ref18.bbdQyxxId,
                        degree = _ref18.degree,
                        _ref18$date = _ref18.date,
                        date = _ref18$date === undefined ? '' : _ref18$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/people/' + bbdQyxxId + '/' + degree + date,
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformStock
                    };
                },
                // 扩展数据
                extension: function extension(_ref19) {
                    var bbdQyxxId = _ref19.bbdQyxxId,
                        degree = _ref19.degree,
                        _ref19$date = _ref19.date,
                        date = _ref19$date === undefined ? '' : _ref19$date;

                    if (date) {
                        date = '/' + date;
                    }
                    return {
                        url: '/wellspring/api/v1.0/relation/people/' + bbdQyxxId + '/' + degree + date,
                        transformResponse: transformStockExtension
                    };
                }
            },
            // 抵押担保分布接口(生态信用评级项目)
            mortgage: {
                // 初始化数据
                init: function init(_ref20) {
                    var bbdQyxxId = _ref20.bbdQyxxId,
                        degree = _ref20.degree,
                        date = _ref20.date;

                    return {
                        url: '/api/v1.0/company/mortgage_guarantee/' + bbdQyxxId,
                        data: {
                            bbdQyxxId: bbdQyxxId
                        },
                        params: {
                            targetId: bbdQyxxId
                        },
                        transformResponse: transformCircle
                    };
                }
            },
            // 通用功能接口
            common: {
                // 关联方count数量统计
                count: function count(_ref21) {
                    var bbdQyxxId = _ref21.bbdQyxxId,
                        isEvent = _ref21.isEvent,
                        labels = _ref21.labels,
                        degree = _ref21.degree,
                        relationType = _ref21.relationType,
                        type = _ref21.type,
                        date = _ref21.date;

                    return {
                        url: '/wellspring/api/v1.0/relation/count',
                        method: 'post',
                        data: {
                            bbdQyxxId: bbdQyxxId,
                            isEvent: isEvent,
                            labels: labels,
                            degree: degree,
                            relationType: relationType,
                            type: type,
                            date: date
                        }
                    };
                },
                // 当月工商变更数量
                monthBgxxCount: function monthBgxxCount(_ref22) {
                    var month = _ref22.month,
                        nodeIds = _ref22.nodeIds;

                    return {
                        url: '/wellspring/api/v1.0/company/monthBgxxCount',
                        method: 'post',
                        data: {
                            month: month,
                            nodeIds: nodeIds
                        }
                    };
                },
                // 新鲜事
                newData: function newData(nodeIds) {
                    return {
                        url: '/wellspring/api/v1.0/company/newData',
                        method: 'post',
                        data: {
                            nodeIds: nodeIds
                        }
                    };
                },
                // 图谱历史动态风险项
                relationRisk: function relationRisk(_ref23) {
                    var bbdQyxxId = _ref23.bbdQyxxId,
                        degree = _ref23.degree;

                    return {
                        url: '/wellspring/api/v1.0/relation/risk/' + bbdQyxxId + '/' + degree
                    };
                },
                eventDetail: function eventDetail(_ref24) {
                    var bbdEventId = _ref24.bbdEventId,
                        labels = _ref24.labels;

                    return {
                        url: '/wellspring/api/v1.0/relation/event',
                        method: 'post',
                        data: {
                            bbdEventId: bbdEventId,
                            labels: labels
                        }
                    };
                }
            }
        }
    }
};

function settings(config) {
    (0, _merge2.default)(_config, config);
}

function getSettings() {
    return (0, _cloneDeep2.default)(_config);
}

exports.default = _config;