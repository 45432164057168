import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';


export function getQueryListedCompanyFb(params) {
    return request(`${API.V1}/auth/kcDriver/index/queryListedCompanyFb`, params);
}

//财务风险侧重情况
export function financialRiskFocus(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightSecond/financialRiskRadar`, params);
}

