/**
 * @desc 入库企业分析-基础信息-上市能力分析
 * @author Ray
 */
import React, { useContext, useState } from 'react';
import { Empty } from 'antd';
import styles from './styles.scss';
import Valuation from './valuation';
import ListingQualificationJudgement from './listingQualificationJudgment';
import Recommendation from './recommendation';
import ScientificInnovation from './scientificInnovation';
import ScientificInnovationTable from './scientificInnovationTable';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';

const elements = [
    Valuation,
    ListingQualificationJudgement,
    //Recommendation,
    ScientificInnovation,
    ScientificInnovationTable
];

export default function ListedAbilityAnalysis(props) {
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );
    return (
        <div className={styles.root}>
            {((gs.isAdvanceUser || gs.isVIPUser) && isOwnedCompany) ||
            (gs.isIntermediary && gs.isVIPUser) ||
            gs.isGOV ||
            gs.isQiYuan ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                elements.map((E, index) => <E key={index} {...props} />)
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
