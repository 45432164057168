import React from 'react';
import RelatedParty from '../../../../common/ralationMap';
// import HiggsChartsMap from '../../../../common/ralationMap/HiggsChartsMap';
import Title from '../../../../common/title';
import styles from './styles.scss';
import { Divider } from 'antd';
import NewRelation from '../../../riskMonitoring/newRelationMap'

export default function(props) {
    return (
        <div className={styles.relatedBox}>
            <Title level={1} text="公司图谱" />
            <Divider />
            {/*<newRelation {...props} />*/}
            <NewRelation {...props}/>
        </div>
    );
}
