import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import Radios from './radio';
import { riskAverage } from './service';
import { getAreas } from '../service';
import { isNotEmpty } from '../../../../utils/utils';
import Pic1 from '../images/1.png';
import Pic2 from '../images/2.png';
import Pic3 from '../images/3.png';
import Pic4 from '../images/4.png';

const radiosData = [
    { name: '全国', value: 1 },
    { name: '北京', value: 2 },
    { name: '上海', value: 3 },
    { name: '更多', value: 4 }
];

export default function MapTools({ onChange, value, address }) {
    const [data, setData] = useState({
        ipoCompaniesAmount: 0,
        ipoCompaniesRiskAmount: 0,
        ipoCompaniseRiskPercent: 0,
    });
    const [provinces, setProvince] = useState([]);
    useEffect(() => {
        riskAverage({
            area: address
        }).then(res => {
            if (isNotEmpty(res?.data)) {
                setData(res.data);
            }
        });

        getAreas().then(res => {
            const arr = res && res.data.reduce((acc, cur) => {
                if (address.toString().indexOf(`${cur.code}`) !== -1) {
                  acc.push(cur.area)
                  return acc;
                }
                return acc;
            }, [])
            setProvince(arr)
        })
    }, [address, value]);

    return (
        <div className={styles.mapTool}>
            <br />
            <div style={{
                width: window.screen.width < 1920 ? '200px' : '260px',
                height: '46px',
                float: 'left',
                fontSize: window.screen.width < 1920 ? '8px' : '16px',
                color: '#8fc8ff',
                marginLeft: window.screen.width < 1920 ? '100px' : '-16px',
                background: `url(${Pic1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}已上市企业数量&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: window.screen.width < 1920 ? '8px' : '2px',
                    paddingRight: '15px',
                    fontSize: window.screen.width < 1920 ? '8px' : '20px',
                    color: '#fff',
                }}>
                    {data.ipoCompaniesAmount}
                </div>
            </div>
            <div style={{
                width: window.screen.width < 1920 ? '180px' : '236px',
                height: '46px',
                float: 'left',
                fontSize: window.screen.width < 1920 ? '8px' : '16px',
                color: '#8fc8ff',
                marginLeft: window.screen.width < 1920 ? '10px' : '21px',
                background: `url(${Pic2})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}风险企业数量&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: window.screen.width < 1920 ? '8px' : '2px',
                    paddingRight: '15px',
                    fontSize: window.screen.width < 1920 ? '8px' : '20px',
                    color: '#fff',
                }}>
                    {data.ipoCompaniesRiskAmount}
                </div>
            </div>
            <div style={{
                width: window.screen.width < 1920 ? '200px' : '280px',
                height: '52px',
                float: 'left',
                fontSize: window.screen.width < 1920 ? '8px' : '16px',
                color: '#8fc8ff',
                marginLeft: window.screen.width < 1920 ? '10px' : '15px',
                background: `url(${Pic3})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <div style={{
                    float: 'left',
                    paddingTop: '15px',
                    paddingLeft: '15px',
                }}>
                    {provinces && provinces.length ? provinces.join().replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "") : '全国'}风险企业占比(%)&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div style={{
                    paddingTop: window.screen.width < 1920 ? '8px' : '2px',
                    paddingRight: '15px',
                    fontSize: window.screen.width < 1920 ? '8px' : '20px',
                    color: '#fff',
                }}>
                    {data.ipoCompaniseRiskPercent}
                </div>    
            </div>
        </div>
    );
}
