/**
 * @author Ray
 * @desc 入库企业分析-基础信息-作品著作
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { PaperWorksColumns } from './columns';
import { paperWorks } from './service';
import { isNotEmpty } from '../../../../..//utils/utils';
import Title from '../../../../../common/title';

export default function PaperWorks({ match }) {
    // TODO 加上查询条件
    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <>
            <Title level={3} text="作品著作" />
            <Table
                query={query}
                getData={paperWorks}
                parseData={res => (isNotEmpty(res) ? res?.data : [])}
                columns={PaperWorksColumns}
                locale={{
                    emptyText:
                        '未发现企业的作品著作记录。不排除存在未公示或时间相对滞后的情况。'
                }}
            />
        </>
    );
}
