"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getTarget = getTarget;
function getTarget() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var target = []; // 关系探寻可能多个目标节点.
    var nodes = state.nodes || state.node || {}; // 兼容 state.nodes = [node1, ...], state.node = {id: node, ...}
    // nodes 可能是数组或对象
    for (var key in nodes) {
        if (nodes[key].isTarget) {
            target.push(nodes[key]);
        }
    }

    if (target.length === 0) {
        // 兼容现有调用程序
        target = {};
    } else if (target.length === 1) {
        target = target[0];
    }
    return target;
}