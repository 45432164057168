import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  券商过会率
*/
export function brokerIPOPassHistogramLine(params) {
    return request(`${API.V1}/cockpit/domesticStock/leftForth/brokerIPOPassHistogramLine`, params)
}

//获取券商信息
export function listOfBroker() {
    return request(`${API.V1}/cockpit/domesticStock/listOfBroker`)
}