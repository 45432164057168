import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

// export function getApplyIndexInfo() {
//     // 获取上市奖励首页的数据
//     return request(
//         `${API.V1}/policypractice/user/apply/index`,
//         {},
//         {
//             method: 'get'
//         }
//     );
// }

/**
 * @desc 获取中介公司详情页的头部详情
 * @param qyxxId
 * @param companyName
 */
export function getTopCompanyInfo(qyxxId, companyName) {
    return request(`${API.V1}/investbank/intermediary/queryHeadInfo`, {
        qyxxId: qyxxId === '--' ? undefined : qyxxId,
        companyName
    });
}
