import request from 'utils/fetch';
import {API} from 'constants/enum';

/**
 * @description 展示融资情况
 * @param {*} code	企业信用代码
 */
export function showFinancing(code) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/showFinancing`,
        {code},
        {method: 'POST'}
    );
}

/**
 * @description 添加基础信息
 * @param {*} code  企业信用代码
 * @param {*} industryLabel  行业标签
 * @param {*} industrySubclass	经济行业分类小类
 * @param {*} srcIndustry	证监会所属行业
 */
export function addbaseInfo(
    code,
    industryLabel,
    industrySubclass,
    srcIndustry,
    plate
) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/addBaseInfo`,
        {
            code,
            industryLabel,
            industrySubclass,
            srcIndustry,
            plate: plate && plate.toString()
        },
        {
            method: 'POST'
        }
    );
}

/**
 *
 * @description 添加融资情况
 * @export
 * @param {*} code 企业信用代码
 * @param {*} date	日期
 * @param {*} financingMoney	融资金额
 * @param {*} financingRotation	融资轮次
 * @param {*} investors	投资方
 * @param {*} valuation	估值
 * @returns
 */
export function addFinancing(
    code,
    date,
    financingMoney,
    financingRotation,
    investors,
    valuation
) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/addFinancing`,
        {
            code,
            date,
            financingMoney,
            financingRotation,
            investors,
            valuation
        },
        {
            method: 'GET'
        }
    );
}

/**
 * @description 根据时间获取不同时间段的表数据
 * @param {string} code
 * @param {string} startYear
 * @param {string} endYear
 * @param {number} reportType 文件类型 2、利润表 3、资产债务表 4、现金流
 */
export function findFinance({code, startYear, endYear, reportType}) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/findFinance`,
        {
            code,
            startYear,
            endYear,
            reportType
        },
        {
            method: 'POST'
        }
    );
}

// 科创评分详情
export function innovateDetail(data) {
    return request(
        `${API.V1}/auth/tech/innovate/detail`,
        {...data},
        {method: 'GET'}
    );
}
/**
 * @desc  经营信息填报
 * @param params
 */
export function operatingDataAdd(data = {}) {
    return request(
        `${API.V1}/investbank/company/jyxx/add`,
        data,
        // { companyJyxxs: [...params] },
        {method: 'POST'}
    );
}

/**
 * @desc  经营信息填报
 * @param params
 */
export function operatingDataUpdate(data = {}) {
    return request(`${API.V1}/investbank/company/jyxx/update`, data, {
        method: 'POST'
    });
}
// 科创属性填写
export function innovateUpdate(data) {
    return request(
        `${API.V1}/auth/tech/innovate/update`,
        {...data},
        {
            method: 'POST'
        }
    );
}
/**
 * @desc  经营信息填报
 * @param params
 */
export function otherDataAdd(params) {
    return request(
        `${API.V1}/investbank/company/jyxx/other/add`,
        {...params},
        {method: 'post'}
    );
}
/**
 * @desc  经营信息填报
 * @param params
 */
export function otherDataUpdate(params) {
    return request(
        `${API.V1}/investbank/company/jyxx/other/update`,
        {...params},
        {method: 'post'}
    );
}
/**
 * @desc 获取经营信息、客户、供应商填报信息
 * @param creditCode
 * @param type
 */
// 查看 /consultant/service.js
// export function getOperatingData(creditCode, type) {
//     return request(`${API.V1}/company/jyxx/list`, {
//         creditCode,
//         type
//     });
// }

/**
 * @desc  内控规范和创始团队获取
 * @param params
 */
export function getByCreditCode(params) {
    return request(`${API.V1}/auth/company/data/get`, {...params});
}

/**
 * @desc  内控规范和创始团队填写
 * @param params
 */
export function teamUpdate(params) {
    return request(
        `${API.V1}/auth/company/data/update`,
        {...params},
        {method: 'post'}
    );
}
