/**
 * @desc 使用用户的权限来限制访问
 */

import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../common/globalStore/createProvider';

export default function useAuth(permissionKey) {
    const [isAuth, setIsAuth] = useState(false);
    const gs = useContext(GlobalContext);

    useEffect(() => {
        const pks = gs.user?.resources?.map(r => r.id) || [];
        if ([503, 502, 501, 494,490, 489, 488,179,43].includes(permissionKey)) {
            setIsAuth(true);
        } else {
            setIsAuth(pks.includes(permissionKey));
        }
    }, []);

    return isAuth;
}

export function Permission({ children, permission }) {
    const isAuthed = useAuth(permission);
    return isAuthed ? children : null;
}
