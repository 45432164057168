/**
 * @author Ray
 * @description 重写一下财务信息的tab。以前那个太撇了
 */

import React, { useContext, useState } from "react";
import { Button, Tabs, Icon } from "antd";
import styles from "./styles.scss";
import Profit from "./profitTable";
import Asset from "./assetTable";
import Cash from "./cashFlowTable";
import Refresh from "./refreshBtn";

const { TabPane } = Tabs;

Financial.propTypes = {};

Financial.defaultProps = {
  readOnly: true,
  onRefreshClick: () => {}
};

export default function Financial(props) {
  const [signal, updateSignal] = useState(undefined);
  const { status, readOnly } = props;

  function goTo(e) {
    e.preventDefault();
    e.stopPropagation();
    // 设置页面跳转的路径到缓存
    // gs.setLocalLocation('/companyInformation/financial');
    // 打开新的窗口
    setTimeout(
      () =>
        window.open(
          location.protocol +
            "//" +
            location.host +
            "/companyInformation/financial",
          "_blank"
        ),
      0
    );
  }

  function refresh() {
    props.onRefreshClick(1);
    updateSignal(new Date().getTime());
  }

  return (
    <div className={styles.root}>
        {readOnly ? 
          <div class={styles.innerTitle}>
            <Refresh type="primary" onClick={refresh}>
              刷新
            </Refresh>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a target="_blank" onClick={goTo}>
              <Button type="primary" icon="dollar">
                财务数据填报
              </Button>
            </a>
          </div> : null
        }
      <Tabs>
        <TabPane tab="利润表" key="1">
          <Profit {...props} signal={signal} />
        </TabPane>
        <TabPane tab="资产负债表" key="2">
          <Asset {...props} signal={signal} />
        </TabPane>
        <TabPane tab="现金流量表" key="3">
          <Cash {...props} signal={signal} />
        </TabPane>
      </Tabs>
    </div>
  );
}
