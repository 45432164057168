/**
 * @desc  柱形图
 */
import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import Radios from './radio';
import { Empty } from 'antd';
import { neeqIpoCompanyCount, neeqIpoCompanyCountOfIndustry, neeqIpoCompanyCountOfArea } from './service';

global.BarCharts = null;

export default function BarCharts() {
    const [type, setType] = useState(1);
    const [data, setData] = useState([]);


    const radiosData = [
        { name: '股票发行企业家数趋势', value: 1 },
        { name: '股票发行行业分布情况', value: 2 },
        { name: '股票发行地域统计', value: 3 },
    ];
    
    useEffect(() => {
        //请求股票发行企业家数量数据
        type == 1 ? neeqIpoCompanyCount().then(({ success, data }) => {
            if (success == true) {
              if (global.BarCharts == null) {
                 global.BarCharts = new Chart({
                    container: 'Line11BarCharts',
                    autoFit: true,
                    height: 500,
                });

                global.BarCharts.data(data);
                global.BarCharts.scale('amount', {
                    nice: true,
                });
                
                global.BarCharts.tooltip({
                    showMarkers: false
                });
                global.BarCharts.interaction('active-region');

                global.BarCharts.interval()
                .position('item*amount')
                .label('amount')
                global.BarCharts.render();
              } else {
                global.BarCharts.clear();
                global.BarCharts.changeData(data);
                
                global.BarCharts.scale('amount', {
                    nice: true,
                });
                
                global.BarCharts.tooltip({
                    showMarkers: false
                });
                global.BarCharts.interaction('active-region');
        
                global.BarCharts.interval()
                .position('item*amount')
                .label('amount')
                global.BarCharts.render();
              }
            } else {
                <Empty />
            }
        }) : 
        //请求股票发行行业分布数据
        type == 2 ? neeqIpoCompanyCountOfIndustry().then(({ success, data }) => {
            if (success == true) {
                global.BarCharts.clear();
                global.BarCharts.changeData(data);
                
                global.BarCharts.scale('amount', {
                    nice: true,
                });
                
                global.BarCharts.tooltip({
                    showMarkers: false
                });
                global.BarCharts.interaction('active-region');
        
                global.BarCharts.interval()
                .position('item*amount')
                .label('amount')
                global.BarCharts.render();
            } else {
                <Empty />
            }
        }) : 
        //请求股票发行地域分布数据
        type == 3 ? neeqIpoCompanyCountOfArea().then(({ success, data }) => {
            if (success == true) {
                global.BarCharts.clear();
                global.BarCharts.changeData(data);
                
                global.BarCharts.scale('amount', {
                    nice: true,
                });
                
                global.BarCharts.tooltip({
                    showMarkers: false
                });
                global.BarCharts.interaction('active-region');
        
                global.BarCharts.interval()
                .position('item*amount')
                .label('amount')
                global.BarCharts.render();
            } else {
                <Empty />
            }
        }) : <Empty />
    }, [type])




    
    return (
        <div className={ styles.barChart }>
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <div id='Line11BarCharts' className={ styles.line11BarCharts }></div>
        </div>
    )
}
