/**
 * @author Ray
 */
import React from 'react';
import { Icon } from 'antd';
import styles from './styles.scss';
import { API } from 'constants/enum';
import { download } from 'utils/fetch';

export default class extends React.Component {
    static defaultProps = {
        fallback: '--',
        multi: false
    };

    handleClick = value => () => {
        let { action } = this.props;

        try {
            value = JSON.parse(value);
        } catch (error) {}

        if (!value) {
        } else {
            download(
                action || `${API.V1}/policypractice/file/download`,
                {
                    id: value.id,
                    name: value.name || value.fileName,
                    fileId: value.id
                },
                {
                    method: 'GET'
                }
            );
        }
    };

    render() {
        let { value, multi } = this.props;

        if (typeof value === 'string') {
            try {
                value = JSON.parse(value);
            } catch (e) {}
        }

        return value ? (
            <div className={styles.downloadRoot}>
                {multi ? (
                    value.map(v => (
                        <div>
                            <Icon
                                className={styles.fileIcon}
                                theme="twoTone"
                                type="file"
                                twoToneColor="#2562DC"
                            />
                            <a onClick={this.handleClick(v)}>
                                {v.name || v.fileName}
                            </a>
                        </div>
                    ))
                ) : (
                    <div>
                        <Icon
                            className={styles.fileIcon}
                            theme="twoTone"
                            type="file"
                            twoToneColor="#2562DC"
                        />
                        <a onClick={this.handleClick(value)}>
                            {value.name || value.fileName}
                        </a>
                    </div>
                )}
            </div>
        ) : (
            this.props.fallback
        );
    }
}
