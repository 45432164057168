/**
 * @author Ray
 * @desc 中介机构分析-中介机构评价
 */

import React, { useContext, useEffect, useState } from 'react';
import Title from '../../../../common/title';
import RiskEvaluation from './riskEvaluation';
import AfterMeeting from './afterMetting';
import styles from './styles.scss';
import { Divider, Typography, Empty } from 'antd';
import LegalAnalysis from '../chart/legalAnalysis';
import LostTrustee from './lostTrustee';
import ExecutedPerson from './executedPerson';
import Referee from './referee';
import RegulatoryAnnounce from './regulatoryAnnounce';
import BusinessAbility from './businessAbility';
import { queryGhCount } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

const elements = [
    AfterMeeting,
    BusinessAbility,
    LegalAnalysis,
    LostTrustee,
    ExecutedPerson,
    Referee,
    RegulatoryAnnounce
];

function convertToPercent(number) {
    if (typeof number !== 'number') return number;
    return String((100 * number).toFixed(2));
}

export default function IntermediaryEvaluation(props) {
    const gs = useContext(GlobalContext);
    const [query] = useState({ companyName: props.match.params.companyName });
    const [ghCount, setGhCount] = useState({});
    const [riskData, setRiskData] = useState({});

    useEffect(
        function() {
            queryGhCount(query).then(res => {
                if (isNotEmpty(res) && isNotEmpty(res.data)) {
                    const {
                        zjjgHfxScore,
                        zjjgHgxScore,
                        zjjgTotalScore,
                        zjjgYwnlScore
                    } = res.data;
                    setGhCount(res.data);
                    setRiskData({
                        zjjgHfxScore,
                        zjjgHgxScore,
                        zjjgTotalScore,
                        zjjgYwnlScore
                    });
                }
            });
        },
        [query]
    );

    return (
        <div className={styles.root}>
            <Title level={1} text="中介机构评价" />
            <Divider />
            {gs.isAdvanceUser ||
            gs.isVIPUser ||
            gs.isQiYuan ||
            gs.isGOV ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                <>
                    <Title level={2} text="服务能力评价" />
                    <RiskEvaluation {...props} data={riskData} />
                    <Title level={2} text="业务能力" />
                    <Divider />
                    <Title level={3} text="近三年过会情况" />
                    <Typography.Text style={{ fontSize: 16, color: '#2082ED' }}>
                        {`总体过会情况${ghCount.totalDlcg3years ||
                            0}/${ghCount.totalDlsl3years ||
                            0}（${convertToPercent(ghCount.totalGhl3years) ||
                            0}%）：
                    ${ghCount.totalZbdlcg3years ||
                        0}/${ghCount.totalZbdlsl3years || 0}（${convertToPercent(
                            ghCount.totalZbGhl3years
                        ) || 0}%）家上交所主板；
                    ${ghCount.totalKcdlcg3years ||
                        0}/${ghCount.totalKcdlsl3years || 0}（${convertToPercent(
                            ghCount.totalKcGhl3years
                        ) || 0}%）家上交所科创板；
                    ${ghCount.totalZxdlcg3years ||
                        0}/${ghCount.totalZxdlsl3years || 0}（${convertToPercent(
                            ghCount.totalZxGhl3years
                        ) || 0}%）家深交所中小板；
                    ${ghCount.totalCydlcg3years ||
                        0}/${ghCount.totalCydlsl3years || 0}（${convertToPercent(
                            ghCount.totalCyGhl3years
                        ) || 0}%）家深交所创业板；
                    ${ghCount.totalSzZbdlcg3years ||
                        0}/${ghCount.totalSzZbdlsl3years || 0}（${convertToPercent(
                            ghCount.totalSzZbGhl3years
                        ) || 0}%）家深交所主板；`}
                    </Typography.Text>
                    <br/><br/>
                    {elements.map((E, index) => {
                        return (
                            <React.Fragment key={index}>
                                <E {...props} />
                            </React.Fragment>
                        );
                    })}
                </>
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
