import React, { useState, useEffect } from 'react';
import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { industryIPOAmount } from './service';
import { Empty } from 'antd';
/**
 * tab1	各行业累计上市公司家数
 * tab2	各行业新增上市公司
 */
global.ListCompnayAmountBar = null;

export default function ListCompnayAmountBar({ address }) {
  
   const [data, setData] = useState([]);

   useEffect(() => {
    industryIPOAmount({ area: address }).then(({ success, data }) => {
        if (success == true) {
           if (global.ListCompnayAmountBar == null) {
                  global.ListCompnayAmountBar = new Chart({
                    container: 'ListCompnayAmountBar',
                    autoFit: true,
                    padding: [10, 0, 0, 190],
                  });
                  global.ListCompnayAmountBar.data(data);
                  global.ListCompnayAmountBar.axis('score', false);
                  global.ListCompnayAmountBar.coordinate().transpose();
                  global.ListCompnayAmountBar.facet('mirror', {
                    fields: ['item'],
                    transpose: true,
                    title: {
                      offsetY: -3,
                      style: {
                        fontSize: 12,
                        fill: '#000',
                      }
                    },
                    eachView: (view, facet) => {
                      const facetIndex = facet.columnIndex;
                      if (facetIndex === 0) {
                        view.axis('industry', {
                          position: 'top',
                          tickLine: {
                            alignTick: false,
                            length: 0
                          },
                          label: {
                            style: {
                              fill: '#fff',
                            },
                          },
                          line: null,
                        });
                      } else {
                        view.axis('industry', false);
                      }
                      const color = (facetIndex === 0) ? '#1890ff' : '#2fc25b';
                      
                      view.interval().position('industry*score').color(color)
                        .size(18)
                        .label('score', (val) => {
                          let offset = (facetIndex === 1) ? -1 : 1;
                          let shadowBlur = 2;
                          let textAlign = (facetIndex === 1) ? 'end' : 'start';
                          let fill = 'white';
                          if (val < 40 && facetIndex === 1) {
                            offset = 4;
                            textAlign = 'start';
                            shadowBlur = 0;
                          }
                          if (val < 1500 && facetIndex === 0) {
                            offset = -4;
                            textAlign = 'end';
                            shadowBlur = 0;
                          }
                          return {
                            offset,
                            style: {
                              fill,
                              stroke: null,
                              shadowBlur,
                              shadowColor: 'rgba(0, 0, 0, .45)',
                              textAlign
                            }
                          };
                        });
                    }
                  });
                  global.ListCompnayAmountBar.tooltip(false);
                  global.ListCompnayAmountBar.render();
           } else {
              <Empty />
           }
        } else {
            <Empty />
        }
    })
   }, [address])


   return (
      <div id='ListCompnayAmountBar' className={ styles.ListCompnayAmountBar }></div>
   )

}


// export default class ListCompnayAmountBar extends PureComponent {
//   componentDidMount() {
    
//     const data = [
//       { industry: '酒、饮料和精制茶制造业', item: 'amount', amount: 300 },
//       { industry: '酒、饮料和精制茶制造业', item: 'increase', amount: 8 },
//       { industry: '烟草制品业', item: 'amount', amount: 360 },
//       { industry: '烟草制品业', item: 'increase', amount: 5 },
//       { industry: '纺织服装、服饰业', item: 'amount', amount: 370 },
//       { industry: '纺织服装、服饰业', item: 'increase', amount: 12 },
//       { industry: '化学原料及化学制品制造业', item: 'amount', amount: 290 },
//       { industry: '化学原料及化学制品制造业', item: 'increase', amount: 22 },
//       { industry: '通用设备制造业', item: 'amount', amount: 238 },
//       { industry: '通用设备制造业', item: 'increase', amount: 6 },
//       { industry: '建筑装饰和其他建筑业', item: 'amount', amount: 116 },
//       { industry: '建筑装饰和其他建筑业', item: 'increase', amount: 9 },
//     ];
    
//     const chart = new Chart({
//       container: 'ListCompnayAmountBar',
//       autoFit: true,
//       height: 150,
//       padding: [0, 0, 0, 80],
//     });
//     chart.data(data);
    
//     chart.axis('amount', false);
//     chart.coordinate().transpose();
//     chart.facet('mirror', {
//       fields: ['item'],
//       transpose: true,
//       showTitle: false,
//       eachView: (view, facet) => {
//         const facetIndex = facet.columnIndex;
//         if (facetIndex === 0) {
//           view.axis('industry', {
//             position: 'top',
//             tickLine: {
//               alignTick: false,
//               length: 0
//             },
//             line: null,
//           });
//         } else {
//           view.axis('industry', false);
//         }
//         const color = (facetIndex === 0) ? '#1890ff' : '#2fc25b';
//         view.interval().position('industry*amount').color(color)
//           .size(18)
//           .label('amount', (val) => {
//             let offset = (facetIndex === 1) ? -4 : 4;
//             let shadowBlur = 2;
//             let textAlign = (facetIndex === 1) ? 'end' : 'start';
//             let fill = 'white';
//             if (val < 15) {
//               offset = (facetIndex === 1) ? 4 : -4;
//               textAlign = (facetIndex === 1) ? 'start' : 'end';
//               shadowBlur = 0;
//             }
//             return {
//               offset,
//               style: {
//                 fill,
//                 stroke: null,
//                 shadowBlur,
//                 shadowColor: 'rgba(0, 0, 0, .45)',
//                 textAlign
//               }
//             };
//           });
//       }
//     });
//     chart.tooltip(false);
//     chart.render();
//   }
    
//   render() {
//     return (
//       <div id='ListCompnayAmountBar' className={ styles.ListCompnayAmountBar }></div>
//     )
//   }
// }
