/**
 * @desc 入库企业分析-基础信息-司法诉讼
 * @author Ray
 */

import React, { Fragment, useContext, useState } from 'react';
import Title from '../../../../../common/title';
import JudicialInsuranceAnalysis from './judicialInsuranceAnalysis';
import ExecutedPersonInformation from './executedPersonInformation';
import TrustExecutedPersonInformation from './trustExecutedPersonInformation';
import BusinessAnomalies from './businessAnomalies';
import AdministrativePenaltyInformation from './administrativePenaltyInformation';
import RefereePaperwork from './refereePaperwork';
import AnnouncementSession from './announcementSession';
import CourtAnnouncement from './courtAnnouncement';
import Case from './Case';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

const Elements = [
    JudicialInsuranceAnalysis,
    ExecutedPersonInformation,
    TrustExecutedPersonInformation,
    BusinessAnomalies,
    AdministrativePenaltyInformation,
    RefereePaperwork,
    AnnouncementSession,
    CourtAnnouncement,
    Case
];

const titles = [
    '司法风险分析',
    '被执行人信息',
    '失信被执行人信息',
    '经营异常信息',
    '行政处罚信息',
    '裁判文书',
    '开庭公告',
    '法院公告',
    '立案调查'
];

export default function JudicialProceeding(props) {
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );
    return (
        <div>
            {isOwnedCompany ||
            (!isOwnedCompany && gs.isEnterprise && gs.isVIPUser) ||
            gs.isIntermediary ||
            gs.isQiYuan ||
            gs.isGOV ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                Elements.map((Element, index) => (
                    <Fragment key={index}>
                        <Title level={3} text={titles[index]} />
                        <Element {...props} />
                    </Fragment>
                ))
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
