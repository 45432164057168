/**
 * @author Ray
 * @description antd的form表单中可使用的文本上传组件，默认不开启模板下载
 */
import React from 'react';
import { Upload, message, Modal } from 'antd';
import styles from './styles.scss';
import { API } from '../../constants/enum';
import DownloadFile from '../downloadFile';
import { TemplateButton, UploadButton } from '../buttons';
import { download } from '../../utils/fetch';
import { isEmpty, isNotEmpty } from '../../utils/utils';
import { uploadFile } from '../../utils/fetch';
import { func } from 'prop-types';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class UploadFile extends React.Component {

    state = {
        file: [],
        isUploadedShow: true,
        fileList: undefined,
        defaultFileList: '',
        previewVisible: false,
        previewImage: '',
        previewTitle: ''
    };

    //  图片预览
    handlePreview = async (file) => {
        
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        
        this.setState({
            previewVisible: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    }

    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }

    // 文件上传
    uploadStatusChange = ({ file, fileList }) => {
        const { multi, maxFileCount } = this.props;
        const { status } = file;
        let newFiles;

        if (status === undefined) {
            return;
        }

        newFiles = fileList.slice(0);
        if (multi) {
            if (fileList.length >= maxFileCount) {
                newFiles = newFiles.slice(0, -1);
                newFiles.push(file);
            }
            newFiles = newFiles.map(function(file) {
                if (file.response) {
                    file.id = file?.response?.data?.id || file?.response?.data;
                }
                return file;
            });
        } else {
            newFiles = newFiles.splice(-1).map(function(file) {
                if (file.response) {
                    file.id = file?.response?.data?.id || file?.response?.data;
                }

                return file;
            });
        }

        this.setState({
            fileList: isEmpty(newFiles) ? undefined : newFiles,
            isUploadedShow: true
        });

        if (status === 'done') {
            if (file?.response?.success) {
                const { fileUrl } = file.response.data;
                let output;

                if (multi) {
                    output = newFiles.map(function(f) {
                        const { id, name, uid } = f;
                        return { id, name, uid };
                    });
                } else {
                    const { name, id, uid } = newFiles[0];
                    output = { name, id, uid, url: fileUrl };
                }

                this.props.onChange(
                    fileUrl
                        ? JSON.stringify(output)
                        : Array.isArray(output)
                        ? output.map(o => o.id)
                        : output.id
                );
            } else {
                message.error(file?.response?.message);
                let output = undefined;

                if (multi) {
                    output = newFiles
                        .filter(f => f.uid !== file.uid)
                        .map(function(f) {
                            const { id, name, uid } = f;
                            return { id, name, uid };
                        });
                }

                this.setState(
                    {
                        isUploadedShow: false,
                        fileList: output
                    },
                    () => {
                        this.props.onChange(
                            Array.isArray(output)
                                ? output.map(o => o.id)
                                : output
                        );
                    }
                );
            }
        } else if (status === 'removed') {
            let output = newFiles.map(function(f) {
                const { id, name } = f;
                return {
                    // url,
                    id,
                    name
                };
            });
            this.props.onChange(multi ? output.map(o => o.id) : undefined);
        } else if (status === 'error') {
            message.error('网络故障，文件上传失败！');
            // let a = newFiles;
            //
            // a = multi
            //     ? newFiles.filter(f => {
            //           return f.status !== 'error';
            //       })
            //     : // .map(function(f) {
            //       //     const { url, id, name, uid } = f;
            //       //     return { url, id, name, uid };
            //       // })
            //       undefined;
            // this.setState(
            //     {
            //         isUploadedShow: false,
            //         fileList: a
            //     },
            //     () => {
            //         this.props.onChange(
            //             Array.isArray(a) ? a.map(o => o.id) : a
            //         );
            //     }
            // );
        }
    };

    beforeUpload = file => {
        let isOutBoundary = false;
        let isNotAcceptType = false;
        const { fileList } = this.state;
        const { multi, maxFileCount, accept = '' } = this.props;
        let allowTypesArray = accept.split(',');
        let uploadFileType = file.name.split('.');

        if (
            isNotEmpty(allowTypesArray) &&
            !allowTypesArray.includes('.' + uploadFileType.slice(-1)[0].toLowerCase())
        ) {
            message.error(`不支持上传此文件格式，支持文件格式（${accept}）！`);
            isNotAcceptType = true;
        }
        if (multi && fileList?.length >= maxFileCount) {
            message.error(
                `上传文件数超过限制，最多上传${maxFileCount}个文件！`
            );
            isOutBoundary = true;
        }

        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isLt5M) {
            message.error('文档大小超过5m！');
        }

        return isLt5M && !isOutBoundary && !isNotAcceptType;
        // isCorrectType &&
    };

    handleRemove = () => {
        //  TODO delete file from server
        const { multi } = this.props;
        if (!multi) {
            this.props.onChange(undefined);
        }
    };

    handleTemplateDownload = () => {
        return () => {
            const {
                templateName,
                templateAction,
                templateQuery = {}
            } = this.props;

            download(
                templateAction || `${API.V1}/policypractice/file/downloadFile`,
                {
                    file: templateName,
                    ...templateQuery
                },
                {
                    method: 'GET'
                }
            );
        };
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value === prevState.defaultFileList) {
            return null;
        }

        return {
            defaultFileList:
                typeof nextProps.value === 'string' && !nextProps.noFileList
                    ? JSON.parse(nextProps.value)
                    : nextProps.value
        };
    }

    handleFileUpload(upload) {
        uploadFile(
            upload.action,
            {
                fileName: upload.file.name,
                ...upload.data,
                ...upload.params,
                file: upload.file
            },
            upload.onProgress,
            upload.onError,
            upload.onSuccess
        );
    }

    render() {
        let { isUploadedShow, fileList, defaultFileList, previewVisible, previewImage } = this.state;
        let {
            fileType,
            templateUrl,
            action,
            query,
            value,
            preview,
            accept,
            noFileList,
            downloadAction,
            prefix,
            disabled
        } = this.props;
        let params = { fileType };

        let usedFileList = [];

        if (isEmpty(fileList) && isNotEmpty(defaultFileList)) {
            if (Array.isArray(defaultFileList)) {
                usedFileList = defaultFileList.map(file => {
                    if (typeof file !== 'number') {
                        return {
                            uid: file.id,
                            name: file.fileName,
                            status: 'done',
                            // url: file.fileUrl,
                            id: file.id
                        };
                    }
                });
                if (usedFileList[0] === undefined) {
                    usedFileList = fileList;
                }
            } else {
                if (typeof defaultFileList === 'number') {
                    usedFileList = fileList;
                } else {
                    usedFileList = [
                        {
                            uid: defaultFileList.id,
                            name:
                                defaultFileList.fileName ||
                                defaultFileList.name,
                            status: 'done',
                            url: prefix + defaultFileList.url,
                            id: defaultFileList.id
                        }
                    ];
                }
            }
        } else {
            usedFileList = fileList;
        }

        return (
            <div className={styles.uploadRoot}>
                {preview ? (
                    <DownloadFile
                        multi={this.props.multi}
                        fallback={'--'}
                        value={value}
                        action={downloadAction}
                    />
                ) : (
                    <>
                        {this.props.showTemplateDownload && (
                            <>
                                <TemplateButton
                                    text="模板下载"
                                    onClick={this.handleTemplateDownload(
                                        templateUrl
                                    )}
                                />
                                &nbsp;&nbsp;
                            </>
                        )}
                        <Upload
                            disabled={disabled}
                            className={'filedownload'}
                            withCredentials
                            supportServerRender
                            multiple={false}
                            showUploadList={isUploadedShow && !noFileList}
                            beforeUpload={this.beforeUpload}
                            defaultFileList={[]}
                            fileList={usedFileList}
                            listType='picture'
                            onChange={this.uploadStatusChange}
                            onPreview={this.handlePreview}
                            accept={accept}
                            action={
                                action
                                    ? action
                                    : `${API.V1}/policypractice/file/upload`
                            }
                            data={{
                                ...params,
                                ...query
                            }}
                            onRemove={this.handleRemove}
                            customRequest={this.handleFileUpload}
                        >
                            {
                                usedFileList && usedFileList.length >= 1 ? null :
                                disabled ? '--' :
                                <UploadButton text={this.props.text} />
                            }
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </>
                )}
            </div>
        );
    }
}

UploadFile.defaultProps = {
    showTemplateDownload: false,
    onTemplateDownload: () => {},
    onChange: () => {},
    fileType: 5,
    allowMIME: [],
    templateUrl: '',
    message: '',
    action: '',
    query: {},
    multi: false,
    maxFileCount: 5
};
