import { useState, useEffect } from 'react';
import { judicialAPI } from '../service';
import { litigationCol, punishCol, executorCol, noExecutorCol, caseCol, businessAnomaliesColumns, announcementSessionColumns, courtAnnouncementColumns } from './columns';

export default function({qyxxId, stockCode, year}){

    const [barOption, setBarOption] = useState({}); // 柱状图
    const [tableData, setTableData] = useState([]); // 表格数据
    const [loading, setLoading] = useState({}); 
    const [gaugeOptions, setGaugeOptions] = useState(null); // 仪表盘

    // 获取司法风险数据
    function getJudicial() {
        if(!qyxxId || !stockCode){
            return;
        }
        judicialAPI({
            qyxxId,
            stockCode,
            year
        }).then(res => {
            if(res.success){
                const { shifaRiskScore, ...others } = res.data;
                getTables(others);
                getGaugeOptions(shifaRiskScore);
            }
        }).finally(()=>{
            setLoading(false);
        })
    };

    // 表格数据
    function getTables({litigations, punishes, executeds, dishonests, investigations}){
        const tables = [
            { name: "被执行人", columns: executorCol, data: executeds },
            { name: "失信被执行人", columns: noExecutorCol, data: dishonests },
            { name: "裁判文书", columns: litigationCol, data: litigations },
            { name: "经营异常", columns: businessAnomaliesColumns, data: [] },
            { name: "开庭公告", columns: announcementSessionColumns, data: [] },
            { name: "法院公告", columns: courtAnnouncementColumns, data: [] },
            { name: "处罚相关", columns: punishCol, data: punishes },
            { name: "立案调查", columns: caseCol, data: investigations }
        ];
        getBarOptions(tables);
        setTableData(tables);
    }

    function getBarOptions(data){
        const colors = ["#59DF8F", "#2082ED", "#FB7B39"];;
        const options = {
            tooltip: {
                trigger: 'item',
                formatter: function (v) {
                    const { value, name, color } = v; 
                    return `<div class="tooltipBox">
                                ${name}:
                                <span style = "color:${color}"><b>${value}</b></span>
                            <div>`
                    }
            },
            grid: {
                top: 70,
                left: 110,
                bottom: 60
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: '#333'
                }
            },
            yAxis: {
                data: data.map(ele => ele.name),
                type: 'category',
                axisLabel: {
                    color: '#333'
                }
            },
            series: [
                {
                    type: 'bar',        
                    itemStyle: {
                        barBorderRadius: 10
                    },
                    data: data.map( (ele, i) => {
                        let color ='';
                        if(i === 0 ){
                            color = colors[0];
                        }else if(i === data.length - 1 ){
                            color = colors[2];
                        }else{
                            color = colors[1];
                        }
                        return { name: ele.name, value: ele.data ? ele.data.length : 0, itemStyle: {color} };
                    })
                }
            ]
        }
        setBarOption(options);
    }

    function getGaugeOptions(score){
        const options = {
            series: [
                {
                    type: 'gauge',
                    data: [{value: score.toFixed(2), name: '风险总分'}]
                }
            ]
        };
        setGaugeOptions(options);
    }

    useEffect(()=>{
        getJudicial();
    }, [year])

    return { gaugeOptions, loading, tableData, barOption }
}