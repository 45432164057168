/**
 * @author hjie
 * 我的学习
 */
import { withRouter, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import RiskTitle from 'common/riskTitle';
import { Input, Table, InputNumber, Select, Button } from 'antd';
import { BLOCKTYPE, RISKTYPE } from 'constants/common';
import { risksort, bjDistrict,areEarnings } from '../service';
import { isNumber, transferStringNull } from 'utils/utils';
const { Group: InputGroup } = Input;
const { Option } = Select;

// 获取排序的表格类目
function getRiskColumn() {
    return Object.keys(RISKTYPE).map(ele => {
        const { name, key } = RISKTYPE[ele];
        return {
            title: name,
            dataIndex: key,
            key,
            type: ele,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a[key] - b[key],
            render: (text) => isNumber(text) ? text.toFixed(2) : '--'
        }
    })
}

function getColumns(pageNum, pageSize, props) {
    return [
        {
            title: '序号',
            dataIndex: 'qyxxId',
            key: 'qyxxId',
            render: (text, item, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            },
            width: '5%'
        },
        {
            title: '上市公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text,record) => <Link to = {`./detail/risk/${record.companyName}/${record.qyxxId}`}>{transferStringNull(text)}</Link>
            // width: '14%'
        },
        {
            title: '法定代表人',
            dataIndex: 'frname',
            key: 'frname',
            render: transferStringNull
        },
        {
            title: '所属区',
            dataIndex: 'district',
            key: 'district',
            render: transferStringNull
        },
        {
            title: '上市板块',
            dataIndex: 'block',
            key: 'block',
            render: (text) => {
                return BLOCKTYPE[text] || '--';
            },
        },
        {
            title: '财报日期',
            dataIndex: 'year',
            key: 'year',
           
        },
        ...getRiskColumn(),
        {
            title: '关注状态',
            dataIndex: 'isFocus',
            key: 'isFocus',
            render: (text) => text == 1 ? '已关注' : '未关注'
        }
    ];
}

export default withRouter(function (props) {
    const [dataSource, setDataSource] = useState({ data: [], total: 0 });
    const [pageInfo, setPageInfo] = useState({ pageNum: 1, pageSize: 10 });
    const [params, setParams] = useState({
        block: '', // 上市板块
        companyName: '', // 公司名称
        point: '', // 分数
        riskType: '', // 风险类别
        type: '', // 大于或小于 1=大于 2=小于
        districtCode: '', // 地区
        year:''  //财报日期
    });
    const [sortInfo, setSortInfo] = useState({
        sortType: 1, // 排序类型	
        upset: 2 // 1=升序 2=降序
    });
    const [areaInfo, setAreaInfo] = useState([]);
    const [selectDate,setSelectDate]=useState([])

    const { pageSize, pageNum } = pageInfo;
    const columns = getColumns(pageNum, pageSize, props);

    function getList() {
        risksort({
            ...pageInfo,
            ...sortInfo,
            ...params
        }).then((res) => {
            if (res && res.success) {
                const { data, totalCount } = res.data;
                setDataSource({ data, total: totalCount });
            }
        })
    }
    function getDistrict(){
        bjDistrict().then(res => {
            if(res.success){
                setAreaInfo(res.data);
            }
        });
    }
    function getareEarnings(){
        
        areEarnings().then(res=>{
      
            if(res.success){
                setSelectDate(res.data)
            }
        })
    }

    // 排序和分页
    function onChange(pagination, filters, sorter) {
        const { order, column } = sorter;
        setPageInfo({ pageNum: pagination.current, pageSize: pagination.pageSize });
        if (order) {
            let upset = order === "descend" ? 2 : 1;
            setSortInfo({ upset, sortType: column.type });
        } else {
            setSortInfo({ upset: '', sortType: '' });
        }
    }

    // 筛选赋值
    function handleChange(value, type) {
        if (!value && type === 'riskType') {
            setParams({ ...params, [type]: value, point: '', type: '' }); // 没选风险类型的时候 充值得分
            return;
        }
        setParams({ ...params, [type]: value });
    }

    function inputNum() {
        setPageInfo({ ...pageInfo, pageNum: 1 });
        // getList();
    }

    useEffect(() => {
        getDistrict();
        getareEarnings();
    }, [])

    useEffect(() => {
        getList();
    }, [pageInfo, sortInfo])

    const display = params.riskType ? 'inline-block' : 'none';
    return (
        <div className={styles.riskSort}>
            <RiskTitle>风险排名</RiskTitle>
            <div className="box">
                <div className={styles.filters}>
                    <Input placeholder="输入上市公司代码或关键字" className='companyIpt' size="large" onChange={(e) => handleChange(e.target.value.trim(), 'companyName')} />
                    <Select 
                        placeholder="选择上市板块" 
                        size="large" style={{ width: '160px', margin: '0 20px' }} 
                        onChange={(value) => handleChange(value, 'block')}
                        getPopupContainer={() => document.getElementById('riskBLOCK')} 
                        id = "riskBLOCK"
                    >
                        <Option value='' >选择上市板块</Option>
                        {
                            Object.keys(BLOCKTYPE).map(ele => {
                                return <Option value={ele} key={ele}>{BLOCKTYPE[ele]}</Option>;
                            })
                        }
                    </Select>
                    <Select 
                        placeholder="选择所属区" 
                        size="large" style={{ width: '150px', marginRight: '20px' }} 
                        onChange={(value) => handleChange(value, 'districtCode')}
                        getPopupContainer={() => document.getElementById('riskArea')} 
                        id = "riskArea"
                    >
                        <Option value='' >北京市</Option>
                        {
                            areaInfo.map(ele => {
                                return <Option value={ele.code} key={ele.code}>{ele.district}</Option>;
                            })
                        }
                    </Select>
                    <Select 
                        placeholder="选择财报日期" 
                        size="large" style={{ width: '150px', marginRight: '20px' }} 
                        onChange={(value) => handleChange(value, 'year')}
                        getPopupContainer={() => document.getElementById('riskyear')} 
                        id = "riskyear"
                    >
                        {
                            selectDate.map(ele => {
                                return <Option value={ele.year} key={ele}>{ele.year}</Option>;
                            })
                        }
                    </Select>
                    <div className={styles.InputGroup}>
                        <InputGroup compact>
                            <Select 
                                placeholder="选择风险类别" 
                                onChange={(value) => handleChange(value, 'riskType')} 
                                size="large" style={{ width: '150px' }}
                                getPopupContainer={() => document.getElementById('riskType')} 
                                id = "riskType"
                            >
                                <Option value='' >选择风险类别</Option>
                                {
                                    Object.keys(RISKTYPE).map(ele => {
                                        return <Option value={ele} key={ele}>{RISKTYPE[ele].name}</Option>;
                                    })
                                }
                            </Select>
                            <Select 
                                placeholder="选择得分范围" size="large" style={{ width: '145px', display }} 
                                value={params.type} onChange={(value) => handleChange(value, 'type')}
                                getPopupContainer={() => document.getElementById('riskRange')} 
                                id = "riskRange"
                            >
                                <Option value='' >选择得分范围</Option>
                                <Option value="1">得分大于</Option>
                                <Option value="2">得分小于</Option>
                            </Select>
                            <InputNumber placeholder="输入1-100的数字" value={params.point} size="large" min={1} max={100} style={{ width: '160px', display }} onChange={(value) => handleChange(value, 'point')} />
                        </InputGroup>
                        <Button onClick={inputNum}>搜索</Button>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={dataSource.data}
                    onChange={onChange}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total: dataSource.total,
                        showTotal: total => `共 ${Math.ceil(dataSource.total / pageSize)} 页/${total}条数据`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        size: "small"
                    }}
                />
            </div>
        </div>
    );
})
