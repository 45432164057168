import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import Radios from './radio';
import { ventureEnterprise } from './service';
import { Empty } from 'antd';
/**
 * @desc 风险企业数量及占比情况
 */

global.riskCompaniesNumber = null;

export default function RiskCompaniesNumHistogram({ address, style }) {
  const [type, setType] = useState(1);

  const radiosData = [
    { name: '占比', value: 1 },
    { name: '数量', value: 2 },
  ];

  useEffect(() => {
    ventureEnterprise({ area: address, orderType: type }).then(({ success, data }) => {
      if (success == true) {
        if (global.riskCompaniesNumber == null && type == 1) {
          global.riskCompaniesNumber = new Chart({
            container: 'RiskCompaniesNumHistogram',
            autoFit: true,
            height: 200,
          });

          global.riskCompaniesNumber.data(data);
          global.riskCompaniesNumber.tooltip(false);
          global.riskCompaniesNumber.legend(false);
          global.riskCompaniesNumber.scale ('percent',{
            nice:true,
            alias:'占比'
          });
          global.riskCompaniesNumber.axis('percent',{
            label: {
              formatter: (val) => {
                return val + '%';
              },
            },
            // title: {
            //   offset: 80,
            //   style: {
            //     fill: '#aaaaaa'
            //   }
            // }
          });


          global.riskCompaniesNumber.interval().position('area*percent').color('percent', (val) => {
            if (val < 35) {
              return '#38c060';//ff5957
            } else if(35 <= val && val <= 45 ) {
              return '#1A8DF8';
            } else {
              return '#ff5957';
            }
          }).label('percent',{
            position:"middle",
            content: (obj) => {
              return obj.percent + '%';
            },
          });
          global.riskCompaniesNumber.render();
        } else if (global.riskCompaniesNumber && type == 2) {
            global.riskCompaniesNumber.clear();
            global.riskCompaniesNumber.changeData(data);
            global.riskCompaniesNumber.tooltip(false);
            global.riskCompaniesNumber.legend(false);
            global.riskCompaniesNumber.scale ('amount',{
              max:500,
              alias:'数量'
            });
            global.riskCompaniesNumber.axis('amount',{
              label: {
                formatter: (val) => {
                  return val;
                },
              },
              // title: {
              //   offset: 80,
              //   style: {
              //     fill: '#aaaaaa'
              //   }
              // }
            });

            global.riskCompaniesNumber.interval().position('area*amount').color('amount', (val) => {

              if (val > 200) {
                return '#ff5957';
              } else if(50 < val && val <= 200 ) {
                return '#1A8DF8';
              } else {
                return '#38c060';
              }
            }).label('amount',{
              position:"middle",
              content: (obj) => {
                return obj.amount;
              },
            });
            global.riskCompaniesNumber.render();
        } else {
          global.riskCompaniesNumber.clear();
          global.riskCompaniesNumber.changeData(data);
          global.riskCompaniesNumber.tooltip(false);
          global.riskCompaniesNumber.legend(false);
          global.riskCompaniesNumber.scale ('percent',{
            nice:true,
            alias:'占比'
          });
          global.riskCompaniesNumber.axis('percent',{
            label: {
              formatter: (val) => {
                return val + '%';
              },
            },
            // title: {
            //   offset: 80,
            //   style: {
            //     fill: '#aaaaaa'
            //   }
            // }
          });

          global.riskCompaniesNumber.interval().position('area*percent').color('percent', (val) => {
            if (val < 35) {
              return '#38c060';//ff5957
            } else if(35 <= val && val <= 45 ) {
              return '#1A8DF8';
            } else {
              return '#ff5957';
            }
          }).label('percent',{
            position:"middle",
            content: (obj) => {
              return obj.percent + '%';
            },
          });
          global.riskCompaniesNumber.render();
        }
      } else {
        <Empty />
      }
    })
  }, [address, type]);
  return (
    <div style={{ position: 'relative' }}>
        <div className={ styles.RiskCompaniesNumber }>风险企业</div>
        <div className={ styles.RiskCompaniesRadios }>
          <Radios
            value={type}
            data={radiosData}
            defaultValue={1}
            onChange={t => {
              setType(t.target.value);
            }}
          />
        </div>
        <div id='RiskCompaniesNumHistogram' className={ styles.RiskCompaniesNumHistogram }></div>
    </div>
  )
}

