/**
 * @author Ray
 * @desc 科创属性-核心技术情况
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, InputNumber, Tooltip, Icon, message } from 'antd';
import { Selector } from '../../merging/home/apply';
import { CoreTechMature, CoreTechAdvance } from '../../../constants/enum';
import { SaveButton } from '../../../common/buttons';
import { GreaterThanZro } from '../../../utils/validator';
import { showModal, closeModal } from '../../investment/script/actions';
import { maxCreat } from '../../../constants/common';
const MESSAGE1 =
  '请填写公司已获得的境内外发明专利数量（不包含实用新型专利、软件著作权及正在申请的发明专利）。';
const MESSAGE2 = '此项指标不计算与其他企业、高校、研究院等机构共有专利数量。';
const FormEle = React.forwardRef(({ form, data }, ref) => {
  React.useImperativeHandle(ref, () => ({ form }));
  data = data || {};
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
    >
      <Form.Item
        label={
          <span>
            具有自主知识产权的核心专利数量&nbsp;
            <Tooltip placement='right' title={MESSAGE1}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('independentPatentNum', {
          rules: [GreaterThanZro],
          initialValue: data.independentPatentNum
        })(<InputNumber placeholder={'请输入'} maxLength={9} suffix='件' />)}
      </Form.Item>
      <Form.Item
        label={
          <span>
            拥有独立专利所有权专利数量&nbsp;
            <Tooltip placement='right' title={MESSAGE2}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('occupyPatentNum', {
          rules: [GreaterThanZro],
          initialValue: data.occupyPatentNum
        })(<InputNumber placeholder={'请输入'} maxLength={9} suffix='件' />)}
      </Form.Item>
      <Form.Item label='核心技术领先程度'>
        {form.getFieldDecorator('techLeadLevel', {
          rules: [],
          initialValue: data.techLeadLevel
        })(<Selector options={CoreTechAdvance} getPopupContainer={()=>document.getElementById('core')} />)}
      </Form.Item>
      <Form.Item label='核心技术成熟度'>
        {form.getFieldDecorator('techMatureLevel', {
          rules: [],
          initialValue: data.techMatureLevel
        })(<Selector options={CoreTechMature} getPopupContainer={()=>document.getElementById('core')} />)}
      </Form.Item>
    </Form>
  );
});

export const FormElement = ({ formRef, data }) => {
  let [Element, setElement] = useState(null);

  useEffect(
    function() {
      let E = Form.create({
        name: 'core',
        onValuesChange: () => {}
      })(FormEle);

      setElement(<E wrappedComponentRef={formRef} data={data} />);
    },
    [data]
  );

  return Element;
};

let currentKey = 0;
export default function CoreTech(props) {
  const formRef = useRef(null);
  const save = () => {
    formRef.current.form.validateFields((errors, values) => {
      if (!errors) {
        if (values.occupyPatentNum > values.independentPatentNum) {
          message.error(
            '占有独立专利所有权专利数量不可大于具有自主知识产权的核心专利数量'
          );
        } else {
          props.saveFun(values);
        }
      }
    });
  };

  useEffect(
    function() {
      if (props.activeKey != currentKey && currentKey == 0) {
        currentKey = props.activeKey;
        // 检验值是否修改过
        const currentValue = formRef.current.form.getFieldsValue();
        const hasChanged = Object.keys(currentValue).filter(function(
          value,
          index
        ) {
          return currentValue[value] !== props.data[value];
        });
        if (currentValue.occupyPatentNum > currentValue.independentPatentNum) {
          hasChanged.forEach(value => {
            formRef.current.form.setFieldsValue({
              [value]: props.data[value]
            });
          });
          return message.error(
            '占有独立专利所有权专利数量不可大于具有自主知识产权的核心专利数量'
          );
        }
        if (hasChanged.length) {
          props.dispatch(
            showModal({
              title: '核心技术情况',
              cancelText: '不保存！',
              okText: '保存！',
              onOk: () => {
                props.dispatch(closeModal());
                formRef.current.form.validateFields((errors, values) => {
                  if (!errors) {
                    props.saveFun(values, function() {
                      hasChanged.forEach(value => {
                        formRef.current.form.setFieldsValue({
                          [value]: props.data[value]
                        });
                        props.dispatch(closeModal());
                      });
                    });
                  }
                });
              },
              onClose: () => {
                // 不保存恢复原样
                hasChanged.forEach(value => {
                  formRef.current.form.setFieldsValue({
                    [value]: props.data[value]
                  });
                  props.dispatch(closeModal());
                });
              }
            })
          );
        }
      }
      if (props.activeKey == 0) {
        currentKey = 0;
      }
    },
    [props.activeKey, props.data]
  );

  return (
    <div id="core">
      <FormElement formRef={formRef} data={props.data} />
      <div className='textCenter marginTop'>
        <SaveButton onClick={save} />
      </div>
    </div>
  );
}
