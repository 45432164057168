import React, {PureComponent} from 'react';
import {rankingOfVolumeBasic, rankingOfVolumeInnovate, rankingOfVolumeSelected,rankingOfVolumeAll} from "./service";
import styles from './styles.scss'
import {limitString} from "../../../../utils/utils";
import {Empty} from "antd";

export default class IndividualShares extends PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount=()=>{
        const { type } = this.props;

        //表格无缝滚动
        this.industryNews = setInterval(this.taskIndustryNews, 50);

        //请求全部
        type == 1 ? rankingOfVolumeAll().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) :
        //请求创新层
        type == 2 ? rankingOfVolumeInnovate().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) :
        //请求基础层
        type == 3 ? rankingOfVolumeBasic().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) :
        //请求精选层
        type == 4 ? rankingOfVolumeSelected().then(({ success, data }) => {
            if (success == true) {
                this.setState({
                    data
                })
            } else {
                <Empty />
            }
        }) : <Empty />


    }

    componentWillReceiveProps(nextProps) {
        if (this.props.type != nextProps.type) {
            const { type } = nextProps;
            //表格无缝滚动


            //请求全部
            type == 1 ? rankingOfVolumeAll().then(({ success, data }) => {
                    if (success == true) {
                        this.setState({
                            data
                        })
                    } else {
                        <Empty />
                    }
                }) :
            //请求创新层
            type == 2 ? rankingOfVolumeInnovate().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) :
            //请求基础层
            type == 3 ? rankingOfVolumeBasic().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) :
            //请求精选层
            type == 4 ? rankingOfVolumeSelected().then(({ success, data }) => {
                if (success == true) {
                    this.setState({
                        data
                    })
                } else {
                    <Empty />
                }
            }) : <Empty />
        }
    }

    taskIndustryNews = () => {
        if ( this.refs.newDiv && this.refs.newDiv.scrollTop >= this.refs.newDivUI.offsetHeight - this.refs.newDiv.clientHeight) {
            this.refs.newDiv.scrollTop = 0;
        }
        else if (this.refs.newDiv) {
            this.refs.newDiv.scrollTop += 1;
        }
    }

    handleIndustryNewsEnter = () => {
        clearInterval(this.industryNews);
    }

    handleIndustryNewsLeave = () => {
        this.industryNews = setInterval(this.taskIndustryNews, 50);
    }

    componentDidWillUnmount = () => {
        clearInterval(this.industryNews);
    }

    render(){
        const {data} = this.state;
        return(
            <div className={styles.leftSecondDiv}>
                <div className={ styles.ceShiTable }>
                    <div className={ styles.ceShiTabletitle }>
                        <span className={ styles.ceShiTabletext2 }>代码</span>
                        <span className={ styles.ceShiTabletext2 }>简称</span>
                        <span className={ styles.ceShiTabletext2 }>最近</span>
                        <span className={ styles.ceShiTabletext2 }>涨跌幅</span>
                        <span className={ styles.ceShiTabletext2 }>成交量 (万股)</span>
                    </div>
                    <div
                        ref='newDiv'
                        className={ styles.ceShiTablebody }
                        onMouseEnter={this.handleIndustryNewsEnter.bind(this)}
                        onMouseLeave={this.handleIndustryNewsLeave.bind(this)}
                    >
                        <ul ref='newDivUI'>
                            {data && data.length > 0
                                ?
                                data.map(this.tableBody)
                                : <span className={styles.noData}>暂无数据</span>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    tableBody = (item, index) => {
        return (
            <li key={index}>
              <span className={ styles.ceShiTabletext2 }>
                  {limitString(8,item.stockCode)}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {item.companyShortName}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                    {item.stockPrice}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {item.upAndDown}
              </span>
                <span className={ styles.ceShiTabletext2 }>
                  {item.volume}
              </span>
            </li>
        );
    }
}
