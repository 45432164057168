/**
 * @desc  挂牌企业地域分布图
 */
import React, { useState } from 'react';
import Map from "./map";
import MapTool from "./mapTool";

export default function CenterTopIndex({type}) {
    return(
        <div>
            <MapTool/>
            <Map type={type} style={{ height: '100%' }} />
        </div>
    )
}
