import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';

export default function Drawer({ position, children, style }) {
  return (
    <div
      style={{
        ...style
      }}
      className={classnames(styles.drawer, {
        [styles.rightDrawer]: position === 'right',
        [styles.leftDrawer]: position === 'left'
      })}
    >
      {children}
    </div>
  );
}

Drawer.defaultProps = {
  position: 'left'
};
