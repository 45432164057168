/**
 * @author Ray
 * @description 定制antd的card组件，去除title的下划线，使用common/title组件来渲染title
 */
import React from 'react';
import Title from '../title';
import { Card } from 'antd';
import classnames from 'classnames';
import './styles.scss';

export default ({ title, children, classes = {}, ...rest }) => {
    const { root, headTitle } = classes;

    return (
        <Card
            className={classnames({
                [root]: Boolean(root)
            })}
            title={<Title classes={{ root: headTitle || '' }} text={title} />}
            {...rest}
        >
            {children}
        </Card>
    );
};
