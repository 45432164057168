/**
 * @author hjie
 * 我的学习
 */

import React, { useState } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import RiskTitle from 'common/riskTitle';
import { Map, Line, Pie } from 'bbdCharts';
import { Radio, Empty, Modal, Select } from 'antd';
import { BLOCKTYPE } from 'constants/common';
import Hook from './hook';
import BasicInfo from './basicInfo';
import BackBtn from '../../../../common/backBtn';
import ModalTable from './modalTable';
import AreaSort from './areaSort';

const { Option } = Select;

const years = ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020']

export default function (props) {

    const { modalParam, setModalParam, visible, setVisible, areaData,statisticsData, areaLoading, pieData, lineData, mapCallBack, mapDatas, setAreaCode, areaCode } = Hook();
    const { blockChecks, ...others } = areaData;

    const { code, name } = areaCode;

    const [mode, setMode] = useState('0'); // 选项卡

    function basicClick(param){
        setVisible(true);
        setModalParam(param);
    }
    
    // 高风险企业总量走势选项卡
    const handleModeChange = e => {
        const modeval = e.target.value;
        setMode(modeval);
    };

    return (
        <div>
            <BasicInfo data={areaData} setVisible = {() => basicClick({districtCode: code})}/>
            <div className={styles.riskAnalysis}>
                <RiskTitle>区域风险分析</RiskTitle>
                <div className="clearfix">
                    <div className="fl">
                        <div className={classnames(styles.mapBox, "box")}>
                            <h4 className="titleBorder">区域风险分布图</h4>
                            <div className={classnames(styles.btnBack, { hide: code === 'AAA' })} >
                                <BackBtn onClick={() => setAreaCode({ code: 'AAA', name: '' })} />
                            </div>
                            <div style = {{height: '600px'}}>
                                {
                                    mapDatas.loading ? '' : mapDatas.data &&  mapDatas.data.length ?
                                        <Map mapCallBack={mapCallBack} data = {mapDatas.data} name = {name}/> : <Empty />
                                }
                            </div>
                        </div>
                        <div className={classnames(styles.lineBox, "box")}>
                            <h4 className="titleBorder"><b className={classnames({ hide: !name })}>{name} - </b>高风险企业总量走势</h4>
                            <Radio.Group className="fr" onChange={handleModeChange} value={mode} style={{ position: 'absolute', top: 10, right: 10 }}>
                                <Radio.Button value="0">季度</Radio.Button>
                                <Radio.Button value="/12/31">年度</Radio.Button>
                            </Radio.Group>
                            <Line {...lineData} showYname={true} loading={areaLoading} mode={mode} />
                        </div>
                    </div>
                    <div className={classnames("fr", styles.right)}>
                        <div className={classnames(styles.tableBox, "box")}>
                            <h4 className="titleBorder"><b className={classnames({ hide: !name })}>{name} - </b>区域统计概览</h4>
                            <div style={{ marginTop: '25px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="25%"></th>
                                            <th width="45%">风险上市公司数量/总数</th>
                                            <th>平均风险评分</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className={styles.tableBody}>
                                {
                                    areaLoading ? '' : statisticsData && statisticsData.length ?
                                        <table>
                                            <tbody>
                                                {
                                                    statisticsData.map(ele => {
                                                        const { block, riskAvg, riskNum, total } = ele;
                                                        return <tr key={block}>
                                                            <td width="25%">{BLOCKTYPE[block]}</td>
                                                            <td width="45%"><b style = {{cursor: 'pointer'}} onClick = {() => basicClick({block, districtCode: code})}>{riskNum}家</b>/{total}家</td>
                                                            <td><b>{riskAvg}</b></td>
                                                        </tr>;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <Empty />
                                }
                            </div>
                        </div>
                        <div className={classnames(styles.barBox, "box")}>
                            <AreaSort barCallBack = {basicClick} {...mapDatas}/>  
                        </div>
                        <div className={classnames(styles.pieBox, "box")}>
                            <h4 className="titleBorder"><b className={classnames({ hide: !name })}>{name} - </b>高风险企业行业分布</h4>
                            <Pie data={pieData} loading={areaLoading} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className='recordModal'
                title=''
                visible={visible}
                width='1100px'
                footer={null}
                // destroyOnClose = {true}
                onCancel = {() => setVisible(false)}
            >
                <ModalTable param = {modalParam}/>
            </Modal>
        </div>
    );
}