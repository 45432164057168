import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  每日成交量前十大个股情况
*/
//创新层
export function rankingOfVolumeInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeInnovate`)
}
//基础层
export function rankingOfVolumeBasic() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeBasic`)
}
//精选层
export function rankingOfVolumeSelected() {
    return request(`${API.V1}/cockpit/neeqStock/five/rankingOfVolumeSelected`)
}