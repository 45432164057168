import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import Radios from '../listOfEnterprises/radio';
import { getStatusDistribution } from './service';
import { isNotEmpty } from '../../../utils/utils';

const radiosData = [
    { name: '已受理', value: 1 },
    { name: '已注册', value: 2 },
    { name: '已上市', value: 3 }
];

export default function MapTools({ onChange, value, address }) {
    const [data, setData] = useState({
        accepted: 0,
        registered: 0,
        fuk: 0
    });

    useEffect(() => {
        getStatusDistribution({
            area: address === '全国' ? '所有地区' : address
        }).then(res => {
            if (isNotEmpty(res?.data)) {
                setData(res.data);
            }
        });
    }, [address, value]);

    return (
        <div className={styles.mapTool}>
            <span>
                {address}已受理：{data.accepted}
            </span>
            <span>
                {address}已注册：{data.registered}
            </span>
            <span>
                {address}已上市：{data.fuk}
            </span>
            <br />
            <br />
            <br />
            <Radios
                value={value}
                data={radiosData}
                defaultValue={1}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    );
}
