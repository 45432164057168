'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CommunityChart = require('./CommunityChart');

var _CommunityChart2 = _interopRequireDefault(_CommunityChart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _CommunityChart2.default;
module.exports = exports['default'];