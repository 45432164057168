import fetch from 'utils/fetch';
import { API, BasePost } from 'constants/enum';

// 企业详情页面右侧关联方信息 getRightRelation.do(原接口)
export function getRightData(data) {
    return fetch(`${API.V1}/companyDetail/getRightRelationOfDegree.do`, data, BasePost);
}

// 查询企业摘要信息
export function getCompanyAbstract(data) {
    return fetch(`${API.V1}/companyDetail/getCompanyAbstract.do`, data, {
        method: 'POST'
    });
}

// 关联方图谱
export function getRelation(data) {
    return fetch(`${API.V1}/risk/graph/relation/query`, data, BasePost);
}



