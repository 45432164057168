/**
 * @desc  挂牌公司行业分布情况
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import List from './list';

export default function Line4Index() {
    
    return (
        <div className={ styles.line4 }>
            <Title />
            <List />
        </div>
        
    );
}
