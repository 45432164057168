import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Radio } from 'antd';
import { Selector } from '../../merging/home/apply';
import { REPORT_TYPE } from '../../../constants/enum';
import { throttle } from '../../../utils/utils';

const { Item } = Form;

export function SearchBar_({ form }) {
  const { getFieldDecorator } = form;

  return (
    <Form layout="inline">
      <Item>
        {getFieldDecorator('contentType', {
          initialValue: 1
        })(
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={1}>标题</Radio.Button>
            <Radio.Button value={2}>作者</Radio.Button>
            <Radio.Button value={3}>标签</Radio.Button>
          </Radio.Group>
        )}
      </Item>
      <Item>
        {getFieldDecorator('inputContent')(
          <Input
            placeholder="请输入关键字"
            style={{ width: 200, lineHeight: 1.5 }}
          />
        )}
      </Item>
      <Item label="报告类型">
        {getFieldDecorator('type')(
          <Selector
            showSelectAll
            placeholder="请选择报告类型"
            style={{ width: 200 }}
            options={REPORT_TYPE}
          />
        )}
      </Item>
      <Item label="添加时间">
        {getFieldDecorator('time', {
          rules: [],
          initialValue: [undefined, undefined]
        })(<DatePicker.RangePicker />)}
      </Item>
    </Form>
  );
}

/**
 * @return {undefined}
 * @return {undefined}
 * @return {undefined}
 */

export default function({ onChange }) {
  const [Element, setElement] = useState(null);

  useEffect(function() {
    let Element_ = Form.create({
      name: 'policySearchBar',
      onValuesChange: throttle(
        (a, b, allValues) => {
          const { time, ...rest } = allValues;
          let [startTime, endTime] = time;

          startTime = startTime && startTime.startOf('day');
          endTime = endTime && endTime.endOf('day');

          onChange({
            ...rest,
            startTime: startTime ? startTime.format('x') : startTime,
            endTime: endTime ? endTime.format('x') : endTime
          });
        },
        1500,
        this
      )
    })(SearchBar_);

    setElement(<Element_ />);
  }, []);

  return Element;
}
