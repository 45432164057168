/**
 * @author hjie
 */
import style from './style.scss';
import React from 'react';
import classnames from 'classnames';

export default function({form, history}) {
    
    return (
        <div className={classnames(style.protocol)}>
           <div className = {style.title}>北京企业上市综合服务平台用户服务及授权协议</div>
           <div className = {style.content}>
                <p><b>尊敬的用户：</b></p>
                <section className = {style.main}>
                    <p className = {style.textIndent} style = {{marginBottom: '1em'}}>
                    北京企业上市综合服务平台由北京市金融监管局、北京证监局和中关村管委会协调，中关村发展集团和中关村股权交易服务集团牵头主导设立。该平台在北京市工商行政管理局注册名称为北京启元资本市场发展服务有限公司。
                    北京企业上市综合服务平台同意按照本协议规定，向使用本平台的用户提供北京企业上市综合服务。

                    为获取北京企业上市综合服务，申办人应当同意本协议的条款并按照页面上的提示完成全部的注册程序，申办人在注册过程中点击“同意”按钮表示完全接受本协议项下的全部条款。
                    </p>
               <p> 一、特别说明</p>
                <ul>
                    <li>
                    1、为保证您在该平台享受便捷的在线申报、在线预约等服务，请您如实填写在线申报和在线预约的相关办事信息。申办人的基本信息在提交之后不能再擅自修改，因信息填写有误造成的后果，需用户自行承担。
                    </li>
                   <li>
                    2、北京企业上市综合服务平台对您因没有遵守本协议而造成的损失不负任何法律责任。
                    </li> 
                </ul>
                <p>二、服务内容</p>
                <ul>
                    <li>目前北京企业上市综合服务平台提供了一系列的服务，可以帮助用户快速便捷地进行在线申报、在线预约、在线咨询等操作。用户在注册成功之后可享受所有的网上服务。</li>

                </ul>
                <p>三、企业用户注册、密码和安全服务</p>
                <ul>
                    <li>
                    1、企业用户申请成为系统用户时，申请人必须提供真实、准确、即时、完整的注册信息；若您提供的信息不真实、不准确、不即时、不完整，对办事过程造成的影响需由用户自行承担。
                    </li>
                    <li>
                    2、在注册过程中，企业用户可自主选择一个用户名和密码，并须对其保密性负责，同时对使用该用户名和密码的所有活动负责。
                    </li>
                    <li>
                    3、企业注册用户须做到：
                    ① 发现非授权使用您的用户名、密码以及其他破坏平台安全性的行为立即向北京企业上市综合服务平台报告；
                    ② 确保每次使用北京企业上市综合服务平台后正确地离开该站点。
                    </li>
                </ul>

                <p>四、信息授权</p>
                <ul>
                    <li>1、北京企业上市综合服务平台采集的企业信息，将应用于企业成长性评估和风险评估，相关评估的结果应用于平台相关服务中。</li>

                    <li>2、针对以上信息采集及使用，同意本协议即同意授权使用。</li>
                </ul>
                <p>五、服务终止</p>
                <ul>
                    <li> 1、北京企业上市综合服务平台有权在告知或未经告知的情况下随时终止您的会员服务。一旦您的会员服务被终止，您将不能使用北京企业上市综合服务平台。</li>
                    <li> 
                        2、对于用户的以下行为，北京企业上市综合服务平台有权暂停或终止对其的服务：
                        ① 向北京企业上市综合服务平台的在线建议投诉功能滥发重复信息；

                        ② 冒用其他用户的名义发布各种不良信息，办理有损于他人的业务；

                        ③ 攻击北京企业上市综合服务平台的数据、网络或服务；

                        ④ 盗用他人在北京企业上市综合服务平台的用户名或密码。
                    </li>
                    
                </ul>
                <p>六、协议修改</p>
                    <ul>
                        <li>1、北京企业上市综合服务平台将有权随时修改本协议的有关条款，一旦本协议的内容发生变动，平台将会通过适当方式向用户提示修改内容。</li>

                        <li>2、如果不同意北京企业上市综合服务平台对本协议相关条款所做的修改，用户有权停止使用平台的各项服务。</li>

                        <li>3、如果用户继续使用平台提供的服务，则视为其接受北京企业上市综合服务平台对本协议相关条款的修改。</li>
                </ul>
                <p className = "right"><b>北京启元资本市场发展服务有限公司</b></p>
                </section>
            </div>
        </div>
    );
}