/**
 * @author Ray
 */
import request from 'utils/fetch';
import { API } from 'constants/enum';
import { isEmpty } from '../../../utils/utils';

/**
 * @description 我的历史申请记录
 * @param {*} applyStatus
 * @param {*} code
 * @param {*} pageNum
 * @param {*} pageSize
 * @param {*} applyStage
 * @param {*} stage
 * @param {*} status
 */
export function historyApply({
    applyStatus,
    pageNum,
    pageSize,
    applyStage,
    stage,
    status,
    code
}) {
    return request(
        `${API.V1}/policypractice/apply/historyApply`,
        {
            applyStatus,
            code,
            pageNum,
            pageSize,
            applyStage,
            status,
            stage
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 申请填报权限
 * @param {string} code 	社会信用代码
 */
export function applyAuth(code) {
    return request(
        `${API.V1}/policypractice/apply/authority`,
        {
            code
        },
        {
            method: 'GET'
        }
    );
}

/**
 * @description 删除申请记录
 * @param {string} code 	社会信用代码
 * @param {number} id 填报id
 * @param {string} stage 上市阶段:完成股改及北京证监局辅导验收、提交首发申请并获中国证监会受理、通过中国证监会发审会审核、在
 * @param {number} status 1 未提交 2 已提交 3 审核中 4 已驳回 5 已完成
 */
export function deleteApply(code, id, stage, status, type) {
    return request(
        `${API.V1}/policypractice/apply/deleteApply`,
        {
            id,
            code,
            stage,
            status,
            type
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 提交保存的申请
 * @param {*} companyName
 * @param {*} id
 * @param {*} status
 */
export function submitSavedApply(companyName, id) {
    return request(`${API.V1}/policypractice/apply/submitOrCancel`, {
        companyName,
        id,
        status: 1
    });
}

/**
 * @description 撤回保存的申请
 * @param {*} companyName
 * @param {*} id
 * @param {*} status
 */
export function withdrawSavedApply(companyName, id) {
    return request(`${API.V1}/policypractice/apply/submitOrCancel`, {
        companyName,
        id,
        status: 2
    });
}
