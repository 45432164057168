/**
 * @desc 入库企业分析-基础信息-基础信息
 * @author Ray
 */

import React, { Fragment, useContext, useEffect, useState } from 'react';
import styles from './styles.scss';
import Title from '../../../../../common/title';
import FinancingProcess from './financingProcess';
import EquityHoldings from './equityHoldings';
import ShareHolder from './shareHolders';
import BusinessChange from './businessChange';
import WinningInformation from './winningInformation';
import InformationTable from './informationTable';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';
import { getCompanyBaseInfo } from './service';
import { isNotEmpty } from '../../../../../utils/utils';

const titles = [
    '基本信息',
    '融资进度',
    '参股控股情况',
    '股东列表',
    '工商变更',
    '中标信息'
];

const elements = [
    InformationTable,
    FinancingProcess,
    EquityHoldings,
    ShareHolder,
    BusinessChange,
    WinningInformation
];

export default function BasicInformation(props) {
    const [company, setCompany] = useState({});
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );

    useEffect(() => {
        getCompanyBaseInfo(props.match.params.companyId).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                setCompany(res.data);
            }
        });
    }, []);

    return (
        <div className={styles.root}>
            {isOwnedCompany ||
            (!isOwnedCompany && gs.isEnterprise && gs.isVIPUser) ||
            gs.isIntermediary ||
            gs.isQiYuan ||
            gs.isGOV ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                elements.map((Element, index) => (
                    <Fragment key={index}>
                        <Title level={3} text={titles[index]} />
                        <Element {...props} company={company} />
                    </Fragment>
                ))
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
