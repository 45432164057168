import fetch from 'utils/fetch';
import { API, BasePost } from 'constants/enum';

// 登录
export function loginApi(data) {
    return fetch(`${API.V1}/sso/login`, data, BasePost );
}

// 用户注册
export function registerApi(data) {
    return fetch(`${API.V1}/sso/register`, data, BasePost );
}

// 图片验证码
export function kaptchaApi() {
    return fetch(`${API.V1}/sso/kaptcha`);
}

// 验证用户名是否存在
export function validateName(data) {
    return fetch(`${API.V1}/sso/user/username/validate`, data );
}

// 验证执业/从业资格证书编号是否存在
export function validateCertificate(data) {
    return fetch(`${API.V1}/sso/user/certificate/validate`, data );
}

// 验证统一社会信用代码是否存在
export function validateCreditcode(data) {
    return fetch(`${API.V1}/sso/user/creditcode/validate`, data );
}

// 忘记密码
export function forgetPsw(data) {
    return fetch(`${API.V1}/sso/user/password/forget`, data, BasePost );
}

// 验证用户绑定的手机号是否一致
export function validatePhone(data) {
    return fetch(`${API.V1}/sso/user/phone/validate`, data );
}

// 账号申诉
export function appeal(data) { 
    return fetch(`${API.V1}/sso/user/account/appeal`, data, BasePost);
}

// 公司搜索 企业
export function searchCompany(data) { 
    return fetch(`${API.V1}/sso/user/company/list`, data);
}

// 公司搜索 中介
export function searchIntermediaryCompany(data) { 
    return fetch(`${API.V1}/sso/user/intermediary/list`, data);
}

// 获取公钥
export function getPublic(data) { 
    return fetch(`${API.V1}/sso/public/rsa`, data);
}
// 验证手机号码是否存在
export function validateMobile(data) { 
    return fetch(`${API.V1}/sso/user/mobile/validate`, data);
}
// 是否免密登陆
export function showSms(data) { 
    return fetch(`${API.V1}/sso/phone/code/display`, data);
}