/**
 * @desc  在审申请挂牌企业基本情况一览
 */
import React, { useState } from 'react';
import RightSecond from './ListedEnterprises'
export default function RightSecondIndex() {
    return(
        <RightSecond/>
    )
}
