/**
 * @author hjie
 */
import style from './style.scss';
import React, { useState, useRef } from 'react';
import { Input, Form, Button, Icon, Modal } from 'antd';
import { StatusCode } from 'constants/enum';
import { validateMobile } from '../service';
import { CellphoneCheck } from 'utils/validator';
import VerifyPhone from 'common/verifyPhone';
import VerifyCode from 'common/verifyCode';

const TEMPLATE = 2;
function CodeLogin({form, history, toLogin, codeEl}) {

    const { getFieldDecorator } = form;
    const phoneEl = useRef(null);

    // 图片验证码
    const [validateImg, setValidateImg] = useState(false);
    const imgCallBack = tip => {
        setValidateImg(tip);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if(!values.imgCaptcha){
                setValidateImg(true);
                return;
            }
            if (!err && !validateImg ) {
                toLogin({...values, systemState: 1, nopassword: true}, history); // 1：前台 2：后台 默认传2
            }
        });
    }

    // 手机验证码 发送手机验证码之前需验证手机号码
    function getCode(){
        form.validateFields(['username', 'imgCaptcha'], (err, values) => {
            if (!err  && !validateImg && values.imgCaptcha) {
                toValidateMobile(values.username, values.imgCaptcha);   
            }
            if (!values.imgCaptcha) {
                setValidateImg(true);
            }
        });
    }
    
    function toValidateMobile(mobile, imgCaptcha){
        validateMobile({mobile}).then(res =>{
            if(res.statusCode === StatusCode.success){
                if(res.data){
                    phoneEl.current.toSend(mobile, TEMPLATE, imgCaptcha); // 倒计时并发送验证码
                }else{
                    Modal.error({
                        title: "提示",
                        okText: '确定',
                        content: "手机号码尚未注册，请检查是否输入正确"
                    });
                }
            }
        })
    }

    return (
            <Form  onSubmit={handleSubmit} >
                <Form.Item
                    // label="用户名"
                >
                    {getFieldDecorator('username', {
                        validateTrigger: 'onBlur',
                        rules: [
                            { required: true, message: '请输入注册手机号' },
                            { pattern: CellphoneCheck.pattern, message: CellphoneCheck.message }
                        ]
                    })(
                        <Input  prefix={<Icon type="user"  />} placeholder={'请输入注册手机号'} autoComplete="on" />
                    )}
                </Form.Item>
                <Form.Item label="" className="formCaptcha">
                    {getFieldDecorator('imgCaptcha', {
                        initialValue: ''
                    })(
                        <VerifyCode
                            callBack={imgCallBack}
                            showErrTip={validateImg}
                            template={TEMPLATE}
                            ref={codeEl}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    // label="密码"
                >
                    {getFieldDecorator('smsCode', {
                        rules: [
                            { required: true, message: '请输入验证码' }
                        ]
                    })(
                        <VerifyPhone handleClick = {getCode} ref={phoneEl} />
                    )}
                </Form.Item>
                <Form.Item
                >
                    <Button type="primary" htmlType="submit" className={style['loginButton']} style={{ width: '100%' }}>
                        登录
                    </Button>
                </Form.Item>
            </Form>
    );
}

const CodeLoginForm = Form.create()(CodeLogin);
export default CodeLoginForm;