'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _Shape2 = require('./Shape');

var _Shape3 = _interopRequireDefault(_Shape2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Mark = function (_Shape) {
    (0, _inherits3.default)(Mark, _Shape);

    function Mark(painter) {
        (0, _classCallCheck3.default)(this, Mark);
        return (0, _possibleConstructorReturn3.default)(this, (Mark.__proto__ || (0, _getPrototypeOf2.default)(Mark)).call(this, painter));
    }

    (0, _createClass3.default)(Mark, [{
        key: 'getMaster',
        value: function getMaster() {
            return this.master;
        }
    }]);
    return Mark;
}(_Shape3.default); /**
                     * 图谱标记抽象类
                     */


exports.default = Mark;
module.exports = exports['default'];