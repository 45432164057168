import React, { useState, useEffect } from 'react';
import LeftThird from './ListPassRateArea';

/**
 * @desc  IPO申报情况
 */
export default function LeftThirdIndex({ address }) {
  return (
    <div>
        <LeftThird address={address} />
    </div>
  );
}