import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc 获取挂牌企业地域分布图数据
 */
//全部
export function listedEnterprises() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaAll`)
} 
//基础层
export function listedEnterprisesBasic() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaBasic`)
}
//创新层
export function listedEnterprisesInnovate() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaInnovate`)
}
//精选层
export function listedEnterprisesSelected() {
    return request(`${API.V1}/cockpit/neeqStock/one/mapOfAreaSelected`)
}
