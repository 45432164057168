/**
 * @desc
 * @param children
 * @param other
 * @returns {*}
 * @constructor
 */

export default function Model({ children, ...other }) {
    return children({
        ...other
    });
}
