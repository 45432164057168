/**
 * @author Ray
 */
import React from 'react';
import { Col, Row } from 'antd';
import styles from './head.scss';
import classnames from 'classnames';

export default function({ data, year }) {

    let newData = [
        {
            dataIndex: "item", 
            title: "项目（单位：万元）"
        },
        {
            dataIndex: year, 
            title: year
        }
    ]

    return (
        <Row>
            {newData.map(function({ dataIndex, title }, i) {
                return (
                    <Col
                        key={Math.random()}
                        className={classnames('ellipsis', styles.cell)}
                        style={{width: `${(100 / newData.length).toFixed(3)}%`}}
                        title={title}
                    >
                        {`${title}${i > 0 ? '年' : ''}`}
                    </Col>
                );
            })}
        </Row>
    );
}
