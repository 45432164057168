import { useState, useEffect, useCallback } from 'react';
import { areaBasicInfo, mapData ,areaDataStatistics ,areaEnterpriseDistribution ,areaEnterpriseTrends} from '../service';

export default function(props){

    const [areaData, setAreaData] = useState({}); // 区域市场概况
    const [statisticsData, setStatisticsData] = useState([]);//区域统计概览
    const [areaCode, setAreaCode] = useState( {code: 'AAA', name: '', light: false}); // 地区code
    const [mapDatas, setMapData] = useState({data: [], loading: true}); // 地图数据
    const [lineData, setLineData] = useState({}); // 高风险企业总量走势
    const [pieData, setPieData] = useState([]); // 高风险企业行业分布
    const [areaLoading, setAreaLoading] = useState(true);
    const [visible, setVisible] = useState(false); // 弹窗
    const [modalParam, setModalParam] = useState(null); // 弹窗参数
    // const [mapChart, setMapChart] = useState(null); // 地图实例

    // 区域风险分析
    // function getArea(){
    //     areaAnalyse({
    //         code: areaCode.code
    //     }).then(res => {
    //         if(res && res.success){
    //             const { riskIndustries, riskTrends, ...others } = res.data;
    //             const line = getLineData(riskTrends);
    //             const pie = getPieData(riskIndustries);
    //             setLineData(line);
    //             setPieData(pie);
    //             setAreaData(others);
    //         }
    //     }).finally( () => {
    //         setAreaLoading(false);
    //     })
    // }


    //获取相关信息
    function getAreaData() {
        //区域市场概况
        areaBasicInfo({code:areaCode.code}).then(({data,success})=>{
            if(success){
                setAreaData(data);
            }
            //区域统计概览
            areaDataStatistics({code:areaCode.code}).then(({data,success})=>{
                if(success){
                    setStatisticsData(data);
                }
                //区域高风险企业行业分布
                areaEnterpriseDistribution({code:areaCode.code}).then(({data,success})=>{
                    if(success){
                        let pie = getPieData(data);
                        setPieData(pie);
                    }
                    //区域高风险企业总量走势
                    areaEnterpriseTrends({code:areaCode.code}).then(({data,success})=>{
                        if(success){
                            let line = getLineData(data);
                            setLineData(line);
                        }
                    })
                });
            });
        }).finally(()=>{setAreaLoading(false)});
    }


    // 区域风险分析-地图数据
    function getMap(){
        mapData().then(res => {
            if( res && res.success){
                setMapData({ data: res.data, loading: false});
                return;
            }
            setMapData({ data: [], loading: false});
        })
    }

    // 高风险企业总量走势
    function getLineData(riskTrends){
        let xAxis = [], highNumArr = [], highPointArr = [];
        if(!riskTrends){
            return { xAxis };
        }
        riskTrends.forEach( ele => {
            const { highNum, highPoint, year } = ele;
            // xAxis.push(year);
            xAxis.push(
                {
                    'year': year,
                    'highNum': highNum,
                    'highPoint': (highPoint * 100).toFixed(2)
                }
            )
            highNumArr.push(highNum);
            highPointArr.push((highPoint * 100).toFixed(2));
        });
        const series = [
            {
                type: 'line',
                name: '高风险企业数量',
                data:  highNumArr
            },
            {
                type: 'line',
                name: '高风险企业占比(%)',
                yAxisIndex: 1,
                data:  highPointArr
            }
        ];
        return { xAxis, series };
    }

    // 高风险企业行业分布
    function getPieData(riskIndustries){
        if( !riskIndustries ){
            return [];
        }
        return riskIndustries.map( ele => {
            const { industryName, num } = ele;
            return { name: industryName, value: num}
        });
    }

    const mapCallBack = (v) => {
        let { code, name } = v.data;
        setAreaCode({code, name});
    };

    // const mapCallBack = useCallback((v)=>{
    //     let { code, name } = v.data;
    //     setAreaCode({code, name});
    // },[])

    useEffect(()=>{
        getMap();
    }, []);

    useEffect(()=>{
        // getArea();
        getAreaData();
    }, [areaCode.code]);

    return { modalParam, setModalParam, visible, setVisible, areaData,statisticsData, areaLoading, pieData, lineData, mapCallBack, mapDatas, setAreaCode, areaCode }
}
