import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';



export default function Line14List({ type }) {
    const data = [
        { securitiesNumber: 830809, totalEquity: '安达科技' },
        { securitiesNumber: 830809, totalEquity: '安达科技' },
        { securitiesNumber: 830809, totalEquity: '安达科技' },
        { securitiesNumber: 830809, totalEquity: '安达科技' },
    ]

    const data1 = [
        { securitiesNumber: 830809, totalEquity: '安达科技' },
        { securitiesNumber: 830809, totalEquity: '安达科技' },
    ]
    return (
        <div className={ styles.line14List }>
            {
                data && data.length ? (
                    <table>
                        <thead>
                            <tr>
                                <td>时间</td>
                                <td>内容</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                type == 1 ? data.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="80%">{ele.securitiesNumber}</td>
                                            <td width="5.5%">{ele.totalEquity}</td>
                                        </tr>
                                    )
                                }) : data1.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="26.8%">{ele.securitiesNumber}</td>
                                            <td width="65%">{ele.totalEquity}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : <Empty />
            }
            {/* <div className={ styles.loadMore }>
                点击查看更多
            </div> */}
        </div>
    )
}