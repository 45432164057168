/**
 * @desc 挂牌企业地域分布图title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line1Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line1Title }>
                挂牌企业地域分布图
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}