import { Avatar, Card, Col, Row, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import Pagination from '../../../common/list/pagination';
import styles from '../expertIndex/styles.scss';
import { queryListExpertByCondition } from '../service';
import { IMAGE_HOST } from '../../../constants/enum';
import expertImg from '../expertIndex/image/expert.jpeg';
export default function ExpertList({ history, params }) {
  const { isPub, isDev, isTest } = useContext(GlobalContext);
  const enviroment = [isPub, isDev, isTest];
  const [data, setData] = useState({
    expertList: []
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 9,
    total: 10
  });
  useEffect(() => {
    load();
  }, [params]);
  function load(page = {}) {
    async function fetchData() {
      let result = await queryListExpertByCondition({
        ...params,
        pageNum: page.pageNum || 1,
        pageSize: 9
      });
      result = result.data || {};
      setData({ expertList: result.data || [] });
      setPagination({
        pageNum: result.pageNum,
        pageSize: 9,
        total: result.totalCount,
        totalPage: result.pageCount
      });
    }
    fetchData();
  }
  return (
    <div>
      <Row gutter={16} className={styles.expertList}>
        {data?.expertList?.map((expert, index) => (
          <Col span={8} key={'expert' + index} style={{ marginTop: 16 }}>
            <Card
              hoverable
              bordered={false}
              style={{ height: 150 }}
              onClick={() => {
                history.push({
                  pathname: `/investment/expert/${expert.expertTeam}`,
                  state: expert
                });
              }}
            >
              <Card.Meta
                avatar={<Avatar src={(expert.avatar != '--') ? expert.avatar : expertImg} />}
                title={expert.expertTeam + '（' + expert.expertFirm + '）'}
                description={
                  <div className={styles.textContent}>
                    <Tooltip title={expert.firmName}>
                      <Typography>
                        <div className={styles.textBoundary}>
                          {expert.firmName}
                        </div>
                      </Typography>
                    </Tooltip>
                    <Tooltip title={expert.zjMainServiceBlock}>
                      <Typography>
                        <div className={styles.textBoundary}>
                          主要服务板块：{expert.zjMainServiceBlock.split(',')[0]}
                        </div>
                      </Typography>
                    </Tooltip>
                    <Tooltip title={expert.zjMainServiceIndustry}>
                      <Typography>
                        <div className={styles.textBoundary}>
                          主要领域：{expert.zjMainServiceIndustry.split(',')[0]}
                        </div>
                      </Typography>
                    </Tooltip>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination {...pagination} onChange={load} />
    </div>
  );
}
