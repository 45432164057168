import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { queryEduExperience } from '../service';
import styles from './style.scss';
import { message } from 'antd';
import WorkExperience from './workExperience';
import Title from '../../../common/title';

export default function workExperience(props) {
  const [data, setData] = useState({
    info: []
  });
  useEffect(() => {
    async function fetchData() {
      const result =
        (await queryEduExperience({
          licenseNum: props.detail.cpaLicense,
          firstUniqueId: props.detail.firstUniqueId
        })) || {};
      if (result.statusCode === 1000) {
        setData({ info: result.data });
      } else {
        setData({ info: [] });
        message.info('教育经历' + result.message);
      }
    }
    fetchData();
  }, []);
  return (
    <div className={styles.experience}>
      <Title level={2} text="教育经历" />
      {data.info.length
        ? data.info.map((item, index) => {
            return (
              <div className={styles.date} key={'edu' + index}>
                <div>
                  <i className={styles.bigCircle}></i>
                  {moment(item.startTime).format('YYYY-MM-DD')}至
                  {moment(item.endTime).format('YYYY-MM-DD')}
                </div>
                <div className={styles.others}>
                  <i className={styles.smallCircle}></i>
                  {item.school} | {item.major}
                </div>
              </div>
            );
          })
        : '暂无数据'}
    </div>
  );
}
