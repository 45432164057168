/**
 * @desc  交易情况概览
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import Candlestick from './candlestick';

export default function Line6Index() {
    
    return (
        <div className={ styles.line6 }>
            <Title />
            <Candlestick />
        </div>
        
    );
}
