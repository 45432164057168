/**
 * @author Ray
 * @desc 财务信息
 */
import React, { useState } from 'react';
import Title from 'common/title';
import BusinessTabs from '../../../common/financialTabs';
import { Divider } from 'antd';
import styles from './styles.scss';

export default function({ history }) {
    return (
        <section className={styles.root}>
            <Title text="财务信息" />
            <Divider />
            <BusinessTabs readOnly={false} />
        </section>
    );
}
