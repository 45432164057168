/**
 * @desc 中介机构详情页-首页
 * @author Ray
 */

import React, { useEffect } from 'react';
import styles from './styles.scss';
import Title from '../../../../common/title';
import ListingService from './listingService';
import ServiceCompanyHistory from './serviceCompanyHistory';
import { Divider, Tabs } from 'antd';
import Basic from './basic';
import Shareholders from './shareholder';
import TeamMembers from './teamMembers';
import BranchOffice from './branchOffice';
import BusinessChange from './businessChange';

export default function Detail(props) {
    return (
        <div className={styles.root}>
            <Title level={1} text="中介机构详情" />
            <Divider />
            <Title level={2} text={'上市服务情况'} />
            <ListingService {...props} />
            <Title level={2} text={'历史服务公司情况'} />
            <ServiceCompanyHistory {...props} />
            <br />
            <br />
            {props.zjjgType !== '律师事务所' ? (
                <Tabs defaultActiveKey={'0'}>
                    <Tabs.TabPane key={'0'} tab="基本信息">
                        <Title level={2} text="基本信息" />
                        <Basic {...props} />
                        <Title level={2} text="股东" />
                        <Shareholders {...props} />
                        <Title level={2} text="团队成员" />
                        <TeamMembers {...props} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={'1'} tab="分支机构">
                        <Title level={2} text="分支机构" />
                        <BranchOffice {...props} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key={'2'} tab="工商变更">
                        <Title level={2} text="工商变更" />
                        <BusinessChange {...props} />
                    </Tabs.TabPane>
                </Tabs>
            ) : (
                <>
                    <Title level={2} text="团队成员" />
                    <TeamMembers {...props} />
                </>
            )}
        </div>
    );
}
