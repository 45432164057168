/**
 * @author Ray
 * @desc 入库企业分析-基础信息-核心竞争力
 */

import React, { Fragment, useContext, useState } from 'react';
import styles from './styles.scss';
import Title from '../../../../../common/title';
import PatentBusinessMatching from './patentBusinessMatching';
// import LegalStatus from './legalStatus';
// import PatentOperation from './patentOperation';
// import PatentRanking from './patentRanking';
import Patents from './patents';
import SoftwareWorks from './softwareWorks';
import Brands from './brands';
import PaperWorks from './paperWorks';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

const Elements = [
    PatentBusinessMatching,
    Patents,
    SoftwareWorks,
    PaperWorks,
    Brands
];

export default function Competitiveness(props) {
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );
    return (
        <div className={styles.root}>
            {isOwnedCompany ||
            (!isOwnedCompany && gs.isEnterprise && gs.isVIPUser) ||
            gs.isIntermediary ||
            gs.isQiYuan ||
            gs.isGOV ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                Elements.map((Element, index) => (
                    <Element key={index} {...props} />
                ))
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
