/**
 * @author Ray
 * @desc 社会责任评价
 * @remider 只是属于这家公司的可以对评价进行更新？
 */
import React, { useContext } from 'react';
import styles from './styles.scss';
import Title from '../../../../common/title';
import { Typography, Icon, Divider } from 'antd';
import ListedPublicWelfareActivities from './listedPublicWelfareActivities';
import CharitableActivities from './charitableActivities';
import Donation from './donation';
import BailoutParticipation from './bailoutParticipation';
import Employment from './employment';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

const elements = [
    ListedPublicWelfareActivities,
    CharitableActivities,
    Employment,
    Donation,
    BailoutParticipation
];

export default function SocialEvaluation(props) {
    const gs = useContext(GlobalContext);
    return (
        <div className={styles.root}>
            <Title level={1} text="社会责任评价">
                {gs.user?.organization !== props.match.params.companyName && (
                    <>
                        <Icon
                            style={{ fontSize: 16 }}
                            type="exclamation-circle"
                            theme="twoTone"
                            twoToneColor="#f5222d"
                        />
                        &nbsp;
                        <Typography.Text style={{ fontSize: 16 }} type="danger">
                            您不属于该公司，无法编辑该页面信息
                        </Typography.Text>
                    </>
                )}
            </Title>
            <Divider />
            {elements.map((Element, index) => {
                return <Element key={index} {...props} />;
            })}
        </div>
    );
}
