import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import Radios from './radio';
import { area, industry, broker } from './service';
import { Empty } from 'antd';
/**
 * 	 tab标题	横坐标	                   纵坐标	         筛选1	         筛选2
  *  tab1	   各地IPO申报数量	  12月月份	IPO申报项目数量	2015-2020年	    多选-全国/各省市地区
  *  tab2	   各券商IPO申报数量	12月月份	IPO申报项目数量	2015-2020年	    多选-全国/各省市地区
  *  tab3	   各行业IPO申报数量	12月月份	IPO申报项目数量	2015-2020年	    多选-各行业
 * 
 * 
 * @desc IPO申报情况
 */

global.IPODeclarationLine = null;

export default function IPODeclarationLine() {
    const [type, setType] = useState(1);
    const [data, setData] = useState([]);

    const radiosData = [
      { name: '地区', value: 1 },
      { name: '行业', value: 2 },
      { name: '券商', value: 3 },
    ];

    useEffect(() => {
      //请求地区接口数据
      type == 1 ? area().then(({ success, data }) => {
         if (success == true) {
            if (global.IPODeclarationLine == null) {
              global.IPODeclarationLine = new Chart({
                        container: 'IPODeclarationLine',
                        autoFit: true,
                        height: 210,
                        padding:[18,15,45,27]
                    });
                      
                    global.IPODeclarationLine.data(data);
                    global.IPODeclarationLine.scale({
                        month: {
                          range: [0, 1],
                        },
                        temperature: {
                          nice: true,
                        },
                      });
                    global.IPODeclarationLine.scale('amount',{
                        nice : true,
                      });
                      global.IPODeclarationLine.tooltip(false);
                      
                      global.IPODeclarationLine
                        .line()
                        .position('month*amount')
                        .color('item')
                        .shape('smooth');
                      
                      global.IPODeclarationLine
                        .point().label('amount')
                        .position('month*amount')
                        .color('item')
                        .shape('circle');
                      
                      global.IPODeclarationLine.render();
            } else {
                global.IPODeclarationLine.clear();
                global.IPODeclarationLine.changeData(data);
                global.IPODeclarationLine.scale({
                  month: {
                    range: [0, 1],
                  },
                  temperature: {
                    nice: true,
                  },
                });
                global.IPODeclarationLine.scale('amount',{
                  nice : true,
                });
                global.IPODeclarationLine.tooltip(false);
                
                global.IPODeclarationLine
                  .line()
                  .position('month*amount')
                  .color('item')
                  .shape('smooth');
                
                global.IPODeclarationLine
                  .point().label('amount')
                  .position('month*amount')
                  .color('item')
                  .shape('circle');
                
                global.IPODeclarationLine.render();
            }
          } else {
             <Empty />
          }
      }) : 
      //请求行业接口数据
      type == 2 ? industry().then(({ success, data }) => {
         if (success == true) {
            global.IPODeclarationLine.clear();
            global.IPODeclarationLine.changeData(data);
            global.IPODeclarationLine.scale({
              month: {
                range: [0, 1],
              },
              temperature: {
                nice: true,
              },
            });
            global.IPODeclarationLine.scale('amount',{
              nice:true
            });
            global.IPODeclarationLine.tooltip(false);
            
            global.IPODeclarationLine
              .line()
              .position('month*amount')
              .color('item')
              .shape('smooth');
            
            global.IPODeclarationLine
              .point().label('amount')
              .position('month*amount')
              .color('item')
              .shape('circle');
            
            global.IPODeclarationLine.render();
         } else {
            <Empty />
         }
      }) :
      //请求券商接口数据
      type == 3 ? broker().then(({ success, data }) => {
          if (success == true) {
             global.IPODeclarationLine.clear();
             global.IPODeclarationLine.changeData(data);
             global.IPODeclarationLine.scale({
              month: {
                range: [0, 1],
              },
              temperature: {
                nice: true,
              },
            });
            global.IPODeclarationLine.scale('amount',{
              nice:true
            });
            global.IPODeclarationLine.tooltip(false);
            
            global.IPODeclarationLine
              .line()
              .position('month*amount')
              .color('item')
              .shape('smooth');
            
            global.IPODeclarationLine
              .point().label('amount')
              .position('month*amount')
              .color('item')
              .shape('circle');
            
            global.IPODeclarationLine.render();
          } else {
              <Empty />
          }
      }) : <Empty />
    }, [type])

    return (
      <div style={{ position: 'relative' }}>
          <Radios
              value={type}
              data={radiosData}
              defaultValue={1}
              onChange={e => setType(e.target.value)}
          />
          <div id='IPODeclarationLine' className={ styles.IPODeclarationLine }></div>
      </div>
    )
}
