
import moment from 'moment';
import {transferStringNull} from 'utils/utils';

// 股票质押情况表头
export const pledgesCol =  [
    {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
        render: (text) => moment(text).format('YYYY-MM-DD')
    },
    {
        title: '质押笔数',
        dataIndex: 'pledgeNum',
        key: 'pledgeNum',
        render: transferStringNull
    },
    {
        title: 'A股总股本',
        dataIndex: 'totalShare',
        key: 'totalShare',
        render: transferStringNull
    },
    {
        title: '质押比例',
        dataIndex: 'pledgePercent',
        key: 'pledgePercent',
        render: transferStringNull
    },
    {
        title: '无限售股份质押数量(万)',
        dataIndex: 'unlimitedAmount',
        key: 'unlimitedAmount',
        render: transferStringNull
    },
    {
        title: '有限售股份质押数量(万)',
        dataIndex: 'limitedAmount',
        key: 'limitedAmount',
        render: transferStringNull
    }
];


// 股权质押明细
export const pledgesColDetail = [
    {
        title: '序号',
        dataIndex: 'num',
        key: 'num',
        render: (text, item, index) => {
            return index + 1;
        },
        // width: '5%'
    },
    {
        title: '股东名称',
        dataIndex: 'name',
        key: 'name',
        // width: '14%'
        render: transferStringNull
    },
    {
        title: '质押股份数量',
        dataIndex: 'amount',
        key: 'amount',
        render: transferStringNull
    },
    {
        title: '占所持股份比例（%）',
        dataIndex: 'holdPct',
        key: 'holdPct',
        render: transferStringNull
    },
    {
        title: '占总股本比例(%)',
        dataIndex: 'totalPct',
        key: 'totalPct',
        render: transferStringNull
    },
    {
        title: '质押原因',
        dataIndex: 'reason',
        key: 'reason',
        render: transferStringNull
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: transferStringNull
    },
    {
        title: '公告日期',
        dataIndex: 'fileDate',
        key: 'fileDate',
        render: (text) => moment(text).format('YYYY-MM-DD')
    }
];

//重要股东质押表头
export const  pledgorsCol = [
    {
        title: '序号',
        dataIndex: 'num',
        key: 'num',
        render: (text, item, index) => {
            return index + 1;
        },
        // width: '5%'
    },
    {
        title: '股东名称',
        dataIndex: 'shareHolder',
        key: 'shareHolder',
        // width: '14%'
        render: transferStringNull
    },
    {
        title: '最新质押笔数',
        dataIndex: 'latestCount',
        key: 'latestCount',
        render: transferStringNull
    },
    {
        title: '剩余质押股数（股）',
        dataIndex: 'lastAmount',
        key: 'lastAmount',
        render: transferStringNull
    },
    {
        title: '类型',
        dataIndex: 'shareHolderType',
        key: 'shareHolderType',
        render: (text) => text == 0 ? '个人' : '公司'
    },
    {
        title: '更新日期',
        dataIndex: 'updateTimeEastmoney',
        key: 'updateTimeEastmoney',
        render: (text) => moment(text).format('YYYY-MM-DD')
    }
];
