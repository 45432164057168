import styles from './style.scss';
import React, { useState, useEffect } from 'react';
import TrianglePillar from './Line';
import Radios from './radio';
import { getQueryImpIndexCompany } from './service';

/**
 * @desc  重要节点企业名单
 */
let currentType = 1;

export default function BigScreen(props) {
  const [xData, setXData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const radiosData = [
    { name: '上会通过', value: 1 },
    { name: '注册生效', value: 2 }
  ];
  const { address, style } = props;
  function queryImpIndexCompany(type) {
    currentType = type;
    getQueryImpIndexCompany({ area: address, type: type }).then(res => {
      if (res.success) {
        let arr = [];
        let newArr = [];
        res.data.forEach((item, indexes) => {
          item.impIndexCompanyCountVos.forEach((element, index) => {
            if (index === 0) {
              newArr.unshift(element.meetingDate);
            }
            if (indexes === 0) {
              arr = [
                ...arr,
                {
                  name: element.area,
                  type: 'line',
                  data: [element.count],
                  areaStyle: {}
                }
              ];
            } else {
              arr[index].data.unshift(element.count);
            }
          });
        });
        setXData([...newArr]);
        setSeriesData([...arr]);
      }
    });
  }
  useEffect(() => {
    queryImpIndexCompany(currentType);
  }, [address]);

  return (
    <div className={styles.listOfEnterprises} style={{ ...style }}>
      <div className={`clearfix ${styles.listOfEnterprisesRadio}`}>
        <div className="fr">
          <Radios
            data={radiosData}
            defaultValue={1}
            onChange={v => queryImpIndexCompany(v.target.value)}
          />
        </div>
      </div>
      <div style={{ height: '87%' }}>
        <TrianglePillar xData={xData} seriesData={seriesData} />
      </div>
    </div>
  );
}
