import React from 'react';
import RightSecond from './FinancialRiskRadar';
/**
 * @desc  右二区块显示
 */
export default function RightSecondIndex({ address, style }) {
  return (
      <RightSecond address={address} />
  );
}

