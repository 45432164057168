import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { limitString } from '../../../utils/utils'
// import { loadingOption } from '../../../echarts/theme';
/**
 * @desc 饼图
 */
export default class Pie extends PureComponent {
    getOption(datas) {
        const { name = '' } = this.props;
        const colors = ['#0293DC', '#36CBCB', '#4ECB73', '#FBD437', '#F1637B'];
        return {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} :{c}",
                backgroundColor: 'rgba(16,70,118,0.68)',
                borderColor: '#0293DC',
                borderWidth: 1,
                textStyle: { color: '#fff' },
            },
            legend: {
                orient: 'vertical',
                right: '20',
                y: 'center',
                textStyle: { color: '#fff' },
                formatter: function (params) {
                    return limitString(6, params)
                },
            },
            series: [
                {
                    name: name,
                    type: 'pie',
                    radius: '55%',
                    center: ['35%', '50%'],
                    data: datas.sort(function (a, b) { return a.value - b.value; }),
                    // roseType: 'radius',
                    label: {
                        normal: {
                            formatter: function (params) {
                                return `${limitString(3,params.name)}${params.percent}%`
                            },
                        }
                    },
                    labelLine: {
                        normal: {
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                    itemStyle: {
                        normal: {
                            shadowBlur: 200,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    }
                }
            ],
            color: colors,
        };
    }
    render() {
        const { style = { height: '250px' }, datas } = this.props;
        return (
            <div style={style}>
                {
                    datas.loading ? "" :
                        datas.data.length ?
                            <ReactEcharts
                                option={this.getOption(datas.data)}
                                notMerge={true}
                                style={{ height: '100%' }}
                            // loadingOption={loadingOption}
                            /> :
                            <Empty />
                }
            </div>
        );
    }
}
