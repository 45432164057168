import React, { useEffect } from 'react';
import { Typography } from 'antd';
import Title from '../../../common/title';
import ExpertList from './expertList';
import GroupList from './groupList';

export default function ExpertIndex({ history, setBannerShow }) {
  useEffect(() => {
    setBannerShow(false);
  }, []);
  return (
    <div>
      <Typography.Title level={2}>投行业务与智能投顾</Typography.Title>
      <Title level={2} text='专家列表' />
      <ExpertList indexShow history={history} />
      <Title level={2} text='专家团队' />
      <GroupList indexShow history={history} />
    </div>
  );
}
