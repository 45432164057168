/**
 * @author Ray
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.scss';
import { Button, Form, Input, Tag, Typography } from 'antd';
import VerifyCode from '../../../common/verifyCode';
import VerifyPhone from '../../../common/verifyPhone';
import ErrorTip from '../../../common/errorTip';
import { UserType, API, LEVELS } from '../../../constants/enum';
import { random } from '../../../utils/utils';
import moment from 'moment';
import { TimeFormat } from '../../../constants/enum';
import useStorage from '../../../hooks/useStorage';
import { asyncValidator } from '../../../utils/validator';
import { validateOldPassword } from '../services';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import getKeyHook from '../../../hooks/getKeyHook';

/**
 *
 * @param form
 * @param onSubmit
 * @returns {*}
 * @constructor
 */
function AccountForm({ form, onSubmit = () => {} }) {
    const phoneRef = useRef(null);
    const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();

    const passwordValidator = useRef(
        asyncValidator(
            validateOldPassword,
            <ErrorTip text="密码错误！" />,
            undefined,
            undefined,
            undefined,
            v => {
                return getRsaPassWord(v);
            }
        )
    );
    // const [user] = useStorage('UserInfo');

    const { user } = useContext(GlobalContext);
    const codeRef = useRef(null);
    const [codeHash, setHash] = useState(random(5));
    const [codeStatus, setCodeStatus] = useState(false);

    function handleSubmit() {
        form.validateFields((error, values) => {
            if (!error) {
                getRsaPreparedAsync().then(function(){
                    onSubmit({
                        ...values,
                        password: getRsaPassWord(values.password)
                    })
                        .then(success => {
                            if (success) form.resetFields();
                        })
                        .finally(() => codeRef.current.reset());
                });
            }
        });
    }

    function handleCodeClick() {
        setHash(random(5));
    }

    // 验证码验证之后的回调
    function validateCode(success) {
        setCodeStatus(success);
    }

    // 手机验证码 发送手机验证码之前需验证手机号码和图片验证码是否正确
    function getCode() {
        form.validateFields(
            ['phone', 'password', 'imgCaptcha'],
            (errors, values) => {
                if (!errors) {
                    phoneRef.current.toSend(user?.phone, 6, values.imgCaptcha); // 倒计时并发送验证码
                }
            }
        );
    }

    return (
        <Form
            className={styles.formRoot}
            labelCol={{ xs: { span: 12 }, sm: { span: 10 } }}
            wrapperCol={{ xs: { span: 12 }, sm: { span: 14 } }}
            onSubmit={handleSubmit}
        >
            <Form.Item label="用户类型">
                <Typography.Text>{UserType[(user?.type)]}</Typography.Text>
                <Tag style={{ marginLeft: 16 }} color="green">
                    {LEVELS[user?.level - 1]}
                </Tag>
                {user?.level !== 1 && (
                    <Typography.Text type="warning">
                        到期时间&nbsp;&nbsp;
                        {user?.expireTime &&
                            moment(user?.expireTime, 'x').format(TimeFormat)}
                    </Typography.Text>
                )}
            </Form.Item>
            <Form.Item label="登录用户名">
                <Typography>{user?.username}</Typography>
            </Form.Item>
            <Form.Item label="手机号码">
                <Typography>{user?.phone}</Typography>
            </Form.Item>
            <Form.Item required label="密码">
                {form.getFieldDecorator('password', {
                    validateTrigger: 'onBlur',
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请输入密码" />
                        },
                        passwordValidator.current
                    ]
                })(
                    <Input.Password
                        placeholder="请输入密码"
                        className={styles.formInput}
                    />
                )}
            </Form.Item>
            <Form.Item required label="图片验证码">
                {form.getFieldDecorator('imgCaptcha', {
                    rules: []
                })(
                    <VerifyCode
                        ref={codeRef}
                        callBack={validateCode}
                        showErrTip={codeStatus}
                        onCodeClick={handleCodeClick}
                        template={6}
                        codeSrc={`${API.V1}/sso/kaptcha?${codeHash}`}
                    />
                )}
            </Form.Item>
            <Form.Item required label="短信验证码">
                {form.getFieldDecorator('phoneCaptcha', {
                    rules: [
                        {
                            required: true,
                            message: <ErrorTip text="请输入短信验证码" />
                        }
                    ]
                })(<VerifyPhone handleClick={getCode} ref={phoneRef} />)}
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 22, offset: 2 }
                }}
                label=""
                htmlType="submit"
            >
                <div className={styles.center}>
                    <Button onClick={handleSubmit} type={'primary'}>
                        注销
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default function({ onSubmit }) {
    const [E, setE] = useState(null);

    useEffect(function() {
        const Element = Form.create({ name: 'accountForm' })(AccountForm);

        setE(<Element onSubmit={onSubmit} />);
    }, []);

    return E;
}
