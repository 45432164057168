import React, { useState, useEffect } from 'react';
import Body from './layout/body';
import TopTitle from './component/topTitle';
import Drawer from './component/drawer';
import Card from './component/card';
import styles from './styles.scss';
import { Selector } from '../merging/home/apply';
import KCDiamondChart from './kCDiamondChart';
import BigScreenMap from './map';
import Industry from './industry';
import Intermediary from './intermediary';
import ListOfEnterprises from './listOfEnterprises';
import selectorFramePic from './images/selectorFrame.png';
import Tree from './tree/index';
import DiamondNum from './diamondNum';
import ListedCompanies from './listedCompanies';
import Financing from './financing';
import { Permission } from '../../hooks/useAuth';
import { getAreas } from '../diamondScore/service';

export default function BigScreen() {
  const [address, setAddress] = useState('全国');
  const [provinces, setProvince] = useState([]);

  useEffect(() => {
    getAreas().then(res => {
      setProvince(res?.data?.map(d => d.name) || []);
    });
  }, []);
  return (
    <Permission permission={489}>
      <Body>
        <TopTitle />
        <Drawer position="left" style={{ width: '28vw' }}>
          <Card title="数量趋势" style={{ width: '28vw', height: '24vh' }}>
            <ListOfEnterprises address={address} style={{ height: '100%' }} />
          </Card>
          <Card
            title="行业内优质企业分布情况"
            style={{ width: '28vw', height: '24vh' }}
          >
            <Industry address={address} style={{ height: '100%' }} />
          </Card>
          <Card title="科创钻石指数" style={{ width: '28vw', height: '24vh' }}>
            <DiamondNum address={address} style={{ height: '100%' }} />
          </Card>
          <Card
            title={'科创钻石指数横向对比'}
            style={{ width: '28vw', height: '23vh' }}
          >
            <KCDiamondChart style={{ height: '100%' }} />
          </Card>
        </Drawer>
        <Drawer position="right" style={{ width: '28vw' }}>
          <div
            className={styles.areaSelect}
            style={{
              background: `url(${selectorFramePic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%'
            }}
          >
            <Selector
              defaultValue={address}
              style={{ width: 200, height: '6vh' }}
              placeholder="请选择区域"
              options={[...provinces]}
              onChange={addr => {
                // 只展示北京市下属地区的数据，其他国内地区展示全国数据
                setAddress(addr);
              }}
            />
          </div>
          <Card
            title="融资金额分布"
            style={{ width: '28vw', height: '30.3vh' }}
          >
            <Financing style={{ height: '100%' }} />
          </Card>
          <Card
            title="拟上市企业分布"
            style={{ width: '28vw', height: '30.3vh' }}
          >
            <ListedCompanies address={address} style={{ height: '100%' }} />
          </Card>
          <Card
            title="中介信息概览"
            style={{ width: '28vw', height: '29.3vh' }}
          >
            <Intermediary address={address} style={{ height: '100%' }} />
          </Card>
        </Drawer>
        <div>
          <BigScreenMap address={address} style={{ height: '60vh' }} />
          <Tree area={address} style={{ height: '38vh', width: '41vw' }} />
        </div>
      </Body>
    </Permission>
  );
}
