import React from 'react';
import styles from './styles.scss';
import framePic from '../images/topLogoFrame.png';
import WechatIMG from '../../header/images/WechatIMG.png';
/**
 * @desc 顶部中间的title
 * @constructor
 */
export default function TopTitle() {
    return (
        <div
            className={styles.topTitle}
            style={{
                background: `url(${framePic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%'
            }}
        >
            <img src={WechatIMG} style={{ width: 38 }} />
            &nbsp;&nbsp; 钻石指数全景分析
        </div>
    );
}
