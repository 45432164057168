import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';

export default function Drawer({ position, children, style }) {
  return (
    <div
      className={classnames(styles.drawer, {
        [styles.rightDrawer]: position === 'right',
        [styles.leftDrawer]: position === 'left'
      })}
      style={{ ...style }}
    >
      {children}
    </div>
  );
}

Drawer.defaultProps = {
  position: 'left'
};
