import React, { useState, useEffect } from 'react';
import RightFirst from './RiskFocusRose';
/**
 * @desc  行业地域分布
 */
export default function RightFirstIndex({ address, style = {} }) {
 
  return (
    <RightFirst address={ address } />
  );
}
