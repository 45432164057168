import React from 'react';
import RightThird from './FundRaisingBar'

/**
 * @desc 再融资情况
 * @returns {*}
 * @constructor
 */
export default function RightThirdIndex({ address }) {
  return (
    <RightThird address={address} style={{ height: '100%' }}/>
  );
}