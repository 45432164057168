/**
 * @author hjie
 * 问卷详情
 */

import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import { getExamsInfo, saveAnswer } from '../service';
import { StatusCode } from 'constants/enum';
import ErrorTip from 'common/errorTip';
import { Form, Input, Radio, Button, Checkbox, Modal, message } from 'antd';
import { isNotEmpty } from '../../../../utils/utils';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import BackBtn from 'common/backBtn';
import { Object } from 'core-js';

const { TextArea } = Input;

const AnswersType = {
    1: '单选',
    2: '多选',
    3: '填空题'
};

// 是否选中
const IsSelect = {
    Not: 2, // 未选中
    Yes: 1 // 选中
};

//
const Status = {
    save: 1, // 保存
    submit: 2 // 提交
};

function DetailForm({ form, match, history }) {
    const { getFieldDecorator } = form;
    const [examsInfo, setExamsInfo] = useState(null);
    // const [user] = useStorage('UserInfo');

    const { user } = useContext(GlobalContext);
    // 问卷详情信息
    function getInfo() {
        const { id } = match.params;
        getExamsInfo({
            examId: id
        }).then(res => {
            const { statusCode, data } = res;
            if (statusCode === StatusCode.success) {
                setExamsInfo(data);
            }
        });
    }
    useEffect(() => {
        getInfo();
    }, []);

    const [showTip, setShowTip] = useState(false);
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                let keyArr = Object.keys(err);
                if(keyArr.length === 1 && keyArr[0] === 'companyName'){
                    setShowTip(false);
                }else{
                    setShowTip(true);
                }
            } else {
                setShowTip(false);
                if(Status.submit == 2){
                    Modal.confirm({
                        title: '确认提交吗?',
                        cancelText: '取消',
                        okText: '确认',
                        onOk() {
                            toSave(values, Status.submit);
                        }
                    });
                }else{
                    toSave(values, Status.submit);
                }
                
            }
        });
    };

    function toSave(values, status) {
        const { companyName, ...others } = values;
        const { id } = match.params;
        for (let key in others) {
            const [type, index] = key.split('-');
            let item = examsInfo.questionList[index];
            const currentIem = others[key];
            // 吧选择的答案赋值给原来的questionList
            if (type == '1') {
                let current = item.options.find(ele => ele.num == currentIem);
                if (current) {
                    current['select'] = IsSelect.Yes;
                }
            } else if (type === '2') {
                item.options.forEach(element => {
                    if (currentIem.includes(element.num)) {
                        element['select'] = IsSelect.Yes;
                    }
                });
            } else if (type === '3') {
                item['content'] = currentIem;
            }
        }
        saveAnswer({
            status, // 1=保存 2=提交
            companyName,
            examId: id,
            answers: examsInfo.questionList
        }).then(res => {
            const { statusCode } = res;
            if (statusCode === StatusCode.success) {
                message.success(res.message);
                if(isNotEmpty(user)){
                    history.push('/listingTraining/coordination');
                }else{
                    setExamsInfo(null);
                    getInfo();
                }
            } else {
                message.error(res.message);
            }
        });
    }

    function getDom(type, options, isNot) {
        let Dom = '';
        if (type === 1) {
            Dom = (
                <Radio.Group disabled={isNot}>
                    {options.map(({ name, num }) => (
                        <Radio value={num} key={num}>
                            {name}
                        </Radio>
                    ))}
                </Radio.Group>
            );
        } else if (type === 2) {
            Dom = (
                <Checkbox.Group style={{ width: '100%' }} disabled={isNot}>
                    {options.map(({ name, num }) => (
                        <Checkbox value={num} key={num}>
                            {name}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            );
        } else {
            Dom = (
                <TextArea
                    placeholder="请输入..."
                    disabled={isNot}
                    maxLength={500}
                />
            );
        }
        return Dom;
    }

    // 根据不同类型的问题，设置初始值
    function getInitialValue(item) {
        let InitialValue = '';
        const { type } = item;
        if (type === 1) {
            let current = item.options.find(
                ele => ele['select'] === IsSelect.Yes
            );
            if (current) {
                InitialValue = current.num;
            }
        } else if (type === 2) {
            InitialValue = [];
            item.options.forEach(element => {
                if (element['select'] == IsSelect.Yes) {
                    InitialValue.push(element.num);
                }
            });
        } else {
            InitialValue = item.content;
        }
        return InitialValue;
    }

    function isSave() {
        let values = form.getFieldsValue();
        toSave(values, Status.save);
    }
    if (examsInfo) {
        const { name, questionList, isSubmit, answerVo } = examsInfo;
        const isNot = isSubmit === 1 || isSubmit === 2; // 是否已经提交/保存过问卷
        const disabled = !!(isSubmit === 2); // 是否已提交
        let itemList = isNot ? answerVo.answers : questionList;
        let companyName = isNot ? answerVo.companyName : examsInfo.companyName;
        return (
            <>
            { isNotEmpty(user) && <div style= {{marginBottom: '20px'}}><BackBtn history = {history} /></div> }
            <div className={styles.examDetail}>
                <h2
                    className={classnames('titleLine', styles.title)}
                    mark="\ \ \ "
                >
                    {name}
                </h2>
                <div className={styles.form}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Item label="企业名称：" className="companyItem">
                            {getFieldDecorator('companyName', {
                                initialValue: companyName || '',
                                rules: [{ required: true, message: '企业名称未填写' }]
                            })(
                                <Input
                                    placeholder="请输入企业名称"
                                    disabled={!!companyName}
                                    maxLength={50}
                                    style = {{width: '310px'}}
                                />
                            )}
                        </Form.Item>
                        {itemList &&
                            itemList.map((ele, index) => {
                                const { title, type, options } = ele;
                                const name = AnswersType[type];
                                const Dom = getDom(type, options, disabled);
                                return (
                                    <Form.Item
                                        label={`${index +
                                            1}.${title}（${name}）`}
                                        key={title}
                                    >
                                        {getFieldDecorator(`${type}-${index}`, {
                                            initialValue: isNot
                                                ? getInitialValue(ele)
                                                : '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '必选'
                                                }
                                            ]
                                        })(Dom)}
                                    </Form.Item>
                                );
                            })}
                        <Form.Item className="btnItem">
                            {
                                isNotEmpty(user) && 
                                <Button
                                    type="primary"
                                    disabled={disabled}
                                    className="saveBtn"
                                    onClick={isSave}
                                >
                                    保存
                                </Button>
                            } 
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={disabled}
                            >
                                提交
                            </Button>
                            <div className={styles.errorBox}>
                                {showTip && <ErrorTip text="有题目尚未回答" />}
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            </>
        );
    }
    return <div></div>;
}
const FormBox = Form.create()(DetailForm);
export default FormBox;
