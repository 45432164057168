import React, { useEffect, useState } from 'react';
import Body from './layout/body';
import TopTitle from './component/topTitle';
import Drawer from './component/drawer';
import Card from './component/card';
import styles from './styles.scss';
import { Selector } from '../merging/home/apply';
import { Provinces } from '../../constants/enum';
import BigScreenMap from './map';
import ListOfEnterprises from './listOfEnterprises';
import selectorFramePic from './images/selectorFrame.png';
import DiamondNum from './diamondNum';
import DiamondNumThead from './diamondNumThead';
import DiamondNumStatus from './diamondNumStatus';
import Score from './score';
import Top5 from './top5';
import Statistics from './statistics';
import Summary from './summary';
import { Permission } from '../../hooks/useAuth';
import { getAreas } from './service';

export default function BigScreen() {
  // 后台要把全国设成“所有地区”
  const [address, setAddress] = useState('全国');
  const [provinces, setProvince] = useState([]);

  useEffect(() => {
    getAreas().then(res => {
      setProvince(res?.data?.map(d => d.name) || []);
    });
  }, []);

  return (
    <Permission permission={490}>
      <Body>
        <TopTitle />
        <Drawer position="left" style={{ width: '28vw' }}>
          <Card title="科创钻石评分" style={{ height: '24vh' }}>
            <Score
              area={address == '全国' ? '所有地区' : address}
              style={{ height: '100%' }}
            />
          </Card>
          <Card title="最近上市企业" style={{ height: '24vh' }}>
            <DiamondNum
              area={address == '全国' ? '所有地区' : address}
              style={{ height: '100%' }}
            />
          </Card>
          <Card title="申报企业数量趋势" style={{ height: '24vh' }}>
            <ListOfEnterprises
              area={address == '全国' ? '所有地区' : address}
              style={{ height: '100%' }}
            />
          </Card>
          <Card title={`${address}重点企业TOP5`} style={{ height: '23vh' }}>
            <DiamondNumThead
              area={address == '全国' ? '所有地区' : address}
              style={{ height: '100%' }}
            />
          </Card>
        </Drawer>
        <Drawer position="right" style={{ width: '28vw' }}>
          <div
            className={styles.areaSelect}
            style={{
              background: `url(${selectorFramePic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              height: '6vh'
            }}
          >
            <Selector
              defaultValue={address}
              style={{ width: 200 }}
              placeholder="请选择区域"
              options={[...provinces]}
              onChange={addr => {
                // 只展示北京市下属地区的数据，其他国内地区展示全国数据
                setAddress(addr);
              }}
            />
          </div>
          <Card title="申报科创板企业概况" style={{ height: '20vh' }}>
            <Summary address={address} style={{ height: '100%' }} />
          </Card>
          <Card style={{ height: '28vh' }}>
            <Top5 address={address} style={{ height: '100%' }} />
          </Card>
          <Card title="申报科创板企业统计情况" style={{ height: '42vh' }}>
            <Statistics address={address} style={{ height: '100%' }} />
          </Card>
        </Drawer>
        <div>
          <BigScreenMap address={address} style={{ height: '67vh' }} />
          <div
            style={{
              width: '43vw',
              margin: '0 auto'
            }}
          >
            <Card
              title="科创板企业申报状态"
              style={{ height: '31vh', width: '42vw' }}
            >
              <DiamondNumStatus
                style={{ height: '28vh' }}
                area={address == '全国' ? '所有地区' : address}
              />
            </Card>
          </div>
        </div>
      </Body>
    </Permission>
  );
}
