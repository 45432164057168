import React, {useEffect, useState} from 'react';
import style from './style.scss'
import Pic1 from '../images/1.png';
import Pic2 from '../images/2.png';
import Pic3 from '../images/3.png';
import Pic4 from '../images/4.png';
import {marketMakingIndex,componentIndex,innovateIndex} from "./service";
import {Empty} from "antd";
import ViewItem from "./ViewItem";


export default function TopView({type}) {
    const [data, setData] = useState({
        indexNum: 0,
        time: 0,
        transactionAmount: 0,
        upAndDownRange: 0,
    });

    useEffect(()=>{
        type===1? marketMakingIndex().then(({ success, data })=>{
            if(success){
                setData(data);
            }
            else {
                setData([]);
            }
        }): type===2? componentIndex().then(({ success, data })=>{
            if(success){
                setData(data);
            }
            else {
                setData([]);
            }
        }):type===3?innovateIndex().then(({ success, data })=>{
            if(success){
                setData(data);
            }
            else {
                setData([]);
            }
        }):<Empty />
    },[type]);

    return(
        <div className={style.mapTool}>
            <br />
            <ViewItem title={'时间'} picpath={Pic1} val={data.time} w={'206px'} lw={'45%'} rw={'55%'} />
            <ViewItem title={'指数'} picpath={Pic2} val={data.indexNum} />
            <ViewItem title={'涨跌幅'} picpath={Pic3} val={data.upAndDownRange} />
            <ViewItem title={'交易金额'} picpath={Pic4} val={data.transactionAmount} />
        </div>
    )
}
