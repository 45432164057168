/**
 * @desc 入库企业分析-基础信息-中标信息
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { winningInformationColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { bids } from './service';

/**
 *
 * @returns {*}
 */
export default function WinningInformation({match}) {
    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={winningInformationColumns}
            getData={bids}
            parseData={res =>
                (isNotEmpty(res) && isNotEmpty(res.data) && res.data) || []
            }
            locale={{emptyText: '未发现企业的中标信息记录。不排除存在未公示或时间相对滞后的情况。'}}

        />
    );
}
