import React from 'react';
import styles from './styles.scss';
import bgpic from '../images/new_leftMiddle.png';
import CardHeader from './cardHeader';
import leftIcon from '../images/titleLIcon.png';
import rightIcon from '../images/titleRIcon.png';

export default function Card({ children, title, style }) {
    return (
        <div
            className={styles.card}
            style={{
                background: `url(${bgpic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                ...style
            }}
        >
            {title && (
                <CardHeader
                    prefix={<img src={leftIcon} alt="prefix" />}
                    suffix={<img src={rightIcon} alt="suffix" />}
                    title={title}
                />
            )}
            <div className={styles.body}>{children}</div>
        </div>
    );
}
