import * as Types from './types';
import { makeReducer } from '../../../utils/utils';

const reducers = {
    [Types.CONSULTANT_SHOW_MODAL](state, action) {
        return {
            ...state,
            modal: {
                ...action.payload
            }
        };
    },
    [Types.CONSULTANT_CLOSE_MODAL](state, action) {
        return {
            ...state,
            modal: {
                ...action.payload
            }
        };
    }
};

export default makeReducer(reducers);
