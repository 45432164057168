/**
 * @author Ray
 * @desc 经营信息的输入框表
 */

import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Col, Input, Row, Form, InputNumber, DatePicker } from 'antd';
import YearPicker from '../../../common/yearPicker';
import styles from './inputTable.scss';
import { isEmpty, isNotEmpty } from '../../../utils/utils';
import moment from 'moment';

InputTable.defaultProps = {
    readonly: false,
    dataSource: [],
    rows: [],
    years: [],
    columns: []
};

/**
 *
 * @param readonly
 * @param columns
 * @param rows
 * @param years
 * @param dataSource
 * @param onChange
 * @returns {*}
 * @constructor
 */
const InputsElement = React.forwardRef(function(
    { readonly, columns, rows, dataSource, onChange, form, onInit, isPreview },
    ref
) {
    useEffect(function() {
        onInit(form);
    }, []);

    useImperativeHandle(ref, () => ({
        form
    }));

    return (
        <div className={styles.inputTable} id="inputTable">
            <Row>
                {columns.map(({ title, dataIndex }, index) => {
                    return (
                        <Col span={6} key={index}>
                            {index === 0 ? (
                                <Input disabled value={title} maxLength={50} />
                            ) : (
                                form.getFieldDecorator(dataIndex + index, {
                                    initialValue: moment()
                                        .add(-index, 'year')
                                        .format('YYYY')
                                })(
                                    <YearPicker
                                        scope={[
                                            moment().format('YYYY') - 30,
                                            moment().format('YYYY') - 1
                                        ]}
                                        allowClear={false}
                                        disabled={isPreview}
                                        getCalendarContainer={()=> document.getElementById('inputTable') }
                                    />
                                )
                            )}
                        </Col>
                    );
                })}
            </Row>
            {rows.map(function(r, i) {
                let isOdd = i % 2 === 1;
                return (
                    <Row key={i}>
                        <Col span={6}>
                            <Input disabled value={r.title} />
                        </Col>
                        {[1, 2, 3].map(function(value) {
                            return (
                                <Col key={value} span={6}>
                                    {form.getFieldDecorator(
                                        r.dataIndex + value,
                                        {
                                            rules: [
                                                {
                                                    required: i <= 1,
                                                    message: 'error'
                                                }
                                            ]
                                        }
                                    )(
                                        isOdd ? (
                                            <InputNumber
                                                precision={2}
                                                maxLength={15}
                                                min={0}
                                                disabled={isPreview}
                                            />
                                        ) : (
                                            <Input
                                                maxLength={50}
                                                disabled={isPreview}
                                            />
                                        )
                                    )}
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </div>
    );
});

export const parser = values => {
    if (isNotEmpty(values)) {
        let obj = {};

        values.forEach(function(item, index) {
            // 移除id和type，避免form表单的警告
            delete item.id;
            delete item.type;
            delete item.creditCode;
            let _obj = {};
            let keys = Object.keys(item);

            keys.forEach(function(k) {
                _obj[k + (index + 1)] = item[k];
            });

            Object.assign(obj, _obj);
        });

        return obj;
    } else {
        return values;
    }
};

export const revert = values => {
    let data = [];
    let keys = [];
    if (isNotEmpty(values)) {
        keys = Object.keys(values);
    }

    const parser = (key, i) => {
        let obj = { [key.replace(String(i + 1), '')]: values[key] };
        if (isEmpty(data[i])) {
            data[i] = obj;
        } else {
            data[i] = Object.assign(data[i], obj);
        }
    };

    if (isNotEmpty(keys)) {
        keys.map(function(key) {
            if (/1$/.test(key)) {
                parser(key, 0);
            } else if (/2$/.test(key)) {
                parser(key, 1);
            } else if (/3$/.test(key)) {
                parser(key, 2);
            }
        });
    }

    return data;
};

export default function InputTable({
    onChange = () => {},
    rows,
    columns,
    onInit = () => {},
    isPreview
}) {
    const [Element, setElement] = useState(null);

    function handleChange(a, b, values) {
        const data = revert(values);

        onChange(data);
    }

    useEffect(function() {
        const Element_ = Form.create({
            name: 'inputs',
            onValuesChange: handleChange
        })(InputsElement);

        setElement(
            <Element_
                rows={rows}
                columns={columns}
                onInit={onInit}
                isPreview={isPreview}
            />
        );
    }, []);

    return Element;
}
