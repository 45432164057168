/**
 * @author Ray
 * @description 行
 */

import React, { useEffect } from 'react';
import styles from './row.scss';
import { Form, Row, Col, InputNumber, Input } from 'antd';
import classnames from 'classnames';
import { ProfitKeys, AssetsKeys, CashKeys } from '../../../constants/enum';


function RowEx(props) {
    const { form, data, onInit, year, type, readOnly } = props;
    useEffect(() => {
        onInit('rowEx', form);
    }, [])
  
    return (
        <Row>
            {data && data.length > 0 ? data.map(function(item, index) {
                let isOdd = index % 2 === 0;
                return (
                    <Form
                        style={{
                            height: 50,
                        }}
                    >
                        <Col
                            className={classnames('ellipsis', styles.bodyCell)}
                            style={isOdd ? {
                                    width: `${(100 / 2).toFixed(3)}%`,
                                }:{
                                    width: `${(100 / 2).toFixed(3)}%`,
                                    background: 'hsla(0,0%,86.7%,.3)',
                                    borderBottom: '1px solid #ddd',
                                    borderTop: '1px solid #ddd'
                                }
                            }
                        >
                            <Form.Item
                                style={{
                                    border: 'none',
                                    marginBottom: 0
                                }}
                            >
                               {item.item} 
                            </Form.Item>
                        </Col>
                        <Col
                            className={classnames('ellipsis', styles.bodyCell)}
                            style={isOdd ? {
                                    width: `${(100 / 2).toFixed(3)}%`,
                                }:{
                                    width: `${(100 / 2).toFixed(3)}%`,
                                    background: 'hsla(0,0%,86.7%,.3)',
                                    borderBottom: '1px solid #ddd',
                                    borderTop: '1px solid #ddd'
                                }
                            }
                        >
                            <Form.Item
                                style={{
                                    border: 'none',
                                    marginBottom: 0,
                                    marginTop: '-3px'
                                }}
                                name="number"
                            >
                                {form.getFieldDecorator(item.item, {
                                        initialValue: item[year] || '--'
                                    })(
                                        <InputNumber
                                            precision={2}
                                            maxLength={15}
                                            // min={0}
                                            disabled={readOnly}
                                            // defaultValue={v}
                                            style={{ width: '30%' }}
                                        />  
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                );
            }) 
                :
            type == 2 ? 
                ProfitKeys.map((item, index) => {
                    let isOdd = index % 2 === 0;
                    return (
                        <Form
                            style={{
                                height: 50,
                            }}
                        >
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0
                                    }}
                                >
                                    {item} 
                                </Form.Item>
                            </Col>
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0,
                                        marginTop: '-3px'
                                    }}
                                    name="number"
                                >
                                    {form.getFieldDecorator(item, {
                                            initialValue: '--'
                                        })(
                                            <InputNumber
                                                precision={2}
                                                maxLength={15}
                                                // min={0}
                                                disabled={readOnly}
                                                // defaultValue={v}
                                                style={{ width: '30%' }}
                                            />  
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                    );
                }) :
            type == 3 ?
                AssetsKeys.map((item, index) => {
                    let isOdd = index % 2 === 0;
                    return (
                        <Form
                            style={{
                                height: 50,
                            }}
                        >
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0
                                    }}
                                >
                                    {item} 
                                </Form.Item>
                            </Col>
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0,
                                        marginTop: '-3px'
                                    }}
                                    name="number"
                                >
                                    {form.getFieldDecorator(item, {
                                            initialValue: '--'
                                        })(
                                            <InputNumber
                                                precision={2}
                                                maxLength={15}
                                                // min={0}
                                                disabled={readOnly}
                                                // defaultValue={v}
                                                style={{ width: '30%' }}
                                            />  
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                    );
                }) :
            type == 4 ?
                CashKeys.map((item, index) => {
                    let isOdd = index % 2 === 0;
                    return (
                        <Form
                            style={{
                                height: 50,
                            }}
                        >
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0
                                    }}
                                >
                                    {item} 
                                </Form.Item>
                            </Col>
                            <Col
                                className={classnames('ellipsis', styles.bodyCell)}
                                style={isOdd ? {
                                        width: `${(100 / 2).toFixed(3)}%`,
                                    }:{
                                        width: `${(100 / 2).toFixed(3)}%`,
                                        background: 'hsla(0,0%,86.7%,.3)',
                                        borderBottom: '1px solid #ddd',
                                        borderTop: '1px solid #ddd'
                                    }
                                }
                            >
                                <Form.Item
                                    style={{
                                        border: 'none',
                                        marginBottom: 0,
                                        marginTop: '-3px'
                                    }}
                                    name="number"
                                >
                                    {form.getFieldDecorator(item, {
                                            initialValue: '--'
                                        })(
                                            <InputNumber
                                                precision={2}
                                                maxLength={15}
                                                // min={0}
                                                disabled={readOnly}
                                                // defaultValue={v}
                                                style={{ width: '30%' }}
                                            />  
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                    );
                }) : null
            }
        </Row>




        // <Col
        //     key={item.item}
        //     title={item.item}
        //     className={classnames('ellipsis', styles.bodyCell)}
        //     style={{
        //         width: `${(100 / data.length).toFixed(3)}%`
        //     }}
        // >
        //     {
        //         // isOdd ? 
        //         //     v 
        //         //     :
        //             form.getFieldDecorator(item.item, {
        //                 initialValue: item['2020']
        //             })(
        //                 <InputNumber
        //                     precision={2}
        //                     maxLength={15}
        //                     min={0}
        //                     // disabled={isPreview}
        //                     // defaultValue={v}
        //                     style={{ width: '30%' }}
        //                     // onChange={(val) => console.log(val, 'val')}
        //                 />  
        //             )
        //     }
        // </Col>


        // <Row>
        //     {data.map(function(v, i) {
        //         let isOdd = i % 2 === 0;
        //         return (
        //             <Col
        //                 key={v + i}
        //                 title={v}
        //                 className={classnames('ellipsis', styles.bodyCell)}
        //                 style={{
        //                     width: `${(100 / data.length).toFixed(3)}%`
        //                 }}
        //             >
        //                 {
        //                     isOdd ? 
        //                         v 
        //                         :
        //                         form.getFieldDecorator(v + i, {
        //                             initialValue: v
        //                         })(
        //                             <InputNumber
        //                                 precision={2}
        //                                 maxLength={15}
        //                                 min={0}
        //                                 // disabled={isPreview}
        //                                 // defaultValue={v}
        //                                 style={{ width: '30%' }}
        //                                 // onChange={(val) => console.log(val, 'val')}
        //                             />  
        //                         )
        //                 }
        //             </Col>
        //         );
        //     })}
        // </Row>
    );
}

export default Form.create({ name: 'rowEx' })(RowEx);
