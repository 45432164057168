import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

export function getData(data) {
    return request(`${API.V1}/auth/kcDriver/index/queryZsZs`, data );
}

//风险企业行业分布
export function industryRiskEnterprises(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/leftSecond/industryRiskBar`, params)
}
