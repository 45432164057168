import request from '../../utils/fetch';
import { API } from '../../constants/enum';

/**
 *
 * @param startYear
 * @param endYear
 * @param reportType
 * @param code
 * @param applyId
 * @param needUpdate
 * @param type
 */
export function callFinance(
    startYear,
    endYear,
    reportType,
    code,
    applyId,
    needUpdate,
    type
) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/findFinance`,
        {
            startYear,
            endYear,
            reportType,
            code,
            applyId,
            needUpdate,
            type
        },
        {
            method: 'POST'
        },
        () => {},
        false
    );
}

/**
 * @param type
 * @param year
 * @param companyProfitTable
 * @param companyLiabilitiesTable
 * @param companyCashFlowTable   
 */
export function submitProfitInfo(
    type,
    year,
    companyProfitTable,
    companyLiabilitiesTable,
    companyCashFlowTable
) {
    return request(
        `${API.V1}/policypractice/companyReport/update/finance`,
        {
            type,
            year,
            companyProfitTable,
            companyLiabilitiesTable,
            companyCashFlowTable
        },
        {
            method: 'POST'
        },
        () => {},
        false
    );
}
