/* eslint-disable react/no-multi-comp */
/**
 * @author Ray
 * @desc 融资信息
 */
import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from './styles.scss';
import Title from '../../../common/title';
import Table from '../../../common/tableEx';
import { Button, Divider, Icon, Typography } from 'antd';
import columns from './columns';
import classnames from 'classnames';
import Modal from '../../../common/modalEx';
import FinancingForm from './form';
import moment from 'moment';
import { DeleteButton, EditButton } from '../../../common/buttons';
import { getFinancingList } from './service';
import { GlobalContext } from '../../../common/globalStore/createProvider';

let editMode = false;

export default function(props) {
  // const { dataSource } = props;
  const formRef = useRef(null);
  const { user } = useContext(GlobalContext);
  const [query, setQuery] = useState(null);
  const [data, setData] = useState(undefined);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);

  function parseData(data) {
    return data?.data || [];
  }

  function handleEdit(record) {
    return function() {
      editMode = true;
      setData(record);
      const {
        date,
        financingRotation,
        valuation,
        financingMoney,
        investors
      } = record;

      setModalFormVisible(true);
      setTimeout(
        () =>
          formRef.current.form.setFieldsValue({
            date: moment(date),
            financingRotation,
            valuation,
            financingMoney,
            investors
          }),
        0
      );
    };
  }

  function handleDelete(record) {
    return function() {
      setData(record);
      setDeleteConfirmModal(true);
    };
  }

  function deleteRecord() {
    props
      .delete(data.id)
      .then(
        success =>
          success && setQuery({ ...query, reload: new Date().getTime() })
      );
    setDeleteConfirmModal(false);
    setData(undefined);
  }

  function handleSubmit(fieldsValues) {
    let { date } = fieldsValues;
    date = moment(date).format('x');
    fieldsValues.date = date;
    if(!fieldsValues.valuation) {
      delete fieldsValues.valuation;
      if(data){
        delete data.valuation;
      }
    }
    if (data) {
      props
        .update(Object.assign(data, fieldsValues))
        .then(
          success =>
            success && setQuery({ ...query, reload: new Date().getTime() })
        );
    } else {
      props
        .add(fieldsValues)
        .then(
          success =>
            success && setQuery({ ...query, reload: new Date().getTime() })
        );
    }
    setModalFormVisible(false);
    setData(undefined);
  }

  function updateRecord() {
    formRef.current.form.validateFields(function(errors, values) {
      if (!errors) {
        handleSubmit(values);
      }
    });
  }

  useEffect(() => {
    setQuery({ code: user?.creditCode });
  }, []);

  return (
    <section className={styles.financingRoot}>
      <Title text="融资情况" />
      <Divider />
      <div
        className={classnames(
          styles.topButtonBox,
          'marginTop forceMarginBottom'
        )}
      >
        <Button
          className="blueButton"
          icon="plus-circle"
          onClick={() => setModalFormVisible(true)}
        >
          添加
        </Button>
      </div>
      <Table
        query={query}
        getData={getFinancingList}
        parseData={parseData}
        pagination={{ pageSize: 5 }}
        columns={[
          ...columns,
          {
            key: 'actions',
            title: '操作',
            width: '25%',
            align: 'center',
            render: (t, record) => {
              return [
                <EditButton
                  key="edit"
                  text="编辑"
                  onClick={handleEdit(record)}
                />,
                <DeleteButton key="delete" onClick={handleDelete(record)} />
              ];
            }
          }
        ]}
      />
      <Modal
        closable
        key={modalFormVisible}
        visible={modalFormVisible}
        onCancel={() => {
          setData(undefined);
          setModalFormVisible(false);
        }}
        title="融资情况"
        onClose={() => setModalFormVisible(false)}
        onOk={updateRecord}
      >
        <FinancingForm wrappedComponentRef={formRef} />
      </Modal>
      <Modal
        visible={deleteConfirmModal}
        onCancel={() => setDeleteConfirmModal(false)}
        title="提示"
        onOk={deleteRecord}
        okText="确定"
        onClose={() => setDeleteConfirmModal(false)}
      >
        <div className="center">
          <Typography>确认删除该条数据吗？</Typography>
        </div>
      </Modal>
    </section>
  );
}
