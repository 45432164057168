import React from 'react';
/**
 * @author Ray
 */

export const Columns = [
    {
        title: '风险总分',
        dataIndex: 'totalRiskScore',
        key: 'totalRiskScore',
        align: 'center',
        width: '15%',
        render: text => (text ? text.toFixed(2) : '--')
    },
    {
        title: '财务风险',
        dataIndex: 'caiwuRiskScore',
        key: 'caiwuRiskScore',
        align: 'center',
        width: '13%',
        render: text => (text ? text.toFixed(2) : '--')
    },
    {
        title: '质押担保风险',
        dataIndex: 'zhiyadanbaoRiskScore',
        key: 'zhiyadanbaoRiskScore',
        align: 'center',
        width: '16%',
        render: text => (text ? text.toFixed(2) : '--')
    },
    {
        title: '舆情口碑',
        dataIndex: 'tyqgScore',
        key: 'tyqgScore',
        align: 'center',
        width: '15%',
        render: text => (text ? text.toFixed(2) : '--')
    },
    {
        title: '司法风险',
        dataIndex: 'shifaRiskScore',
        key: 'shifaRiskScore',
        align: 'center',
        width: '15%',
        render: text => (text ? text.toFixed(2) : '--')
    }
];
