import React, {useState, useRef, useEffect} from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import {
    getDataByType
} from './service';
import {isNotEmpty} from '../../../../utils/utils';
import getLocalMap from './service';

export default function Map({type, address}) {
    const mapSize = {
        all: {
            zoom: window.screen.width < 1920 ? 2.0 : 1.0,
            top: window.screen.width < 1920 ? '80' : '20',
            bottom: window.screen.width < 1920 ? '0' : '20',
        }
    };
    const echartsInstance = useRef(null);
    const [addressData, setAddressData] = useState([]);
    const [map, setMap] = useState();
    const [mapsize, setMapSize] = useState('all');

    useEffect(()=>{
        getLocalMap('all').then(res=>{
            echarts.registerMap('all',res);
            if (address == 'all') { 
                setMapSize('all')
            }
        })
    },[address]);

    useEffect(()=>{
        getDataByType(type).then(res=>{
            if (isNotEmpty(res) && isNotEmpty(res.data)){
                let data = res.data.map(item => {
                    const { xxssdq, xxsl } = item;
                    return {
                        name: xxssdq,
                        value: xxsl
                    }
                });
                setAddressData(data);
            }
        })
    },[type]);

    function getOption() {
        return{
            visualMap: {
                min: 0,
                max: 1000,
                left: window.screen.width < 1920 ? 20 : 40,
                bottom: window.screen.width < 1920 ? 20 : 40,
                showLabel: !0,
                text: ["高", "低"],
                textStyle: {
                    color: '#fff'
                },
                pieces: [{
                    gt: 1000,
                    label: "> 1000",
                    color: "#E86452"
                }, {
                    gte: 500,
                    lte: 1000,
                    label: "500 - 1000",
                    color: "#F6BD16"
                }, {
                    gte: 200,
                    lte: 500,
                    label: "200 - 500",
                    color: "#5D7092"
                }, {
                    gte: 50,
                    lte: 200,
                    label: "50 - 200",
                    color: "#5B8FF9"
                }, {
                    lt: 50,
                    label: "< 50",
                    color: "#5AD8A6"
                }],
                show: !0
            },
            series:[
                {
                    label: {
                        show: true,
                        color:'#fff',
                    },
                    type: 'map',
                    map: 'all',
                    data:addressData,
                    zoom: 1.2,
                    zoom: mapSize[mapsize].zoom,
                    top: mapSize[mapsize].top,
                    bottom: mapSize[mapsize].bottom,
                    layoutSize: 400,
                    nameMap:{
                        '北京市':'北京',
                        '上海市':'上海',
                        '天津市':'天津',
                        '广西壮族自治区':'广西省',
                        '新疆维吾尔自治区':'新疆自治区',
                        '重庆市':'重庆',
                    },
                }
            ]
        }
    }

    return (
        <div style={{height: '67vh', paddingTop: '180px'}}>
            <ReactEcharts
                notMerge={true}
                ref={echartsInstance}
                option={getOption()}
                style={{height: '100%'}}
            />
        </div>
    );
}

Map.defaultProps = {
    address: 'all'
};