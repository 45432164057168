import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc 科创行业查询
 */
export function getIndustry() {
    return request(`${API.V1}/auth/red/excellent/industry`);
}

/**
 * @desc 行业内优质企业分布情况
 */
export function getData(data) {
    return request(`${API.V1}/auth/red/excellent/distribution`, data);
}

/**
 * @desc 风险企业板块分布
*/
export function riskEnterprises(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/leftThird/stockRiskPie`, params);
}