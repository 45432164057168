import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc 企业估值
 * @param creditCode
 */
export function valuationMethods({ creditCode }) {
    return request(`${API.V1}/investbank/store/valuation/methods`, {
        creditCode
    });
}

/**
 * @desc  上市板块推荐
 * @param creditCode
 * @param qyxxId
 */
export function recommendPlates({ creditCode, qyxxId }) {
    return request(`${API.V1}/investbank/company/recommend/plate`, {
        creditCode,
        qyxxId
    });
}

/**
 * @desc  上市资格判断
 * @param creditCode
 * @param companyName
 * @param type
 */
export function listingJudge({ creditCode, companyName, type }) {
    return request(`${API.V1}/investbank/list/seniority`, {
        creditCode,
        companyName,
        type
    });
}

/**
 * @desc  上市资格判断(香港)
 * @param creditCode
 * @param rate
 */
export function listingJudgeHongKong({ creditCode, rate }) {
    return request(`${API.V1}/investbank/store/hongkong/seniority`, {
        creditCode,
        rate
    });
}

/**
 * @desc  科创评分详情
 * @param companyName
 * @param type
 */
export function kechuangScore({ companyName, type }) {
    return request(`${API.V1}/auth/tech/innovate/detail`, {
        companyName,
        type
    });
}

/**
 * @desc 根据倍率计算估值
 * @param code
 * @param method
 * @param rate
 */
export function fetchValuationByRatio({ code, method, rate }) {
    return request(`${API.V1}/investbank/valuation/method/value`, {
        creditCode: code,
        method,
        rate
    });
}

/**
 * @desc 估值方法推荐
 * @param code
 */
export function fetchRecommendMethod({ code }) {
    return request(`${API.V1}/investbank/valuation/method/recommend`, {
        creditCode: code
    });
}
