'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _uuid = require('../util/uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _common = require('../util/common');

var _merge = require('lodash/merge');

var _merge2 = _interopRequireDefault(_merge);

var _index = require('../config/index.js');

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Layer = function () {
    function Layer(root, handler, options) {
        (0, _classCallCheck3.default)(this, Layer);

        this.id = (0, _uuid2.default)();
        this.root = root;
        this.handler = handler;
        this.options = options;
        this.shapes = {};
    }

    (0, _createClass3.default)(Layer, [{
        key: 'hide',
        value: function hide() {}
    }, {
        key: 'show',
        value: function show() {}
    }, {
        key: 'clear',
        value: function clear() {}
    }, {
        key: 'getId',
        value: function getId() {
            return this.id;
        }
    }, {
        key: 'getType',
        value: function getType() {
            return this.type;
        }
    }, {
        key: 'getProxy',
        value: function getProxy() {
            return this.proxy;
        }
    }, {
        key: 'refresh',
        value: function refresh() {}
    }, {
        key: 'needsRefresh',
        value: function needsRefresh() {
            this._needsRefresh = true;
        }
    }, {
        key: 'getShapes',
        value: function getShapes() {
            return this.shapes;
        }
    }, {
        key: 'add',
        value: function add(shape) {
            if (!shape) {
                return;
            }

            if (!this.shapes) {
                this.shapes = {};
            }
            shape.setLayer(this);
            this.shapes[shape.id] = shape;
            shape.setDirty(true);
        }
    }, {
        key: 'remove',
        value: function remove(shape, delayRefresh) {
            if (!shape) {
                return;
            }

            var _shape = this.shapes[shape.id];
            if (_shape) {
                _shape.setLayer(null);
                delete this.shapes[shape.id];
                if (!delayRefresh) {
                    this._needsRefresh = true;
                }
            } else {
                var layer = shape.getLayer();
                if (layer) {
                    layer.remove(shape);
                }
            }
        }
    }, {
        key: 'removeAll',
        value: function removeAll() {
            var _this = this;

            if ((0, _common.isEmpty)(this.shapes)) {
                return;
            }

            for (var id in this.shapes) {
                var shape = this.shapes[id];
                // 此处先执行删除动画效果, 然后再从layer删除.
                shape.remove(function (_shape) {
                    _this.remove(_shape, true);
                });
            }

            this._needsRefresh = true;
        }
    }, {
        key: 'clearAnimation',
        value: function clearAnimation() {}
    }, {
        key: 'zoomIn',
        value: function zoomIn() {}
    }, {
        key: 'zoomOut',
        value: function zoomOut() {}
    }, {
        key: 'drag',
        value: function drag() {}
    }, {
        key: 'reset',
        value: function reset() {}
    }, {
        key: 'dispose',
        value: function dispose() {
            this.root.removeChild(this.container);
            this.id = null;
            this.type = null;
            this.root = null;
            this.handler = null;
            this.options = null;
            this.shapes = null;
            this.container = null;
            this._needsRefresh = null;
            this.proxy = null;
            this.proxyClass = null;
        }

        /**
         * 创建layer的容器
         */

    }, {
        key: 'createDOM',
        value: function createDOM(id) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var _options$zIndex = options.zIndex,
                zIndex = _options$zIndex === undefined ? 0 : _options$zIndex,
                _options$position = options.position,
                position = _options$position === undefined ? 'absolute' : _options$position,
                width = options.width,
                height = options.height;


            var dom = document.createElement('div');
            dom.id = id;
            //dom.onselectstart = function () {};

            var style = dom.style;
            style.zIndex = zIndex;
            style.position = position;
            style.top = 0;
            style.right = 0;
            style.bottom = 0;
            style.left = 0;
            style.width = width + 'px';
            style.height = height + 'px';
            style['-webkit-user-select'] = 'none';
            style['user-select'] = 'none';
            style['-webkit-touch-callout'] = 'none';
            style['-webkit-tap-highlight-color'] = 'rgba(0,0,0,0)';
            style['padding'] = 0;
            style['margin'] = 0;
            style['border-width'] = 0;
            return dom;
        }
    }, {
        key: 'createCanvas',
        value: function createCanvas(id) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var _options$zIndex2 = options.zIndex,
                zIndex = _options$zIndex2 === undefined ? 0 : _options$zIndex2,
                _options$position2 = options.position,
                position = _options$position2 === undefined ? 'absolute' : _options$position2,
                _options$width = options.width,
                width = _options$width === undefined ? '0' : _options$width,
                _options$height = options.height,
                height = _options$height === undefined ? '0' : _options$height,
                _options$top = options.top,
                top = _options$top === undefined ? 0 : _options$top,
                _options$left = options.left,
                left = _options$left === undefined ? 0 : _options$left;


            var dom = document.createElement('canvas');
            dom.id = id;
            //dom.onselectstart = returnFalse;    // 避免页面选中的尴尬
            dom.width = width;
            dom.height = height;

            var style = dom.style;
            style.zIndex = zIndex;
            style.position = position;
            style.top = top;
            style.left = left;
            style['-webkit-user-select'] = 'none';
            style['user-select'] = 'none';
            style['-webkit-touch-callout'] = 'none';
            style['-webkit-tap-highlight-color'] = 'rgba(0,0,0,0)';
            style['padding'] = 0;
            style['margin'] = 0;
            style['border-width'] = 0;
            // dom.width = width * dpr;
            // dom.height = height * dpr;
            return dom;
        }
    }, {
        key: 'drawText',
        value: function drawText(ctx, options) {
            var _options$text = options.text,
                text = _options$text === undefined ? '' : _options$text,
                _options$textAlign = options.textAlign,
                textAlign = _options$textAlign === undefined ? 'center' : _options$textAlign,
                _options$textBaseline = options.textBaseline,
                textBaseline = _options$textBaseline === undefined ? 'middle' : _options$textBaseline,
                font = options.font,
                textFill = options.textFill,
                _options$position3 = (0, _slicedToArray3.default)(options.position, 2),
                x = _options$position3[0],
                y = _options$position3[1];

            ctx.save();
            ctx.font = font;
            ctx.fillStyle = textFill;
            ctx.textAlign = textAlign;
            ctx.textBaseline = textBaseline;
            ctx.fillText(text, x, y);
            ctx.restore();
        }
    }, {
        key: 'drawLine',
        value: function drawLine(ctx, options) {
            var stroke = options.stroke,
                _options$start = (0, _slicedToArray3.default)(options.start, 2),
                x1 = _options$start[0],
                y1 = _options$start[1],
                _options$end = (0, _slicedToArray3.default)(options.end, 2),
                x2 = _options$end[0],
                y2 = _options$end[1];

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.strokeStyle = stroke;
            ctx.stroke();
            ctx.restore();
        }
    }, {
        key: 'drawCircle',
        value: function drawCircle(ctx, options) {
            var fill = options.fill,
                radius = options.radius,
                _options$position4 = (0, _slicedToArray3.default)(options.position, 2),
                x = _options$position4[0],
                y = _options$position4[1];

            ctx.save();
            ctx.fillStyle = fill;
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI);
            ctx.fill();
            ctx.restore();
        }

        // 标题

    }, {
        key: 'drawTitle',
        value: function drawTitle(ctx, options, chartType, target) {
            var _ctx$canvas = ctx.canvas,
                width = _ctx$canvas.width,
                height = _ctx$canvas.height;
            var text = options.text,
                other = (0, _objectWithoutProperties3.default)(options, ['text']);


            this.drawText(ctx, (0, _extends3.default)({
                text: typeof text === 'function' ? text(chartType, target) : text
            }, other, {
                position: [width / 2, 40]
            }));
        }
        // 底部文字

    }, {
        key: 'drawFooter',
        value: function drawFooter(ctx, options, chartType, target) {
            var _ctx$canvas2 = ctx.canvas,
                width = _ctx$canvas2.width,
                height = _ctx$canvas2.height;
            var text = options.text,
                textAlign = options.textAlign,
                font = options.font,
                textFill = options.textFill,
                lineStroke = options.lineStroke;

            var offsetY = 30;

            this.drawText(ctx, {
                text: typeof text === 'function' ? text(chartType, target) : text,
                textAlign: textAlign,
                font: font,
                textFill: textFill,
                position: [width / 2, height - offsetY]
            });

            this.drawLine(ctx, {
                stroke: lineStroke,
                start: [0, height - offsetY],
                end: [width / 2 - 540 / 2, height - offsetY]
            });

            this.drawLine(ctx, {
                stroke: lineStroke,
                start: [width / 2 + 540 / 2, height - offsetY],
                end: [width, height - offsetY]
            });
        }
        // 右下角的图例信息

    }, {
        key: 'drawLegend',
        value: function drawLegend(ctx, options) {
            var _this2 = this;

            var _ctx$canvas3 = ctx.canvas,
                width = _ctx$canvas3.width,
                height = _ctx$canvas3.height;
            var textFill = options.textFill,
                _options$items = options.items,
                items = _options$items === undefined ? [] : _options$items;


            var offsetX = 20;
            var offsetY = 80;
            var space = 30;
            items.forEach(function (item, index) {
                var text = item.text,
                    circle = item.circle;

                var tx = width - offsetX * 2;
                var ty = height - offsetY - (items.length - index - 1) * space;
                var cx = width - offsetX;
                var cy = ty;
                text.textFill = text.textFill || textFill;

                _this2.drawText(ctx, (0, _extends3.default)({}, text, {
                    position: [tx, ty]
                }));

                _this2.drawCircle(ctx, (0, _extends3.default)({}, circle, {
                    position: [cx, cy]
                }));
            });
        }
        // 绘制图例

    }, {
        key: 'drawAttachment',
        value: function drawAttachment(ctx, options, chartType, target) {
            var title = options.title,
                footer = options.footer,
                legend = options.legend;

            var exportConfig = _index2.default.export;
            // 增加顶部标题信息
            if (title) {
                var titleOption = (0, _merge2.default)({}, exportConfig.title, title);
                this.drawTitle(ctx, titleOption, chartType, target);
            }

            // 底部文字
            if (footer) {
                var footerOption = (0, _merge2.default)({}, exportConfig.footer, footer);
                this.drawFooter(ctx, footerOption, chartType, target);
            }

            // 增加图例
            if (legend) {
                var legendOption = (0, _merge2.default)({}, exportConfig.legend, legend);
                this.drawLegend(ctx, legendOption);
            }
        }
        // 绘制水印

    }, {
        key: 'drawWatermark',
        value: function drawWatermark(watermark, ctx, callback) {
            if (!watermark) {
                callback && callback(ctx);
                return;
            }

            var img = new Image();

            img.onload = function () {
                var _ctx$canvas4 = ctx.canvas,
                    width = _ctx$canvas4.width,
                    height = _ctx$canvas4.height;

                var widthUnit = width / 3;
                var heightUnit = height / 3;
                var watermarkPoint = [0, 0];

                // 绘制3个水印
                for (var i = 0; i < 3; i++) {
                    ctx.drawImage(img, watermarkPoint[0], watermarkPoint[1], widthUnit, heightUnit);
                    watermarkPoint[0] += widthUnit;
                    watermarkPoint[1] += heightUnit;
                }

                callback && callback(ctx);
            };

            img.src = watermark;
        }
    }, {
        key: 'drawLayer',
        value: function drawLayer(ctx) {
            if (!ctx) {
                return;
            }

            var canvasList = this.container.querySelectorAll('canvas');

            canvasList.forEach(function (canvas) {
                ctx.drawImage(canvas, 0, 0);
            });

            return ctx;
        }

        /**
         * 更新画布大小
         * @param {object} opts, {width, height } 
         */

    }, {
        key: 'resize',
        value: function resize(opts) {}
    }]);
    return Layer;
}();

exports.default = Layer;
module.exports = exports['default'];