// http协议method
export const HttpMethod = {
    GET: 'get',
    POST: 'post'
};
// http协议头Content-Type
export const ContentType = {
    FILE: 'file', // 自定义的文件类型
    JSON: 'application/json',
    FORM_URLENCODED: 'application/x-www-form-urlencoded'
};
// Api Version
export const API = {
    V1: '/api/v1.0' // /api/v1.0
};
// Post Params
export const BasePost = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
};
// 申请提交状态
export const ApplyStatus = ['未提交', '已提交', '审核中', '已驳回', '已完成'];
// 申请进度
export const ApplyProcess = [
    '企业提交',
    '资料核对',
    '区金融办',
    '市金融局',
    '拨款下发'
];
// 数据分享提示标语
export const DataSharingProtocol =
    '双创平台是政府设立的为企业提供综合服务的平台，其由政府指导设立，有严格的数据安全保护措施，同意将您上传的授权数据在上市服务综合服务平台和双创平台进行共享，方便您在双创平台获取服务时更加便捷，减少数据多次输入，并且帮助您在双创平台获取更加优质且匹配的服务。';
// 操作状态
export const ProcessStatus = ['驳回', '通过', '提交'];
// 上市阶段
export const ListingStage = [
    '完成股改及北京证监局辅导验收',
    '提交首发申请并获中国证监会受理',
    '通过中国证监会发审会审核',
    '在境外交易所成功上市'
];
// 用户类型
export const UserType = {
    1: '启元资本',
    2: '企业',
    3: '中介机构(券商、律所、会计事务所)',
    4: '政府机构 ',
    5: '基金管理人',
    6: '行业协会'
};
// 用户类型
export const RegisterUserType = {
    2: '企业',
    3: '中介机构(券商、律所、会计事务所)',
    7: '个人'
};
// 用户级别
export const LEVELS = ['普通会员', '高级会员', 'VIP会员'];
// 状态码
export const StatusCode = {
    success: 1000, // 成功
    error: 2000, // 未知异常
    paramsErr: 3001, // 参数错误
    msgErr: 4001, // 短信发送失效
    loginErr: 4002, // 登录失效
    notPower: 4021, // 没有访问权限
    pswExpired: 5001, // 密码过期
};
// 利润表键值
export const ProfitKeys = [
    '一、营业收入',
    '减：营业成本',
    '税金及附加',
    '销售费用',
    '管理费用',
    '研发费用',
    '财务费用',
    '其中：利息费用',
    '利息收入',
    '加：其他收益',
    '投资收益（损失以“-”号填列）',
    '其中：对联营企业和合营企业的投资收益',
    '以摊余成本计量的金融资产终止确认收益（损失以“-”号填列）',
    '净敞口套期收益（损失以“-”号填列）',
    '公允价值变动收益（损失以“-”号填列）',
    '信用减值损失（损失以“-”号填列）',
    '资产减值损失（损失以“-”号填列）',
    '资产处置收益（损失以“-”号填列）',
    '二、营业利润（亏损以“-”号填列）',
    '加：营业外收入',
    '减：营业外支出',
    '三、利润总额（亏损总额以“-”号填列）',
    '减：所得税费用',
    '四、净利润（净亏损以“-”号填列）',
    '（一）持续经营净利润（净亏损以“-”号填列）',
    '（二）终止经营净利润（净亏损以“-”号填列）',
    '五、其他综合收益的税后净额',
    '（一）不能重分类进损益的其他综合收益',
    '1．重新计量设定受益计划变动额',
    '2．权益法下不能转损益的其他综合收益',
    '3．其他权益工具投资公允价值变动',
    '4．企业自身信用风险公允价值变动',
    '（二）将重分类进损益的其他综合收益',
    '1．权益法下可转损益的其他综合收益',
    '2．其他债权投资公允价值变动',
    '3．金融资产重分类计入其他综合收益的金额',
    '4．其他债权投资信用减值准备',
    '5．现金流量套期储备',
    '6．外币财务报表折算差额',
    '六、综合收益总额',
    '七、每股收益：',
    '（一）基本每股收益',
    '（二）稀释每股收益'
];
// 资产表键值
export const AssetsKeys = [
    '流动资产：',
    '货币资金',
    '交易性金融资产',
    '衍生金融资产',
    '应收票据',
    '应收账款',
    '应收款项融资',
    '预付款项',
    '其他应收款',
    '存货',
    '合同资产',
    '持有待售资产',
    '一年内到期的非流动资产',
    '其他流动资产',
    '流动资产合计',
    '非流动资产：',
    '债权投资',
    '其他债权投资',
    '长期应收款',
    '长期股权投资',
    '其他权益工具投资',
    '其他非流动金融资产',
    '投资性房地产',
    '固定资产',
    '在建工程',
    '生产性生物资产',
    '油气资产',
    '使用权资产',
    '无形资产',
    '开发支出',
    '商誉',
    '长期待摊费用',
    '递延所得税资产',
    '其他非流动资产',
    '非流动资产合计',
    '资产总计',
    '流动负债：',
    '短期借款',
    '交易性金融负债',
    '衍生金融负债',
    '应付票据',
    '应付账款',
    '预收款项',
    '合同负债',
    '应付职工薪酬',
    '应交税费',
    '其他应付款',
    '持有待售负债',
    '一年内到期的非流动负债',
    '其他流动负债',
    '流动负债合计',
    '非流动负债：',
    '长期借款',
    '应付债券',
    '其中：优先股',
    '永续债',
    '租赁负债',
    '长期应付款',
    '预计负债',
    '递延收益',
    '递延所得税负债',
    '其他非流动负债',
    '非流动负债合计',
    '负债合计',
    '所有者权益（或股东权益）：',
    '实收资本（或股本）',
    '其他权益工具',
    '其中：优先股',
    '永续债',
    '资本公积',
    '减：库存股',
    '其他综合收益',
    '专项储备',
    '盈余公积',
    '未分配利润',
    '所有者权益（或股东权益）合计',
    '负债和所有者权益（或股东权益）总计'
];
// 现金流表键值
export const CashKeys = [
    '一、经营活动产生的现金流量：',
    '销售商品、提供劳务收到的现金',
    '收到的税费返还',
    '收到的其他与经营活动有关的现金',
    '经营活动现金流入小计',
    '购买商品、接受劳务支付的现金',
    '支付给职工以及为职工支付的现金',
    '支付的各项税费',
    '支付的其他与经营活动有关的现金',
    '经营活动现金流出小计',
    '经营活动产生的现金流量净额',
    '二、投资活动产生的现金流量：',
    '收回投资所收到的现金',
    '取得投资收益所收到的现金',
    '处置固定资产、无形资产和其他长期资产所收回的现金净额',
    '处置子公司及其他营业单位收到的现金净额',
    '收到的其他与投资活动有关的现金',
    '投资活动现金流入小计',
    '购建固定资产、无形资产和其他长期资产所支付的现金',
    '投资支付的现金',
    '取得子公司及其他营业单位支付的现金净额',
    '支付其他与投资活动有关的现金',
    '投资活动现金流出小计',
    '投资活动产生的现金流量净额',
    '三、筹资活动产生的现金流量：',
    '吸收投资收到的现金',
    '取得借款收到的现金',
    '收到其他与筹资活动有关的现金',
    '筹资活动现金流入小计',
    '偿还债务支付的现金',
    '分配股利、利润或偿付利息支付的现金',
    '支付其他与筹资活动有关的现金',
    '筹资活动现金流出小计',
    '筹资活动产生的现金流量净额',
    '四、汇率变动对现金及现金等价物的影响',
    '五、现金及现金等价物净增加额',
    '加：期初现金及现金等价物余额',
    '六、期末现金及现金等价物余额'
];
// 时间格式化
export const TimeFormat = 'YYYY-MM-DD';
// 行业标签
export const IndustryLabels = [
    '人工智能',
    '区块链',
    '大数据',
    '医疗健康',
    '教育培训',
    '文娱传媒',
    '消费升级',
    '金融',
    '电子商务',
    '企业服务',
    'VR/AR',
    '旅游户外',
    '餐饮业',
    '房产家居',
    '汽车交通',
    '体育健身',
    '生活服务',
    '食品饮料',
    '物联网',
    '硬件',
    '游戏',
    '生产制造',
    '物流运输',
    '农业',
    '批发零售',
    '先进制造',
    '社交社区',
    '工具软件',
    '服装纺织',
    '建筑',
    '开采',
    '环保',
    '能源电力',
    '政务及公共服务',
    '科研及技术服务'
];
// 上市咨询申请类型
export const APPLY_TYPES = [
    {
        value: 1,
        text: '收购'
    },
    {
        value: 2,
        text: '转让'
    }
];
// 申请状态
export const APPLY_STATUS = [
    {
        value: 1,
        text: '未提交'
    },
    {
        value: 2,
        text: '已提交'
    },
    {
        value: 3,
        text: '已受理'
    },
    {
        value: 4,
        text: '不予受理'
    }
];
// 企业上市板块
export const ENTERPRISE_TYPE = [
    {
        value: '深交所主板上市',
        text: '深交所主板上市'
    },
    {
        value: '深交所创业板上市',
        text: '深交所创业板上市'
    },
    {
        value: '上交所主板上市',
        text: '上交所主板上市'
    },
    {
        value: '上交所科创板上市',
        text: '上交所科创板上市'
    },
    {
        value: '港股上市',
        text: '港股上市'
    },
    {
        value: '美股上市',
        text: '美股上市'
    },
    {
        value: '新三板上市',
        text: '新三板上市'
    },
    {
        value: '四板上市',
        text: '四板上市'
    },
    {
        value: '未上市',
        text: '未上市'
    },
    {
        value: '北交所',
        text: '北交所'
    },
];
// 企业经济类型
export const ECONOMIC_TYPE = [
    {
        value: '国有经济',
        text: '国有经济'
    },
    {
        value: '集体经济',
        text: '集体经济'
    },
    {
        value: '私营经济',
        text: '私营经济'
    },
    {
        value: '个体经济',
        text: '个体经济'
    },
    {
        value: '联营经济',
        text: '联营经济'
    },
    {
        value: '股份制',
        text: '股份制'
    },
    {
        value: '外商投资',
        text: '外商投资'
    },
    {
        value: '港澳台投资',
        text: '港澳台投资'
    },
    {
        value: '其他经济类',
        text: '其他经济类'
    }
];
// 启元企业分类
export const QIYUAN_CATEGORY = [
    {
        value: 1,
        text: '初创企业（成立三个自然年以内）'
    },
    {
        value: 2,
        text: '成长企业（成立三个自然年以上）'
    },
    {
        value: 3,
        text: '上市企业'
    }
];
// 是/否
export const TRUE_FALSE = [
    {
        value: 1,
        text: '是'
    },
    {
        value: 0,
        text: '否'
    }
];
// 提交状态
export const SUBMIT_STATUS = [
    {
        value: 2,
        text: '已提交'
    },
    {
        value: 1,
        text: '未提交'
    }
];
// 处理状态
export const HANDLE_STATUS = [
    {
        value: 2,
        text: '已受理'
    },
    {
        value: 1,
        text: '未受理'
    },
    {
        value: 3,
        text: '不予受理'
    }
];
// 上市交易所
export const STAGES = [
    {
        value: '深交所',
        text: '深交所'
    },
    {
        value: '上交所',
        text: '上交所'
    },
    {
        value: '北交所',
        text: '北交所'
    },
    {
        value: '境外交易所',
        text: '境外交易所'
    }
];
// 上市板块
export const PLATES = [
    {
        value: '上交所主板',
        text: '上交所主板',
        label: '上交所主板'
    },
    {
        value: '上交所科创板',
        text: '上交所科创板',
        label: '上交所科创板'
    },
    {
        value: '深交所主板',
        text: '深交所主板',
        label: '深交所主板'
    },
    {
        value: '深交所中小板',
        text: '深交所中小板',
        label: '深交所中小板'
    },
    {
        value: '深交所创业板',
        text: '深交所创业板',
        label: '深交所创业板'
    },
    {
        value: '北交所',
        text: '北交所',
        label: '北交所'
    }
];

export const PLATES2 = [
    '上交所主板',
    '上交所科创板',
    '深交所主板',
    '深交所中小板',
    '深交所创业板',
    '港交所主板',
    '港交所GEM板',
    '北京证券交易所',
    '新三板'
];
// 报告类型
export const REPORT_TYPE = [
    {
        value: 1,
        text: '行业研究报告'
    },
    {
        value: 2,
        text: '政策研究报告'
    }
];
// 中介类型
export const IntermediaryAnalysis = [
    {
        value: 1,
        text: '会计事务所',
        label: '会计事务所'
    },
    {
        value: 2,
        text: '证券公司',
        label: '证券公司'
    },
    {
        value: 3,
        text: '律师事务所',
        label: '律师事务所'
    }
];
//企业类型
export const CompanyType = [
    {
        label: '上市',
        value: '上市,外资企业'
    },
    {
        label: '拟上市',
        value: '拟上市,外资企业'
    },
    {
        label: '非上市',
        value: '长期培育,外资企业'
    }
]
// 行业
export const Industry2 = [
    { label: '农、林、牧、渔业', value: 'A' },
    { label: '采矿业', value: 'B' },
    { label: '制造业', value: 'C' },
    {
        label: '电力、热力、燃气及水生产和供应业',
        value: 'D'
    },
    { label: '建筑业', value: 'E' },
    { label: '批发和零售业', value: 'F' },
    { label: '交通运输、仓储和邮政业', value: 'G' },
    { label: '住宿和餐饮业', value: 'H' },
    {
        label: '信息传输、软件和信息技术服务业',
        value: 'I'
    },
    { label: '金融业', value: 'J' },
    { label: '房地产业', value: 'K' },
    { label: '租赁和商务服务业', value: 'L' },
    { label: '科学研究和技术服务业', value: 'M' },
    {
        label: '水利、环境和公共设施管理业',
        value: 'N'
    },
    {
        label: '居民服务、修理和其他服务业',
        value: 'O'
    },
    { label: '教育', value: 'P' },
    { label: '卫生和社会工作', value: 'Q' },
    { label: '文化、体育和娱乐业', value: 'R' },
    {
        label: '公共管理、社会保障和社会组织',
        value: 'S'
    },
    { label: '国际组织', value: 'T' },
    { label: '其他', value: 'Z' }
];

//上市板块
export const Stock = [
    {
        value: '000',
        label: '深交所主板'
    },
    {
        value: '300',
        label: '深交所创业板'
    },
    {
        value: '688',
        label: '上交所科创板'
    },
    {
        value: '6',
        label: '上交所主板'
    },
    {
        value: '8',
        label: '北交所'
    }
]
// 中介类型
export const GroupTypes = [
    {
        text: '律师',
        value: 1
    },
    {
        text: '会计',
        value: 2
    },
    {
        text: '保荐',
        value: 3
    }
];
// 核心技术领先
export const CoreTechAdvance = [
    {
        text: '其他',
        value: '其他'
    },
    {
        text: '高',
        value: '高'
    },
    {
        text: '国内领先',
        value: '国内领先'
    },
    {
        text: '进口替代/填补国内技术空白',
        value: '进口替代/填补国内技术空白'
    },
    {
        text: '国际领先',
        value: '国际领先'
    }
];
// 核心技术成熟度
export const CoreTechMature = [
    {
        text: '技术处于理论研究阶段',
        value: '技术处于理论研究阶段'
    },
    {
        text: '技术成熟度较差',
        value: '技术成熟度较差'
    },
    {
        text: '技术成熟度一般',
        value: '技术成熟度一般'
    },
    {
        text: '技术相对成熟',
        value: '技术相对成熟'
    },
    {
        text: '技术成熟',
        value: '技术成熟'
    }
];
// 研发管理
export const ResearchManage = [
    {
        text: '其他',
        value: '其他'
    },
    {
        text: '建立研发管理制度',
        value: '建立研发管理制度'
    },
    {
        text: '建立规范化的研发管理制度',
        value: '建立规范化的研发管理制度'
    },
    {
        text: '通过行业标准的研发管理体系认证',
        value: '通过行业标准的研发管理体系认证'
    },
    {
        text: '通过国际/国家标准的研发管理体系认证',
        value: '通过国际/国家标准的研发管理体系认证'
    }
];
// 研发人员背景
export const ResearchBackground = [
    {
        text: '本科以上占比超过20%',
        value: '本科以上占比超过20%'
    },
    {
        text: '本科以上占比超过30%',
        value: '本科以上占比超过30%'
    },
    {
        text: '本科以上占比超过40%',
        value: '本科以上占比超过40%'
    },
    {
        text: '本科以上占比超过50%',
        value: '本科以上占比超过50%'
    },
    {
        text: '硕士以上占比超过50%',
        value: '硕士以上占比超过50%'
    }
];
// 研发设备情况
export const ResearchEquipment = [
    {
        text: '其他',
        value: '其他'
    },
    {
        text: '拥有企业实验室',
        value: '拥有企业实验室'
    },
    {
        text: '与科研院所或知名企业建立联合实验室',
        value: '与科研院所或知名企业建立联合实验室'
    },
    {
        text: '拥有省市级实验室',
        value: '拥有省市级实验室'
    },
    {
        text: '拥有世界级、国家级实验室',
        value: '拥有世界级、国家级实验室'
    }
];
// 重大科研项目参与情况
export const ImportantResearch = [
    {
        text: '未参与重大科研项目',
        value: '未参与重大科研项目'
    },
    {
        text: '承担高校或国有大型企业科研项目',
        value: '承担高校或国有大型企业科研项目'
    },
    {
        text: '承担区县或园区重大科研项目',
        value: '承担区县或园区重大科研项目'
    },
    {
        text: '承担省部级或市级重大科研项目',
        value: '承担省部级或市级重大科研项目'
    },
    {
        text: '承担世界级、国家级重大科研项目',
        value: '承担世界级、国家级重大科研项目'
    }
];
// 标准定制情况
export const StandardSetting = [
    {
        text: '未参与标准制定',
        value: '未参与标准制定'
    },
    {
        text: '参与区县或园区标准制定',
        value: '参与区县或园区标准制定'
    },
    {
        text: '参与省级或市级标准制定',
        value: '参与省级或市级标准制定'
    },
    {
        text: '参与行业标准制定',
        value: '参与行业标准制定'
    },
    {
        text: '参与国际或国家标准制定',
        value: '参与国际或国家标准制定'
    }
];
// 获奖情况
export const Awards = [
    {
        text: '未曾获奖',
        value: '未曾获奖'
    },
    {
        text: '获得知名企业或高校组织赛事奖项',
        value: '获得知名企业或高校组织赛事奖项'
    },
    {
        text: '获得行业协会奖项',
        value: '获得行业协会奖项'
    },
    {
        text: '获得省部级或市级奖项',
        value: '获得省部级或市级奖项'
    },
    {
        text: '获得国际或国家级奖项',
        value: '获得国际或国家级奖项'
    }
];
// 行业所处阶段
export const IndustryStage = [
    {
        text: '其他',
        value: '其他'
    },
    {
        text: '衰退期，替代品较多',
        value: '衰退期，替代品较多'
    },
    {
        text: '幼稚期，进入壁垒较低',
        value: '幼稚期，进入壁垒较低'
    },
    {
        text: '成长期，进入壁垒提高',
        value: '成长期，进入壁垒提高'
    },
    {
        text: '成熟期，进入壁垒很高',
        value: '成熟期，进入壁垒很高'
    }
];
// 公司所处行业地位
export const CompanyStatus = [
    {
        text: '其他',
        value: '其他'
    },
    {
        text: '领先地位',
        value: '领先地位'
    },
    {
        text: '进口替代',
        value: '进口替代'
    },
    {
        text: '第2-3名',
        value: '第2-3名'
    },
    {
        text: '第1名',
        value: '第1名'
    }
];
// 技术优势及可持续性
export const TechAdvantage = [
    {
        text: '技术水平较低，且很难取得技术突破',
        value: '技术水平较低，且很难取得技术突破'
    },
    {
        text: '技术水平略低于平均水平且很难取得技术突破',
        value: '技术水平略低于平均水平且很难取得技术突破'
    },
    {
        text: '技术在行业内略高于平均水平，但优势很容易被打破',
        value: '技术在行业内略高于平均水平，但优势很容易被打破'
    },
    {
        text: '技术在行业内拥有相对优势且可短期保持相对优势',
        value: '技术在行业内拥有相对优势且可短期保持相对优势'
    },
    {
        text: '技术在行业内拥有明显优势且可长期保持优势',
        value: '技术在行业内拥有明显优势且可长期保持优势'
    }
];
//核心经营团队和技术团队竞争力
export const CoreTeamArgs = [
    {
        text: '核心团队学历背景及工作经验与公司主营业务无相关性',
        value: '核心团队学历背景及工作经验与公司主营业务无相关性'
    },
    {
        text: '核心团队学历背景及工作经验与公司主营业务相关性不高',
        value: '核心团队学历背景及工作经验与公司主营业务相关性不高'
    },
    {
        text:
            '核心团队的学历背景及工作经验与公司主营业务有一定的相关性，但在业内知名度不高，认可度一般',
        value:
            '核心团队的学历背景及工作经验与公司主营业务有一定的相关性，但在业内知名度不高，认可度一般'
    },
    {
        text:
            '核心团队由拥有与公司经营相关的学历背景及工作经验人员组成，在业内拥有一定的知名度和认可',
        value:
            '核心团队由拥有与公司经营相关的学历背景及工作经验人员组成，在业内拥有一定的知名度和认可'
    },
    {
        text:
            '核心团队由拥有与公司经营密切相关的学历背景及工作经验人员组成，在业内拥有极高知名度及认可',
        value:
            '核心团队由拥有与公司经营密切相关的学历背景及工作经验人员组成，在业内拥有极高知名度及认可'
    }
];
// 全国省份
export const Provinces = [
    '北京',
    '天津',
    '河北',
    '山西',
    '内蒙古',
    '辽宁',
    '吉林',
    '黑龙江',
    '上海',
    '江苏',
    '浙江',
    '安徽',
    '福建',
    '江西',
    '山东',
    '河南',
    '湖北',
    '湖南',
    '广东',
    '广西',
    '海南',
    '重庆',
    '四川',
    '贵州',
    '云南',
    '西藏',
    '陕西',
    '甘肃',
    '青海',
    '宁夏',
    '新疆',
    '香港',
    '澳门',
    '台湾'
];
// 北京区
export const BeiJingCountry = {
    全部: '',
    市辖区: '110000,110100',
    东城区: '110101',
    西城区: '110102',
    朝阳区: '110105',
    丰台区: '110106',
    石景山区: '110107',
    海淀区: '110108',
    门头沟区: '110109',
    房山区: '110111',
    通州区: '110112',
    顺义区: '110113',
    昌平区: '110114',
    大兴区: '110115',
    怀柔区: '110116',
    平谷区: '110117',
    密云区: '110118',
    延庆区: '110119',
    经济技术开发区: '110115403000',
};
// 图片服务器
export const IMAGE_HOST = [
    'http://file.beijingipo.com.cn/',
    'http://192.168.88.106/',
    'http://192.168.88.106/'
];

export const getCascaderProvince = () => {
    let provinces = Provinces.map(p => ({
        value: p,
        label: p,
        children:
            p === '北京'
                ? Object.keys(BeiJingCountry).map((address, index) => ({
                    label: address,
                    value: Object.values(BeiJingCountry)[index]
                }))
                : undefined
    }));

    provinces.unshift({
        value: null,
        label: '全国'
    });

    return provinces;
};
