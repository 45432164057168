import React, { useEffect, useState } from 'react';
import Gauge from './Gauge';
import { authDiamondEvaluate } from '../service';
import bgPng from './new_mapBottomU.png';
import { message, Empty } from 'antd';
import { isNotEmpty } from '../../../utils/utils';
/**
 * @desc 行业内优质企业分布情况
 * @returns {*}
 * @constructor
 */
export default function Score({ area, style }) {
  let [data, setData] = useState({});
  useEffect(() => {
    authDiamondEvaluate(area).then(res => {
      if (res.statusCode == 1000) {
        setData(res.data);
      } else {
        message.error(res.message);
      }
    });
  }, [area]);

  return (
    <>
      {
        isNotEmpty(data) ?
          < div style={{ ...style }
          }>
            <div style={{ float: 'left', height: '100%', width: '50%' }}>
              <Gauge style={{ height: '100%', ...style }} data={data.score} />
            </div>

            <div
              style={{
                float: 'right',
                textAlign: 'center',
                color: '#fff',
                width: '50%',
                height: '100%'
              }}
            >
              <div
                style={{
                  backgroundImage: 'url(' + bgPng + ')',
                  height: 66,
                  lineHeight: '50px',
                  width: '100%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                钻石评分
        </div>
              <div style={{ lineHeight: '40px' }}>
                {data.total && (
                  <div>
                    {area == '全国' ? '全国' : ''}总分： {data.total}
                  </div>
                )}
                {data.average && <div>地区均分： {data.average}分</div>}
              </div>
            </div>
          </div >
          :
          <Empty />
      }
    </>
  );
}
