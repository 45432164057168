import React, { useState } from 'react';
import style from './style.scss'
import Radios from "./radio";
import TopView from "./TopView";

export default function MapTool() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '三板做市', value: 1 },
        { name: '三板成指', value: 2 },
        { name: '创新成指', value: 3 },
    ];

    return(
        <div>
            <div className={style.mapToolRadio}>
                <Radios
                    value={type}
                    data={radiosData}
                    defaultValue={1}
                    onChange={e => setType(e.target.value)}
                />
            </div>
            <TopView type={type} />
        </div>
    )
}
