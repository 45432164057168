/**
 * @desc
 */

import React, { useState, useEffect } from 'react';
import CenterBottom from './Transactions'

export default function CenterButtomIndex() {
    return(
        <div style={{ position: 'relative' }}>
            <CenterBottom />
        </div>
    )
}
