import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Empty } from 'antd';



export default function Line13List() {
    const data = [
        { securitiesNumber: 8931, totalEquity: 6028.81 },
        { securitiesNumber: 8931, totalEquity: 6028.81 },
        { securitiesNumber: 8931, totalEquity: 6028.81 },
        { securitiesNumber: 8931, totalEquity: 6028.81 },
    ]

    return (
        <div className={ styles.line13List }>
            {
                data && data.length ? (
                    <table>
                        <thead>
                            <tr>
                                <td>日期</td>
                                <td>内容</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((ele, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="80%">{ele.securitiesNumber}</td>
                                            <td width="5.2%">{ele.totalEquity}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                ) : <Empty />
            }
            {/* <div className={ styles.loadMore }>
                点击查看更多
            </div> */}
        </div>
    )
}