/**
 * @author Ray
 * @desc tags选择组件
 */

import React from 'react';
import { Icon, Tooltip } from 'antd';
import styles from './styles.scss';
import { Typography } from 'antd';
import classnames from 'classnames';

export default class extends React.Component {
    state = {
        showMore: false,
        selectedKey: undefined
    };

    static defaultProps = {
        moreTag: false,
        lessCount: 6,
        showAll: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value === prevState.selectedKey) {
            return false;
        }
        return {
            selectedKey: nextProps.value
        };
    }

    onOptionClick = v => {
        this.setState(
            {
                selectedKey: v
            },
            () => {
                this.props.onChange(v);
            }
        );
    };

    onShowMoreClick = () => {
        this.setState({
            showMore: !this.state.showMore
        });
    };

    render() {
        let { options = [], moreTag, lessCount } = this.props;
        let { selectedKey, showMore } = this.state;
        let renderOpts = [];
        if (showMore) {
            renderOpts = options;
        } else {
            renderOpts = options.slice(0, lessCount + 1);
        }

        return (
            <div
                onChange={this.props.onChange}
                className={styles.radioGroupRoot}
            >
                {moreTag && (
                    <span
                        onClick={this.onShowMoreClick}
                        className={styles.showMoreButton}
                    >
                        更多
                        {showMore ? (
                            <Icon type="caret-up" />
                        ) : (
                            <Icon type="caret-down" />
                        )}
                    </span>
                )}
                <div>
                    {this.props.showAll && (
                        <Tooltip
                            title="全部"
                            key="all"
                            overlayClassName="rustTooltip"
                        >
                            <Typography
                                className={classnames(styles.option, {
                                    [styles.active]: selectedKey === undefined
                                })}
                                onClick={() => this.onOptionClick(undefined)}
                            >
                                全部
                            </Typography>
                        </Tooltip>
                    )}
                    {renderOpts.map(tag => {
                        let value, key, label;
                        if (typeof tag === 'string') {
                            value = key = label = tag;
                        } else {
                            value = tag.value;
                            key = tag.key;
                            label = tag.label;
                        }

                        return (
                            <Tooltip
                                title={label}
                                key={key || label}
                                overlayClassName="rustTooltip"
                            >
                                <Typography
                                    className={classnames(styles.option, {
                                        [styles.active]: selectedKey === value
                                    })}
                                    onClick={e => this.onOptionClick(value)}
                                >
                                    {label.length > 11
                                        ? label.slice(0, 9) + '...'
                                        : label}
                                </Typography>
                            </Tooltip>
                        );
                    })}
                </div>
            </div>
        );
    }
}
