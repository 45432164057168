/**
 * @author Ray
 */
import React, { useEffect, useState } from 'react';
import { Divider, message } from 'antd';
import Title from '../../../common/title';
import ProjectList from './projcets';
import Works from './works';
import Education from './educations';
import { SaveButton } from '../../../common/buttons';
import { checkEmpty, isNotEmpty, random } from '../../../utils/utils';

/**
 *
 * @param props
 * @returns {*}
 */
export default function View(props) {
  const { projects, works, educations, updateExpert } = props;
  const [expertData, setExpertData] = useState({
    sysExpertProExperiences: projects,
    sysExpertWorkExes: works,
    sysExpertEduExperiences: educations
  });

  useEffect(
    function() {
      setExpertData({
        sysExpertWorkExes: works.map(work => ({
          ...work,
          index: work.id || random(5)
        })),
        sysExpertProExperiences: projects.map(project => ({
          ...project,
          index: project.id || random(5)
        })),
        sysExpertEduExperiences: educations.map(edu => ({
          ...edu,
          index: edu.id || random(5)
        }))
      });
    },
    [projects, works, educations]
  );

  function handleChange(type, data) {
    setExpertData({ ...expertData, [type]: data });
  }

  function handleSave() {
    let flag = true,
      checkKeys = [];
    let keys = Object.keys(expertData);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      switch (key) {
        case 'sysExpertWorkExes':
          {
            checkKeys = [
              'startTime',
              'endTime',
              'company',
              // 'describe',
              'position'
            ];
          }
          break;
        case 'sysExpertProExperiences':
          {
            checkKeys = [
              'plate',
              'csrcStatus',
              'organization',
              'projectPosition',
              'companyIndustry',
              'serviceCompany'
            ];
          }
          break;
        case 'sysExpertEduExperiences':
          {
            checkKeys = ['startTime', 'endTime', 'school', 'major'];
          }
          break;
        default:
          break;
      }
      if (isNotEmpty(expertData[key])) {
        for (let j = 0; j < expertData[key].length; j++) {
          let data = expertData[key][j];
          flag = checkEmpty(data, checkKeys);
          if (!flag) {
            break;
          }
        }
      }
      if (!flag) {
        break;
      }
    }

    if (!flag) {
      return message.error('专家资料未填写完整，请补充后再尝试保存！');
    }

    updateExpert(expertData);
  }

  return (
    <section>
      <Title text="专家资料修改" />
      <Divider />
      <Title text="项目经历" level={2} />
      <ProjectList
        dataSource={expertData.sysExpertProExperiences}
        onChange={handleChange}
      />
      <Title text="工作经历" level={2} />
      <Works
        dataSource={expertData.sysExpertWorkExes}
        onChange={handleChange}
      />
      <Title text="教育经历" level={2} />
      <Education
        dataSource={expertData.sysExpertEduExperiences}
        onChange={handleChange}
      />
      <div className="center marginTop">
        <SaveButton text="保存" onClick={handleSave} />
      </div>
    </section>
  );
}

View.defaultProps = {};

View.propTypes = {};
