import React, { useState, useEffect } from 'react';
import RightSecond from './ListCompnayAmountBar';

/**
 * @desc  上市募集金额
 */
export default function RightSecondIndex({ address }) {
  return (
    <RightSecond address={address} />
  );
}