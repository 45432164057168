import React from 'react';
import { Input } from 'antd';
import styles from './search.scss';

const { Search: AntSearch } = Input;

Search.defaultProps = {
    placeholder: '',
    onSearch: () => {}
};

/**
 *
 * @param placeholder
 * @param onSearch
 */
export default function Search({ placeholder, onSearch }) {
    return (
        <div className={styles.root}>
            <AntSearch
                placeholder={placeholder}
                onSearch={value => onSearch(value.trim())}
                className={styles.search}
                maxLength={50}
            />
        </div>
    );
}
