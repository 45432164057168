import React, { useContext, useRef, useState } from 'react';
import { Divider, message, Tabs } from 'antd';
import Title from '../../../common/title';
import styles from './styles.scss';
import MainIndustry from '../../investment/consultant/material/main';
import Relationships from '../../investment/consultant/material/relationships';
import Vendor from '../../investment/consultant/material/vendor';
import Other from '../../investment/consultant/material/others';
import { revert } from '../../investment/common/inputTable';
import { isNotEmpty, paramFilter } from '../../../utils/utils';
import {
  operatingDataUpdate,
  otherDataUpdate,
  otherDataAdd,
  operatingDataAdd
} from '../service';
import * as actions from '../../investment/script/actions';
import { GlobalContext } from '../../../common/globalStore/createProvider';

const types = ['主营信息', '主要客户', '主要供应商', '其他信息'];

const tabs = [MainIndustry, Relationships, Vendor, Other];

export function appendPropsToArrayMember(arr, append) {
  return arr.map(item => ({ ...item, ...append }));
}

export default function Operating({ dispatch }) {
  const { user } = useContext(GlobalContext);
  const refs = useRef({});
  const [realActive, setRealActive] = useState('主营信息');
  const [activeKey, setActiveKey] = useState('主营信息');
  // 保存form的表单实例
  const storeRef = name => ref => (refs.current[name] = ref);

  // 添加/更新数据
  function updateData(data, isUpdate) {
    let func = isUpdate ? operatingDataUpdate : operatingDataAdd;
    func({
      companyJyxxs: [...data].map(item => ({ ...item, style: 1 })),
      companyName: user.organization
    }).then(res => {
      if (isNotEmpty(res) && res.success) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }

      goToNext(activeKey);
    });
  }

  // 添加/更新其他数据
  function updateOtherData(data, isUpdate) {
    let func = isUpdate ? otherDataUpdate : otherDataAdd;
    func({ ...data, type: 1 }).then(res => {
      if (isNotEmpty(res) && res.success) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }

      goToNext(activeKey);
    });
  }

  // 保存操作、校验数据的正确性
  function save(type) {
    return function(isUpdate) {
      let form = refs.current[type];

      form.validateFields((errors, values) => {
        // 是否为标准的表单组件(区分其它信息)
        let isNotStandardForm = type !== types.slice(-1)[0];
        let typeFakeIndex = types.indexOf(type) + 1;
        let extra = {
          type: typeFakeIndex,
          creditCode: user?.creditCode,
          companyName: user?.organization
        };
        if (!errors) {
          if (isNotStandardForm) {
            let years = Object.values(
              form.getFieldsValue(['year1', 'year2', 'year3'])
            );
            if (years.length !== [...new Set(years)].length) {
              return message.error('存在重复的年份');
            } else {
              values = revert(
                paramFilter(values, {
                  encrypt: false
                })
              );
              values = appendPropsToArrayMember(values, extra);
            }
          } else {
            return updateOtherData(Object.assign({}, values, extra), isUpdate);
          }
          updateData([...values], isUpdate);
        } else {
          if (isNotStandardForm) {
            message.error(
              `第一名的${type}名称和营收不能为空，请补充完整后保存！`
            );
          }
        }
      });
    };
  }
  // 前往下一个
  function goToNext(key) {
    setRealActive(key);
    dispatch(actions.closeModal());
  }
  function nextFunc(isModified, key, callback, isNew) {
    if (isModified) {
      dispatch(
        actions.showModal({
          title: '提示',
          content: '是否保存修改？',
          okText: '保存',
          cancelText: '不保存',
          onOk: () =>
            save(realActive, () => {
              // 如果保存，更新子组件
              callback(true);
              goToNext(key);
            })(!isNew),
          onClose: () => {
            // 如果取消，不更新子组件
            callback(false);
            goToNext(key);
          },
          onCancel: () => {
            setActiveKey(realActive);
            dispatch(actions.closeModal());
          }
        })
      );
    } else {
      goToNext(key);
    }
  }

  // tab变化
  function handleTabChange(key) {
    setActiveKey(key);
  }

  return (
    <div className={styles.operationRoot}>
      <Title level={1} text="经营信息" />
      <Divider />
      <Tabs activeKey={realActive} onChange={handleTabChange}>
        {tabs.map((TabContent, index) => {
          let current = types[index];
          return (
            <Tabs.TabPane tab={current} key={current}>
              {realActive === current && (
                <TabContent
                  reloadFlag={current}
                  realKey={realActive}
                  activeKey={activeKey}
                  trigger={nextFunc}
                  onSave={save(current)}
                  onInit={storeRef(current)}
                />
              )}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}
