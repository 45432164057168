import React, { useEffect, useState } from 'react';
import createContext from './createContext';

export const GlobalContext = createContext({});

export default function Provider({ store, children }) {
    const [user, setUser] = useState(store.user);
    const [env, setEnv] = useState({});
    const [isPasscodeExpired, setPasscodeExpired] = useState(false);

    useEffect(() => {
        setEnv({
            isPub: location.host === 'qyfw.beijingipo.com.cn',
            isDev: location.host === 'localhost:3000',
            isTest: location.host === 'test.beijingipo.com.cn'
        });
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                user,
                setUser,
                isPasscodeExpired, // 密码是否过期
                setPasscodeExpired, // 设置密码是否过期
                isNormalUser: user?.level === 1, // 普通用户
                isAdvanceUser: user?.level === 2, // 高级用户
                isVIPUser: user?.level === 3, // VIP用户
                isQiYuan: user?.type === 1, // 启元用户
                isEnterprise: user?.type === 2, // 企业用户
                isIntermediary: user?.type === 3, // 中介用户
                isGOV: user?.type === 4, // 政府用户
                isFundManager: user?.type === 5, // 基金管理人用户
                isTradeAssociations: user?.type === 6, // 。。。
                ...env
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
