/**
 * @author Ray
 * @desc 入库企业分析-基础信息-经营分析
 */

import React, { useContext, useState } from 'react';
import styles from './styles.scss';
import Title from '../../../../../common/title';
import MainCourse from './mainCourse';
import Vendor from './mainVendor';
import Structure from './profitStructure';
import MostLike from './mostLike';
import Indicators from './indicators';
import AssetsStructure from './assetsStructure';
import Financial from '../../../../../common/financialTabs';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

const elements = [
  MainCourse,
  Structure,
  MostLike,
  Indicators,
  AssetsStructure,
  Vendor
];

export default function BusinessAnalysis(props) {
  const gs = useContext(GlobalContext);
  const [isOwnedCompany] = useState(
    props.match.params.companyName === gs.user?.organization
  );
  return ((gs.isAdvanceUser || gs.isVIPUser) && isOwnedCompany) ||
    (gs.isIntermediary && gs.isVIPUser) ||
    gs.isGOV ||
    gs.isQiYuan ||
    gs.isTradeAssociations ||
    gs.isFundManager ? (
    <div className={styles.root}>
      {elements.map((E, index) => (
        <E key={index} {...props} />
      ))}
      {/* <Title level={3} text="财务报表" />
            <Financial readOnly status={5} code={props.creditCode} /> */}
    </div>
  ) : (
    <Empty description={'请升级会员查看'} />
  );
}
