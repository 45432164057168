'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _legend = require('../image/legend.png');

var _legend2 = _interopRequireDefault(_legend);

var _index = require('../config/index');

var _index2 = _interopRequireDefault(_index);

var _Layer2 = require('../interface/Layer');

var _Layer3 = _interopRequireDefault(_Layer2);

var _enum = require('../constant/enum');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var URL = '//webapi.amap.com/maps?v=' + _index2.default.map.version + '&key=' + _index2.default.map.key + '&callback=initMap';

var MapLayer = function (_Layer) {
    (0, _inherits3.default)(MapLayer, _Layer);

    function MapLayer(root, handler, options) {
        (0, _classCallCheck3.default)(this, MapLayer);

        var _this = (0, _possibleConstructorReturn3.default)(this, (MapLayer.__proto__ || (0, _getPrototypeOf2.default)(MapLayer)).call(this, root, handler, options));

        _this.type = _enum.LayerType.MAP;

        var id = _this.type + '-layer';
        _this.container = _this.createDOM(id, options);
        root.appendChild(_this.container);
        return _this;
    }

    (0, _createClass3.default)(MapLayer, [{
        key: 'onReady',
        value: function onReady(options) {
            var _this2 = this;

            return new _promise2.default(function (resolve, reject) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                window.initMap = function () {
                    _this2.proxyClass = AMap;
                    AMap.plugin(['AMap.Geocoder'], function () {
                        _this2.proxy = new AMap.Map(_this2.container.id, options);
                        _this2.proxy.on('complete', function () {
                            resolve({
                                map: _this2.proxy,
                                Map: _this2.proxyClass
                            });
                        });
                    });
                };
                script.src = URL;
                _this2.container.appendChild(script);
            });
        }
    }, {
        key: 'hide',
        value: function hide() {
            this.container.style.display = 'none';
        }
    }, {
        key: 'show',
        value: function show() {
            this.container.style.display = 'block';
        }
    }, {
        key: 'zoomIn',
        value: function zoomIn() {
            this.proxy.zoomIn();
        }
    }, {
        key: 'zoomOut',
        value: function zoomOut() {
            this.proxy.zoomOut();
        }
        /**
         * 清空该层画布
         */

    }, {
        key: 'clear',
        value: function clear() {
            (0, _get3.default)(MapLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MapLayer.prototype), 'removeAll', this).call(this);
            if (this.proxy) {
                this.proxy.clearMap();
            }
        }
    }, {
        key: 'reset',
        value: function reset() {
            // TODO: 此处应该修改
            this.dispose();
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.clear();

            if (this.proxy) {
                this.proxy.destroy();
            }
            (0, _get3.default)(MapLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MapLayer.prototype), 'dispose', this).call(this);
        }
    }]);
    return MapLayer;
}(_Layer3.default);

exports.default = MapLayer;
module.exports = exports['default'];