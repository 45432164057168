import React, { useState, useEffect } from 'react';
import Body from './layout/body';
import TopTitle from './component/topTitle';
import Drawer from './component/drawer';
import Card from './component/card';
import CenterTop from './centerTop';
import CenterBottom from './centerBottom';
import LeftFirst from './leftFirst';
import LeftSecond from './leftSecond';
import LeftThird from './leftThird';
import LeftForth from './leftForth';
import RightFirst from './rightFirst';
import RightSecond from './rightSecond';
import RightThird from './rightThird';
import RightForth from './rightForth';
import zhongjianPic from './images/zhongjian.png';
import zhongxiaPic from './images/zhongxia.png';
import zuo1Pic from './images/zuo-1.png';
import zuo2Pic from './images/zuo-2.png';
import zuo3Pic from './images/zuo-3.png';
import zuo4Pic from './images/zuo-4.png';
import you1Pic from './images/you-1.png';
import you4Pic from './images/you-4.png';
import { Permission } from '../../../hooks/useAuth';
import { getAreas } from './service';
import { Selector } from '../../merging/home/apply';
import styles from './styles.scss';
import { getTheme } from '@antv/g2';
import Radios from './centerTop/radio.js';
import RadiosRightFirst from './rightFirst/radio.js';
import Footer from '../common/footer'
import bottomBg1 from '../domesticStock/centerBottom/bg1.png'
import bottomBg2 from '../domesticStock/centerBottom/bg2.png'

const radiosData = [
  { name: '全国', value: 'all' },
  { name: '北京', value: 110000 },
  { name: '天津', value: 120000 }
];

const radiosData1 = [
  { name: '换手率', value: 1 },
  { name: '成交额', value: 2 },
];


const selectValue = ['科创板', '其他板块']

export default function All() {
  const [type, setType] =useState('all');
  const [value, setValue] = useState('科创板');
  const [address, setAddress] = useState('全国');
  const [provinces, setProvince] = useState([]);
  const [rightValue, setRightValue] = useState(1);

  const [bottomBg, setBottomBg]=useState(bottomBg1);

  useEffect(() => {
    getAreas().then(res => {
      setProvince(res.data.slice(3,res.data.length).map(d => d.area) || []);
    });
  }, []);

  useEffect(()=>{
      if(value=='科创板') setBottomBg(bottomBg1);
      else setBottomBg(bottomBg2);
  },[value]);

  function change(addr) {
    getAreas().then(res => {
      const arr = res.data.slice(2,res.data.length).reduce((acc, cur) => {
        if (addr.indexOf(`${cur.area}`) !== -1) {
          acc.push(cur.code)
          return acc;
        }
        return acc;
      }, [])
      setType(arr)
    })
  }


  const defaultTheme = getTheme();

  // console.log(defaultTheme.components.axis);
  defaultTheme.labels.style.fill = "#fff";

  defaultTheme.components.axis.left.label.style.fill = "#fff";
  defaultTheme.components.axis.right.label.style.fill = "#fff";
  defaultTheme.components.axis.bottom.label.style.fill = '#fff';
  defaultTheme.components.axis.circle.label.style.fill = '#fff';
  defaultTheme.components.axis.radius.label.style.fill = '#fff';
  defaultTheme.components.legend.bottom.itemName.style.fill = '#fff';
  defaultTheme.components.legend.right.itemName.style.fill = '#fff';
  defaultTheme.components.legend.top.itemName.style.fill = '#fff';


  return (
      <Permission permission={502}>
      <Body>
        <TopTitle />
        <Drawer position="left" style={{ width: '25vw', left: window.screen.width < 1920 ? '25px' : '30px' }}>
          <Card
            title={
              <div className={ styles.leftFirstTitle }>IPO申报情况</div>
            }
            style={{
              width: '25vw',
              height: '29vh',
              marginTop: window.screen.width < 1920 ? '20px' : '30px',
              background: `url(${you1Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}>
            <LeftFirst address={type} style={{ height: '100%' }} />
          </Card>
          <Card
            style={{
              width: '25vw',
              height: '22vh',
              background: `url(${zuo2Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <LeftSecond address={type} style={{ height: '100%' }} />
          </Card>
          <Card
            style={{
              width: '25vw',
              height: '22vh',
              background: `url(${zuo3Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}>
            <LeftThird address={type} style={{ height: '100%' }} />
          </Card>
          <Card
            style={{
              width: '25vw',
              height: '21vh',
              background: `url(${zuo4Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <LeftForth address={type} style={{ height: '100%' }} />
          </Card>
        </Drawer>
        <Drawer position="right" style={{ width: '28vw', right: window.screen.width < 1920 ? '-10px' : '-15px', }}>
          <Card
            title={
              <div className={ styles.rightFirstTitle }>
                最新上市公司
              </div>
            }
            style={{
              width: '26vw',
              height: '28vh',
              marginTop: window.screen.width < 1920 ? '30px' : '40px',
              background: `url(${you1Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              position: 'relative'
            }}
          >
            <RadiosRightFirst
                  value={rightValue}
                  data={radiosData1}
                  defaultValue={1}
                  onChange={e => {
                    setRightValue(e.target.value)
                  }}
            />
            <RightFirst address={type} value={rightValue} style={{ height: '100%' }} />
          </Card>
          <Card
            title={
              <div className={ styles.rightSecondTitle }>
                各行业上市公司
              </div>
            }
            style={{
              width: '25.6vw',
              height: '24vh',
              background: `url(${zuo2Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <RightSecond address={type} style={{ height: '100%' }} />
          </Card>
          <Card
            style={{
              width: '25.6vw',
              height: '22vh',
              background: `url(${zuo2Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <RightThird address={type} style={{ height: '100%' }} />
          </Card>
          <Card
            style={{
              width: '25.6vw',
              height: '19vh',
              background: `url(${you4Pic})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            <RightForth address={type} style={{ height: '100%' }} />
          </Card>
        </Drawer>
        <div
          style={{
            background: `url(${zhongjianPic})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            height: '93%',
            width: '45%',
            marginLeft: '27.2%',
            marginTop: window.screen.width < 1920 ? '30px' : '50px',
            position: 'relative',
          }}
        >
            <div
              className={ styles.selector }
            >
              <Radios
                  value={type}
                  data={radiosData}
                  defaultValue={'all'}
                  onChange={e => setType(e.target.value)}
              />
              <Selector
                defaultValue={'更多'}
                className={ styles.selectValue }
                placeholder="请选择区域"
                options={[...provinces]}
                onChange={addr => {
                  // 只展示北京市下属地区的数据，其他国内地区展示全国数据
                  change(addr);
                }}
              />
            </div>
            <CenterTop address={type} style={{ height: '60vh' }} />
          <div
            style={{
              width: '44vw',
              height: '28vh',
              marginLeft: 10,
              background: `url(${zhongxiaPic})`,
              // background: `url(${bottomBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              position: 'relative'
            }}
          >
              <Selector
                defaultValue={'科创板'}
                className={ styles.selectorValue }
                placeholder="请选择区域"
                options={selectValue}
                onChange={value => {
                   setValue(value)
                }}
              />
              <CenterBottom address={type} value={value} style={{ height: '100%' }} />
          </div>
            <Footer/>
        </div>
      </Body>
      </Permission>
  );
}
