/**
 * @author Ray
 * @desc 基本信息
 */
import React, {useEffect, useRef, useContext} from 'react';
import styles from './styles.scss';
import Title from '../../../common/title';
import {Divider} from 'antd';
import BasicForm from './form';
import {isEmpty, isNotEmpty} from '../../../utils/utils';
import {SaveButton} from '../../../common/buttons';
import {GlobalContext} from '../../../common/globalStore/createProvider';

export default React.memo(function(props) {
    // const [user] = useStorage('UserInfo');
    const basicRef = useRef(null);
    const {user} = useContext(GlobalContext);
    useEffect(function() {
        // 获取企业基本信息
        props.getBaseInfo(user?.creditCode).then(function(res) {
            if (isNotEmpty(res)) {
                const {
                    industryLabel,
                    industrySubclass,
                    srcIndustry,
                    plate
                } = res;
                basicRef.current.setFieldsValue({
                    industryLabel,
                    industrySubclass,
                    srcIndustry,
                    plate: plate ? plate.split(',') : undefined
                });
            }
        });
    }, []);

    // 提交企业基本信息修改
    function handleSubmit() {
        basicRef.current.validateFieldsAndScroll(function(error, values) {
            if (isEmpty(error)) {
                props.addBaseInfo({
                    ...values
                });
            }
        });
    }

    return (
        <section className={styles.root}>
            <Title text='基本信息' />
            <Divider />
            <BasicForm onInit={ref => (basicRef.current = ref)} />
            <div className='center'>
                <SaveButton onClick={handleSubmit} />
            </div>
        </section>
    );
});
