/* eslint-disable react/no-multi-comp */
/**
 * @author Ray
 */

import React from 'react';
import styles from './styles.scss';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SaveButton } from '../buttons';

function Footer({ onOk, onClose, okText, cancelText }) {
    return (
        <div
            className="center"
            style={{
                padding: '0 8px'
            }}
        >
            {onClose && (
                <SaveButton
                    type="default"
                    text={cancelText}
                    onClick={onClose}
                />
            )}
            {onOk && <SaveButton text={okText} onClick={onOk} />}
        </div>
    );
}

Footer.defaultProps = {};

Footer.propTypes = {
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string
};

export default function ModalEx(props) {
    const {
        onOk,
        onCancel,
        okText,
        cancelText,
        footer,
        className,
        onClose,
        ...restProps
    } = props;

    return (
        <Modal
            {...props}
            centered
            className={classnames(styles.modalRoot, className)}
            {...restProps}
            onCancel={onCancel}
            footer={
                footer === null ? null : (
                    <Footer
                        onOk={onOk}
                        onClose={onClose}
                        okText={okText}
                        cancelText={cancelText}
                    />
                )
            }
        />
    );
}

ModalEx.defaultProps = {
    okText: '确认',
    cancelText: '取消'
};
ModalEx.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string
};
