/**
 * @author Ray
 * @desc 合并重组
 */

import React from 'react';
import Buttons from './buttons';
import Apply from './apply';
import styles from './styles.scss';
import Title from '../../../common/title';
import { Divider } from 'antd';

export default function View(props) {
    return (
        <div className={styles.root}>
            <Buttons history={props.history} />
            <Title level={2} text="我的申请" />
            <Divider />
            <Apply history={props.history} dispatch={props.dispatch} />
        </div>
    );
}
