/**
 * @desc  柱形图
 */
import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import Radios from './radio';

export default function BarChart() {
    const [type, setType] = useState(1);

    const radiosData = [
        { name: '行业分布', value: 1 },
        { name: '营业收入规模', value: 2 },
    ];
    
    useEffect(() => {
        const data = [
            { year: '1951 年', sales: 38 },
            { year: '1952 年', sales: 52 },
            { year: '1956 年', sales: 61 },
            { year: '1957 年', sales: 145 },
            { year: '1958 年', sales: 48 },
            { year: '1959 年', sales: 38 },
            { year: '1960 年', sales: 38 },
            { year: '1962 年', sales: 38 },
        ];
        const chart = new Chart({
            container: 'Line5BarChart',
            autoFit: true,
            height: 500,
        });

        chart.data(data);
        chart.scale('sales', {
            nice: true,
        });
        
        chart.tooltip({
            showMarkers: false
        });
        chart.interaction('active-region');

        chart.interval()
        .position('year*sales')
        .label('sales')
        chart.render();
    }, [])




    
    return (
        <div className={ styles.barChart }>
            <Radios
                value={type}
                data={radiosData}
                defaultValue={1}
                onChange={e => setType(e.target.value)}
            />
            <div id='Line5BarChart' className={ styles.line5BarChart }></div>
        </div>
    )
}
