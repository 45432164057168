import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'fast-deep-equal';
import { filterInvest, filterNotInvest} from './config';
let Higgscharts = null;

export default class HiggsChartsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chart: null
        };
    } 
    static defaultProps = {
        DEV: false,
        id: 'higgsCharts',
        className: null,
        plugins: [],
        onReady: null,
        onDispose: null,
        onEvents: {},
        status: {
            name: true,         // 关联方名称
            relation: false,     // 关联关系
            num: false,         // 关联数量
            ratio: false,        // 投资比例
            followed: false,    // 已关注标识
            remark: false,      // 有备注标识
            path: false         // 风险路径
        },
        option: {
            chart: {
                type: 'circle',
                animation: false,
                scale: [1.0, 1.0],
                draggable: true,     // 节点是否可拖动
                data: {
                    
                },
                filter(params, state, target){ // 数据过滤 用来高亮节点
                    if(params === "invest"){
                        return filterInvest(state)
                    }else if(params === "notInvest"){
                        return filterNotInvest(state)
                    }
                }
            },
            plugins: []
        }
    }
    componentDidMount() {               
        window.__DEV__ = false;
        Higgscharts = require('higgscharts');
        this.initChart();
        this.getRationData();
    }
    componentWillUnmount() {
        this.chart = null;
    }

    componentDidUpdate(prevProps){
        if(!isEqual(prevProps.relationData, this.props.relationData)){ 
            this.getRationData();
            return false;
        }
        if(!isEqual(prevProps.isMax, this.props.isMax)){
            this.setOption(this.props.option);
            return false;
        }
       
        if(this.props.reset){
            this.setOption(this.props.option);
            return false;
        }
    }

    getRationData( ){
        // this.chart && this.clear();
        const {companyId, relationData } = this.props;

        // 不要高风险和黑名单；
        relationData.nodes.map(ele => {
            if(ele.black){
                ele.black = false;
            }
            if(ele.riskCount > 0){
                ele.riskCount = 0;
            }
        });
        let HJTranslator2 = new Higgscharts.Translator();
        const store2 = HJTranslator2.transformCircle({targetId: companyId, data: relationData});  
        // store2.data.links.map(ele => {
        //     let color = ele.relationType === 'investment' ? '#ff6b61' : '#2082ed';
        //     // ele.hovered = true;
        //     ele.slight = true;
        //     // ele.ratio = 
        //     // ele.style ={
        //     //     fill: color,
        //     //     stroke: color
        //     // };
        // })
        this.props.option.chart.data = store2.data;
        this.setOption(this.props.option);

    }

    filter(){
        if(this.props.reset){
            this.setOption(this.props.option);
            return false;
        }
    }

    initChart = () => {
        let { DEV, id, plugins } = this.props;
        // Higgscharts.settings({
        //     line: {
        //         style:{
        //             item: {
        //                 stroke: 'red'
        //             }
        //         }
               
        //     }
        // });
        this.chart = Higgscharts.init(id, {
            DEV,
            plugins
        });
        this.props.onReady && this.props.onReady(this.getInstance());
    }

    setOption(option = {}) {

        this.chart.setOption(option);
        this.chart.trigger('circle.setting.setData', {statusParam: {
            name: true,
            relation: false,
            ratio: false
        }});
    }

    clear = () => {
        this.chart.clear();
    }

    getInstance = () => {
        if (!this.chart) {
            this.initChart();
        }

        return this.chart;
    }
    render() {
        var { id, style, className, children } = this.props;

        return (
            <div id={id} className={className} style={{ ...style }} ref={c => this.relatedPartyRef = c}>
                {children}
            </div> 
        );
    }
}

HiggsChartsMap.propTypes = {
    DEV: PropTypes.bool,
    className: PropTypes.string,
    init: PropTypes.object,
    option: PropTypes.object,
    onReady: PropTypes.func,
    onDispose: PropTypes.func,
    onEvents: PropTypes.object,
    status: PropTypes.object,
    shouldSetOption: PropTypes.func,
    settings: PropTypes.object
};