import React from 'react';
import Model from './model';
import View from './view';
import Home from './home';
import CloudClassroom from './cloudClassroom';
import CloudClassDetail from './cloudClassroom/detail/index';
import Column from './column';
import ColumnDetail from './column/detail/index';
import ColumnArticleDetail from './column/article/index';
import Questionnaire from './questionnaire/index';
import QuestionnaireDetail from './questionnaire/detail/index';
import MyStudy from './myStudy/index';
import ColumnAttachment from './column/attachment/index'

export const Routes = [
    {
        exact: false,
        name: '首页',
        path: '/index',
        visible: true,
        component: Home,
        noContent: true
    },
    {
        exact: false,
        name: '云课堂',
        path: '/course',
        visible: true,
        component: CloudClassroom
    },
    {
        exact: true,
        name: '云课堂详情',
        path: '/courseDetail/:id',
        visible: true,
        component: CloudClassDetail
    },
    {
        exact: false,
        name: '专栏',
        path: '/column',
        visible: true,
        component: Column
    },
    {
        exact: true,
        name: '专栏详情',
        path: '/columnDetail/:id',
        visible: true,
        component: ColumnDetail
    },
    {
        exact: true,
        name: '专栏附件查看',
        path: '/columnAttachment/:url',
        visible: true,
        component: ColumnAttachment
    },
    {
        exact: true,
        name: '专栏文章详情',
        path: '/articleDetail/:id/:chapter/:section',
        visible: true,
        component: ColumnArticleDetail
    },
    {
        exact: false,
        name: '政企协调',
        path: '/coordination',
        visible: true,
        component: Questionnaire
    },
    {
        exact: true,
        name: '政企协调问卷调查哦',
        path: '/coordinationDetail/:id',
        visible: true,
        component: QuestionnaireDetail
    },
    {
        exact: false,
        name: '我的学习',
        path: '/learning',
        visible: true,
        component: MyStudy
    }
];

export default function(props) {
    return (
        <Model Routes={Routes}>
            {prop => {
                return <View {...props} {...prop} />;
            }}
        </Model>
    );
}
