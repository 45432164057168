/**
 * @author hjie
 * 专栏详情页
 */

import React from 'react';
import Detail from '../../common/detail/index';

const stepsData = [
    { name: '专栏介绍', domId: 'descriptionColumn', param: 'description' },
    { name: '专栏大纲', domId: 'outlineColumn', param: 'outline'}
]
export default function(props) {
   return <Detail {...props} Type = "专栏" stepsData = {stepsData} Name = "专栏"/>
}
