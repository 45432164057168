/**
 * @desc 每日成交量前十大个股情况title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line7Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line7Title }>
                每日成交量前十大个股情况
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}