import { useEffect, useState } from 'react';

export default function useProps() {
    const [props, setProps] = useState({
        dataSource: [],
        columns: []
    });

    useEffect(function() {

    }, []);

    return [props, setProps];
}
