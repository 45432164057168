'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// 字段对应的名称
var keyName = exports.keyName = {
    litigation: { // 诉讼记录
        actioncause: '案由',
        caseCode: '案号',
        caseType: '案件类型',
        caseoutCome: '案件结果',
        sentenceDate: '审判日期',
        title: '案件标题',
        city: '地区',
        actionCause: '案由',
        hearingCode: '案号',
        hearingDate: '开庭日期',
        litigant: '当事人',
        caseCreateTime: '立案日期',
        caseState: '状态',
        execCourtName: '执行法院',
        execSubject: '执行标的',
        concreteSituation: '失信被执行人行为具体情形',
        noticeContent: '公告内容',
        noticePeople: '公告人',
        noticeTime: '公告日期',
        noticeType: '公告类型'
    },
    debit: { // 借贷担保
        amount: '金额',
        closingDate: '贷款截止日',
        debtee: '贷款方(债权人)',
        debtor: '借款方(债务人)',
        sponsorMode: '担保方及担保方式',
        sponsor: '担保方',
        mode: '担保方式',
        startDate: '贷款起始日期',
        title: '案件名称'
    },
    bidding: { // 招投标信息
        city: '地区',
        companyNameInvite: '招标方',
        companyNameWin: '中标方',
        industry: '行业',
        projectName: '项目名称',
        pubdate: '发布日期'
    }
};