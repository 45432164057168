import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  股票发行情况统计
*/
//股票发行企业家数量
export function neeqIpoCompanyCount() {
    return request(`${API.V1}/cockpit/neeqStock/nine/neeqIpoCompanyCount`)
}
//股票发行行业分布情况
export function neeqIpoCompanyCountOfIndustry() {
    return request(`${API.V1}/cockpit/neeqStock/nine/neeqIpoCompanyCountOfIndustry`)
}
//股票发行地域统计
export function neeqIpoCompanyCountOfArea() {
    return request(`${API.V1}/cockpit/neeqStock/nine/neeqIpoCompanyCountOfArea`)
}