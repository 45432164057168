'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _zrender = require('zrender');

var _zrender2 = _interopRequireDefault(_zrender);

var _Layer2 = require('../interface/Layer');

var _Layer3 = _interopRequireDefault(_Layer2);

var _enum = require('../constant/enum');

var _common = require('../util/common');

var _index = require('../config/index.js');

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MainLayer = function (_Layer) {
    (0, _inherits3.default)(MainLayer, _Layer);

    function MainLayer(root, handler, options) {
        (0, _classCallCheck3.default)(this, MainLayer);

        var _this = (0, _possibleConstructorReturn3.default)(this, (MainLayer.__proto__ || (0, _getPrototypeOf2.default)(MainLayer)).call(this, root, handler, options));

        _this.type = _enum.LayerType.MAIN;

        var id = _this.type + '-layer';
        _this.container = _this.createDOM(id, options);
        root.appendChild(_this.container);
        // this.proxy = zrender.init(this.container, options);
        _this.proxy = _zrender2.default.init(_this.container);
        _this.globalGroup = new _zrender2.default.Group({
            origin: [options.width / 2, options.height / 2]
        });
        _this.proxy.add(_this.globalGroup);
        _this.proxyClass = _zrender2.default;
        return _this;
    }

    /**
     * 重写了ZRender的实例方法
     */


    (0, _createClass3.default)(MainLayer, [{
        key: 'add',
        value: function add(shape) {
            (0, _get3.default)(MainLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MainLayer.prototype), 'add', this).call(this, shape);
            var el = shape.group || shape.element;
            this.globalGroup.add(el);
        }
    }, {
        key: 'remove',
        value: function remove(shape) {
            (0, _get3.default)(MainLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MainLayer.prototype), 'remove', this).call(this, shape);
            var el = shape.group || shape.element;
            this.globalGroup.remove(el);
        }
    }, {
        key: 'removeShapes',
        value: function removeShapes(type) {
            if (!type) {
                return;
            }

            if ((0, _common.isEmpty)(this.shapes)) {
                return;
            }

            for (var id in this.shapes) {
                var shape = this.shapes[id];
                if (shape.type === type) {
                    shape.remove();
                }
            }
        }
    }, {
        key: 'reset',
        value: function reset() {
            var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _opts$scale = opts.scale,
                scale = _opts$scale === undefined ? [1, 1] : _opts$scale;


            this.globalGroup.animateTo({
                scale: scale,
                position: [0, 0]
            }, 1000, 'sinusoidalIn');
        }
        /**
         * 更新画布大小
         * @param {object} opts, {width, height } 
         */

    }, {
        key: 'resize',
        value: function resize() {
            var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var _opts$width = opts.width,
                width = _opts$width === undefined ? 0 : _opts$width,
                _opts$height = opts.height,
                height = _opts$height === undefined ? 0 : _opts$height;

            var style = this.container.style;

            style.width = width + 'px';
            style.height = height + 'px';

            this.proxy.resize();
            this.globalGroup.attr('origin', [width / 2, height / 2]);
        }
    }, {
        key: 'zoomIn',
        value: function zoomIn() {
            var scale = this.globalGroup.scale;

            var k = 0.2;
            var scaleX = scale[0];
            var scaleY = scale[1];

            scaleX += k;
            scaleY += k;

            this.zoom({
                scaleX: scaleX,
                scaleY: scaleY
            });
        }
    }, {
        key: 'zoomOut',
        value: function zoomOut() {
            var scale = this.globalGroup.scale;

            var k = 0.2;
            var scaleX = scale[0];
            var scaleY = scale[1];

            scaleX -= k;
            scaleY -= k;

            this.zoom({
                scaleX: scaleX,
                scaleY: scaleY
            });
        }
    }, {
        key: 'zoom',
        value: function zoom(_ref) {
            var scaleX = _ref.scaleX,
                scaleY = _ref.scaleY;

            var MIN = 0.2;
            var MAX = 5;

            if (scaleX < MIN) {
                scaleX = MIN;
                scaleY = MIN;
            }

            if (scaleX > MAX) {
                scaleX = MAX;
                scaleY = MAX;
            }

            this.globalGroup.attr('scale', [scaleX, scaleY]);
        }
    }, {
        key: 'drag',
        value: function drag(_ref2) {
            var event = _ref2.event,
                target = _ref2.target;

            if (target === this.globalGroup) {
                /**
                 * 从鼠标按下的位置计算拖动距离
                 * event.event 第一个event是zrender封装的event, 第二个是DOM原生的event
                 */
                target.attr('position', [event.event.clientX - target._x, event.event.clientY - target._y]);
            }
        }
    }, {
        key: 'loopLayers',
        value: function loopLayers(type, callback) {
            if (!type) {
                return;
            }

            var typeConfig = _index2.default[type];
            var container = this.container;

            (0, _keys2.default)(typeConfig.layer).forEach(function (subLayer) {
                var layer = container.querySelector('canvas[data-zr-dom-id=zr_' + typeConfig.layer[subLayer] + ']');
                if (layer && callback) {
                    callback(layer);
                }
            });
        }
    }, {
        key: 'hide',
        value: function hide(type) {
            if (type) {
                this.loopLayers(type, function (layer) {
                    if (layer.style.display !== 'none') {
                        layer.style.display = 'none';
                    }
                });
            } else {
                this.globalGroup.hide();
            }
        }
    }, {
        key: 'show',
        value: function show(type) {
            if (type) {
                this.loopLayers(type, function (layer) {
                    if (layer.style.display !== 'block') {
                        layer.style.display = 'block';
                    }
                });
            } else {
                this.globalGroup.show();
            }
        }
    }, {
        key: 'getGlobalGroup',
        value: function getGlobalGroup() {
            return this.globalGroup;
        }
    }, {
        key: 'addHover',
        value: function addHover(el, style) {
            this.proxy.addHover(el, style);
        }
    }, {
        key: 'getAnimation',
        value: function getAnimation() {
            return this.proxy.animation;
        }
    }, {
        key: 'configLayer',
        value: function configLayer(zLevel, config) {
            this.proxy.configLayer(zLevel, config);
        }
    }, {
        key: 'flush',
        value: function flush() {
            this.proxy.flush();
        }
    }, {
        key: 'getWidth',
        value: function getWidth() {
            return this.proxy.getWidth();
        }
    }, {
        key: 'getHeight',
        value: function getHeight() {
            return this.proxy.getHeight();
        }
    }, {
        key: 'off',
        value: function off(eventName, eventHandler) {
            this.proxy.off(eventName, eventHandler);
        }
    }, {
        key: 'on',
        value: function on(eventName, eventHandler, context) {
            this.proxy.on(eventName, eventHandler, context);
        }
    }, {
        key: 'trigger',
        value: function trigger(eventName, event) {
            this.proxy.trigger(eventName, event);
        }
    }, {
        key: 'refresh',
        value: function refresh() {
            this.proxy.refresh();
        }
    }, {
        key: 'refreshHover',
        value: function refreshHover() {
            this.proxy.refreshHover();
        }
    }, {
        key: 'refreshHoverImmediately',
        value: function refreshHoverImmediately() {
            this.proxy.refreshHoverImmediately();
        }
    }, {
        key: 'refreshImmediately',
        value: function refreshImmediately() {
            this.proxy.refreshImmediately();
        }
    }, {
        key: 'removeHover',
        value: function removeHover() {
            this.proxy.removeHover(el);
        }
    }, {
        key: 'drawFullLayer',
        value: function drawFullLayer(options) {
            var _options$state = options.state,
                state = _options$state === undefined ? {} : _options$state,
                getRegisterShape = options.getRegisterShape,
                chartType = options.chartType,
                backgroundColor = options.backgroundColor,
                callback = options.callback;
            // 增大一些全屏大小, 保证边缘节点的文字可以全部看见
            // var overflowX = 220;
            // var overflowY = 100;

            var overflowX = 400;
            var overflowY = 200;

            var _globalGroup$getBound = this.globalGroup.getBoundingRect(),
                x = _globalGroup$getBound.x,
                y = _globalGroup$getBound.y,
                width = _globalGroup$getBound.width,
                height = _globalGroup$getBound.height;

            // 创建临时div容器        


            var div = document.createElement('div');
            var offscreenZR = _zrender2.default.init(div, {
                width: width + overflowX,
                height: height + overflowY
            });
            // 图谱开始绘制的位置, 去除大片留白的区域.
            var _x = 0 - x + overflowX / 2;
            var _y = 0 - y + overflowY / 2;
            var group = new _zrender2.default.Group({
                position: [_x, _y]
            });

            var shapes = [];
            for (var type in state) {
                var shapesState = state[type];
                for (var id in shapesState) {
                    var shapeState = shapesState[id];
                    var Shape = getRegisterShape(type);
                    var shape = new Shape(shapeState);
                    shapes.push(shape);
                }
            }

            shapes.forEach(function (shape) {
                var el = shape.group || shape.element;
                shape.draw(true);
                group.add(el);
            });

            offscreenZR.add(group);
            offscreenZR.refreshImmediately();

            // 第一次图片是异步加载的, 所以不能直接返回. 第二次就有缓存了.
            setTimeout(function () {
                var renderedCanvas = offscreenZR.painter.getRenderedCanvas({
                    backgroundColor: backgroundColor
                });
                var ctx = renderedCanvas.getContext('2d');
                ctx.setTransform(1, 0, 0, 1, 0, 0); // 重置矩阵

                callback && callback(ctx);
            }, 0);
        }
    }, {
        key: 'clear',
        value: function clear() {
            (0, _get3.default)(MainLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MainLayer.prototype), 'removeAll', this).call(this);
            this.globalGroup.removeAll();
            this.clearAnimation();
            this.clearHover();
        }
    }, {
        key: 'clearAnimation',
        value: function clearAnimation() {
            this.proxy.clearAnimation();
        }
    }, {
        key: 'clearHover',
        value: function clearHover() {
            this.proxy.clearHover();
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.clear();
            this.proxy.dispose();
            (0, _get3.default)(MainLayer.prototype.__proto__ || (0, _getPrototypeOf2.default)(MainLayer.prototype), 'dispose', this).call(this);
        }
    }]);
    return MainLayer;
}(_Layer3.default);

exports.default = MainLayer;
module.exports = exports['default'];