/**
 * @author Ray
 * @desc 公司信息
 */
import React, { useContext } from 'react';
import { Col, Icon, List, Row, Typography } from 'antd';
import styles from './styles.scss';
import useStorage from '../../../hooks/useStorage';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import Typo from '../../../common/addonTypo';
import { TemplateButton } from '../../../common/buttons';

export default function Company() {
    // const [user] = useStorage('UserInfo');
    const { user } = useContext(GlobalContext);
    return (
        <div className={styles.company}>
            <Typography.Title level={4}>{user?.realName}</Typography.Title>
            <List.Item>
                <List.Item.Meta
                    description={
                        <React.Fragment>
                            <Typo
                                prefix={
                                    <Icon
                                        type="phone"
                                        theme="twoTone"
                                        twoToneColor="#30CFB7"
                                    />
                                }
                            >
                                联系人电话：{user?.phone}
                            </Typo>
                            <Typo
                                prefix={
                                    <Icon
                                        type="mail"
                                        theme="twoTone"
                                        twoToneColor="#FDAD4D"
                                    />
                                }
                            >
                                企业邮箱：{user?.email}
                            </Typo>
                        </React.Fragment>
                    }
                />
            </List.Item>
        </div>
    );
}
