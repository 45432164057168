/**
 * @author hjie
 */
import style from "./style.scss";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Alert, Tabs } from "antd";
import classnames from "classnames";
import PswLoginForm from "./pswLogin";
import CodeLoginForm from "./codeLogin";
const { TabPane } = Tabs;
import hooks from "./hook";
// import LoginLeftImg from '../images/loginLeftImg.png';
import ContentImg from "../images/content.png";
import LogoImg from "../images/titleLogo.png";
import ThreeImg from "../images/three.png";
import Pic1Img from "../images/pic_1.png";
import Pic2Img from "../images/pic_2.png";
import Pic3Img from "../images/pic_3.png";
import Pic4Img from "../images/pic_4.png";
import { showSms } from "../service";
import { GlobalContext } from "../../../common/globalStore/createProvider";

export default function Login({ history }) {
  const { setPasscodeExpired } = useContext(GlobalContext);
  const { visibleMsg, handleClose, ...others } = hooks();
  const [smsVisible, setSmsVisible] = useState(false);
  const [key, setKey] = useState(1);

  useEffect(() => {
    setPasscodeExpired(false);
    localStorage.removeItem("localLoginName"); // 移除账号过期缓存的用户名
    showSms().then((res) => {
      if (res) {
        setSmsVisible(res.data);
      }
    });
  }, []);

  return (
    <div>
      <div className={classnames(style.login)}>
        <div className={style.loginTop}>
          <img src={LogoImg}></img>
          <div className={style.title}>北京企业上市综合服务平台</div>
        </div>
        <div className={style.loginTitle}>
          <span>
            <Link to="/user/register">上市奖励</Link>
          </span>
          <span>
            <Link to="/user/register">兼并重组</Link>
          </span>
          <span>
            <Link to="/user/register">资本学院</Link>
          </span>
          <span>
            <Link to="/user/register">风险监测</Link>
          </span>
          <span>
            <Link to="/user/register">精品投行</Link>
          </span>
          <span>
            <Link to="/user/register">全景分析</Link>
          </span>

        </div>
        <div className={style.loginLeft}>
          {/* <h1 className={style.leftTitle}>北京企业上市综合服务平台</h1> */}
          {/* <img className={style.loginImg} src={ContentImg}></img> */}
          <div className={style.loginImg} >上市奖励申报系统</div>
        </div>
        <div className={style["loginForm"]}>
          <h3 className={smsVisible ? style.rightTitles : style.rightTitle}>
            欢迎登录
          </h3>
          <div className={key === 1 ? style.rightContent : style.rightContents}>
            {visibleMsg.visible && key === 2 && (
              <Alert
                className={style.alertMsg}
                message={visibleMsg.msg}
                type="error"
                showIcon
                closable
                afterClose={handleClose}
              />
            )}
            {/* {
              smsVisible ? <PswLoginForm history={history} {...others} smsVisible={smsVisible} /> : ( */}
            {/* <Tabs defaultActiveKey='1' tabBarStyle={{ color: '#ffffff' }}>
                  <TabPane tab='免密登录' key='1'>
                    <CodeLoginForm history={history} {...others} smsVisible={smsVisible} />
                  </TabPane>
                  <TabPane tab='密码登录' key='2'>
                    <PswLoginForm history={history} {...others} smsVisible={smsVisible} />
                  </TabPane>
                </Tabs> */}
            {smsVisible ? (
              <PswLoginForm
                history={history}
                {...others}
                smsVisible={smsVisible}
              />
            ) : (
              <>
                <div className={style.rightTab}>
                  <div
                    onClick={() => {
                      setKey(1);
                    }}
                    style={
                      key === 2
                        ? { opacity: "0.6", marginLeft: 35 }
                        : { opacity: "1" }
                    }
                  >
                    免密登录
                    <br />
                    {key === 1 ? <img src={ThreeImg}></img> : ""}
                  </div>
                  <div
                    onClick={() => {
                      setKey(2);
                    }}
                    style={
                      key === 1
                        ? { opacity: "0.6" }
                        : { opacity: "1", marginLeft: 80 }
                    }
                  >
                    密码登录
                    <br />
                    {key === 2 ? <img src={ThreeImg}></img> : ""}
                  </div>
                </div>
                {key === 1 ? (
                  <CodeLoginForm
                    history={history}
                    {...others}
                    smsVisible={smsVisible}
                  />
                ) : (
                  <PswLoginForm
                    history={history}
                    {...others}
                    smsVisible={smsVisible}
                  />
                )}
              </>
            )}

            {/* )
            } */}
            <div className={style.btns}>
             {/* 跳转新系统注册 */}
              {
              /* <a href="https://qyfwnew.beijingipo.com.cn/user/login" target="_blank" rel="noopener noreferrer" className="fl">新用户注册</a>
              <a href="https://qyfwnew.beijingipo.com.cn/user/login" target="_blank" rel="noopener noreferrer"  >忘记密码？</a> */}


              {/* <Link to="/user/complaintAccount/false" className="fr">
                账号申诉
              </Link> */}
              {/* 老系统注册 */}
              <Link to="/user/register" className="fl">
                新用户注册         </Link>
              <Link to="/user/forgetPsw">忘记密码？</Link>
  
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", bottom: -65 }}>
        <div className={style.bot_img}>
          <img src={Pic1Img}></img>
          <img src={Pic2Img}></img>
          <img src={Pic3Img}></img>
          <img src={Pic4Img}></img>
        </div>
      </div>
    </div>
  );
}
