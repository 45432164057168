"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return idStart++;
};

/**
 * 生成唯一id
 */
var idStart = 0x0907;

module.exports = exports["default"];