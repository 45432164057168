/**
 * @author Ray
 * @description 利润表
 */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, message, Tabs } from 'antd';
import { FlexibleTable } from '../flexibleTable';
import { SaveButton } from '../buttons';
import styles from './styles.scss';
import { callFinance, submitProfitInfo } from './service';
import { isEmpty, isNotEmpty } from '../../utils/utils';
import { convertData } from './utils';
import useProps from './useTableProps';
import useTimeRangerProps from './useTimeRangerProps';
import { GlobalContext } from '../globalStore/createProvider';

const { TabPane } = Tabs;

let formMap = new Map();

export default function Assets({ readOnly, applyId, signal, type, code }) {
    const [year, setYear] = useState(2022);
    const [tableProps, setTableProps] = useProps();
    const [query, setQuery] = useTimeRangerProps(readOnly);
    const { user } = useContext(GlobalContext);

    function handleFormInit(name, form) {
        formMap.set(name, form);
    }

    // 第一次加载的时候调用一次获取数据
    useEffect(
        function() {
            if (isNotEmpty(signal)) {
                applyId = undefined;
            }

            getFinance(query, user, applyId);
        },
        [signal, applyId, year]
    );

    // 查找财务信息
    function getFinance(query, user, applyId) {
        callFinance(
            parseInt(year),
            parseInt(year),
            3,
            code || user?.creditCode,
            applyId,
            undefined,
            type
        ).then(function(res) {
            if (isEmpty(res))
                return setTableProps({
                    columns: [],
                    dataSource: []
                });

            let tableProps = convertData(3, res.data);

            setTableProps({ 
                ...tableProps 
            });

            //  form表单重置为默认值
            formMap.forEach((form) => {
                form.resetFields();
            })
        });
    }

    function handleSave() {
        formMap.forEach((form) => {
            form.validateFieldsAndScroll((errors, values) => {
                
                let obj = {
                    "flowAsset": values['流动资产：'] || '--',
                    "monetaryFund": values['货币资金'] || '--',
                    "changeFinancingAsset": values['交易性金融资产'] || '--',
                    "derivationFinancingAsset": values['衍生金融资产'] || '--',
                    "notesReceivable": values['应收票据'] || '--',
                    "accountsReceivable": values['应收账款'] || '--',
                    "receivablesFinancing": values['应收款项融资'] || '--',
                    "advancePayment": values['预付款项'] || '--',
                    "otherReceivable": values['其他应收款'] || '--',
                    "stock": values['存货'] || '--',
                    "contractualAssets": values['合同资产'] || '--',
                    "holdingAssetsSale": values['持有待售资产'] || '--',
                    "expireNoflowAsset": values['一年内到期的非流动资产'] || '--',
                    "otherFlowAsset": values['其他流动资产'] || '--',
                    "flowAssetTotal": values['流动资产合计'] || '--',
                    "noflowAsset": values['非流动资产：'] || '--',
                    "debtInvestment": values['债权投资'] || '--',
                    "otherDebtInvestment": values['其他债权投资'] || '--',
                    "longTermReceivables": values['长期应收款'] || '--',
                    "longTermEquityInvestment": values['长期股权投资'] || '--',
                    "otherEquityInstrumentsInvestment": values['其他权益工具投资'] || '--',
                    "otherNoflowAssetFinancingAsset": values['其他非流动金融资产'] || '--',
                    "investmentRealEstate": values['投资性房地产'] || '--',
                    "fixedAsset": values['固定资产'] || '--',
                    "constructionProgress": values['在建工程'] || '--',
                    "biologyAsset": values['生产性生物资产'] || '--',
                    "associatedGasAsset": values['油气资产'] || '--',
                    "usufructAsset": values['使用权资产'] || '--',
                    "intangibleAsset": values['无形资产'] || '--',
                    "developmentExpenditure": values['开发支出'] || '--',
                    "goodwill": values['商誉'] || '--',
                    "longTermPendingExpenses": values['长期待摊费用'] || '--',
                    "deferredTaxAsset": values['递延所得税资产'] || '--',
                    "otherNoflowAsset": values['其他非流动资产'] || '--',
                    "noflowAssetTotal": values['非流动资产合计'] || '--',
                    "assetTotal": values['资产总计'] || '--',
                    "flowLiabilities": values['流动负债：'] || '--',
                    "shortTermLoan": values['短期借款'] || '--',
                    "transactionalFinancialLiabilities": values['交易性金融负债'] || '--',
                    "derivativeFinancialLiabilities": values['衍生金融负债'] || '--',
                    "notesPayable": values['应付票据'] || '--',
                    "accountsPayable": values['应付账款'] || '--',
                    "advanceReceive": values['预收款项'] || '--',
                    "contractualLiability": values['合同负债'] || '--',
                    "payableRemuneration": values['应付职工薪酬'] || '--',
                    "taxesPayable": values['应交税费'] || '--',
                    "otherAccountsPayable": values['其他应付款'] || '--',
                    "holdingLiabilitiesSale": values['持有待售负债'] || '--',
                    "expireNoflowLiabilities": values['一年内到期的非流动负债'] || '--',
                    "otherFlowLiabilities": values['其他流动负债'] || '--',
                    "flowLiabilitiesTotal": values['流动负债合计'] || '--',
                    "noflowLiabilities": values['非流动负债：'] || '--',
                    "longTermLoan": values['长期借款'] || '--',
                    "bondsPayable": values['应付债券'] || '--',
                    "bondsPayablePreferredStock": values['其中：优先股'] || '--',
                    "bondsPayableSustainableDebt": values['永续债'] || '--',
                    "leaseLiabilities": values['租赁负债'] || '--',
                    "longTermAccountsPayable": values['长期应付款'] || '--',
                    "projectedLiabilities": values['预计负债'] || '--',
                    "deferredIncome": values['递延收益'] || '--',
                    "deferredTaxLiability": values['递延所得税负债'] || '--',
                    "otherNoliabilities": values['其他非流动负债'] || '--',
                    "noflowLiabilitiesTotal": values['非流动负债合计'] || '--',
                    "liabilitiesTotal": values['负债合计'] || '--',
                    "ownerEquity": values['所有者权益（或股东权益）：'] || '--',
                    "paidAsset": values['实收资本（或股本）'] || '--',
                    "otherEquityTool": values['其他权益工具'] || '--',
                    "otherEquityToolPreferredStock": values['其中：优先股'] || '--',
                    "otherEquityToolSustainableDebt": values['永续债'] || '--',
                    "assetSurplus": values['资本公积'] || '--',
                    "treasuryStock": values['减：库存股'] || '--',
                    "otherComprehensiveBenefits": values['其他综合收益'] || '--',
                    "specialReserve": values['专项储备'] || '--',
                    "surplusReserves": values['盈余公积'] || '--',
                    "undistributedProfits": values['未分配利润'] || '--',
                    "ownerEquityTotal": values['所有者权益（或股东权益）合计'] || '--',
                    "liabilitiesOwerEquityTotal": values['负债和所有者权益（或股东权益）总计'] || '--'
                }
                
                submitProfitInfo(
                    3,
                    parseInt(year),
                    {},
                    obj,
                    {}
                ).then(res => {
                    if (res.success == true) {
                        message.success(res.message);
                    } else {
                        message.error(res.message);
                    }
                })
            })
        })
    }
    return (
        <div className={styles.root}>
            {readOnly ? 
                <Tabs onChange={(val) => setYear(val)}>
                    <TabPane tab='2022' key='2022'></TabPane>
                    <TabPane tab='2021' key='2021'></TabPane>
                    <TabPane tab='2020' key='2020'></TabPane>
                </Tabs>
                    : 
                (<Row className={styles.row}>
                    <Col span={17}>
                        <Tabs onChange={(val) => setYear(val)}>
                            <TabPane tab='2022' key='2022'></TabPane>
                            <TabPane tab='2021' key='2021'></TabPane>
                            <TabPane tab='2020' key='2020'></TabPane>
                        </Tabs>
                    </Col>
                </Row>
            )}
            <FlexibleTable {...tableProps} onInit={handleFormInit} year={year} type={3} readOnly={readOnly} />
            {readOnly == false && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </div>
    );
}
