export const qualifyColumns = [
    {
        dataIndex: 'index',
        title: '序号',
        align: 'center',
        render: (text, record, index) => index + 1,
        width: '10%'
    },
    {
        dataIndex: 'condition',
        title: '上市条件',
        align: 'center',
        width: '65%'
    }
];

export const RecommendColumns = [
    {
        key: 'index',
        dataIndex: 'index',
        title: '序号',
        align: 'center',
        render: (text, record, index) => index + 1,
        width: '10%'
    },
    {
        dataIndex: 'reason',
        title: '理由',
        align: 'center',
        width: '75%'
    }
];

export const columns3 = [
    {
        dataIndex: 'feature1',
        title: '特征一',
        align: 'center'
    },
    {
        dataIndex: 'feature2',
        title: '特征二',
        align: 'center'
    },
    {
        dataIndex: 'feature3',
        title: '特征三',
        align: 'center'
    },
    {
        dataIndex: 'feature4',
        title: '特征四',
        align: 'center'
    },
    {
        dataIndex: 'feature5',
        title: '特征五',
        align: 'center'
    }
];
