/**
 * @desc  股票发行情况统计
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import BarCharts from './barcharts';

export default function Line11Index() {
    
    return (
        <div className={ styles.line11 }>
            <Title />
            <BarCharts />
        </div>
        
    );
}
