/**
 * @author hjie
 */
import style from './style.scss';
import React, { useState, useRef, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Input,
  Form,
  Select,
  Button,
  Checkbox,
  Modal,
  message,
  AutoComplete,
  Alert
} from 'antd';
import {
  UserName,
  EmailCheck,
  CellphoneCheck,
  PswCheckSymbol,
  PswCheckLetter,
  PswCheckNum,
  SpaceCheck
} from 'utils/validator';
import {
  registerApi,
  validateName,
  validateCreditcode,
  validateCertificate,
  searchCompany,
  searchIntermediaryCompany
} from '../service';
import { RegisterUserType, StatusCode } from 'constants/enum';
import { btnLayoutCol, modalformLayout } from 'constants/common';
import VerifyCode from 'common/verifyCode';
import VerifyPhone from 'common/verifyPhone';
import ErrorTip from 'common/errorTip';
import BackBtn from 'common/backBtn';
import getKeyHook from '../../../hooks/getKeyHook';
import Hook from './hook';
import GlobalContext from '../../router/index.js';

const { Option } = Select;
const { confirm } = Modal;
const TEMPLATE = 1; // 模板ID

const AlertInfo = {
  2: '每个企业只能注册一个用户，如需变更需要提交相关证明资料',
  3: '每个执业/从业资格证书只能注册一个用户，如需变更需要提交相关证明资料',
  7: '每个手机号只能注册一个用户，如需变更需要提交相关证明资料',
};

function Register({ form, history }) {
  const { getFieldDecorator } = form;
  const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();
  const gs = useContext(GlobalContext);
  const [organization,setOrganization]=useState({});

  const {
    phoneEl,
    codeEl,
    validateImg,
    setValidateImg,
    getCode,
    resetCodeItem,
    dataSource,
    setdataSource,
    //organization,
    onSelect,
    funcRef,
    userType,
    setUserType,
    companyError
  } = Hook(form, TEMPLATE);

  const [nameValidate, setNameValidate] = useState({
    hasFeedback: false, // 用于给输入框添加反馈图标
    validateStatus: '', // 校验状态，可选 'success', 'warning', 'error', 'validating'。
    showErrTip: false
  });

  //公司名称
  const [companyName,setCompanyName]=useState('');

  // 用户名验证是否已被注册
  const checkUserName = e => {
    form.validateFields(['username'], (errors, values) => {
      if (!errors) {
        getUserName(values.username); // 接口验证是否存在相同用户名
      }
    });
  };

  function getUserName(userName) {
    validateName({
      userName
    }).then(res => {
      if (res.statusCode === StatusCode.success && !res.data) {
        setNameValidate({ hasFeedback: true, validateStatus: '' });
        return;
      }
      setNameValidate({
        hasFeedback: false,
        validateStatus: 'error',
        showErrTip: true
      });
    });
  }

  // 重置用户设置
  const resetUserName = e => {
    setNameValidate({
      hasFeedback: false,
      validateStatus: '',
      showErrTip: false
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!values.imgCaptcha) {
        setValidateImg(true);
        return;
      }
      if (!err && !nameValidate.showErrTip && !validateImg) {
        const { agreement, confirmPassword, ...others } = values; // 数据过滤
        validateCompany({ ...others, ...organization });
      }
    });
  };

  // 验证企业或中介机构是否已被注册
  const validateCompany = values => {
    let respPromise = '',
      text = '';
    if (userType === '2') {
      respPromise = validateCreditcode({ creditCode: values.creditCode });
      text = '企业';
    } else if (userType === '3') {
      respPromise = validateCertificate({ certificate: values.code });
      text = '资格证书';
    } else {
      respPromise = '';
      text = '';
    }
    respPromise ? respPromise.then(res => {
      if (res.statusCode === StatusCode.success) {
        if (res.data) {
          confirm({
            title: `该${text}已被注册，是否进行申诉`,
            content: '',
            cancelText: '取消',
            okText: '账号申诉',
            onOk() {
              sessionStorage.setItem('complaintInfo', JSON.stringify(values));
              history.push('/user/complaintAccount/true');
            },
            onCancel() {
              resetCodeItem();
            }
          });
        } else {
          goRegister(values);
        }
      } else {
        message.error(res.message);
        resetCodeItem();
      }
    }) : goRegister(values);
  };

  const goRegister = param => {
    getRsaPreparedAsync().then(res => {
      if (res && res.success) {
        const rsaPassWord = getRsaPassWord(param.password);
        let json = { ...param, password: rsaPassWord };
        registerApi(json).then(res => {
          if (res.statusCode === StatusCode.success) {
            Modal.success({
              title: '提示',
              okText: '确定',
              content: '注册成功',
              onOk() {
                history.push('/user/login');
              }
            });
          } else {
            Modal.error({
              title: '提示',
              okText: '确定',
              content: res.message
            });
            resetCodeItem();
          }
        });
      }
    });
  };

  return (
    <div className={classnames(style.register)}>
      <BackBtn history={history} url="/user/login" text="返回登录" />
      {userType ? (
        <Alert
          className={style.typeWarning}
          message={AlertInfo[userType]}
          type="warning"
          showIcon
        />
      ) : null}
      <div className={classnames(style.formWrap, 'formWrap')}>
        <div className="userTitle">用户注册</div>
        <Form onSubmit={handleSubmit} {...modalformLayout}>
          <Form.Item label="用户类型">
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请选择用户类型" />
                }
              ]
            })(
              <Select
                placeholder="选择用户类型"
                onChange={val => {
                  setUserType(val);
                  setdataSource([]);
                  form.setFieldsValue({ organization: undefined });
                }}
                getPopupContainer={() => document.getElementById('type')}
              >
                {Object.keys(RegisterUserType).map(key => {
                  const item = RegisterUserType[key];
                  return (
                    <Option value={key} key={key}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="用户名"
            hasFeedback={nameValidate.hasFeedback}
            validateStatus={nameValidate.validateStatus}
          >
            {getFieldDecorator('username', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入用户名" />
                },
                {
                  pattern: UserName.pattern,
                  message: <ErrorTip text={UserName.message} />
                }
              ]
            })(
              <Input
                type="text"
                placeholder="4-10位中文、英文（区分大小写）或数字"
                autoComplete="off"
                onFocus={e => resetUserName(e)}
                onBlur={e => checkUserName(e)}
              />
            )}
            {nameValidate.showErrTip && (
              <div className="ant-form-explain">
                <ErrorTip text="该用户名已被使用" />
              </div>
            )}
          </Form.Item>
          <Form.Item label="企业名称">
            {getFieldDecorator('organization', {
              validateTrigger: 'onBlur',
              validateFirst: true,
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入关键字选择" />
                },
                // {
                //   validator: (rule, value, callback) => {
                //     if (userType === '2') {
                //       organization
                //         ? callback()
                //         : callback(<ErrorTip text="请从列表中选择企业" />);
                //     } else {
                //       callback();
                //     }
                //   }
                // },
                {
                  validator: (rule, value, callback) => {
                    console.log('rule',value);
                    setOrganization({});
                    let func;
                    if(userType === "3") {
                      func = searchIntermediaryCompany;
                    }else {
                      func = searchCompany;
                    }
                    func({
                      companyName:value,
                      pageNum: 1,
                      pageSize: 10
                    }).then(res=>{
                      if(res.success){
                        callback();
                        setOrganization({
                          'creditCode':res.data.creditCode,
                          'organization':value,
                          'bbdQyxxId':''
                        })
                      }
                      else{
                        callback(<ErrorTip text={res.message} />);
                      }
                    })
                  }
                }
              ]
            })(
              // <AutoComplete
              //   onSelect={onSelect}
              //   onSearch={
              //     function (value) {
              //       setCompanyName(value);
              //     }
              //   }
              //     onBlur={
              //       function () {
              //         funcRef.current(companyName);
              //       }
              //     }
              //   placeholder="请输入关键字选择"
              // >
              //   {dataSource.map(ele => (
              //     <Option
              //       key={ele.bbdQyxxId}
              //       creditcode={ele.creditCode}
              //       bbdqyxxid={ele.bbdQyxxId}
              //     >
              //       {ele.companyName}
              //     </Option>
              //   ))}
              // </AutoComplete>
                <Input
                    type="text"
                    placeholder="请输入关键字选择"
                    autoComplete="off"
                />
            )}
          </Form.Item>
          {userType === '3' && (
            <Form.Item label="执业/从业资格证书编号">
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: <ErrorTip text="输入执业/从业资格证" />
                  }
                ]
              })(<Input type="text" placeholder="输入执业/从业资格证" />)}
            </Form.Item>
          )}
          <Form.Item label="姓名">
            {getFieldDecorator('realName', {
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入真实姓名" />
                },
                {
                  max: 50,
                  message: <ErrorTip text="姓名不能超出50位" />
                },
                {
                  whitespace: true,
                  message: <ErrorTip text="请输入真实姓名" />
                }
              ]
            })(<Input type="text" placeholder="请输入真实姓名" />)}
          </Form.Item>
          <Form.Item label="密码">
            {getFieldDecorator('password', {
              validateFirst: true,
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入密码" />
                },
                {
                  pattern: SpaceCheck.pattern,
                  message: <ErrorTip text={SpaceCheck.message} />
                },
                {
                  pattern: PswCheckSymbol.pattern,
                  message: <ErrorTip text={PswCheckSymbol.message} />
                },
                {
                  pattern: PswCheckLetter.pattern,
                  message: <ErrorTip text={PswCheckLetter.message} />
                },
                {
                  pattern: PswCheckNum.pattern,
                  message: <ErrorTip text={PswCheckNum.message} />
                },
                {
                  min: 6,
                  message: <ErrorTip text="长度至少6位" />
                }
              ]
            })(
              <Input
                type="password"
                placeholder="包含英文（区分大小写）、数字和特殊符号"
                autoComplete="new-password"
              />
            )}
          </Form.Item>
          <Form.Item label="确认密码">
            {getFieldDecorator('confirmPassword', {
              validateFirst: true,
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请再次输入密码" />
                },
                {
                  validator: (rule, value, callback) => {
                    let _password = form.getFieldValue('password');
                    if (value === _password) {
                      callback();
                    } else {
                      callback(<ErrorTip text="输入密码不匹配" />);
                    }
                  }
                }
              ]
            })(<Input type="password" placeholder={'请再次输入密码'} />)}
          </Form.Item>
          <Form.Item label="手机号码">
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入手机号码" />
                },
                {
                  pattern: CellphoneCheck.pattern,
                  message: <ErrorTip text={CellphoneCheck.message} />
                }
              ]
            })(<Input type="text" placeholder="请输入手机号码" />)}
          </Form.Item>
          <Form.Item label="邮箱地址">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入邮箱地址" />
                },
                {
                  pattern: EmailCheck.pattern,
                  message: EmailCheck.message
                }
              ]
            })(<Input type="text" placeholder="请输入邮箱地址" />)}
          </Form.Item>
          <Form.Item label="图片验证码" className="formCaptcha">
            {getFieldDecorator('imgCaptcha', {
              initialValue: ''
            })(
              <VerifyCode
                callBack={tip => setValidateImg(tip)}
                showErrTip={validateImg}
                ref={codeEl}
                template={TEMPLATE}
              />
            )}
          </Form.Item>
          <Form.Item label="短信验证码">
            {getFieldDecorator('smsCode', {
              rules: [
                {
                  required: true,
                  message: <ErrorTip text="请输入验证码" />
                }
              ]
            })(<VerifyPhone handleClick={getCode} ref={phoneEl} />)}
          </Form.Item>
          <Form.Item className="checkItem" wrapperCol={btnLayoutCol}>
            {getFieldDecorator('agreement', {
              valuePropName: 'checked',
              rules: [
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      callback();
                    } else {
                      callback(<ErrorTip text="请勾选北京企业上市综合服务平台用户服务及授权协议" />);
                    }
                  }
                }
              ]
            })(<Checkbox>我已认真阅读并同意北京企业上市综合服务平台</Checkbox>)}
            <Link
              to="/user/protocol"
              className={style.protocol}
              target="_blank"
            >
              《北京企业上市综合服务平台用户服务及授权协议》
            </Link>
          </Form.Item>
          <Form.Item wrapperCol={btnLayoutCol}>
            <Button type="primary" htmlType="submit" className={style.button}>
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const RegisterForm = Form.create()(Register);
export default RegisterForm;
