'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _set = require('babel-runtime/core-js/set');

var _set2 = _interopRequireDefault(_set);

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

exports.delByIds = delByIds;
exports.flodByIds = flodByIds;
exports.getFree = getFree;
exports.getHightLight = getHightLight;
exports.getPathById = getPathById;
exports.getShortPathById = getShortPathById;
exports.getRelationByNodeId = getRelationByNodeId;

var _lodash = require('../lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _grid = require('../structure/grid');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 依赖数据格式：
 * links { from: 'id1', to: 'id2', fromNode: data1, toNode: data2 }
 * nodes { id: 'id', degree: '圈层度数', childs: [] }
 * nodesGroup [{0: [...]}, ...]
 * nodesKV {id1:..., id2:...}
 * linksGroup {formId1-toId2:[...]}
 * linksKV {id:...}
 */

/**
 * @desc 数据处理, 删除ids
 * @param {array} ids id的数组
 * @param {gird} gridData 数据
 * @param {string} type 默认不传，如果type === 'event' 的时候，会另外处理
*/
function delByIds(ids, gridData, centerId, type) {
    if (ids instanceof Array) {
        // ...
    } else {
        console.error('ids 必须是数组');
        return;
    }
    var data = {
        nodes: [],
        links: []
    };
    var nodesKV = gridData.nodesKV;

    var lines = [];
    var events = [];
    ids.forEach(function (d) {
        var iNode = nodesKV[d];
        iNode && iNode.childs.forEach(function (node) {
            lines.push(d + '-' + node.id);
            lines.push(node.id + '-' + d);
            events.push(node);
        });
    });
    events = _lodash2.default.uniq(events);
    lines = _lodash2.default.uniq(lines);

    if (type === 'event') {
        events.forEach(function (d) {
            d.childs.forEach(function (cd) {
                if (d.degree >= cd.degree) {
                    lines.push(d.id + '-' + cd.id);
                    lines.push(cd.id + '-' + d.id);
                }
            });
        });
        lines = _lodash2.default.uniq(lines);
    }

    // 获取游离点
    var exData = getFree(lines, gridData, centerId);
    exData.nodes.forEach(function (d) {
        data.nodes.push(d.id);
    });
    exData.links.forEach(function (d) {
        data.links.push(d.id);
    });

    return data;
}

/**
 * @desc 折叠点的时候，不收起degree小于他自己的
*/
function flodByIds(ids, gridData, centerId) {
    if (ids instanceof Array) {
        // ...
    } else {
        console.error('ids 必须是数组');
        return;
    }
    var data = {
        nodes: [],
        links: []
    };
    var nodesKV = gridData.nodesKV;

    var lines = [];
    ids.forEach(function (d) {
        var iNode = nodesKV[d];
        iNode && iNode.childs.forEach(function (node) {
            if (node.degree > iNode.degree) {
                lines.push(d + '-' + node.id);
                lines.push(node.id + '-' + d);
            }
        });
    });
    lines = _lodash2.default.uniq(lines);

    // 获取游离点
    var exData = getFree(lines, gridData, centerId);
    exData.nodes.forEach(function (d) {
        data.nodes.push(d.id);
    });
    exData.links.forEach(function (d) {
        data.links.push(d.id);
    });

    return data;
}

/**
 * @desc 获取游离点
 * @param {array} lines 去掉的线条
 * @param {grid} gridData 网格数据
 * @param {id} centerId 中心点
*/
function getFree(lines, gridData, centerId) {
    var nodesKV = gridData.nodesKV,
        nodes = gridData.nodes,
        linksGroup = gridData.linksGroup,
        nodesGroup = gridData.nodesGroup;

    // 最大级别

    var maxLevel = _lodash2.default.keys(nodesGroup).length;
    var hasCount = [centerId]; // 记录已经遍历到的数据
    var tNodes = [nodesKV[centerId]];

    var _loop = function _loop(i) {
        var arr = [];
        tNodes.forEach(function (tNode) {
            if (tNode.childs) {
                tNode.childs.forEach(function (node) {
                    if (node.degree > i) {
                        if (lines.indexOf(node.id + '-' + tNode.id) === -1 && lines.indexOf(tNode.id + '-' + node.id) === -1) {
                            hasCount.push(tNode.id);
                            hasCount.push(node.id);
                            arr.push(node);
                        }
                    }
                });
            } else {
                console.error('getFree 数据异常！');
            }
        });
        tNodes = _lodash2.default.uniqBy(arr, 'id');
    };

    for (var i = 0; i < maxLevel; i++) {
        _loop(i);
    }
    hasCount = _lodash2.default.uniq(hasCount); // 去重

    // 计算游离点
    var exNodes = [];
    nodes.forEach(function (d) {
        if (hasCount.indexOf(d.id) === -1) {
            exNodes.push(d);
        }
    });

    // 找出线条
    var lineArr = [];

    var _loop2 = function _loop2(d) {
        d.childs.forEach(function (cd) {
            lineArr.push(d.id + '-' + cd.id);
            lineArr.push(cd.id + '-' + d.id);
        });
    };

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        for (var _iterator = (0, _getIterator3.default)(exNodes), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var d = _step.value;

            _loop2(d);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    lineArr = _lodash2.default.uniq(lineArr);
    var exLinks = [];
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
        for (var _iterator2 = (0, _getIterator3.default)(lineArr), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var id = _step2.value;

            var d = linksGroup[id];
            if (d) {
                exLinks = _lodash2.default.concat(exLinks, d);
            }
        }
    } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
            }
        } finally {
            if (_didIteratorError2) {
                throw _iteratorError2;
            }
        }
    }

    console.log('计算得到游离点：', {
        nodes: exNodes,
        links: exLinks
    });

    return {
        nodes: exNodes,
        links: exLinks
    };
}

/**
 * @desc 获取高亮点
 * @param {string} id 传入高亮的id 
*/
function getHightLight(id, gridData) {
    var nodesKV = gridData.nodesKV,
        linksGroup = gridData.linksGroup;

    var tNode = nodesKV[id];
    var nodes = [tNode];
    var links = [];

    tNode.childs.forEach(function (d) {
        var line1 = linksGroup[tNode.id + '-' + d.id];
        var line2 = linksGroup[d.id + '-' + tNode.id];
        nodes.push(d);
        if (line1) {
            line1.forEach(function (d) {
                links.push(d);
            });
        }
        if (line2) {
            line2.forEach(function (d) {
                links.push(d);
            });
        }
    });
    return { nodes: nodes, links: links };
}

/**
 * 以下是求两点之间路径，以及最短路径的方法
 * *****************************************************************************************************************************
 * @desc 路径查询 sid 默认为圆点，tid是查询的目标点。查询算法原理：设置tid为中心点，然后获取childs下面存在更高级degre的点，
 * 然后设置sid为中心点，同样方法获取点，然后将点求交集。交集即全部路径。然后在全部路径中求最短路径
 * @param string tid 目标id
 * @param string sid 开始id
*/
function getPathById(tid, sid, gridData, originData) {
    var nodesKV = gridData.nodesKV;

    var tNode = nodesKV[tid];
    var sNode = nodesKV[sid];
    if (!tNode) {
        console.error('目标t不存在');
        return false;
    } else if (tNode.degree === null) {
        console.error('目标t为游离点,不存在路径');
        return null;
    }
    if (!sNode) {
        console.error('目标s不存在');
        return false;
    } else if (sNode.degree === null) {
        console.error('目标s为游离点,不存在路径');
        return null;
    }

    // 正向路径
    var pNodes = pathNodes(tid, (0, _grid.stateToGrid)(originData, sid));

    // 逆向路径
    var spNodes = pathNodes(sid, (0, _grid.stateToGrid)(originData, tid));

    // 求出覆盖的id
    var xdata = _lodash2.default.xor(pNodes, spNodes);
    var cdata = _lodash2.default.uniq(_lodash2.default.concat(pNodes, spNodes));
    var ddata = _lodash2.default.difference(cdata, xdata);

    // 获取gridData 里面的 nodes, links 引用地址

    var _ddataToData = ddataToData(ddata, gridData),
        nodes = _ddataToData.nodes,
        links = _ddataToData.links;

    return { nodes: nodes, links: links };
}

/**
 * @desc 获取最短路径
*/
function getShortPathById(tid, sid, gridData, originData) {
    var _getPathById = getPathById(tid, sid, gridData, originData),
        nodes = _getPathById.nodes,
        links = _getPathById.links;
    // 从nodes,links 中找出最短路径。模拟originData


    var newDData = shortPath({ nodes: nodes, links: links }, sid, tid);
    var path = ddataToData(newDData, gridData);
    return {
        nodes: path.nodes,
        links: path.links
    };
}

/**
 * @desc 数据转换, ddata 里面是存放的node的 id，将gridData里面对应的id 取出来，以及对应的线条取出来
*/
function ddataToData(ddata, gridData) {
    var nodesKV = gridData.nodesKV,
        linksGroup = gridData.linksGroup;

    var nodes = [];
    // 路径
    ddata.forEach(function (d) {
        nodes.push(nodesKV[d]);
    });
    var links = [];
    nodes.forEach(function (d) {
        d.childs.forEach(function (cd) {
            if (ddata.indexOf(d.id) !== -1 && ddata.indexOf(cd.id) !== -1) {
                var line1 = linksGroup[d.id + '-' + cd.id];
                var line2 = linksGroup[cd.id + '-' + d.id];
                if (line1) {
                    line1.forEach(function (d) {
                        links.push(d);
                    });
                }
                if (line2) {
                    line2.forEach(function (d) {
                        links.push(d);
                    });
                }
            }
        });
    });
    links = _lodash2.default.uniqBy(links, 'id');
    return { nodes: nodes, links: links };
}

/**
 * @desc 获取最短路径， realData 是 gridData 数据，且与图像关联的数据
*/
function shortPath(realData, sid, tid) {
    var nodes = realData.nodes,
        links = realData.links;

    var oData = {
        nodes: [],
        links: []
    };
    nodes.forEach(function (d) {
        oData.nodes.push({
            id: d.id
        });
    });
    links.forEach(function (d) {
        oData.links.push({
            from: d.from,
            to: d.to
        });
    });
    var pNodes = pathNodes(tid, (0, _grid.stateToGrid)(oData, sid));
    var spNodes = pathNodes(sid, (0, _grid.stateToGrid)(oData, tid));
    // 求出覆盖的id
    var xdata = _lodash2.default.xor(pNodes, spNodes);
    var cdata = _lodash2.default.uniq(_lodash2.default.concat(pNodes, spNodes));
    var ddata = _lodash2.default.difference(cdata, xdata);
    return ddata;
}

/**
 * @desc 路径点
*/
function pathNodes(tid, gridData) {
    var nodesGroup = gridData.nodesGroup,
        nodesKV = gridData.nodesKV;

    var tNode = nodesKV[tid];
    var tDegree = tNode.degree; // 目标的 degree
    var pNodes = []; // 存放 path 的 nodes
    var groupArr = []; // 存放 group 正向

    for (var num in nodesGroup) {
        if (num !== 'null') {
            groupArr.push(nodesGroup[num]);
        }
    }

    // 求正向路径点
    for (var _num = 0; _num < groupArr.length; _num++) {
        var nodes = groupArr[_num];
        nodes.forEach(function (node) {
            var degree = node.degree;
            if (degree < tDegree) {
                var mark = false;
                node.childs.forEach(function (d) {
                    if (d.degree > degree) {
                        mark = true;
                    }
                });
                if (mark) {
                    pNodes.push(node.id);
                }
            }
        });
    }
    pNodes.push(tNode.id);
    return pNodes;
}

function getRelationByNodeId(nodeId, gridData) {
    var degree = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    var _gridData$links = gridData.links,
        links = _gridData$links === undefined ? [] : _gridData$links,
        nodesKV = gridData.nodesKV;

    var relNodes = new _set2.default([nodesKV[nodeId]]);
    var relLinks = new _set2.default();

    links.forEach(function (link) {
        var fromNode = link.fromNode,
            toNode = link.toNode;


        if (fromNode.id === nodeId) {
            relLinks.add(link);
            relNodes.add(toNode);
        } else if (toNode.id === nodeId) {
            relLinks.add(link);
            relNodes.add(fromNode);
        }
    });

    return {
        nodes: [].concat((0, _toConsumableArray3.default)(relNodes)),
        links: [].concat((0, _toConsumableArray3.default)(relLinks))
    };
}