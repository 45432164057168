/**
 * @author Ray
 * @description 验证码的展示与输入组件，配合antd的form表单使用
 */
import { Input } from 'antd';
import classnames from 'classnames';
import ErrorTip from 'common/errorTip';
import React, { forwardRef, useState, useImperativeHandle } from 'react';
import hooks from './hooks';
import { validateKaptcha } from './service';
import styles from './styles.scss';

/**
 *
 * @param callBack
 * @param value
 * @param onChange
 * @param showErrTip
 * @param classes
 * @param template
 * @param ref
 * @returns {*}
 * @constructor
 */
function ImgInput(
    {
        callBack = () => {},
        value,
        onChange,
        showErrTip = false,
        classes = {},
        template = 1
    },
    ref
) {
    const { input } = classes;
    // 图片验证码
    const [
        captcha,
        requestCaptche,
        isChange,
        setIsChange,
        iptVal,
        setIptVal
    ] = hooks(value, template);

    function validateImg() {
        if (iptVal) {
            validateKaptcha({
                imgCaptcha: iptVal,
                template
            }).then(res => {
                callBack(!res.data);
            });
        } else {
            callBack(true);
        }
        setIsChange(false);
    }
    function resetImgCaptcha() {
        callBack(false);
        !isChange && showErrTip && requestCaptche(template);
    }

    function imgClick() {
        setIsChange(true); // 点击图片已经更新，防止获取焦点的时候二次更新
        reset();
    }

    function triggerChange(changedValue) {
        if (onChange) {
            onChange(changedValue);
        }
    }

    function reset() {
        setIptVal(undefined);
        // triggerChange(undefined);
        requestCaptche(template);
    }

    // 把重置输入框和图片的方法暴露给父组件
    useImperativeHandle(ref, () => ({
        reset: reset
    }));

    return (
        <div className={styles.root}>
            <Input
                onChange={({ target: { value: val } }) => {
                    setIptVal(val);
                    triggerChange(val);
                }}
                value={iptVal}
                placeholder="请输入验证码"
                style={{ width: 182 }}
                onFocus={e => resetImgCaptcha(e)}
                onBlur={e => validateImg(e)}
                className={classnames({
                    [input]: Boolean(input)
                })}
            />
            <span
                id="captcha"
                className={styles.code}
                onClick={() => imgClick()}
            >
                <img
                    alt="code"
                    src={captcha}
                    style={{ width: '100%', height: '100%' }}
                />
            </span>
            {showErrTip && iptVal && (
                <div className="ant-form-explain">
                    <ErrorTip text={"验证码错误！"} />
                </div>
            )}
        </div>
    );
}
const VerifyCode = forwardRef(ImgInput);
export default VerifyCode;
