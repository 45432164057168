import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';


//重要节点企业名单
export function getQueryImpIndexCompany(params) {
    return request(`${API.V1}/auth/kcDriver/index/queryImpIndexCompany`,params);
}

//中介机构行政处罚情况(券商)
export function intermediary(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightForth/intermediaryPunishmentList/brokerage`, params)
}

//中介机构行政处罚情况(会所)
export function intermediaryClub(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightForth/intermediaryPunishmentList/accountingFirm`, params)
}

//中介机构行政处罚情况(律所)
export function intermediaryLawFirm(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightForth/intermediaryPunishmentList/lawFirm`, params)
}


