/**
 * @desc 使用用户的权限来限制访问
 */

import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../common/globalStore/createProvider';

export default function useAuth(permissionName) {
    const [isAuth, setIsAuth] = useState(false);
    const gs = useContext(GlobalContext);

    useEffect(() => {
        const pks = gs.user?.reportResources?.map(r => r.name) || [];
        setIsAuth(pks.includes(permissionName));
    }, []);

    return isAuth;
}
