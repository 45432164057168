/**
 * @author Ray
 */

import React, { useState, useEffect } from 'react';
import { Divider, Row } from 'antd';
import Banner from '../../../common/banner';
import Title from '../../../common/title';
import Apply from './apply';
import styles from './styles.scss';
import { ConsultantButton } from '../../../common/buttons';
import { getBanner } from './service.js';

export default function View(props) {
    useEffect(() => {
        props.setBannerShow(true);
    }, []);

    function handleInvestService() {
        props.history.push({
            pathname: '/investment/consultant/require'
        });
    }

    return (
        <div className={styles.root}>
            <Row className="textCenter">
                <ConsultantButton onClick={handleInvestService} />
            </Row>
            <Title level={2} text="我的申请" />
            <Divider />
            <Apply {...props} />
        </div>
    );
}
