import fetch from '../../utils/fetch';
import { API } from '../../constants/enum';

//板块下拉框
export function getShowPlate(params) {
    return fetch(
        `${API.V1}/investbank/intermediary/showPlate`,
        {
            ...params
        },
    );
}