/**
 * @author Ray
 */

import React, { useContext, useState } from 'react';
import { Routes } from '../../router';
import styles from './styles.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { GlobalContext } from '../../../common/globalStore/createProvider';

// 没有children
export function MenuItem({ path, activeItem, name, target }) {
  // 大屏的数据展示页面单开标签页查看
  return (
    <Link to={path} target={target || "_self"}>
      <div
        className={classnames(styles.menuItem, {
          [styles.menuActive]: activeItem === path
        })}
      >
        {name}
      </div>
    </Link>
  );
}

export function SubMenu({ path, name, target }) {
  return  <Link to={path} target={target || "_self"}>
      <div className='customize-sub-menu-item' title={name}>
        {name}
      </div>
    </Link>


}

export default function Menus({ match,routes=[] }) {
  const { user, isEnterprise } = useContext(GlobalContext);
  const [activeItem] = useState(function() {
    let { path } = match;

    // path = path.replace('/', '');

    return path;
  });

  function renderSubMenuContent(subs) {
    return subs.map(sub => {
      return <SubMenu key={sub.name} {...sub} />
    });
  }
  return (
    <div className={styles.menuRoot}>
      {routes.map(function({ name, path, visible, children, target}) {
        return visible ? (
          children ? (
              <Popover
                key={name}
                placement='bottom'
                content={renderSubMenuContent(
                  isEnterprise && path === '/investment'
                    ? [
                        {
                          name: '我的企业',
                          path: `/investment/enterpriseAnalysis/${user?.organization}/${user?.bbdQyxxId}/${user?.creditCode}`
                        },
                        ...children
                      ]
                    : children
                )}
              >
                <Link
                  to={path}
                  target={target || '_self'}
                >
                  <div
                    className={classnames(styles.menuItem, {
                      [styles.menuActive]: activeItem === path
                    })}
                  >
                    {name}
                  </div>
                </Link>
              </Popover>
          ) :
            <MenuItem
              key={name}
              path={path}
              activeItem={activeItem}
              name={name}
            />
        ) : null;
      })}
    </div>
  );
}
