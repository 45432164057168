/* eslint-disable react/no-multi-comp */
/**
 * @author Ray
 */

import React, { useState } from 'react';
import Table, { Column } from '../../../../common/table';
import { ApplyStatus, ApplyProcess } from '../../../../constants/enum';
import moment from 'moment';
import Modal from '../../../../common/modalEx';
import {
    DeleteButton,
    PreviewButton,
    EditButton,
    RejectButton,
    SubmitButton
} from '../../../../common/buttons';
import { limitString } from '../../../../utils/utils';
import { Tooltip } from 'antd';

let defaultInfos = {
    visible: false,
    message: '',
    onOk: () => {},
    onCancel: () => {}
};

export default function({
    totalPage,
    totalCount,
    dataSource = [],
    pageNum,
    pageSize,
    history,
    ...methods
}) {
    const [modalInfo, setModalInfo] = useState(defaultInfos);

    // 查看申请详情
    function checkDetail(record) {
        const { id } = record;

        history.push({
            pathname: `/policy/process/${id}`
        });
    }
    // 编辑申请
    function edit(record) {
        //
        const { id } = record;
        history.push({
            pathname: `/policy/fillin/edit/${id}`
        });
    }
    // 提交申请
    function commit(record) {
        //
        let { companyName, id } = record;

        if (companyName === null) {
            companyName = 'bbd';
        }

        methods.commitSavedApply({
            companyName,
            id
        });
    }
    // 撤回申请
    function withdraw(record) {
        //
        let { companyName, id } = record;

        if (companyName === null) {
            companyName = 'bbd';
        }

        methods.withdrawApply({
            companyName,
            id
        });
    }
    // 删除申请
    function deleteApply(record) {
        const { code, id, stage, status } = record;
        methods.deleteApply({
            code,
            id,
            stage,
            status,
            type: 1
        });
    }

    /**
     * @description 生成操作按钮
     * @param text
     * @param record
     * @returns {*}
     */
    function renderActions(text, record) {
        const { status, applyStage } = record;

        // 申请未提交
        let isUnCommit = status === 1;
        // 申请已提交
        let isCommit = status === 2;
        // 审核中
        let isReviewing = status === 3;
        // 已驳回
        let isReject = status === 4;
        // 已完成
        let isFinished = status === 5;

        return (
            <React.Fragment>
                {(isFinished || isReject || isCommit || isReviewing) && (
                    <PreviewButton onClick={() => checkDetail(record)} />
                )}
                {(isUnCommit || (applyStage === 1 && isReject)) && (
                    <EditButton onClick={() => edit(record)} />
                )}
                {(isUnCommit || (applyStage === 1 && isReject)) && (
                    <SubmitButton
                        onClick={() => {
                            setModalInfo({
                                visible: true,
                                message:
                                    '确定提交该申请吗？提交后申请将不可编辑。',
                                onOk: () => {
                                    commit(record);
                                    setModalInfo(defaultInfos);
                                }
                            });
                        }}
                    />
                )}
                {isUnCommit && (
                    <DeleteButton
                        onClick={() => {
                            setModalInfo({
                                visible: true,
                                message: '确定删除该申请吗？',
                                onOk: () => {
                                    deleteApply(record);
                                    setModalInfo(defaultInfos);
                                }
                            });
                        }}
                    />
                )}
                {(isCommit || (applyStage === 2 && isReviewing)) && (
                    <RejectButton
                        text="撤回"
                        onClick={() => {
                            setModalInfo({
                                visible: true,
                                message: '确定撤回该申请吗？',
                                onOk: () => {
                                    withdraw(record);
                                    setModalInfo(defaultInfos);
                                },
                                onCancel: () => {
                                    setModalInfo(defaultInfos);
                                }
                            });
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Table
                dataSource={dataSource}
                pagination={{
                    total: totalCount,
                    totalPage: totalPage,
                    pageNum: pageNum,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                        methods.getApplyHistory({
                            pageSize,
                            pageNum: page
                        });
                    }
                }}
            >
                <Column
                    title="企业名称"
                    dataIndex="companyName"
                    key="companyName"
                    align="center"
                    render={text => {
                        return text ? (
                            <Tooltip title={text}>
                                {limitString(15, text)}
                            </Tooltip>
                        ) : (
                            '--'
                        );
                    }}
                />
                <Column
                    title="申请时间"
                    dataIndex="applyTime"
                    key="applyTime"
                    align="center"
                    render={text => {
                        return text
                            ? moment(text).format('YYYY-MM-DD HH:mm:ss')
                            : '--';
                    }}
                />
                <Column
                    title="上市阶段"
                    dataIndex="stage"
                    key="stage"
                    align="center"
                />
                <Column
                    title="提交状态"
                    dataIndex="status"
                    key="status"
                    align="center"
                    render={text => {
                        return ApplyStatus[Number(text) - 1];
                    }}
                />
                <Column
                    title="申请进度"
                    dataIndex="applyStage"
                    key="applyStage"
                    align="center"
                    render={text => {
                        return text ? ApplyProcess[Number(text) - 1] : '--';
                    }}
                />
                <Column
                    title="操作"
                    dataIndex="actions"
                    key="actions"
                    align="center"
                    render={renderActions}
                />
            </Table>
            <Modal
                title="提示"
                visible={modalInfo.visible}
                onOk={modalInfo.onOk}
                onClose={() => setModalInfo(defaultInfos)}
                onCancel={() => {
                    setModalInfo({
                        message: '',
                        onOk: () => {},
                        onCancel: () => {},
                        visible: false
                    });
                }}
            >
                {modalInfo.message}
            </Modal>
        </React.Fragment>
    );
}
