/**
 * @author Ray
 */
import React from 'react';
import styles from './styles.scss';
import DetailTabs from './detailTabs';
import { Button } from 'antd';
import Back from '../../../common/backBtn';

export default function(props) {
    const {
        history,
        basicInfo,
        basicApplyMaterial,
        applyMaterials,
        ...rest
    } = props;

    return (
        <div className={styles.processRoot}>
            <Back history={history} url="/policy" />
            <div className={styles.tabsRoot}>
                <DetailTabs
                    data={{
                        basicInfo,
                        basicApplyMaterial,
                        applyMaterials
                    }}
                    {...rest}
                />
            </div>
        </div>
    );
}
