import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc IPO申报明细
 */
export function ipoDeclarationDetailsList(params) {
    return request(`${API.V1}/cockpit/domesticStock/leftSecond/ipoDeclarationDetailsList`, params);
}
//最新上市公司接口
export function lastestIPOCompanyList(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightFirst/lastestIPOCompanyList`, params)
}
