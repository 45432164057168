/**
 * @author hjie
 * 我的学习
 */

import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import {isNumber} from 'utils/utils';
import RiskTitle from 'common/riskTitle';
import { industryTrend, industryList } from '../service';
import ReactEcharts from 'echarts-for-react';
import { Empty, Select } from 'antd';

const { Option } = Select;

export default function(props) {

    const [selectData, setSelect ] = useState([]);
    const [current, setCurrent ] = useState('AAA');
    const [option, setOption ] = useState(null);
    const [loading, setloading ] = useState(true);

    function getList(){
        industryTrend({
            industry: current
        }).then( (res) => {
            if(res && res.success ){
                const xAxis = [], netProfitArr = [], operatingIncomeArr = [], totalAssetsArr = [];

                res.data.forEach(element => {
                    const { netProfit, operatingIncome, totalAssets, year } = element;
                    xAxis.push( year);
                    netProfitArr.push(netProfit);
                    operatingIncomeArr.push(operatingIncome );
                    totalAssetsArr.push( totalAssets);
                });

                const series = [
                    {
                        type: 'line',
                        name: '营业收入',
                        data:  operatingIncomeArr
                    },
                    {
                        type: 'line',
                        name: '资产规模',
                        data:  totalAssetsArr
                    },
                    {
                        type: 'line',
                        name: '净利润',
                        data: netProfitArr
                    }
                ]; 
                let options = getOption(xAxis, series);
                setOption(options);
            }else{
                setOption(null);
            }
        }).finally(() => {
            setloading(false);
        })
    }

    function getOption(xAxis, series) {
        const options = {
            legend: {
                show: true,
                top: '20',
                data: series.map(ele => ele.name),
                itemGap: 30
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                padding: 0,
                formatter: (v) => {
                    let str = '';
                    v.forEach(ele => {
                        str += `<p class= "title">${ele.seriesName} : <span style = "color:${ele.color}">${formatterNum(ele.value, 2)}</span></p>`
                    });
                    return `<div class = "tooltipBox">
                                ${str}
                            </div>`
                    // return 
                }
            },
            grid: {
                top: '60',
                left: '8%',
                right: '5%',
            },
            xAxis: {
                type: 'category',
                data: xAxis,

            },
            yAxis: [{
                type: 'value',
                axisLabel: {
                    formatter: (v) => formatterNum(v)
                }
            }],
            series
        };
        return options;
    } 

    function getIndusty(){
        industryList().then( (res) => {
            if(res && res.success){
                setSelect(res.data);
            }
        })
    }

    // 格式化数值
    function formatterNum(num, decimal = 0) {
        let nums = +num, forNum = '';
        if(!isNumber(nums)){
            return 0;
        }
        let absNum = Math.abs(nums);
        if(absNum >= 100000000 ) {
            forNum =  (absNum / 100000000).toFixed(decimal) + '亿';
        }else if(absNum < 100000000 && absNum >= 10000){
            forNum =  (absNum / 10000).toFixed(decimal) + '万';
        }else{
            forNum = absNum.toFixed(decimal);
        }
        return nums < 0 ? `-${forNum}` : forNum;
    }

    useEffect(() => {
        getList();
    }, [current])

    useEffect(() => {
        getIndusty();
    }, [])

    return (
        <div className = {styles.industryAnalysis}>
            <RiskTitle>产业分析</RiskTitle>
            <div className = "box">
                <div className = "clearfix">
                    <label className = "label">行业选择</label>
                    <Select 
                        defaultValue="AAA" 
                        style={{ width: 160, verticalAlign: 'middle' }} 
                        onChange={(value) => setCurrent(value)}
                        getPopupContainer={() => document.getElementById('riskIndustry')} 
                        id = "riskIndustry"
                    >
                        <Option value = 'AAA'>行业选择</Option>
                        {
                            selectData.map( ele => {
                                const { id, industry, industryName } = ele;
                                return <Option key = {id} value = {industry}>{industryName}</Option>
                            })
                        }
                    </Select>
                </div>
                {
                    loading ?  '' :
                        option ?
                        <ReactEcharts
                            option={option}
                            notMerge={true}
                            style= {{ height: 380 }}
                            theme={ 'myTheme'}
                        /> :  <Empty />
                }
            </div>
        </div>
    );
}
