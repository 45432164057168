import { useState, useEffect, useRef } from 'react';
import { throttle } from 'utils/utils';

export default function(menu){

    const [styleFixed, setStyle] = useState(false);
    const [index, setInex] = useState(0);
    const funcRef = useRef(() => {});

    function scroll(){
        if(menu){
            getOffest();
            window.onmousewheel = function(e){
                let scrolltop = document.documentElement.scrollTop || document.body.scrollTop || document.getElementById('root').scrollTop;
                funcRef.current(scrolltop); 
            }
        } 
    }

    // 获取每一个dom的offsetTop
    const getOffest = () =>{
        menu.forEach((ele)=> {
            let value = document.getElementById(ele.domId).offsetTop;
            ele['offsetTop'] = value;
        });
    }

    function getIndex(top){
        let indexCur = 0, len = menu.length - 1;
        if(top > menu[len].offsetTop){
            indexCur = len;
        }else{
            for(let i = 0; i < len; i++){
                const { offsetTop } = menu[i];
                if(top >= offsetTop && top < menu[i + 1].offsetTop){
                    indexCur = i;
                    break;
                }
            }
        }
        if( top > 420){
            setStyle(true);
        }else{
            setStyle(false);
        }
        setInex(indexCur);
    }

    const clickIndex = (i) =>{
        setInex(i);
        document.getElementById('root').scrollTop = menu[i].offsetTop + 80;
    }

    useEffect(() => {
        scroll();
        funcRef.current = throttle(v => getIndex(v), 500, this);
    },[menu])

    return {styleFixed, index, setInex, clickIndex};
}