import './style.scss';

import echarts from 'echarts';
import { myTheme } from './theme';
echarts.registerTheme('myTheme', myTheme);

export { default as Line } from './options/Line';
export { default as Bar } from './options/Bar';
export { default as Pie } from './options/Pie';
export { default as Map } from './options/Map';
export { default as Gauge } from './options/Gauge';
export { default as Radar } from './options/Radar';
export { default as Donut } from './options/Donut';
export { default as GroupBar } from './options/GroupBar';
export { default as BarStack } from './options/BarStack';
