/**
 * @author Ray
 * @desc 标的企业股权结构
 */

import React, { useEffect, useState } from 'react';
import columns from './columns';
import styles from './styles.scss';
import Table from '../../../common/table';
import {
  EditableCell,
  EditableRow
} from '../../userCenter/modifyExpert/projcets/view';
import { Column } from '../../../common/table';
import { isNotEmpty, replace } from '../../../utils/utils';

Structure.defaultProps = {
  onChange: () => {}
};

export default function Structure({ dataSource, onChange, isPreview }) {
  const [source, setSource] = useState(function() {
    return Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10).map(function(item, i) {
      return {
        index: item,
        shareholderName: undefined,
        ownRatio: undefined
      };
    });
  });

  function handleFieldChange(item) {
    let _source = replace(source, item, t => t.index === item.index);
    setSource(_source);
    onChange({
      mergeEquity: [..._source]
    });
  }

  useEffect(
    function() {
      if (isNotEmpty(dataSource)) {
        setSource(dataSource);
      }
    },
    [dataSource]
  );

  /**
   * @desc 回显十条股东数据，
   */
  return (
    <div className={styles.structure}>
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell
          }
        }}
        pagination={false}
        dataSource={source}
      >
        {columns &&
          columns.map(c => {
            return (
              <Column
                {...c}
                key={c.dataIndex}
                onCell={(record, index) => ({
                  index,
                  record,
                  isPreview,
                  type: c.type,
                  editable: c.editable,
                  dataIndex: c.dataIndex,
                  onChange: handleFieldChange
                })}
              />
            );
          })}
      </Table>
    </div>
  );
}
