import { Card, Col, Divider, Row, Tag, Typography, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Pagination from '../../../common/list/pagination';
import WithLabel from '../../../common/withLabel';
import styles from '../expertIndex/styles.scss';
import { queryListExpertTeamByCondition } from '../service';

export default function GroupList({ history, params }) {
  const [data, setData] = useState({
    teamList: []
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 9,
    total: 10
  });
  useEffect(() => {
    load();
  }, [params]);

  function load(page = {}) {
    async function fetchData() {
      queryListExpertTeamByCondition({
        ...params,
        pageNum: page.pageNum || 1,
        pageSize: 9
      }).then(res => {
        if (res.statusCode == 1000) {
          const data = res.data || {};
          setData({ teamList: data.data || [] });
          setPagination({
            pageNum: data.pageNum,
            pageSize: 9,
            total: data.totalCount,
            totalPage: data.pageCount
          });
        } else {
          setData({ teamList: [] });
          setPagination({
            pageNum: 1,
            pageSize: 9,
            total: 0,
            totalPage: 0
          });
          message.info(res.message);
        }
      });
    }
    fetchData();
  }

  return (
    <div>
      <Row gutter={16} className={styles.group}>
        {data.teamList.map((team, index) => (
          <Col
            className='clearfix'
            span={24}
            style={{ marginTop: 16 }}
            key={index}
          >
            <Card
              hoverable
              bordered={false}
              style={{ height: 200 }}
              onClick={() => {
                history.push({
                  pathname: `/investment/expertGroup/${team.expertTeam}`,
                  state: team
                });
              }}
            >
              <Row>
                <Col span={24}>
                  <WithLabel text='团队成员:'>
                    <Typography.Text>{team.expertTeam}</Typography.Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Tag color='#2082ED'>{team.expertFirm}</Tag>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography.Text>{team.zjtdInInstitude}</Typography.Text>
                  </WithLabel>
                </Col>
                <Col span={24}>
                  <WithLabel text='主要服务领域:'>
                    <Typography.Text>
                      {team.zjtdMainServiceIndustry}
                    </Typography.Text>
                  </WithLabel>
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  <WithLabel text='主要服务板块:'>
                    <Typography.Text>
                      {team.zjtdMainServiceBlock}
                    </Typography.Text>
                  </WithLabel>
                </Col>
                <Divider />
                <Row>
                  <Col span={24}>
                    <WithLabel text='最近项目:'>{team.company}</WithLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    <WithLabel text='所属行业:'>{team.industryName}</WithLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    <WithLabel text='上市板块:'>{team.location}</WithLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    <WithLabel text='上市时间:'>
                      {team.ipoDate
                        ? moment(team.ipoDate).format('YYYY-MM-DD')
                        : '--'}
                    </WithLabel>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination {...pagination} onChange={load} showSizeChanger={false} />
    </div>
  );
}
