import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

// 再融资情况，两个饼状图，第一个按行业
export function refinancingPieIndustry(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightForth/refinancingPie/industry`, params);
}

// 再融资情况，两个饼状图，第二个按融资工具
export function refinancingPieTools(params) {
    return request(`${API.V1}/cockpit/domesticStock/rightForth/refinancingPie/financingTools`, params);
}