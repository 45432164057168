'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.mergeOriginData = mergeOriginData;

var _lodash = require('../lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @desc 合并 originData 删除，或者添加， 删除的时候 nodes,links 传入id
 */
function mergeOriginData(originData, newData, type) {

    if (!newData) {
        return originData;
    }

    // 过滤数据
    newData.nodes.forEach(function (d) {
        delete d.childs;
    });

    // console.log('更新originData');
    // console.time('reOriginData');
    var data = null;
    if (type === 'add') {

        // 添加数据
        var nodes = originData.nodes.concat(newData.nodes);
        var links = originData.links.concat(newData.links);

        // 去重
        nodes = _lodash2.default.uniqBy(nodes, 'id');
        links = _lodash2.default.uniqBy(links, 'id');

        data = { nodes: nodes, links: links };
    } else if (type === 'del') {
        var _nodes = originData.nodes,
            _links = originData.links;

        _nodes.forEach(function (node) {
            if (_lodash2.default.findIndex(newData.nodes, function (d) {
                return d === node.id;
            }) !== -1) {
                node.remove = true;
            }
        });
        // nodes = _.compact(nodes);
        var nNodes = [];
        _nodes.forEach(function (node) {
            if (node.remove === undefined) {
                nNodes.push(node);
            }
        });

        _links.forEach(function (link) {
            // if (_.findIndex(newData.links, d => { return (d.startId + '-' + d.endId + '-' + d.properties.bbdRoleId) === (link.startId + '-' + link.endId + '-' + link.properties.bbdRoleId); }) !== -1) {
            if (_lodash2.default.findIndex(newData.links, function (d) {
                return d === link.id;
            }) !== -1) {
                link.remove = true;
            }
        });
        // links = _.compact(links);
        var nLinks = [];
        _links.forEach(function (link) {
            if (link.remove === undefined) {
                nLinks.push(link);
            }
        });

        data = { nodes: nNodes, links: nLinks };
    } else {
        // ...
        console.log('更新操作...');
        var _nodes2 = originData.nodes,
            _links2 = originData.links;

        _nodes2.forEach(function (node, i) {
            var index = _lodash2.default.findIndex(newData.nodes, function (d) {
                return d === node.id;
            });
            if (index !== -1) {
                _nodes2[i] = newData.nodes[index];
            }
        });

        _links2.forEach(function (link, i) {
            var index = _lodash2.default.findIndex(newData.links, function (d) {
                return d === link.id;
            });
            if (index !== -1) {
                _links2[i] = newData.links[index];
            }
        });

        data = { nodes: _nodes2, links: _links2 };
    }
    // console.log('合并后的数据 ', data);
    // console.timeEnd('reOriginData');
    return data;
}