/**
 * @author Ray
 * @desc 主营信息
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import InputTable, { parser } from '../../common/inputTable';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import { getOperatingData } from '../service';
import useStorage from '../../../../hooks/useStorage';
import { SaveButton } from '../../../../common/buttons';
import deepEqual from 'fast-deep-equal';
import { message } from 'antd';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

const Rows = [
    { dataIndex: 'firstName', title: '收入第一名的产品名称' },
    { dataIndex: 'firstValue', title: '收入第一名的产品营收(万元)' },
    { dataIndex: 'secondName', title: '收入第二名的产品名称' },
    { dataIndex: 'secondValue', title: '收入第二名的产品营收(万元)' },
    { dataIndex: 'thirdName', title: '收入第三名的产品名称' },
    { dataIndex: 'thirdValue', title: '收入第三名的产品营收(万元)' },
    { dataIndex: 'fourthName', title: '收入第四名的产品名称' },
    { dataIndex: 'fourthValue', title: '收入第四名的产品营收(万元)' },
    { dataIndex: 'fifthName', title: '收入第五名的产品名称' },
    { dataIndex: 'fifthValue', title: '收入第五名的产品营收(万元)' }
];

export const Columns = [
    { title: '报告年度' },
    { dataIndex: 'year', title: 'year' },
    { dataIndex: 'year', title: 'year' },
    { dataIndex: 'year', title: 'year' }
];

export default function MainIndustry({
    onChange = () => {},
    onInit = () => {},
    isPreview = false,
    onSave = () => {},
    trigger = () => {},
    activeKey,
    realKey,
    reloadFlag
}) {
    const [dataCopy, setDataCopy] = useState(undefined);
    const [data, setData] = useState(undefined);
    const ref = useRef(null);
    const [isNew, setIsNew] = useState(true);
    // const [user] = useStorage('UserInfo');
    const { user } = useContext(GlobalContext);
    function handleInit(instance) {
        onInit(instance);
        ref.current = instance;
    }

    useEffect(() => {
        reloadFlag &&
            user?.creditCode &&
            getOperatingData(user?.creditCode, 1).then(res => {
                if (isNotEmpty(res) && res.success) {
                    if (isNotEmpty(res.data)) {
                        setIsNew(false);
                        let parse =
                            res?.data?.map((data = {}) => {
                                const {
                                    year,
                                    firstName,
                                    firstValue,
                                    secondName,
                                    secondValue,
                                    thirdName,
                                    thirdValue,
                                    fourthName,
                                    fourthValue,
                                    fifthName,
                                    fifthValue
                                } = data;
                                return {
                                    year,
                                    firstName,
                                    firstValue,
                                    secondName,
                                    secondValue,
                                    thirdName,
                                    thirdValue,
                                    fourthName,
                                    fourthValue,
                                    fifthName,
                                    fifthValue
                                };
                            }) || [];
                        const data = parser(parse);
                        // 保存一份接口返回的数据，用于和修改的数据作比较，一旦保存过后，更新备份数据为保存的数据
                        setDataCopy(data);
                        setData(data);
                        onChange(data);
                        setTimeout(
                            () => ref.current?.setFieldsValue({ ...data }),
                            0
                        );
                    } else {
                        setIsNew(true);
                        setTimeout(() => ref.current?.resetFields(), 0);
                    }
                }
            });
    }, [reloadFlag]);

    function result(isUpdated) {
        if (isUpdated) setDataCopy(parser(data));
    }

    useEffect(() => {
        let parsedData = parser(data);
        let isModified = !checkEqual(dataCopy, parsedData);
        if (activeKey === realKey || (isEmpty(dataCopy) && isEmpty(data)))
            isModified = false;
        trigger(isModified, activeKey, result, isNew);
    }, [activeKey]);

    function handleChange(data) {
        // const years = data.map(value => value.year);
        // if(unique(years).length != years.length){
        //     message.info('所选年份已存在');
        //     return
        // }
        setData(data);
        onChange(data);
        // function unique(arr) {
        //     if (!Array.isArray(arr)) {
        //         console.log('type error!')
        //         return
        //     }
        //     return [...new Set(arr)]
        // }
    }

    function handleSave() {
        setDataCopy(parser(data));
        onSave(isNotEmpty(dataCopy));
    }

    function checkEqual(a, b) {
        return deepEqual(a, b);
    }

    return (
        <>
            <InputTable
                isPreview={isPreview}
                rows={Rows}
                columns={Columns}
                onChange={handleChange}
                onInit={handleInit}
            />
            {!isPreview && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </>
    );
}
