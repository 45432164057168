import React, { useEffect, useState } from 'react';
import { Pie, Donut } from '../../../../../echarts';
import { Col, Row } from 'antd';
import Title from '../../../../../common/title';
import { Selector } from '../../../../merging/home/apply';
import styles from './styles.scss';
import { debtStructure, getDebtYears } from './service';
import {
    // generateYearRangeOptions,
    isEmpty,
    isNotEmpty,
    moneyFormatter
} from '../../../../../utils/utils';
// import moment from 'moment';

export default function AssetsStructure({ match }) {
    const [yearOptions, setYearOptions] = useState([]);
    const [year, setYear] = useState(undefined);
    const [data, setData] = useState([]);

    useEffect(() => {
        getDebtYears({ creditCode: match.params.companyCode }).then(res => {
            if (!res.data) {
                res.data = [];
            }
            setYearOptions(res.data);
            setYear(res.data[0]);
        });
    }, []);

    useEffect(() => {
        year &&
            debtStructure({ code: match.params.companyCode, year }).then(
                res => {
                    if (isNotEmpty(res) && isNotEmpty(res.data)) {
                        const { debtList, profitList } = res.data;
                        setData([debtList, profitList]);
                    }
                }
            );
    }, [year]);

    return (
        <>
            <Title level={3} text="资产负债构成">
                <Selector
                    value={year}
                    options={yearOptions}
                    className={styles.selector}
                    onChange={year => setYear(year)}
                />
            </Title>
            <Row>
                <Col span={12}>
                    <Donut
                        nodata={isEmpty(data[1])}
                        data={data[1] || []}
                        style={{ height: 250 }}
                        formatter={params => {
                            const { name, value, percent } = params;
                            return (
                                name +
                                ': ' +
                                (value ? moneyFormatter(value) + '万' : value) +
                                '(' +
                                percent +
                                '%)'
                            );
                        }}
                        title={{
                            text: '资产构成',
                            y: '48%',
                            x: '44%',
                            textStyle: {
                                fontSize: 16
                            }
                        }}
                    />
                </Col>
                <Col span={12}>
                    <Donut
                        nodata={isEmpty(data[0])}
                        data={data[0] || []}
                        style={{ height: 250 }}
                        formatter={params => {
                            const { name, value, percent } = params;
                            return (
                                name +
                                ': ' +
                                (value ? moneyFormatter(value) + '万' : value) +
                                '(' +
                                percent +
                                '%)'
                            );
                        }} //{`{b}: {c}万({d}%)`}
                        title={{
                            text: '负债构成',
                            y: '48%',
                            x: '44%',
                            textStyle: {
                                fontSize: 16
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}
