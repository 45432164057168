/**
 * @author Ray
 * @desc 基础信息-财务概况-财务风险评估
 */

import React from 'react';
import { Radar, Gauge } from '../../../../../echarts';
import { Col, Icon, Row, Typography } from 'antd';
import Title from '../../../../../common/title';

export default function RiskAssessment({ data }) {
    return (
        <>
            <Title level={3} text="财务风险评估值">
                {/*<Icon*/}
                {/*    type="exclamation-circle"*/}
                {/*    theme="twoTone"*/}
                {/*    twoToneColor="#f5222d"*/}
                {/*/>*/}
                {/*&nbsp;*/}
                {/*<Typography.Text type="danger">*/}
                {/*    说明：该公司在营运能力、偿债能力、发展能力、盈利能力、现金流能力方面存在显著风险*/}
                {/*</Typography.Text>*/}
            </Title>
            <Row>
                <Col span={12}>
                    <Gauge
                        data={[
                            {
                                value: data.totalRiskScore
                                    ? data.totalRiskScore.toFixed(2)
                                    : 0,
                                name: '风险总分'
                            }
                        ]}
                    />
                    {data.riskMessage && <p style={{ textAlign: 'center' }}>
                        <Icon type="exclamation-circle" style={{color: '#2082ed'}} theme="filled" />
                        &nbsp;说明：{data.riskMessage}
                    </p>}
                </Col>
                <Col span={12}>
                    <Radar
                        indicator={[
                            { text: '现金流', max: 100 },
                            { text: '盈利', max: 100 },
                            { text: '营运', max: 100 },
                            { text: '偿债', max: 100 },
                            { text: '发展', max: 100 }
                        ]}
                        data={[
                            data.xianjinliuRiskScore || 0,
                            data.yingliRiskScore || 0,
                            data.yingyunRiskScore || 0,
                            data.changzhaiRiskScore || 0,
                            data.fazhanRiskScore || 0
                        ]}
                        style={{ height: 260 }}
                    />
                </Col>
            </Row>
        </>
    );
}
