/**
 * @author Ray
 * @desc 研发能力情况
 */

import React, { useEffect, useRef, useState } from 'react';
import { Form, Tooltip, Icon } from 'antd';
import { Selector } from '../../merging/home/apply';
import {
  IndustryStage,
  CompanyStatus,
  TechAdvantage,
  CoreTeamArgs
} from '../../../constants/enum';
import { SaveButton } from '../../../common/buttons';
import { GreaterThanZro } from '../../../utils/validator';
import { showModal, closeModal } from '../../investment/script/actions';
const MESSAGE1 = '请企业衡量、判断自身所处行业的行业阶段。';
const MESSAGE2 = '仅判断企业国内行业地位。';
const MESSAGE3 =
  '核心经营团队为公司董、监、高人员，技术团队为公司技术负责人及技术骨干。';

const FormEle = React.forwardRef(({ form, data }, ref) => {
  React.useImperativeHandle(ref, () => ({ form }));
  data = data || {};
  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 10 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 10 } }}
    >
      <Form.Item
        label={
          <span>
            行业所处阶段&nbsp;
            <Tooltip placement='right' title={MESSAGE1}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('industryStage', {
          rules: [GreaterThanZro],
          initialValue: data.industryStage
        })(<Selector options={IndustryStage} getPopupContainer={()=>document.getElementById('competition')} />)}
      </Form.Item>
      <Form.Item
        label={
          <span>
            公司所处行业地位&nbsp;
            <Tooltip placement='right' title={MESSAGE2}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('companyIndustyPosition', {
          rules: [],
          initialValue: data.companyIndustyPosition
        })(<Selector options={CompanyStatus} getPopupContainer={()=>document.getElementById('competition')}  />)}
      </Form.Item>
      <Form.Item label='技术优势及可持续性'>
        {form.getFieldDecorator('techAdvantageSustain', {
          rules: [],
          initialValue: data.techAdvantageSustain
        })(<Selector options={TechAdvantage} getPopupContainer={()=>document.getElementById('competition')}  />)}
      </Form.Item>
      <Form.Item
        label={
          <span>
            核心经营团队和技术团队竞争力&nbsp;
            <Tooltip placement='right' title={MESSAGE3}>
              <Icon
                theme='filled'
                className='blueButton'
                type='question-circle'
              />
            </Tooltip>
          </span>
        }
      >
        {form.getFieldDecorator('coreTechTeamCondition', {
          rules: [],
          initialValue: data.coreTechTeamCondition
        })(<Selector options={CoreTeamArgs} getPopupContainer={()=>document.getElementById('competition')} />)}
      </Form.Item>
    </Form>
  );
});
export const FormElement = ({ formRef, data }) => {
  let [Element, setElement] = useState(null);

  useEffect(
    function() {
      let E = Form.create({
        name: 'core',
        onValuesChange: () => {}
      })(FormEle);

      setElement(<E wrappedComponentRef={formRef} data={data} />);
    },
    [data]
  );

  return Element;
};
let currentKey = 3;
export default function Competition(props) {
  const formRef = useRef(null);

  const save = () => {
    formRef.current.form.validateFields((errors, values) => {
      if (!errors) {
        props.saveFun(values);
      }
    });
  };

  useEffect(
    function() {
      if (props.activeKey != currentKey && currentKey == 3) {
        currentKey = props.activeKey;
        const currentValue = formRef.current.form.getFieldsValue();
        const hasChanged = Object.keys(currentValue).filter(function(
          value,
          index
        ) {
          return currentValue[value] !== props.data[value];
        });
        if (hasChanged.length) {
          props.dispatch(
            showModal({
              title: '相对竞争优势情况',
              cancelText: '不保存！',
              okText: '保存！',
              onOk: () => {
                props.dispatch(closeModal());
                formRef.current.form.validateFields((errors, values) => {
                  props.saveFun(values, function() {
                    hasChanged.forEach(value => {
                      formRef.current.form.setFieldsValue({
                        [value]: props.data[value]
                      });
                      props.dispatch(closeModal());
                    });
                  });
                });
              },
              onClose: () => {
                // 不保存恢复原样
                hasChanged.forEach(value => {
                  formRef.current.form.setFieldsValue({
                    [value]: props.data[value]
                  });
                  props.dispatch(closeModal());
                });
              }
            })
          );
        }
      }
      if (props.activeKey == 3) {
        currentKey = 3;
      }
    },
    [props.activeKey, props.data]
  );

  return (
    <div id="competition">
      <FormElement formRef={formRef} data={props.data} />
      <div className='textCenter marginTop'>
        <SaveButton onClick={save} />
      </div>
    </div>
  );
}
