/**
 * @author Ray
 * @desc 入库企业分析-基础信息-企业资质
 */

import React, { useContext, useState } from 'react';
import Title from '../../../../../common/title';
import Qualifications from './qualifications';
import { GlobalContext } from '../../../../../common/globalStore/createProvider';
import { Empty } from 'antd';

export default function CorporateQualifications({ match }) {
    const gs = useContext(GlobalContext);
    const [isOwnedCompany] = useState(
        match.params.companyName === gs.user?.organization
    );
    return (
        <div>
            {((gs.isAdvanceUser || gs.isVIPUser) && isOwnedCompany) ||
            (gs.isIntermediary && gs.isVIPUser) ||
            gs.isGOV ||
            gs.isQiYuan ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                <>
                    <Title level={3} text="企业资质" />
                    <Qualifications match={match} />
                </>
            ) : (
                <Empty description={'请升级会员查看'} />
            )}
        </div>
    );
}
