import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
/**
 * @desc  最新上市公司情况
*/
//换手率接口
export function turnoverRate() {
    return request(`${API.V1}/cockpit/domesticStock/rightFirst/turnoverRate`)
}
//成交额接口
export function turnover() {
    return request(`${API.V1}/cockpit/domesticStock/rightFirst/turnover`)
}
