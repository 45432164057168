import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styles from './styles.scss';
import useAuth from '../../hooks/useAuth';
import { DONT_NEED_LOGIN } from '../router';
import { makeRegex } from 'utils/utils';
import classnames from 'classnames';

export default function (props) {
    const { Routes, match } = props;
    let isAuth = '';

    let dontNeedLogin = false;
    // 未登录即可以访问的页面（登录，注册，申述，忘记密码
    // console.log("当前url", props.location.pathname);
    for (let url of DONT_NEED_LOGIN) {
        console.log(url)
        if (url === props.location.pathname || makeRegex(url).test(props.location.pathname)) {
            dontNeedLogin = true
            break;
        }
    }

    if (dontNeedLogin) {
        isAuth = true;
    } else {
        isAuth = useAuth(43);
    }
    return (
        <div className={styles.listingTraining}>
            {isAuth && (
                <Switch>
                    {Routes.map(o => (
                        <Route
                            key={o.path}
                            exact={o.exact}
                            path={`${match.url}${o.path}`}
                            render={prop => {
                                return <div className={classnames({ [styles.content]: !o.noContent })}>
                                    <o.component {...props} {...prop} />
                                </div>
                            }
                            }
                        />
                    ))}
                    <Redirect from={match.url} to={props.route.redirect} />
                </Switch>
            )}
        </div>
    );
}
