import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc  失信被执行人
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 * @param companyName
 */
export function getLostTrustee({ qyxxId, pageNum, pageSize, companyName }) {
    return request(`${API.V1}/investbank/company/sxbzxr`, {
        qyxxId,
        pageNum,
        pageSize,
        companyName
    });
}

/**
 * @desc   开庭公告
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function publicNotice({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/ktgg`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  经营异常
 * @param qyxxId
 */
export function abnormalOperation({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/jyyc`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  行政处罚
 * @param qyxxId
 */
export function adminPenalties({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/xzcf`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  法院公告
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function courtAnnounce({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/fygg`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  被执行人信息
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function lostTrusteeInfo({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/legalperson`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc  裁判文书
 * @param qyxxId
 * @param pageNum
 * @param pageSize
 */
export function judgePaper({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/cpws`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc 司法险分析
 * @param qyxxId
 */
export function judicial(qyxxId) {
    return request(`${API.V1}/investbank/company/judicial`, {
        qyxxId
    });
}
