import React, { useEffect, useState } from 'react';
import { GroupBar } from '../../../../../echarts';
import Title from '../../../../../common/title';
import { operationStructure } from './service';
import { moneyFormatter, isEmpty, isNotEmpty } from '../../../../../utils/utils';

export default function Structure({ match, isIpoCompany }) {
    const [xAxis, setAxis] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        operationStructure({ creditCode: match.params.companyCode }).then(
            res => {
                const { data } = res;
                const xAxisData = data ? data.map(d => d.year) : [];
                const first = [];
                const second = [];
                const third = [];
                const fourth = [];
                const fifth = [];
                data &&
                    data.forEach(item => {
                        first.push({
                            name: item.firstName,
                            value: item.firstValue || 0
                        });
                        second.push({
                            name: item.secondName,
                            value: item.secondValue || 0
                        });
                        third.push({
                            name: item.thirdName,
                            value: item.thirdValue || 0
                        });
                        fourth.push({
                            name: item.fourthName,
                            value: item.fourthValue || 0
                        });
                        fifth.push({
                            name: item.fifthName,
                            value: item.fifthValue || 0
                        });
                    });

                setAxis(
                    isNotEmpty(xAxisData)
                        ? xAxisData.map(year => year + '年')
                        : xAxisData
                );
                setData([first, second, third, fourth, fifth]);
            }
        );
    }, []);

    function getBar(data) {
        return [
            {
                type: 'bar',
                barGap: '200%',
                itemStyle: { color: '#2082ED' },
                data: data[0] || []
            },
            {
                type: 'bar',
                barGap: '200%',
                itemStyle: { color: '#59DF8F' },
                data: data[1] || []
            },
            {
                type: 'bar',
                barGap: '200%',
                itemStyle: { color: '#FB7B39' },
                data: data[2] || []
            },
            {
                type: 'bar',
                barGap: '200%',
                itemStyle: { color: '#004591' },
                data: data[3] || []
            },
            {
                type: 'bar',
                barGap: '200%',
                itemStyle: { color: '#0800FF' },
                data: data[4] || []
            }
        ];
    }

    return (
        !isIpoCompany && (
            <>
                <Title level={3} text="营业收入结构" />
                <GroupBar
                    xAxisData={xAxis}
                    series={getBar(data)}
                    nodata={
                        isEmpty(data[0]) &&
                        isEmpty(data[1]) &&
                        isEmpty(data[2]) &&
                        isEmpty(data[3]) &&
                        isEmpty(data[4])
                    }
                    formatter={params => {
                        return `<p>
                                ${params
                                    .map(param => {
                                        return (
                                            param.data.name &&
                                            `<p>
                                               ${param.marker +
                                                   ' ' +
                                                   param.data.name +
                                                   ': ' +
                                                   moneyFormatter(param.data.value) +
                                                   '万'}
                                           </p>`
                                        );
                                    })
                                    .join('')}
                            </p>`;
                    }}
                />
            </>
        )
    );
}
