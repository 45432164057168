import styles from './styles.scss';
import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import Radios from '../listOfEnterprises/radio';
import { getData } from './service';
import { isNotEmpty, LimitNumber } from '../../../utils/utils';
import { Empty } from 'antd';

const radiosData = [
  { name: '地区TOP5', value: 1 },
  { name: '行业TOP5', value: 2 },
  { name: '保荐TOP5', value: 3 }
];

/**
 * @desc 行业内优质企业分布情况
 * @returns {*}
 * @constructor
 */
const color = ['#0CD64A', '#00DBFF', '#61A5E8', '#F87E12', '#D4614B'];
export default function Top5({ style = {}, address }) {
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);

  function getTop(type) {
    setData([]);
    getData({
      area: address === '全国' ? '所有地区' : address,
      type
    }).then(res => {
      if (isNotEmpty(res?.data)) {
        setData(
          res.data.map(({ name, num }) => ({
            name,
            value: num
          }))
        );
      }
    });
  }

  function getOptions() {
    return {
      tooltip: {
        show: true,
        trigger: 'axis',
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        axisPointer: {
          type: 'shadow'
        },
        formatter: v => {
          const { name, value } = v[0];
          return `<div class = "bgPageTooltip">
                                <p class = "title">${name}</p> 
                                <p><b class = "num">${value}</b> 家企业</p>     
                            </div>`;
        }
      },
      grid: {
        top: 8,
        left: 90,
        right: 40
        // bottom: 8
      },
      xAxis: [
        {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(86,197,253,0.3)'
            }
          }
        }
      ],
      yAxis: {
        data: data.map(item => item.name).reverse(),
        type: 'category',
        axisLabel: {
          color: '#fff',
          formatter: value => {
            return LimitNumber(value, 5);
          }
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: [
        {
          type: 'bar',
          data: data.reverse(),
          barMaxWidth: 16,
          itemStyle: {
            barBorderRadius: 0
          },
          label: {
            show: true,
            position: 'right',
            color: '#fff'
          }
        }
      ]
    };
  }

  useEffect(() => {
    getTop(type);
  }, [address]);

  return (
    <div className={styles.box} style={{ ...style }}>
      <div style={{ height: '15%', textAlign: 'right' }}>
        <Radios
          defaultValue={1}
          data={radiosData}
          onChange={e => {
            setType(e.target.value);
            getTop(e.target.value);
          }}
        />
      </div>
      {data.length ? (
        <div style={{ height: '85%' }}>
          <ReactEcharts
            theme={'myTheme'}
            style={{ height: '100%' }}
            option={getOptions()}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
}
