/**
 * @author Ray
 */
import React from 'react';
import { message } from 'antd';
import { callShowExpert, callModifyExpert } from './service';
import { isNotEmpty } from '../../../utils/utils';

export default class extends React.Component {
    state = {
        projects: [],
        works: [],
        educations: []
    };
    componentDidMount() {
        callShowExpert().then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                const { data } = res;
                const {
                    sysExpertEduExperiences,
                    sysExpertProExperiences,
                    sysExpertWorkExes
                } = data;
                this.setState({
                    works: sysExpertWorkExes,
                    projects: sysExpertProExperiences,
                    educations: sysExpertEduExperiences
                });
            } else {
                message.error(res.message);
            }
        });
    }

    updateExpert = data => {
        callModifyExpert({ ...data }).then(res => {
            if (isNotEmpty(res) && res.success) {
                message.success(res.message);
            }
        });
    };

    render() {
        return this.props.children({
            ...this.props,
            ...this.state,
            updateExpert: this.updateExpert
        });
    }
}
