import React, { useEffect, useState } from 'react';
import ExpertGroupList from './groupList';
import Search from './search';
import styles from './styles.scss';
import BackBtn from '../../../common/backBtn/index';

export default function ExpertGroup({ history, setBannerShow }) {
    useEffect(() => {
        setBannerShow(false);
    }, []);
    const [data, setData] = useState({
        params: []
    });
    function setParams(value) {
        setData({ params: value });
    }
    return (
        <div className={styles.root}>
            <BackBtn history={history} />
            <br /><br />
            <Search onChange={setParams} />
            <ExpertGroupList history={history} params={data.params} />
        </div>
    );
}
