/**
 * @author Ray
 */
import React, { Fragment } from 'react';
import styles from './styles.scss';
import {
  List,
  Button,
  Input,
  DatePicker,
  Col,
  Row,
  Divider,
  Empty
} from 'antd';
import Label from '../../../../../common/withLabel';
import PropTypes from 'prop-types';
import moment from 'moment';
import { replace, timeRange } from '../../../../../utils/utils';

export default class extends React.Component {
  state = {
    source: []
  };

  static propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func
  };

  static defaultProps = {
    data: [],
    onChange: () => {}
  };

  handleChange = (field, item) => v => {
    const { onChange, data } = this.props;
    let newItem = {};
    switch (field) {
      case 'timeRange':
        v = v.map(t => moment(t).format('x'));
        newItem = {
          ...item,
          startTime: Number(v[0]),
          endTime: Number(v[1])
        };
        break;
      case 'company':
      case 'describe':
      case 'position':
        v = v.target.value;
        newItem = { ...item, [field]: v };
        break;
      default:
        break;
    }
    let _data = replace(data, newItem, o => item.index === o.index);
    this.setState({ source: _data }, () => {
      onChange(data, this.state.source, [item.index], [newItem]);
    });
  };

  deleteItem = item => () => {
    // 删除记录
    const { index } = item;
    const { data } = this.props;
    let _data = data.filter(d => d.index !== index);
    this.setState({ source: _data }, () => {
      this.props.onChange(data, this.state.source, [index], [item]);
    });
  };

  componentWillUnmount() {
    this.setState({ source: [] });
  }

  render() {
    const { data } = this.props;
    return (
      <div className={styles.template}>
        <List split itemLayout="horizontal">
          {data.map((item, index) => {
            return (
              <Fragment key={item.index}>
                <List.Item
                  actions={[
                    <Button
                      className={styles.actionButton}
                      icon="minus-circle"
                      onClick={this.deleteItem(item)}
                    >
                      删除该行
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    description={
                      <Label
                        extra={<i style={{ color: 'red' }}>*</i>}
                        text="任职时间"
                      >
                        <DatePicker.RangePicker
                          onChange={this.handleChange('timeRange', item)}
                          defaultValue={timeRange(item.startTime, item.endTime)}
                        />
                      </Label>
                    }
                  />
                </List.Item>
                <Row>
                  <Col span={14}>
                    <Label
                      extra={<i style={{ color: 'red' }}>*</i>}
                      text="公司名称"
                    >
                      <Input
                        style={{ width: 374 }}
                        onChange={this.handleChange('company', item)}
                        maxLength={50}
                        defaultValue={item.company}
                      />
                    </Label>
                  </Col>
                  <Col span={10} className="right">
                    <Label
                      extra={<i style={{ color: 'red' }}>*</i>}
                      text="担任职位"
                    >
                      <Input
                        style={{ width: 229 }}
                        onChange={this.handleChange('position', item)}
                        maxLength={50}
                        defaultValue={item.position}
                      />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Label
                    // extra={<i style={{ color: 'red' }}>*</i>}
                    text="工作说明"
                  >
                    <Input.TextArea
                      style={{ width: 500 }}
                      defaultValue={item.describe}
                      autosize={{
                        maxRows: 6,
                        minRows: 4
                      }}
                      maxLength={500}
                      onChange={this.handleChange('describe', item)}
                    />
                  </Label>
                </Row>
                {index !== data.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </List>
        {!data.length && <Empty />}
        {/*<List split dataSource={data} itemLayout="horizontal" />*/}
      </div>
    );
  }
}
