/**
 * @author hjie
 */
import style from '../register/style.scss';
import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Input, Form, Button, Modal } from 'antd';

import {
    CellphoneCheck,
    PswCheckSymbol,
    PswCheckLetter,
    PswCheckNum,
    SpaceCheck
} from 'utils/validator';
import { btnLayoutCol, modalformLayout } from 'constants/common';
import VerifyCode from 'common/verifyCode';
import ErrorTip from 'common/errorTip';
import VerifyPhone from 'common/verifyPhone';
import { forgetPsw, validatePhone, validateName } from '../service';
import { StatusCode } from 'constants/enum';
import BackBtn from 'common/backBtn';
import getKeyHook from '../../../hooks/getKeyHook';

const TEMPLATE = 4;
function ForgetPsw({ form, history }) {
    const { getFieldDecorator } = form;
    const phoneEl = useRef(null);
    const codeEl = useRef(null);
    const { getRsaPassWord, getRsaPreparedAsync } = getKeyHook();

    // 图片验证码
    const [validateImg, setValidateImg] = useState(false);
    const imgCallBack = tip => {
        setValidateImg(tip);
    };

    // 手机验证码 发送手机验证码之前需验证手机号码、用户名、图片验证码
    function getCode() {
        form.validateFields(
            ['phone', 'username', 'imgCaptcha'],
            (err, values) => {
                if (!err && !validateImg && values.imgCaptcha) {
                    validateUser(values);
                    return;
                }
                if (!values.imgCaptcha) {
                    setValidateImg(true);
                }
            }
        );
    }

    // 验证用户名是否存在
    function validateUser(values) {
        validateName({
            userName: values.username
        }).then(res => {
            if (res.statusCode === StatusCode.success && res.data) {
                validateNum(values);
            } else {
                Modal.error({
                    title: '提示',
                    okText: '确定',
                    content: '用户不存在，请确认用户名输入正确'
                });
            }
        });
    }

    // 验证用户绑定的手机号是否一致
    function validateNum(values) {
        const { phone, username, imgCaptcha } = values;
        validatePhone({
            phone,
            userName: username
        }).then(res => {
            if (res.data) {
                phoneEl.current.toSend(phone, TEMPLATE, imgCaptcha); // 倒计时并发送验证码
            } else {
                Modal.error({
                    title: '提示',
                    okText: '确定',
                    content: '请输入该账户绑定的手机号码'
                });
            }
        });
    }
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if(!values.imgCaptcha){
                setValidateImg(true);
                return;
            }
            if (!err && !validateImg) {
                toSave(values);
            }
        });
    };

    function toSave(param) {
        const { newPassword, confirmPassword, ...others } = param;
        getRsaPreparedAsync().then(res => {
            if (res && res.success) {
                const rsaPassWord = getRsaPassWord(newPassword);
                const json = {
                    ...others,
                    newPassword: rsaPassWord,
                    confirmPassword: rsaPassWord
                };
                forgetPsw(json).then(res => {
                    if (res.statusCode === StatusCode.success) {
                        Modal.success({
                            title: '提示',
                            okText: '确定',
                            content: '密码修改成功',
                            onOk() {
                                history.push('/user/login');
                            }
                        });
                    } else {
                        codeEl.current.reset();
                        Modal.error({
                            title: '提示',
                            okText: '确定',
                            content: res.message
                        });
                    }
                });
            }
        })  
    }

    return (
        <div className={classnames(style.register, style.forgetPsw)}>
            <BackBtn history={history} url="/user/login" text="返回登录" />
            <div className={classnames(style.formWrap, 'formWrap')}>
                <div className="userTitle">忘记密码</div>
                <Form onSubmit={handleSubmit} {...modalformLayout}>
                    <Form.Item label="用户名">
                        {getFieldDecorator('username', {
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请输入用户名" />
                                },
                                {
                                    whitespace: true,
                                    message: <ErrorTip text="请输入用户名" />
                                }
                            ]
                        })(
                            <Input
                                type="text"
                                placeholder="请输入"
                                autoComplete="off"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="绑定手机号码">
                        {getFieldDecorator('phone', {
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请输入手机号码" />
                                },
                                {
                                    pattern: CellphoneCheck.pattern,
                                    message: (
                                        <ErrorTip
                                            text={CellphoneCheck.message}
                                        />
                                    )
                                }
                            ]
                        })(<Input type="text" placeholder="请输入手机号码" />)}
                    </Form.Item>
                    <Form.Item label="图片验证码" className="formCaptcha">
                        {getFieldDecorator('imgCaptcha', {
                            initialValue: ''
                        })(
                            <VerifyCode
                                callBack={imgCallBack}
                                showErrTip={validateImg}
                                ref = {codeEl}
                                template={TEMPLATE}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="短信验证码">
                        {getFieldDecorator('smsCode', {
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请输入验证码" />
                                }
                            ]
                        })(<VerifyPhone handleClick={getCode} ref={phoneEl} />)}
                    </Form.Item>
                    <Form.Item label="密码">
                        {getFieldDecorator('newPassword', {
                            validateFirst: true,
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请输入密码" />
                                },
                                {
                                    pattern: SpaceCheck.pattern,
                                    message: (
                                        <ErrorTip text={SpaceCheck.message} />
                                    )
                                },
                                {
                                    pattern: PswCheckSymbol.pattern,
                                    message: (
                                        <ErrorTip
                                            text={PswCheckSymbol.message}
                                        />
                                    )
                                },
                                {
                                    pattern: PswCheckLetter.pattern,
                                    message: (
                                        <ErrorTip
                                            text={PswCheckLetter.message}
                                        />
                                    )
                                },
                                {
                                    pattern: PswCheckNum.pattern,
                                    message: (
                                        <ErrorTip text={PswCheckNum.message} />
                                    )
                                },
                                {
                                    min: 6,
                                    message: <ErrorTip text="长度至少6位" />
                                }
                            ]
                        })(
                            <Input
                                type="password"
                                placeholder="包含英文（区分大小写））、数字和特殊符号"
                                autoComplete="new-password"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="确认密码">
                        {getFieldDecorator('confirmPassword', {
                            validateFirst: true,
                            rules: [
                                {
                                    required: true,
                                    message: <ErrorTip text="请再次输入密码" />
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        let _password = form.getFieldValue(
                                            'newPassword'
                                        );
                                        if (value === _password) {
                                            callback();
                                        } else {
                                            callback(
                                                <ErrorTip text="输入密码不匹配" />
                                            );
                                        }
                                    }
                                }
                            ]
                        })(
                            <Input
                                type="password"
                                placeholder={'请再次输入密码'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={btnLayoutCol}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={style.button}
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

const ForgetPswForm = Form.create()(ForgetPsw);
export default ForgetPswForm;
