import React, { useState, useRef, useEffect } from 'react';
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { listedEnterprises, listedEnterprisesBasic, listedEnterprisesInnovate, listedEnterprisesSelected } from './service';
import './china.js';
import { isNotEmpty } from '../../../../utils/utils';
import axios from 'axios';
import getLocalMap from '../../domesticStock/service';
/**
 * @desc  企业地域分布地图展示
*/
export default function Map({ address, type }) {
  const echartsInstance = useRef(null);
  const [data, setData] = useState([]);


  useEffect(() => {
    //axios请求本地json文件
    // axios.get(`/public/data/map/${address}.json`, {})
    // .then(function(response) {
      getLocalMap(address).then(res => {
      echarts.registerMap(address, res)
      let coordies = res.features.reduce((acc, feature) => {
        acc[feature.properties.name] = feature.properties.cp;
            return acc;
        }, {});

        //请求全部
        type == 1 ? listedEnterprises()
          .then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
              let data = res.data.map(item => {
                  const { xxssdq, xxsl } = item
                  return {
                      name: xxssdq,
                      value: xxsl
                  };
              });

              setTimeout(() => {
                  setData(data);
              }, 0);
            }
          }) :

        //请求基础层
        type == 2 ? listedEnterprisesBasic()
          .then(res => {
              if (isNotEmpty(res) && isNotEmpty(res.data)) {
                 let data = res.data.map(item => {
                    const { xxssdq, xxsl } = item
                    return {
                       name: xxssdq,
                       value: xxsl
                    }
                 })

                 setTimeout(() => {
                    setData(data);
                 }, 0)
              }
          }) :

        //请求创新层
        type == 3 ? listedEnterprisesInnovate()
          .then(res => {
              if (isNotEmpty(res) && isNotEmpty(res.data)) {
                let data = res.data.map(item => {
                   const { xxssdq, xxsl } = item
                   return {
                      name: xxssdq,
                      value: xxsl
                   }
                })

                setTimeout(() => {
                  setData(data);
                }, 0)
              }
          }) :

        //请求精选层
        type == 4 ? listedEnterprisesSelected()
          .then(res => {
             if (isNotEmpty(res) && isNotEmpty(res.data)) {
                let data = res.data.map(item => {
                   const { xxssdq, xxsl } = item
                   return {
                      name: xxssdq,
                      value: xxsl
                   }
                })

                setTimeout(() => {
                  setData(data);
                }, 0)
             }
          }) : <Empty />
    })
    // })
  }, [address, type])




  function getOption() {
    return {
      title: {
        // text: '风险平均分分布',
        // subtext: '纯属虚构',
        //left: 'left',
        top: 80,
        left: 20,
        textStyle: {
          color: '#fff'
        }
      },
      tooltip: {
        // triggerOn: "click",
        formatter: function(e, t, n) {
            return .5 == e.value ? e.name + "：有疑似病例" : e.seriesName + "<br />" + e.name + "：" + e.value
        }
      },
      visualMap: {
          min: 0,
          max: 1000,
          left: 40,
          bottom: 40,
          showLabel: !0,
          text: ["高", "低"],
          textStyle: {
            color: '#969696'
          },
          pieces: [{
              gt: 1000,
              label: "> 1000",
              color: "#E86452"
          }, {
              gte: 500,
              lte: 1000,
              label: "500 - 1000",
              color: "#F6BD16"
          }, {
              gte: 200,
              lte: 500,
              label: "200 - 500",
              color: "#5D7092"
          }, {
              gte: 50,
              lte: 200,
              label: "50 - 200",
              color: "#5AD8A6"
          }, {
              lt: 50,
              label: "< 50",
              color: "#5B8FF9"
          }],
          show: !0
      },
      geo: {
          map: "china",
          roam: !1,
          scaleLimit: {
              min: 1,
              max: 2
          },
          zoom: 1.23,
          label: {
              normal: {
                  // show: !0,
                  show: false,
                  fontSize: "14",
                  color: "rgba(0,0,0,0.7)"
              }
          },
          itemStyle: {
              normal: {
                  //shadowBlur: 50,
                  //shadowColor: 'rgba(0, 0, 0, 0.2)',
                  borderColor: "rgba(0, 0, 0, 0.2)"
              },
              emphasis: {
                  areaColor: "#f2d5ad",
                  shadowOffsetX: 0,
                  shadowOffsetY: 0,
                  borderWidth: 0
              }
          }
      },
      series: [
        {
          name: "风险平均分",
          type: "map",
          geoIndex: 0,
          data: data
        }
      ]
    }
  }

  return (
        <div style={{ height: '62vh', }}>
          <ReactEcharts
              notMerge
              theme={'myTheme'}
              ref={echartsInstance}
              option={getOption()}
              style={{ height: '100%' }}
              // loadingOption={loadingOption}
          />
        </div>
  );
}

Map.defaultProps = {
    address: 'all'
}
