/**
 * @author Ray
 */

import React, { useEffect, useRef } from 'react';
import { Button, message, Row } from 'antd';
import Title from '../../../../common/title';
import RequireForm from './form';
import styles from './styles.scss';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import { callApplyDetail } from '../service';

export default function Require({
    demand,
    setStep,
    setDemand,
    history,
    match,
    isPreview,
    setBannerShow
}) {
    useEffect(() => {
        setBannerShow(false);
    }, []);
    const requireRef = useRef(null);

    function next() {
        const id = match.params.id;

        requireRef.current.form.validateFieldsAndScroll((errors, values) => {
            if (isEmpty(errors)) {
                setDemand(values);
                setStep(1);
                history.push({
                    pathname: isEmpty(id)
                        ? '/investment/consultant/material'
                        : `/investment/consultant/material/${id}`
                });
            }
        });
    }

    useEffect(function() {
        const id = match.params.id;

        if (isNotEmpty(id)) {
            callApplyDetail(id).then(function(res) {
                if (isNotEmpty(res) && isNotEmpty(res.data)) {
                    const { demand } = res.data;
                    setDemand(demand);
                    requireRef.current &&
                        requireRef.current.form.setFieldsValue(demand);
                } else {
                    message.error(res.message);
                }
            });
        } else {
            if (isNotEmpty(demand))
                setTimeout(
                    () =>
                        requireRef.current &&
                        requireRef.current.form.setFieldsValue(demand),
                    0
                );
        }
    }, []);

    return (
        <div className={styles.root}>
            <Title level={2} text="上市需求" />
            <RequireForm isPreview={isPreview} formRef={requireRef} />
            <br />
            <br />
            {isPreview ? null : (
                <Row className="textCenter">
                    <Button
                        className={styles.button}
                        type="primary"
                        onClick={next}
                    >
                        下一步
                    </Button>
                </Row>
            )}
        </div>
    );
}
