import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import LeftSecond from './IndustryRiskBar';

/**
 * @desc 左边第二个
 */
export default function LeftSecondIndex({ address, style }) {
  return (
    <LeftSecond address={ address } />
  );
}
