/**
 * @author Ray
 * @desc 基本申请材料
 */
import React, { useEffect, useContext } from 'react';
import { Form, Input, Tooltip, Icon, Alert } from 'antd';
import ImageUpload from '../../../../common/uploadImage';
import UploadFileYear from '../../../../common/uploadFileYear';
import styles from './styles.scss';
import { MIME1, MIME2 } from '../../../../constants/enum';
import ErrorTip from '../../../../common/errorTip';
import { GlobalContext } from "../../../../common/globalStore/createProvider";

function BasicMaterialForm({ form, onInit, data }) {
    const gs = useContext(GlobalContext);
    const prefix = gs.isPub
        ? 'http://file.beijingipo.com.cn/'
        : 'http://192.168.88.106/'

    useEffect(function () {
        onInit('documentaryProofForm', form);
    }, []);

    useEffect(
        function () {
            delete data.id;

            form.setFieldsValue({ ...data });
        },
        [data]
    );

    return (
        data && data.plate === '新三板' || data.plate === '新三板精选层' ?
            <Form
                className={styles.formRoot}
                labelCol={{ xs: { span: 8 } }}
                wrapperCol={{ xs: { span: 16 } }}
            >
                <Form.Item
                    label={
                        <span>
                            证监会股票发行批复&nbsp;
                            <Tooltip title={`中国证监会《关于核准${data.companyName}向不特定合格投资者公开发行股票的批复》`}>
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('approvedCsrcUrl', {
                        rules: [
                            {
                                required: true,
                                message: <ErrorTip text="请上传文件！" />
                            }
                        ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item label="附件备注">
                    {form.getFieldDecorator('decription', {})(
                        <Input.TextArea
                            maxLength={500}
                            style={{ width: 400 }}
                            autosize={{ minRows: 4, maxRows: 6 }}
                        />
                    )}
                </Form.Item>
            </Form>
            :
            <Form
                className={styles.formRoot}
                labelCol={{ xs: { span: 8 } }}
                wrapperCol={{ xs: { span: 16 } }}
            >
                <Form.Item
                    label={
                        <span>
                            交易所关于股票上市交易的通知/关于股票上市的函&nbsp;
                            <Tooltip title={`上市成功后《关于${data.companyName}人民币普通股股票上市交易的通知》`}>
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('issueCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: <ErrorTip text="请上传文件！" />
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item
                    label="证监局关于辅导项目的意见函"
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('coachSummaryUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: <ErrorTip text="请上传文件！" />
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item>
                    <Alert
                        banner
                        message={(
                            <p>如果您的企业属于下列情形，请务必上传如下材料：<br />
                                <span className={styles.circle}></span>沪市主板、深市主板上市 <br />
                                <span className={styles.circle}></span>2020年6月12日之前在深市创业板进入辅导备案的存量企业
                            </p>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="证监会核准批复"
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('approvedCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: <ErrorTip text="请上传文件！" />
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item>
                    <Alert
                        banner
                        message={(
                            <p>如果您的企业属于下列情形，请务必上传如下材料：<br />
                                <span className={styles.circle}></span>沪市主板、深市主板上市 <br />
                                <span className={styles.circle}></span>2020年6月12日之前在深市创业板进入辅导备案的存量企业
                            </p>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="证监会受理函"
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('acceptanceCsrcUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: <ErrorTip text="请上传文件！" />
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item>
                    <Alert
                        banner
                        message={(
                            <p>如果您的企业属于下列情形，请务必上传如下材料：<br />
                                <span className={styles.circle}></span>沪市主板、深市主板上市 <br />
                                <span className={styles.circle}></span>2020年6月12日之前在深市创业板进入辅导备案的存量企业
                            </p>
                        )}
                    />
                </Form.Item>
                <Form.Item
                    label="五年不外迁承诺（境外注册企业境内上市）"
                    extra="支持扩展名：.png .jpg"
                >
                    {form.getFieldDecorator('guaranteeUrl', {
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: <ErrorTip text="请上传文件！" />
                        //     }
                        // ]
                    })(<ImageUpload accept=".png,.jpg" prefix={prefix} />)}
                </Form.Item>
                <Form.Item label="附件备注">
                    {form.getFieldDecorator('decription', {})(
                        <Input.TextArea
                            maxLength={500}
                            style={{ width: 400 }}
                            autosize={{ minRows: 4, maxRows: 6 }}
                        />
                    )}
                </Form.Item>
            </Form>
    );
}

export default Form.create({ name: 'documentaryProofForm' })(BasicMaterialForm);
