/**
 * @author Ray
 */
import React from 'react';
import { getApplyInfo } from './service';
import { isEmpty } from 'utils/utils';
import { isNotEmpty } from '../../../utils/utils';
import { message } from 'antd';
export default class extends React.Component {
    state = {
        basicApplyMaterial: {
            applyCash: '',
            manageProUrl: '',
            businessLicUrl: '',
            yearReportUrl: ''
        },
        basicInfo: {
            companyName: '',
            code: '',
            plate: '',
            stage: '',
            district: ''
        },
        applyMaterials: {
            coachSummaryUrl: '',
            approvedCsrcUrl: '',
            issueCsrcUrl: '',
            acceptanceCsrcUrl: '',
            acceptanceExchangeUrl: '',
            deliberationIpoCouncilUrl: '',
            decription: '',
            agreeCsrcUrl: '',
            shareTransferUrl: '',
            administrativeLicenseCsrcUrl: '',
            selfRegulationUrl: '',
            considerationResultUrl: '',
            agreeCoachBjsrUrl: ''
        },
        sources: {},
        applyStage: undefined
    };
    componentDidMount() {
        const { match } = this.props;

        getApplyInfo(match.params.id).then(res => {
            if (isNotEmpty(res) && isNotEmpty(res.data)) {
                const { data } = res;

                const {
                    companyName,
                    code,
                    plate,
                    stage,
                    district,
                    tipContent,
                    id,
                    status,
                    companyProvince
                } = data; // 基本信息
                const { applyCash, manageProUrl, businessLicUrl, yearReportUrl } = data; //  基本申请材料
                const {
                    coachSummaryUrl,
                    approvedCsrcUrl,
                    issueCsrcUrl,
                    acceptanceCsrcUrl,
                    acceptanceExchangeUrl,
                    deliberationIpoCouncilUrl,
                    decription,
                    agreeCsrcUrl,
                    shareTransferUrl,
                    administrativeLicenseCsrcUrl,
                    selfRegulationUrl,
                    considerationResultUrl,
                    agreeCoachBjsrUrl,
                    applyStage
                } = data;

                this.setState({
                    id,
                    applyStage,
                    status,
                    basicInfo: {
                        companyName,
                        code,
                        plate,
                        stage,
                        district,
                        tipContent,
                        companyProvince
                    },
                    basicApplyMaterial: {
                        applyCash,
                        manageProUrl,
                        businessLicUrl,
                        yearReportUrl
                    },
                    applyMaterials: {
                        coachSummaryUrl,
                        approvedCsrcUrl,
                        issueCsrcUrl,
                        acceptanceCsrcUrl,
                        acceptanceExchangeUrl,
                        deliberationIpoCouncilUrl,
                        decription,
                        agreeCsrcUrl,
                        shareTransferUrl,
                        administrativeLicenseCsrcUrl,
                        selfRegulationUrl,
                        considerationResultUrl,
                        agreeCoachBjsrUrl,
                        plate
                    }
                });
            } else {
                message.error(res.message);
                this.props.history.goBack();
            }
        });
    }

    render() {
        return this.props.children({
            ...this.props,
            ...this.state
        });
    }
}
