/**
 * @desc  股转动态
 */
import React, { useState } from 'react';
import styles from './styles.scss';
import Title from './title';
import List from './list';

export default function Line13Index() {
    
    return (
        <div className={ styles.line13 }>
            <Title />
            <List />
        </div>
        
    );
}
