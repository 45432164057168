/**
 * @author hjie
 * 云课堂
 */

import React from 'react';
import ListPage from '../common/listPage/index';

export default function(props) {
    return (
        <ListPage {...props} Type = "云课堂"  Text = "课程"/>
    );
}
