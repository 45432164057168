import { useState, useEffect } from 'react';
import { getIndustryInfo } from './service';

const BarOptions = [
    { title: '营业收入现金含量', key: 'salescashtoincomeList', color: '#2082ED', formula: '（销售收到现金/营业收入）'},
    { title: '资产负债率(%)', key: 'assetLiabilityRatioList', color: '#59DF8F', formula: '（负债合计/资产合计）' },
    { title: '净利润率(%)', key: 'salesNetMarginList', color: '#FB7B39', formula: '（净利润/营业收入）'},
    { title: '存货周转率天数', key: 'invturnoverdaysList', color: '#FFC641', formula: '（360*平均存货/营业成本）'},
    { title: '应收账款周转天数', key: 'acctrecturnoverdaysList', color: '#6C68F6', formula: '（360*平均应收账款/营业收入）'},
    { title: '毛利率(%)', key: 'salesGrossMarginList', color: '#53A5FF', formula: '(主营业务收入-主营业务成本)/主营业务收入×100%)'}
];

export default function(year, qyxxId){

    const [data, setData ] = useState();
    const [barData, setBarData ] = useState();
    const [loading, setLoading ] = useState(true);

    function getData(){
        getIndustryInfo({
            qyxxId,
            year
        }).then(res => {
            if(res.success){
                getBarData(res.data);
            }else{
                setData(null);
            }
        }).finally( () =>{
            setLoading(false);
        });
    }

    function getBarData(allData){
        let arr =  BarOptions.map( ele => {
            const item = allData[ele.key];
            let series = [];
            if(item){
                const { companyName, point, sorts, sort } = item;
                let otherData = sorts.map( p => {
                    return {name: p.companyName, value: p.point, sort: p.sort };
                });
                series = [{name: companyName, value: point, sort }, ...otherData];
            }    
            return {...ele, data: series };
        })
        setBarData(arr);
        setData(allData);
    }

    useEffect( () =>{
        getData();
    }, [year])

    return { data, loading, BarOptions, barData };
}