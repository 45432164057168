/**
 * @desc 两网及退市公司title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line3Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line3Title }>
                两网及退市公司
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}