/**
 * @author Ray
 */

import React, { useEffect, useState } from 'react';
import Title from '../../../common/title';
import styles from './styles.scss';
import { Divider, message } from 'antd';
import Content from './content';
import { getMessageDetail } from './service';
import { isNotEmpty } from '../../../utils/utils';
import moment from 'moment';
import { changeStatus, getMessages } from '../message/service';
import Back from '../../../common/backBtn';
import { actions } from '../../frame/scripts';

export default function(props) {
    const [time, setTime] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    useEffect(function() {
        const {
            params: { msgId }
        } = props.match;
        const { dispatch } = props;

        if (isNotEmpty(msgId)) {
            getMessageDetail(msgId)
                .then(function(res) {
                    if (res.success) {
                        setContent(res.data.content);
                        setTitle(res.data.title);
                        setTime(
                            moment(res.data.gmtCreate, '').format(
                                'YYYY年MM月DD日'
                            )
                        );
                        return res.data.msgId;
                    } else {
                        message.error(res.message);
                    }
                    return false;
                })
                .then(function(msgId) {
                    if (msgId) {
                        changeStatus([Number(msgId)]).then(() => {
                            getMessages({
                                pageNum: 1,
                                pageSize: 10,
                                read: 0
                            }).then(res => {
                                if (
                                    isNotEmpty(res) &&
                                    isNotEmpty(res.data) &&
                                    isNotEmpty(res.data.data)
                                ) {
                                    dispatch(actions.updateNotify(false));
                                } else {
                                    dispatch(actions.updateNotify(true));
                                }
                            });
                        });
                    }
                });
        }
    }, []);

    return (
        <section className={styles.messageContentRoot}>
            <Back history={props.history} url="/userCenter/" />
            <Title classes={{ root: styles.titleFont }} text={'消息详情'} />
            <Divider />
            <Content title={title} time={time} text={content} />
        </section>
    );
}
