'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _eventemitter = require('eventemitter3');

var _eventemitter2 = _interopRequireDefault(_eventemitter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MOUSE_EVENT = ['mouseover', 'mousedown', 'mouseup', 'mousemove', 'mouseout', 'click', 'dblclick', 'contextmenu', 'mousewheel']; /**
                                                                                                                                     * 事件处理器, 用于全局事件绑定和响应
                                                                                                                                     */

var Handler = function () {
    // 自定义事件处理器

    // 所有的事件绑定
    function Handler(n) {
        (0, _classCallCheck3.default)(this, Handler);

        this._customDispatcher = new _eventemitter2.default();
        this._customDispatcher.setMaxListeners(n);
    } // 鼠标事件处理器
    // 根节点


    (0, _createClass3.default)(Handler, [{
        key: 'init',
        value: function init(root) {
            this._root = root;
            this._listeners = {};
            return this;
        }
    }, {
        key: 'setMouseDispatcher',
        value: function setMouseDispatcher(dispatcher) {
            this._mouseDispatcher = dispatcher;
        }
    }, {
        key: 'on',
        value: function on(name, handler, context) {
            if (!name || !handler) {
                return;
            }

            if (!this._listeners[name]) {
                this._listeners[name] = {};
            }

            // 避免绑定重复的函数, handler相同
            var _handler = this._listeners[name][handler];
            if (_handler) {
                // 函数引用相同, 说明已经绑定, 不再重复绑定
                if (_handler === handler) {
                    return;
                }
                // 函数引用不同, 但是key相同, 可能是箭头函数或匿名函数, 移除原有绑定, 再重新绑定新函数.
                this.off(name, _handler);
            }

            var event = name.toLowerCase();
            // 鼠标事件交给 zrender 处理
            if (MOUSE_EVENT.indexOf(event) !== -1) {
                // 鼠标移除窗口只有document和window能监听到, 所以用document来监听此方法.
                if (event === 'mouseup' && document) {
                    document.addEventListener(event, handler);
                } else {
                    this._mouseDispatcher.on(name, handler, context);
                }
                // 自定义事件交给 eventemitter 处理
            } else {
                this._customDispatcher.on(name, handler, context);
            }

            this._listeners[name][handler] = handler;
            return this;
        }
    }, {
        key: 'off',
        value: function off(name, handler, context) {
            if (!this._listeners[name]) {
                return;
            }

            var event = name.toLowerCase();
            if (MOUSE_EVENT.indexOf(event) !== -1) {
                if (event === 'mouseup' && document) {
                    document.removeEventListener(event, handler, context);
                } else {
                    this._mouseDispatcher.off(name, handler);
                }
            } else {
                this._customDispatcher.removeListener(name, handler, context);
            }

            delete this._listeners[name][handler];

            return this;
        }
    }, {
        key: 'removeMatchingListeners',
        value: function removeMatchingListeners(event) {
            if (!event || !this._listeners) {
                return;
            }

            for (var name in this._listeners) {
                if (name.startsWith(event)) {
                    var handlers = this._listeners[name];
                    for (var handler in handlers) {
                        this.off(name, this._listeners[name][handler]);
                    }
                    delete this._listeners[name];
                }
            }
        }
    }, {
        key: 'removeAllListeners',
        value: function removeAllListeners(events) {
            var _this = this;

            if (!this._listeners) {
                return;
            }

            if (!events) {
                events = (0, _keys2.default)(this._listeners);
            } else if (typeof events === 'string') {
                events = [events];
            }

            events.forEach(function (event) {
                var handlers = _this._listeners[event];
                if (handlers) {
                    for (var handler in handlers) {
                        _this.off(event, _this._listeners[event][handler]);
                    }
                    delete _this._listeners[event];
                }
            });
        }
    }, {
        key: 'trigger',
        value: function trigger(name, data) {
            try {
                if (MOUSE_EVENT.indexOf(name.toLowerCase()) !== -1) {
                    this._mouseDispatcher.trigger(name, data);
                } else {
                    this._customDispatcher.emit(name, data);
                }
            } catch (e) {
                console.error('Handler trigger Error', name, e);
            }
            return this;
        }
    }, {
        key: 'getAllListeners',
        value: function getAllListeners() {
            return this._listeners;
        }
    }, {
        key: 'clear',
        value: function clear() {
            this.removeAllListeners();
            this._listeners = {};
            return this;
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            this.clear();
            // TODO: 由于在painter.dispose()之前调用, 会出现zrender已经被销毁, painter中对象无法移除事件的问题, 目前暂时先这样修改.
            // this._root = null;
            // this._listeners = null;
            // this._mouseDispatcher = null;
            // this._customDispatcher = null;
        }
    }]);
    return Handler;
}();

exports.default = Handler;
module.exports = exports['default'];