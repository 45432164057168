import React from 'react';
import { Button } from 'antd';
import styles from './styles.scss';

Refresh.defaultProps = {
    onClick: () => {}
};

export default function Refresh({ onClick }) {
    return (
        <div className={styles.refresh}>
            <Button type="primary" icon="sync" onClick={onClick}>
                刷新
            </Button>
        </div>
    );
}
