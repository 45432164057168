import { message } from 'antd';
import { AssetsKeys, CashKeys, ProfitKeys } from '../constants/enum';
import moment from 'moment';
import { callFinance } from '../common/financialTabs/service';
import {
  getOperatingData,
  getOperatingOtherData
} from '../containers/investment/consultant/service';
import { convertData } from '../common/financialTabs/utils';

/**
 * 过滤参数，过滤空字符串
 * */
export function paramFilter(params = {}, options = { encrypt: true }) {
  let result = {};
  for (let k in params) {
    if (params[k] !== undefined && params[k] !== null) {
      result[k] = options.encrypt
        ? window.encodeURIComponent(params[k])
        : params[k];
    }
  }
  return result;
}

export function getRequestQuery(query) {
  const res = [];
  Object.entries(query).forEach(o => res.push(`${o[0]}=${o[1]}`));
  return res.join('&');
}

export function isSpecialSon(target, className) {
  let flag = false; // 默认为否
  let selfTarget = null;
  // 闭包 用以公用变量
  function fn(_target) {
    // 判断target是否有特定的className

    // 递归的出口
    if (flag || !_target) {
      return;
    }
    if (!_target.classList) {
      return;
    }

    if (_target.classList.contains(className)) {
      flag = true;
      selfTarget = _target;
    } else {
      // 递归的入口
      fn(_target.parentNode);
    }
  }

  fn(target);
  return { flag, selfTarget };
}

/* 截取地址栏跳转过来的参数  返回的params是对象*/
export function addressNum(k) {
  let str = k.history.location.search;
  let params = (str => {
    if (typeof str === 'string') {
      str = str.substr(1);
      let strArr = str.split('&');
      let params = null;
      if (Array.isArray(strArr)) {
        params = {};
        strArr.forEach(item => {
          let _arr = item.split('=');
          params[_arr[0]] = _arr[1];
        });
      }
      return params;
    }
  })(str);

  return params;
}
// 全局提示配置
export function errConfig(type, msg) {
  message.destroy();
  message.config({
    top: 60
  });
  if (type === 'success') {
    message.success(msg);
  } else if (type === 'error') {
    message.error(msg);
  }
}
// 限制显示字数，超出省略号显示
export function LimitNumber(txt, limitNum) {
  var str = txt;
  if (typeof str === 'string' && str !== 'null') {
    if (str.length > limitNum) {
      str = str.substr(0, limitNum) + '...';
    }
  }
  return str;
}

/**
 * @desc 函数节流
 * @url http://underscorejs.org/#throttle
 * @param {string} func 防抖函数
 * @param {number} wait 间隔时间
 * @param {object|undefined} options 可选项
 */
export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) {
    options = {};
  }

  var later = function() {
    previous = options.leading === false ? 0 : +new Date();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = args = null;
    }
  };

  return function() {
    var now = +new Date();
    if (!previous && options.leading === false) {
      previous = now;
    }
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

/** *
 * @description 防抖函数
 */
export function debounce(fn, delay, scope) {
  let timer = null;
  // 返回函数对debounce作用域形成闭包
  return function() {
    // setTimeout()中用到函数环境总是window,故需要当前环境的副本；
    let context = scope || this,
      args = arguments;
    // 如果事件被触发，清除timer并重新开始计时
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * 过滤params为null的
 * @param obj
 * @returns {{}}
 */
export function dealParam(obj) {
  let param = {};
  if (obj === null || obj === undefined || obj === '') {
    return param;
  }
  for (var key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== '' &&
      obj[key] !== 'all' &&
      obj[key] !== '请选择'
    ) {
      param[key] = obj[key];
    }
  }
  return param;
}

/**
 * 如果标题字数超出指定长度，则多余部分显示...
 * @param {number} num
 * @param {string} str
 */
export function limitString(num, str) {
  if (str && str.length > num) {
    return str.substring(0, num) + '...';
  }
  return str;
}

/**
 * 判断字段是否异常，如果异常则返回‘--’
 * @param value
 * @returns {*}
 */
export function fieldAnomaly(value) {
  if (
    (typeof value === 'number' && !isNaN(value)) ||
    (typeof value === 'string' && value.trim().length > 0)
  ) {
    return value;
  }

  if (!value) {
    return '--';
  }
  return value;
}

// get 参数拼接
export function convertQueryString(params) {
  let query = '';
  for (let key in params) {
    if (params[key] || params[key] === 0) {
      if (query.indexOf('?') === -1) {
        query = query + `?${key}=${params[key]}`;
      } else {
        query = query + `&${key}=${params[key]}`;
      }
    }
  }
  return query;
}

// 加密名字
export function encryptName(name) {
  if (isEmpty(name)) {
    return '';
  }

  return name.replace(name.slice(1), '**');
}

// 加密手机号
export function encryptNumber(number) {
  if (isEmpty(number)) {
    return '';
  }

  return number.replace(number.slice(3, 7), '****');
}

// 内部函数, 用于判断对象类型
function _getClass(object) {
  return Object.prototype.toString.call(object).match(/^\[object\s(.*)\]$/)[1];
}

export function isArray(obj) {
  return _getClass(obj).toLowerCase() === 'array';
}

export function isString(obj) {
  return _getClass(obj).toLowerCase() === 'string';
}

export function isDate(obj) {
  return _getClass(obj).toLowerCase() === 'date';
}

export function isObject(obj) {
  return _getClass(obj).toLowerCase() === 'object';
}

export function isNumber(obj) {
  return _getClass(obj).toLowerCase() === 'number';
}

/**
 * @desc 判断参数是否为空, 包括null, undefined, [], '', {}
 * @param {object} obj 需判断的对象
 */
export function isEmpty(obj) {
  var empty = false;

  if (obj === null || obj === undefined) {
    // null and undefined
    empty = true;
  } else if ((isArray(obj) || isString(obj)) && obj.length === 0) {
    empty = true;
  } else if (isObject(obj)) {
    var hasProp = false;
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        hasProp = true;
        break;
      }
    }
    if (!hasProp) {
      empty = true;
    }
  } else if (isNumber(obj) && isNaN(obj)) {
    empty = true;
  }
  return empty;
}

export function isNotEmpty(obj) {
  return !isEmpty(obj);
}

// 脱敏
export function desense(str, from = 3, len = 4) {
  if (!str) {
    return str;
  }
  const reg = new RegExp(`^(.{${from}}).{${len}}`, 'g');
  return str.replace(reg, '$1****');
}

/**
 * 千分位
 * @return {string}
 */
export function formatNumber(num, unit = 1) {
  if (isNaN(parseFloat(num))) {
    return '0';
  }
  return ('' + ((num * 100) / unit).toFixed(unit === 1 ? 0 : 1) / 100).replace(
    /(\d{1,3})(?=(\d{3})+(?:$|\.))/g,
    '$1,'
  );
}
// 格式化金额
export function money(k) {
  if (k) {
    // let a = k.split('.')[0];
    let b =
      k.toString().indexOf('.') === -1
        ? k
            .toString()
            .split('')
            .reverse()
        : k
            .toString()
            .split('.')[0]
            .split('')
            .reverse();
    for (var i = 0; i < b.length; i++) {
      if ((i + 1) % 4 === 0) {
        b.splice(i, 0, ',');
      }
    }
    b.reverse();
    var str = '';
    for (var c = 0; c < b.length; c++) {
      str += b[c];
    }
    return (
      str +
      (k.toString().indexOf('.') === -1 ? '' : '.' + k.toString().split('.')[1])
    );
  } else {
    return 0;
  }
}

/**
 *
 * @param array
 * @param replace
 * @param callback
 * @param push
 * @returns {Buffer | SharedArrayBuffer | T[] | BigUint64Array | Uint8ClampedArray | Uint32Array | Blob | Int16Array | T[] | Float64Array | Float32Array | string | Uint16Array | ArrayBuffer | Int32Array | BigInt64Array | Uint8Array | Int8Array | T[]}
 */
export function replace(array, replace, callback = () => false, push) {
  let _flag = false;
  if (!array.forEach) {
    throw new TypeError(`${JSON.stringify(array)} is not a valid Array`);
  }

  let _array = array.slice();

  array.forEach(function(v, i) {
    let flag = callback.call(null, v, i);

    if (flag) {
      _flag = true;
      _array[i] = replace;
    }
  });

  if (!_flag && push) {
    _array.push(replace);
  }

  return _array;
}

// 根据条件生成filter回调函数

export function filterFactory(indexKey) {
  return function(data) {
    return data.dataIndex === indexKey;
  };
}

// 更具文档类型返回key
export function getListLKeys(type) {
  switch (type) {
    case 2:
    case '2':
      return ProfitKeys;
    case 3:
    case '3':
      return AssetsKeys;
    case 4:
    case '4':
      return CashKeys;
    default:
      return [];
  }
}

/**
 * @description 生成随机数
 * @param {*} count
 */
export function random(count) {
  return (Math.random() * (10 ^ count)).toFixed(16);
}

/**
 * @desc 包装reducer函数
 * @param reducers
 * @returns {function(*=, *): *}
 */
export function makeReducer(reducers) {
  return function(state, action) {
    return reducers[action.type] ? reducers[action.type](state, action) : state;
  };
}

export function sortArrJson(property, isAsc) {
  return function(obj1, obj2) {
    var value1 = obj1[property];
    var value2 = obj2[property];
    let data = value2 - value1;
    if (isAsc) {
      data = value1 - value2; // 升序
    }
    return data;
  };
}

/**
 * @desc 格式化钱钱
 *
```javascript
let a = '123456789';
moneyFormatter(a) // 输出 123,456,789
```
 */
export function moneyFormatter(v) {
  return `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getDomain() {
  const { hostname } = location;
  if (hostname === 'localhost') {
    return hostname;
  }

  return hostname.match(/(\.\w+\.\w+(\.cn)?)$/)[1];
}

//读取cookies
export function getCookie(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');

  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

//写cookies
export function setCookie(name, value, Days = 30) {
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + '=' + escape(value) + ';expires=' + exp.toGMTString();
}

//删除cookie//必须指定domain和path
export function removeCookie(name, val = '', day = -1) {
  var date = new Date();
  date.setDate(date.getDate() + day);
  document.cookie =
    name +
    '=' +
    val +
    ';expires=' +
    date +
    ';domain' +
    '=' +
    getDomain() +
    ';path' +
    '=/';
}

// 生成selector组件使用的年份区间
export function generateYearRangeOptions(first, last) {
  let years = [];
  for (let i = first; i <= last; i++) {
    years.push({
      text: i,
      value: i
    });
  }
  return years;
}

// 生成timeRange数组, 参数为时间戳
export function timeRange(start, end) {
  return [
    start ? moment(start, 'x') : undefined,
    end ? moment(end, 'x') : undefined
  ];
}

// query string
export function qs(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

// 校验财务报表
export function checkFinanceData(type, code, way) {
  return callFinance(
    undefined,
    undefined,
    type,
    code,
    undefined,
    undefined,
    way
  ).then(res => {
    let data = convertData(type, res.data);
    if (data.columns.length < 4) return false;
    return true;
  });
}

// 校验经营信息
export function checkOperationData(type, creditCode) {
  return getOperatingData(creditCode, type).then(res => res.data);
}

// 校验经营信息
export function checkOtherData(creditCode) {
  return getOperatingOtherData(creditCode).then(res => res.data);
}

// group object /针对TM的主要客户和供应商这中奇怪的数据结构
export function groupObject(obj) {
  if (isEmpty(obj)) return [];

  let values = [];
  let names = [];
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (/Name$/.test(key)) names.push(obj[key]);
      if (/Value$/.test(key)) values.push(obj[key]);
    }
  }

  return values.map((value, index) => ({
    value,
    name: names[index]
  }));
}

// highlight
export function highlight(a, b) {
  let tag = '；';
  let groupA = a.split(tag);
  let groupB = b.split(tag);

  if (groupA.length === 1) {
    tag = ';';
    groupA = a.split(tag);
    groupB = b.split(tag);
  }

  if (groupA.length === 1) {
    tag = ' ';
    groupA = a.split(tag);
    groupB = b.split(tag);
  }

  if (groupA.length === 1) {
    tag = '、';
    groupA = a.split(tag);
    groupB = b.split(tag);
  }

  if (groupA.length === 1) {
    tag = '，';
    groupA = a.split(tag);
    groupB = b.split(tag);
  }

  if (groupA.length === 1) {
    tag = ',';
    groupA = a.split(tag);
    groupB = b.split(tag);
  }

  if (groupA.length <= 1) return a;

  let newGroup = groupA.map(item => {
    if (!groupB.includes(item)) {
      return `<span style="color:red">${item}</span>`;
    }
    return item;
  });

  return `<span>${newGroup.join(tag)}</span>`;
}

// transfer string null
export function transferStringNull(nullString) {
  return nullString === 'null' || isEmpty(nullString) ? '--' : nullString;
}

// make regex
export function makeRegex(str) {
  return new RegExp(str);
}

export function unique(arr) {
  if (!Array.isArray(arr)) {
    console.log('type error!');
    return;
  }
  return [...new Set(arr)];
}

/**
 * 字符串插入符号
 * @param {string} str   需要插入的字符（串）
 * @param {int} length   间隔几个字符
 * @param {int}  unit  插入符号
 * @param {int}  line  行数
 */
export function strInsert(str, unit, line = 2) {
  if (!str) {
    return '';
  }
  let length = str.length;
  if (length < 6) {
    return str;
  }
  let len = Math.ceil(length / line);
  let reg = new RegExp('.{1,' + len + '}', 'g');
  let ma = str.match(reg);
  return ma.join(unit);
}
export function getPermissionIds(user) {
  return user.resources.map(value => {
    return value.id;
  });
}

/**
 * @desc 生成一个随机id
 */
export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function queryStringAll() {
  var reg = /(?:^|&)([^&]+)=([^&]+)(?=&|$)/g,
    args = {},
    result,
    search = location.search.slice(1);
  while ((result = reg.exec(search))) {
    args[result[1]] = result[2];
  }
  return args;
}

// 检查对象中是否有空值
export function checkEmpty(obj, checkKeys) {
  let flag = true;
  if (isEmpty(obj)) {
    flag = false;
  }

  for (let index = 0; index < checkKeys.length; index++) {
    let key = checkKeys[index];
    if (obj.hasOwnProperty(key)) {
      if (isEmpty(obj[key])) {
        flag = false;
        break;
      }
    } else {
      flag = false;
      break;
    }
  }

  return flag;
}
