'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.defaultForce = defaultForce;
exports.collideForce = collideForce;

var _d3Force = require('d3-force');

var force = _interopRequireWildcard(_d3Force);

var _lodash = require('../lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

// d3.layout.force - 使用物理模拟排放链接节点的位置。
// force.alpha - 取得或者设置力布局的冷却参数。
// force.chargeDistance - 取得或者设置最大电荷距离。
// force.charge - 取得或者设置电荷强度。
// force.drag - 给节点绑定拖动行为。
// force.friction - 取得或者设置摩擦系数。
// force.gravity - 取得或者设置重力强度。
// force.linkDistance - 取得或者设置链接距离。
// force.linkStrength - 取得或者设置链接强度。
// force.links - 取得或者设置节点间的链接数组。
// force.nodes - 取得或者设置布局的节点数组。
// force.on - 监听在计算布局位置时的更新。
// force.resume - 重新加热冷却参数，并重启模拟。
// force.size - 取得或者设置布局大小。
// force.start - 当节点变化时启动或者重启模拟。
// force.stop - 立即停止模拟。
// force.theta - 取得或者设置电荷作用的精度。
// force.tick - 运行布局模拟的一步。

// 方法集合
/**
 * 说明：力导向算法模拟分子作用力进行计算，新版的力算法原理：两个分子为例，分子会产生互相的瞬间速度，而这个速度会随着距离的缩进慢慢变小。
 * 而这个变化，这里称之为仿真值，alpha（范围0~1）,如果仿真值为0，会停止运动。所以时间复杂度从以前的n*n 变成了 n*(1~n) 提高了性能。
 * 
 * d3.forceSimulation([nodes]) 返回一个 simulation 对象 传入接点。开始为仿真计算做准备。也可以通过 simulation.nodes(nodes) 传入
 * 
 * simulation对象的方法可以传递参数的均可获取参数，也可以设置参数，也可以传入一个函数，依次设置参数
 * 
 * eg: simulation.alpha(); 获取当前仿真值
 *     simulation.alpha(n); 设置当前仿真值
 *     simulation.alpha( d => { return xx; }); 分别设置单个接点的仿真值
 * 
 * simulation.alpha(n); 设置或者获取仿真值，也就是初始速度。n 是 0~1; n 还可以是一个函数 d => { ... }
 * 
 * simulation.alphaMin(n); 设置或获取最小的alpha值，区间为[0,1], 默认为0.001
 * 
 * simulation.alphaDecay(n); 设置或获取衰减系数，用来设置alpha的衰减率。默认为0.0228… = 1 - pow(0.001, 1 / 300)；如果衰减为0，会一直运动
 * 
 * simulation.alphaTarget([target]); alpha的目标值，区间为[0,1]. 默认为0
 * 
 * simulation.restart() 重新开始计算。
 * 
 * simulation.velocityDecay(n); 速度衰减系数，相当于摩擦力。区间为[0,1], 默认为0.4。在每次tick之后，
 * 节点的速度都会等于当前速度乘以1-velocityDecay,和alpha衰减类似，速度衰减越慢最终的效果越好，但是如果速度衰减过慢，可能会导致震荡。
 * 
 * simulation.force(name, fun); 默认情况下，仿真是中的节点是没有力的作用的，需要通过这个方法为仿真系统设置力的作用，力有很多种，需要根据实际情况
 * 指定，比如在对图布局进行仿真时，可以设置如下几种力:
    simulation.force("charge", d3.forceManyBody());	 // 节点间的作用力
    simulation.force("link", d3.forceLink(links));	 // 连线作用力
    simulation.force("center", d3.forceCenter());	 // 重力，布局有一个参考位置，不会跑偏
 *
 * simulation.find(x, y, radius); 返回半径radius 内的所有点 距离点(x, y) 最近的点 
 * 
 * simulation.on('eventName', callback); 绑定事件，tick 事件：每次计算的回调， end 计算完成的回调
 * 
 * simulation.force('force', d => { ... });  d返回 alpha， 可以在这里修改 node 的参数. 这里的'force'可以是任何字符串, 除了link,node外，取个名字
 * force.initialize(nodes) 这个还没搞懂怎么用
 * 
 * d3.forceCenter 创建center作用力
 * eg: simulation.force('center', d3.forceCenter(x, y)); x,y 为引力点
 * 
 * d3.forceCollide() 创建碰撞作用力， 返回一个 collide 对象，链式写法
 * collide.radius(r); r 为半径，也可以是一个return函数 d => { ... return r; }；
 * collide.strength(n); n是 0~1,默认是0.7 碰撞力的强度，节点的重叠通过迭代松弛来解决 
 * collide.iterations(n); 获取或设置迭代次数。
 * 
 * d3.forceLink 创建连线作用力， 返回一个link 对象，链式写法
 * link.links(links); 设置或获取link作用力的连接数组并重新计算distance 和 strength. 如果没有指定links则返回当前的links数组，默认为空
 * 每个links 包含 {index, source, target} index 用来做索引的， 如果links数组发生了改变，比如添加或删除一个link时则必须重新调用这个方法
 * link.id([id]) 设置或获取link中节点的查找方式，默认使用node.index:
 * link.distance(n); 获取或者设置两点之间的距离。 n 可以是个返回值的函数
 * link.strength([strength])  设置或获取link的强度，默认：
    function strength(link) {
        return 1 / Math.min(count(link.source), count(link.target));
    }
 * link.iterations(n); 获取或者设置迭代次数 
 * 
 * d3.forceManyBody() 多体作用力
 * manyBody.strength([strength]); 设置或获取强度参数，可以为负值也可以为正值，分别表示不同的力学类型，默认为 -30
 * manyBody.theta([theta]); 设置或获取theta参数。theta参数用来设置Barnes–Hut 的近似标准。默认为0.9
 * manyBody.distanceMin([distance]); 设置或获取最小连接距离
 * manyBody.distanceMax([distance]); 设置或获取最大连接距离
 * 
 * d3.forceX([x]) 根据给定的x位置创建一个x方向的作用力。如果没有指定x则默认为0, 返回x对象
 * x.strength([strength]); 设置或获取力的强度访问器，strength决定了节点x方向的速度增量:(x - node.x) × strength, 这个值越大则节点的位置会越快的朝向目标位置过渡，默认为:0.1
 * x.x(n); 设置或获取x坐标访问器。默认为0
 * 
 * d3.forceY([y]) 根据给定的y位置创建一个y方向的作用力。如果没有指定y则默认为0, 返回x对象
 * y.strength([strength]); 设置或获取力的强度访问器，strength决定了节点x方向的速度增量:(y - node.y) × strength, 这个值越大则节点的位置会越快的朝向目标位置过渡，默认为:0.1
 * y.y(n); 设置或获取y坐标访问器。默认为0
 * 
 * d3.forceRadial(radius, x, y) // 圈层作用力
 * 
 */

/**
 * @desc 设置 source 和 target
*/
function setSourceTarget(links) {
    if (links[0] !== undefined && links[0]['source'] !== undefined && links[0]['target'] !== undefined) {
        // ...
    } else {
        links.forEach(function (elem) {
            elem['source'] = elem['startId'];
            elem['target'] = elem['endId'];
        });
    }
}

/**
 * @desc 必填项判断
*/
function jugdeParam(nodes, links, set) {

    // 数据过滤
    setSourceTarget(links);

    var linkKey = set.linkKey,
        center = set.center;

    if (!linkKey) {
        set.linkKey = 'id';
    }
    if (!center) {
        console.error('center 必填!');
        return false;
    }
    if (!nodes) {
        console.error('nodes 必填!');
        return false;
    }
    if (!links) {
        console.error('links 必填!');
        return false;
    }
    return true;
}

/**
 * @desc 默认的力导向图
 * @param {array} nodes 
 * @param {array} links
 * @param {object} set 
 * distance 点之间的距离，可以是个回调函数
 * distanceMin 点之间的距离，可以是个回调函数
 * distanceMax 点之间的距离，可以是个回调函数
 * strength 设置强度参数，可以为负值也可以为正值，分别表示不同的力学类型，默认为 -30
 * linkKey 连线的关系key, 默认是 id
 * center 作用力坐标 [x, y]
 * rkey 半径的key，默认是 'r'
 * interval 间隙
 * 
*/
function defaultForce(nodes, links, set) {

    if (!jugdeParam(nodes, links, set)) {
        return;
    }

    var distanceMin = set.distanceMin,
        distanceMax = set.distanceMax,
        distance = set.distance,
        linkKey = set.linkKey,
        center = set.center,
        rkey = set.rkey,
        interval = set.interval,
        strength = set.strength;

    // 初始化力算法

    var simulation = force.forceSimulation();

    // .nodes 的时候 调用了 initialize(nodes)  
    simulation.nodes(nodes);

    // 重力，布局有一个参考位置，不会跑偏
    simulation.force('center', force.forceCenter(center[0], center[1]));

    // simulation.force('x', force.forceX());
    // simulation.force('y', force.forceY());

    // 设置连线
    var forceLink = force.forceLink();
    simulation.force('link', forceLink.id(function (d) {
        return d[linkKey];
    }));
    if (distance) {
        // 连线作用力 , d3.forceLink可以为每个连接单独指定作用力
        if (_lodash2.default.isFunction(distance)) {
            simulation.force('link', forceLink.distance(function (d) {
                return distance(d);
            }));
        } else {
            simulation.force('link', forceLink.distance(distance));
        }
    }
    simulation.force('link').links(links);

    // 节点作用力
    var forceManyBody = force.forceManyBody();
    if (strength !== undefined) {
        forceManyBody = forceManyBody.strength(strength);
    }
    if (distanceMin !== undefined) {
        forceManyBody = forceManyBody.distanceMin(distanceMin);
    }
    if (distanceMax !== undefined) {
        forceManyBody = forceManyBody.distanceMax(distanceMax);
    }
    simulation.force('charge', forceManyBody);

    // 半径不碰撞
    var forceCollide = force.forceCollide();
    if (_lodash2.default.isFunction(interval)) {
        forceCollide.radius(function (d) {
            return interval(d);
        });
    } else if (interval !== undefined) {
        forceCollide.radius(interval);
    }
    if (rkey) {
        forceCollide.radius(function (d) {
            return d[rkey] || 30 + interval;
        });
    }
    simulation.force('charge2', forceCollide);

    simulation.reset = function (num) {
        if (strength !== undefined) {
            forceManyBody = forceManyBody.strength(strength);
        }
        if (distanceMin !== undefined) {
            forceManyBody = forceManyBody.distanceMin(distanceMin);
        }
        if (distanceMax !== undefined) {
            forceManyBody = forceManyBody.distanceMax(distanceMax);
        }
        simulation.force('charge', forceManyBody);

        // 重力，布局有一个参考位置，不会跑偏
        simulation.force('center', force.forceCenter(center[0], center[1]));

        if (_lodash2.default.isFunction(interval)) {
            forceCollide.radius(function (d) {
                return interval(d);
            });
        } else if (interval !== undefined) {
            forceCollide.radius(interval);
        }
        if (rkey) {
            simulation.force('charge2', force.forceCollide().radius(function (d) {
                return d[rkey] + (interval || 0);
            }));
        }
        if (interval !== undefined) {
            forceCollide.radius(interval);
        }

        simulation.alpha(num === undefined ? 1 : num);
        simulation.restart();
    };

    // 返回一个简易的 D3对象
    return simulation;
}

/**
 * @desc 碰撞力图形
 * @param {array} nodes 
 * @param {array} links 建议links传递 [] 数组，连线也看不清楚的
 * @param {object} set 
 * linkKey 连线的关系key
 * center 作用力坐标 [x, y]
 * interval 间隙
 * rkey 半径的key，默认是r
 */
function collideForce(nodes, links, set) {
    if (!jugdeParam(nodes, links, set)) {
        return;
    }
    var linkKey = set.linkKey,
        center = set.center,
        interval = set.interval,
        rkey = set.rkey;

    if (rkey === undefined) {
        rkey = rkey ? rkey : 'r';
    } else if (rkey === null) {
        rkey = null;
    }

    // 初始化力算法
    var simulation = force.forceSimulation();
    simulation.nodes(nodes);
    simulation.force('center', force.forceCenter(center[0], center[1]));

    // 碰撞力
    simulation.force('charge', force.forceCollide().radius(function (d) {
        return rkey ? d[rkey] + (interval || 0) : 0;
    }));

    // 设置连线
    simulation.force('link', force.forceLink().id(function (d) {
        return d[linkKey];
    }));
    simulation.force('link').links(links);

    simulation.reset = function (num) {
        simulation.force('center', force.forceCenter(center[0], center[1]));
        simulation.force('charge', force.forceCollide().radius(function (d) {
            return rkey ? d[rkey] + (interval || 0) : 0;
        }));
        simulation.alpha(num === undefined ? 1 : num);
        simulation.restart();
    };

    // 返回一个d3对象
    return simulation;
}