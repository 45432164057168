import { API } from 'constants/enum';
import request from 'utils/fetch';

/**
 * @description 获取融资信息列表
 * @param {*} code
 */
export function getFinancingList({ code, pageNum, pageSize }) {
    // 获取上市奖励首页的数据
    return request(
        `${API.V1}/policypractice/companyReport/finance/showFinancing`,
        {
            code,
            pageNum,
            pageSize
        },
        {
            method: 'GET'
        }
    );
}

/**
 * @description 添加融资信息
 * @param {*} code
 * @param {*} date
 * @param {*} financingMoney
 * @param {*} financingRotation
 * @param {*} investors
 * @param {*} valuation
 */
export function addFinancing(
    code,
    date,
    financingMoney,
    financingRotation,
    investors,
    valuation
) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/addFinancing`,
        {
            code,
            date,
            financingMoney,
            financingRotation,
            investors,
            valuation
        },
        {
            method: 'POST'
        }
    );
}

/**
 * @description 删除融资信息
 * @param {*} id
 */
export function deleteFinancing(id) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/delFinancing`,
        {
            id
        },
        {
            method: 'GET'
        }
    );
}

/**
 * @description 编辑融资信息
 * @param code
 * @param date
 * @param financingMoney
 * @param financingRotation
 * @param id
 * @param investors
 * @param valuation
 * @param unionId
 */
export function updateFinancing(
    code,
    date,
    financingMoney,
    financingRotation,
    id,
    investors,
    valuation,
    unionId
) {
    return request(
        `${API.V1}/policypractice/companyReport/finance/editFinancing`,
        {
            code,
            date,
            financingMoney,
            financingRotation,
            id,
            investors,
            valuation, unionId
        },
        {
            method: 'POST'
        }
    );
}
