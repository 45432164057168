import { makeReducer } from '../../../utils/utils';
import * as Types from './types';

export const reducer = {
    [Types.UPDATE_NOTIFICATION](state, action) {
        return {
            ...state,
            noUnreadMessage: action.payload
        };
    }
};

export default makeReducer(reducer);
