import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc 分支机构信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function queryFzjg({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/intermediary/queryFzjg`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc 基础信息展示
 * @param qyxxId
 * @param companyName
 */
export function queryJbxx(qyxxId, companyName) {
    return request(`${API.V1}/investbank/intermediary/queryJbxx`, {
        qyxxId,
        companyName
    });
}

/**
 * @desc 工商变更信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function queryBgxx({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/intermediary/queryBgxx`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc 查询联系方式
 * @param qyxxId
 */
export function queryContact({ qyxxId }) {
    return request(`${API.V1}/investbank/intermediary/queryContact`, {
        qyxxId
    });
}

/**
 * @desc 股东信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function queryGdxx({ companyName, pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/intermediary/queryGdxx`, {
        companyName,
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc  机构团队成员查询
 * @param agency
 */
export function queryTeamMembers({ agency }) {
    return request(`${API.V1}/investbank/intermediary/queryAgencyTeam`, {
        agency
    });
}

/**
 * @desc 上市过会公司数量
 * @param agency
 */
export function queryGhCompanyCount(agency) {
    return request(`${API.V1}/investbank/intermediary/queryGhCompanyCount`, {
        agency
    });
}

/**
 * @desc  板块分布
 * @param agency
 */
export function queryAgencyServicePlate(agency) {
    return request(
        `${API.V1}/investbank/intermediary/queryAgencyServicePlateFb`,
        {
            agency
        }
    );
}

/**
 * @desc  行业分布
 * @param agency
 */
export function queryAgencyServiceIndustryFb(agency) {
    return request(
        `${API.V1}/investbank/intermediary/queryAgencyServiceIndustryFb`,
        {
            agency
        }
    );
}

/**
 * @desc 历史服务公司情况
 * @param industry
 * @param plate
 * @param agency
 * @param pageNum
 * @param pageSize
 * @returns {*}
 */
export function queryAgencyService({
    industry,
    plate,
    agency,
    pageNum,
    pageSize
}) {
    return request(`${API.V1}/investbank/intermediary/queryAgencyService`, {
        agency,
        pageNum,
        pageSize,
        industry,
        plate
    });
}
