import React from 'react';
import YearPicker from '../yearPicker';
import moment from 'moment';
export default function GroupYearPicker({ onChange, value }) {
    function handleChange(index) {
        return function(year) {
            value[index] = year;
            onChange(value);
        };
    }

    return (
        <div>
            <YearPicker
                value={value[0] && moment(value[0], 'YYYY')}
                onChange={handleChange(0)}
            />
            &nbsp;&nbsp; 至 &nbsp;&nbsp;
            <YearPicker
                value={value[1] && moment(value[1], 'YYYY')}
                onChange={handleChange(1)}
            />
        </div>
    );
}
