import React, { useState } from 'react';
import Table from '../../../common/tableEx';
import { PunishColumns } from './columns';
import { queryExpertRiskPunish } from '../service';
import Relation from './relation';
import Title from '../../../common/title';

export default function Punish(props) {
  const [query] = useState({
    expert: props.detail.expertTeam,
    companyName: props.detail.firmName
  });
  return (
    <div style={{background:'white',padding:'8px 16px'}}>
      <Title level={2} text="处罚记录" />{' '}
      <Table
        columns={PunishColumns}
        query={query}
        getData={queryExpertRiskPunish}
        parseData={res => (res && res.data ? res.data : [])}
      />
    </div>
  );
}
