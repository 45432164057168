import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

export function getIntermediaryMatch({ creditCode }) {
    // 获取上市奖励首页的数据
    return request(`${API.V1}/investbank/company/match`, {
        creditCode
    });
}

/**
 * @desc 修改企业标签
 */
export function changeCompanyLabel(qyxxId, status) {
    return request(
        `${API.V1}/auth/company/mgr/update`,
        {
            qyxxId,
            status
        },
        {
            method: 'post'
        }
    );
}

/**
 * @desc 下载重点企业分析报告
 * @param companyId
 * @param companyName
 * @param year
 */
export function downloadReport({ companyId, companyName, year }) {
    return request(
        `${API.V1}/auth/report/breed/upload`,
        { companyId, companyName, year },
        {
            method: 'post'
        }
    );
}
