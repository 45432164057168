/**
 * @author Ray
 * @desc 入库企业分析-智能中介匹配
 */

import React, { Fragment, useContext, useState, useEffect } from 'react';
import Title from '../../../../common/title';
import Sponsor from './sponsor';
import AccountingFirm from './accountingFirm';
import LawFirm from './lawFirm';
import { Divider, Empty } from 'antd';
import { getIntermediaryMatch } from './service';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

const Elements = [Sponsor, AccountingFirm, LawFirm];

const titles = ['保荐机构', '会计师事务所', '律师事务所'];

export default function(props) {
    const gs = useContext(GlobalContext);
    const [data, setData] = useState({});
    const [isOwnedCompany] = useState(
        props.match.params.companyName === gs.user?.organization
    );

    useEffect(() => {
        getIntermediaryMatch({
            creditCode: props.match.params.companyCode
        }).then(res => {
            setData(res.data);
        });
    }, []);

    return (
        <>
            <Title level={1} text="中介机构匹配" />
            <Divider />
            {((gs.isAdvanceUser || gs.isVIPUser) && isOwnedCompany) ||
            (gs.isIntermediary && gs.isVIPUser) ||
            gs.isGOV ||
            gs.isQiYuan ||
            gs.isTradeAssociations ||
            gs.isFundManager ? (
                Elements.map((Element, index) => (
                    <Fragment key={index}>
                        <Title level={3} text={titles[index]} />
                        <Element {...props} dataSource={data} />
                    </Fragment>
                ))
            ) : (
                <Empty description="请升级用户" />
            )}
        </>
    );
}
