import React, { useContext, useState } from 'react';
import { message, Tooltip, Typography } from 'antd';
import styles from './styles.scss';
import SearchBar from './searchBar';
import Table from '../../../common/tableEx';
import { callPolicyReportList } from './service';
import { limitString } from '../../../utils/utils';
import { REPORT_TYPE, TimeFormat } from '../../../constants/enum';
import moment from 'moment';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { callPolicyReportDetail } from '../policyDetail/service';
import { Permission } from '../../../hooks/useAuth';

export default function(props) {
  const gs = useContext(GlobalContext);
  const [query, setQuery] = useState(null);

  function getColumns(checkAuth) {
    return [
      {
        key: 0,
        title: '标题',
        dataIndex: 'title',
        align: 'center',
        render: (text, record) => (
          <Tooltip title={text}>
            <a onClick={checkAuth(record)}>{limitString(25, text)}</a>
          </Tooltip>
        ),
        width: '20%'
      },
      {
        key: 1,
        title: '简介',
        dataIndex: 'introduce',
        align: 'center',
        width: '15%',
        render: text => <Tooltip title={text}>{limitString(25, text)}</Tooltip>
      },
      {
        key: 2,
        title: '标签',
        dataIndex: 'label',
        align: 'center',
        width: '15%',
        render: text => <Tooltip title={text}>{limitString(25, text)}</Tooltip>
      },
      {
        key: 3,
        title: '作者',
        dataIndex: 'author',
        align: 'center',
        width: '15%',
        render: text => <Tooltip title={text}>{limitString(25, text)}</Tooltip>
      },
      {
        key: 4,
        title: '报告类型',
        dataIndex: 'type',
        align: 'center',
        render: text => REPORT_TYPE.filter(r => r.value === text)[0]?.text,
        width: '15%'
      },
      {
        key: 5,
        title: '报告日期',
        dataIndex: 'reportDate',
        align: 'center',
        render: text => moment(text, 'x').format(TimeFormat),
        width: '20%'
      }
    ];
  }

  function checkAuth(record) {
    return function() {
      callPolicyReportDetail(record.id, gs.user?.level).then(res => {
        if (!res.success) {
          message.error(res.message);
        } else {
          props.history.push({
            pathname: `/investment/policyReport/detail/${record.id}`
          });
        }
      });
    };
  }

  return (
    <Permission permission={494}>
      <div className={styles.root}>
        <Typography.Title level={3}>行业政策研究报告</Typography.Title>
        <SearchBar onChange={query => setQuery(query)} />
        <br />
        <Table
          query={query}
          columns={getColumns(checkAuth)}
          parseData={d => (d ? d.data : [])}
          getData={callPolicyReportList}
        />
      </div>
    </Permission>
  );
}
