/**
 * @author Ray
 * @desc 中介机构-社会责任评价-企业纾困
 */

import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState
} from 'react';
import Table from '../../../../common/tableEx';
import { bailoutColumns } from './columns';
import { EditButton, DeleteButton } from '../../../../common/buttons';
import { Button, Empty, Form, Input, InputNumber } from 'antd';
import { GreaterThanZro, Required } from '../../../../utils/validator';
import Title from '../../../../common/title';
import { addEditRelief, delRelief, showRelief } from './service';
import { isEmpty, isNotEmpty } from '../../../../utils/utils';
import useActions from './actionHook';
import { GlobalContext } from '../../../../common/globalStore/createProvider';

export function BailoutForm({ form }, ref) {
    useImperativeHandle(ref, () => ({
        form
    }));
    return (
        <Form labelCol={{ xs: 6 }} wrapperCol={{ xs: 15 }}>
            <Form.Item label="参与纾困项目">
                {form.getFieldDecorator('joinReliefProject', {
                    rules: [GreaterThanZro, Required]
                })(
                    <Input.TextArea
                        autosize={{
                            maxRows: 6,
                            minRows: 4
                        }}
                        maxLength={500}
                        style={{ width: '100%' }}
                    />
                )}
            </Form.Item>
            <Form.Item label="出资额">
                {form.getFieldDecorator('pushMoney', {
                    rules: [GreaterThanZro, Required]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
            <Form.Item label="出资比例">
                {form.getFieldDecorator('pushMoneyRatio', {
                    rules: [GreaterThanZro, Required]
                })(<InputNumber maxLength={9} />)}
            </Form.Item>
        </Form>
    );
}

export default function BailoutParticipation({ dispatch, match }) {
    const [showList, setShowList] = useState(true);
    const gs = useContext(GlobalContext);
    const [isOwnCompany] = useState(
        gs.user?.organization === match.params.companyName
    );
    const [addRecord, editRecord, delRecord, , query] = useActions({
        dispatch,
        query: { companyName: match.params.companyName, type: 1 },
        update: addEditRelief,
        remove: delRelief,
        create: addEditRelief,
        form: forwardRef(BailoutForm),
        callback: () => {
            setShowList(true);
        }
    });

    function parseData(res) {
        if (isNotEmpty(res)) {
            if (isEmpty(res.data)) {
                setShowList(false);
                return [];
            } else {
                setShowList(true);
                return res.data;
            }
        }
        return [];
    }

    return (
        <>
            <Title level={2} text="企业纾困参与">
                {isOwnCompany && (
                    <>
                        <p style={{ flex: 1 }} />
                        <Button
                            type="primary"
                            onClick={addRecord('添加企业纾困记录')}
                        >
                            新增
                        </Button>
                    </>
                )}
            </Title>
            {showList ? (
                <Table
                    query={query}
                    columns={
                        isOwnCompany
                            ? bailoutColumns.concat({
                                  title: '',
                                  align: 'center',
                                  render: (text, record) => (
                                      <>
                                          <EditButton
                                              text="编辑"
                                              onClick={editRecord(record)(
                                                  '编辑企业纾困记录'
                                              )}
                                          />
                                          <DeleteButton
                                              onClick={delRecord(record)(
                                                  '删除企业纾困记录'
                                              )}
                                          />
                                      </>
                                  )
                              })
                            : bailoutColumns
                    }
                    getData={showRelief}
                    parseData={parseData}
                    showPagination={10}
                />
            ) : (
                <Empty description="该中介机构暂未维护自身社会责任评价" />
            )}
        </>
    );
}
