/**
 * @desc 挂牌公司总览title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line2Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line2Title }>
                挂牌公司总览
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}