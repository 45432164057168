import fetch from '../../utils/fetch';
import { API } from '../../constants/enum';
/**
 * @desc 获取地图数据
 * @param mapName
 */
export default function getLocalMap(mapName) {
  return fetch(
    `/data/map/${mapName}.json`,
    undefined,
    undefined,
    undefined,
    true
  );
}

export function authRedOverview(area) {
  return fetch(`${API.V1}/auth/red/overview`, { area });
}

// 科创钻石评分
export function authDiamondEvaluate(area) {
  return fetch(`${API.V1}/auth/diamond/evaluate`, { area });
}
// 最新五家上市企业
export function authDiamondNewFive(area) {
  return fetch(`${API.V1}/auth/diamond/new/five`, { area });
}
// 申报企业数量趋势
export function authDiamondTrend(area) {
  return fetch(`${API.V1}/auth/diamond/trend`, { area });
}

// 全国重点企业TOP5
export function authDiamondTopFive(area) {
  return fetch(`${API.V1}/auth/diamond/top/five`, { area });
}

// 科创板企业申报状态
export function authDiamondStatus(area) {
  return fetch(`${API.V1}/auth/diamond/status`, { area });
}

// 获取全国地区接口
export function getAreas() {
  return fetch(`${API.V1}/auth/diamond/area`);
}
