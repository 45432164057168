/**
 * @author Ray
 * @description 政策落实数据模型层
 */
import React, { useContext, useEffect, useState } from 'react';
import { applyIndex } from './service';
import { isNotEmpty } from '../../utils/utils';
import { GlobalContext } from '../../common/globalStore/createProvider';

export default function Model({ children, ...rest }) {
    const { user } = useContext(GlobalContext);
    const [state, setState] = useState({
        companyName: '',
        contactPhone: '',
        email: '',
        plateDescription: '',
        sources: {}
    });
    /**
     * @description 申请
     * @memberof Policy
     */
    function apply(stage) {
        rest.history.push({
            pathname: rest.match.url + `/fillin/apply/${stage}`
        });
    }

    function applyList() {
        return applyIndex(user?.creditCode).then(res => {
            if (isNotEmpty(res) && res.success) {
                const {
                    companyName,
                    phone,
                    email,
                    plateDescription
                } = res.data;

                setState({
                    ...state,
                    companyName,
                    phone,
                    email,
                    plateDescription
                });
            }
        });
    }

    useEffect(() => {
        applyList();
    }, []);

    return children({ ...state, ...rest, apply });
}
