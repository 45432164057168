import { useEffect, useState } from 'react';
import moment from 'moment';

export default function useTimeRangerProps(readOnly) {
    const [query, setQuery] = useState({
        startYear: readOnly
            ? undefined
            : moment()
                  .add(-3, 'year')
                  .year(),
        endYear: readOnly ? undefined : moment().add(-1, 'years').year()
    });

    useEffect(function() {}, []);

    return [query, setQuery];
}
