'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _sign = require('babel-runtime/core-js/math/sign');

var _sign2 = _interopRequireDefault(_sign);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

exports.hasLngLat = hasLngLat;
exports.parseAddress = parseAddress;
exports.getPixel = getPixel;
exports.getNoLocationPixel = getNoLocationPixel;
exports.isInContainer = isInContainer;
exports.setFitView = setFitView;
exports.rePaint = rePaint;
exports.setNodes = setNodes;
exports.setLinks = setLinks;
exports.dealNoLonLat = dealNoLonLat;
exports.pushInLinks = pushInLinks;
exports.hiddenLine = hiddenLine;

var _common = require('../../../util/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 判断是否有经纬度
 */
function hasLngLat(local) {
    return local.gisLon && local.gisLat;
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 根据地址解析成经纬度
 */
function parseAddress(local, AMap) {
    var geocoder = new AMap.Geocoder({
        radius: 1000
    });
    if (local.address) {
        return new _promise2.default(function (resolve) {
            if (!local.gisLon || !local.gisLat) {
                geocoder.getLocation(local.address, function (status, result) {
                    // 解析完成
                    if (status === 'complete' && result.info === 'OK') {
                        var geocode = result.geocodes;
                        resolve({ local: local, result: geocode[0] });
                    } else {
                        resolve({ local: local });
                    }
                });
            } else {
                resolve({
                    local: local,
                    result: {
                        location: {
                            lng: local.gisLon,
                            lat: local.gisLat
                        }
                    }
                });
            }
        });
    }
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 根据经纬度解析成xy坐标
 */
function getPixel(local, map, AMap) {
    var lngLat = new (Function.prototype.bind.apply(AMap.LngLat, [null].concat((0, _toConsumableArray3.default)(local))))();
    return map.lngLatToContainer(lngLat); // 返回{x: -- , y: --};
}

function getNoLocationPixel(center) {
    var agree = Math.random() * 2 * Math.PI;
    var l = center.r * Math.random();
    var x = center.x + l * Math.cos(agree);
    var y = center.y + l * (0, _sign2.default)(agree);
    return { x: x, y: y };
}

function isInContainer(position, container) {
    return position[0] > 0 && position[0] < container.width && position[1] > 0 && position[1] < container.height;
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 将所有的节点适应最佳视野
 */
function setFitView(nodes, map, AMap) {
    Array.isArray(nodes) && nodes.forEach(function (node) {
        var data = node.data;
        if (data.gisLat && data.gisLon) {
            new AMap.Marker({
                map: map,
                position: [data.gisLon, data.gisLat]
            });
        }
    });
    map.setFitView();
    map.clearMap();
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 重绘
 */
function rePaint() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var nodes = state.nodes,
        links = state.links;

    if (state.nodes) {
        state.nodes = setNodes.call(this, nodes);
        state.links = setLinks.call(this, links);
    }
    this.setState(state);
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 处理节点：（1）有经纬度；（2）没有经纬度的但是有地址的；（3）没有经纬度也没有地址
 * 无法定位的点画在[-1, -1]上
 */
function setNodes(nodes) {
    var _this = this;

    var displayNodes = [];
    var pixel = void 0;
    var notlocalNodeNum = 0;
    this.noLonLatNodeNum = 0;
    var ids = [];
    Array.isArray(nodes) && nodes.forEach(function (nodeOpts) {
        ids.push(nodeOpts.id);
        var data = nodeOpts.data;
        // 有经纬度而且必须在layer范围内
        if (hasLngLat(data)) {
            data.lngLat = [data.gisLon, data.gisLat];
            pixel = getPixel(data.lngLat, _this.map, _this.AMap); // 获取x, y坐标
            if (pixel.x && pixel.y) {
                nodeOpts.position = [pixel.x, pixel.y];
                displayNodes.push(nodeOpts);
                _this.displayNodesMap.set(nodeOpts.id, nodeOpts); // 放进可定位的node集合
            } else {
                console.log(data);
            }
        } else if (data.address !== '-' && !(0, _common.isEmpty)(data.address)) {
            //  有地址的点
            // 没有经纬度，但是有地址，进行解析
            dealNoLonLat.call(_this, nodeOpts);
            _this.noLonLatNodeNum++;
        } else {
            // 没有经纬度，也没有地址
            notlocalNodeNum++;
            nodeOpts.position = [-1, -1];
            displayNodes.push(nodeOpts);
            _this.displayNodesMap.set(nodeOpts.id, nodeOpts);
        }
    });
    // 当把所有点都定位在图谱上后触发的事件
    if (this.noLonLatNodeNum === 0) {
        this.handler.trigger('chart.getSettingStatus');
        if ((0, _common.isNotEmpty)(ids)) {
            this.handler.trigger('chart.getSettingList', ids);
        }
    }
    return displayNodes;
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 根据节点确定线
 */
function setLinks(links) {
    var _this2 = this;

    var displayLinks = [];
    this.noPositionLinks = [];
    Array.isArray(links) && links.forEach(function (v, i) {
        var startPoint = _this2.displayNodesMap.get(v.from);
        var endPoint = _this2.displayNodesMap.get(v.to);
        if (startPoint && endPoint) {
            var startPosition = startPoint.position;
            var endPosition = endPoint.position;
            // 隐藏不能定位的线
            hiddenLine(v, startPosition, endPosition);
            displayLinks.push(v);
        } else {
            _this2.noPositionLinks.push(v);
        }
    });
    return displayLinks;
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 处理没有经纬度但有地址
 */
function dealNoLonLat(nodeOpts) {
    var _this3 = this;

    var notlocalNodeNum = 0;
    var pixel = void 0;
    var data = nodeOpts.data;
    parseAddress(data, this.AMap).then(function (values) {
        var state = _this3.getState();
        var result = values.result;
        // 能解析出经纬度的点
        if ((0, _common.isNotEmpty)(result)) {
            var lng = result.location.lng;
            var lat = result.location.lat;
            (0, _assign2.default)(data, {
                gisLon: lng,
                gisLat: lat,
                lngLat: [lng, lat]
            });
            pixel = getPixel(data.lngLat, _this3.map, _this3.AMap);
            if (pixel.x && pixel.y) {
                nodeOpts.position = [pixel.x, pixel.y];
                state.nodes.push(nodeOpts);
                _this3.displayNodesMap.set(nodeOpts.id, nodeOpts);
                // 解析出坐标后把没定位线定位
                pushInLinks.call(_this3, nodeOpts);
                _this3.setState(state);
            }
        } else {
            // 存入无法解析的地址, 画在画布外边
            nodeOpts.position = [-1, -1];
            state.nodes.push(nodeOpts);
            _this3.displayNodesMap.set(nodeOpts.id, nodeOpts);
            pushInLinks.call(_this3, nodeOpts);
            _this3.setState(state);
            notlocalNodeNum++;
        }
        // 没有定位的数量减一
        _this3.noLonLatNodeNum--;
        if (_this3.noLonLatNodeNum === 0) {
            _this3.handler.trigger('chart.getSettingStatus');
            // 去获取attention，和remark

            var _getState = _this3.getState(),
                nodes = _getState.nodes;

            var ids = [];
            nodes.forEach(function (node) {
                ids.push(node.id);
            });
            if ((0, _common.isNotEmpty)(ids)) {
                _this3.handler.trigger('chart.getSettingList', ids);
            }
        }
    });
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 将没有定位的线循环，把通过异步已经定位的点重新将线定位
 */
function pushInLinks(nodeOpts) {
    var _this4 = this;

    // 轮询在links中没有经纬度
    var state = this.getState();
    this.noPositionLinks.forEach(function (link, index) {
        // 如果节点企业ID等于from，且from的position不存在，将position赋值给from
        if (link.from === nodeOpts.id && _this4.displayNodesMap.get(link.to)) {
            // 如果toNode的position存在，放进state中
            hiddenLine(link, nodeOpts.position, _this4.displayNodesMap.get(link.to).position);
            state.links.push(link);
            _this4.noPositionLinks.splice(index, 1);
        }
        // 如果节点企业ID等于to，且to的position不存在，将position赋值给to
        if (link.to === nodeOpts.id && _this4.displayNodesMap.get(link.from)) {
            hiddenLine(link, _this4.displayNodesMap.get(link.from).position, nodeOpts.position);
            state.links.push(link);
            _this4.noPositionLinks.splice(index, 1);
        }
    });
    // if(!this.noPositionLinks.length) {
    //     this.handler.trigger('chart.getSettingStatus');
    // }
}

/**
 *@author: TurboLoong
 *@date: 2018/2/5
 *@des: 隐藏线
 */
function hiddenLine(line, startPosition, endPosition) {
    if (startPosition[0] === -1 && startPosition[1] === -1 || endPosition[0] === -1 && endPosition[1] === -1) {
        line.hidden = true;
    }
}