import {useState, useEffect} from 'react';
import {getBannerData } from './service';
import { getCourse, getColumn, enrollCourse, enrollColumn } from '../service';
import { StatusCode } from 'constants/enum';
import { CATEGORY } from 'constants/common';
import { Modal } from 'antd';

export default function (props) {
    const [bannerData, setBanner] = useState(null);
    const [courseList, setCourseList] = useState(null);
    const [columnList, setColumnList] = useState(null);

    useEffect(() => {
        getBanner();
        getCourseList();
        getColumnList();
    }, [])

    function getBanner(){
        getBannerData().then( res =>{
            const { statusCode, data = [] } = res;
            if( statusCode === StatusCode.success ){
                setBanner( data );
            }
        })
    }

    function getCourseList(){
        getCourse({
            pageNum: 1,
            pageSize: 6,
            showPriority: 2,
            status: 1
        }).then(res =>{
            const { statusCode, data } = res;
            if( statusCode === StatusCode.success ){
                setCourseList( data.data);
            }
        })
    }

    function getColumnList(){
        getColumn({ 
            pageNum: 1,
            pageSize: 6,
            showPriority: 2,
            status: 1
        }).then(res =>{
            const { statusCode, data } = res;
            if( statusCode === StatusCode.success ){
                setColumnList( data.data);
            }
        })
    }

    function goEnroll(id, isEnroll, type){
        let func = type === CATEGORY.Course ? enrollCourse : enrollColumn;
        func({
            id,
            status: isEnroll
        }).then( res => {
            if( res.statusCode === StatusCode.success ){
                type === CATEGORY.Course ? getCourseList() : getColumnList();
            }else{
                Modal.error({
                    title: '提示',
                    okText: '确定',
                    content: res.message
                });
            }
        })
    }

    return [bannerData, courseList, columnList, goEnroll ];
}