/**
 * @author Ray
 */

import React, { useContext } from 'react';
import {
    updateUser as callUpdateUser,
    updatePassword as callUpdatePassword,
    updatePhone as callUpdatePhone,
    resignUser as callResignUser,
    getLoginUserInfo,
    updatePasswordThreeMonths as callUpdatePasswordThreeMonths
} from './services';
import { message } from 'antd';
import { LOGIN } from '../router';
import { GlobalContext } from '../../common/globalStore/createProvider';

export default function Model({ children, ...props }) {
    const { setUser, setPasscodeExpired } = useContext(GlobalContext);

    function updateUser({
        creditCode, // 组织机构代码或统一社会信用代码
        email, // 邮箱
        organization, // 公司名称
        realName, // 姓名
        code,
        emailCode,
        avatar,
        bbdQyxxId
    }) {
        return callUpdateUser({
            creditCode,
            email,
            organization,
            realName,
            code,
            emailCode,
            avatar,
            bbdQyxxId
        }).then(res => {
            if (res.success) {
                message.success(res.message);
                getLoginUserInfo().then(res => setUser(res.data));
            } else {
                message.error(res.message);
            }

            return res.success;
        });
    }

    function updatePassword({ confirmPassword, newPassword, oldPassword }) {
        return callUpdatePassword(
            confirmPassword,
            newPassword,
            oldPassword
        ).then(res => {
            if (res.success) {
                setUser(null);
                props.history.push(LOGIN);
                message.success('密码修改成功，请重新登录！');
            } else {
                message.error(res.message);
            }
            return res.success;
        });
    }

    function updatePasswordThreeMonths({ confirmPassword, newPassword, oldPassword, userName }) {
        return callUpdatePasswordThreeMonths(
            {
                confirmPassword,
                newPassword,
                oldPassword,
                userName
            }
        ).then(res => {
            if (res.success) {
                setUser(null);
                setPasscodeExpired(false);
                props.history.push(LOGIN);
                message.success('密码修改成功，请重新登录！');
            } else {
                message.error(res.message);
            }
        });
    }

    function updatePhoneNumber({ imgCaptcha, password, phone, smsCode }) {
        return callUpdatePhone(imgCaptcha, password, phone, smsCode).then(
            res => {
                if (res.success) {
                    getLoginUserInfo().then(res => setUser(res.data));
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }

                return res.success;
            }
        );
    }

    function resignUser({ imgCaptcha, password, phoneCaptcha }) {
        return callResignUser(imgCaptcha, password, phoneCaptcha).then(res => {
            if (res.success) {
                setUser(null);
                props.history.push(LOGIN);
                message.success('账户注销成功！');
            } else {
                message.error(res.message);
            }
            return res.success;
        });
    }

    return children({
        ...props,
        updateUser,
        updatePassword,
        updatePhoneNumber,
        resignUser,
        updatePasswordThreeMonths
    });
}
