/**
 * @author hjie
 * 讲师介绍
 */

import React from 'react';
import styles from './styles.scss';


export default function({ data = [] }) {
    return (
        <div className = {styles.text}>
            {
                data && data.map( ele => {
                    const { name, descp, job, company, id } = ele;
                    return <div className = {styles.lecturersItem } key = {id}>
                            讲师姓名：{name} <br/>
                            所属机构：{company || '- -'}<br/>
                            所任职位：{job || '- -' }<br/>
                            个人介绍：{ descp || '- -'}
                            </div>
                })
            }
        </div>
    );
}
