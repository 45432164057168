import arrows1 from './images/arrows1.svg';
import arrows2 from './images/arrows2.svg';

export const legendList = [
    { name: '企业', icon: '/company' },
    // { name: '企业（高危预警）', icon: '/riskCompany' },
    { name: '企业（吊销/注销）', icon: '/revokeCompany' },
    // { name: '企业（黑名单）', icon: '/blackCompany' },
    { name: '自然人', icon: '/person' },
    { name: '投资关系', icon: '/arrows1', img: arrows1, key: 'invest' },
    { name: '管理关系', icon: '/arrows2', img: arrows2, key: 'notInvest'  }
];

export function filterInvest(state) {
    const {links} = state;
    let lineJson = {}, nodeJson = {};
    links.forEach(ele => {
        if(ele.data.labels.indexOf('Invest') !== -1){
            nodeJson[ele.from] = true;
            nodeJson[ele.to] = true;
            lineJson[ele.id] = true;
        }
    });
    return { nodes: nodeJson, links: lineJson};
}
export function filterNotInvest(state) {
    const {links} = state;
    let lineJson = {}, nodeJson = {};
    links.forEach(ele => {
        if(ele.data.labels.indexOf('Invest') === -1){
            nodeJson[ele.from] = true;
            nodeJson[ele.to] = true;
            lineJson[ele.id] = true;
        }
    });
    return { nodes: nodeJson, links: lineJson};
}