/**
 * @author Ray
 * @desc 交易条件与受让方资格条件
 */

import React, { useEffect, useState } from 'react';
import { InputNumber, Input, Form, DatePicker } from 'antd';
import { Selector } from '../home/apply';
import styles from './styles.scss';
import { PositiveFloat } from '../../../utils/validator';
import { TRUE_FALSE } from '../../../constants/enum';
import Text, {
    DatePreview,
    SelectorPreview,
    TextareaPreview
} from '../../../common/text';
import { moneyFormatter } from '../../../utils/utils';

const { Item } = Form;

function TransactionInfo(props, ref) {
    const { form, onInit, isPreview } = props;
    const { getFieldDecorator } = form;

    const [isMargin, setMargin] = useState(true);

    useEffect(function() {
        onInit(form);
    }, []);

    return (
        <Form
            labelCol={{ xs: 8 }}
            wrapperCol={{ xs: 16 }}
            className={styles.transaction}
        >
            <Item label="转让底价(万元)">
                {getFieldDecorator('transferBottomPrice', {
                    rules: [PositiveFloat]
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            disabled={isPreview}
                            placeholder="请输入"
                            maxLength={15}
                        />
                    )
                )}
            </Item>
            <Item label="价款支付方式">
                {getFieldDecorator('paymentMethod', {
                    rules: []
                })(
                    isPreview ? (
                        <Text />
                    ) : (
                        <Input
                            disabled={isPreview}
                            placeholder="请输入"
                            maxLength={50}
                        />
                    )
                )}
            </Item>
            <Item label="与转让相关的其他条件">
                {getFieldDecorator('transferOtherConditions', {
                    rules: []
                })(
                    isPreview ? (
                        <TextareaPreview />
                    ) : (
                        <Input.TextArea
                            disabled={isPreview}
                            placeholder="请输入"
                            maxLength={500}
                            autosize={{
                                minRows: 4,
                                maxRows: 6
                            }}
                        />
                    )
                )}
            </Item>
            <Item label="受让方资格条件">
                {getFieldDecorator('transfereeQualification', {
                    rules: []
                })(
                    isPreview ? (
                        <TextareaPreview />
                    ) : (
                        <Input.TextArea
                            disabled={isPreview}
                            placeholder="请输入"
                            maxLength={500}
                            autosize={{
                                minRows: 4,
                                maxRows: 6
                            }}
                        />
                    )
                )}
            </Item>
            <Item label="缴纳保证金">
                {getFieldDecorator('margin', {
                    rules: []
                })(
                    isPreview ? (
                        <SelectorPreview options={TRUE_FALSE} />
                    ) : (
                        <Selector
                            disabled={isPreview}
                            options={TRUE_FALSE}
                            onChange={v => setMargin(Boolean(v))}
                        />
                    )
                )}
            </Item>
            <Item label="缴纳金额(万元)">
                {getFieldDecorator('paymentMarginAmount', {
                    rules: [PositiveFloat]
                })(
                    isPreview ? (
                        <Text formatter={moneyFormatter} suffix="万元" />
                    ) : (
                        <InputNumber
                            disabled={!isMargin}
                            placeholder="请输入"
                            maxLength={15}
                        />
                    )
                )}
            </Item>
            <Item label="缴纳时间">
                {getFieldDecorator('paymentTime', {
                    rules: []
                })(
                    isPreview ? (
                        <DatePreview />
                    ) : (
                        <DatePicker disabled={isPreview} placeholder="请选择" />
                    )
                )}
            </Item>
        </Form>
    );
}

export default function Transaction({ onChange, onInit, isPreview }) {
    const [E, setE] = useState(null);

    useEffect(function() {
        const Form_ = Form.create({
            name: 'transaction',
            onValuesChange: (a, b, allValues) => {
                if (
                    !allValues.margin &&
                    allValues.paymentMarginAmount != undefined
                ) {
                    a.form.setFieldsValue({
                        paymentMarginAmount: undefined
                    });
                    allValues.paymentMarginAmount = undefined;
                }
                onChange({ mergeQualification: { ...allValues } });
            }
        })(React.forwardRef(TransactionInfo));

        setE(<Form_ onInit={onInit} isPreview={isPreview} />);
    }, []);

    return E;
}
