/**
 * 专栏附件查看
 * @author hjie
 */

import React from 'react';
import BackBtn from 'common/backBtn';

export default function(props) {

    const {url} = props.match.params;
    return (
        <div>
            <BackBtn history={props.history} url={props.location.state.from} />
            <div style = {{padding: '20px 0'}}>
                <iframe height= "700" width="1200" frameBorder = "0" src = {`https://view.officeapps.live.com/op/view.aspx?src=${url}`}></iframe>
            </div>
        </div>
    );
}
