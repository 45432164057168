/**
 * @author Ray
 * @desc 中介机构-监管公告
 */

import React, { useState } from 'react';
import { regulatoryColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { queryRiskPunish } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import Title from '../../../../common/title';
import { Divider } from 'antd';

export default function RegulatoryAnnounce({ match, zjjgType }) {
    const [query] = useState({
        qyxxId: match.params.companyId
    });
    function mockData(res) {
        return isNotEmpty(res) ? res : [];
    }

    return zjjgType !== '律师事务所' ? (
        <>
            <Title level={2} text="合规性" />
            <Divider />
            <Title level={3} text="监管公告" />
            <TableEx
                query={query}
                parseData={mockData}
                getData={queryRiskPunish}
                columns={regulatoryColumns}
                locale={{emptyText:`未发现企业的监管公告记录。不排除存在未公示或时间相对后滞的情况`}}
            />
        </>
    ) : null;
}
