/**
 * @author Ray
 */
import request from 'utils/fetch';
import { API } from 'constants/enum';

export function getProcessList(id) {
    return request(`${API.V1}/policypractice/apply/nodeShow`, {
        id
    });
}
