import React, { useState, useEffect } from 'react';
import LeftFirst from './IPODeclarationLine';

/**
 * @desc  IPO申报情况
 */
export default function LeftFirstIndex({ address, style }) {
  return (
    <div>
        <LeftFirst address={address} />
    </div>
  );
}
