'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _math = require('../../../util/math');

var math = _interopRequireWildcard(_math);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var IndustryCircle = function IndustryCircle() {
    var _this = this;

    (0, _classCallCheck3.default)(this, IndustryCircle);
    this.nodeSize = {// 节点的数据：半径、面积...
        // r: 20,  半径
        // area,  面积
        // rectArea,  正方形面积
        // perimeter,  周长
        // gap,  间隙
        // gapRectArea  含间隙的正方形面积
    };
    this.nodeStyle = {
        fill: 'red'
    };
    this.roundCircle = {// 画圈层的固定参数
        // r,  节点半径，和 nodeSize 的 r 相等
        // minR,
        // addR,
        // arcLength
    };

    this._initNodeSize = function () {
        var r = 20; // 节点半径
        var area = math.circleArea(r);
        var rectArea = math.rectArea(2 * r);
        var gap = 20;
        var gapRectArea = math.rectArea(2 * (r + 10));
        _this.nodeSize = {
            r: r,
            area: area,
            rectArea: rectArea,
            perimeter: math.circlePerimeter(r),
            gap: gap,
            gapRectArea: gapRectArea
        };
        // 计算圈层的固定参数
        _this._getRoundCircleParams();
    };

    this._getRoundCircleParams = function () {
        var _nodeSize = _this.nodeSize,
            r = _nodeSize.r,
            gap = _nodeSize.gap;

        var minR = (r + gap) * 2; // 最小半径
        var roundCircleAngle = math.halfAngle(minR, r + gap); // 通过半径和间隙计算出第一圈开始的两个圆的夹角
        var arcLength = math.angleToArclength(roundCircleAngle, minR) * 2; // 通过角度计算出弧长
        _this.roundCircle = {
            r: r,
            minR: minR,
            addR: minR,
            arcLength: arcLength,
            full: true
        };
    };

    this._initNodeSize();
    this._getRoundCircleParams();
}
// 初始化节点数据

// 计算圈层的固定参数
; /**
   * Created by Tirion on 2017/12/6.
   */

exports.default = new IndustryCircle();
module.exports = exports['default'];