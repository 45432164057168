/**
 * @author Ray
 * @desc 中介评价-裁决信息
 */

import React, { useState } from 'react';
import { refereeColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { queryZgcpwsw } from './service';
import { isNotEmpty } from '../../../../utils/utils';
import Title from '../../../../common/title';

export default function Referee({ match, zjjgType }) {
    const [query] = useState({
        companyName: match.params.companyName,
        qyxxId: match.params.companyId
    });
    function mockData(res) {
        return isNotEmpty(res) ? res?.data : [];
    }

    return zjjgType !== '律师事务所' ? (
        <>
            <Title level={3} text="裁判文书" />
            <TableEx
                query={query}
                getData={queryZgcpwsw}
                parseData={mockData}
                columns={refereeColumns}
                locale={{emptyText:`未发现企业的裁判文书记录。不排除存在未公示或时间相对后滞的情况`}}
            />
        </>
    ) : null;
}
