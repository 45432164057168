import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc 获取地区分布数据
 * @param area
 */
export function getAreaDistribution(area) {
    return request(
        `${API.V1}/auth/red/distribution`,
        { area },
        undefined,
        undefined,
        true
    );
}


/**
 * @desc 获取企业状态分布数据
 * @param area
 */
export function getStatusDistribution({ area }) {
    return request(
        `${API.V1}/auth/diamond/distribution`,
        { area },
        undefined,
        undefined,
        true
    );
}

/**
 * @desc 获取风险平均分分布数据
*/
export function riskAverage(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/centerTop`, params)
}