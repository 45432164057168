/**
 * @author hjie
 */
import styles from './style.scss';
import React, {useContext} from 'react';
import home from '../images/home.png';
import login from '../images/login.png';
import ip from '../images/ip.png';
import { GlobalContext } from 'common/globalStore/createProvider';
import moment from 'moment';

export default function welcome({history}) {
    const { user } = useContext(GlobalContext);

    return (
        <div className={styles.welCome}>
            <div className = {styles.tips}>
                <img src = {home}/>
                <b>欢迎您，{user?.username || ''}</b>
            </div>
            <div className = {styles.info}>
                <span className = {styles.item}><img src = {login}/><span>上次登录：{ (user && user.lastLoginTime) ? moment(user.lastLoginTime ).format('YYYY年MM月DD日 HH:mm:ss') : '--'} </span></span>
                <span className = {styles.item}><img src = {ip}/><span>本次登录IP: {user?.ip || '--'} </span></span>
            </div>
            <div className = {styles.title}>北京企业上市综合服务平台</div>
            
        </div>
    );
}
