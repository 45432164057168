import styles from './styles.scss';
import React, { useEffect, useState } from 'react';
import { authDiamondTopFive } from '../service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
/**
 * @desc 重点企业TOP5
 * @returns {*}
 * @constructor
 */
export default function DiamondNum({ area, style }) {
  const [tableData, setData] = useState({ loading: true, data: [] });
  useEffect(() => {
    authDiamondTopFive(area).then(({ statusCode, data }) => {
      if (statusCode == 1000) {
        setData({ loading: false, data: data });
      } else {
        setData({ loading: false, data: [] });
      }
    });
  }, [area]);

  const { loading, data } = tableData;
  return (
    <div className={styles.diamondNum} style={{ ...style }}>
      <div className={styles.table}>
        {loading ? (
          ''
        ) : data && data.length ? (
          <table>
            <thead>
              <tr>
                <td>企业名</td>
                <td>融资金额(亿)</td>
                <td>当前状态</td>
              </tr>
            </thead>
            <tbody>
              {data.map(ele => {
                const { fullName } = ele;
                return (
                  <tr key={fullName}>
                    <td width="60%">
                      <Link
                        target="_blank"
                        to={
                          (ele.isList === 1)
                            ? `/riskMonitoring/detail/risk/${fullName}/${ele.bbdQyxxId}`
                            : `/investment/enterpriseAnalysis/${fullName}/${ele.bbdQyxxId}/${ele.creditCode}`
                        }
                      >
                        {fullName}
                      </Link>
                    </td>
                    <td width="20%">{parseFloat(ele.amount).toFixed(2)}</td>
                    <td width="20%">{ele.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
}
