/**
 * @author Ray
 * @description 利润表
 */

import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, message, Tabs } from 'antd';
import { FlexibleTable } from '../flexibleTable';
import { SaveButton } from '../buttons';
import styles from './styles.scss';
import { callFinance, submitProfitInfo } from './service';
import { isEmpty, isNotEmpty } from '../../utils/utils';
import { convertData } from './utils';
import useProps from './useTableProps';
import useTimeRangerProps from './useTimeRangerProps';
import { GlobalContext } from '../globalStore/createProvider';

/**
 *
 * @param readOnly
 * @param applyId
 * @param signal
 * @param status
 * @returns {*}
 * @constructor
 */
const { TabPane } = Tabs;

let formMap = new Map();

export default function Profit({ readOnly, applyId, signal, type, code }) {
    const [year, setYear] = useState(2022);
    const [tableProps, setTableProps] = useProps();
    const [query, setQuery] = useTimeRangerProps(readOnly);
    const { user } = useContext(GlobalContext);

    function handleFormInit(name, form) {
        formMap.set(name, form);
    }

    // 第一次加载的时候调用一次获取数据
    useEffect(
        function() {
            if (isNotEmpty(signal)) {
                applyId = undefined;
            }

            getFinance(query, user, applyId, code);
        },
        [signal, applyId, year]
    );

    // 查找财务信息
    function getFinance(query, user, applyId, code) {
        callFinance(
            parseInt(year),
            parseInt(year),
            2,
            code || user?.creditCode,
            applyId,
            undefined,
            type
        ).then(function(res) {
            if (isEmpty(res))
                return setTableProps({
                    columns: [],
                    dataSource: []
                });
            let tableProps = convertData(2, res.data);

            setTableProps({
                ...tableProps
            });

            //  form表单重置为默认值
            formMap.forEach((form) => {
                form.resetFields();
            })
        });
    }

    // 文件上传成功
    function fileUpload(url) {
        if (isEmpty(url)) return;
        message.success('上传成功！');
        getFinance(query, user, undefined);
    }

    function handleSave() {
        formMap.forEach((form) => {
            form.validateFieldsAndScroll((errors, values) => {
                
                let obj = {
                    "businessIncome": values['一、营业收入'] || '--',
                    "businessCost": values['减：营业成本'] || '--',
                    "taxesAdditional": values['税金及附加'] || '--',
                    "saleCost": values['销售费用'] || '--',
                    "managementCost" : values['管理费用'] || '--',
                    "researchCost" : values['研发费用'] || '--',
                    "financeCost" : values['财务费用'] || '--',
                    "interestCost" : values['其中：利息费用'] || '--',
                    "interestIncome" : values['利息收入'] || '--',
                    "otherProfit" : values['加：其他收益'] || '--',
                    "investProfit" : values['投资收益（损失以“-”号填列）'] || '--',
                    "coupletsCooperationProfit" : values['其中：对联营企业和合营企业的投资收益'] || '--',
                    "terminationProfit" : values['以摊余成本计量的金融资产终止确认收益（损失以“-”号填列）'] || '--',
                    "muzzleProfit" : values['净敞口套期收益（损失以“-”号填列）'] || '--',
                    "valueChangeProfit" : values['公允价值变动收益（损失以“-”号填列）'] || '--',
                    "creditImpairmentLoss" : values['信用减值损失（损失以“-”号填列）'] || '--',
                    "assetsImpairmentLoss" : values['资产减值损失（损失以“-”号填列）'] || '--',
                    "assetsHandleProfit" : values['资产处置收益（损失以“-”号填列）'] || '--',
                    "businessProfit" : values['二、营业利润（亏损以“-”号填列）'] || '--',
                    "businessOutIncome" : values['加：营业外收入'] || '--',
                    "businessOutExpenditure" : values['减：营业外支出'] || '--',
                    "profitTotal" : values['三、利润总额（亏损总额以“-”号填列）'] || '--',
                    "incomeTax" : values['减：所得税费用'] || '--',
                    "netProfit" : values['四、净利润（净亏损以“-”号填列）'] || '--',
                    "continuousOperationProfit" : values['（一）持续经营净利润（净亏损以“-”号填列）'] || '--',
                    "stopOperationProfit" : values['（二）终止经营净利润（净亏损以“-”号填列）'] || '--',
                    "otherComprehensiveNetAmount" : values['五、其他综合收益的税后净额'] || '--',
                    "noReclassificationProfit" : values['（一）不能重分类进损益的其他综合收益'] || '--',
                    "benefitPlanForehead" : values['1．重新计量设定受益计划变动额'] || '--',
                    "noRotationLossProfit" : values['2．权益法下不能转损益的其他综合收益'] || '--',
                    "toolValueChange" : values['3．其他权益工具投资公允价值变动'] || '--',
                    "creditRisksChange" : values['4．企业自身信用风险公允价值变动'] || '--',
                    "reclassificationProfit" : values['（二）将重分类进损益的其他综合收益'] || '--',
                    "rotationLossProfit" : values['1．权益法下可转损益的其他综合收益'] || '--',
                    "otherDebtInvestChange" : values['2．其他债权投资公允价值变动'] || '--',
                    "financeReclassificationForehead" : values['3．金融资产重分类计入其他综合收益的金额'] || '--',
                    "otherImpairmentReady" : values['4．其他债权投资信用减值准备'] || '--',
                    "assetHedgingReserve" : values['5．现金流量套期储备'] || '--',
                    "conversionDifference" : values['6．外币财务报表折算差额'] || '--',
                    "comprehensiveTotal" : values['六、综合收益总额'] || '--',
                    "perShareProfit" : values['七、每股收益：'] || '--',
                    "basePreShareProfit" : values['（一）基本每股收益'] || '--',
                    "dilutionPreShareProfit": values['（二）稀释每股收益'] || '--'
                }

                submitProfitInfo(
                    2,
                    parseInt(year),
                    obj,
                    {},
                    {}
                ).then(res => {
                    if (res.success == true) {
                        message.success(res.message);
                    } else {
                        message.error(res.message);
                    }
                })
            })
        })
        
    }
    
    return (
        <div>
            {readOnly ? 
                <Tabs onChange={(val) => setYear(val)}>
                    <TabPane tab='2022' key='2022'></TabPane>
                    <TabPane tab='2021' key='2021'></TabPane>
                    <TabPane tab='2020' key='2020'></TabPane>
                </Tabs> 
                    : 
                (<Row className={styles.row}>
                    <Col span={17}>
                        <Tabs onChange={(val) => setYear(val)}>
                            <TabPane tab='2022' key='2022'></TabPane>
                            <TabPane tab='2021' key='2021'></TabPane>
                            <TabPane tab='2020' key='2020'></TabPane>
                        </Tabs>
                    </Col>
                </Row>
            )}
            <FlexibleTable {...tableProps} onInit={handleFormInit} year={year} type={2} readOnly={readOnly} />
            {readOnly == false && (
                <div className="textCenter marginTop">
                    <SaveButton onClick={handleSave} />
                </div>
            )}
        </div>
    );
}
