import React from 'react';
import style from './styles.scss'
import Relation from './GetRelationMap'

export default function RelationMapIndex(props) {
    return(
        <div className={style.divBox}>
            <Relation {...props}/>
        </div>
    )
}
