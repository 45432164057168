/**
 * @author Ray
 * @description 填报买方申请
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import ErrorTip from '../../../common/errorTip';
import { Form, Input, Typography, Icon, Button, InputNumber } from 'antd';
import {
    ENTERPRISE_TYPE,
    ECONOMIC_TYPE,
    TRUE_FALSE
} from '../../../constants/enum';
import { Selector } from '../home/apply';
import {
    asyncValidator,
    EmailCheck,
    GreaterThanZro,
    PhonePattern,
    Required
} from '../../../utils/validator';
import styles from './enterprices.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getBaseInfos } from '../../imformation/basiInfo/service';
import { isNotEmpty, moneyFormatter } from '../../../utils/utils';
import { validatePhone } from '../../userCenter/services';
import Text, { SelectorPreview, TextareaPreview } from '../../../common/text';
import { GlobalContext } from '../../../common/globalStore/createProvider';
import { queryJbxx } from '../../investment/intermediaryDetail/home/service';

const { Item } = Form;

export const InfoForm = withRouter(
    React.forwardRef(function(props, ref) {
        // const [user] = useStorage('UserInfo');
        React.useImperativeHandle(ref, () => ({ form }));
        const { user } = useContext(GlobalContext);
        const [defaultIndustry, setDefaultIndustry] = useState('');
        // const [currency, setCurrency] = useState('');
        const phoneValidator = useRef(
            asyncValidator(
                validatePhone,
                <ErrorTip text="手机号码已被注册" />,
                (res, value) => {
                    // 如果输入是用户自己的电话，直接返回正确
                    if (value === user?.phone)
                        return { success: true, data: true };
                    if (isNotEmpty(res) && res.success) {
                        // 翻转结果，传入组件，用来判断输入的手机哈是否可用
                        res.data = !res.data;
                    }
                    return res;
                }
            )
        );
        const { form, onInit, showConfig, isPreview, isEdit } = props;
        const { getFieldDecorator } = form;
        function getIndustry() {
            getBaseInfos(user?.creditCode).then(function(res) {
                if (isNotEmpty(res.data)) {
                    setDefaultIndustry(res.data.srcIndustry);
                    props.form.setFieldsValue({
                        industry: res.data.srcIndustry
                    });
                }
            });
        }
        function getCompany() {
            queryJbxx(user?.bbdQyxxId, user?.companyName).then(company => {
                const { data } = company;
                // setCurrency(
                //     data?.regcapCurrency === 'null' ? '' : data?.regcapCurrency
                // );
                setTimeout(
                    () =>
                        form.setFieldsValue({
                            registeredAddress: data?.address,
                            businessScope: data?.operateScope,
                            registeredCapital: data?.regcapAmount
                                ? Math.abs(data?.regcapAmount / 10000)?.toFixed(
                                      2
                                  )
                                : 0
                        }),
                    0
                );
            });
        }
        function goToFinance(e) {
            e.preventDefault();
            e.stopPropagation();
            // 设置页面跳转的路径到缓存
            // gs.setLocalLocation('/companyInformation/');
            // 打开新的窗口
            setTimeout(
                () =>
                    window.open(
                        location.protocol +
                            '//' +
                            location.host +
                            '/companyInformation/',
                        '_blank'
                    ),
                0
            );
        }
        useEffect(function() {
            onInit(form);
            getIndustry();
            !isPreview && !isEdit && getCompany();
        }, []);
        return (
            <div id="enterpriseInformation" style={{ position: 'relative' }}>
                <Form
                    labelCol={{ xs: 8 }}
                    wrapperCol={{ xs: 16 }}
                    className={styles.root}
                >
                    <Item label="企业名称">
                        <Typography.Text>{user?.organization}</Typography.Text>
                    </Item>
                    <Item label="统一社会信用代码">
                        <Typography.Text>
                            {user?.creditCode || '--'}
                        </Typography.Text>
                    </Item>
                    <Item required label="邮箱地址">
                        {getFieldDecorator('mailbox', {
                            initialValue: user?.email,
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <ErrorTip text="请输入邮箱地址！" />
                                    )
                                },
                                EmailCheck
                            ]
                        })(
                            isPreview ? (
                                <Text />
                            ) : (
                                <Input
                                    disabled={isPreview}
                                    placeholder="请输入"
                                />
                            )
                        )}
                    </Item>
                    <Item required label="联系方式">
                        {getFieldDecorator('contactInformation', {
                            initialValue: user?.phone,
                            validateTrigger: 'onBlur',
                            rules: [
                                Required,
                                PhonePattern,
                                // phoneValidator.current
                            ]
                        })(
                            isPreview ? (
                                <Text />
                            ) : (
                                <Input
                                    disabled={isPreview}
                                    placeholder="请输入"
                                    maxLength={50}
                                />
                            )
                        )}
                    </Item>
                    <Item required label="所属行业">
                        {getFieldDecorator('industry', {
                            initialValue: defaultIndustry,
                            rules: []
                        })(
                            isPreview ? (
                                <Text />
                            ) : (
                                <Input
                                    disabled
                                    placeholder="点击右侧按钮选择行业"
                                />
                            )
                        )}
                        {!isPreview && (
                            <>
                                &nbsp; &nbsp;
                                <Icon onClick={getIndustry} type="sync" />
                                &nbsp; &nbsp;
                                <a target="_blank" onClick={goToFinance}>
                                    <Button type="primary">修改所属行业</Button>
                                </a>
                            </>
                        )}
                    </Item>
                    <Item required label="注册地(住所)">
                        {getFieldDecorator('registeredAddress', {
                            rules: [Required]
                        })(
                            isPreview ? (
                                <TextareaPreview />
                            ) : (
                                <Input.TextArea
                                    placeholder="请输入"
                                    autosize={{
                                        maxRows: 6,
                                        minRows: 4
                                    }}
                                    disabled={isPreview}
                                    maxLength={500}
                                />
                            )
                        )}
                    </Item>
                    <Item required label="注册资本(万)">
                        {getFieldDecorator('registeredCapital', {
                            rules: [GreaterThanZro, Required]
                        })(
                            isPreview ? (
                                <Text suffix="万" formatter={moneyFormatter} />
                            ) : (
                                <InputNumber
                                    disabled={isPreview}
                                    placeholder="请输入"
                                    maxLength={15}
                                />
                            )
                        )}
                    </Item>
                    <Item required label="经营范围">
                        {getFieldDecorator('businessScope', {
                            rules: [Required]
                        })(
                            isPreview ? (
                                <TextareaPreview />
                            ) : (
                                <Input.TextArea
                                    placeholder="请输入"
                                    autosize={{
                                        maxRows: 6,
                                        minRows: 4
                                    }}
                                    disabled={isPreview}
                                    maxLength={500}
                                />
                            )
                        )}
                    </Item>
                    <Item label="实收资本(万元)">
                        {getFieldDecorator('realityCapital', {
                            rules: [GreaterThanZro]
                        })(
                            isPreview ? (
                                <Text suffix="万" formatter={moneyFormatter} />
                            ) : (
                                <InputNumber
                                    disabled={isPreview}
                                    placeholder="请输入"
                                    maxLength={15}
                                />
                            )
                        )}
                    </Item>
                    <Item required label="经济类型">
                        {getFieldDecorator('economicType', {
                            rules: [Required]
                        })(
                            isPreview ? (
                                <SelectorPreview options={ECONOMIC_TYPE} />
                            ) : (
                                <Selector
                                    getPopupContainer={() =>
                                        document.getElementById(
                                            'enterpriseInformation'
                                        )
                                    }
                                    disabled={isPreview}
                                    options={ECONOMIC_TYPE}
                                />
                            )
                        )}
                    </Item>
                    <Item required label="企业类型">
                        {getFieldDecorator('enterpriseType', {
                            rules: [Required]
                        })(
                            isPreview ? (
                                <SelectorPreview options={ENTERPRISE_TYPE} />
                            ) : (
                                <Selector
                                    getPopupContainer={() =>
                                        document.getElementById(
                                            'enterpriseInformation'
                                        )
                                    }
                                    disabled={isPreview}
                                    placeholder="请输入"
                                    options={ENTERPRISE_TYPE}
                                />
                            )
                        )}
                    </Item>
                    {showConfig && (
                        <>
                            <Item label="其他股东是否放弃优先受让权">
                                {getFieldDecorator(
                                    'shareholdersPriorityTransfer',
                                    {
                                        rules: []
                                    }
                                )(
                                    isPreview ? (
                                        <SelectorPreview options={TRUE_FALSE} />
                                    ) : (
                                        <Selector
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'enterpriseInformation'
                                                )
                                            }
                                            disabled={isPreview}
                                            options={TRUE_FALSE}
                                        />
                                    )
                                )}
                            </Item>
                            <Item label="企业管理层是否参与受让">
                                {getFieldDecorator(
                                    'managementParticipateTransfer',
                                    {
                                        rules: []
                                    }
                                )(
                                    isPreview ? (
                                        <SelectorPreview options={TRUE_FALSE} />
                                    ) : (
                                        <Selector
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'enterpriseInformation'
                                                )
                                            }
                                            disabled={isPreview}
                                            options={TRUE_FALSE}
                                        />
                                    )
                                )}
                            </Item>
                            <Item label="是否涉及职工安置">
                                {getFieldDecorator('staffArrangement', {
                                    rules: []
                                })(
                                    isPreview ? (
                                        <SelectorPreview options={TRUE_FALSE} />
                                    ) : (
                                        <Selector
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'enterpriseInformation'
                                                )
                                            }
                                            disabled={isPreview}
                                            options={TRUE_FALSE}
                                        />
                                    )
                                )}
                            </Item>
                            <Item label="导致标的企业的实际控制权发生转移">
                                {getFieldDecorator('controlTransfer', {
                                    rules: []
                                })(
                                    isPreview ? (
                                        <SelectorPreview options={TRUE_FALSE} />
                                    ) : (
                                        <Selector
                                            getPopupContainer={() =>
                                                document.getElementById(
                                                    'enterpriseInformation'
                                                )
                                            }
                                            disabled={isPreview}
                                            options={TRUE_FALSE}
                                        />
                                    )
                                )}
                            </Item>
                        </>
                    )}
                </Form>
            </div>
        );
    })
);

EnterpriseInformation.defaultProps = {
    onChange: () => {}
};

EnterpriseInformation.propTypes = {
    onChange: PropTypes.func
};

// 买方企业信息
export default function EnterpriseInformation({
    isPreview,
    onChange,
    onInit,
    showConfig,
    isEdit
}) {
    let [Element, setElement] = useState(null);

    useEffect(function() {
        let Form_ = Form.create({
            name: 'info',
            onValuesChange: (a, b, allValues) =>
                onChange({ merge: { ...allValues } })
        })(InfoForm);

        setElement(
            <Form_
                showConfig={showConfig}
                onInit={onInit}
                isPreview={isPreview}
                isEdit={isEdit}
            />
        );
    }, []);

    return Element;
}
