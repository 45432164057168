import { useState, useEffect } from 'react';
import { riskLevelAPI, formListAPI } from './service';

export default function( qyxxId, stockCode, year ){

    const [barOption, setBarOption] = useState({}); // 风险程度 柱状图
    const [levelLoading, setLevelLoading] = useState({}); 
    const [gaugeOptions, setGaugeOptions] = useState(null); // 风险程度 仪表盘
    const [formList, setFormList] = useState([]); // 风险程度 

    // 风险程度
    function getRiskLevel() {
        riskLevelAPI({
            qyxxId,
            year
        }).then(res => {
            if(res.success){
                const { totalRiskScore, ...others } = res.data;
                getBarOptions(others);
                getGaugeOptions(totalRiskScore);
            }
        }).finally(()=>{
            setLevelLoading(false);
        })
    };

    function getBarOptions({caiwuRiskScore, shifaRiskScore, zhiyadanbaoRiskScore}){
        const options = {
            grid: {
                left: 150,
                right: 30
            },
            tooltip: {
                trigger: 'item',
                formatter: function (v) {
                    const { value, name, color } = v; 
                    return `<div class="tooltipBox">
                                ${name}:
                                <span style = "color:${color}"><b>${value}</b></span>
                            <div>`
                    }
            },
            color: [ "#2082ED", "#FB7B39", "#59DF8F"],
            xAxis: {
                type: 'value'
            },
            yAxis: {
                data: ["司法风险", "质押担保风险", "财务风险"],
                type: 'category',
                splitLine: {
                    show: false
                }
            },
            series: [
                {
                    type: 'bar',        
                    itemStyle: {
                        barBorderRadius: 10
                    },
                    data: [
                        {name: "司法风险", value: shifaRiskScore.toFixed(2) || 0, itemStyle: {color: "#59df8f"}  },
                        {name: "质押担保风险", value: zhiyadanbaoRiskScore.toFixed(2) || 0, itemStyle: {color: "#2082ED"}  },
                        {name: "财务风险", value: caiwuRiskScore.toFixed(2) || 0, itemStyle: {color: "#FB7B39"}  },
                    ]
                }
            ]
        }
        setBarOption(options);
    }

    function getGaugeOptions(totalRiskScore){
        const options = {
            series: [
                {
                    type: 'gauge',
                    data: [{value: totalRiskScore.toFixed(2) || 0, name: '风险总分'}]
                }
            ]
        };
        setGaugeOptions(options);
    }

    // 风险程度
    function getList() {
        if(!stockCode){
            return;
        }
        formListAPI({
            stockCode
        }).then(res => {
            if(res.success){
                setFormList(res.data);
            }
        })
    };

    useEffect(()=>{
        if(year){
            getRiskLevel();
        }
    }, [year])

    useEffect(()=>{
        getList();
    }, [stockCode])

    return { barOption, levelLoading, gaugeOptions, formList }
}