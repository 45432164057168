'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _zrender = require('zrender');

var _zrender2 = _interopRequireDefault(_zrender);

var _common = require('../util/common');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 图谱单元抽象类
 */
var Shape = function () {
    function Shape(options) {
        (0, _classCallCheck3.default)(this, Shape);

        this.engine = _zrender2.default;
    }

    // 绘制


    (0, _createClass3.default)(Shape, [{
        key: 'draw',
        value: function draw() {}

        // 更新属性

    }, {
        key: 'update',
        value: function update() {}

        // 删除

    }, {
        key: 'remove',
        value: function remove() {}
    }, {
        key: 'setDirty',
        value: function setDirty(dirty) {
            this._dirty = dirty;
            if (dirty && this.layer) {
                this.layer.needsRefresh();
            }
        }
    }, {
        key: 'isDirty',
        value: function isDirty() {
            return this._dirty;
        }
    }, {
        key: 'isIgnore',
        value: function isIgnore() {
            return this._ignore;
        }
    }, {
        key: 'setLayer',
        value: function setLayer(layer) {
            this.layer = layer;
        }
    }, {
        key: 'getLayer',
        value: function getLayer() {
            return this.layer;
        }

        // 获取他的数据处理器

    }, {
        key: 'getType',
        value: function getType() {
            return this.type;
        }
    }, {
        key: 'getId',
        value: function getId() {
            return this.id;
        }
    }, {
        key: 'getOptions',
        value: function getOptions() {
            return this.options;
        }
    }, {
        key: 'getElement',
        value: function getElement() {
            return this.element;
        }
    }, {
        key: 'getLayer',
        value: function getLayer() {
            return this.layer;
        }

        /**
         * Element 函数封装
         */

    }, {
        key: 'show',
        value: function show() {}
    }, {
        key: 'hide',
        value: function hide() {}
    }, {
        key: 'traverse',
        value: function traverse() {
            this.element.traverse();
        }
    }, {
        key: 'afterUpdate',
        value: function afterUpdate() {
            this.element.afterUpdate();
        }
    }, {
        key: 'beforeUpdate',
        value: function beforeUpdate() {
            this.element.beforeUpdate();
        }
    }, {
        key: 'removeClipPath',
        value: function removeClipPath() {
            this.element.removeClipPath();
        }
    }, {
        key: 'setClipPath',
        value: function setClipPath() {
            this.element.setClipPath();
        }

        /**
         * Event 函数封装
         */

    }, {
        key: 'isSilent',
        value: function isSilent() {
            this.element.isSilent();
        }
    }, {
        key: 'off',
        value: function off(event, handler) {
            this.element.off(event, handler);
        }
    }, {
        key: 'on',
        value: function on(event, handler, context) {
            this.element.on(event, handler, context);
        }
    }, {
        key: 'one',
        value: function one(event, handler, context) {
            this.element.one(event, handler, context);
        }
    }, {
        key: 'trigger',
        value: function trigger(event) {
            this.element.trigger(event);
        }
    }, {
        key: 'animate',
        value: function animate() {}
    }, {
        key: 'animateTo',
        value: function animateTo() {}
    }, {
        key: 'convertOptions',
        value: function convertOptions(opts) {
            if ((0, _common.isEmpty)(opts)) {
                return;
            }

            // 初始属性
            var options = {
                z: 0,
                shapeType: this.type,
                isTarget: false,
                style: {}
            };

            for (var key in opts) {
                // 这2个是style属性
                if (key === 'itemStyle' || key === 'textStyle') {
                    (0, _assign2.default)(options.style, opts[key]);
                } else {
                    options[key] = opts[key];
                }
            }

            return options;
        }
    }]);
    return Shape;
}();

exports.default = Shape;
module.exports = exports['default'];