/**
 * @desc 信息披露title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line14Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line14Title }>
                信息披露
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}