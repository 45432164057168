import fetch from '../../../utils/fetch';
import { API } from 'constants/enum';
/**
 * @desc 获取地图数据
 * @param mapName
 */
export default function getLocalMap(mapName) {
    return fetch(
        `/data/map/${encodeURIComponent(mapName)}.json`, true
    );
}

export function authRedOverview(area) {
    return fetch(`${API.V1}/auth/red/overview`, { area });
}

// 各行业IPO申报
export function authDiamondNewFive(area) {
    return fetch(`${API.V1}/auth/diamond/new/five`, { area });
}

// 获取全国地区接口
export function getAreas() {
    return fetch(`${API.V1}/cockpit/listedCompanyRisk/getArea`);
}