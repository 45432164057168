import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc 基本信息
 * @param qyxxId
 */
export function getCompanyBaseInfo(qyxxId) {
    return request(`${API.V1}/investbank/company/basic`, {
        qyxxId
    });
}

/**
 * @desc 融资
 * @param qyxxId
 */
export function getFinancingProgress({ qyxxId, pageNum, pageSize }) {
    return request(`${API.V1}/investbank/company/china/venture`, {
        qyxxId,
        pageNum,
        pageSize
    });
}

/**
 * @desc 参股控股情况
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function getHolders({ pageNum, pageSize, qyxxId, companyName }) {
    return request(`${API.V1}/investbank/company/invest`, {
        pageNum,
        pageSize,
        qyxxId,
        companyName
    });
}

/**
 * @desc 股东列表
 * @param qyxxId
 */
export function shareHolders(param) {
    return request(`${API.V1}/investbank/company/gdxx`, param);
}

/**
 * @desc 工商变更
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function businessChange({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/company/gsbg`, {
        pageNum,
        pageSize,
        qyxxId
    });
}

/**
 * @desc 中标信息
 * @param pageNum
 * @param pageSize
 * @param qyxxId
 */
export function bids({ pageNum, pageSize, qyxxId }) {
    return request(`${API.V1}/investbank/company/bidwinning`, {
        pageNum,
        pageSize,
        qyxxId
    });
}
