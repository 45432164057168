/**
 * @desc 入库企业分析-基础信息-司法诉讼-经营异常
 * @author Ray
 */

import React, { useState } from 'react';
import Table from '../../../../../common/tableEx';
import { businessAnomaliesColumns } from './columns';
import { isNotEmpty } from '../../../../../utils/utils';
import { abnormalOperation } from './service';

export default function BusinessAnomalies({ match }) {
    function parseData(res) {
        if (isNotEmpty(res)) return res?.data || [];
        return [];
    }

    const [query] = useState({ qyxxId: match.params.companyId });

    return (
        <Table
            query={query}
            columns={businessAnomaliesColumns}
            getData={abnormalOperation}
            parseData={parseData}
            locale={{
                emptyText:
                    '未发现企业的经营异常记录。不排除存在未公示或时间相对滞后的情况。'
            }}
        />
    );
}
