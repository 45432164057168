import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 获取地区分布数据
 * @param area
 */
export function getAreaDistribution(area) {
    return request(
        `${API.V1}/auth/red/distribution`,
        { area },
        undefined,
        undefined,
        true
    );
}
