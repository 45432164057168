/**
 * @desc  主办券商执业情况
 */

import React, { useState } from 'react';
import style from './style.scss'
import Radios from "./radio";
import InfoList from './infoList'

export default function LeftThirdIndex() {

    const [type, setType] = useState(1);

    const radiosData = [
        { name: '新挂牌', value: 1 },
        { name: '新发行股票', value: 2 },
        { name: '转板上市', value: 3 },
    ];

    return(
        <div className={style.leftThirdDiv}>
            <div className={style.divTitle}>
                主办券商执业情况
            </div>
            <div className={style.divRadio}>
                <Radios
                    value={type}
                    data={radiosData}
                    defaultValue={1}
                    onChange={e => setType(e.target.value)}
                />
            </div>
            <InfoList type={type} />
        </div>
    )
}
