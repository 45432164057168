'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _get2 = require('babel-runtime/helpers/get');

var _get3 = _interopRequireDefault(_get2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _Chart2 = require('../../../interface/Chart');

var _Chart3 = _interopRequireDefault(_Chart2);

var _common = require('../../../util/processor/common');

var _circlePos = require('../../../util/coordinate/circlePos');

var _processor = require('../../../util/processor/processor');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 圈层分布图
 */
var CircleChart = function (_Chart) {
    (0, _inherits3.default)(CircleChart, _Chart);

    function CircleChart(painter, store, handler, opts, setting) {
        (0, _classCallCheck3.default)(this, CircleChart);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CircleChart.__proto__ || (0, _getPrototypeOf2.default)(CircleChart)).call(this, painter, store, handler, opts, setting));

        _this.type = 'circle';

        _this.process = function () {
            var state = _this.getState();
            _this.centerId = (0, _common.getTarget)(state).id;
            _this.updateNode(state);
        };

        _this.reRender = function (info) {
            _this.centerId = info.id;
            _this.date = info.date;
            _this.store.loadNewState({
                bbdQyxxId: info.id,
                degree: info.degree,
                date: info.date,
                isHistory: info.isHistory
            }, 'init').then(function (newState) {
                if (newState) {
                    _this.updateNode(newState);
                }
            });
        };

        _this.path = function (node) {
            var state = _this.getState();
            var gridState = _this.store.toGridState(state, _this.centerId);

            var newOrginData = _this.newOrginData || _this.store.getOriginData();
            var path = (0, _processor.getShortPathById)(node.data.bbdQyxxId, _this.centerId, gridState, newOrginData);

            _this.setHighLight(path, gridState);
            _this.renderNode(gridState);
        };

        _this.highlight = function (node) {
            var state = _this.getState();
            if (state && state.nodes && state.nodes.length > 0) {
                state.nodes.map(function (n) {
                    return n.animation = false;
                });
                state.links.map(function (n) {
                    return n.animation = false;
                });
                var gridState = _this.store.toGridState(state, _this.centerId);
                if (node && node.shapeType === 'node') {
                    var highLight = (0, _processor.getHightLight)(node.data.bbdQyxxId, gridState);
                    _this.setHighLight(highLight, gridState);
                } else {
                    gridState.nodes.map(function (n) {
                        return n.slight = false;
                    });
                    gridState.links.map(function (n) {
                        return n.slight = false;
                    });
                }
                _this.renderNode(gridState);
            }
        };

        _this.setHighLight = function (newPoint, gridState) {

            gridState.nodes.map(function (n) {
                return n.slight = true;
            });
            gridState.links.map(function (n) {
                return n.slight = true;
            });

            newPoint.nodes.map(function (n) {
                return n.slight = false;
            });
            newPoint.links.map(function (n) {
                return n.slight = false;
            });

            gridState = _this.store.mergeState(gridState, newPoint);
        };

        _this.deletePoint = function (node, del) {
            var gridState = _this.store.toGridState(_this.getState(), _this.centerId);
            var newPoints = null;
            if (del) {
                // 删除
                newPoints = (0, _processor.delByIds)([node.data.bbdQyxxId], gridState, _this.centerId);
            } else {
                // 收起
                newPoints = (0, _processor.flodByIds)([node.data.bbdQyxxId], gridState, _this.centerId);
            }
            _this.handlePoint(newPoints);
        };

        _this.handlePoint = function (point) {
            var state = _this.getState();
            var gridState = _this.store.toGridState(state, _this.centerId);

            gridState.nodes.map(function (n) {
                n.animation = true;
                n.slight = false;
            });
            gridState.links.map(function (n) {
                n.animation = true;
                n.slight = false;
            });

            _this.setState(gridState);
            point.nodes.filter(function (n) {
                if (gridState.nodesKV[n]) {
                    gridState.nodesKV[n].hidden = true;
                }
            });
            point.links.filter(function (n) {
                if (gridState.linksKV[n]) {
                    gridState.linksKV[n].hidden = true;
                }
            });
            var nodes = gridState.nodes.filter(function (n) {
                return !gridState.nodesKV[n.id].hidden;
            });
            var links = gridState.links.filter(function (n) {
                return !gridState.linksKV[n.id].hidden;
            });
            _this.renderNode({ nodes: nodes, links: links });
        };

        _this.seek = function (info) {
            _this.store.loadNewState({
                nodeIds: info.nodeIds,
                nodeTypes: info.nodeTypes,
                seekType: 'INVEST_MANAGE'
            }, 'init', 'seek').then(function (extensionState) {
                if (extensionState && extensionState.nodes && extensionState.nodes.length > 0) {
                    var state = _this.getState();
                    state.nodes.map(function (n) {
                        return n.slight = true;
                    });
                    state.links.map(function (n) {
                        return n.slight = true;
                    });

                    extensionState.nodes.map(function (n) {
                        return n.slight = false;
                    });
                    extensionState.links.map(function (n) {
                        return n.slight = false;
                    });

                    _this.newOrginData = _this.store.mergeState(state, extensionState);
                    var gridState = _this.store.toGridState(_this.newOrginData, _this.centerId);

                    (0, _circlePos.circlePos)(gridState, _this.info);
                    _this.renderNode(gridState);
                }
            });
        };

        _this.extend = function (node) {
            _this.store.loadNewState({
                bbdQyxxId: node.data.bbdQyxxId,
                degree: 1,
                date: _this.date
            }, 'extension').then(function (newState) {
                // 设置起始位置
                newState.nodes.forEach(function (newNode) {
                    newNode.startPoint = node.parent.position;
                });
                _this.newOrginData = _this.store.mergeState(_this.getState(), newState);
                _this.updateNode(_this.newOrginData);
            });
        };

        _this.renderNode = function (gridState) {
            _this.setState(gridState);
            _this.handler.trigger('chart.changeList', gridState);
            _this.handler.trigger('chart.getSettingStatus');
            _this.handler.trigger('chart.resetFilterSetting');
        };

        _this.updateNode = function (state) {
            if (state && state.nodes && state.nodes.length > 0) {
                state.nodes.map(function (n) {
                    return n.slight = false;
                });
                state.links.map(function (n) {
                    return n.slight = false;
                });
                var gridState = _this.store.toGridState(state, _this.centerId);
                (0, _circlePos.circlePos)(gridState, _this.info);
                _this.renderNode(gridState);
            } else {
                _this.setState({});
            }
        };

        _this.info = {
            center: _this.getCenterPoint(),
            size: {
                nodesInterval: opts.nodesInterval || 70, // 节点间隔
                groupsInterval: opts.groupsInterval || 130, // 组的间隔
                circleInterval: opts.circleInterval || 50 // 圈层间隔
            }
        };
        _this.centerId = null;
        _this.newOrginData = null;
        _this.date = '';
        return _this;
    }

    (0, _createClass3.default)(CircleChart, [{
        key: 'beforeProcess',
        value: function beforeProcess() {
            (0, _get3.default)(CircleChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CircleChart.prototype), 'beforeProcess', this).call(this);
        }
    }, {
        key: 'afterProcess',


        // 监听事件或行为的最佳位置
        value: function afterProcess() {
            // 行为绑定
            this.mouseEvent();
        }
    }, {
        key: 'dispose',
        value: function dispose() {
            (0, _get3.default)(CircleChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CircleChart.prototype), 'dispose', this).call(this);
            this.handler.off('click', this.handleHighlight);
            this.handler.off('dblclick', this.handleExtension);
            this.handler.off('mousewheel', this.handleZoom);
            this.handler.off('mouseover', this.handleHighlightLine);

            this.painter = null;
            this.store = null;
            this.handler = null;
            this.newOrginData = null;
        }
    }, {
        key: 'mouseEvent',
        value: function mouseEvent() {
            var _this2 = this;

            (0, _get3.default)(CircleChart.prototype.__proto__ || (0, _getPrototypeOf2.default)(CircleChart.prototype), 'handleDrag', this).call(this);

            this.handler.on('click', this.handleHighlight);
            this.handler.on('dblclick', this.handleExtension);
            this.handler.on('mousewheel', this.handleZoom);
            this.handler.on('mouseover', this.handleHighlightLine);
            // 扩展
            this.handler.on(this.type + '.extension', function (node) {
                if (node && node.shapeType !== 'node') {
                    return;
                }
                _this2.extend(node);
            });
            // 隐藏
            this.handler.on(this.type + '.hide', function (node) {
                _this2.deletePoint(node, true);
            });
            // 探寻
            this.handler.on(this.type + '.seek', function (info) {
                _this2.seek(info);
            });
            // 收起
            this.handler.on(this.type + '.packup', function (node) {
                if (node && node.shapeType !== 'node') {
                    return;
                }
                _this2.deletePoint(node);
            });
            // 高亮
            this.handler.on(this.type + '.highlight', function (node) {
                _this2.highlight(node);
            });
            // 路径
            this.handler.on(this.type + '.path', function (node) {
                _this2.path(node);
            });
            // 度数切换
            this.handler.on(this.type + '.reRender', function (info) {
                if (info.reset) {
                    _this2.resetTransform();
                }

                _this2.reRender(info);
            });
        }

        /**
         * 度数切换
         */


        /**
         * 路径 
         */


        /**
         * 高亮 
         */


        /**
         * 设置高亮
         */


        /**
         * 移除点
         */


        /**
         * 点处理
         */


        /**
         * 探寻
         */


        /**
         * 扩展
         */


        /**
         * 渲染
         */

    }]);
    return CircleChart;
}(_Chart3.default);

exports.default = CircleChart;
module.exports = exports['default'];