import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';


export function getQueryListedCompanyFb(params) {
    return request(`${API.V1}/auth/kcDriver/index/queryListedCompanyFb`, params);
}
//风险企业
export function ventureEnterprise(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/centerBottom/riskCompaniesNumHistogram`, params);
}
