/**
 * @author Ray
 */
import React from 'react';
import Model from './model';
import View from './view';
import MaterialFillin from './materialFillin';
import ProcessCheck from './process';
import Basic from './basic';

export const Routes = [
    {
        exact: true,
        name: '基础信息',
        path: '/policy',
        component: Basic
    },
    {
        exact: false,
        name: '材料填报',
        path: '/policy/fillin/apply/:stage',
        component: MaterialFillin
    },
    {
        exact: false,
        name: '材料编辑',
        path: '/policy/fillin/edit/:id',
        component: MaterialFillin
    },
    {
        exact: false,
        name: '填报信息检视',
        path: '/policy/process/:id',
        component: ProcessCheck
    }
];

export default function(props) {
    return (
        <Model {...props} Routes={Routes}>
            {prop => {
                return <View {...prop} />;
            }}
        </Model>
    );
}
