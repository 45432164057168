/**
 * @author hjie
 * 区域风险排名
 */

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import ReactEcharts from 'echarts-for-react';
import {areaSort} from '../service';
import { Radio, Empty } from 'antd';
import classnames from 'classnames';
import styles from './styles.scss';

export default function({barCallBack, data}) {
    const [barOption, setBarOption] = useState(null); // 区域风险排名
    const [noBar, setNoBar] = useState(false);
    const [mode, setMode] = useState('1'); // 选项卡
    const clickCallback = useCallback((v) => {
        let name = data.find( ele => ele.district === v.name);
        if(name){
            barCallBack({districtCode: name.code}); 
        }  
    }, [data]);

    // 区域风险排名选项卡
    const handleModeChange = e => {
        const modeval = e.target.value;
        setMode(modeval);
    };

    // 区域风险排名
    function getAreaSort(){
        setNoBar(false);
        areaSort({
            type: mode
        }).then(res => {
            if(res && res.success){
                getOption(res.data);
            }else{
                setNoBar(true);
            }
        })
    }
    function getOption(data){
        if( data && data.length){
            data.reverse();
            const param = mode == '1' ? 'num' : 'percent';
            const barOption = {
                grid:{
                    bottom: 0,
                    top: 20
                },
                tooltip: {
                    show: false
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    data: data.map(ele => ele.district),
                    type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                series: [
                    {
                        type: 'bar',        
                        itemStyle: {
                            barBorderRadius: 10
                        },
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: '14',
                            color: '#999999',
                            formatter: mode == '1' ? '{c}家' : '{c}'
                        },
                        data: data.map(ele => ele[param]),
                    }
                ]
            };
            setBarOption(barOption);
        }else{
            setNoBar(true);
            setBarOption({});
        }
    }

    useEffect(()=>{
        getAreaSort();
    }, [mode])


    return (
        <Fragment>
            <div className="clearfix">
                <h4 className={classnames("fl", styles.sortTit)}>区域风险排名</h4>
                <Radio.Group className="fr" onChange={handleModeChange} value={mode}>
                    <Radio.Button value="1">按风险公司数量</Radio.Button>
                    <Radio.Button value="2">按平均风险评分</Radio.Button>
                </Radio.Group>
            </div>
             {
                noBar ? <Empty /> : barOption ?
                    <ReactEcharts
                        option={barOption}
                        notMerge={true}
                        style={{height: '310px'}}
                        theme={'myTheme'}
                        onEvents = {{ click: clickCallback}}
                    /> : ''
            }
        </Fragment>
    );
}
