import { message } from 'antd';

export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

export const formItemLayoutCol = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

export const detailLayoutCol = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

export const investDetailLayoutCol = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

export const modalformLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};
export const btnLayoutCol = {
  xs: { span: 24, offset: 0 },
  sm: { span: 22, offset: 8 }
};
export const UPLOAD_CONFIG = {
  name: 'file',
  headers: {
    authorization: 'authorization-text'
  },
  beforeUpload: file => {
    const isJPG =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif';
    if (!isJPG) {
      message.error('目前仅支持图片上传!');
    }

    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不能超过5MB!');
    }
    return isJPG && isLt2M;
  },
  multiple: false,
  showUploadList: false
};

//2、	证监会行业选择器提示
export const MESSAGE1 =
  '证监会公布的上市公司所属行业类别，请根据《上市公司行业分类指引》进行对应，选择您公司对应的上市公司行业代码。';
//4、	国民经济行业分类选择器提示
export const MESSAGE2 =
  '国民经济行业分类是由国家标准化管委会发布的用于宏观管理的行业分类依据，用于所有企业所属行业的划分。示例：小类代码，A0111，对应行业，稻谷种植。';
//5 企业行业标签说明
export const MESSAGE3 =
  '选择您公司对应的行业标签，方便匹配与公司相匹配的公司，提供更准确的同行业公司比较。';

// 课程分类
export const Classification = {
  0: '全部',
  1: '深交所主板',
  2: '深交所创业板',
  3: '深交所中小板',
  4: '上交所主板',
  5: '上交所科创板',
  6: '港交所',
  7: '北交所'
};

// 课程报名
export const ISENROLL = {
  not: 2, // 未报名
  yes: 1 // 已报名
};

// 上市培训类别
export const CATEGORY = {
  Course: '云课堂',
  Column: '专栏'
};

// 上市板块
export const BLOCKTYPE = {
  '000': '深交所主板',
  '300': '深交所创业板',
  '600': '上交所主板',
  '688': '上交所科创板',
  '8': '北交所',
};

// 风险类型
export const RISKTYPE = {
  '1': { name: '总体风险 ', key: 'totalRiskScore' },
  '2': { name: '财务风险', key: 'caiwuRiskScore' },
  '3': { name: '质押担保风险', key: 'zhiyadanbaoRiskScore' },
  '4': { name: '司法风险', key: 'shifaRiskScore' },
  '5': { name: '舆情口碑', key: 'tyqgScore' }
};

export const maxCreat = 999999999;
