'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.StreamDirect = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _StreamDirect;

exports.setPosition = setPosition;
exports.delDataById = delDataById;
exports.getFreeNodes = getFreeNodes;
exports.setDegree = setDegree;

var _lodash = require('../../../util/lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _enum = require('../../../constant/enum');

var _formatter = require('../../../util/formatter');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StreamDirect = exports.StreamDirect = (_StreamDirect = {}, (0, _defineProperty3.default)(_StreamDirect, _enum.StreamType.UPPER, -1), (0, _defineProperty3.default)(_StreamDirect, _enum.StreamType.LOWER, 1), _StreamDirect);

/**
 * @desc 股权结构设置坐标
 * @param {object} gridData 网格数据
 * @param {object} options 配置项
 *      nodeSize {number}  节点大小
 *      nodeSpace {number}  节点间距
 *      layerSpace {number}  排距
 *      degreeSpace {number}  度距
 *      center {array}  中心点坐标
 *      alignCenter {boolean}  是否居中显示
 */
function setPosition() {
    var _preY;

    var gridData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var nodesGroup = gridData.nodesGroup;
    var nodeSize = options.nodeSize,
        nodeSpace = options.nodeSpace,
        layerSpace = options.layerSpace,
        degreeSpace = options.degreeSpace,
        center = options.center,
        alignCenter = options.alignCenter;

    var maxDegree = 0; // Math.max.apply(null, Object.keys(nodesGroup));
    for (var i in nodesGroup) {
        if (Number(i) && i > maxDegree) {
            maxDegree = i;
        }
    }
    var maxCount = Math.max(Math.floor((center[0] * 2 - nodeSpace) / nodeSpace), 12);
    maxCount += maxCount % 2 - 1; // 最大数必须为奇数

    var preY = (_preY = {}, (0, _defineProperty3.default)(_preY, _enum.StreamType.UPPER, center[1]), (0, _defineProperty3.default)(_preY, _enum.StreamType.LOWER, center[1]), _preY);

    var _loop = function _loop(degree) {
        if (degree === 0) {
            // 中心点，特殊处理
            nodesGroup[degree].forEach(function (node) {
                node.position = center;
            });
        } else {
            var typeGroups = _lodash2.default.groupBy(nodesGroup[degree] || [], 'streamType'); // 不同类型

            var _loop2 = function _loop2(type) {
                // 遍历类型
                var nodes = typeGroups[type];
                var direct = StreamDirect[type];
                var line = 0; // 当前排数
                preY[type] += direct * degreeSpace;

                var _loop3 = function _loop3() {
                    if (line > 0) {
                        // 0排不需要加排距离
                        preY[type] += direct * layerSpace;
                    }
                    var currentNodes = nodes.splice(0, maxCount); // 每次取出最大长度数据
                    var middle = alignCenter ? (currentNodes.length / 2).toFixed(0) : 0; // 中间Index
                    var baseX = alignCenter ? center[0] : 0; // 根据中心还是左端排列
                    currentNodes.forEach(function (node, index) {
                        var x = (index - middle) * nodeSpace + baseX + (line + degree) % 2 * nodeSpace / 2;
                        node.position = [(index - middle) * nodeSpace + baseX + (line + degree) % 2 * nodeSpace / 2 + nodeSize, preY[type]];
                    });
                    line++; // 行数+1
                };

                while (nodes.length) {
                    _loop3();
                }
            };

            for (var type in typeGroups) {
                _loop2(type);
            }
        }
    };

    for (var degree = 0; degree <= maxDegree; degree++) {
        _loop(degree);
    }
}

/**
 * @desc 股权结构根据箭头方向查找数结构
 * @param {object} gridData 网格数据
 * @param {string} targetId 中心点ID
 * @param {boolean} setDegree 是否设置度数
 * @return {object}
 *          treeKV {object}
 *          treeNodes {array}
 */
function findTree(gridData, targetId) {
    var setDegree = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var nodesKV = gridData.nodesKV;

    var centerNode = nodesKV[targetId]; // 中心节点
    var treeKV = {}; // id 键值
    var treeNodes = [];
    if (centerNode) {
        (function () {
            var startLevel = 0; // 从0度开始
            var whileArr = [centerNode];

            var _loop4 = function _loop4() {
                var arr = [];
                whileArr.forEach(function (node) {
                    if (!treeKV[node.id]) {
                        treeKV[node.id] = node;
                        if (setDegree) {
                            node.degree = startLevel; // 设置度数
                        }
                        arr = arr.concat((0, _formatter.getValidChild)(gridData, node));
                    }
                });
                startLevel++;
                whileArr = _lodash2.default.uniqBy(arr, 'id');
            };

            while (whileArr.length) {
                _loop4();
            }
        })();
    }

    for (var id in treeKV) {
        treeNodes.push(treeKV[id]);
    }

    return { treeKV: treeKV, treeNodes: treeNodes };
}

/**
 * @desc 股权结构根id 获取删除数据
 * @param {object} id 目标点ID
 * @param {object} gridData 网格数据
 * @param {string} targetId 中心点ID
 * @param {boolean} delSelf 是否删除本身
 * @return {object}
 *         delNodes {array} 删除点
 *         delLinks {array} 删除线
 */
function delDataById(id, gridData, targetId, delSelf) {
    var nodesKV = gridData.nodesKV,
        linksGroup = gridData.linksGroup;

    var targetNode = nodesKV[id];
    var streamType = targetNode.streamType,
        _targetNode$childs = targetNode.childs,
        childs = _targetNode$childs === undefined ? [] : _targetNode$childs;

    var delBoth = streamType === _enum.StreamType.CENTER || delSelf; // 删除入资和出资
    var delNodes = delSelf ? [targetNode] : [],
        delLinks = [];

    if (streamType === _enum.StreamType.CENTER && delSelf) {
        // 中心点不能隐藏
        return false;
    }

    var filterChilds = childs.filter(function (child) {
        // 筛选要留下的child和线
        var links1 = linksGroup[child.id + '-' + id];
        var links2 = linksGroup[id + '-' + child.id];
        var retain = true; // 是否保留

        if ((streamType === _enum.StreamType.UPPER || delBoth) && links1) {
            // 删除入资点
            delLinks = delLinks.concat(links1);
            retain = false;
        }

        if ((streamType === _enum.StreamType.LOWER || delBoth) && links2) {
            // 删除入资点
            delLinks = delLinks.concat(links2);
            retain = false;
        }

        return retain;
    });

    if (filterChilds.length !== childs.length) {
        // 有删除的数据, 查找游离点
        targetNode.childs = filterChilds;

        var _getFreeNodes = getFreeNodes(gridData, targetId),
            exNodes = _getFreeNodes.exNodes,
            exLinks = _getFreeNodes.exLinks;

        delNodes = delNodes.concat(exNodes);
        delLinks = _lodash2.default.uniqBy(delLinks.concat(exLinks), 'id');
    }

    return { delNodes: delNodes, delLinks: delLinks };
}

// 查找游离点
function getFreeNodes(gridData, targetId) {
    var nodes = gridData.nodes,
        linksGroup = gridData.linksGroup;

    var _findTree = findTree(gridData, targetId),
        treeKV = _findTree.treeKV;

    var exNodes = [],
        exLinks = [],
        findedLink = {};

    nodes.forEach(function (node) {
        if (!treeKV[node.id]) {
            exNodes.push(node);
            node.childs && node.childs.forEach(function (cNode) {
                var key1 = node.id + '-' + cNode.id,
                    key2 = cNode.id + '-' + node.id;
                if (!findedLink[key1]) {
                    exLinks = exLinks.concat(linksGroup[key1] || []);
                }
                if (!findedLink[key2]) {
                    exLinks = exLinks.concat(linksGroup[key2] || []);
                }
            });
        }
    });

    // console.log('游离点：', exNodes);

    return { exNodes: exNodes, exLinks: exLinks };
}

// 设置度数
function setDegree(gridData, targetId) {
    findTree(gridData, targetId, true);
}