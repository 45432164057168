import React, {useEffect, useState} from 'react';
import style from './style.scss'

export default function ViewItem({title,val,picpath,w,lw,rw}) {
    return(
        <div className={style.item} style={{background: `url(${picpath})`,backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', width: window.screen.width < 1920 ? 155 : w}}>
            <div style={{width:lw}} className={style.leftdiv}>{title}</div>
            <div style={{width:rw}} className={style.rightdiv}>{val}</div>
        </div>
    )
}
