/**
 * @desc 中介机构详情-基本信息-基本信息
 * @author Ray
 */

import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { queryJbxx } from './service';
import { isNotEmpty, transferStringNull } from '../../../../utils/utils';

export default function Basic({ match }) {
    const [jbxx, setJbxx] = useState({});
    useEffect(() => {
        queryJbxx(match.params.companyId, match.params.companyName).then(
            res => {
                if (isNotEmpty(res) && isNotEmpty(res.data)) {
                    setJbxx(res.data);
                }
            }
        );
    }, []);

    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    <td>企业名称</td>
                    <td>{transferStringNull(jbxx.companyName) || '--'}</td>
                </tr>
                <tr>
                    <td>类型</td>
                    <td>{transferStringNull(jbxx.companyType) || '--'}</td>
                </tr>
                <tr>
                    <td>法定代表人</td>
                    <td>{transferStringNull(jbxx.frname) || '--'}</td>
                </tr>
                <tr>
                    <td>注册资本</td>
                    <td>{transferStringNull(jbxx.regcap) || '--'}</td>
                </tr>
                <tr>
                    <td>成立日期</td>
                    <td>{transferStringNull(jbxx.esdate) || '--'}</td>
                </tr>
                <tr>
                    <td>地址</td>
                    <td>{transferStringNull(jbxx.address) || '--'}</td>
                </tr>
                <tr>
                    <td>经营范围</td>
                    <td>{transferStringNull(jbxx.operateScope) || '--'}</td>
                </tr>
                <tr>
                    <td>核准日期</td>
                    <td>{transferStringNull(jbxx.approvalString) || '--'}</td>
                </tr>
                <tr>
                    <td>登记状态</td>
                    <td>{transferStringNull(jbxx.enterpriseStatus) || '--'}</td>
                </tr>
            </tbody>
        </table>
    );
}
