/**
 * @author hjie
 * 云课堂详情页
 */

import React from 'react';
import Detail from '../../common/detail/index';

const stepsData = [
    { name: '课程介绍', domId: 'descriptionCourse', param: 'description' },
    { name: '讲师介绍', domId: 'lecturersCourse', param: 'lecturers' },
    { name: '课程大纲', domId: 'outlineCourse', param: 'outline'}
]
export default function(props) {
   return <Detail {...props} Type = "云课堂" stepsData = {stepsData} Name = "课程"/>
}
