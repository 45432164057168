import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Empty } from 'antd';
import { loadingOption } from '../../../echarts/theme';
/**
 * @desc 折线图
 */

const colors = [
  '#0CD64A',
  '#00DBFF',
  '#61A5E8',
  '#F87E12',
  '#D4614B',
  '#7e7e7e'
];

export default class AreaStyleLine extends PureComponent {
  getOption() {
    const { xData = [], legendData = [], seriesData = [] } = this.props;
    return {
      tooltip: {
        formatter: function(params) {
          const newData = `<p style="color:#fff">
                            ${params[0].name}<br/>
                            ${params.map(
                              item =>
                                `${item.seriesName}：<span style="color:${item.color};font-size:18px;font-weight:bold;">${item.value}</span>家<br/>`
                            )}
                        </p>`;
          return newData.replace(/[',']/g, '');
        },
        trigger: 'axis',
        position: function (point, params, dom, rect, size) {
            return [point[0],'-15%']
        },
        backgroundColor: 'rgba(16,70,118,0.68)',
        borderColor: '#0293DC',
        borderWidth: 1,
      },
      toolbox: {
        show: true
      },
      xAxis: {
        type: 'category',
        data: xData,
        axisLabel: {
          textStyle: {
            color: '#fff'
          }
        },
        splitLine: 'none'
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          textStyle: {
            color: '#fff'
          }
        },
        splitLine: {
          lineStyle: {
            type: 'solid',
            color: '#40BFFF',
            opacity: 0.3
          }
        },
        splitNumber: 4
      },
      series: seriesData,
      legend: {
        textStyle: {
          color: '#fff'
        }
      },
      color: colors.concat([
        '#c23531',
        '#2f4554',
        '#d48265',
        '#91c7ae',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3'
      ])
    };
  }
  render() {
    const { style = {}, xData = [], seriesData = [] } = this.props;
    return !xData.length || !seriesData.length ? (
      <Empty />
    ) : (
      <ReactEcharts
        option={this.getOption()}
        notMerge={true}
        style={{ height: '100%', ...style }}
        loadingOption={loadingOption}
        theme={'myTheme'}
      />
    );
  }
}
