import request from '../../../../../utils/fetch';
import { API } from '../../../../../constants/enum';

/**
 * @desc 企业资质
 * @param companyName
 * @param pageNum
 * @param pageSize
 * @param type
 */
export function getQualification({ companyName, pageNum, pageSize, type }) {
    return request(`${API.V1}/investbank/company/qualification`, {
        companyName,
        pageNum,
        pageSize,
        type
    });
}
