/**
 * @author Ray
 * @description 用户中心消息通知使用的list。
 */

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Avatar, Badge, Checkbox, List } from 'antd';
import styles from './styles.scss';
import Pagination from './pagination';
import { isNotEmpty } from '../../utils/utils';
import { replace } from '../../utils/utils';
const { Item } = List;
const { Meta } = Item;

/**
 *
 * @param dot
 * @param query
 * @param onClick
 * @param checkbox
 * @param parseData
 * @param loadSource
 * @param onChange
 * @returns {*}
 */
export default function({
  dot,
  query,
  onClick,
  checkbox,
  parseData,
  loadSource,
  onChange = () => {}
}) {
  const [source, setSource] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 10
  });

  useEffect(
    function() {
      load(pagination, query);
    },
    [query]
  );

  function load(p, q) {
    if (p.resetCheckbox === undefined || p.resetCheckbox) {
      setSelectedItems([]);
    }

    delete p.resetCheckbox;
    loadSource({
      ...p,
      ...q
    }).then(function(res) {
      if (isNotEmpty(res.data)) {
        const { data, pageCount, pageNum, pageSize, totalCount } = res.data;
        setSource(parseData(data));
        setPagination({
          ...pagination,
          total: totalCount,
          totalPage: pageCount,
          pageNum,
          pageSize
        });
      }
    });
  }

  function handleCheckboxClick(selectedItem) {
    return function(e) {
      let _selectedItems = [...selectedItems];
      if (e.target.checked) {
        _selectedItems = replace(
          _selectedItems,
          selectedItem,
          s => s.id === selectedItem.id,
          true
        );
      } else {
        _selectedItems = _selectedItems.filter(function(s) {
          return s.id !== selectedItem.id;
        });
      }
      setSelectedItems(_selectedItems);
      onChange(_selectedItems);
    };
  }

  return (
    <>
      <List
        dataSource={source}
        className={styles.listRoot}
        renderItem={(item, i) => {
          return (
            <div className={styles.itemBox}>
              {checkbox && (
                <Checkbox
                  disabled={!item.dot}
                  checked={selectedItems.map(item => item.id).includes(item.id)}
                  onChange={handleCheckboxClick(item)}
                />
              )}
              <Item
                key={i}
                onClick={() => onClick(item)}
                className={classnames(styles.listItem, {
                  [styles.listItemUnRead]: item.dot
                })}
              >
                {dot && item.dot && <Badge dot color={'#FE5353'} />}
                <Meta
                  title={item.title}
                  avatar={item.avatar && <Avatar src={item.avatar} />}
                  description={item.description}
                />
                <span>{item.time}</span>
              </Item>
            </div>
          );
        }}
      />
      <Pagination {...pagination} onChange={load} />
    </>
  );
}
