import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AutoComplete as Auto } from 'antd';
import { isEmpty, debounce } from '../../utils/utils';

export function AutoComplete({ form, loadSource, parser, ...props }, ref) {
  const [dataSource, setSource] = useState([]);
  const [query, setQuery] = useState(props.value || '');
  const funcRef = useRef(() => {});

  useImperativeHandle(ref, () => ({}));

  function loadSourceData(query = '') {
    loadSource(query).then(function(res) {
      if (!isEmpty(res) && !isEmpty(res.data)) {
        let _source = parser ? parser(res.data) : res.data;
        setSource(_source);
      } else {
        setSource([]);
      }
    });
  }
  // 组件加载进行一次搜索
  useEffect(() => loadSourceData(), []);

  useEffect(
    function() {
      if (!query) return;
      loadSourceData(query);
    },
    [query]
  );

  useEffect(function() {
    funcRef.current = debounce(v => setQuery(v), 800, this);
  }, []);

  function renderOptions(dataSource) {
    return dataSource.map(d => {
      return (
        <Auto.Option key={JSON.stringify(d)} value={d.value}>
          {d.label}
        </Auto.Option>
      );
    });
  }

  function onBlur() {
    form.setFieldsValue({
      industrySubclass: ''
    })
  }

  return (
    <Auto
      {...props}
      onSearch={funcRef.current}
      getPopupContainer={node => node.parentElement}
      onBlur={onBlur}
    >
      {renderOptions(dataSource)}
    </Auto>
  );
}

export default React.forwardRef(AutoComplete);
