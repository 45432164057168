// register theme object
const axisCommon = {
  axisLine: {
    show: true,
    lineStyle: {
      color: '#DFDFDF'
    }
  },
  splitLine: {
    show: true,
    lineStyle: {
      color: '#DFDFDF',
      type: 'dashed'
    }
  },
  axisLabel: {
    show: true,
    color: '#999',
    fontSize: '14',
    fontFamily: 'Microsoft YaHei'
  },
  axisTick: {
    show: false
  }
};
const gridCommon = {
  top: '30',
  left: '70',
  right: '60',
  bottom: '30'
};

const tooltipCommon = {
  show: true,
  trigger: 'axis',
  backgroundColor: '#fff',
  padding: [4, 8],
  borderColor: 'rgba(230,240,248,1)',
  borderWidth: 1,
  textStyle: {
    color: '#333'
  },
  axisPointer: {
    type: 'line',
    lineStyle: {
      color: '#DFDFDF',
      width: 1
    },
    crossStyle: {
      color: '#DFDFDF',
      width: 1
    },
    shadowStyle: {
      color: 'rgba(38,130,185,0.2)'
    }
  }
};
export const myTheme = {
  grid: gridCommon,
  textStyle: {
    fontFamily: 'Microsoft YaHei'
  },
  color: [
    '#2082ED',
    '#FB7B39',
    '#59DF8F',
    '#004591',
    '#c23531',
    '#2f4554',
    '#61a0a8'
  ],
  line: {
    itemStyle: {
      borderWidth: 3
    },
    lineStyle: {
      width: 3
    },
    symbolSize: 10
  },
  bar: {
    barMaxWidth: 12,
    itemStyle: {
      color: '#2082ED',
      barBorderRadius: 10
    }
  },
  gauge: {
    radius: '70%',
    axisLine: {
      // 坐标轴线
      lineStyle: {
        // 属性lineStyle控制线条样式
        color: [[0.25, '#59DF8F'], [0.75, '#2082ED'], [1, '#FB7B39']],
        width: 45
      }
    },
    title: {
      offsetCenter: [0, '80%'],
      fontWeight: 'bold'
    },
    axisLabel: {
      distance: 25 // 坐标轴小标记
    },
    detail: {
      offsetCenter: ['0', '60%'], // x, y，单位px
      fontSize: 30,
      fontWeight: 'bolder'
    },
    pointer: {
      length: '60%'
    }
  },
  pie: {
    startAngle: 20,
    radius: ['18%', '46%'],
    center: ['50%', '50%'],
    label: {
      show: true,
      color: '#999999'
    },
    avoidLabelOverlap: true,
    labelLine: {
      show: true,
      length: 15,
      length2: 15,
      lineStyle: {
        color: '#999'
      }
    },
    itemStyle: {
      borderWidth: 0
    }
  },
  effectScatter: {
    rippleEffect: {
      scale: 4
    },
    itemStyle: {
      normal: {
        color: '#FE8E5C'
      },
      emphasis: {
        color: '#FE8E5C'
      }
    }
  },
  map: {
    itemStyle: {
      areaColor: '#2082ED',
      borderColor: '#fff',
      borderWidth: 1,
      shadowColor: 'rgba(32, 130, 237, 0.5)',
      shadowBlur: 2,
      shadowOffsetY: 5
    },
    label: {
      show: false,
      color: '#ffffff'
    },
    emphasis: {
      label: {
        color: '#ffffff'
      }
    }
  },
  categoryAxis: {
    ...axisCommon,
    boundaryGap: true
  },
  valueAxis: {
    ...axisCommon,
    nameTextStyle: {
      color: '#666666',
      padding: [0, 0, 5, 25]
    }
  },
  tooltip: tooltipCommon,
  axisPointer: {
    type: 'line',
    lineStyle: {
      color: '#DFDFDF',
      width: 1
    },
    crossStyle: {
      color: '#DFDFDF',
      width: 1
    }
  }
};

export const loadingOption = {
  text: '',
  color: '#1890FF',
  maskColor: 'rgba(0, 0, 0, 0.25)'
};
