/**
 * @author Ray
 * @description 封装antd的table组件，仅修改样式
 */
import React from 'react';
import { Table } from 'antd';
import styles from './styles.scss';

export const Column = props => <Table.Column {...props} />;

// eslint-disable-next-line react/no-multi-comp
export default ({ pagination, ...props }) => (
    <Table
        className={styles.tableRoot}
        {...props}
        pagination={
            pagination
                ? {
                      size: 'small',
                      hideOnSinglePage: true,
                      showTotal: () => {
                          return (
                              <div style={{ textAlign: 'left' }}>
                                  <span>
                                      共{pagination.totalPage}
                                      页&nbsp;&nbsp;/&nbsp;&nbsp;
                                      {pagination.total}
                                      条数据
                                  </span>
                              </div>
                          );
                      },
                      total: pagination.total,
                      current: pagination.pageNum,
                      ...pagination
                  }
                : false
        }
    />
);
