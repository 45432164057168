import { getListLKeys, isEmpty } from '../../utils/utils';

/**
 * @description 解析财务信息的数据，返回columns和dataSource
 * @param type
 * @param data
 * @returns {{columns: *, dataSource: *}}
 */
export function convertData(type, data) {
    if (isEmpty(data))
        return {
            dataSource: [],
            columns: []
        };

    let keys = getListLKeys(type);
    let columns =
            data &&
            data.map(d => ({
                dataIndex: String(d.year),
                title: d.year
            })),
        years = data && data.map(d => d.year);
    data =
        data &&
        data.map(d => ({
            [d.year]: d.content ? d.content.split(',') : []
        }));

    let source = keys.map((key, i) => {
        let obj = {};
        obj.item = key;
        let year = years[0];
        let endYear = years.slice(-1);
        while (year <= endYear) {
            let values =
                data &&
                data.filter(d => {
                    return Object.prototype.hasOwnProperty.call(d, year);
                });
            obj[String(year)] = values[0]?.[year]?.[i];
            year++;
        }

        return obj;
    });

    return {
        dataSource: source,
        columns: []
            .concat([{ dataIndex: 'item', title: '项目（单位：万元）' }])
            .concat(columns)
    };
}
