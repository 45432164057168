import { callPolicyReportList } from './service';
import { isEmpty } from '../../../utils/utils';

export default function Model({ children, ...others }) {
    /**
     * @desc 获取报告列表
     * @returns {*}
     */
    function getPolicyReportList() {
        return callPolicyReportList().then(function(res) {
            if (isEmpty(res)) return;
            return res;
        });
    }

    return children({
        ...others,
        getPolicyReportList
    });
}
