import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';
import { isEmpty } from '../../../../utils/utils';

/**
 *
 * @param name
 */
export function getCompany(name) {
  if (!name)
    return Promise.resolve({
      success: true,
      data: { data: [] }
    });
  return request(`${API.V1}/sso/user/company/list`, {
    companyName: name,
    pageNum: 1,
    pageSize: 10
  });
}

// 公司搜索 中介
export function getIntermediaryCompany(name) {
  return request(`${API.V1}/sso/user/intermediary/list`, {
    companyName: name,
    pageNum: 1,
    pageSize: 10
  });
}

export function logout() {
  return request(`${API.V1}/sso/logout`);
}
