/**
 * @desc  股票发行情况统计
 */
import React, { useState } from 'react';
import RightThird from './EquityIssuance'
export default function RightThirdIndex() {
    return(
        <RightThird/>
    )
}
