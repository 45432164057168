/**
 * @author Ray
 * @desc 主营信息
 */

import React from 'react';
import styles from './styles.scss';
import Title from '../../../../../common/title';

export default function MainCourse({ match, operateScope }) {
    return (
        <>
            <Title level={3} text="经营范围" />
            <div className={styles.mainCourse}>
                <span dangerouslySetInnerHTML={{ __html: operateScope }} />
            </div>
        </>
    );
}
