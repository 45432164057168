import React from 'react';

export default class Frame extends React.Component {
    render() {
        return this.props.children({
            ...this.props,
            ...this.state
        });
    }
}
