/**
 * @param children
 * @param rest
 * @returns {*}
 */

export default function Model({ children, ...rest }) {
    return children({
        ...rest
    });
}
