/**
 * @author Ray
 * @desc 中介机构详情-股东信息
 */

import React, { useState } from 'react';
import { shareholdersColumns } from './columns';
import TableEx from '../../../../common/tableEx';
import { queryGdxx } from './service';
import { isNotEmpty } from '../../../../utils/utils';

export default function Shareholders({ match }) {
    // 是否为上市公司。
    const [isListed, setIsListed] = useState(false);
    const [query] = useState({
        companyName: match.params.companyName,
        qyxxId: match.params.companyId
    });

    function mockData(res = {}) {
        if (res.data) {
            if (isNotEmpty(res.data)) {
                if (res.data[0].ipoCompany === '--') {
                    setIsListed(false);
                } else {
                    setIsListed(true);
                }
            }
            return res.data;
        } else {
            return [];
        }
    }

    function getColumns(isIPO) {
        return [...shareholdersColumns].filter(column => {
            return (
                (isIPO &&
                    column.dataIndex !== 'investName' &&
                    column.dataIndex !== 'subAmount') ||
                (!isIPO &&
                    column.dataIndex !== 'investRatio' &&
                    column.dataIndex !== 'investAmount')
            );
        });
    }

    return (
        <TableEx
            query={query}
            getData={queryGdxx}
            parseData={mockData}
            columns={getColumns(isListed)} // getColumns(isListed)
        />
    );
}
