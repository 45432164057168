import style from './style.scss';
import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState
} from 'react';
import { Spin, BackTop, message } from 'antd';
import Header from '../header';
import Footer from '../footer';
import request from '../../utils/fetch';
import { actions, consts, reducer } from './scripts';
import { getMessages } from '../userCenter/message/service';
import { isNotEmpty, makeRegex, queryStringAll } from '../../utils/utils';
import { StatusCode } from '../../constants/enum';
import { GlobalContext } from '../../common/globalStore/createProvider';
import { LOGIN_GROUP, DONT_NEED_LOGIN, LOGIN, MODIFYPASSWORD } from '../router';
import Banner from '../../common/banner';
import classnames from 'classnames';

/**
 * @desc 页面整体框架组件
 */

export default function ({ route, ...rest }) {
    const domRef = useRef(null);
    const { user, setUser, isPasscodeExpired } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [isBannerShow, setBannerShow] = useState(true);
    const [state, dispatch] = useReducer(reducer, consts.initialState);

    // 判断是否有新的消息通知
    function fetchUserMessage() {
        getMessages({
            pageNum: 1,
            pageSize: 10,
            read: 0
        }).then(res => {
            if (
                isNotEmpty(res) &&
                isNotEmpty(res.data) &&
                isNotEmpty(res.data.data)
            ) {
                dispatch(actions.updateNotify(false));
            } else {
                dispatch(actions.updateNotify(true));
            }
        });
    }

    useEffect(function () {
      
        request.preFetch = () => setLoading(true);
        request.fetched = (res, response) => {
            if (res.statusCode === StatusCode.loginErr) {
                message.destroy();
                user &&
                    !response.url.includes('/logout') &&
                    message.error(res.message);

                setTimeout(() => {
                    setUser(null);
                    rest.history.push('/user/login', {
                        from:
                            rest.location.pathname === LOGIN
                                ? rest.location?.state?.from
                                : undefined
                    });
                }, 500);
            }
            if (res.statusCode === StatusCode.notPower) {
                message.destroy();
                message.info('暂无权限');
            }
        };
        request.afterFetch = () => setLoading(false);
        user && fetchUserMessage();


        

        // 用户未登录访问其他路由，跳转到登录页面
        if (!user) {

            let dontNeedLogin = false;
            // 未登录即可以访问的页面（登录，注册，申述，忘记密码
            for (let url of DONT_NEED_LOGIN) {
                if (url === rest.location.pathname || makeRegex(url).test(rest.location.pathname)) {
                    dontNeedLogin = true
                    break;
                }
            }

            if (
                dontNeedLogin ||
                (
                    // 用户账号密码过期，直接前往修改密码界面。
                    isPasscodeExpired && makeRegex(MODIFYPASSWORD).test(rest.location.pathname)
                )
            ) {
                return rest.history.push({ pathname: rest.location.pathname });
            }
            if (!LOGIN_GROUP.includes(rest.location.pathname)) {
                // 用户未登录，所有其他路由重定向到登录界面，并记录推出的路由地址
                return rest.history.push({
                    pathname: '/user/login',
                    state: { from: rest.location.pathname }
                });
            }
        }
      
    }, []);

    useEffect(() => {
        let listener = rest.history.listen(
            // 页面所有页面加载滚动置顶
            () => (document.getElementById('root').scrollTop = 0)
        );
        return () => listener();
    }, []);

    const { balackHeader, noContent } = route;
    return (
        <div className={style.frame} id="frame" ref={domRef}>
            {!route.isolate && (
                <Header {...rest} store={state} balackHeader={balackHeader} />
            )}
            {route.banner && isBannerShow && <Banner {...route.banner} />}
            {noContent ? (
                <route.component {...rest} route={route} dispatch={dispatch} />
            ) : (
                <div
                    className={classnames(style.content, {
                        [style['content-with-banner']]:
                            route.banner && isBannerShow
                    })}
                >
                    <route.component
                        {...rest}
                        route={route}
                        dispatch={dispatch}
                        setBannerShow={setBannerShow}
                    />
                </div>
            )}
            {!route.isolate && <Footer {...rest} balackHeader={balackHeader} />}
            {loading ? (
                <div className={style.loading}>
                    <Spin size="large" />
                </div>
            ) : null}
            <BackTop target={() => document.getElementById('root')} />
        </div>
    );
}
