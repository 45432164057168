import React, { useState, useEffect } from 'react';
import { Chart } from '@antv/g2';
import styles from './styles.scss';
import { Empty } from 'antd';
import Radios from './radio';
import { fundRaisingBarIPO, fundRaisingBarStocks, industryOptions } from './service';
import { Selector } from '../../../merging/home/apply';
/**
 * @desc 堆叠条形图
 */
global.FundRaisingBar = null;
export default function FundRaisingBar({ address, style }) {
  const [type, setType] = useState(1);
  const [year, setYear] = useState('2023');
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [selectValues, setSelectValues] = useState('');


  const selectValue = ['2022', '2023']

  const radiosData = [
    { name: '首发上市', value: 1 },
    { name: '上市板块', value: 2 },
  ];

  useEffect(() => {
    //获取行业信息接口
    industryOptions({ year: year }).then(({ success, data }) => {
        setValue(data)
    })

    //请求首发上市接口
    type == 1 ? fundRaisingBarIPO({ area: address, year: year, industry: selectValues }).then(({ success, data }) => {
        if (success == true) {
           if (global.FundRaisingBar == null) {
              global.FundRaisingBar = new Chart({
                container: 'FundRaisingBar',
                autoFit: true,
                padding: [8, 8, 72, 30]
              });
              global.FundRaisingBar.data(data);
              global.FundRaisingBar.scale('decimal', {
                alias: '金额(元)'
              });
              global.FundRaisingBar.axis('item', {
                tickLine: null,
              });

              global.FundRaisingBar.legend(false);
              global.FundRaisingBar.tooltip(false);

              global.FundRaisingBar
              .interval()
              .size(20)
              .adjust('dodge')
              .position('item*decimal')
              .label('decimal',(decimal) => {
                  return {
                    position:'middle',
                    content: decimal + '亿',
                  };
              })
              .color('item', ['#40a9ff', '#1890ff', '#096dd9', '#0050b3']);

              global.FundRaisingBar.render();
           } else {
              global.FundRaisingBar.clear();
              global.FundRaisingBar.changeData(data);
              global.FundRaisingBar.scale('decimal', {
                alias: '金额(元)'
              });
              global.FundRaisingBar.axis('item', {
                tickLine: null,
              });

              global.FundRaisingBar.legend(false);
              global.FundRaisingBar.tooltip(false);

              global.FundRaisingBar
              .interval()
              .size(20)
              .adjust('dodge')
              .position('item*decimal')
              .label('decimal',(decimal) => {
                  return {
                    position:'middle',
                    content: decimal + '亿',
                  };
              })
              .color('item', ['#40a9ff', '#1890ff', '#096dd9', '#0050b3']);

              global.FundRaisingBar.render();
           }
        } else {
            <Empty />
        }
    }) :
    //请求上市板块接口
    type == 2 ? fundRaisingBarStocks({ area: address, year: year }).then(({ success, data }) => {
       if (success == true) {
          global.FundRaisingBar.clear();
          global.FundRaisingBar.changeData(data);
          global.FundRaisingBar.scale('decimal', {
            alias: '金额(元)'
          });
          global.FundRaisingBar.axis('item', {
            tickLine: null,
          });

          global.FundRaisingBar.legend(false);
          global.FundRaisingBar.tooltip(false);

          global.FundRaisingBar
          .interval()
          .size(20)
          .adjust('dodge')
          .position('item*decimal')
          .label('decimal',(decimal) => {
              return {
                position:'middle',
                content: decimal + '亿',
              };
          })
          .color('item', ['#40a9ff', '#1890ff', '#096dd9', '#0050b3']);

          global.FundRaisingBar.render();
       } else {
          <Empty />
       }
    }) : <Empty />
  }, [address, type, year, selectValues])

  return (
      <div>
          <div>
              <div className={ styles.rightThirdTitle }>
                  上市募集金额
              </div>
              <div className={ styles.rightThirdRadio }>
                <Radios
                    value={type}
                    data={radiosData}
                    defaultValue={1}
                    onChange={e => setType(e.target.value)}
                />
              </div>
              <Selector
                  defaultValue={'2023'}
                  className={ styles.selectorValue }
                  placeholder="请选择区域"
                  options={selectValue}
                  onChange={value => {
                      setYear(value)
                  }}
              />
              <Selector
                  defaultValue={'行业'}
                  className={ styles.selectorValues }
                  placeholder="请选择区域"
                  options={value}
                  onChange={values => {
                      setSelectValues(values)
                  }}
              />
          </div>
          <div id='FundRaisingBar' className={ styles.FundRaisingBar }></div>
      </div>

  )
}
