import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 * @desc 后获取入库企业列表
 * @param params
 */
export function getCompanyList(params) {
  // 获取上市奖励首页的数据
  return request(
    `${API.V1}/investbank/store/qyxx/extend/page`,
    { ...params },
    {
      method: 'post'
    },
    () => {}
  );
}

/**
 * @desc 获取企业状态标签集合
 */
export function getLabels() {
  return request(
    `${API.V1}/investbank/company/list/label`,
    {},
    {},
    () => {},
    true
  );
}


