import React from 'react';
// import Model from './model';
// import View from './view';

export default function(props) {
    return null;
}

{
    /*<Model {...props}>*/
}
{
    /*    {prop => {*/
}
{
    /*        return <View {...prop} />;*/
}
{
    /*    }}*/
}
{
    /*</Model>*/
}
