import React, { useState } from 'react';
import Table from '../../../common/tableEx';
import { ProjectColumns } from './columns';
import { queryListExpertTeamProject } from '../service';
import { isNotEmpty } from '../../../utils/utils';
import { Conditions } from '../intermediaryDetail/home/serviceCompanyHistory';
import ExpertGroupCard from './expertGroupCard';
import Title from '../../../common/title';
export default function Project(props) {
    const [query, setQuery] = useState({
        expertFirm: props.detail.expertFirm,
        expertTeam: props.detail.expertTeam
    });
    return (
        <div style={{background:'white',padding:'8px 16px'}}>
            <Title level={2} text="合作项目" />
            <Conditions
                onSearch={values =>
                    setQuery({
                        ...query,
                        ...values,
                        pageNum: 1
                    })
                }
            />
            <Table
                columns={ProjectColumns}
                query={query}
                getData={queryListExpertTeamProject}
                parseData={res => (isNotEmpty(res) ? res.data : [])}
            />
        </div>
    );
}
