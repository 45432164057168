/**
 * @author Ray
 * @desc 上市咨询-需求填写
 */

import React, { useContext, useEffect, useState } from 'react';
import { Form, InputNumber } from 'antd';
import { Selector } from '../../../merging/home/apply';
import { STAGES, PLATES } from '../../../../constants/enum';
import { PositiveInteger, Required } from '../../../../utils/validator';
import styles from './styles.scss';
import {
  generateYearRangeOptions,
  isEmpty,
  isNotEmpty,
  moneyFormatter
} from '../../../../utils/utils';
import moment from 'moment';
import Text, { SelectorPreview } from '../../../../common/text';
import { GlobalContext } from '../../../../common/globalStore/createProvider';
import { getBaseInfos } from '../../../imformation/basiInfo/service';
import PlateSelection from '../../../../common/plateSelection';
const { Item } = Form;

export function FormRequire({ form, isPreview }, ref) {
  const { getFieldDecorator } = form;

  const { user } = useContext(GlobalContext);
  const [filterKey, setFilterKey] = useState(form.getFieldsValue().exchange);
  useEffect(() => {
    getBaseInfos(user?.creditCode).then(res => {
      if (isNotEmpty(res.data)) {
        form.setFieldsValue({
          industryCode: res.data.srcIndustry
            ? res.data.srcIndustry.split(' ')[0]
            : undefined
        });
      }
    });
  }, []);

  useEffect(() => {
    setFilterKey(form.getFieldsValue().exchange);
  }, [form]);

  // 给出实例
  React.useImperativeHandle(ref, () => ({ form }));

  return (
    <Form labelCol={{ xs: 8 }} wrapperCol={{ xs: 16 }} className={styles.form}>
      <Item required label="拟上市交易所">
        {getFieldDecorator('exchange', {
          rules: [Required]
        })(
          isPreview ? (
            <SelectorPreview options={STAGES} />
          ) : (
            <Selector
              disabled={isPreview}
              options={STAGES}
              onSelect={value => {
                setFilterKey(value);
                if (value === '境外交易所') {
                  form.setFieldsValue({ plate: '境外版块' });
                } else {
                  form.setFieldsValue({ plate: undefined });
                }
              }}
            />
          )
        )}
      </Item>
      <Item required label="拟上市板块">
        {getFieldDecorator('plate', {
          rules: [Required]
        })(
          isPreview ? (
            <Text />
          ) : (
            <PlateSelection
              isAll={true}
              disabled={filterKey === '境外交易所'}
              filterKey={filterKey}
            />
          )
        )}
      </Item>
      <Item required label="拟上市年份">
        {getFieldDecorator('year', {
          rules: [PositiveInteger, Required]
        })(
          isPreview ? (
            <Text />
          ) : (
            <Selector
              disabled={isPreview}
              options={generateYearRangeOptions(
                Number(moment().format('YYYY')),
                Number(moment().format('YYYY')) + 30
              )}
            />
          )
        )}
      </Item>
      {isPreview && (
        <Item required label="公司所属证监会代码">
          {getFieldDecorator('industryCode', {
            rules: []
          })(<Text />)}
        </Item>
      )}
      <Item required label="期望融资规模(万元)">
        {getFieldDecorator('amt', {
          rules: [Required]
        })(
          isPreview ? (
            <Text formatter={moneyFormatter} suffix="万元" />
          ) : (
            <InputNumber
              placeholder="请输入"
              disabled={isPreview}
              maxLength={15}
              precision={0}
              formatter={value =>
                isEmpty(value) ? value : value <= 0 ? 1 : value
              }
            />
          )
        )}
      </Item>
    </Form>
  );
}

export default ({ formRef, isPreview }) => {
  const [Element, setElement] = useState(null);

  useEffect(function() {
    const Form12 = Form.create({
      name: 'require'
    })(React.forwardRef(FormRequire));
    setElement(<Form12 wrappedComponentRef={formRef} isPreview={isPreview} />);
  }, []);

  return Element;
};
