import fetch from 'utils/fetch';
import { API } from 'constants/enum';

// 根据名称或机构代码批量查询企业列表
export function companysearch(data) {
    return fetch(`${API.V1}/risk/company/search`, data );
}

// 区域风险分析
export function areaAnalyse(data) {
    return fetch(`${API.V1}/risk/risk/area/analyse`, data );
}

// 区域风险排名
export function areaSort(data) {
    return fetch(`${API.V1}/risk/risk/area/sort`, data );
}
// 区域风险排名 -地图
export function mapData(data) {
    return fetch(`${API.V1}/risk/risk/district`, data );
}

// 风险排名
export function risksort(data) {
    return fetch(`${API.V1}/risk/risk/sort`, data, {method: 'POST'} );
}

// 获取产业走势
export function industryTrend(data) {
    return fetch(`${API.V1}/risk/industry/trend`, data );
}

// 获取行业列表
export function industryList(data) {
    return fetch(`${API.V1}/risk/industry/list`, data );
}

// 北京区县信息
export function bjDistrict(data) {
    return fetch(`${API.V1}/risk/risk/beijing/district`, data );
}

// 财报日期
export function areEarnings(data){
    return fetch(`${API.V1}/risk/industry/year`,data)
}

// 风险排名弹窗
export function sortWin(data) {
    return fetch(`${API.V1}/risk/risk/sort/win`, data, {method: 'POST'} );
}

//区域市场概况
export function areaBasicInfo(data) {
    return fetch(`${API.V1}/risk/risk/area/analyse`, data );
}

//区域统计概览
export function areaDataStatistics(data) {
    return fetch(`${API.V1}/risk/risk/area/analyse/block`, data );
}

//区域高风险企业行业分布
export function areaEnterpriseDistribution(data) {
    return fetch(`${API.V1}/risk/risk/area/analyse/industry`, data );
}

//区域高风险企业总量走势
export function areaEnterpriseTrends(data){
    return fetch(`${API.V1}/risk/risk/area/analyse/trend`, data );
}
