import React from 'react';
import styles from './styles.scss';
import bgpic from '../images/new_leftMiddle.png';
import CardHeader from './cardHeader';


export default function Card({ children, title, style }) {
    return (
        <div
            className={styles.card}
            style={{
                background: `url(${bgpic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                ...style
            }}
        >
            {title && (
                <CardHeader
                    title={title}
                />
            )}
            <div className={styles.body}>{children}</div>
        </div>
    );
}
