/**
 * @author Ray
 * @desc 投行与投顾
 */

import React, { Fragment, useReducer } from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import Index from './home';
import Consultant from './consultant';
import EnterpriseAnalysis from './enterpriseAnalysis';
import EnterpriseDetail from './enterpriseDetail';
import IntermediaryAnalysis from './intermediaryAnalysis';
import IntermediaryDetail from './intermediaryDetail';
import PolicyReport from './policyReport';
import PolicyDetail from './policyDetail';
import Expert from './expert';
import ExpertDetail from './expertDetail';
import ExpertGroup from './expertGroup';
import ExpertGroupDetail from './expertGroupDetail';
import ExpertIndex from './expertIndex';
import ModalEx from '../../common/modalEx';
import reducer from './script/reducer';
import { initState } from './script/const';
import * as Actions from './script/actions';
import { Permission } from '../../hooks/useAuth';
import classnames from 'classnames';
import styles from '../listingTraining/styles.scss';

const routes = [
    {
        path: '/investment/enterpriseAnalysis',
        exact: true,
        component: EnterpriseAnalysis
    },
    {
        path: '/investment/markSuggest',
        exact: true,
        component: Index
    },
    {
        exact: false,
        path: '/investment/consultant',
        component: Consultant
    },
    {
        exact: true,
        path: '/investment/intermediaryAnalysis',
        component: IntermediaryAnalysis
    },
    {
        exact: true,
        path: '/investment/expert/index',
        component: ExpertIndex
    },
    {
        path: '/investment/expert',
        exact: true,
        component: Expert
    },
    {
        path: '/investment/expert/:id',
        exact: true,
        component: ExpertDetail
    },
    {
        path: '/investment/expertGroup',
        exact: true,
        component: ExpertGroup
    },
    {
        path: '/investment/expertGroup/:id',
        exact: true,
        component: ExpertGroupDetail
    },
    {
        exact: true,
        path: '/investment/policyReport',
        component: PolicyReport
    },
    {
        exact: true,
        path: '/investment/policyReport/detail/:companyId',
        component: PolicyDetail
    },
    {
        exact: false,
        path:
            '/investment/enterpriseAnalysis/:companyName/:companyId/:companyCode',
        component: EnterpriseDetail
    },
    {
        exact: false,
        path: '/investment/intermediaryAnalysis/:companyName/:companyId',
        component: IntermediaryDetail
    }
];

export default function Investment({ setBannerShow, route }) {
    const [store, dispatch] = useReducer(reducer, initState);
    // const isAuth = useAuth(179);

    return (
        <Fragment>
            <Permission permission={179}>
                <Switch>
                    {routes.map(o => (
                        <Route
                            key={o.path}
                            path={o.path}
                            exact={o.exact}
                            render={prop => {
                                return (
                                    <o.component
                                        {...prop}
                                        store={store}
                                        dispatch={dispatch}
                                        setBannerShow={setBannerShow}
                                    />
                                );
                            }}
                        />
                    ))}
                    <Redirect from={route.path} to={route.redirect} />
                </Switch>
            </Permission>
            <ModalEx
                title={'提示'}
                onClose={() => dispatch(Actions.closeModal())}
                onCancel={() => dispatch(Actions.closeModal())}
                {...store.modal}
            >
                {store.modal.content}
            </ModalEx>
        </Fragment>
    );
}
