/**
 * @author Ray
 */

import request from '../../../utils/fetch';
import { API } from '../../../constants/enum';

/**
 *
 * @param id
 */
export function getMessageDetail(id) {
    return request(`${API.V1}/auth/sys/message/user/detail`, {
        id
    });
}
