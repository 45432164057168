/**
 * @author Ray
 * @desc 投行与投顾
 */

import React from 'react';
import Bigscreen from '../bigscreen/index';
import DiamondScore from '../diamondScore/index';
import DomesticStock from '../cockpit/domesticStock/index';  //全A驾驶舱
import ListedCompanyRisk from '../cockpit/listedCompanyRisk/index';   //风险监测驾驶舱
import NewotcMarket from '../cockpit/newotcmarket/index';   //新三板驾驶舱
import { Redirect, Route, Switch } from 'react-router-dom';
import { Permission } from '../../hooks/useAuth';
import { allCheck } from '../../utils/validator';
import NeeqStock from '../cockpit/neeqStock'

const routes = [
    {
        path: '/sciAndTech/index',
        exact: true,
        component: Bigscreen
    },
    {
        exact: true,
        path: '/sciAndTech/diamond',
        component: DiamondScore
    },
    {
        path: '/sciAndTech/cockpit/domesticStock',
        exact: true,
        component: DomesticStock
    },
    {
        path: '/sciAndTech/cockpit/listedCompanyRisk',
        exact: true,
        component: ListedCompanyRisk
    },
    // {
    //     path: '/sciAndTech/cockpit/newotcmarket',
    //     exact: true,
    //     component: NewotcMarket
    // },
    {
        path: '/sciAndTech/cockpit/neeqStock',
        exact: true,
        component: NeeqStock
    }
];

export default ({ route }) => {
    return (
        <Permission permission={488}>
            <Switch>
                {routes.map(o => (
                    <Route
                        key={o.path}
                        path={o.path}
                        exact={o.exact}
                        render={prop => {
                            return <o.component {...prop} />;
                        }}
                    />
                ))}
                <Redirect from={route.path} to={route.redirect} />
            </Switch>
        </Permission>
    );
};
