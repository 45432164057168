'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by Tirion on 2017/12/7.
 */

var RoundCircle = function () {
    function RoundCircle(_ref) {
        var zr = _ref.zr,
            cx = _ref.cx,
            cy = _ref.cy,
            r = _ref.r,
            minR = _ref.minR,
            addR = _ref.addR,
            arcLength = _ref.arcLength,
            count = _ref.count,
            draw = _ref.draw,
            full = _ref.full;
        (0, _classCallCheck3.default)(this, RoundCircle);

        this.zr = zr;
        this.cx = cx;
        this.cy = cy;
        this.r = r; // 每个圆的半径
        this.minR = minR; // 圆心到第一层圆的半径
        this.addR = addR; // 每层圆增加的半径
        this.arcLength = arcLength; // 两个圆间隔的弧长
        this.count = count; // 总共画多少个圆
        this.isDraw = draw; // 是否将圆绘制出来
        this.full = full; // 最外层的圈个数是否补满
        this.coords = []; // 每个圆的坐标
        this.style = {
            fill: 'red'
        };

        this.attr = { // 圈层的属性
            tier: 0,
            r: 0,
            area: 0,
            rectArea: 0,
            perimeter: 0
        };

        this.init();
    }

    (0, _createClass3.default)(RoundCircle, [{
        key: 'init',
        value: function init() {
            this._drawCenterCircle();
            this._drawRoundCircle();
            this._setAttr();
        }

        // 绘制中心的圆

    }, {
        key: '_drawCenterCircle',
        value: function _drawCenterCircle() {
            var cx = this.cx,
                cy = this.cy,
                r = this.r;

            if (this.isDraw) {
                var circle = new zrender.Circle({
                    shape: {
                        cx: cx,
                        cy: cy,
                        r: r
                    },
                    style: this.style
                });
                this.zr.add(circle);
            }
            this.coords.push({ cx: cx, cy: cy });
            this.count--;
        }
    }, {
        key: '_drawRoundCircle',
        value: function _drawRoundCircle() {
            var count = 0;

            out: for (var i = 0; i < this.count; i++) {
                if (this.full) {
                    if (count > this.count) {
                        break out;
                    }
                }

                this.attr.tier = i + 1;

                var r = this.minR + i * this.addR;
                var perimeter = 2 * Math.PI * r;
                var currentCircleCount = perimeter / this.arcLength; // 计算出当前圈能画的个数
                // 对小数部分进行处理
                if (currentCircleCount.toString().split('.')[1] && currentCircleCount.toString().split('.')[1][0] > 8) {
                    currentCircleCount = Math.ceil(currentCircleCount);
                } else {
                    currentCircleCount = Math.floor(currentCircleCount);
                }

                for (var j = 0; j < currentCircleCount; j++) {
                    count++;

                    if (!this.full && count > this.count) {
                        break out;
                    }

                    this._drawOneRoundCircle(j, r);
                }
            }
        }
    }, {
        key: '_drawOneRoundCircle',
        value: function _drawOneRoundCircle(i, r) {
            // 已知弧长、半径求弧度
            var radian = this.arcLength * i / r;
            // 通过弧度求得角度
            var angle = 180 / Math.PI * radian;
            // 已知圆心位置、半径、角度，计算出圆上点的坐标
            var cx = this.cx + Math.cos(angle * Math.PI / 180) * r;
            var cy = this.cy + Math.sin(angle * Math.PI / 180) * r;

            if (this.isDraw) {
                var circle = new zrender.Circle({
                    shape: {
                        cx: cx,
                        cy: cy,
                        r: this.r
                    },
                    style: this.style
                });
                this.zr.add(circle);
            }
            this.coords.push({ cx: cx, cy: cy });
        }
    }, {
        key: '_setAttr',
        value: function _setAttr() {
            var r = this.r + this.addR * this.attr.tier + this.addR / 2;
            this.attr.r = r;
            this.attr.area = Math.PI * Math.pow(r, 2);
            this.attr.rectArea = Math.pow(2 * r, 2);
            this.attr.perimeter = 2 * Math.PI * r;
        }
    }]);
    return RoundCircle;
}();

exports.default = RoundCircle;
module.exports = exports['default'];