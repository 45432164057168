/**
 * @author Ray
 * @description 带侧边竖线的标题文本
 */

import React from 'react';
import classnames from 'classnames';
import styles from './styles.scss';
import { Typography } from 'antd';
import { isEmpty } from '../../utils/utils';

/**
 *
 * @param text
 * @param classes
 * @param children
 * @returns {*}
 */
export default function({ text, classes = {}, children, level }) {
    const { root } = classes;

    return (
        <Typography
            className={classnames(styles.title, {
                [root]: Boolean(root),
                [styles.level1]: level === 1 || level === undefined,
                [styles.level2]: level === 2,
                [styles.level3]: level === 3,
                [styles.hide]: isEmpty(text) && isEmpty(children)
            })}
        >
            {text}
            &nbsp;&nbsp;
            {children}
        </Typography>
    );
}
