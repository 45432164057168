import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

export function getData(data) {
    return request(`${API.V1}/auth/kcDriver/index/queryZqTj`, data );
}

//上市公司行政处罚情况
export function listedCompanies(params) {
    return request(`${API.V1}/cockpit/listedCompanyRisk/rightThird/punishmentListHistogram`, params)
}