import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import Branch from './tables/branch';
import Business from './tables/business';
import Change from './tables/change';
import Executive from './tables/executive';
import Shareholder from './tables/shareholder';
import { getCompanyInfo } from './service';
import { Empty } from 'antd';

export default function(props) {

    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    function getData(){
        // const 
        const {companyId} = props.match.params;
        getCompanyInfo({qyxxId: companyId }).then(res => {
            if(res.success){
                setData(res.data);
            }
        }).finally( () =>{
            setLoading(false);
        })
    }
    
    useEffect(()=>{
        getData();
    }, [])

    if(loading){
        return <div></div>;
    }
    if(!data){
        return <Empty/>;
    }
    const { basic, baxxes, bgxxes, fzjgs, gdxxes} = data;

    return (
        <div className={styles.riskMonitoring}>
            <div className = "box">
                <h4 className = "titleBorder">工商信息</h4>
                <Business data = {basic}/>
            </div>
            <div className = "box">
                <h4 className = "titleBorder">股东信息</h4>
                <Shareholder data = {gdxxes}/>
            </div>
            <div className = "box">
                <h4 className = "titleBorder">董监高信息</h4>
                <Executive data = {baxxes}/>
            </div>
            <div className = "box">
                <h4 className = "titleBorder">变更信息</h4>
                <Change data = {bgxxes}/>
            </div>
            <div className = "box">
                <h4 className = "titleBorder">分支机构信息</h4>
                <Branch data = {fzjgs}/>
            </div>
        </div>
    );
}
