/**
 * @author Ray
 * @desc 上市服务情况-服务板块和行业分布图标
 */

import React from 'react';
import { Empty } from 'antd';
import ReactEcharts from 'echarts-for-react';

export default function ServiceAndIndustry({ data = {}, loading, nodata }) {
    function getOptions() {
        const { industry = [], plate = [] } = data;

        return {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'horizontal',
                bottom: 0,
                data: plate.map(d => d.location)
            },
            series: [
                {
                    name: '服务板块',
                    type: 'pie',
                    selectedMode: 'single',
                    radius: [0, '30%'],
                    label: { show: false },
                    data: plate.map(d => ({ value: d.count, name: d.location }))
                },
                {
                    name: '服务行业',
                    type: 'pie',
                    radius: ['40%', '55%'],
                    label: {
                        normal: {
                            formatter: '{b}: {d}%',
                            padding: [8, 16]
                        }
                    },
                    data: industry.map(indus => ({
                        name: indus.industryName,
                        value: indus.count
                    }))
                }
            ]
        };
    }

    return (
        <div>
            {nodata ? (
                <Empty />
            ) : (
                <ReactEcharts
                    notMerge={true}
                    option={getOptions()}
                    showLoading={loading}
                    style={{ height: 380 }}
                />
            )}
        </div>
    );
}
