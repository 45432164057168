/**
 * @desc 每日成交量前50大个股辅导券商/做市商title
 * @constructor
 */
import React from 'react';
import styles from './styles.scss';

export default function Line8Title() {
    return (
        <div className={ styles.lineContent }>
            <div className={ styles.line8Title }>
                每日成交量前50大个股辅导券商/做市商
            </div>
            <div className={ styles.lines }></div>
        </div>
    )
}