import React from 'react';
import LeftForth from './HightRiskCompaniesList'

/**
 * @desc  高风险企业
 */
export default function LeftForthIndex({ address, style }) {
  return (
      <LeftForth address={address} />
  );
}
