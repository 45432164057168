/**
 * @author Ray
 */
import React from 'react';
import { Pagination } from 'antd';

export default class extends React.PureComponent {
    componentDidMount() { }
    render() {
        const {
            pageSize,
            pageNum,
            showSizeChanger,
            total = 0,
            totalPage = 0,
            onChange
        } = this.props;

        return (
            <Pagination
                size="small"
                showQuickJumper
                showSizeChanger={showSizeChanger}
                hideOnSinglePage
                total={total}
                current={pageNum}
                pageSize={pageSize}
                pageSizeOptions={['5', '10', '15', '20']}
                showTotal={total => {
                    return (
                        <div style={{ textAlign: 'left' }}>
                            <span>
                                共{totalPage}页 / {total}条数据
                            </span>
                        </div>
                    );
                }}
                onChange={(pageNum, pageSize) =>
                    onChange({ pageNum, pageSize, resetCheckbox: false })
                }
                onShowSizeChange={(current, size) =>
                    onChange({
                        pageNum: current,
                        pageSize: size,
                        resetCheckbox: false
                    })
                }
            />
        );
    }
}
