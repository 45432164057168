import React, { useState, useEffect } from 'react';
import blue2 from './blue 2.png';
import yellow2 from './yellow 2.png';
import red2 from './red 2.png';
import { authRedOverview } from '../service';
import { centerBottom } from './service';
import { Empty } from 'antd';
import styles from './styles.scss';
export default function Tree({ address, value, style }) {
  let [data, setData] = useState({});
  const textColor = '#fff';
  // [x, y, r]
  const r = 22,
    R = 33;
  const circleBg = {
    Gradient1: blue2,
    Gradient2: yellow2,
    Gradient3: red2
  };
  //科创板绘制
  const circlePosition = [
    [45, 175, 'kcbAcceptanced', 'Gradient1'], //0
    [205, 175, 'kcbEnquiry', 'Gradient1'], //1
    [365, 175, 'kcbCommittee', 'Gradient1'], // 2
    [525, 175, 'kcbSubmit', 'Gradient1'], //3
    [685, 175, 'kcbEffect', 'Gradient1'], //4

    [300, 100, 'kcbInterrupt', 'Gradient2'], //5
    [400, 100, 'kcbReprieve', 'Gradient2'], //6
    [530, 100, 'kcbSupplement', 'Gradient2'], //7

    [300, 260, 'kcbExamination', 'Gradient3'], //8
    [450, 260, 'kcbUncommittee', 'Gradient3'], //9
    [600, 260, 'kcbUnregister', 'Gradient3'], //10
    [680, 260, 'kcbRegisterEnd', 'Gradient3'] //11
  ];
  const text = {
    kcbAcceptanced: '已受理',
    kcbCommittee: '上市委会议通过',
    kcbEffect: '注册生效',
    kcbEnquiry: '已问询',
    kcbExamination: '终止审查',
    kcbInterrupt: '中止审查',
    kcbRegisterEnd: '终止注册',
    kcbReprieve: '暂缓审议',
    kcbSupplement: '补充审核',
    kcbUncommittee: '上市委会议不通过',
    kcbUnregister: '不予注册',
    kcbSubmit: '提交注册'
  };
  const lines = [
    [
      circlePosition[0][0] + R,
      circlePosition[0][1],
      circlePosition[1][0] - R,
      circlePosition[1][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[5][0] - R,
      circlePosition[5][1] + R
    ],

    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[2][0] - R,
      circlePosition[2][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[8][0] - (R * 2) / 3,
      circlePosition[8][1] - R
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[6][0] - R,
      circlePosition[6][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[9][0] - R,
      circlePosition[9][1]
    ],

    [
      circlePosition[2][0] + R,
      circlePosition[2][1],
      circlePosition[7][0] - R,
      circlePosition[7][1]
    ],
    [
      circlePosition[2][0] + R,
      circlePosition[2][1],
      circlePosition[3][0] - R,
      circlePosition[3][1]
    ],

    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[4][0] - R,
      circlePosition[4][1]
    ],
    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[10][0] - (R * 2) / 3,
      circlePosition[10][1] - R
    ],
    [
      circlePosition[3][0] + R,
      circlePosition[3][1],
      circlePosition[11][0] - R,
      circlePosition[11][1] - R
    ],
  ];

  //主板绘制
  const circlePosition1 = [
    [45, 175, 'aStockMeetingNo', 'Gradient1'], //0
    [205, 175, 'aStockMeetingPassed', 'Gradient1'], //1
    // [365, 175, 'kcbCommittee', 'Gradient1'], // 2
    // [525, 175, 'kcbSubmit', 'Gradient1'], //3
    // [685, 175, 'kcbEffect', 'Gradient1'], //4

    [300, 100, 'aStockCancelAudit', 'Gradient2'], //2
    // [400, 100, 'reprieve', 'Gradient2'], //6
    // [530, 100, 'supplement', 'Gradient2'], //7

    [300, 260, 'aStockDeferVote', 'Gradient3'], //3
    [520, 260, 'aStockMeetingFailed', 'Gradient3'], //4
    // [600, 260, 'kcbUnregister', 'Gradient3'], //10
    // [680, 260, 'kcbRegisterEnd', 'Gradient3'] //11
  ];

  const text1 = {
    aStockMeetingNo: '未上会',
    aStockMeetingPassed: '上会通过',
    aStockCancelAudit: '取消审核',
    aStockDeferVote: '暂缓表决',
    aStockMeetingFailed: '上会未通过',
  };

  const lines1 = [
    [
      circlePosition[0][0] + R,
      circlePosition[0][1],
      circlePosition[1][0] - R,
      circlePosition[1][1]
    ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[2][0] - 2.5*R,
      circlePosition[2][1] + 1.5*R,
    ],

    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[2][0] - 2.8*R,
      circlePosition[2][1] - 1.3*R
    ],
    // [
    //   circlePosition[1][0] + R,
    //   circlePosition[1][1],
    //   circlePosition[3][0] - (R * 2) / 3,
    //   circlePosition[3][1] - R
    // ],
    // [
    //   circlePosition[1][0] + R,
    //   circlePosition[1][1],
    //   circlePosition[6][0] - R,
    //   circlePosition[6][1]
    // ],
    [
      circlePosition[1][0] + R,
      circlePosition[1][1],
      circlePosition[4][0] - 6*R,
      circlePosition[4][1] + 1.5*R
    ],

    // [
    //   circlePosition[2][0] + R,
    //   circlePosition[2][1],
    //   circlePosition[7][0] - R,
    //   circlePosition[7][1]
    // ],
    // [
    //   circlePosition[2][0] + R,
    //   circlePosition[2][1],
    //   circlePosition[3][0] - R,
    //   circlePosition[3][1]
    // ],

    // [
    //   circlePosition[3][0] + R,
    //   circlePosition[3][1],
    //   circlePosition[4][0] - R,
    //   circlePosition[4][1]
    // ],
    // [
    //   circlePosition[3][0] + R,
    //   circlePosition[3][1],
    //   circlePosition[10][0] - (R * 2) / 3,
    //   circlePosition[10][1] - R
    // ],
    // [
    //   circlePosition[3][0] + R,
    //   circlePosition[3][1],
    //   circlePosition[11][0] - R,
    //   circlePosition[11][1] - R
    // ],
  ];
  
  useEffect(() => {
    value == '其他板块' ? centerBottom({ stock: 'A_STOCK', area: address }).then(({ success, data }) => {
       if (success == true) {
          setData(data)
       } else {
          <Empty />
       }
    }) :
    value == '科创板' ? centerBottom({ stock: 'KCB', area: address }).then(({ success, data }) => {
       if (success == true) {
          setData(data)
       } else {
          <Empty />
       }
    }) : <Empty />

    document.addEventListener('resize', () => {
      debugger;
    });
  }, [address, value]);
  return (
    <div
      style={{
        width: window.screen.width < parseInt(1920) ? 500 : 770,
        margin: window.screen.width < parseInt(1920) ? 0 : 'auto',
        position: 'relative',
        top: '-40px',
        ...style
      }}
    >
      {
        value == '科创板' ? <svg className={ styles.kcb } preserveAspectRatio="xMinYMin meet" viewBox={window.screen.width < parseInt(1920) ? '0, 0, 300, 300' : '0, 0, 770, 770'}>
        {circlePosition.map((value, index) => {
          return (
            <g key={'g' + index}>
              <image
                xlinkHref={circleBg[value[3]]}
                x={value[0] - 37.5}
                y={value[1] - 37.5}
                height={53}
                width={71}
              />

                <text
                  x={value[0] - 5}
                  y={value[1] - 30}
                  fill={textColor}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  style={{
                    // transform: 'rotate(-1deg)'
                  }}
                >
                  {data[value[2]]}
                </text>

              <text
                x={value[0]}
                y={value[1] + 20}
                fill={textColor}
                fontWeight="bold"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {text[value[2]]}
              </text>
            </g>
          );
        })}
        {lines.map((value, index) => {
          return (
            <line
              key={'line' + index}
              x1={value[0]}
              y1={value[1]}
              x2={value[2]}
              y2={value[3]}
              style={{
                stroke: '#0B8ED9',
                strokeWidth: 2,
                strokeDasharray: '5,5'
              }}
            />
          );
        })}
        y
      </svg> :

      value == '其他板块' ? <div style={{ marginLeft: 70 }}><svg className={ styles.other } preserveAspectRatio="xMinYMin meet" viewBox={window.screen.width < parseInt(1920) ? '0, 0, 300, 300' : '0, 0, 770, 770'}>
      {circlePosition1.map((value, index) => {
        return (
          <g key={'g' + index}>
            <image
              xlinkHref={circleBg[value[3]]}
              x={value[0] - 37.5}
              y={value[1] - 37.5}
              width={71}
              height={53}
            />

              <text
                x={value[0] - 5}
                y={value[1] - 30}
                fill={textColor}
                dominantBaseline="middle"
                textAnchor="middle"
                style={{
                  // transform: 'rotate(-1deg)'
                }}
              >
                {data[value[2]]}
              </text>

            <text
              x={value[0]}
              y={value[1] + 20}
              fill={textColor}
              fontWeight="bold"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {text1[value[2]]}
            </text>
          </g>
        );
      })}
      {lines1.map((value, index) => {
        return (
          <line
            key={'line' + index}
            x1={value[0]}
            y1={value[1]}
            x2={value[2]}
            y2={value[3]}
            style={{
              stroke: '#0B8ED9',
              strokeWidth: 2,
              strokeDasharray: '5,5'
            }}
          />
        );
      })}
      y
    </svg></div> : ''

      }

    </div>
  );
}
