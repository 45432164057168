/**
 * @author Ray
 */

import React from 'react';

export default class extends React.Component {

    render() {
        return this.props.children({
            ...this.props,
            ...this.state
        });
    }
}
