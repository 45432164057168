import styles from './styles.scss';
import React, { useEffect, useState } from 'react';
import { getData } from './service';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { NegativeNumber } from '../../../utils/validator';
/**
 * @desc 科创钻石指数
 * @returns {*}
 * @constructor
 */

export default function DiamondNum({ address, style }) {
  const [tableData, setData] = useState({ loading: true, data: [] });
  useEffect(() => {
    getData({
      area: address
    }).then(({ success, data }) => {
      if (success) {
        setData({ loading: false, data });
      } else {
        setData({ loading: false, data: [] });
      }
    });
  }, [address]);

  const { loading, data } = tableData;
  return (
    <div className={styles.diamondNum} style={style}>
      <div className={styles.table}>
        {loading ? (
          ''
        ) : data && data.length ? (
          <table>
            <tbody>
              {data.map(ele => {
                const { fullName } = ele;
                return (
                  <tr key={fullName}>
                    <td width="65%">
                      <Link
                        target="_blank"
                        to={
                          ele.isMarket
                            ? `/riskMonitoring/detail/risk/${fullName}/${ele.qyxxId}`
                            : `/investment/enterpriseAnalysis/${fullName}/${ele.qyxxId}/${ele.creditCode}`
                        }
                      >
                        {fullName}
                      </Link>
                    </td>
                    <td width="20%">{NegativeNumber.pattern.test(ele.score) ? parseFloat(ele.score).toFixed(2) : ele.score}</td>
                    <td>{ele.level}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
              <Empty />
            )}
      </div>
    </div>
  );
}
