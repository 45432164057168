import request from '../../../../utils/fetch';
import { API } from '../../../../constants/enum';

/**
 * @desc 上市公益活动/公益活动列表展示
 * @param companyName
 * @param type
 */
export function querySsgy({ companyName, type, pageNum, pageSize }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showListBenefit`,
        {
            companyName,
            type, // 1、上市公益活动 2、公益活动
            pageNum,
            pageSize
        }
    );
}

/**
 * @desc 上市公益活动/公益活动删除
 * @param id
 */
export function deleteAppraise({ id }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/delListBenefit`,
        {
            id
        }
    );
}

/**
 * @desc 获取公益活动详情
 * @param id 上市公益活动/公益活动id
 */
export function showAppraise(id) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showListBenefitDetail`,
        {
            id
        }
    );
}

/**
 * @desc 上市公益/公益活动添加编辑
 * @param activityContent
 * @param activityTime
 * @param companyName
 * @param id
 * @param joinCompany
 * @param type
 */
export function addEditAppraise({
    activityContent,
    activityTime,
    companyName,
    id,
    joinCompany,
    type // 1、上市公益活动 2、公益活动
}) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/addListBenefit`,
        {
            activityContent,
            activityTime,
            companyName,
            id,
            joinCompany,
            type
        },
        {
            method: 'post'
        }
    );
}

/**
 * @desc 企业纾困活动列表展示
 * @param companyName
 * @param pageSize
 * @param pageNum
 * @returns {*}
 */
export function showRelief({ companyName, pageSize, pageNum }) {
    return request(`${API.V1}/investbank/intermediary/appraise/showRelief`, {
        companyName,
        pageSize,
        pageNum
    });
}

/**
 * @desc 企业纾困活动删除
 * @param id
 */
export function delRelief({ id }) {
    return request(`${API.V1}/investbank/intermediary/appraise/delRelief`, {
        id
    });
}

/**
 * @desc 企业纾困活动展示详情回显
 * @param id
 */
export function showReliefDetail({ id }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showReliefDetail`,
        {
            id
        }
    );
}

/**
 * @desc 企业纾困活动添加编辑
 * @param companyName
 * @param id
 * @param joinReliefProject
 * @param pushMoney
 * @param pushMoneyRatio
 */
export function addEditRelief({
    companyName,
    id,
    joinReliefProject,
    pushMoney,
    pushMoneyRatio,
    pageNum,
    pageSize
}) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/addRelief`,
        {
            companyName,
            id,
            joinReliefProject,
            pushMoney,
            pushMoneyRatio,
            pageNum,
            pageSize
        },
        {
            method: 'post'
        }
    );
}

/**
 * @desc 公益捐款活动列表展示
 * @param companyName
 * @param pageNum
 * @param pageSize
 * @returns {*}
 */
export function showDonations({ companyName, pageNum, pageSize }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showBenefitDonation`,
        {
            companyName,
            pageNum,
            pageSize
        }
    );
}

/**
 * @desc 公益捐款活动删除
 * @param id
 */
export function delDonation({ id }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/delBenefitDonation`,
        {
            id
        }
    );
}

/**
 * @desc 公益捐款活动展示详情回显
 * @param id
 */
export function showDonationDetail({ id }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showBenefitDonationDetail`,
        {
            id
        }
    );
}

/**
 * @desc 公益捐款活动添加编辑
 * @param companyName
 * @param donationMoney
 * @param donationTarget
 * @param donationTime
 * @param id
 */
export function addEditDonation({
    companyName,
    donationMoney,
    donationTarget,
    donationTime,
    id
}) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/addBenefitDonation`,
        {
            companyName,
            donationMoney,
            donationTarget,
            donationTime,
            id
        },
        {
            method: 'post'
        }
    );
}

/**
 * @desc 就业情况删除
 * @param id
 */
export function delEmployment({ id }) {
    return request(`${API.V1}/investbank/intermediary/appraise/delEmployment`, {
        id
    });
}

/**
 * @desc 就业情况展示详情回显
 * @param id
 */
export function showEmploymentDetail({ companyName }) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/showEmploymentDetail`,
        {
            companyName
        }
    );
}

/**
 * @desc 就业情况添加编辑
 * @param annuityRatio
 * @param companyName
 * @param contractRatio
 * @param graduatesNumber
 * @param insuranceRatio
 * @param staffNumber
 * @param womanNumberRatio
 */
export function addEditEmployment({
    annuityRatio,
    companyName,
    contractRatio,
    graduatesNumber,
    insuranceRatio,
    staffNumber,
    womanNumberRatio,
    id
}) {
    return request(
        `${API.V1}/investbank/intermediary/appraise/addEmployment`,
        {
            annuityRatio,
            companyName,
            contractRatio,
            graduatesNumber,
            insuranceRatio,
            staffNumber,
            womanNumberRatio,
            id
        },
        { method: 'post' }
    );
}
