import React from 'react';
import styles from './styles.scss';
import WechatIMG from '../../../header/images/WechatIMG.png';
import moment from 'moment';
/**
 * @desc 顶部中间的title
 * @constructor
 */
export default function TopTitle() {
    return (
        <div>
            <div style={{
                position: 'absolute',
                left: '50px',
                top: '15px',
                fontSize: '28px'
            }}>{moment().format('YYYY-MM-DD')}</div>
            <div
                className={styles.topTitle}
                style={{}}
            >
                {/* <img src={WechatIMG} style={{ width: 38 }} /> */}
                &nbsp;&nbsp; 境内上市公司风险情况
            </div>
            <div style={{
                float: 'right',
                marginTop: '15px',
                marginRight: '50px',
                fontSize: '28px'
            }}>{moment().format('HH:mm:ss')}</div>
        </div>
    );
}
