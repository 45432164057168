'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var LayerType = exports.LayerType = {
    BASE: 'base',
    MAIN: 'main',
    MAP: 'map'
};
var ChartStatus = exports.ChartStatus = {
    IDLE: 'idle', // 空闲
    BUSY: 'busy' // 繁忙
};
var ShapeType = exports.ShapeType = {
    NODE: 'node',
    LINE: 'line',
    CIRCLE: 'circle'
};

var NodeType = exports.NodeType = {
    NORMAL: 'normal', // 普通企业
    RISK: 'risk', // 风险企业
    BLACK: 'black', // 黑名单企业
    REVOKE: 'revoke', // 吊销企业
    PERSON: 'person', // 自然人
    ADDRESS: 'address', // 同地址
    DEBIT: 'debit', // 借贷担保
    LITIGATION: 'litigation', // 诉讼
    BIDDING: 'bidding' // 招投标
};

// 事件节点类型映射
var EventTypeMapping = exports.EventTypeMapping = {
    address: ['address'], // 同地址
    debit: ['qyxg_debet'], // 借贷担保
    litigation: ['ktgg', 'rmfygg', 'zgcpwsw', 'zhixing', 'dishonesty'], // 诉讼
    bidding: ['shgy_zhongjg', 'shgy_zhaobjg'] // 招投标
};

// state数据类型映射到图数据
var TypeMapping = exports.TypeMapping = {
    nodes: 'node',
    links: 'line',
    circles: 'circle'
};

var NodeTypeText = exports.NodeTypeText = {
    'normal': '',
    'risk': '',
    'black': '',
    'revoke': '',
    'person': '',
    'address': '同地址',
    'debit': '借贷/担保',
    'litigation': '涉诉',
    'bidding': '招中标'
};

var StreamType = exports.StreamType = {
    CENTER: 'center',
    UPPER: 'upper',
    LOWER: 'lower'
};

// 关联关系
var RelationType = exports.RelationType = {
    INVESTMENT: 'investment',
    MANAGEMENT: 'management'
};
// 投资类型
var InvestmentTypes = exports.InvestmentTypes = ['invest', 'gd'];
// 管理类型
var ManagementTypes = exports.ManagementTypes = ['supervisor', 'director', 'legal', 'executive', 'fr', 'js', 'gg'];
// 线上关系类型文字转换
var RelationRole = exports.RelationRole = {
    invest: '投资',
    supervisor: '监事',
    director: '董事',
    legal: '法人',
    executive: '经理',
    gd: '股东',
    fr: '法人',
    js: '监事',
    gg: '高管'
};

// 图谱类型文字转换
var typeToName = exports.typeToName = {
    circle: '圈层分布',
    community: '关键链路',
    region: '地域分布',
    industry: '行业分布',
    stock: '股权结构',
    event: '事件关系',
    seek: '关系探寻',
    natural: '核心自然人'
};