import React from 'react';
import styles from './styles.scss';
import classnames from 'classnames';
import bgpic from '../images/beijing.png';

export default function Body({ children }) {
    return (
        <div
            className={classnames('clearfix', styles.body)}
            style={{
                background: `url(${bgpic})`,
                backgroundColor: '#0c1d3b',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%'
            }}
        >
            {children}
        </div>
    );
}
