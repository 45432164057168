'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

exports.stateToGrid = stateToGrid;

var _collection = require('lodash/collection');

var _degree = require('../processor/degree');

var _common = require('../common');

var _handler = require('../../core/handler');

var _handler2 = _interopRequireDefault(_handler);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * 将State格式化为Grid数据
 * @param {object} state 当前图型状态
 * @param {string} centerId 中心节点, 用于计算度数
 * @return {object} 返回GridState对象
 *   type {string}                              表示返回的数据格式类型, 此为 grid 格式
 *   nodes { degree: '圈层度数', childs: [] }    比 state.nodes 增加此2项属性
 *   links { fromNode: node, toNode: node }     比 state.links 增加此2项属性
 *   nodesKV {id1: node1, id2: node2}           以 id: 为key, node为value格式化
 *   linksKV {id1: link1, id2: link2}           以 id: 为key, link为value格式化
 *   nodesGroup {0: [...nodes], 1: [...nodes]}  以 degree 度数分组 nodesGroup[0][0].id
 *   linksGroup {from-to: [], from-to: []}      以 from-to 节点id分组
 */
function stateToGrid(state, centerId) {
    var setDegree = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _degree.setDegree;

    if ((0, _common.isEmpty)(state)) {
        return;
    }

    var _state$nodes = state.nodes,
        nodes = _state$nodes === undefined ? [] : _state$nodes,
        _state$links = state.links,
        links = _state$links === undefined ? [] : _state$links;

    var dataRight = true;

    if (nodes[0] && (0, _typeof3.default)(nodes[0].id) === 'object') {
        _handler2.default.trigger('store.error', {
            error: 'nodes 格式不正确！nodes 必须存在正确的id',
            type: 'dataException'
        });
        throw new Error('nodes 格式不正确！nodes 必须存在正确的id');
    }

    if (links[0] && (0, _typeof3.default)(links[0].from) === 'object') {
        _handler2.default.trigger('store.error', {
            error: 'links 格式不正确！from，to对象只能是id',
            type: 'dataException'
        });
        throw new Error('links 格式不正确！from，to对象只能是id');
    }

    var nodesGroup = {}; // 节点按度数分组
    var linksGroup = {}; // 线条按from_to分组
    var nodesKV = (0, _collection.keyBy)(nodes, 'id'); // 转换成对象
    var linksKV = (0, _collection.keyBy)(links, 'id'); // 转换成对象

    // 设置childs
    nodes.forEach(function (d) {
        d.childs = [];
    });

    var _loop = function _loop(i) {
        var d = links[i];

        d['fromNode'] = nodesKV[d.from] || null;
        d['toNode'] = nodesKV[d.to] || null;

        if (d['fromNode'] === null || d['toNode'] === null) {
            // console.error('数据有问题：', d);
            dataRight = false;
            _handler2.default.trigger('store.error', {
                error: '数据有问题：link的fromNode或toNode为空' + d,
                type: 'dataException'
            });
            console.error('index \u4E3A ' + i + ' \u7684 link \u6570\u636E\u6709\u7F3A\u5931\uFF1AfromNode \u6216 toNode \u4E3A\u7A7A, \u8BE5\u6570\u636E\u5C06\u88AB\u5FFD\u7565.', d);
        } else {
            // 重复数据不能添加
            if (d.fromNode['childs'].findIndex(function (item) {
                return item.id === d.toNode.id;
            }) === -1) {
                d.fromNode['childs'].push(d.toNode);
            }
            if (d.toNode['childs'].findIndex(function (item) {
                return item.id === d.fromNode.id;
            }) === -1) {
                d.toNode['childs'].push(d.fromNode);
            }

            // 设置 id1_id2
            if (!linksGroup[d.from + '-' + d.to]) {
                linksGroup[d.from + '-' + d.to] = [];
            }
            linksGroup[d.from + '-' + d.to].push(d);
        }
    };

    for (var i = 0; i < links.length; i++) {
        _loop(i);
    }

    // 如果有中心节点则计算度数 degree
    if (centerId) {
        setDegree && setDegree(nodes, centerId);
        nodesGroup = (0, _collection.groupBy)(nodes, 'degree'); // 圈层分数组存储
    }

    return { nodes: nodes, links: links, nodesKV: nodesKV, linksKV: linksKV, nodesGroup: nodesGroup, linksGroup: linksGroup, dataRight: dataRight, centerId: centerId };
}